import { ObjectStore, StoreChangeDetail, Where } from 'browserbase';
import { isEqual } from 'typewriter-editor';
import { DatabaseStore } from '../dabble-database';
import { getNow } from '../date';
import { DabbleDatabase } from '../types';
import { FromRemote } from './remote-support';
import { Readable, StartStopNotifier, writable } from './store';

export interface ModifiedData {
  modified?: number;
}

export interface Lookup<T> {
  [key: string]: T;
}

export interface UpdateOptions {
  isFromRemote?: boolean;
}

export interface ModifiedDataStore<T extends ModifiedData, Packaged = T[]> extends Readable<Packaged> {
  update(key: Key, updates: Partial<T>, options?: UpdateOptions): Promise<T>;
  delete(key: Key, forceDelete?: boolean): Promise<T>;
  reload(): Promise<void>;
  isLoaded: Readable<boolean>;
}

export type SortFunction<T> = (a: T, b: T) => number;

export interface ModifiedDataOptions<T, Packaged = T[]> {
  fields?: Set<string>;
  sort?: SortFunction<T>;
  fromRemote?: FromRemote;
  packageData?: (list: T[]) => Packaged;
  query?: (store: ObjectStore) => ObjectStore | Where<any, any>;
  start?: StartStopNotifier<Packaged>;
  filterChanges?: (data: T, key: Key) => boolean;
  trueDelete?: boolean;
}

type Key = string | string[];

function passThrough(list: any) {
  return list;
}

export function createModifiedDataStore<T extends ModifiedData, Packaged = T[]>(
  dbStore: DatabaseStore,
  storeName: string,
  options: ModifiedDataOptions<T, Packaged> = {}
): ModifiedDataStore<T, Packaged> {
  let store: ObjectStore;
  let keyPath: string;
  let list: T[] = [];
  const isLoaded = writable(false);
  let { packageData, query } = options;
  const { fields, sort, fromRemote, start, filterChanges } = options;
  if (!packageData) packageData = passThrough;
  if (!query) query = passThrough;
  const { get, set, subscribe } = writable<Packaged>(packageData(list), start);

  dbStore.register(onDBChange);

  async function onDBChange(db: DabbleDatabase) {
    if (store) {
      store.removeEventListener('change', onChange);
      list = [];
      updateStore();
    }

    store = db && (db.stores as any)[storeName];

    if (store) {
      keyPath = store.keyPath as string;
      store.addEventListener('change', onChange);
      reload();
    }
  }

  async function reload() {
    list = [];
    isLoaded.set(false);
    if (store) {
      const storeOrWhere = query(store);
      if (storeOrWhere) {
        list = await storeOrWhere.getAll();
        isLoaded.set(true);
      }
    }
    updateStore();
  }

  function onChange({ detail: { obj, key, declaredFrom } }: CustomEvent<StoreChangeDetail<T, Key>>) {
    if (declaredFrom === 'local' || (filterChanges && !filterChanges(obj, key))) return;
    list = filterByKeyPath(list, keyPath, key);
    if (obj) list = list.concat(obj);
    updateStore();
  }

  async function update(key: Key, updates: Partial<T>, options?: UpdateOptions) {
    if (!store) throw new Error(`No ${storeName} loaded for updating.`);
    const index = findByKeyPath(list, keyPath, key);
    let data = list[index] as any;

    if (data && !hasChanges(data, updates)) return;

    const keys: { [key: string]: string } = {};
    if (Array.isArray(keyPath)) keyPath.forEach((name, i) => (keys[name] = key[i]));
    else keys[keyPath] = key as string;

    const oldData = data;
    data = { ...data, ...updates, modified: getNow(), ...keys };
    if (fields) data = removeFieldsExcept(data, fields);
    Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
    if (isEqual(data, oldData)) return;
    list = [...list];
    if (index < 0) list.push(data);
    else list[index] = data;
    updateStore();

    const remote = options && options.isFromRemote && fromRemote;
    remote && fromRemote.add(data);
    try {
      await store.put(data);
    } catch (err) {
      remote && fromRemote.delete(data);
      throw err;
    }
    remote && fromRemote.delete(data);
    return data;
  }

  async function deleteData(key: Key, forceDelete?: boolean) {
    if (!store) throw new Error(`No ${storeName} loaded for updating.`);
    const index = findByKeyPath(list, keyPath, key);
    const data = list[index];
    if (index !== -1) {
      // Not all data may be loaded depending on options, allow a db.delete to happen even if the store doesn't update
      if (fields && fields.has('trashed') && !(data as any).trashed && !forceDelete) {
        return update(key, { trashed: getNow() } as any as Partial<T>);
      }

      list = [...list.slice(0, index), ...list.slice(index + 1)];
      updateStore();
    }
    if (options.trueDelete) {
      return await store.delete(key as string);
    } else {
      return update(key, { deleted: getNow() } as any as Partial<T>);
    }
  }

  function updateStore() {
    if (sort) list.sort(sort);
    set(packageData(list));
  }

  return {
    update,
    delete: deleteData,
    reload,
    isLoaded,
    get,
    subscribe,
  };
}

export type LookupFunc<T> = (list: T[], keyName: string) => Lookup<T>;

export function createLookupFromList<T>(list: T[], keyName: string) {
  return list.reduce((lookup, data) => (lookup[(data as any)[keyName]] = data) && lookup, {} as Lookup<T>);
}

function findByKeyPath<T>(list: T[], keyPath: string | string[], key: Key) {
  if (Array.isArray(keyPath) && typeof key !== 'object') {
    throw new Error('Must include a keyed object with compound keys');
  }
  if (Array.isArray(keyPath)) {
    return list.findIndex(data => keyPath.every((keyName, i) => (data as any)[keyName] === key[i]));
  } else {
    return list.findIndex(data => (data as any)[keyPath] === key);
  }
}

function filterByKeyPath<T>(list: T[], keyPath: string | string[], key: Key) {
  if (Array.isArray(keyPath) && typeof key !== 'object') {
    throw new Error('Must include a keyed object with compound keys');
  }
  if (Array.isArray(keyPath)) {
    return list.filter(data => !keyPath.every((keyName, i) => (data as any)[keyName] === key[i]));
  } else {
    return list.filter(data => (data as any)[keyPath] !== key);
  }
}

function hasChanges(source: any, updates: any) {
  return Object.keys(updates).some(key => {
    return source[key] !== updates[key];
  });
}

// Remove all fields except for those listedn in the set
function removeFieldsExcept(obj: any, fields: Set<string>) {
  Object.keys(obj).forEach(key => {
    if (!fields.has(key)) {
      delete (obj as any)[key];
    }
  });
  return obj;
}
