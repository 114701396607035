import { RouterStore } from './router';
import { Readable, writable } from './store';

export function createLastUrl(router: RouterStore): Readable<string> {
  let currentUrl: string = sessionStorage.lastUrl || '/';
  const { get, set, subscribe } = writable<string>(currentUrl);

  router.subscribe(router => {
    sessionStorage.lastUrl = currentUrl;
    set(currentUrl);
    currentUrl = router.url;
  });

  return {
    get,
    subscribe,
  };
}

export function createLastProductUrl(lastUrl: Readable<string>): Readable<string> {
  const { get, set, subscribe } = writable<string>(sessionStorage.lastProductUrl || '/');

  lastUrl.subscribe(lastUrl => {
    if (/^(\/$|\/p\/)/.test(lastUrl)) {
      sessionStorage.lastProductUrl = lastUrl;
      set(lastUrl);
    }
  });

  return {
    get,
    subscribe,
  };
}
