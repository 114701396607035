import waf from '@dabble/util/waf';
import { isEqual } from 'typewriter-editor';
import { DESKTOP, size } from '../device';
import { Preferences } from '../types';
import { FeaturesStore } from './features';
import { RouterStore } from './router';
import { Readable, writable } from './store';
import { UserMetaTypeStore } from './user-meta';

export interface Focus {
  focusing: boolean;
  focusLock: boolean;
  preventFocusing: boolean;
  focused: boolean;
  autofocused: boolean;
}

export interface FocusStore extends Readable<Focus> {
  start: (lock?: boolean) => void;
  stop: (unlock?: boolean) => void;
  prevent: (on: boolean) => void;
  skipNextStop: () => void;
}

export const FOCUSING_TIME = 500;
export const FOCUSING_TIME_SLOW = 3000;

export function createFocusStore(
  features: FeaturesStore,
  preferences: UserMetaTypeStore<Preferences>,
  router: RouterStore
): FocusStore {
  let focus: Focus = { focusing: false, focusLock: false, preventFocusing: false, focused: false, autofocused: false };
  let skipNextTimeout: any = 0;
  let focusingTimeout: any = 0;
  let focusedTimeout: any = 0;
  let continuedInputTimeout: any = 0;
  const { get, set, subscribe } = writable(focus, () => {
    return router.subscribe(router => {
      if (!router.path.startsWith('/p/') && focus.focusing) stop(true);
    });
  });

  function update(updates: Partial<Focus>) {
    const newFocus = { ...focus, ...updates };
    if (!isEqual(newFocus, focus)) {
      set((focus = newFocus));
    }
  }

  function start(lock?: boolean) {
    if (
      size.get() !== DESKTOP ||
      (focus.preventFocusing && !lock) ||
      !features.get().has('focus') ||
      (preferences.get().noAutofade && !lock)
    ) {
      return;
    }
    if (lock === true) {
      clearTimeout(focusedTimeout);
      update({ focusing: true, focusLock: lock, preventFocusing: false });
      focusedTimeout = setTimeout(() => update({ focusing: true, focused: true }), FOCUSING_TIME);
    } else if (!focus.focusing && !focusingTimeout) {
      clearTimeout(focusedTimeout);
      focusingTimeout = setTimeout(() => {
        clearTimeout(continuedInputTimeout);
        focusingTimeout = null;
        update({ ...focus, focusing: true });
        focusedTimeout = setTimeout(() => update({ focusing: true, autofocused: true }), FOCUSING_TIME_SLOW);
      }, 6000);
    }
    if (lock !== true && focusingTimeout && !(focus.focusing || focus.autofocused || focus.focused)) {
      clearTimeout(continuedInputTimeout);
      continuedInputTimeout = setTimeout(stop, 1500);
    }
  }

  async function stop(unlock?: boolean) {
    if (skipNextTimeout) {
      // Don't count mousemoves triggered by the autoscroll
      skipNextTimeout = 0;
    } else if (unlock === true && focus.focusing) {
      clearTimeout(focusedTimeout);
      focusingTimeout = null;
      update({ focusing: false, focusLock: false, preventFocusing: false, focused: false, autofocused: false });
    } else if ((focusingTimeout || focus.focusing) && !focus.focusLock) {
      clearTimeout(focusingTimeout);
      focusingTimeout = null;
      await waf();
      update({ focusing: false, focused: false, autofocused: false });
    }
  }

  function prevent(on: boolean) {
    if (focus.preventFocusing !== on) {
      update({ preventFocusing: on });
    }
  }

  function skipNextStop() {
    skipNextTimeout = setTimeout(() => (skipNextTimeout = 0), 40);
  }

  return {
    start,
    stop,
    prevent,
    skipNextStop,
    get,
    subscribe,
  };
}
