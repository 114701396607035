<script>
  import { currentDocId, projectStore, waitForUpdates } from '@dabble/app';
  import { onDestroy, tick } from 'svelte';
  import { normalizeRange } from 'typewriter-editor';
  import {
    activeCommentId,
    chosenCommentId,
    commentsStore,
    selectedCommentId,
    showComments,
    updateCommentDisplay,
  } from '../comments-store';
  import Comment from './Comment.svelte';
  import { getEditorForComment, getPageComments } from './display-helpers';

  export let pageElement;
  const margin = 16;
  let oldPageElement;
  let commentsElement;
  let commentList = [];
  let showGutter;
  let changingDoc;
  let document;

  $: onPageElement(pageElement);
  $: if (pageElement && commentsElement) updateComments(true);
  $: onDocId($currentDocId);
  $: onActiveCommentId($activeCommentId);
  $: onCommentsChange($commentsStore);
  $: onListChange(commentList);
  $: height = commentList.length ? getBottom(commentList[commentList.length - 1]) : 0;
  $: if (!$showComments && $chosenCommentId) {
    const info = commentList.find(c => c.id === $chosenCommentId);
    onRelease(info);
  }

  onDestroy(updateCommentDisplay(updateComments));
  onDestroy(
    projectStore.forceTextUpdate(async () => {
      await tick();
      updateComments(true);
    })
  );

  async function onPageElement(pageElement) {
    if (oldPageElement) {
      document.removeEventListener('selectionchange', onSelectionChange);
      oldPageElement.removeEventListener('editor-change', onEditorChange);
      oldPageElement.removeEventListener('comment-created', onComentCreating);
    }
    if (pageElement) {
      document = pageElement.ownerDocument;
      document.addEventListener('selectionchange', onSelectionChange);
      pageElement.addEventListener('editor-change', onEditorChange);
      pageElement.addEventListener('comment-created', onComentCreating);
    } else {
      commentList = [];
    }
    oldPageElement = pageElement;
  }

  async function onDocId() {
    changingDoc = true;
    await tick();
    changingDoc = false;
    if (pageElement) {
      await updateComments(true);
    }
  }

  async function onCommentsChange() {
    if (!changingDoc && pageElement) {
      updateComments();
    }
  }

  async function onEditorChange({ detail: { change } }) {
    if (change && change.contentChanged) {
      await updateComments(true);
    }
  }

  function onSelectionChange() {
    const selection = document.getSelection();
    const range = selection.rangeCount && selection.getRangeAt(0);
    if (range) {
      const editable = range.startContainer.parentElement.closest('.typewriter-editor');
      const editor = editable && editable.editor;
      if (editor && editor.doc.selection) {
        const [from, to] = editor.doc.selection;
        let [first, last] = normalizeRange([from, to]);
        if (first === last) last++;

        const format = editor.doc.getTextFormat([first, last]);
        if (format.comment) {
          const ids = Object.keys(format.comment).filter(id => format.comment[id]);
          if (ids.length > 1 && from !== to) {
            const source = [from, from + (from < to ? 1 : -1)];
            const format = editor.doc.getTextFormat(source);
            $selectedCommentId = Object.keys(format.comment).pop();
          } else {
            $selectedCommentId = ids.pop();
          }
          if ($chosenCommentId) {
            $chosenCommentId = '';
          }
          return;
        }
      }
    }

    $selectedCommentId = '';
  }

  function onComentCreating() {
    updateComments();
  }

  let gutterTimeout;
  function onListChange(comments) {
    clearTimeout(gutterTimeout);
    if (comments.length) {
      if (!showGutter) showGutter = true;
    } else {
      gutterTimeout = setTimeout(() => (showGutter = false), 300);
    }
  }

  // Look at all the comment spans on the page and create add the comments into the commentList, sorted by date created.
  async function updateComments(noTransition) {
    if (!commentsElement) return;
    if (noTransition) {
      commentsElement.classList.add('initing');
    }
    commentList = getPageComments(pageElement);
    await tick();
    updatePosition();
    if (noTransition) {
      await waitForUpdates();
      if (commentsElement) {
        commentsElement.offsetHeight;
        commentsElement.classList.remove('initing');
      }
    }
  }

  function updatePosition() {
    if (!commentsElement) return;
    for (const node of commentsElement.children) {
      const { id } = node.dataset;
      if (!id) continue;
      const info = commentList.find(info => info.id === id);
      info.height = node.offsetHeight + margin;
      info.top = info.origin;
    }

    let top = 0;
    commentList.forEach(info => {
      if (info.id !== $activeCommentId && info.top < top) {
        info.top = top;
      }
      top = getBottom(info);
    });

    if ($activeCommentId) {
      const i = commentList.findIndex(info => info.id === $activeCommentId);
      if (commentList[i]) {
        const active = commentList[i];
        top = active.top = active.origin;
        commentList
          .slice(0, i)
          .reverse()
          .some(info => {
            const bottom = getBottom(info);
            if (bottom >= top) {
              info.top = top - info.height;
              top -= info.height;
            } else {
              // We can break out of the loop once there is space between comments since the rest don't need adjusting.
              return true;
            }
          });
      }
    }

    // Trigger update
    commentList = commentList;
  }

  function getBottom(info) {
    return info.top + info.height;
  }

  async function onActiveCommentId() {
    await tick();
    updatePosition();
  }

  function onRelease(info, hasInput) {
    $chosenCommentId = '';
    if (!info.comment && !hasInput) {
      const editor = getEditorForComment(pageElement, info.id);
      if (editor) {
        editor.modules.decorations.getDecorator('comments').clear().apply();
        updateComments();
      }
    }
  }

  function onSelect(info) {
    $chosenCommentId = info.id;
  }
</script>

<div class="comments focus-fade" bind:this={commentsElement} style="height: {height}px;">
  {#each commentList as info (info.id)}
    <Comment
      {info}
      selected={$activeCommentId === info.id}
      on:select={() => onSelect(info)}
      on:release={event => onRelease(info, event.detail.hasInput)}
    />
  {/each}
</div>

<style>
  .comments {
    position: relative;
    width: 272px;
  }
  .comments:global(.initing .thread) {
    transition: none;
  }
</style>
