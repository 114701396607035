<script lang="ts">
  import { currentProjectMeta, projectMetas, projectStore } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiRabbit, mdiTortoise } from '@mdi/js';

  let currentSpeed = 1;
  $: updateSpeed($currentProjectMeta?.readingSpeed || 1);
  $: speedText = `${currentSpeed}×`;

  function updateSpeed(speed: number) {
    currentSpeed = speed;
  }

  function decreaseSpeed() {
    if (currentSpeed > 0.1)
      projectMetas.update($projectStore.projectId, { readingSpeed: Math.round((currentSpeed - 0.1) * 10) / 10 });
  }

  function increaseSpeed() {
    if (currentSpeed < 2)
      projectMetas.update($projectStore.projectId, { readingSpeed: Math.round((currentSpeed + 0.1) * 10) / 10 });
  }

  function onSpeedChange(event: Event) {
    projectMetas.update($projectStore.projectId, {
      readingSpeed: parseFloat((event.target as HTMLInputElement).value),
    });
  }
</script>

<div class="speed-controls">
  <button class="speed-btn slow-icon icon" on:click={decreaseSpeed}>
    <Icon path={mdiTortoise} />
  </button>
  <div class="range-box">
    <div class="speed-text">{speedText}</div>
    <input
      type="range"
      class="speed"
      min="0.1"
      max="2"
      step="0.1"
      on:change={onSpeedChange}
      bind:value={currentSpeed}
    />
  </div>
  <button class="speed-btn fast-icon icon" on:click={increaseSpeed}>
    <Icon path={mdiRabbit} />
  </button>
</div>

<style>
  .speed-controls {
    display: flex;
    align-items: center;
  }
  .speed-btn {
    flex: 0 0 auto;
    font-size: 1.5rem;
    color: var(--text-color-lighterer);
  }
  .range-box {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    text-align: center;
  }
  .speed-text {
    font-weight: bold;
    margin-top: -1.125rem;
    font-size: var(--font-size-xs);
    color: var(--text-color-lighterer);
  }
  .speed {
    width: 100%;
    min-width: 0;
  }
</style>
