import { projectStore, settings } from '@dabble/app';
import { Doc, DocSettings } from '@dabble/data/types';
import { Delta, Editor, PasteEvent, deltaFromHTML, inlineToHTML, normalizeRange } from 'typewriter-editor';

// If on paste in a chapter header the content has newlines, handle import
// Import finds page breaks from Word (and maybe from Scrivener, GDocs, etc) and creates new chapters
// It finds *** and # on empty lines and creates new scenes
// It finds headers and tries to match them to chapter titles
// Handle simple multi-line titles, chapters, and entire books

const unnumberedNames = new Set(['prologue', 'epilogue']);
const chapterTitleLength = 60;
const bookPartExp =
  /[\n\f]*^(part|chapter|prologue|epilogue)(?: [-a-z0-9]+)? *(?:(?:\n|: *)([^\n]{1,60}?))? *$[\n\f][\n\f ]*|\n* *(#+|\*+) *\n[\n ]*/gim;
const titleExp = /^((?:part|chapter) [-a-z0-9]+ *)(?:(\n *|: *)([^\n]{1,60}?))? *[\n\f][\n\f ]*/i;

// Pasting a multi-line string into `subtitle` or `author` will just paste the first line
export function onHeaderPaste(event: PasteEvent) {
  const { delta } = event;
  const text = deltaToText(delta);
  if (!text.includes('\n')) return;

  const newlineIndex = text.indexOf('\n');
  event.delta = delta.slice(0, newlineIndex);
}

export function onChapterTitlePaste(event: PasteEvent, doc: Doc) {
  let { delta } = event;
  const sceneSettings = doc && settings.getFor('novel_scene');

  if (event.html) {
    const editor = new Editor({ types: sceneSettings.editorOptions.body.typeset });
    delta = deltaFromHTML(editor, event.html, { possiblePartial: true });
  }

  let text = deltaToText(delta);
  if (!text.includes('\n')) return;

  // Trim whitespace
  let whitespaceMatch: RegExpMatchArray;
  if ((whitespaceMatch = text.match(/^\s+/))) {
    text = text.replace(/^\s+/, '');
    delta = delta.slice(whitespaceMatch[0].length);
  }
  let newlineIndex = text.indexOf('\n');
  let sceneBody = delta;
  let remainingText = text.slice(newlineIndex + 1).trim();

  if (newlineIndex <= chapterTitleLength || !remainingText) {
    const match = text.match(titleExp);
    if (match) {
      let start = match[1].length + (match[2] ? match[2].length : 0);
      let length = match[3] ? match[3].length : 0;
      if (length && match[3].trim().length === 0) {
        start += length;
        length = 0;
      }
      event.delta = delta.slice(start, start + length);
      newlineIndex = match[0].length - 1;
      remainingText = text.slice(newlineIndex + 1).trim();
    } else {
      event.delta = delta.slice(0, newlineIndex);
    }
    sceneBody = delta.slice(newlineIndex + 1);
    text = remainingText;
  } else {
    event.delta = null;
  }

  if (text.length) {
    const firstScene = projectStore.getDocsOfType(doc, 'novel_scene', true)[0];
    if (firstScene) {
      pasteBook(firstScene, sceneBody, text);
    }
  }
  // Make sure the title is saved before a forceUpdate clears it
  Promise.resolve().then(() => projectStore.saveText());
}

export function onSceneBodyPaste(event: PasteEvent, doc: Doc, editor: Editor) {
  event = cleanPasteData(event, editor, doc);
  const { delta } = event;
  const text = deltaToText(delta);
  const selection = normalizeRange(editor.doc.selection);
  if (text.match(bookPartExp) && (!doc.body || doc.body.length - 1 === selection[1])) {
    event.delta = pasteBook(doc, delta, text, true);
  }
}

function cleanPasteData(event: PasteEvent, editor: Editor, doc: Doc) {
  const docSettings = settings.getFor(doc) as DocSettings;
  const sanitizers = Object.values(docSettings.onPasteSanitize);
  sanitizers.forEach(s => {
    event = s(event);
  });

  const text = deltaToText(event.delta);
  const html = inlineToHTML(editor, event.delta);
  event.text = text;
  event.html = html;

  return event;
}

function pasteBook(scene: Doc, delta: Delta, text: string, returnDelta?: boolean) {
  projectStore.commitQueuedTextChanges();
  scene = projectStore.getDoc(scene.id); // Be sure to have the latest body
  let match;
  let chapter: any = projectStore.getParent(scene.id); // chapter or folder
  const sceneIndex: Index = { index: chapter.children.indexOf(scene.id) };
  let part: any = chapter.type === 'novel_chapter' ? projectStore.getParent(chapter.id) : chapter; // book, part, or folder
  const chapterIndex: Index = part === chapter ? sceneIndex : { index: part.children.indexOf(chapter.id) };
  const book: any = part.type === 'novel_part' ? projectStore.getParent(part.id) : part; // book or folder
  let partIndex = part === book ? chapterIndex : { index: book.children.indexOf(part) };
  const patch = projectStore.patch();
  let pastedDelta: Delta = null;

  let lastPos = 0,
    matchStart = 0;
  let firstScene = true;

  function handleScene() {
    let body = delta.slice(lastPos, matchStart);
    let bodyText = text.slice(lastPos, matchStart);

    // Trim the front/end of the scene
    if (bodyText.trim().length !== bodyText.length) {
      const start = bodyText.length - bodyText.replace(/^[\s\n]+/, '').length;
      const end = bodyText.length - (bodyText.length - bodyText.replace(/[\s\n]+$/, '').length);
      body = body.slice(start, end);
      bodyText = bodyText.slice(start, end);
    }

    // Trim the front/end of each line
    const trimDelta = new Delta();
    let length = 0;
    bodyText.replace(/^\s+|\s+$|\s*\n\s*/g, (match, offset) => {
      if (match === '\n') return match;
      const parts = match.split('\n');
      trimDelta.retain(offset - length);
      length = offset + match.length;
      for (let i = 0; i < parts.length; i++) {
        if (i > 0) trimDelta.retain(1);
        if (parts[i].length) trimDelta.delete(parts[i].length);
      }
      return match.replace(/^\s+|\s+$/g, '');
    });
    trimDelta.chop();
    if (trimDelta.ops.length) {
      body = body.compose(trimDelta);
    }

    if (!body.ops.length) {
      return;
    }

    if (firstScene) {
      if (returnDelta) {
        pastedDelta = body;
      } else {
        patch.changeText(scene.id, 'body', body.insert('\n'));
      }
    } else {
      const id = projectStore.createDocId();
      const newScene = { id, type: 'novel_scene' };
      patch.createDoc(newScene, chapter.id, ++sceneIndex.index);
      patch.changeText(id, 'body', body.insert('\n'), true);
    }
  }

  function handleBookPart(type: string, title: string) {
    const unnumbered = unnumberedNames.has(type);
    const id = projectStore.createDocId();
    if (type === 'part') {
      part = { id, type: 'novel_part', title, children: [] };
      if (partIndex === chapterIndex) {
        partIndex = { ...chapterIndex };
      }
      chapterIndex.index = -1;
      patch.createDoc(part, book.id, ++partIndex.index, false);
    } else {
      chapter = { id, type: 'novel_chapter', title, children: [] };
      if (unnumbered) {
        chapter.unnumbered = true;
      }
      sceneIndex.index = -1;
      patch.createDoc(chapter, part.id, ++chapterIndex.index, false);
    }
  }

  while ((match = bookPartExp.exec(text))) {
    const [all, type, titleMatch, sceneBreak] = match;
    const matchEnd = bookPartExp.lastIndex;
    matchStart = matchEnd - all.length;
    let title = titleMatch && titleMatch.trim().length === 0 ? undefined : titleMatch;
    if (type && !title && unnumberedNames.has(type.toLowerCase())) {
      title = type; // e.g. Prologue
    }

    handleScene();
    if (type) handleBookPart(type.toLowerCase(), title);
    lastPos = matchEnd;
    firstScene = false;
  }

  matchStart = text.length;
  handleScene();

  patch.save().then(() => {
    projectStore.forceTextUpdate();
  });

  return pastedDelta;
}

function deltaToText(delta: Delta) {
  return delta.ops
    .map(op => {
      if (typeof op.insert === 'string') return op.insert;
      if (!op.insert) return '';
      if ('pageBreak' in op.insert) return '\n';
      return ' ';
    })
    .join('')
    .replace(/\n$/, '');
}

interface Index {
  index: number;
}
