<script>
  import {
    alert,
    connectionSendAfterAuthed,
    inform,
    plugins,
    projectMetas,
    router,
    t,
    userProjects,
  } from '@dabble/app';
  import Modal from '@dabble/toolkit/Modal.svelte';

  const { currentUser } = plugins.stores;

  if (sessionStorage.importTemplate) {
    loadTemplate();
  }

  let template;
  let waitingForLogin;

  // If waitingForLogin is true and currentUser changes, set it to false to open the modal again
  $: if ($currentUser) onCurrentUser();

  function onCurrentUser() {
    if (waitingForLogin) {
      waitingForLogin = false;
    }
  }

  async function loadTemplate() {
    try {
      const beforeVerified = await connectionSendAfterAuthed('getProject', sessionStorage.importTemplate);

      if (beforeVerified.isTemplate) {
        template = beforeVerified;
        return;
      }
      throw new Error('not_shared');
    } catch (err) {
      //clearTemplate();
      console.log(err);
      const errorKey = `template_${err.message}`;
      const errorMsg = $t(errorKey) === errorKey ? err.message : $t(errorKey);
      await alert($t('error'), errorMsg);
      if (!$currentUser) {
        //location.href = 'https://www.dabblewriter.com/';
      }
      return;
    }
  }

  function loginScreen(screen) {
    router.navigate(`/auth/${screen}`);
    waitingForLogin = true;
  }

  async function execute(action) {
    if (action === 'decline') {
      clearTemplate();
      await alert($t('success'), $t(`collab_success_${action}`));
      if (!$currentUser) {
        location.href = 'https://www.dabblewriter.com/';
      }
    }
    if (action === 'accept') {
      userProjects.update(template.id, {
        isTemplate: true,
        projectId: template.id,
        creator: $currentUser.uid,
      });
      const count = $projectMetas[template.id]?.templateCount ? $projectMetas[template.id].templateCount : 0;
      await projectMetas.update(template.id, {
        templateCount: count + 1,
      });
      clearTemplate();
      inform('success', 'Template successfully added.');
    } else {
      waitingForLogin = true;
    }
  }

  function clearTemplate() {
    template = null;
    delete sessionStorage.importTemplate;
  }
</script>

{#if template && !waitingForLogin}
  <Modal title={$t('template_share')} on:close={() => (template = null)}>
    <section>
      <p>
        {@html $t('template_shared', {
          templater: template.author ? template.author : 'Unknown',
          title: template.title,
        })}
      </p>
      {#if $currentUser}
        <p>{@html $t('template_accept', { email: $currentUser.email })}</p>
      {:else}
        <p>{@html $t('template_signin_first_message')}</p>
      {/if}
      <footer>
        <button class="btn secondary" on:click={() => execute('decline')}>{$t('collab_decline')}</button>
        {#if $currentUser}
          <button class="btn secondary" on:click={() => loginScreen('signin')}>{$t('account_login_another')}</button>
          <button class="btn primary" on:click={() => execute('accept')}>{$t('collab_accept')}</button>
        {:else}
          <button class="btn primary" on:click={() => loginScreen('signup')}>{$t('account_signup')}</button>
          <button class="btn primary" on:click={() => loginScreen('signin')}>{$t('account_login')}</button>
        {/if}
      </footer>
    </section>
  </Modal>
{/if}
