import { authed, observe, plugins, projectMetas, projectStore, readonly, settings } from '@dabble/app';
import { mdiImageText } from '@mdi/js';
import AcceptSharedTemplate from './components/AcceptSharedTemplate.svelte';
import CardPopup from './components/CardPopup.svelte';
import Counts from './components/Counts.svelte';
import FileView from './components/FileView.svelte';
import FolderView from './components/FolderView.svelte';
import ProfileFolderView from './components/ProfileFolderView.svelte';
import ProfileView from './components/ProfileView.svelte';
import ProjectView from './components/ProjectView.svelte';
import './locales';

settings.configure('project', {
  view: {
    component: ProjectView,
    searchableFields: ['title'],
  },
});
settings.configure('folder', {
  view: {
    component: FolderView,
    searchableFields: ['title'],
  },
});
settings.configure('file', {
  view: {
    component: FileView,
    searchableFields: ['title', 'body'],
  },
});
settings.configure('profile_folder', {
  view: {
    component: ProfileFolderView,
    searchableFields: ['title'],
  },
});
settings.configure('profile', {
  icon: mdiImageText,
  view: {
    component: ProfileView,
    searchableFields: ['title', 'description', 'body'],
  },
});
settings.configure('dabble', {
  globals: { CardPopup, AcceptSharedTemplate },
  statusBarRight: { Counts },
});

// lock and unlock uneditable templates
observe([projectStore, projectMetas], ([projectStore, projectMetas]) => {
  readonly.removeLock('template');
  const id = projectStore.projectId;
  const projectMeta = projectMetas[id];
  const uid = plugins.stores.uid.get();
  const canEdit = authed.get() && projectMeta?.roles[uid];
  if (!canEdit && projectMeta?.isTemplate) readonly.addLock('template');
});
