<script>
  import { isOnline, t } from '@dabble/app';
  import { createImageInfo, saveImage } from '@dabble/plugins/content/images';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiImage, mdiImageSearch } from '@mdi/js';
  import { getUnsplashImage } from './UnsplashSearchDialog.svelte';

  export let editor;
  let file;

  export async function addImage(event) {
    const images = Array.from(event.target.files).filter(file => file.type.startsWith('image/'));

    await Promise.all(
      images.map(async file => {
        const info = await createImageInfo({ file });
        const url = await saveImage({ file }, info);

        editor.insert('\n', { image: url, ...info });
      })
    );
  }

  async function getFromUnsplash() {
    const info = await getUnsplashImage();
    if (info) {
      const { url, ...attr } = info;
      attr.image = url;
      editor.insert('\n', attr);
    }
  }
</script>

<button
  use:tooltipTop={$t('editor_upload_image')}
  class="menu-button icon button"
  on:click={() => {
    file.click();
  }}
>
  <Icon path={mdiImage} />
  <input style="display:none" type="file" on:change={addImage} bind:this={file} />
</button>

<button
  use:tooltipTop={$t('editor_unsplash_image')}
  class="menu-button icon"
  on:click={getFromUnsplash}
  disabled={!$isOnline}
>
  <Icon path={mdiImageSearch} />
</button>
