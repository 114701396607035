import { currentProjectMeta, features, locale, plugins, readonly } from '@dabble/app';
import { derived } from '@dabble/data/stores/store';
import { createAllowedChecksStore, createGrammarSettingsStore, createSpellingLanguageStore } from './grammar-stores';

export const allowedChecks = createAllowedChecksStore(currentProjectMeta, features, readonly);
export const shouldCheckStore = derived(allowedChecks, ({ grammar, spelling }) => grammar || spelling);
export const spellingLanguageStore = createSpellingLanguageStore(currentProjectMeta, locale);
export const grammarSettingsStore = createGrammarSettingsStore(allowedChecks, spellingLanguageStore);

plugins.register({ allowedChecks, shouldCheckStore, spellingLanguageStore, grammarSettingsStore });
