import { Editor, ShortcutEvent } from 'typewriter-editor';

interface NavigationOptions {
  container: string;
  header?: string;
}

interface Rules {
  header?: boolean; // Only applies to headers
  isCollapsed?: boolean; // Only applies if the selection is collapsed
  atStart?: boolean; // Applies when the selection is at the start of the line
  atEnd?: boolean; // Applies when the selection is at the end of the line
  boundary?: 'top' | 'bottom'; // Applies when the selection is at the top or bottom boundary
}

interface ShortcutRules {
  [shortcut: string]: Rules;
}

const VALID_SHORTCUTS: ShortcutRules = {
  ArrowRight: { isCollapsed: true, atEnd: true },
  Enter: { isCollapsed: true, header: true, atEnd: true },
  ArrowDown: { boundary: 'bottom' },
  'Cmd+ArrowDown': { boundary: 'bottom' },
  ArrowLeft: { isCollapsed: true, atStart: true },
  Backspace: { isCollapsed: true, atStart: true },
  ArrowUp: { boundary: 'top' },
  'Cmd+ArrowUp': { boundary: 'top' },
};

/**
 * Moves the cursor from one editable field to the next/previous by arrow keys and Enter/Backspace.
 */
export default function navigation(options: NavigationOptions) {
  const { container, header } = options;
  // const marker = document.createElement('div');

  return (editor: Editor) => {
    if (!container) return;

    function onShortcut(event: ShortcutEvent) {
      if (event.defaultPrevented || !editor.doc.selection) return;
      const { root, typeset } = editor;
      const { shortcut } = event;
      const rules = VALID_SHORTCUTS[shortcut];
      if (!rules) return;
      const [from, to] = editor.doc.selection;
      const isCollapsed = from === to;
      const atStart = isCollapsed && from === 0;
      const atEnd = isCollapsed && from === editor.doc.length - 1;
      const check = {
        top: () => {
          const at = Math.min(from, to);
          const bounds = editor.getBounds([at, at]);
          return boundsEmpty(bounds) || bounds.top < editor.getBounds([0, 0]).bottom;
        },
        bottom: () => {
          const at = Math.max(from, to);
          const end = editor.doc.length - 1;
          const bounds = editor.getBounds([at, at]);
          return boundsEmpty(bounds) || bounds.bottom > editor.getBounds([end, end]).top;
        },
      };
      const atBoundary = rules.atStart || rules.atEnd;

      // TODO remove this once a schema is implemented
      if (shortcut === 'Enter' && header) {
        event.preventDefault();
      }

      if (rules.isCollapsed && !isCollapsed) return;
      if (rules.header && !header) return;
      if (rules.atStart && !atStart) return;
      if (rules.atEnd && !atEnd) return;
      if (!atBoundary && !check[rules.boundary]()) return;

      // Allow the line to be converted to a paragraph by backspacing
      if (atStart && shortcut === 'Backspace') {
        const line = typeset.lines.findByAttributes(editor.doc.getLineFormat(0), true);
        if (line !== typeset.lines.default) return;
      }

      // We are at at the start, end, or top/bottom boundary and need to move to another text editor
      const containerElement = root.closest(container);
      if (!containerElement) return;
      const roots = Array.from(containerElement.querySelectorAll('.typewriter-editor')) as any[];
      const rootIndex = roots.indexOf(root);
      const nextIndex = rootIndex + (rules.boundary === 'top' || rules.atStart ? -1 : 1);
      if (rootIndex === -1 || nextIndex === roots.length || nextIndex === -1) return;

      event.preventDefault();
      const nextEditor = roots[nextIndex].editor as Editor;
      if (rules.atStart) {
        nextEditor.select(nextEditor.doc.length - 1);
      } else if (rules.atEnd) {
        nextEditor.select(0);
      } else {
        const selStart = rules.boundary === 'top' ? nextEditor.doc.length - 1 : 0;
        const notAtStart = rules.boundary !== 'top';
        const index = notAtStart ? 0 : nextEditor.doc.length - 1;
        const nextBounds = nextEditor.getBounds([index, index]);
        const min = Math.min(from, to);
        const bounds = editor.getBounds([min, min]);
        if (boundsEmpty(nextBounds) || boundsEmpty(bounds)) {
          nextEditor.select([selStart, selStart]);
        } else {
          const x = bounds.left;
          const y = notAtStart ? nextBounds.top + nextBounds.height / 2 : nextBounds.bottom - nextBounds.height / 2;
          const index = nextEditor.getIndexFromPoint(x, y);

          // containerElement.appendChild(marker);
          // let offset = containerElement.getBoundingClientRect();
          // marker.innerHTML = `<div style="position:absolute;left:${x - offset.left}px;` +
          //   `top:${y - offset.top}px;width:2px;height:10px;background:blue;pointer-events:none"></div>`;

          nextEditor.select(index || 0);
        }
      }
    }

    return {
      init() {
        editor.root.addEventListener('shortcut', onShortcut);
      },
      destroy() {
        editor.root.removeEventListener('shortcut', onShortcut);
      },
    };
  };
}

function boundsEmpty(bounds: DOMRect) {
  return !bounds || (bounds.left === 0 && bounds.height === 0);
}
