<script>
  import { projectStore, t } from '@dabble/app';
  import { createImageInfo, saveImage } from '@dabble/plugins/content/images';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { mdiImage } from '@mdi/js';
  import Dropzone from 'svelte-file-dropzone/src/components/Dropzone.svelte';
  import { titleImageDocId } from '../title-image-menu-item';

  $: doc = $titleImageDocId;

  function close() {
    $titleImageDocId = '';
  }

  async function handleImageDrop(e) {
    const { acceptedFiles } = e.detail;
    if (acceptedFiles.length === 1) {
      const info = await createImageInfo({ file: acceptedFiles[0] });
      const url = await saveImage({ file: acceptedFiles[0] }, info);
      projectStore.updateDoc(doc, { image: { image: url, ...info } });

      close();
    } else {
      await confirm($t('novel_image_unsupported'), $t('novel_image_unsupported_message'));
    }
  }
</script>

{#if $titleImageDocId}
  <Modal title={'Set Title Image'} on:close={close}>
    <section>
      <div class="content-container">
        <p>{$t('novel_image_drop_dialog_main_text')}</p>
        <p>{$t('novel_image_drop_dialog_sub_text')}</p>
      </div>
      <div class="dropzone-container">
        <Dropzone on:drop={handleImageDrop} accept={['.png', '.jpeg', 'jpg', '.gif', '.webp', '.tiff']}>
          <div class="dropzone-text">
            <div class="cover-icon">
              <Icon path={mdiImage} />
            </div>
            <p class="drop-modal-text">{$t('novel_modal_drop_drag_text')}</p>
            <h3 class="or-line"><span>{$t('novel_modal_drop_or_divider')}</span></h3>
            <button class="browse-button btn primary">{$t('novel_modal_drop_browse_button')}</button>
          </div>
        </Dropzone>
      </div>
    </section>
  </Modal>
{/if}

<style>
  section {
    display: flex;
    justify-content: space-between;
  }
  .content-container {
    width: 60%;
    padding-right: 16px;
    box-sizing: border-box;
  }

  .dropzone-text {
    display: flex;
    flex-direction: column;
  }
  .cover-icon {
    font-size: 45px;
    text-align: center;
  }
  .drop-modal-text {
    text-align: center;
  }
  .browse-button {
    margin: 30px auto 10px auto;
    width: 180px;
    padding: 14px;
    border: none;
    border-radius: 10px;
    display: block;
  }
  .browse-button:hover {
    cursor: pointer;
    transition: background-color 0.2s;
  }
  .or-line {
    text-align: center;
    border-bottom: 1px solid var(--grey-light-lighty);
    line-height: 0.1em;
    margin: auto;
    padding-top: 4px;
  }
  .or-line span {
    background: var(--white);
    padding: 0 10px;
    font-size: 22px;
  }
</style>
