<script>
  import Route from '@dabble/toolkit/Route.svelte';
  import Billing from './Billing.svelte';
  import Checkout from './Checkout.svelte';
  import ExitSurvey from './ExitSurvey.svelte';
</script>

<Route path="/account/billing" component={Billing} />
<Route path="/account/billing/cancel" component={ExitSurvey} />
<Route path="/checkout/*" component={Checkout} />
