<script lang="ts">
  import { projectMetas, projectStore } from '@dabble/app';
  import { t } from '@dabble/data/intl';
  import { readingVoice, voices } from '../reading';

  $: currentVoice = $readingVoice?.name;

  function onVoiceChange(event: Event) {
    const readingVoice = (event.target as HTMLSelectElement).value;
    projectMetas.update($projectStore.projectId, { readingVoice });
  }
</script>

<select class="form-control" id="spellingVoices" on:change={onVoiceChange} value={currentVoice}>
  <option value="">{$t('preferences_select_voice')}</option>
  {#each $voices as voice}
    <option value={voice.name}>{voice.name}</option>
  {/each}
</select>
