<script>
  import { size, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { slide } from '@dabble/toolkit/transitions';
  import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
  import { checklist, onboardingAvailable, order, showOnboardingLearnMore } from '../checklist';
  import Checklist from './Checklist.svelte';

  let open = true;

  $: isMobile = $size !== 'desktop';
  $: count = order.reduce((total, o) => ($checklist[o] ? total + 1 : total), 0);
  $: openIcon = open ? (isMobile ? mdiChevronUp : mdiChevronDown) : isMobile ? mdiChevronDown : mdiChevronUp;

  function toggleChecklist() {
    open = !open;
  }

  function dismissChecklist() {
    checklist.update({ hide: true });
  }

  function learnMore() {
    $showOnboardingLearnMore = true;
  }
</script>

{#if $onboardingAvailable && !$checklist.hide}
  <div id="onboarding-checklist" class:isMobile>
    <div class="checklist-container">
      <div class="header" on:click={toggleChecklist} on:keydown={toggleChecklist}>
        <div class="icon">
          <Icon path={openIcon} inline />
        </div>
        <div class="title">{$t('onboarding_getting_started')}</div>
        <div class="count">{count}/{order.length}</div>
      </div>

      {#if open}
        <div class="list" transition:slide|local={{ duration: 200 }}>
          <Checklist />

          <div class="buttons">
            <button on:click={learnMore} class="btn link">{$t('onboarding_learn_more')}</button>
            <button on:click={dismissChecklist} class="btn link">{$t('onboarding_dismiss')}</button>
          </div>
        </div>
      {/if}
    </div>
  </div>
{/if}

<style>
  #onboarding-checklist {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
    background: white;
    padding: 3px 1rem;
    border-radius: 5px 5px 0 0;
    box-shadow: var(--modal-shadow);
    /* border: 1px solid var(--brand-primary); */
    margin-left: 10px;
    cursor: pointer;
  }

  #onboarding-checklist.isMobile {
    top: var(--app-header-height);
    left: 50%;
    margin-left: -125px;
    bottom: auto;
    border-radius: 0px 0px 5px 5px;
  }

  .checklist-container .header {
    padding: 0.5rem 0rem;
    display: flex;
    font-weight: 500;
    text-transform: uppercase;
  }

  .list {
    padding-top: 4px;
    border-top: 1px solid var(--dabble-gray);
  }

  #onboarding-checklist.isMobile .header {
    border-bottom-width: 0px;
  }

  .checklist-container .header {
    padding: 1rem auto;
  }

  .checklist-container .icon {
    margin-right: 0.5rem;
    color: var(--text-color-lighter);
    font-size: 20px;
    font-weight: bold;
  }
  .checklist-container .title {
    flex-grow: 1;
  }

  .buttons {
    border-top: 1px solid var(--dabble-gray);
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .buttons button {
    cursor: pointer;
  }
</style>
