import { Subscriber, Unsubscriber } from '@dabble/data/stores/store';
import * as Sentry from '@sentry/browser';
import { SyncProcess, SyncStep } from './types';

export function createSyncProcess(steps: Array<SyncStep | SyncStep[]>): SyncProcess {
  const stops: Unsubscriber[] = [];
  let stopped = true;
  const errorListeners: Subscriber<Error>[] = [];

  async function start() {
    if (stopped) {
      stopped = false;
      for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        try {
          if (Array.isArray(step)) {
            await Promise.all(
              step.map(async step => {
                await step.sync().catch(e => {
                  Sentry.captureException(e, {
                    tags: {
                      sync: step.name,
                    },
                  });
                  return Promise.reject(e);
                });
                if (!stopped) stops.push(step.start(reportError));
              })
            );
          } else {
            await step.sync().catch(e => {
              Sentry.captureException(e, {
                tags: {
                  sync: step.name,
                },
              });
              return Promise.reject(e);
            });
            if (!stopped) stops.push(step.start(reportError));
          }
        } catch (err) {
          stop();
          reportError(err);
        }
      }
    }
  }

  function stop() {
    stops.forEach(stop => stop());
    stops.length = 0;
    stopped = true;
  }

  function reportError(error: Error) {
    errorListeners.forEach(l => l(error));
  }

  function onError(listener: Subscriber<Error>) {
    errorListeners.push(listener);
    return () => {
      const index = errorListeners.indexOf(listener);
      if (index !== -1) errorListeners.splice(index, 1);
    };
  }

  function isSyncing() {
    return !stopped;
  }

  return {
    start,
    stop,
    isSyncing,
    reportError,
    onError,
  };
}
