import { $t, projectStore, settings, viewport } from '@dabble/app';
import { mdiFileBanner } from '@dabble/toolkit/custom-icons';
import { mdiFolder, mdiImage, mdiNote } from '@mdi/js';

settings.configure('novel_folder', {
  hasChildren: true,
  openByDefault: true,
  icon: mdiFolder,
  placeholders: {
    title: 'folder_untitled',
  },
  menuTypes: ['novel_folder', 'novel_page', 'note_image'],
  requiresFeature: 'story_notes',
  convertToTemplate: true,
  findSettings: {
    childrenShouldBeSearched: true,
  },
});

settings.configure('novel_page', {
  icon: mdiNote,
  placeholders: {
    title: 'novel_page_untitled',
  },
  menuItems: {
    novel_page: [
      {
        icon: mdiFileBanner,
        iconOverlay: doc => ('bannerUrl' in doc ? 'remove' : 'add'),
        key: doc => $t('bannerUrl' in doc ? 'remove_banner' : 'add_banner'),
        action: async doc => {
          await projectStore.updateDoc(doc.id, { bannerUrl: 'bannerUrl' in doc ? null : '' });
          viewport.scrollTop.set(0);
        },
        section: 'rename',
      },
    ],
  },
  mentionsMenu: true,
  requiresFeature: 'story_notes',
  convertToTemplate: true,
});

settings.configure('note_image', {
  icon: mdiImage,
  placeholders: {
    title: 'novel_image',
  },
});
