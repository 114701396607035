import { plugins } from '@dabble/app';
import Tooltip from '@dabble/toolkit/TooltipComponent.svelte';
import { Editor } from 'typewriter-editor';

let currentTooltip: Tooltip;
let timeout: any;

export default function authorTooltip() {
  const { showCoauthors } = plugins.stores;

  return (editor: Editor) => {
    let tooltip: Tooltip;

    function onMouseOver(event: MouseEvent) {
      if (!showCoauthors.get()) return;
      const target = event.target as HTMLElement;
      const formatAuthor = target.closest('format-author');

      if (formatAuthor) {
        const authorUid = formatAuthor.getAttribute('data-uid');
        const uid = plugins.stores.currentUser.get().uid;

        if (authorUid === uid) return;

        const authorName = formatAuthor.getAttribute('data-name');
        if (authorName) {
          createTooltip(formatAuthor, authorName);
        }
      }
    }

    function createTooltip(node: Node, authorName: string) {
      clearTimeout(timeout);

      if (!currentTooltip) {
        tooltip = currentTooltip = new Tooltip({
          target: document.body,
          props: { target: node, html: authorName, placement: 'top' },
        });
        tooltip.removeTooltip = removeTooltip;
      }
    }

    function queueRemoveTooltip(event: MouseEvent) {
      const relatedTarget = event.relatedTarget as HTMLElement;
      const closest = relatedTarget?.closest('format-author');

      if (!closest) {
        tooltip = null;
        timeout = setTimeout(removeTooltip, 10);
      }
    }

    function removeTooltip() {
      if (currentTooltip) {
        currentTooltip.$destroy();
        currentTooltip = null;
        tooltip = null;
      }
    }

    return {
      init() {
        editor.root.addEventListener('mouseover', onMouseOver);
        editor.root.addEventListener('mouseout', queueRemoveTooltip);
      },
      destroy() {
        editor.root.removeEventListener('mouseover', onMouseOver);
        editor.root.removeEventListener('mouseout', queueRemoveTooltip);
      },
    };
  };
}
