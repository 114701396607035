import { settings } from '@dabble/app';
import CoverDropDialog from './components/CoverDropDialog.svelte';
import JoinSceneDialog from './components/JoinSceneDialog.svelte';
import NovelSettings from './components/NovelSettings.svelte';
import NovelToolButtons from './components/NovelToolButtons.svelte';
import PlotSidebar from './components/PlotSidebar.svelte';
import TitleImageDropDialog from './components/TitleImageDropDialog.svelte';
import './locales';
import './model';
import './model/grid-builder';
import './model/grid-updater';
import './styles.css';
import './update-grid';
import './update-project-title';
import './view';

settings.configure('dabble', {
  globals: {
    JoinSceneDialog,
    CoverDropDialog,
    TitleImageDropDialog,
  },
});

settings.configure('novel', {
  sidebar: {
    novel_notes: { PlotSidebar },
  },
  sidebarButtons: { NovelToolButtons },
  projectSettings: { NovelSettings },
});
