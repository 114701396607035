import { observe } from '../observe';
import { Doc } from '../types';
import { ProjectStore } from './project';
import { Readable, writable } from './store';

export type DocStore = Readable<Doc>;

export function createDocStore(projectStore: ProjectStore, currentDocId: Readable<string>): DocStore {
  let docId: string = null;
  let doc: Doc = null;

  const { get, set, subscribe } = writable(doc, () => observe([projectStore, currentDocId], update));

  function update() {
    docId = currentDocId.get();
    const docs = projectStore.get().docs;
    if (!docs.project || !docId) doc = null;
    else if (docId === docs.project.id) doc = docs.project;
    else doc = docs[docId] || null;
    set(doc);
  }

  return {
    get,
    subscribe,
  };
}
