import { $t, getTitle, plugins, projectMetas, projectStore } from '@dabble/app';
import rest from '@dabble/data/rest';
import { writable } from '@dabble/data/stores/store';
import { Doc, ShareData } from '@dabble/data/types';
import { uuid } from '@dabble/data/uuid';
import { getUnixTime } from 'date-fns';
import { docToHTML } from 'typewriter-editor';
import { Counts, ExportData } from './types';
import { editor, getDocName } from './utils';

export const sharedModalOpen = writable('');
export const emailShared = writable('');

const CONTENT_URL = '.';
let author = '';

interface OutlineItems {
  docId: string;
  title: string;
  level: string[1];
}
let outline = [] as OutlineItems[];

interface ExportDocument {
  counts: Counts;
}

interface TypeExporter {
  (document: ExportDocument, doc: Doc, firstDoc?: boolean, index?: number): string;
}

interface TypeExporters {
  [type: string]: TypeExporter;
}

const novelTypes: TypeExporters = {
  novel_book,
  novel_part,
  novel_chapter,
  novel_scene,
  novel_image,
};

export async function downloadHtml(data: ExportData) {
  outline = [];
  const html = await createHtmlDoc(data);
  const blob = new Blob([html], { type: 'text/html;charset=utf-8' });
  return blob;
}

export async function shareToWeb(data: ExportData) {
  const { doc } = data;
  outline = [];
  const html = await createHtmlDoc(data);
  const filename = uuid(21);

  // upload to hosting
  const response = await rest.UPLOAD(
    `share/${filename}`,
    'text/html;charset=utf-8',
    new Blob([html], { type: 'text/html' })
  );

  if (!response) {
    throw new Error($t('novel_export_failed'));
  }

  // save to database
  const newShareData = {
    id: filename,
    created: getUnixTime(new Date()),
    expires: null,
    type: 'static',
    docId: doc.id,
    docType: doc.type,
    title: getTitle(doc),
  } as ShareData;

  const projectId = projectStore.get().projectId;
  const shared = projectMetas.get()[projectId].shared || {};
  const newShared = { ...shared, [filename]: newShareData };
  await projectMetas.update(projectId, { shared: newShared });

  sharedModalOpen.set(response.url);
}

async function createHtmlDoc({ counts, doc }: ExportData) {
  const description = '';
  author = getAuthor(doc);
  const title = doc.title || getDocName(counts, doc);
  const body = processDoc({ counts }, doc, true);

  return (await setupPage(title, description)) + body + endPage();
}

function getAuthor(doc: any) {
  let parent = doc as any;
  while (parent && parent.type != 'novel_book') {
    parent = projectStore.getParent(parent.id);
  }
  author = parent?.author || plugins.stores.currentUser.get().name; // getTitle(parent, 'author');
  return author;
}

function processDoc(document: ExportDocument, item: Doc, firstDoc: boolean, index?: number) {
  let html = '';
  const processor = novelTypes[item.type];
  if (processor) html += processor(document, item, firstDoc, index);
  return html;
}

function processChildren(document: ExportDocument, item: Doc) {
  let html = '';
  const children = projectStore.getChildren(item.id);
  if (children) children.forEach((child, index) => (html += processDoc(document, child, false, index)));
  return html;
}

async function setupPage(title: string, description: string) {
  const fonts = {
    family: 'generic',
    size: 12,
    sceneSpacing: 'double',
    sceneStyle: 'book',
    notesSpacing: 'half',
    notesStyle: 'modern',
  } as any;

  const preferences = (await projectMetas.get()[projectStore.get().projectId].font) as any;

  for (const i in preferences) {
    fonts[i] = preferences[i];
  }

  const jsonOutline = `const jsonOutline = '${JSON.stringify(outline).replace(/'/g, "\\'")}';`;

  const styles = [];
  const keys = Object.keys(fonts);
  for (const i in keys) {
    const key = keys[i];
    styles.push(`${key}-${fonts[key]}`);
  }

  let html = '<!DOCTYPE html><html>';

  html +=
    '<head><meta charset="utf-8"/>' +
    '<meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"/>' +
    `<link rel="stylesheet" href="${CONTENT_URL}/css/share2web.css"/>` +
    `<script>${jsonOutline} const author = "${author}"; const version = 1;</script>` +
    `<title> ${title} </title>` +
    `<meta name="description" content = "${description}" / > <meta name="robots" content = "noindex,nofollow"/> <meta name="generator" content = "Dabble Writer"/>` +
    `<link rel="icon" type = "image/png" href = "${CONTENT_URL}/images/favicon.png" >`;

  html +=
    `</head><body class="${styles.join(' ')}">` +
    `<header id="header"><a href="https://www.dabblewriter.com"><img src="${CONTENT_URL}/images/logo.png" class="logo" alt="Dabble"/></a></header><div class="main">`;

  return html;
}

function endPage() {
  const html =
    '</div><footer>' +
    `<div id="copyright">Copyright &copy; ${new Date().getFullYear()} ${author} All Rights Reserved</div>` +
    `<div id="credits">created with <a href="https://www.dabblewriter.com"><img src="${CONTENT_URL}/images/logo.png" class="logo" alt="Dabble"/></a></div>` +
    `</footer><script src="${CONTENT_URL}/js/share2web.js"></script></body></html>`;

  return html;
}

function novel_book(document: ExportDocument, book: Doc) {
  outline.push({
    docId: book.id,
    title: book.title?.replace(/\n/, ' ') || getDocName(document.counts, book),
    level: 'b',
  });
  let html = '';
  if (book.image) {
    html += `<div class="page cover-image"><img src="${book.image.url}" alt="${book.title || getDocName(document.counts, book)
      }"/></div>`;
  }
  html += `<div id="${book.id}" class="page book-view title-page"><h1>${book.title || getDocName(document.counts, book)
    }</h1>`;
  if (book.subtitle) {
    html += `<h2>${book.subtitle}</h2>`;
  }
  html += `<div class="author"><div class="info">${$t('project_by')}</div><h3>${getTitle(
    book,
    'author'
  )}</h3></div></div>`;
  return html + processChildren(document, book);
}

function novel_part(document: ExportDocument, part: Doc, firstDoc: boolean) {
  const placeholder = part.title || getDocName(document.counts, part);
  outline.push({ docId: part.id, title: part.title?.replace(/\n/, ' ') || placeholder, level: 'p' });
  let html = '';
  if (firstDoc && author) html += `<div class="author">${$t('project_by')} ${author}</div>`;
  html += `<div id="${part.id}" class="page part-view title-page">`;
  if (part.title) {
    html += `<h1><div class="number">${placeholder}</div>`;
    html += `<div class="title">${part.title}</div>`;
  } else {
    html += `<div class="title">${placeholder}</div>`;
  }
  html += '</h1>';
  if (part.image) {
    html += `<div class="title-image"><img src="${part.image.image}" alt=""/></div>`;
  }
  html += '</div>';
  return html + processChildren(document, part);
}

function novel_chapter(document: ExportDocument, chapter: Doc, firstDoc: boolean) {
  const placeholder = !chapter.unnumbered && (getDocName(document.counts, chapter) || '');
  outline.push({ docId: chapter.id, title: chapter.title?.replace(/\n/, ' ') || placeholder, level: 'c' });

  let html = '';
  if (firstDoc && author) html += `<div class="author">${$t('project_by')} ${author}</div>`;
  html += `<div id="${chapter.id}" class="page chapter-view">`;
  if (chapter.image) {
    html += `<div class="title-image"><img src="${chapter.image.image}" alt=""/></div>`;
  }

  html += '<h1>';
  if (chapter.unnumbered) {
    html += `<div class="title">${chapter.title || '—'}</div>`;
  } else {
    if (chapter.title) {
      html += `<div class="number">${placeholder}</div>`;
      html += `<div class="title">${chapter.title}</div>`;
    } else {
      html += `<div class="title">${placeholder}</div>`;
    }
  }
  html += '</h1>';
  html += processChildren(document, chapter);
  html += '</div>';
  return html;
}

function novel_scene(document: ExportDocument, scene: Doc, firstDoc: boolean) {
  outline.push({
    docId: scene.id,
    title: scene.title?.replace(/\n/, ' ') || getDocName(document.counts, scene),
    level: 's',
  });
  let html = '';
  if (firstDoc && author) html += `<div class="author">${$t('project_by')} ${author}</div>`;
  html += `<div id="${scene.id}" class="${firstDoc ? 'page ' : ''}scene-view">`;
  if (scene.body) html += docToHTML(editor, scene.body);
  html += '</div>';
  return html;
}

function novel_image(document: ExportDocument, image: Doc, firstDoc: boolean) {
  outline.push({ docId: image.id, title: image.title?.replace(/\n/, ' '), level: 'i' });
  const inChapter = projectStore.getParent(image.id)?.type === 'novel_chapter';
  let html = '';
  html += `<div class="${firstDoc || !inChapter ? 'page ' : ''}image-view"> `;
  if (firstDoc && author) html += `<div class="author"> ${$t('project_by')} ${author} </div>`;
  html += `<img src="${image.image.url}" alt=""/></div>`;
  return html;
}
