<script>
  import { plugins, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiArrowRightBoldCircle, mdiArrowUpBoldCircle, mdiCreditCard } from '@mdi/js';

  const billing = plugins.stores.billing;
  $: sub = $billing && $billing.subscription;
  $: payment = $billing && $billing.payment;
  $: plan = sub && sub.plan && sub.plan.nickname;
  $: status = sub && sub.status;
  $: subscribed = status === 'active' || status === 'past_due';
  $: canUpgrade = subscribed && plan && (plan.includes('Basic') || plan.includes('Standard'));
  $: canSubscribe = !subscribed && (status !== 'trialing' || payment || plan.includes('Lifetime'));
</script>

<a class="dropdown-item" href="/account/billing">
  <Icon path={mdiCreditCard} />
  {$t('account_screen_billing')}
</a>
{#if canUpgrade || canSubscribe}
  <a class="dropdown-item inactive" href="/checkout/plans">
    <button class="small btn primary"
      ><Icon path={canSubscribe ? mdiArrowRightBoldCircle : mdiArrowUpBoldCircle} />
      {$t(canSubscribe ? 'billing_subscribe' : 'billing_upgrade')}</button
    >
  </a>
{/if}

<style>
  button {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
</style>
