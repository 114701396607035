import { DatabaseStore } from '../dabble-database';
import { ProjectMeta } from '../types';
import {
  createLookupFromList,
  createModifiedDataStore,
  Lookup,
  ModifiedDataStore,
  UpdateOptions,
} from './modified-data';
import { ProjectStore } from './project';
import { derived, Readable } from './store';

export type AllProjectMeta = Lookup<ProjectMeta>;

export type ProjectMetasStore = ModifiedDataStore<ProjectMeta, AllProjectMeta>;

export interface CurrentProjectMetaStore extends Readable<ProjectMeta | null> {
  update(updates: Partial<ProjectMeta>, options?: UpdateOptions): Promise<ProjectMeta>;
}

export const projectMetaFields = new Set([
  'id',
  'type',
  'title',
  'subtitle',
  'author',
  'authorInfo',
  'image',
  'font',
  'export',
  'shared',
  'migratedVersion',
  'roles',
  'created',
  'modified',
  'committed',
  'creator',
  'trackAuthorChanges',
  'spellingLanguage',
  'spellingCheck',
  'grammarCheck',
  'readingVoice',
  'readingSpeed',
  'style',
  'isTemplate',
  'templateCount',
]);

export function createProjectsMetaStore(dbStore: DatabaseStore): ProjectMetasStore {
  return createModifiedDataStore(dbStore, 'project_metas', {
    fields: projectMetaFields,
    packageData: list => createLookupFromList(list, 'id'),
    trueDelete: true,
  });
}

export function createCurrentProjectsMetaStore(
  metas: ProjectMetasStore,
  project: ProjectStore
): CurrentProjectMetaStore {
  const projectId = derived(project, project => project.projectId);
  const { get, subscribe } = derived<ProjectMeta | null, [ProjectMetasStore, Readable<string>]>(
    [metas, projectId],
    ([metas, projectId]) => metas?.[projectId] || null
  );

  function update(updates: Partial<ProjectMeta>, options?: UpdateOptions): Promise<ProjectMeta> {
    if (!projectId.get()) return Promise.reject(new Error('No project selected'));
    return metas.update(projectId.get(), updates, options);
  }

  return {
    get,
    update,
    subscribe,
  };
}
