<script>
  import { t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiDotsVertical, mdiPlusThick } from '@mdi/js';

  export let text;
  export let step;

  const original = $t(`${text}_${step}`);
  const icons = {
    mdiPlusThick,
    mdiDotsVertical,
  };

  const parts = parse(original);

  function parse(original) {
    return original.split(':');
  }
</script>

{#each parts as part}
  {#if part.startsWith('mdi')}
    <Icon path={icons[part]} inline />
  {:else}
    <span class="text">{@html part}</span>
  {/if}
{/each}
