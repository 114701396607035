import { projectStore } from '@dabble/app';
import rest from '@dabble/data/rest';

const cache = new Map();
let queue: string[] = [];
let timeout: any;

const levels = {
  UNKNOWN: 0,
  VERY_UNLIKELY: 1,
  UNLIKELY: 2,
  POSSIBLE: 3,
  LIKELY: 4,
  VERY_LIKELY: 5,
};

export function imageApproved(url: string) {
  if (cache.has(url)) {
    return cache.get(url);
  }
  addToQueue(url);
  return false;
}

interface FileMeta {
  adult: Likelihood;
  racy: Likelihood;
  medical: Likelihood;
  spoof: Likelihood;
  violence: Likelihood;
}

enum Likelihood {
  UNKNOWN = 'UNKNOWN',
  VERY_UNLIKELY = 'VERY_UNLIKELY',
  UNLIKELY = 'UNLIKELY',
  POSSIBLE = 'POSSIBLE',
  LIKELY = 'LIKELY',
  VERY_LIKELY = 'VERY_LIKELY',
}

function addToQueue(url: string) {
  queue.push(url);
  if (!timeout) {
    timeout = setTimeout(async () => {
      timeout = null;
      const urls = queue;
      queue = [];
      const metaForFiles: FileMeta[] = await rest.POST('images/info', { urls });
      metaForFiles.forEach((imageMeta, i) => {
        const dissaproved = levels[imageMeta.adult || Likelihood.UNKNOWN] >= levels.POSSIBLE;
        cache.set(urls[i], !dissaproved);
      });
      projectStore.forceTextUpdate();
    }, 10);
  }
}
