<script>
  import { currentProjectMeta, getTitle, globals, projectMetas, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiInformationOutline } from '@mdi/js';

  let renameError;
  let edits;

  window.dispatchEvent(new CustomEvent('currentscreen', { detail: { name: 'ProjectPreferences' } }));

  const defaults = {
    shortTitle: '',
    phone: '',
    address: '',
    sceneSeparator: '',
  };

  $: authorInfo = ($currentProjectMeta && { ...defaults, ...$currentProjectMeta.authorInfo }) || defaults;
  $: updateEdits(authorInfo);

  async function saveProject() {
    try {
      await projectMetas.update($currentProjectMeta.id, { authorInfo: { ...edits } });
      globals.inform('success', $t('saved'));
    } catch (err) {
      renameError = err.message;
    }
  }

  function updateEdits({ phone, address, sceneSeparator }) {
    edits = { phone, address, sceneSeparator };
  }

  function isEqual(obj1, obj2) {
    return Object.keys(obj1).every(key => obj1[key] === obj2[key]);
  }
</script>

<div class="section">
  <h2>{$t('export_settings')}</h2>
  <form class="novel-title" on:submit|preventDefault={saveProject} disabled={isEqual(edits, authorInfo)}>
    <div class="form-group">
      <label for="export-short-title">{$t('export_short_title')}</label>
      <input
        id="export-short-title"
        type="text"
        class="form-control"
        placeholder={getTitle($currentProjectMeta, 'title')}
        bind:value={edits.shortTitle}
      />
    </div>
    <div class="form-group">
      <label for="export-scene-separator">{$t('export_scene_separator')}</label>
      <input
        id="export-scene-separator"
        type="text"
        class="form-control"
        placeholder="#"
        bind:value={edits.sceneSeparator}
      />
    </div>
    <div class="form-group">
      <div class="phone-label-container">
        <label for="export-phone">{$t('export_phone')}</label>
        <span use:tooltipTop={$t('export_telephone')}><Icon path={mdiInformationOutline} inline /></span>
      </div>
      <input
        id="export-phone"
        type="text"
        class="form-control"
        placeholder={$t('export_phone')}
        bind:value={edits.phone}
      />
    </div>
    <div class="form-group">
      <label for="export-address">{$t('export_address')}</label>
      <textarea
        id="export-address"
        type="text"
        class="form-control"
        rows="2"
        placeholder={$t('export_address')}
        bind:value={edits.address}
      />
    </div>
    <button class="btn primary" type="submit" disabled={isEqual(edits, authorInfo)}>{$t('save')}</button>
  </form>
</div>
