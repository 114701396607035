import { noop } from 'svelte/internal';
import { Readable, StartStopNotifier, writable } from './store';

export interface LockableStore<T> extends Readable<T> {
  set(value: T): void;
  lock: () => void;
  unlock: () => void;
}

export function lockable<T>(value: T, start: StartStopNotifier<T> = noop): LockableStore<T> {
  let lastValue: any;
  let locked = false;

  const { get, set, subscribe } = writable<T>(value, start);

  function conditionalSet(value: T) {
    if (locked) lastValue = value;
    else set(value);
  }

  function lock() {
    locked = true;
    lastValue = get();
  }

  function unlock(reset?: boolean) {
    locked = false;
    if (!reset) set(lastValue);
    lastValue = null;
  }

  return {
    get,
    set: conditionalSet,
    lock,
    unlock,
    subscribe,
  };
}
