import { appUpdate, loadingQuoteCleared } from '@dabble/app';
import { applyUpdate, install, update } from 'offline-plugin/runtime';

export let updateApp = update;

if (location.hostname === 'localhost') {
  updateApp = () => {};
} else {
  install({
    onUpdating() {
      appUpdate.update(update => ({ ...update, state: 'downloading', location: 'web' }));
    },

    onUpdateReady() {
      appUpdate.update(update => ({ ...update, state: 'downloading', location: 'web' }));
      applyUpdate();
    },

    onUpdated() {
      appUpdate.update(update => ({ ...update, state: 'installed', location: 'web' }));
      if (!loadingQuoteCleared.get()) {
        // If the loading quote is still up, reload the page immediately to get the new version
        window.location.reload();
      }
    },

    onUpdateFailed(...args) {
      console.log('ServiceWorker Event:', 'onUpdateFailed', args);
    },
  });
}
