<script>
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiLoading } from '@mdi/js';
  import { exporting } from '../exports';

  window.addEventListener('exporting', e => {
    if (e.detail?.start > 0) {
      $exporting = true;
    } else {
      $exporting = false;
    }
  });
</script>

{#if $exporting}
  <div class="status">
    <Icon path={mdiLoading} spin inline />
    <span class="message">Exporting...</span>
  </div>
{/if}

<style>
  .status {
    display: flex;
    align-items: center;
    padding: 0 4px;
  }
  .message {
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
