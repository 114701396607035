<script>
  import { authed, dbStore, disconnect, getTitle, plugins, projectMetas, t, userProjects } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import { number } from '@dabble/toolkit/helpers';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { fade } from '@dabble/toolkit/transitions';
  import logging from '@dabble/util/log';
  import { mdiLoading } from '@mdi/js';

  const log = logging.tagColor('Data', '#36B1BF');
  const levels = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const RECOMMENDED_MIN_SPACE = 5000000; // 5 MB

  let loading;
  let error;
  let quota;
  let usage;
  let lowSpace;
  let projectId;

  $: admin = $plugins.masterUser && ($plugins.masterUser.admin || $plugins.masterUser.claims?.admin);
  $: activeProjects = $userProjects.filter(p => !p.trashed && !p.deleted);

  updateStorageData();

  async function updateStorageData() {
    if (navigator.storage?.estimate) {
      const estimate = await navigator.storage.estimate();
      lowSpace = estimate.quota - estimate.usage <= RECOMMENDED_MIN_SPACE;
      quota = estimate.quota;
      usage = estimate.usage;
    } else {
      quota = null;
      usage = null;
    }
  }

  function bytes(bytes) {
    let i = 0;
    while (bytes / 1000 > 1 && i++ < levels.length - 1) {
      bytes /= 1000;
    }
    return number(bytes, 1) + ' ' + levels[i];
  }

  async function exportData(localOnly) {
    loading = 'export';

    try {
      const { exportProjectData } = await import(/* webpackChunkName: "exportProjectData" */ './export-data');
      await exportProjectData(projectId, localOnly);
    } catch (e) {
      error = e.message + ' — contact support';
    }

    loading = '';
  }

  async function exportDatabase() {
    loading = 'export';

    try {
      const { exportDatabase } = await import(/* webpackChunkName: "exportProjectData" */ './export-data');
      await exportDatabase();
    } catch (e) {
      error = e.message + ' — contact support';
    }

    loading = '';
  }

  async function importDatabase(file) {
    if (!file) return;
    try {
      disconnect();
      const db = $dbStore;
      const data = JSON.parse(await file.text());
      const storeNames = Object.keys(data);
      for (let i = 0; i < storeNames.length; i++) {
        const name = storeNames[i];
        const values = data[name];
        const store = db.stores[name];
        if (!store) {
          console.warn('store', name, 'does not exist');
          continue;
        }
        await store.deleteAll();
        await store.putAll(values);
      }
      location.reload();
    } catch (e) {
      error = e.message + ' — contact support';
    }
  }
</script>

<div class="section data">
  {#if loading}
    <div class="loading" transition:fade><span class="text">{$t('loading')}</span> <Icon path={mdiLoading} spin /></div>
  {/if}

  <h2>{$t('data_management')}</h2>

  {#if error}
    <Alert type="danger" dismissible on:close={() => (error = null)}>
      <strong>{$t('error')}:</strong>
      {error}
    </Alert>
  {/if}

  {#if quota !== null}
    <div class="preference vertical">
      <h6>{$t('data_used_header')}</h6>
      <div class="space-available" class:danger={lowSpace}>
        <div class="space-used" style="width: {Math.min(usage / quota, 1) * 100}%" />
      </div>
      <div>{@html $t('data_used_available', { used: bytes(usage), available: bytes(quota) })}</div>
      {#if lowSpace}
        <div><strong>{$t('data_clear_space')}</strong></div>
      {/if}
    </div>
  {/if}

  <div class="preference vertical">
    <h6>{$t('data_export_header')}</h6>
    <div class="description">{$t('data_export_description')}</div>
    <div>
      <select bind:value={projectId}>
        <option value="" disabled>{$t('data_select_project')}</option>
        {#each activeProjects as userProject}
          <option value={userProject.projectId}>{getTitle($projectMetas[userProject.projectId]) || '???'}</option>
        {/each}
      </select>
    </div>
    {#if !$authed}<p><strong>{$t('data_disconnected')}</strong></p>{/if}
    <div class="buttons">
      <button type="button" class="btn primary" on:click={() => exportData(true)} disabled={!projectId || loading}
        >{$t('data_export_local')}</button
      >
      <button
        type="button"
        class="btn primary"
        on:click={() => exportData()}
        disabled={!projectId || !$authed || loading}>{$t('data_export_server')}</button
      >
      {#if loading === 'export'}
        &nbsp;<Icon path={mdiLoading} spin /> {$t('data_export_slow')}
      {/if}
    </div>
  </div>

  <div class="preference vertical">
    <h6>{$t('data_export_db_header')}</h6>
    <div class="description">{$t('data_export_db_description')}</div>
    <div class="buttons">
      <button type="button" class="btn primary" on:click={() => exportDatabase()} disabled={loading}
        >{$t('data_export_db')}</button
      >
      {#if admin}
        <button type="button" class="btn primary btn-input" disabled={loading}>
          <input
            class="input"
            type="file"
            accept=".json"
            on:input={event => importDatabase(event.target.files[0])}
            disabled={loading}
          />
          {$t('data_import_db')}
        </button>
      {/if}
      {#if loading === 'export'}
        &nbsp;<Icon path={mdiLoading} spin /> {$t('data_export_slow')}
      {/if}
    </div>
  </div>
</div>

<style>
  .section {
    order: 10;
  }
  .loading {
    color: var(--text-color-lighterer);
  }
  .space-available {
    width: 100%;
    border: 1px solid var(--dabble-blue);
    padding: 0;
    margin-bottom: 0;
    border-radius: 2px;
  }
  .space-available.danger {
    border-color: var(--red);
  }
  .space-used {
    border-right: 1px solid var(--dabble-blue);
    background: rgba(var(--dabble-blue-rgb), 0.5);
    height: 14px;
    min-width: 4px;
    /* border-radius: 2px 0 0 2px; */
  }
  .space-available.danger .space-used {
    border-right: 1px solid var(--red);
    background: var(--red);
  }
  .btn-input {
    position: relative;
  }
  .btn-input .input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 162px;
    opacity: 0;
    cursor: pointer;
  }
</style>
