import { dbStore, plugins } from '@dabble/app';
import PromiseQueue from '@dabble/data/collective/promise-queue';
import { DatabaseStore } from '@dabble/data/dabble-database';
import rest from '@dabble/data/rest';
import { locallyStoredWritable } from '@dabble/data/stores/locally-stored-writable';
import { Readable, writable } from '@dabble/data/stores/store';
import { DabbleDatabase, User } from '@dabble/data/types';
import { getImmutableValue, makeChanges } from '@dabble/util/immutable';
import { StoreChangeDetail } from 'browserbase';
import { isEqual } from 'typewriter-editor';

export interface UserPublicData {
  uid: string;
  name: string;
  email: string;
  photoUrl: string;
}

type UserGetter = (uid: string) => UserPublicData;

export interface UsersMap {
  [uid: string]: UserPublicData;
}

export const showCoauthors = locallyStoredWritable('showCoauthors', false);
const emptyInfo = { uid: '', name: '', email: '', photoUrl: '' };

plugins.register({ showCoauthors });

export const getUser = createUsersStore(dbStore, plugins.stores.currentUser);

plugins.register({ getUser });

const colorCache = new Map<string, string>();
let count = 1;
const uidStore = plugins.stores.uid;

export function getAuthorColor(uid: string) {
  if (uidStore.get() === uid) {
    return 'author-0';
  }
  let color = colorCache.get(uid);
  if (!color) {
    color = `author-${count++}`;
    colorCache.set(uid, color);
  }
  return color;
}

export function createUsersStore(dbStore: DatabaseStore, currentUser: Readable<User>) {
  let db: DabbleDatabase;
  let users: UsersMap = {};
  let timeout: any;
  let queuedIds: string[] = [];
  const loaded = new Set<string>();
  const promiseQueue = new PromiseQueue(1);

  const { get, set, subscribe } = writable<UserGetter>(makeGetUser());

  function makeGetUser() {
    return (uid: string): UserPublicData => {
      if (typeof uid !== 'string' || uid === 'true') return emptyInfo;
      let info = users[uid] as UserPublicData;
      if ((uid && !info) || !loaded.has(uid)) queue(uid);
      if (!info) info = { uid, ...emptyInfo };
      return info;
    };
  }

  function addUsers(added: UserPublicData[]) {
    const old = users;
    // Only change data that has changed
    makeChanges(() => {
      added.forEach(user => {
        if (!isEqual(users[user.uid], user)) {
          users = getImmutableValue(users);
          users[user.uid] = user;
        }
      });
    });
    if (old !== users) set(makeGetUser());
  }

  currentUser.subscribe(user => user && addUsers([infoFromUser(user)]));

  dbStore.register(async value => {
    if (db) {
      db.stores.other.removeEventListener('change', onChange);
    }

    db = value;

    if (db) {
      db.stores.other.addEventListener('change', onChange);
      const dbResults = (await db.stores.other.get('users')) as UsersMap;
      dbResults && addUsers(Object.values(dbResults));
    }
  });

  function onChange(event: CustomEvent<StoreChangeDetail>) {
    const {
      detail: { obj: dbUsers, declaredFrom },
    } = event;
    if (declaredFrom === 'local') return;
    addUsers(Object.values(dbUsers));
  }

  function queue(uid: string) {
    if (loaded.has(uid)) return;
    queuedIds.push(uid);
    loaded.add(uid); // keep from double-loading user information

    if (!timeout) {
      timeout = setTimeout(async () => {
        timeout = null;
        const ids = queuedIds;
        queuedIds = [];
        try {
          await promiseQueue.add(() => populateUserInfo(ids));
        } catch (err) {
          ids.forEach(id => loaded.delete(id));
        }
      });
    }
  }

  async function populateUserInfo(uids: string[]) {
    const results = await rest.GET(`/auth/users?uids=${uids.join(',')}`);
    const old = users;
    addUsers(results.map(infoFromUser));
    if (old !== users) {
      await db.stores.other.put({ id: 'users', ...users });
    }
  }

  return {
    get,
    subscribe,
  };
}

function infoFromUser({ uid, name, email, photoUrl = '' }: User): UserPublicData {
  return { uid, name, email, photoUrl };
}
