import { dateOptions, locale, t } from '@dabble/app';
import { format, formatDistance } from 'date-fns';
import { Coupon, Discount, Invoice } from '../types';

// Currencies that don't need to be devided by 100 when formatting from Stripe
export const zeroDecimalCurrencies = new Set([
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
]);

export function paymentString(invoice: Invoice) {
  const currency = invoice.currency.toUpperCase();
  const opts = {
    total: formatPrice(invoice.amount_due, currency) + ' ' + currency,
    date: format(new Date(invoice.created * 1000), 'PPP', dateOptions),
  };
  return t.get()('billing_payment', opts);
}

export function formatPrice(total: number, currency: string, dropZeros?: boolean) {
  let minimumFractionDigits = 0;
  if (!zeroDecimalCurrencies.has(currency)) total /= 100;
  if (!dropZeros || total % 1 !== 0) minimumFractionDigits = 2;
  return new Intl.NumberFormat(locale.get(), { style: 'currency', currency, minimumFractionDigits }).format(total);
}

export function couponAmount(coupon: Coupon, off?: boolean) {
  const total = coupon.amount_off ? formatPrice(coupon.amount_off, coupon.currency) : `${coupon.percent_off}%`;
  return off ? t.get()('billing_amount_off', { total }) : total;
}

export function couponDuration(coupon: Coupon) {
  return coupon.duration === 'repeating'
    ? t.get()('billing_duration_repeating', { duration: coupon.duration_in_months })
    : t.get()(`billing_duration_${coupon.duration}`);
}

export function discountEnd(discount: Discount) {
  return (discount.end && formatDistance(new Date(discount.end * 1000), new Date(), dateOptions)) || '';
}
