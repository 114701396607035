import { StoreChangeDetail } from 'browserbase';
import { DatabaseStore } from '../dabble-database';
import { getNow } from '../date';
import { DabbleDatabase, GlobalData } from '../types';
import { Readable, derived, writable } from './store';

export interface AllGlobalData {
  [id: string]: GlobalData;
}

export interface GlobalDataStore extends Readable<AllGlobalData> {
  update: (id: string, updates: Partial<AllGlobalData>) => Promise<AllGlobalData>;
}

export interface GlobalDataTypeStore<T extends GlobalData> extends Readable<T> {
  update: (updates: Partial<T>) => Promise<T>;
}

const empty: any = {};

export function createGlobalDataStore(dbStore: DatabaseStore): GlobalDataStore {
  let db: DabbleDatabase;
  let allGlobalData: AllGlobalData = {};
  const { get, set, subscribe } = writable<AllGlobalData>(allGlobalData);

  dbStore.register(async value => {
    if (db) {
      db.stores.global.removeEventListener('change', onChange);
      set((allGlobalData = {}));
    }

    db = value;

    if (db) {
      db.stores.global.addEventListener('change', onChange);
      const rows = await db.stores.global.getAll();
      allGlobalData = {};
      rows.forEach(doc => (allGlobalData[doc.id] = doc));
      set(allGlobalData);
    }
  });

  function onChange({ detail: { obj, key, declaredFrom } }: CustomEvent<StoreChangeDetail<GlobalData>>) {
    if (declaredFrom === 'local') return;
    set((allGlobalData = { ...allGlobalData, [key]: obj }));
  }

  async function update(id: string, updates: Partial<GlobalData>) {
    let GlobalData = allGlobalData[id];
    GlobalData = { ...GlobalData, ...updates, id, modified: getNow() };
    set((allGlobalData = { ...allGlobalData, [id]: GlobalData }));
    await db.stores.global.put(GlobalData);
    return allGlobalData;
  }

  return {
    update,
    get,
    subscribe,
  };
}

export function createGlobalDataTypeStore<T extends GlobalData>(
  allGlobalData: GlobalDataStore,
  id: string
): GlobalDataTypeStore<T> {
  const { get, subscribe } = derived<T, GlobalDataStore>(
    allGlobalData,
    allGlobalData => (allGlobalData[id] || empty) as T
  );

  async function update(updates: Partial<T>) {
    const allGlobal = await allGlobalData.update(id, updates);
    return allGlobal[id] as T;
  }

  return {
    get,
    update,
    subscribe,
  };
}
