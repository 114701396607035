import { settings } from '@dabble/app';
import HighlightButton from './HighlightButton.svelte';
import HighlightMenuSection from './HighlightMenuSection.svelte';
import './highlight-format';

settings.configure('dabble', {
  textBubbleMenu: { HighlightButton },
  textBubbleMenuSection: { HighlightMenuSection },
  editorTypes: {
    del: {
      formats: ['del'],
    },
    highlighter: types => {
      if (!types.formats) types.formats = [];
      if (types.formats.includes('highlight')) return types;
      types.formats.unshift('highlight');
      return types;
    },
  },
});
