<script>
  import { connected, inform, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiShare } from '@mdi/js';
  import html2canvas from 'html2canvas';
  import { shareStats } from '../share-to-social';

  export let container;

  $: sharing = false;

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = window.atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    return new Blob([ab], { type: mimeString });
  }

  async function createShareableImage() {
    if (!container || sharing) return;

    inform('info', $t('share_goals_loading'));

    let usingClone = false;
    let clonedContainer = container;

    // creates second iteration of goals & stats section for correct scaling
    if (clonedContainer.querySelector('.nano-logo')) {
      usingClone = true;
      clonedContainer = container.cloneNode(true);
      clonedContainer.classList.add('cloned');

      clonedContainer.width = container.scrollWidth;
      clonedContainer.height = container.scrollHeight;
      clonedContainer.position = 'absolute';
      clonedContainer.style.left = '1000%';
      clonedContainer.style.top = '0';

      const nanoLogo = clonedContainer.querySelector('.nano-logo');

      const logoImgStyle =
        container.querySelector('.nano-logo').currentStyle ||
        window.getComputedStyle(container.querySelector('.nano-logo'), false);
      const imgSrc = logoImgStyle.backgroundImage.slice(4, -1).replace(/"/g, '');

      nanoLogo.style.overflow = 'hidden';
      nanoLogo.style.position = 'relative';
      nanoLogo.style.background = 'unset';

      const newLogo = document.createElement('img');
      newLogo.setAttribute('src', imgSrc);
      newLogo.style.width = '100%';
      newLogo.style.height = '100%';
      nanoLogo.appendChild(newLogo);

      container.parentNode.appendChild(clonedContainer);
    }

    sharing = true;

    const canvas = await html2canvas(clonedContainer, { scale: 2, imageSmoothingEnabled: true });
    const dataURL = canvas.toDataURL('image/jpeg');

    const blob = dataURItoBlob(dataURL);

    await shareStats(blob);

    if (usingClone) {
      container.parentNode.removeChild(clonedContainer);
    }

    sharing = false;
  }
</script>

<button class="icon share" on:click={createShareableImage} class:feature-disabled={!$connected} disabled={!$connected}>
  <Icon path={mdiShare} />
</button>

<style>
  .icon.share {
    position: absolute;
    top: 10px;
    right: 34px;
  }
</style>
