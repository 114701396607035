<script>
  import { t } from '@dabble/app';
  import Cost from './Cost.svelte';

  export let lifetimePrice;
  export let lifetimePlan;
  export let current;
</script>

<div class="plan-wrapper lifetime" class:current>
  <div class="plan">
    <h3 class="plan-name">Lifetime</h3>
    <div class="plan-desc">{$t('billing_lifetime_info')}</div>
    <a class="btn primary" href="/checkout/confirm?plan={lifetimePlan.id}">{$t('checkout_select_plan')}</a>
    <div class="price">
      <Cost currency={lifetimePrice.currency} amount={lifetimePrice.unit_amount} interval="lifetime" />
      <div class="interval">{$t('billing_lifetime_interval', { currency: lifetimePrice.currency })}</div>
    </div>
    <hr />
    <div class="plan-image" />
    <ul class="features">
      <li class="plus">{$t('billing_lifetime_features')}</li>
    </ul>
  </div>
</div>

<style>
  .plan-wrapper.lifetime {
    order: 4;
  }
  .plan-image {
    background-image: url(../images/plan-premium.svg);
  }
  .interval {
    color: var(--text-color-lightest);
    font-size: var(--font-size-sm);
  }
</style>
