import { Delta } from 'typewriter-editor';

const DEFAULT_IDLE = 3000;
const DEFAULT_MAX = 30000;
const NOOP = () => {};

export type SaveCallback = (delta: Delta, key: string) => Promise<void>;
export type CancelCallback = (key: string) => void;

export interface TextQueueOptions {
  idleDelay?: number;
  maxDelay?: number;
  onSave?: SaveCallback;
  onCancel?: CancelCallback;
}

export interface TextQueueEntry {
  key: string;
  delta: Delta;
  finish: () => Promise<Delta>;
  cancel: () => void;
  idleTimeout: any;
}

export interface TextQueueMap {
  [key: string]: TextQueueEntry;
}
export type TextQueueIterator = (key: string, delta: Delta, queue: TextQueueEntry, queueMap: TextQueueMap) => void;

export interface TextQueue {
  hasQueued: (key?: string) => boolean;
  add: (key: string, delta: Delta, dontExtendDelay?: boolean) => Delta;
  getFor: (key: string) => TextQueueEntry;
  getAll: (startsWith?: string) => TextQueueEntry[];
  transform: (key: string, delta: Delta) => Delta;
  finish: (startsWith?: string) => Promise<void>;
  cancel: (startsWith?: string) => void;
  forEach: (iter: TextQueueIterator) => void;
}

/**
 * Create a new queue with the given idle and max delay. A save will happen after no changes for `idleDelay`
 * milliseconds or every `maxDelay` milliseconds even when not idle. maxDelay should be larger.
 */
export function createTextQueue(options: TextQueueOptions = {}): TextQueue {
  const idleDelay = options.idleDelay || DEFAULT_IDLE;
  const maxDelay = options.maxDelay || DEFAULT_MAX;
  const onSave = options.onSave || NOOP;
  const onCancel = options.onCancel || NOOP;
  let queueMap: TextQueueMap = {};
  let batch = false;

  /**
   * Whether there are any saves queued up.
   */
  function hasQueued(key?: string) {
    if (key) return Boolean(queueMap[key]);
    else return Boolean(Object.keys(queueMap).length);
  }

  /**
   * Add a new save to the queue by key. The delta will be passed to the callback once the save is ready. The callback is
   * what actually performas the save.
   */
  function add(key: string, delta: Delta, dontExtendDelay?: boolean) {
    if (!delta.length()) return delta;

    let queued = queueMap[key];

    if (queued) {
      queued = { ...queued, delta: queued.delta.compose(delta) };
      if (!queued.delta.length()) {
        queued.cancel();
      } else {
        if (!dontExtendDelay) {
          clearTimeout(queued.idleTimeout);
          queued.idleTimeout = setTimeout(queued.finish, idleDelay);
        }
        queueMap = { ...queueMap, [key]: queued };
      }
    } else {
      let finish: () => Promise<Delta>;

      function cancel(fromFinish?: boolean) {
        clearTimeout(queueMap[key].idleTimeout);
        clearTimeout(maxTimeout);
        queueMap = { ...queueMap };
        delete queueMap[key];
        if (!batch && !fromFinish) {
          onCancel(key);
        }
      }

      const promise = new Promise<Delta>(resolve => {
        finish = async () => {
          const { delta } = queueMap[key];
          cancel(true);
          await onSave(delta, key);
          resolve(delta);
          return promise;
        };
      });

      const maxTimeout = setTimeout(finish, maxDelay);
      queued = { key, delta, finish, cancel, idleTimeout: setTimeout(finish, idleDelay) };

      queueMap = { ...queueMap, [key]: queued };
    }

    return queued.delta;
  }

  /**
   * Returns the queued entry for the given key.
   */
  function getFor(key: string) {
    return queueMap[key];
  }

  /**
   * Returns the queued entry for the given key.
   */
  function getAll(startsWith?: string) {
    return startsWith
      ? Object.values(queueMap).filter(queue => queue.key.startsWith(startsWith))
      : Object.values(queueMap);
  }

  /**
   * Transforms a delta against the queued entry and the queued entry against the delta, returning the transformed
   * delta.
   */
  function transform(key: string, delta: Delta) {
    let queued = queueMap[key];
    if (queued) {
      const pendingDelta = queued.delta;
      queued = { ...queued, delta: delta.transform(pendingDelta, true) };
      delta = pendingDelta.transform(delta);
      if (!queued.delta.length()) queued.cancel();
      else queueMap = { ...queueMap, [key]: queued };
    }
    return delta;
  }

  /**
   * Finish the save for a given key. If no key is passed, finish all queued saves.
   */
  async function finish(startsWith?: string) {
    const queues = startsWith
      ? Object.values(queueMap).filter(queue => queue.key.startsWith(startsWith))
      : Object.values(queueMap);
    if (!queues.length) return;

    batch = true;
    const promises = queues.map(queue => queue.finish());
    batch = false;
    await Promise.all(promises);
  }

  /**
   * Cancel the save for a given key. If no key is passed, cancel all queued saves. If no field is passed, cancel all
   * queued saves for the given doc.
   */
  function cancel(startsWith?: string) {
    const queues = startsWith
      ? Object.values(queueMap).filter(queue => queue.key.startsWith(startsWith))
      : Object.values(queueMap);
    if (!queues.length) return;

    batch = true;
    queues.forEach(queue => queue.cancel());
    batch = false;
    queues.forEach(queue => onCancel(queue.key));
  }

  /**
   * Iterate over each item in the queue, receiving its docId, field, and delta.
   */
  function forEach(iter: TextQueueIterator) {
    Object.keys(queueMap).forEach(key => {
      iter(key, queueMap[key].delta, queueMap[key], queueMap);
    });
  }

  return {
    hasQueued,
    add,
    getFor,
    getAll,
    transform,
    finish,
    cancel,
    forEach,
  };
}
