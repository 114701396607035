<script>
  import { t } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import { onMount } from 'svelte';
  import { billing, subscription } from '../billing';
  import { couponAmount, couponDuration, discountEnd } from './util';

  export let coupon;

  let discount = $subscription?.discount;
  $: hasCoupon = discount?.coupon ? true : false;

  onMount(async () => {
    if (coupon) discount = $subscription.discount;
    else if (sessionStorage.referralCode) discount.coupon = await billing.getCoupon(sessionStorage.referralCode);
    else if (sessionStorage.coupon) discount.coupon = await billing.getCoupon(sessionStorage.coupon);
  });
</script>

{#if hasCoupon}
  <Alert type="info" class="coupon">
    <strong class="alert-label">{$t('billing_coupon')} &nbsp; ({discount.coupon.name || discount.coupon.id})</strong>
    {couponAmount(discount.coupon, true)}
    {couponDuration(discount.coupon)}
    {#if discount.coupon.duration === 'repeating'}
      ({discountEnd(discount)} left)
    {/if}
  </Alert>
{/if}

<style>
  .alert-label {
    display: block;
    font-size: 0.625rem;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }
</style>
