<script lang="ts">
  import {
    confirm,
    currentDoc,
    features,
    getIcon,
    getTitle,
    getUrl,
    isDocEmpty,
    projectStore,
    readonly,
    router,
    settings,
    t,
    trashOrDeleteDoc,
  } from '@dabble/app';
  import { getSetting } from '@dabble/data/stores/settings';
  import { Doc, DocSettings, MenuItem } from '@dabble/data/types';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import {
    canPaste,
    copyDocForDuplication,
    getPasteType,
    pasteDocFromDuplication,
  } from '@dabble/toolkit/copy-paste-doc';
  import { availableOverlays, iconVariations } from '@dabble/toolkit/icons';
  import { createFromTemplate, createNewDoc, scrollTo } from '@dabble/toolkit/new-docs';
  import {
    mdiAlphaIBox,
    mdiAlphaTBox,
    mdiContentCopy,
    mdiContentPaste,
    mdiDelete,
    mdiDeleteEmpty,
    mdiDeleteForever,
    mdiPencil,
    mdiPlusThick,
    mdiUndoVariant,
  } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import SubNavItem from './SubNavItem.svelte';

  type IncludedActions = keyof typeof menuActions;

  export let doc: Doc;

  const dispatch = createEventDispatcher();
  const menuActions = { trashDoc, deleteDoc, emptyTrash, restoreDoc };
  const { project, parentsLookup, docs, inTrash } = $projectStore;
  const editMenuItem: MenuItem = { icon: mdiPencil, key: 'edit_title', action: 'editTitle' };
  const copyMenuItem: MenuItem = { icon: mdiContentCopy, key: 'document_copy', action: copyDocForDuplication };
  const pasteMenuItemKey = () => {
    const type = getPasteType();
    return type ? $t('document_paste_doc', { type }) : $t('document_paste');
  };
  const pasteMenuItem: MenuItem = {
    icon: mdiContentPaste,
    key: pasteMenuItemKey,
    action: pasteDocFromDuplication,
    disabled: (doc: Doc) => !canPaste(doc),
  };
  const trashMenuItem: MenuItem = { icon: mdiDelete, key: 'send_to_trash', action: 'trashDoc' };
  const deleteMenuItem: MenuItem = { icon: mdiDelete, key: 'delete_item', action: 'trashDoc' };
  const emptyTrashMenuItem: MenuItem = {
    icon: mdiDeleteEmpty,
    key: 'empty_trash',
    action: 'emptyTrash',
    disabled: () => doc.children.length === 0,
  };
  const restoreMenuItem: MenuItem = { icon: mdiUndoVariant, key: 'restore_from_trash', action: 'restoreDoc' };
  const deletePermanentlyMenuItem: MenuItem = {
    icon: mdiDeleteForever,
    key: 'delete_permanently',
    action: 'deleteDoc',
  };
  const emptyArray: MenuItem[] = [];
  let menuEditItems: MenuItem[];

  $: docSettings = $settings[doc.type] as DocSettings;
  $: docInTrash = $projectStore.inTrash[doc.id];
  $: templatesMenu = $projectStore.project && $settings[$projectStore.project.type].templatesMenu;
  $: canDeleteDoc = isDocEmpty(doc);
  $: menuTypes =
    doc?.type === 'templates'
      ? templatesMenu
      : docSettings?.menuTypes ||
        (docSettings.validChildTypes && Object.keys(docSettings.validChildTypes).filter(Boolean));
  $: allCustomMenuItems = settings
    .getValuesFromPlugins('menuItems', $projectStore.project, doc)
    .flat()
    .sort(menuItemSort);
  $: customMenuItems = allCustomMenuItems.filter(item => !item.section);
  $: menuRenameItems = (
    !docSettings.uneditable
      ? [editMenuItem, copyMenuItem, docSettings.hasChildren && doc.type !== 'trash' ? pasteMenuItem : null].filter(
          Boolean
        )
      : docSettings.hasChildren && doc.type !== 'trash'
      ? [pasteMenuItem]
      : emptyArray
  ).concat(allCustomMenuItems.filter(item => item.section === 'rename'));
  $: menuAddTypes = ((doc && !docInTrash && menuTypes) || emptyArray).concat(
    doc.type !== 'trash' ? allCustomMenuItems.filter(item => item.section === 'add') : emptyArray
  );
  $: {
    menuEditItems = allCustomMenuItems.filter(item => item.section === 'edit');
    if (!docSettings.noDeleteInNavigation) {
      if (docInTrash) {
        if (parentsLookup[doc.id].id === 'trash') {
          if (
            doc.oldParentId &&
            (docs[doc.oldParentId] || project.id === doc.oldParentId) &&
            !inTrash[doc.oldParentId]
          ) {
            menuEditItems.push(restoreMenuItem);
            menuEditItems.push(deletePermanentlyMenuItem);
          }
        }
      } else {
        menuEditItems.push(canDeleteDoc ? deleteMenuItem : trashMenuItem);
      }
    }

    if (doc.type === 'trash') {
      menuEditItems.push(emptyTrashMenuItem);
    }
  }
  $: templates = projectStore.getChildren('templates');
  $: templatesAvailable =
    templates?.filter(t => {
      if (doc?.type === 'trash') return false;
      if (!docSettings.hasChildren) return false;
      const childTypes = docSettings?.validChildTypes;
      if (!childTypes) return true;
      const types = Object.keys(childTypes);
      return types.includes(t.type);
    }) || [];
  $: templateDocIds = Object.values(templatesAvailable).map(t => t.id);
  $: sections = [
    menuAddTypes.length || templatesAvailable.length ? 'add' : null,
    customMenuItems.length ? 'custom' : null,
    menuRenameItems.length ? 'rename' : null,
    menuEditItems.length ? 'edit' : null,
  ];

  function getValue(menuItem: MenuItem, prop: keyof MenuItem, defaultValue?: any) {
    const value = menuItem[prop];
    if (typeof value === 'function') {
      return value(doc);
    } else if (value !== undefined) {
      return value;
    } else {
      return defaultValue;
    }
  }

  function menuItemSort(itemA: MenuItem, itemB: MenuItem) {
    const valueA = itemA.order || 0;
    const valueB = itemB.order || 0;
    return valueB - valueA;
  }

  async function getInsertionPoint(type: string, docId = $currentDoc.id) {
    let index = ($currentDoc && doc.children.indexOf(docId) + 1) || undefined;
    const validTypes = docSettings.validChildTypes?.[type];
    if (typeof validTypes === 'function') {
      index = await validTypes(doc, index);
    }
    return index;
  }

  async function addChild(type: string) {
    const docId = doc.id;
    const index = await getInsertionPoint(type);

    const id = projectStore.createDocId();
    await createNewDoc({ id, type }, docId, index);
    await scrollTo($currentDoc, id);
  }

  function itemClicked(menuItem: MenuItem) {
    if (typeof menuItem.action === 'function') menuItem.action(doc);
    else if (menuItem.action in menuActions) menuActions[menuItem.action as IncludedActions]();
    else dispatch(menuItem.action, { menuItem, doc });
    dispatch('close');
  }

  async function trashDoc() {
    trashOrDeleteDoc(doc.id);
  }
  async function emptyTrash() {
    if (!(await confirm($t('empty_trash_confirm')))) return;
    if ($currentDoc && $projectStore.inTrash[$currentDoc.id]) {
      router.navigate(getUrl($projectStore.project));
    }
    projectStore.emptyTrash();
  }
  async function deleteDoc() {
    trashOrDeleteDoc(doc.id, true);
  }
  function restoreDoc() {
    projectStore.restoreDoc(doc.id);
  }

  function canEdit(type: string) {
    const requiresFeature = settings.getFor(type).requiresFeature;
    if (requiresFeature && !$features.has(requiresFeature)) return false;
    return true;
  }

  function getDocSettings(type: string) {
    return settings.getFor(type) as DocSettings;
  }

  function getAddIcon(type: string): string {
    if (!getDocSettings(type).icon) throw new Error('No icon found for ' + type);
    return getSetting(getDocSettings(type).icon, { type } as Doc);
  }

  async function fromTemplate(templateId: string, docId: string) {
    const template = projectStore.getDoc(templateId);
    const index = await getInsertionPoint(template.type, docId);
    const id = await createFromTemplate(template, docId, index);
    await scrollTo($currentDoc, id);
  }

  function getTemplateIcon(docId: string) {
    return getAddIcon(projectStore.getDoc(docId).type);
  }

  function getTemplateText(docId: string) {
    return getTitle(projectStore.getDoc(docId));
  }
</script>

{#if !sections.filter(Boolean).length}
  <button class="dropdown-item nav-item-menu" disabled>{$t('actions_none')}</button>
{/if}

<div class="section">
  {#each menuAddTypes as type}
    <button
      class="dropdown-item nav-item-menu add-child"
      on:click={() => addChild(type)}
      disabled={$readonly || !canEdit(type)}
    >
      <Icon path={mdiPlusThick} class="prefix" /><Icon path={getAddIcon(type)} />
      {$t('add_new_document', { type })}
    </button>
  {/each}

  {#if templatesAvailable.length}
    <SubNavItem>
      <svelte:fragment slot="submenu-button">
        <Icon path={mdiPlusThick} class="prefix" /><Icon path={mdiAlphaTBox} />
        {$t('templates_add_from')}
      </svelte:fragment>
      {#each templateDocIds as template}
        <button
          class="dropdown-item nav-item-menu add-child"
          on:click={() => fromTemplate(template, doc.id)}
          disabled={$readonly || !canEdit(doc.type)}
        >
          <Icon path={mdiPlusThick} class="prefix" /><Icon path={getTemplateIcon(template)} />
          {$t(getTemplateText(template))}
        </button>
      {/each}
    </SubNavItem>
  {/if}
</div>

<div class="section">
  {#each customMenuItems as item}
    {#if typeof item === 'function'}
      <svelte:component this={item} {doc} />
    {:else if !item.hide || (item.hide && !item.hide(doc))}
      <button
        class="dropdown-item nav-item-menu"
        on:click={() => itemClicked(item)}
        disabled={getValue(item, 'disabled', false)}
      >
        {#if getValue(item, 'icon')}
          <Icon path={getValue(item, 'icon')} overlay={getValue(item, 'iconOverlay')} />
        {/if}
        {$t(getValue(item, 'key'), doc)}
      </button>
    {/if}
  {/each}
</div>

<div class="section">
  {#each menuRenameItems as item}
    <button
      class="dropdown-item nav-item-menu"
      on:click={() => itemClicked(item)}
      disabled={$readonly || !canEdit(doc.type) || getValue(item, 'disabled')}
    >
      {#if getValue(item, 'icon')}
        <Icon path={getValue(item, 'icon')} overlay={getValue(item, 'iconOverlay')} />
      {/if}
      {$t(getValue(item, 'key'), doc)}
    </button>
  {/each}
  {#if menuRenameItems.length && docSettings.icon && iconVariations[doc.type]}
    <SubNavItem>
      <svelte:fragment slot="submenu-button">
        <Icon path={mdiAlphaIBox} />
        {$t('set_icon')}
      </svelte:fragment>

      <div class="icon-menu">
        <button
          class="dropdown-item doc-icon"
          class:open={!doc.icon}
          on:click={() => projectStore.updateDoc(doc.id, { icon: null })}
          disabled={$readonly || !canEdit(doc.type)}
        >
          <Icon path={getSetting(settings.getFor(doc)?.icon, doc)} />
        </button>
        {#each Object.entries(iconVariations[doc.type]) as [icon, path]}
          <button
            class="dropdown-item doc-icon"
            class:open={doc.icon === icon}
            on:click={() => projectStore.updateDoc(doc.id, { icon })}
            disabled={$readonly || !canEdit(doc.type)}
          >
            <Icon {path} />
          </button>
        {/each}
      </div>
    </SubNavItem>
  {/if}
  {#if menuRenameItems.length && docSettings.icon}
    <SubNavItem>
      <svelte:fragment slot="submenu-button">
        <Icon path={getIcon(doc)} overlay={doc.iconOverlay || 'check'} />
        {$t('set_icon_overlay')}
      </svelte:fragment>

      <div class="icon-menu">
        <button
          class="dropdown-item doc-icon"
          class:open={!doc.iconOverlay}
          on:click={() => projectStore.updateDoc(doc.id, { iconOverlay: null })}
          disabled={$readonly || !canEdit(doc.type)}
        >
          <Icon path={getIcon(doc)} />
        </button>
        {#each availableOverlays as iconOverlay}
          <button
            class="dropdown-item doc-icon"
            class:open={doc.iconOverlay === iconOverlay}
            on:click={() => projectStore.updateDoc(doc.id, { iconOverlay })}
            disabled={$readonly || !canEdit(doc.type)}
          >
            <Icon path={getIcon(doc)} overlay={iconOverlay} />
          </button>
        {/each}
      </div>
    </SubNavItem>
  {/if}
</div>

<div class="section">
  {#each menuEditItems as item}
    <button
      class="dropdown-item nav-item-menu"
      on:click={() => itemClicked(item)}
      disabled={$readonly || getValue(item, 'disabled')}
    >
      {#if getValue(item, 'icon')}
        <Icon path={getValue(item, 'icon')} overlay={getValue(item, 'iconOverlay')} />
      {/if}
      {$t(getValue(item, 'key'))}
    </button>
  {/each}
</div>

<style>
  .section:has(*):has(~ .section *)::after {
    display: block;
    content: '';
    margin: 4px 8px;
    border-top: 1px solid var(--menu-separator-color);
  }
  .icon-menu {
    display: grid;
    grid-template-columns: repeat(6, min-content);
    padding: 8px 12px; /* 20px - 8px (icon padding) */
    gap: 4px;
  }
  :global(.is-mouse) .icon-menu {
    --icon-padding: 6px;
    padding: 0 10px;
  }
  :global(.dropdown-menu .dropdown-item).doc-icon {
    font-size: 20px;
    width: auto;
    padding: 8px;
    margin: 0;
  }
  :global(.is-mouse .dropdown-menu .dropdown-item).doc-icon {
    padding: 6px;
  }
  :global(.dropdown-menu .dropdown-item).doc-icon :global(.icon) {
    margin-right: 0;
  }
</style>
