import { Modifier, ModifierArguments, Options } from '@popperjs/core/lib/types';

function frameAdjust({ state, name }: ModifierArguments<Options>) {
  const { x, y } = frameOffsets(state.elements.reference as Element);
  const popperPlacement = state.placement;
  const { placement } = state.options;

  if (popperPlacement == placement) {
    state.rects.reference.x += x;
    state.rects.reference.y += y;
  }
}

export default {
  name: 'frameAdjust',
  enabled: true,
  phase: 'beforeRead',
  fn: frameAdjust,
  data: {},
} as Modifier<'frameAdjust', Options>;

export function frameOffsets(target: Element) {
  let x = 0,
    y = 0;
  while (target && target.ownerDocument) {
    const frame =
      target.ownerDocument && target.ownerDocument.defaultView && target.ownerDocument.defaultView.frameElement;

    if (frame) {
      const rect = frame.getBoundingClientRect();
      x += rect.left;
      y += rect.top;
    }
    target = frame;
  }
  return { x, y };
}
