<script lang="ts">
  import { authed, collective, plugins, projectMetas, readonly, settings, t } from '@dabble/app';
  import { ProjectMeta, UserProject } from '@dabble/data/types';
  import ActionContextMenu from '@dabble/toolkit/ActionContextMenu.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Title from '@dabble/toolkit/Title.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiLockAlert, mdiShareVariant } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import ProjectActions from './ProjectActions.svelte';

  export let userProject: UserProject;
  export let selected: boolean;
  export let loading: boolean;

  const { uid } = plugins.stores;
  const dispatch = createEventDispatcher();
  let disabled = false;
  let menuOpen = false;
  let projectMeta: ProjectMeta;
  let font: typeof projectMeta.font;
  let page: HTMLElement;
  let contextOpen: boolean;
  let imageLoaded: boolean;
  let imageError: boolean;

  $: type = $settings.dabble.defaultProjectType;
  $: projectMeta = $projectMetas[userProject.projectId];
  $: isTemplate = $projectMetas[userProject.projectId]?.isTemplate;
  $: font = (projectMeta && projectMeta.font) || {};
  $: disabled = !projectMeta || loading;
  $: tooltipText = $t(
    userProject.trashed
      ? 'project_restore'
      : $readonly && isTemplate
      ? $t('template_view')
      : isTemplate
      ? 'template_new'
      : '',
    { type: $t(type), name: $t(projectMeta?.title || 'template') }
  );
  $: actionTooltip = $t(isTemplate ? 'template_actions' : 'project_actions');

  function isOwner() {
    return projectMeta?.roles && projectMeta?.roles[$uid] === 'owner';
  }

  function onClick(event: Event) {
    if ((event.target as Element).closest?.('.display-dots-vertical')) return;
    if (!disabled && !userProject.trashed) {
      dispatch('select');
    } else if (userProject.trashed) {
      dispatch('restore');
    }
  }
</script>

<div
  data-id={userProject.projectId}
  class:selected
  class:has-cover={projectMeta && projectMeta.image && !imageError}
  class:image-loaded={imageLoaded}
  class="project-outer shuffle-{parseInt(userProject.projectId, 36) % 5} font-{font.family || ''}"
  class:disabled
  class:menuOpen={menuOpen || contextOpen}
  class:isTemplate
>
  <div
    class="project"
    use:tooltipTop={{ text: tooltipText, offset: 6 }}
    on:click={onClick}
    on:keydown={onClick}
    bind:this={page}
    tabindex="-1"
  >
    <div class="project-inner">
      {#if projectMeta}
        {#if !imageLoaded}
          <Title tag="h1" doc={projectMeta} />
          <Title tag="h2" doc={projectMeta} hiddenPlaceholder field="subtitle" />
          <div class="info">{$t('project_by')}</div>
          <Title tag="h3" doc={projectMeta} field="author" truncate={30} />
        {/if}
        {#if projectMeta.image}
          <img
            class="preview-image"
            src={projectMeta.image}
            alt={projectMeta.title}
            on:load={() => (imageLoaded = true)}
            on:error={() => (imageError = true)}
          />
        {/if}
        {#if isTemplate && isOwner() && projectMeta?.templateCount}
          <div class="template-count" use:tooltipTop={$t('template_count')}>{projectMeta.templateCount || 0}</div>
        {/if}
        <div class="icon display-dots-vertical">
          <ActionContextMenu placement="right-start" arrow target={page} {actionTooltip} bind:menuOpen bind:contextOpen>
            <ProjectActions
              {userProject}
              on:restore
              on:delete
              on:rename
              on:duplicate
              on:template
              on:category
              on:share
              on:edit
              on:view
            />
          </ActionContextMenu>
        </div>
        {#if $authed && !projectMeta.roles?.[collective.author] && !isTemplate}
          <div class="icon issue" use:tooltipTop={$t('project_no_permissions')}>
            <Icon path={mdiLockAlert} />
          </div>
        {:else if $authed && !projectMeta.roles?.[collective.author] && isTemplate}
          <div class="icon shared" use:tooltipTop={$t('project_shared_template')}>
            <Icon path={mdiShareVariant} />
          </div>
        {/if}
      {/if}
    </div>
  </div>
</div>

<style>
  .template-count {
    position: absolute;
    top: -10px;
    left: -10px;
    border-radius: 50px;
    background: var(--dabble-teal);
    color: var(--white);
    padding: 2px 10px;
  }
  .project-outer {
    --height: 180px;
    --width: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    -webkit-user-select: none;
    user-select: none;
    transition: all 0.6s ease-in-out;
    height: var(--height);
    width: var(--width);
    contain: layout size;
  }
  .project-outer.in {
    animation: projectlist-in 600ms ease-in-out;
  }
  .project-outer.out {
    animation: projectlist-out 400ms ease-in-out;
    opacity: 0;
    width: 0px;
    padding: 0px;
  }
  .project-outer.selected {
    animation: project-selected 200ms ease-in;
    opacity: 0;
    z-index: 2;
  }

  .project {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 140px;
    height: 180px;
    padding: 0 10px;
    outline: none;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    contain: layout size style;
    transform: translateZ(0);
  }
  .has-cover .project {
    background-color: var(--page-background);
    border-radius: 3px;
    margin-top: -2px;
    width: 144px;
    height: 184px;
  }
  .project-inner {
    max-width: 100%;
    max-height: 100%;
  }
  .project-inner > :global(*) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .preview-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
    box-shadow: var(--home-project-cover-shadow);
  }
  .has-cover .project-inner::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.5), inset 0 -1px 1px rgba(0, 0, 0, 0.5);
  }

  .project-outer.disabled .project {
    cursor: default;
    opacity: 0.5;
  }
  .project :global(h1) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-family: var(--font-family-header);
    font-size: 16px;
    color: var(--text-color-light);
    margin: 0 0 0.25rem;
  }
  .project :global(h2) {
    font-family: var(--font-family-header);
    font-size: 12px;
    color: var(--text-color-light);
    margin: 0;
  }
  .project .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--text-color-lighterer);
    margin: 0.5rem 0;
  }
  .project :global(h3) {
    color: var(--text-color-lighterer);
    font-size: 12px;
    font-family: var(--font-family-header);
    margin: 0;
  }

  :global(.is-mouse) .project-outer:not(.disabled):hover,
  .project-outer.menuOpen {
    z-index: 1;
  }
  :global(.is-mouse) .project-outer:not(.disabled):hover .project,
  .project-outer.menuOpen .project {
    transform: translate(0, -5px);
    box-shadow: var(--home-project-hover-shadow);
  }

  @media (max-width: 600px) {
    .project-outer {
      transform: scale(0.8, 0.8);
      transform-origin: top;
      margin-bottom: 0;
      margin-right: -16px;
    }
  }

  /* Stack of papers */

  .project-outer:not(.has-cover) .project::before,
  .project-outer:not(.has-cover) .project::after,
  .project-outer:not(.has-cover) .project-inner::before {
    position: absolute;
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: var(--home-project-shadow);
    background-color: var(--page-background);
    transition: all 0.15s ease-in-out;
    contain: layout size style;
  }
  .project-outer.shuffle-0 .project:before {
    transform: rotate(-1.5deg) translate(-2px, -4px);
  }
  .project-outer.shuffle-0 .project-inner:before {
    transform: rotate(4deg);
  }
  .project-outer.shuffle-1 .project:before {
    transform: rotate(1.5deg) translate(2px, 4px);
  }
  .project-outer.shuffle-1 .project-inner:before {
    transform: rotate(-2deg);
  }
  .project-outer.shuffle-2 .project:before {
    transform: rotate(-1deg) translate(-3px, 1px);
  }
  .project-outer.shuffle-2 .project-inner:before {
    transform: rotate(3deg);
  }
  .project-outer.shuffle-3 .project:before {
    transform: rotate(2deg) translate(4px, 0);
  }
  .project-outer.shuffle-3 .project-inner:before {
    transform: rotate(-4deg) translate(0px, 2px);
  }
  .project-outer.shuffle-4 .project:before {
    transform: rotate(-2deg) translate(0, -3px);
  }
  .project-outer.shuffle-4 .project-inner:before {
    transform: rotate(3deg) translate(2px, 1px);
  }

  /* Hover state */
  :global(.is-mouse) .project-outer.shuffle-0:not(.disabled) .project:hover:before,
  .project-outer.shuffle-0.menuOpen:not(.disabled) .project:before {
    transform: rotate(-1.5deg) translate(-1px, -2px);
  }
  :global(.is-mouse) .project-outer.shuffle-0:not(.disabled) .project:hover .project-inner:before,
  .project-outer.shuffle-0.menuOpen:not(.disabled) .project-inner:before {
    transform: rotate(2deg);
  }
  :global(.is-mouse) .project-outer.shuffle-1:not(.disabled) .project:hover:before,
  .project-outer.shuffle-1.menuOpen:not(.disabled) .project:before {
    transform: rotate(1.5deg) translate(1px, 2px);
  }
  :global(.is-mouse) .project-outer.shuffle-1:not(.disabled) .project:hover .project-inner:before,
  .project-outer.shuffle-1.menuOpen:not(.disabled) .project-inner:before {
    transform: rotate(-1.5deg);
  }
  :global(.is-mouse) .project-outer.shuffle-2:not(.disabled) .project:hover:before,
  .project-outer.shuffle-2.menuOpen:not(.disabled) .project:before {
    transform: rotate(-1deg) translate(-1px, 0px);
  }
  :global(.is-mouse) .project-outer.shuffle-2:not(.disabled) .project:hover .project-inner:before,
  .project-outer.shuffle-2.menuOpen:not(.disabled) .project-inner:before {
    transform: rotate(2deg);
  }
  :global(.is-mouse) .project-outer.shuffle-3:not(.disabled) .project:hover:before,
  .project-outer.shuffle-3.menuOpen:not(.disabled) .project:before {
    transform: rotate(2deg) translate(2px, 0);
  }
  :global(.is-mouse) .project-outer.shuffle-3:not(.disabled) .project:hover .project-inner:before,
  .project-outer.shuffle-3.menuOpen:not(.disabled) .project-inner:before {
    transform: rotate(-2deg) translate(0px, 1px);
  }
  :global(.is-mouse) .project-outer.shuffle-4:not(.disabled) .project:hover:before,
  .project-outer.shuffle-4.menuOpen:not(.disabled) .project:before {
    transform: rotate(-2deg) translate(0, -1px);
  }
  :global(.is-mouse) .project-outer.shuffle-4:not(.disabled) .project:hover .project-inner:before,
  .project-outer.shuffle-4.menuOpen:not(.disabled) .project-inner:before {
    transform: rotate(2deg) translate(1px, 1px);
  }
  .project-outer.isTemplate .project:before,
  .project-outer.isTemplate .project-inner:before {
    display: none;
  }

  .project-outer.isTemplate .project {
    border-radius: 5px;
  }
  .project-outer.isTemplate .project::after {
    border-radius: 5px;
    box-shadow: var(--home-template-shadow);
  }

  .icon.display-dots-vertical {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    margin-bottom: 3px;
    margin-right: 3px;
    line-height: 32px;
    color: var(--text-color-lightest);
    cursor: pointer;
  }
  :global(.is-mouse) .icon.display-dots-vertical {
    display: none;
  }
  :global(.is-mouse) .project-outer:hover .icon.display-dots-vertical,
  :global(.is-mouse) .project-outer.menuOpen .icon.display-dots-vertical {
    display: flex;
  }
  .icon.display-dots-vertical:hover,
  .project-outer.menuOpen .icon.display-dots-vertical {
    color: var(--text-color-light);
  }
  .image-loaded .icon.display-dots-vertical {
    color: var(--text-color-light);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
  }
  .image-loaded .icon.display-dots-vertical:hover,
  .image-loaded .project-outer.menuOpen .icon.display-dots-vertical {
    color: var(--text-color-normal);
    background-color: rgba(255, 255, 255, 0.75);
  }

  .icon.issue,
  .icon.shared {
    position: absolute;
    font-size: 18px;
    text-align: center;
    right: 8px;
    top: 8px;
    color: var(--text-color-lighter);
  }
  .icon.shared {
    color: var(--text-color-lightest);
  }

  @keyframes projectlist-in {
    0% {
      opacity: 0;
      width: 0px;
    }
    50% {
      opacity: 0;
      width: var(--width);
    }
    100% {
      opacity: 1;
      width: var(--width);
    }
  }
  @keyframes projectlist-out {
    0% {
      opacity: 1;
      width: var(--width);
    }
    50% {
      opacity: 0;
      width: var(--width);
    }
    100% {
      opacity: 0;
      width: 0px;
    }
  }
  @keyframes project-selected {
    0% {
      opacity: 1;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform: scale(3, 3);
    }
  }
</style>
