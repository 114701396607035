<script>
  import { isTouch } from '@dabble/app';
  import waf from '@dabble/util/waf';
  import { onDestroy } from 'svelte';
  import Dropdown from './Dropdown.svelte';
  import { frameOffsets } from './popper-frames';

  export let target;
  export let targetFilter = null;
  export let activeClass = '';
  export let isOpen = false;
  let dropdown;
  let oldTarget;
  let realTarget = null;
  let dropdownTarget = null;
  let classAdded = '';
  let lastClick;
  let lastClickTimeout;

  $: updateTarget(target);
  $: isOpen = !!dropdownTarget;

  function updateTarget(target) {
    if (oldTarget) {
      oldTarget.removeEventListener('contextmenu', onContextMenu);
    }
    if (target) {
      target.addEventListener('contextmenu', onContextMenu);
    }
    oldTarget = target;
  }

  async function onContextMenu(event) {
    realTarget = targetFilter ? targetFilter(event) : target;
    if (!realTarget || $isTouch || event.menuHandled) return;
    event.preventDefault();
    event.menuHandled = true;
    if (activeClass && !realTarget.classList.contains(activeClass)) {
      realTarget.classList.add((classAdded = activeClass));
    }
    // If the click happens again in the same place (roughly) the toggle closed
    const click = { x: event.pageX, y: event.pageY };
    if (lastClick && Math.abs(lastClick.x - click.x) < 10 && Math.abs(lastClick.y - click.y) < 10) {
      onClose();
    } else {
      await waf();
      dropdownTarget = getDropdownTarget(event);
      lastClick = click;
      clearTimeout(lastClickTimeout);
    }
  }

  function onClose() {
    if (classAdded) {
      realTarget.classList.remove(classAdded);
      classAdded = '';
    }
    dropdownTarget = realTarget = null;
    lastClickTimeout = setTimeout(() => (lastClick = null), 100);
  }

  function getDropdownTarget(event) {
    let left = event.pageX;
    let top = event.pageY;
    const { x, y } = frameOffsets(event.target);
    left += x;
    top += y;

    return {
      getBoundingClientRect() {
        return { left, top, right: left, bottom: top, width: 0, height: 0 };
      },
    };
  }

  onDestroy(() => updateTarget(null));
</script>

{#if dropdownTarget}
  <Dropdown bind:this={dropdown} target={dropdownTarget} on:close={onClose} noFocus>
    <slot target={realTarget} />
  </Dropdown>
{/if}
