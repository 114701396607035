<script>
  import { t } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiContentCopy } from '@mdi/js';
  import { billing } from '../billing';
  import { generateReferralLink } from '../referral';

  let error;
  $: referralCode = $billing?.myReferralCode;

  async function generateReferralCode() {
    let response = await billing.generateReferralCode();
    referralCode = response.myReferralCode;
  }

  async function copyReferralLink() {
    await navigator.clipboard.writeText(generateReferralLink());
  }

  async function copyReferralCode() {
    await navigator.clipboard.writeText(referralCode);
  }
</script>

<div class="section">
  <h2>{$t('profile_referral_label')}</h2>
  <p class="description">{$t('profile_referral_description')}</p>

  <form>
    <section>
      {#if error}
        <Alert type="danger" dismissible>
          <strong>{$t('error')}</strong>
          {error}
        </Alert>
      {/if}

      <div class="row">
        {#if referralCode}
          <div class="row input-group">
            <input bind:value={referralCode} class="form-control" />
            <button on:click|preventDefault={copyReferralCode} class="btn primary">
              <Icon path={mdiContentCopy} inline />
              {$t('referral_copy_code')}
            </button>
            <button on:click|preventDefault={copyReferralLink} class="btn primary">
              <Icon path={mdiContentCopy} inline />
              {$t('referral_copy_link')}
            </button>
          </div>
        {:else}
          <button on:click|preventDefault={generateReferralCode} class="btn primary">
            {$t('referral_generate')}
          </button>
        {/if}
      </div>
    </section>
  </form>
</div>

<style>
  .input-group .btn {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  .input-group .btn:first-of-type {
    border-radius: 0;
  }
</style>
