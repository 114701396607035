<script>
  import { reading } from '../reading';
  import ReadToMe from './ReadToMe.svelte';
  import Thesaurus from './Thesaurus.svelte';

  reading.stop(); /* Stops reading after play, navigate away, then navigate back */
</script>

<Thesaurus />
<ReadToMe />
