import { settings } from '@dabble/app';
import './checklist';
import ChecklistHelp from './components/ChecklistHelp.svelte';
import ChecklistToggle from './components/ChecklistToggle.svelte';
import Checklist from './components/EntranceModal.svelte';
import LearnMore from './components/LearnMore.svelte';
import OnboardingRoutes from './components/OnboardingRoutes.svelte';
import './entrance';
import './locales';

settings.configure('dabble', {
  globals: { Checklist, LearnMore, ChecklistHelp },
  uiToggleMenu: { ChecklistToggle },
  routes: { OnboardingRoutes },
});
