<script lang="ts">
  import { plugins } from '@dabble/app';

  let admin: boolean;
  let Screens: any;

  $: admin = $plugins.currentUser && ($plugins.currentUser.admin || $plugins.currentUser.claims?.admin);
  $: if (admin && !Screens) loadScreens();

  async function loadScreens() {
    Screens = (await import(/* webpackChunkName: "admin" */ './AdminScreens.svelte')).default;
  }
</script>

{#if admin && Screens}
  <div class="section">
    <div class="screens">
      <svelte:component this={Screens} />
    </div>
  </div>
{/if}

<style>
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    background: var(--white);
    overflow-y: auto;
  }
  .screens {
    width: 1004px;
  }

  @media (max-width: 599.98px) {
    .screens {
      width: 100%;
    }
  }
  @media (min-width: 600px) and (max-width: 839.98px) {
    .screens {
      width: 600px;
    }
  }
  @media (min-width: 720px) {
    .screens {
      width: 720px;
    }
  }
  @media (min-width: 840px) {
    .screens {
      width: 840px;
    }
  }
</style>
