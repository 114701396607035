import { getUrl, projectStore, router, settings, userDocs, viewport } from "@dabble/app";
import { Doc } from "@dabble/data/types";
import { createDuplicate } from "./copy-paste-doc";

export async function createNewDoc(doc: Doc, parentId: string, index: number) {
  userDocs.update(parentId, { opened: true });
  return await projectStore.createDoc(doc, parentId, index);
}

export async function createFromTemplate(template: Doc, parentId: string, index: number) {
  const copy = createDuplicate(template, true);

  const id = projectStore.createDocId();
  await createNewDoc({ id, ...copy }, parentId, index);
  return id;
}

export async function scrollTo(doc: Doc, id: string) {
  if (!settings.get()[doc.type].hideInNavigation && !projectStore.contains(doc, id)) {
    const url = getUrl(id, projectStore.get().projectId);
    router.navigate(url);
  } else {
    await viewport.scrollIntoView({ id, field: 'body' });
  }
}
