<script>
  import { currentProjectMeta, features, projectMetas } from '@dabble/app';
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiSpellcheck } from '@mdi/js';
  import { allowedChecks } from '../model';

  function toggle(field) {
    if (!$features.has('spell_check') && field === 'spellingCheck') {
      features.promptUpgrade('spell_check');
    } else if (!$features.has('grammar') && field === 'grammarCheck') {
      features.promptUpgrade('grammar');
    } else {
      if ($currentProjectMeta) {
        const value = !$allowedChecks[field];
        projectMetas.update($currentProjectMeta.id, { [`${field}Check`]: value });
      }
    }
  }
</script>

<button
  class="dropdown-item"
  class:checked={$allowedChecks.spelling}
  on:click|stopPropagation={() => toggle('spelling')}
>
  <Icon path={mdiSpellcheck} />
  {$t('preferences_spellcheck_label')}
</button>
<button class="dropdown-item" class:checked={$allowedChecks.grammar} on:click|stopPropagation={() => toggle('grammar')}>
  <Icon path={mdiSpellcheck} />
  {$t('preferences_grammarcheck_label')}
</button>
