<script lang="ts">
  import { dateOptions, MOBILE, size, today } from '@dabble/app';
  import { DaysOff } from '@dabble/data/types';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
  import { addDays, addMonths, endOfMonth, format, isSameDay, isSameMonth, setDay, startOfMonth } from 'date-fns';
  import { createEventDispatcher } from 'svelte';
  import { isDayOff, isWeekdayOff, toggleDay, toggleWeekday } from '../days-off';

  export let month = undefined;
  export let selected: Date = undefined;
  export let daysOff: DaysOff = undefined;
  export let navigatable = true;
  const dispatch = createEventDispatcher();
  let currentMonth = startOfMonth(month || selected || new Date());
  let weeks: Date[][];
  let weekDays = [0, 1, 2, 3, 4, 5, 6].map(i => format(setDay(new Date(), i, dateOptions), 'EEE', dateOptions));
  let monthFormat: string;

  $: weeks = calculateWeeks(currentMonth, $today);
  $: monthFormat = $size === MOBILE ? 'MMM yyyy' : 'MMMM yyyy';
  $: console.log(selected);

  function calculateWeeks(currentMonth: Date, today: Date) {
    const end = endOfMonth(currentMonth);
    const weeks: Date[][] = [];
    let day = setDay(currentMonth, 0, dateOptions);
    for (let i = 0; i < 6; i++) {
      if (day > end) break;
      const week: Date[] = [];
      weeks.push(week);
      for (let j = 0; j < 7; j++) {
        week.push(day);
        day = addDays(day, 1);
      }
    }
    return weeks;
  }

  function onClick(day: Date) {
    if (!isSameMonth(day, currentMonth)) return;

    if (daysOff) {
      toggleDayByDate(day);
    } else {
      selectDayByDate(day);
    }
  }

  function nextMonth(direction: number) {
    currentMonth = addMonths(currentMonth, direction);
  }

  function toggleWeekdayByIndex(index: number) {
    if (!daysOff) return;
    daysOff = toggleWeekday(daysOff, index);
    dispatch('change', { daysOff });
  }

  function toggleDayByDate(date: Date) {
    if (!daysOff) return;
    daysOff = toggleDay(daysOff, date);
    dispatch('change', { daysOff });
  }

  function selectDayByDate(date: Date) {
    selected = isSameDay(date, selected) ? null : date;
    dispatch('select', { selected });
  }
</script>

{#if currentMonth}
  <div class="dabble-calendar">
    <div class="month-header">
      {#if navigatable}
        <button
          class="icon"
          on:click={() => nextMonth(-1)}
          use:tooltipTop={format(addMonths(currentMonth, -1), monthFormat, dateOptions)}
        >
          <Icon path={mdiChevronLeft} />
        </button>
      {/if}
      <span class="month-name">{format(currentMonth, monthFormat, dateOptions)}</span>
      {#if navigatable}
        <button
          class="icon"
          on:click={() => nextMonth(1)}
          use:tooltipTop={format(addMonths(currentMonth, 1), monthFormat, dateOptions)}
        >
          <Icon path={mdiChevronRight} />
        </button>
      {/if}
    </div>
    <div class="month-body" class:toggleable={daysOff}>
      <div class="day-names">
        {#each weekDays as day, index}
          <button class="day-name" class:off={isWeekdayOff(daysOff, index)} on:click={() => toggleWeekdayByIndex(index)}
            >{day}</button
          >
        {/each}
      </div>
      <div class="weeks">
        {#each weeks as week}
          <div class="week">
            {#each week as day}
              <button
                class="day"
                class:off={daysOff && isDayOff(daysOff, day)}
                class:out={!isSameMonth(day, currentMonth)}
                class:selected={isSameDay(day, selected)}
                class:today={isSameDay(day, $today)}
                on:click={() => onClick(day)}
              >
                {format(day, 'd', dateOptions)}
              </button>
            {/each}
          </div>
        {/each}
      </div>
    </div>
  </div>
{/if}

<style>
  .dabble-calendar {
    width: 296px;
    flex: 0 0 296px;
  }
  :global(.size-mobile) .dabble-calendar {
    width: 100%;
  }
  .dabble-calendar .month-header,
  .dabble-calendar .month-body {
    background: var(--calendar-background);
    border: var(--calendar-border);
  }
  .dabble-calendar .month-header {
    display: flex;
    align-items: center;
    height: 42px;
    color: var(--text-color-light);
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    padding: 2px 8px;
    margin-bottom: 2px;
  }
  .dabble-calendar .month-header .month-name {
    flex: 1 1 auto;
  }
  .dabble-calendar .day-names,
  .dabble-calendar .week {
    display: flex;
    padding: 0 1px;
    user-select: none;
  }
  .dabble-calendar .weeks {
    padding: 2px 0;
  }
  .dabble-calendar .day-names {
    padding-bottom: 2px;
  }
  .dabble-calendar .day-name {
    flex: 1;
    margin: 1px;
    padding: 0;
    border: none;
    line-height: 16px;
    font-size: 11px;
    color: var(--text-color-lighterer);
    text-align: center;
    transition: box-shadow 0.1s linear, color 0.1s linear;
  }
  .dabble-calendar .toggleable .day-name {
    cursor: pointer;
  }
  .dabble-calendar .toggleable .day-name:hover {
    text-decoration: underline;
    color: var(--text-color-lighter);
  }
  .dabble-calendar .day-name.off {
    color: var(--text-color-lightest);
  }
  .dabble-calendar .day-name.off:hover {
    color: var(--text-color-lighterer);
  }
  .dabble-calendar .day {
    flex: 1;
    margin: 1px;
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: var(--text-color-lighterer);
    background: var(--page-background);
    padding: 0;
    border: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: box-shadow 0.1s linear, color 0.1s linear;
  }
  .dabble-calendar .day:not(.out):hover {
    text-decoration: underline;
    color: var(--text-color-lighter);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
  }
  .dabble-calendar .day.off:not(.out) {
    background: none;
    box-shadow: none;
  }
  .dabble-calendar .day.off:not(.out):hover {
    box-shadow: none;
  }
  .dabble-calendar .day.out,
  .dabble-calendar .day.out:hover {
    cursor: default;
    background: none;
    color: var(--text-color-lightest);
    box-shadow: none;
  }
  .dabble-calendar .day.today {
    font-weight: bold;
    padding-top: 3px;
    line-height: 29px;
  }
  .dabble-calendar .day.selected {
    position: relative;
    color: var(--dabble-blue);
    outline: 3px double var(--dabble-blue);
    outline-offset: -2px;
  }
  .dabble-calendar .day.selected:hover {
    color: var(--dabble-blue);
  }
</style>
