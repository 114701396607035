import { connect, disconnect } from '@dabble/app';
import { getImmutable, getImmutableValue, makeChanges } from '@dabble/util/immutable';
import { Browserbase } from 'browserbase';
import { Delta, Op, TextDocument } from 'typewriter-editor';
import log from '../util/log';
import { Change } from './collective/changes';
import { projectMetaFields } from './stores/project-metas';
import { Readable, Subscriber, Unsubscriber, writable } from './stores/store';
import { userProjectFields } from './stores/user-projects';
import { DabbleDatabase, ProjectMeta, ProjectSnapshot } from './types';

const TEXT_FIELDS = { body: true, description: true };

export interface DatabaseStore extends Readable<DabbleDatabase> {
  // Register is *like* subscribe, but the open function will await on the return of each registered function.
  register(listener: Subscriber<DabbleDatabase>): Unsubscriber;
  open(name: string): Promise<void>;
  close(deleteDatabase?: boolean): void;
}

export function createDatabaseStore(): DatabaseStore {
  let db: DabbleDatabase;
  let closing: boolean;
  const registered = new Set<Subscriber<DabbleDatabase>>();
  const { get, set, subscribe } = writable<DabbleDatabase>(null);

  function register(listener: Subscriber<DabbleDatabase>) {
    registered.add(listener);
    return () => registered.delete(listener);
  }

  async function open(uid: string) {
    db = await openDatabase(uid);
    db.addEventListener('recreated', async () => {
      await disconnect();
      await connect();
    }); // If the database was deleted, resync
    set(db);
    await Promise.all(Array.from(registered).map(run => run(db)));
  }

  function close(deleteDatabase?: boolean) {
    if (!db) return;
    closing = true;
    Array.from(registered).map(run => run(null));
    db.close();
    if (deleteDatabase === true) {
      db.deleteDatabase();
    }
    closing = false;
    set((db = null));
  }

  return {
    get,
    register,
    open,
    close,
    subscribe,
  };
}

export async function deleteDatabase(uid: string) {
  Browserbase.deleteDatabase(`dabble/${uid}`);
}

export async function openDatabase(uid: string) {
  // Because I screwed up, first try to load the database by uid, but if it doesn't exist, use dabble/uid as the correct
  // database name
  const name = `dabble/${uid}`;
  const db = new Browserbase(name) as DabbleDatabase;

  db.version(1, {
    project_changes: '[projectId+version], [projectId+committed+version], created',
    project_snapshots: '[id+version], [id+committed+version]',
    user_docs: '[projectId+docId], modified',
    user_stats_by_date: '[date+projectId], modified',
    user_projects: 'id, modified',
    user_goals: 'id, modified',
    user_meta: 'id, modified', // to store user meta data
    other: 'id', // a flexible bucket to store other local data for plugins not handled by core
  })
    .version(2, {
      global: 'id, modified', // to store global meta data
    })
    .version(3, {}, async (oldVersion, transaction) => {
      let trans = db.start(transaction);

      // Set the versionupgrade transaction as the transaction for the following operation
      const [projects, goals, meta] = await Promise.all([
        trans.stores.user_projects.getAll(),
        trans.stores.user_goals.getAll(),
        trans.stores.user_meta.getAll(),
      ]);

      // Update the object data
      projects.forEach(project => (project.projectId = (project as any).id) && delete (project as any).id);
      goals.forEach(goal => (goal.projectId = (goal as any).id) && delete (goal as any).id);
      meta.forEach(meta => (meta.type = (meta as any).id) && delete (meta as any).id);

      // Set the versionupgrade transaction as the transaction for the following operations
      trans = db.start(transaction);

      // Delete tables
      trans.upgradeStore('user_projects', null);
      trans.upgradeStore('user_goals', null);
      trans.upgradeStore('user_meta', null);

      // Re-create tables with new primary keys
      trans.upgradeStore('user_projects', 'projectId, modified');
      trans.upgradeStore('user_goals', 'projectId, modified');
      trans.upgradeStore('user_meta', 'type, modified');

      // Re-populate the new tables
      if (projects.length) trans.stores.user_projects.putAll(projects);
      if (goals.length) trans.stores.user_goals.putAll(goals);
      if (meta.length) trans.stores.user_meta.putAll(meta);
    })
    .version(
      4,
      {
        project_metas: 'id, modified',
      },
      async (oldVersion, transaction) => {
        let trans = db.start(transaction);

        // Set the versionupgrade transaction as the transaction for the following operation
        let userProjects = await trans.stores.user_projects.getAll();

        // Split the data
        const projectMeta = userProjects.map(
          p => removeFieldsExcept({ id: p.projectId, ...p }, projectMetaFields) as ProjectMeta
        );
        userProjects = userProjects.map(p => removeFieldsExcept({ ...p }, userProjectFields));

        // Set the versionupgrade transaction as the transaction for the following operations
        trans = db.start(transaction);

        // Re-populate the new tables
        trans.stores.user_projects.putAll(userProjects);
        trans.stores.project_metas.putAll(projectMeta);
      }
    )
    .version(5, {
      analytics: '++id',
    })
    .version(6, {
      content_uploads: 'name',
    })
    .version(7, {
      project_metas: 'committed',
    })
    // The current version which will be used instead of running 1-latest as a shortcut for new databases (also great doc)
    .version(0, {
      analytics: '++id',
      project_changes: '[projectId+version], [projectId+committed+version], created',
      project_snapshots: '[id+version], [id+committed+version]',
      project_metas: 'id, modified, committed',
      user_docs: '[projectId+docId], modified',
      user_stats_by_date: '[date+projectId], modified',
      user_projects: 'projectId, modified',
      user_goals: 'projectId, modified',
      user_meta: 'type, modified', // to store user meta data
      content_uploads: 'name',
      other: 'id', // a flexible bucket to store other local data for plugins not handled by core
      global: 'id, modified', // to store global meta data
    });

  await db.open();

  log.tagColor('Load', '#444', 'Opened database', db.name);

  db.stores.project_snapshots.store = storeText;
  db.stores.project_snapshots.revive = reviveText;
  db.stores.project_changes.revive = reviveTextInChange;

  return db;
}

function storeText(snapshot: ProjectSnapshot) {
  if (!snapshot) return snapshot;
  return makeChanges(() => {
    let docs = snapshot.docs;
    Object.keys(docs).forEach(docId => {
      let doc = docs[docId];
      Object.keys(doc).forEach(key => {
        const value = doc[key];
        if (value instanceof TextDocument) {
          snapshot = getImmutableValue(snapshot);
          docs = getImmutable(snapshot, 'docs');
          doc = getImmutable(docs, docId);
          doc[key] = { ops: value.toDelta().ops };
        }
      });
    });
    return snapshot;
  });
}

function reviveText(snapshot: ProjectSnapshot) {
  if (!snapshot || !snapshot.docs) return snapshot;
  Object.keys(snapshot.docs).forEach(docId => {
    const doc = snapshot.docs[docId];
    Object.keys(doc).forEach(key => {
      const value = doc[key];
      if (!value) return;
      if (Array.isArray(value.ops)) {
        doc[key] = opsToDocument(value.ops);
      } else if (key in TEXT_FIELDS && Array.isArray(value)) {
        doc[key] = opsToDocument(value as Op[]);
      }
    });
  });
  return snapshot;
}

function reviveTextInChange(change: Change) {
  if (!change || !change.ops) return change;
  change.ops.forEach(op => {
    if (op.path === '' && (op.op === 'add' || op.op === 'replace')) {
      if (op.value && typeof op.value === 'object' && op.value.docs) {
        reviveText(op.value);
      }
    }
  });
  return change;
}

// Remove all fields except for those listedn in the set
export function removeFieldsExcept(obj: any, fields: Set<string>) {
  Object.keys(obj).forEach(key => {
    if (!fields.has(key)) {
      delete (obj as any)[key];
    }
  });
  return obj;
}

(window as any).dbExists = dbExists;

function dbExists(dbname: string) {
  return new Promise(resolve => {
    const req = indexedDB.open(dbname);
    let exists = true;
    function onFinish() {
      if (req.result && req.result.close) req.result.close();
      // If it got created, delete it
      if (!exists) indexedDB.deleteDatabase(dbname);
      resolve(exists);
    }
    req.onsuccess = onFinish;
    req.onblocked = onFinish;
    req.onerror = onFinish;
    req.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const oldVersion = event.oldVersion > Math.pow(2, 62) ? 0 : event.oldVersion; // Safari 8 fix.
      if (oldVersion === 0) {
        exists = false;
        req.transaction.abort();
      }
    };
  });
}

function opsToDocument(ops: Op[]) {
  ops.forEach(op => {
    if (op.attributes?.id) delete op.attributes.id;
  });
  return new TextDocument(new Delta(ops));
}
