import { settings } from '@dabble/app';
import { load } from './accounts';
import AccountMenu from './components/AccountMenu.svelte';
import AccountRoutes from './components/AccountRoutes.svelte';
import HelpMenu from './components/HelpMenu.svelte';
import './locales';

settings.configure('dabble', {
  headerRight: {
    AccountMenu,
    HelpMenu,
  },
  routes: { AccountRoutes },
});

load();
