<script>
  import { features } from '@dabble/app';
  import { tick } from 'svelte';
  import { showStickyNotes, stickyNotesStore } from '../stickies-store';
  import Sticky from './Sticky.svelte';

  export let pageElement;
  let notes = [];

  $: showNotes = $features.has('stickies') && $showStickyNotes;
  $: showNotes && findNotes(pageElement, $stickyNotesStore);
  $: if (!showNotes) notes = [];

  async function findNotes(pageElement, allNotes) {
    await tick();
    if (!pageElement) return [];
    const docIds = new Set();
    Array.from(pageElement.querySelectorAll('[data-id][data-field="body"]')).forEach(node =>
      docIds.add(node.dataset.id)
    );
    notes = Object.values(allNotes).filter(sticky => docIds.has(sticky.docId));
  }
</script>

{#if showNotes && notes.length}
  <div class="stickies">
    {#each notes as note (note.id)}
      <Sticky {pageElement} {note} />
    {/each}
  </div>
{/if}

<style>
  .stickies {
    position: relative;
    z-index: 1;
  }
</style>
