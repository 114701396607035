import { settings } from '@dabble/app';
import VersioningButton from './components/VersioningButton.svelte';
import VersioningGlobals from './components/VersioningGlobals.svelte';
import './locales';
import './styles.css';
import historyModule from './versioning-module';

settings.configure('dabble', {
  toolButtonsBottom: {
    VersioningButton,
  },
  globals: {
    VersioningGlobals,
  },
  editorModules: {
    versioning: historyModule,
  },
});
