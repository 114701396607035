<script lang="ts">
  import { delegate, projectStore, settings, t } from '@dabble/app';
  import { User } from '@dabble/data/types';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import {
    mdiAccountCircle,
    mdiAccountMultiple,
    mdiAccountMultiplePlus,
    mdiAccountSupervisorCircle,
    mdiCardAccountDetails,
    mdiLogoutVariant,
    mdiTune,
  } from '@mdi/js';
  import { accounts, signOut as authSignOut, currentUser, startAuth, switchTo } from '../accounts';

  let dropdownVisible = false;

  $: otherAccounts = ($accounts && $currentUser && $accounts.filter(a => a.uid !== $currentUser.uid)) || [];
  $: accountMenuItems = settings.getValuesFromPlugins('plugins.account.menuItems', $projectStore.project);

  function signIn() {
    startAuth(true);
    dropdownVisible = false;
  }

  function signOut() {
    authSignOut();
    dropdownVisible = false;
  }

  function firstName(user: User) {
    return (user.name && user.name.split(/\s+/)[0]) || user.email;
  }
</script>

{#if delegate && $currentUser}
  <span class="delegate-message">Signed in as <span class="delegate-name">{firstName($currentUser)}</span></span>
{/if}
{#if $currentUser}
  <button
    class="btn dropdown-toggle focus-fade"
    class:active={dropdownVisible}
    on:click={() => (dropdownVisible = true)}
  >
    <Icon path={delegate ? mdiAccountSupervisorCircle : mdiAccountCircle} />
  </button>
  {#if dropdownVisible}
    <Dropdown on:close={() => (dropdownVisible = false)} placement="bottom-end">
      <a class="dropdown-item" href="/account/preferences">
        <span><Icon path={mdiTune} inline />{$t('account_screen_preferences')}</span>
      </a>
      <a class="dropdown-item" href="/account/profile">
        <span><Icon path={mdiCardAccountDetails} inline />{$t('account_screen_profile')}</span>
      </a>
      {#each accountMenuItems as component}
        <svelte:component this={component} />
      {/each}
      <hr />
      {#if otherAccounts.length}
        <div>
          <header>{$t('account_switch_account')}</header>
          {#each otherAccounts as account}
            <button on:click={() => switchTo(account)} class="dropdown-item switch-account">
              <Icon path={mdiAccountMultiple} />
              <span class="name">{account.name || account.email}</span>
            </button>
          {/each}
          <hr />
        </div>
      {/if}
      <button on:click={signIn} class="dropdown-item">
        <Icon path={mdiAccountMultiplePlus} />
        <span>{$t('account_login_another')}</span>
      </button>
      <button on:click={signOut} class="dropdown-item">
        <Icon path={mdiLogoutVariant} />
        {$t('account_logout')}
        {#if delegate}
          from&nbsp;<span class="delegate-name">{$currentUser.name || $currentUser.email}</span>
        {/if}
      </button>
    </Dropdown>
  {/if}
{/if}

<style>
  .delegate-message {
    font-size: var(--font-size-xs);
    color: var(--text-color-lightest);
    white-space: nowrap;
  }
  .delegate-message .delegate-name {
    color: var(--text-color-lighterer);
  }
  .dropdown-item .delegate-name {
    font-weight: bold;
  }
</style>
