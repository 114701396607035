<script lang="ts">
  import { desktop, locale, locales, preferences, settings, t } from '@dabble/app';
  import BlankScreen from '@dabble/toolkit/BlankScreen.svelte';
  import CheckButton from '@dabble/toolkit/CheckButton.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiTune } from '@mdi/js';

  export const showOffline = false;
  const languageNames = new Intl.DisplayNames(undefined, { type: 'language' });

  window.dispatchEvent(new CustomEvent('currentscreen', { detail: { name: 'Preferences' } }));

  $: currentLocale = $preferences.locale || $locale;

  function onLocaleChange(event: Event) {
    const locale = (event.target as HTMLSelectElement).value;
    preferences.update({ locale });
  }

  function toggleDesktopTouchSettings(mode: 'touch' | 'desktop') {
    if ($preferences.preferTouchUI && mode === 'touch') {
      preferences.update({ preferTouchUI: false });
    } else if ($preferences.preferNoMobile && mode === 'desktop') {
      preferences.update({ preferNoMobile: false });
    } else if (!$preferences.preferTouchUI && !$preferences.preferNoMobile) {
      if (mode === 'touch') {
        preferences.update({ preferTouchUI: true });
      } else {
        preferences.update({ preferNoMobile: true });
      }
    } else {
      preferences.update({
        preferTouchUI: !$preferences.preferTouchUI,
        preferNoMobile: !$preferences.preferNoMobile,
      });
    }
  }
</script>

<BlankScreen>
  <svelte:fragment slot="title">
    <Icon path={mdiTune} inline />
    {$t('account_screen_preferences')}
  </svelte:fragment>

  <div class="preferences">
    <div class="section">
      <h2>{$t('account_user_interface')}</h2>

      <div class="preference vertical">
        <label for="localeLanguage">{$t('account_ui_language')}</label>

        <select class="form-control" id="localLanguage" on:change={onLocaleChange}>
          <option value="" disabled>{$t('account_set_ui_language')}</option>
          {#each $locales as locale}
            <option selected={locale === currentLocale} value={locale}>{languageNames.of(locale)}</option>
          {/each}
        </select>
      </div>

      <div class="preference vertical">
        <CheckButton
          on:click={() => preferences.update({ hideLoadingQuotes: !$preferences.hideLoadingQuotes })}
          checked={!$preferences.hideLoadingQuotes}
        >
          {$t('preferences_loading_quote_label')}
        </CheckButton>
        <div class="description">{$t('preferences_loading_quote_description')}</div>
      </div>

      <div class="preference vertical vertical">
        <CheckButton
          on:click={() => preferences.update({ hideHolidays: !$preferences.hideHolidays })}
          checked={!$preferences.hideHolidays}
        >
          {$t('preferences_holidays_label')}
        </CheckButton>
        <div class="description">{$t('preferences_holidays_description')}</div>
      </div>

      <div class="preference vertical">
        <CheckButton
          on:click={() => preferences.update({ noAutofade: !$preferences.noAutofade })}
          checked={!$preferences.noAutofade}
        >
          {$t('preferences_autofade_label')}
        </CheckButton>
        <div class="description">{$t('preferences_autofade_description')}</div>
      </div>

      <div class="preference vertical">
        <CheckButton
          on:click={() => preferences.update({ noScrollLock: !$preferences.noScrollLock })}
          checked={!$preferences.noScrollLock}
        >
          {$t('preferences_autoscroll_label')}
        </CheckButton>
        <div class="description">{$t('preferences_autoscroll_description')}</div>
      </div>

      <div class="preference vertical">
        <CheckButton
          on:click={() => preferences.update({ allowDoubleSpace: !$preferences.allowDoubleSpace })}
          checked={!$preferences.allowDoubleSpace}
        >
          {$t('preferences_extra_space_label')}
        </CheckButton>
        <div class="description">{$t('preferences_extra_space_description')}</div>
      </div>

      {#if !desktop.inApp}
        <div class="preference vertical">
          <CheckButton
            on:click={() => preferences.update({ preferNativeFind: !$preferences.preferNativeFind })}
            checked={!$preferences.preferNativeFind}
          >
            {$t('preferences_native_find_label')}
          </CheckButton>
          <div class="description">{$t('preferences_native_find_description')}</div>
        </div>
      {/if}

      {#if !desktop.inApp}
        <div class="preference vertical">
          <CheckButton
            on:click={() => preferences.update({ preferNativeContextMenu: !$preferences.preferNativeContextMenu })}
            checked={!$preferences.preferNativeContextMenu}
          >
            {$t('preferences_native_context_menu_label')}
          </CheckButton>
          <div class="description">{$t('preferences_native_context_menu_description')}</div>
        </div>
      {/if}

      <div class="preference vertical">
        <CheckButton on:click={() => toggleDesktopTouchSettings('touch')} checked={$preferences.preferTouchUI}>
          {$t('preferences_touch_ui_label')}
        </CheckButton>
        <div class="description">{$t('preferences_touch_ui_description')}</div>
      </div>

      <div class="preference vertical">
        <CheckButton on:click={() => toggleDesktopTouchSettings('desktop')} checked={$preferences.preferNoMobile}>
          {$t('preferences_no_mobile_label')}
        </CheckButton>
        <div class="description">{$t('preferences_no_mobile_description')}</div>
      </div>
    </div>

    {#each settings.getValuesFromPlugins('plugins.account.preferences') as pref}
      <svelte:component this={pref} />
    {/each}
  </div>
</BlankScreen>

<style>
  .preferences {
    display: flex;
    flex-direction: column;
  }
  .preferences :global(label),
  .preferences :global(h6) {
    margin-top: 0;
    font-weight: bold;
    color: var(--text-color-lighter);
    text-transform: uppercase;
  }
  .preferences :global(.preference) {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .preferences :global(.preference.vertical) {
    flex-direction: column;
    align-items: flex-start;
  }
  .preferences :global(.preference:not(.vertical) .description) {
    margin-left: 10px;
  }
  .preferences :global(.preference.vertical .description) {
    margin-bottom: 10px;
  }
  .preferences :global(.preference.vertical .buttons) {
    margin-top: 10px;
  }
  @media (max-width: 688px) {
    .preferences :global(.preference) {
      flex-direction: column;
      align-items: flex-start;
    }
    .preferences :global(.check-button) {
      margin-right: 0;
      margin-bottom: 4px;
    }
  }
</style>
