import { isTouch, plugins, preferences, projectStore, tabStore } from '@dabble/app';
import { isApple } from '@dabble/data/device';
import EventDispatcher from '@dabble/util/eventDispatcher';
import throttle from 'lodash/throttle';
import { KeyboardEventWithShortcut, addShortcutsToEvent } from 'typewriter-editor';

const INACTIVE_TIME = 10_000; // every 10 seconds report activity
const metaKey = isApple ? 'Cmd' : 'Ctrl';
const events = [
  'keydown',
  'keyup',
  'mousemove',
  'click',
  'contextmenu',
  'mousedown',
  'touchstart',
  'touchend',
  'touchcancel',
  'mouseover',
];
const windowEvents = ['blur'];
const preventedEvents = ['dragover', 'drop'];
const dispatcher = new EventDispatcher();
const docs = new Set<Document>();
const windows = new Map<Document, Window>();
const resetInactiveTimer = throttle(resetInactiveTimerOriginal, INACTIVE_TIME);

const MOUSE = 'is-mouse';
const TOUCH = 'is-touch';
const POINTERS = { [MOUSE]: TOUCH, [TOUCH]: MOUSE, touchstart: TOUCH, mouseover: MOUSE };
type PointerType = 'is-mouse' | 'is-touch';
let justTouched = false;
let isTouchTimer: any;

export { events, resetInactiveTimer };
export default dispatcher;

export function listen(doc: Document) {
  const win = doc.defaultView;
  docs.add(doc);
  windows.set(doc, win);
  doc.documentElement.classList.add(isTouch.get() ? TOUCH : MOUSE);
  events.forEach(event => doc.addEventListener(event, onEvent));
  windowEvents.forEach(event => win.addEventListener(event, onEvent));
  preventedEvents.forEach(event => doc.addEventListener(event, preventDefault));
}

export function unlisten(doc: Document) {
  const win = windows.get(doc);
  docs.delete(doc);
  windows.delete(doc);
  events.forEach(event => doc.removeEventListener(event, onEvent));
  if (win) windowEvents.forEach(event => win.removeEventListener(event, onEvent));
  preventedEvents.forEach(event => doc.removeEventListener(event, preventDefault));
}

export function hasFocus() {
  return Array.from(docs).some(doc => doc.hasFocus());
}

function onEvent(event: KeyboardEventWithShortcut) {
  if (event.type !== 'blur') resetInactiveTimer();
  if (event.type.startsWith('key') && !('shortcut' in event)) {
    addShortcutsToEvent(event);
  }

  dispatcher.dispatch(event.type, event);

  if (event.type === 'touchend' || event.type === 'touchcancel') {
    clearTimeout(isTouchTimer);
    isTouchTimer = setTimeout(() => (justTouched = false), 500);
  }

  if (event.type === 'touchstart' || event.type === 'mouseover') {
    if (event.type === 'touchstart') {
      justTouched = true;
      clearTimeout(isTouchTimer);
    } else {
      if (justTouched) return;
    }

    const type = POINTERS[event.type] as PointerType;
    const preferTouch = preferences.get().preferTouchUI;
    isTouch.set(type === TOUCH || (preferTouch && isTouch.get()));
  }

  if (event.type === 'keydown') {
    if (event.key === 'Backspace') {
      // Allow to work within iframes
      const doc =
        ((event.target as Element).ownerDocument.activeElement as HTMLIFrameElement).contentDocument ||
        (event.target as Element).ownerDocument;

      if (doc.activeElement === doc.documentElement) {
        event.preventDefault();
      }
    }

    if (event.shortcut === `${metaKey}+S`) {
      onSave(event);
    }

    dispatcher.dispatch(`shortcut:${event.shortcut}`, event);
    if (~event.shortcut.indexOf(metaKey)) {
      dispatcher.dispatch(`shortcut:${event.shortcut.replace(metaKey, 'Meta')}`, event);
    }
    dispatcher.dispatch('shortcut', event);
  }
}

function resetInactiveTimerOriginal() {
  tabStore.get().call('hadActivity');
}

function onSave(event: KeyboardEvent) {
  event.preventDefault();

  const saveFunctions = plugins.stores.projectSaveFunctions.get();
  const save = saveFunctions.get(projectStore.get().projectId);
  if (save) save(); // save will be undefined if not online and syncing

  if (projectStore.get().project) projectStore.commitQueuedTextChanges();
}

function preventDefault(event: Event) {
  event.preventDefault();
}

Promise.resolve().then(() => {
  isTouch.subscribe(isTouch => {
    const remove = isTouch ? MOUSE : TOUCH;
    const add = isTouch ? TOUCH : MOUSE;
    docs.forEach(doc => {
      doc.documentElement.classList.remove(remove);
      doc.documentElement.classList.add(add);
    });
  });

  // Prevent drag-drop from redirecting to another page (e.g. with an image dropped onto the page)
  listen(document);
});
