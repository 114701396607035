import { DatabaseStore } from '../dabble-database';
import { UserMeta } from '../types';
import {
  createLookupFromList,
  createModifiedDataStore,
  Lookup,
  ModifiedDataStore,
  UpdateOptions,
} from './modified-data';
import { FromRemote } from './remote-support';
import { derived, Readable } from './store';

const EMPTY = {};

export type AllUserMeta = Lookup<UserMeta>;

export type UserMetaStore = ModifiedDataStore<UserMeta, AllUserMeta>;

export interface UserMetaTypeStore<T extends UserMeta> extends Readable<T> {
  update: (updates: Partial<T>, options?: UpdateOptions) => Promise<T>;
}

export function createUserMetaStore(dbStore: DatabaseStore, fromRemote?: FromRemote): UserMetaStore {
  return createModifiedDataStore(dbStore, 'user_meta', {
    fromRemote,
    packageData: list => createLookupFromList(list, 'type'),
  });
}

export function createUserMetaTypeStore<T extends UserMeta>(
  lookupStore: UserMetaStore,
  key: string
): UserMetaTypeStore<T> {
  const { get, subscribe } = derived<T, UserMetaStore>(lookupStore, lookup => (lookup[key] || {}) as T);

  async function update(updates: Partial<T>, options?: UpdateOptions) {
    return (await lookupStore.update(key, updates, options)) as T;
  }

  return {
    get,
    update,
    subscribe,
  };
}

export function createUserMetaVariableStore<T extends UserMeta>(
  lookupStore: UserMetaStore,
  key: Readable<string>
): UserMetaTypeStore<T> {
  const { get, subscribe } = derived<T, [UserMetaStore, Readable<string>]>(
    [lookupStore, key],
    ([lookup, key]) => (lookup[key] || EMPTY) as T
  );

  async function update(updates: Partial<T>, options?: UpdateOptions) {
    return (await lookupStore.update(key.get(), updates, options)) as T;
  }

  return {
    get,
    update,
    subscribe,
  };
}
