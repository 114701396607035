import { is_function, noop, not_equal, run_all } from 'svelte/internal';

/** Callback to inform of a value updates. */
export type Subscriber<T> = (value: T) => void;

/** Unsubscribes from value updates. */
export type Unsubscriber = () => void;

/** Callback to update a value. */
export type Updater<T> = (value: T) => T;

/** Cleanup logic callback. */
export type Invalidator<T> = (value?: T) => void;

/** Start and stop notification callbacks. */
export type StartStopNotifier<T> = (set: Subscriber<T>) => Unsubscriber | void;

/** Readable interface for subscribing. */
export interface Readable<T> {
  /**
   * Returns the value of this store.
   */
  get(): T;
  /**
   * Subscribe on value changes.
   * @param run subscription callback
   * @param invalidate cleanup callback
   */
  subscribe(run: Subscriber<T>, invalidate?: Invalidator<T>): Unsubscriber;
}

/** Writable interface for both updating and subscribing. */
export interface Writable<T> extends Readable<T> {
  /**
   * Set value and inform subscribers.
   * @param value to set
   */
  set(value: T): Promise<void>;

  /**
   * Update value using callback and inform subscribers.
   * @param updater callback
   */
  update(updater: Updater<T>): Promise<void>;
}

/** Pair of subscriber and invalidator. */
type SubscribeInvalidateTuple<T> = [Subscriber<T>, Invalidator<T>];

const subscriber_queue: any[] = [];

/**
 * Creates a `Readable` store that allows reading by subscription.
 * @param value initial value
 * @param {StartStopNotifier}start start and stop notifications for subscriptions
 */
export function readable<T>(value?: T, start?: StartStopNotifier<T>): Readable<T> {
  const { get, subscribe } = writable(value, start);
  return {
    get,
    subscribe,
  };
}

/**
 * Create a `Writable` store that allows both updating and reading by subscription.
 * @param {*=}value initial value
 * @param {StartStopNotifier=}start start and stop notifications for subscriptions
 */
export function writable<T>(value: T, start: StartStopNotifier<T> = noop): Writable<T> {
  let stop: Unsubscriber;
  const subscribers: Array<SubscribeInvalidateTuple<T>> = [];

  function get(): T {
    if (!subscribers.length) (start(set) || noop)();
    return value;
  }

  function set(new_value: T): Promise<void> {
    if (not_equal(value, new_value)) {
      const promises = [];
      value = new_value;
      if (stop) {
        // store is ready
        const run_queue = !subscriber_queue.length;
        for (let i = 0; i < subscribers.length; i += 1) {
          const s = subscribers[i];
          s[1]();
          subscriber_queue.push(s, value);
        }
        if (run_queue) {
          for (let i = 0; i < subscriber_queue.length; i += 2) {
            promises.push(subscriber_queue[i][0](subscriber_queue[i + 1]));
          }
          subscriber_queue.length = 0;
        }
      }
      return Promise.all(promises).then(() => {});
    }
    return Promise.resolve();
  }

  function update(fn: Updater<T>): Promise<void> {
    return set(fn(value));
  }

  function subscribe(run: Subscriber<T>, invalidate: Invalidator<T> = noop): Unsubscriber {
    const subscriber: SubscribeInvalidateTuple<T> = [run, invalidate];
    subscribers.push(subscriber);
    if (subscribers.length === 1) {
      stop = start(set) || noop;
    }
    run(value);

    return () => {
      const index = subscribers.indexOf(subscriber);
      if (index !== -1) {
        subscribers.splice(index, 1);
      }
      if (subscribers.length === 0) {
        stop();
        stop = null;
      }
    };
  }

  return { get, set, update, subscribe };
}

export function immutableWritable<T>(value: T, start: StartStopNotifier<T> = noop): Writable<T> {
  const { get, update, set, subscribe } = writable<T>(value, start);
  function immutableSet(value: T) {
    return set({ ...value });
  }
  return { get, update, set: immutableSet, subscribe };
}

/** One or more `Readable`s. */
export type Stores = Readable<any> | [Readable<any>, ...Array<Readable<any>>];

/** One or more values from `Readable` stores. */
export type StoresValues<T> = T extends Readable<infer U>
  ? U
  : { [K in keyof T]: T[K] extends Readable<infer U> ? U : never };

/**
 * Derived value store by synchronizing one or more readable stores and
 * applying an aggregation function over its input values.
 * @param {Stores} stores input stores
 * @param {function(Stores=, function(*)=):*}fn function callback that aggregates the values
 * @param {*=}initial_value when used asynchronously
 */
export function derived<T, S extends Stores>(
  stores: S,
  fn: (values: StoresValues<S>, set?: Subscriber<T>) => T | Unsubscriber | void,
  initial_value?: T
): Readable<T> {
  const single = !Array.isArray(stores);
  const stores_array: Array<Readable<any>> = single ? [stores as Readable<any>] : (stores as Array<Readable<any>>);

  const auto = fn.length < 2;

  return readable(initial_value, set => {
    let inited = false;
    const values: StoresValues<S> = [] as StoresValues<S>;

    let pending = 0;
    let cleanup = noop;

    const sync = () => {
      if (pending) {
        return;
      }
      cleanup();
      const result = fn(single ? values[0] : values.slice(), set);
      if (auto) {
        set(result as T);
      } else {
        cleanup = is_function(result) ? (result as Unsubscriber) : noop;
      }
    };

    const unsubscribers = stores_array.map((store, i) =>
      store.subscribe(
        value => {
          pending &= ~(1 << i);
          if (not_equal(values[i], value)) {
            values[i] = value;
            if (inited) {
              sync();
            }
          }
        },
        () => {
          pending |= 1 << i;
        }
      )
    );

    inited = true;
    sync();

    return function stop() {
      run_all(unsubscribers);
      cleanup();
    };
  });
}
