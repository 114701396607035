import { plugins } from '@dabble/app';
import rest from '@dabble/data/rest';
import { tick } from 'svelte';

const DAY = 1000 * 60 * 60 * 24;
const THIRTY_DAYS_AGO = Date.now() - DAY * 30;
const THIRTYONE_DAYS = Date.now() + DAY * 31;

async function setup() {
  await tick();
  const { currentUser } = plugins.stores;
  currentUser.subscribe(user => user && user.createdAt > THIRTY_DAYS_AGO && sendUtmData());
}

export function getCookie(name: string) {
  const prefix = `${name}=`;
  const cookies = decodeURIComponent(document.cookie).split(/\s*;\s*/);
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    if (cookie.startsWith(prefix)) return cookie.replace(prefix, '');
  }
  return undefined;
}

export function deleteCookie(cname: string) {
  document.cookie = `${cname}={"sent":true}; expires=${new Date(
    THIRTYONE_DAYS
  ).toUTCString()}; path=/; domain=.dabblewriter.com;`;
}

function getUtmCookie(): Record<string, any> {
  const utmJSONString = getCookie('utmData');
  if (utmJSONString) {
    return JSON.parse(utmJSONString);
  }
  return {}; // Send empty to indicate no utm data
}

export async function sendUtmData() {
  const data = getUtmCookie();
  if (!data.sent) {
    try {
      //rest.hooks.push(sendAfterAuthHook);
      await rest.POST('utm-data', { utmData: data });
      deleteCookie('utmData');
    } catch (err) {
      console.error(err);
    }
  }
}

setup();
