import type { ApplyJSONPatchOptions, JSONPatchCustomTypes, JSONPatchOp } from '@typewriter/json-patch';
import { JSONPatch as TypewriterJsonPatch } from '@typewriter/json-patch';
import { changeText } from '@typewriter/json-patch/lib/custom/text-document';
export { JSONPatchOp };

const applyOptions: ApplyJSONPatchOptions = {
  strict: true,
};

const types: JSONPatchCustomTypes = {
  ['@changeText']: changeText,
};

/**
 * A JSONPatch helps with creating and applying one or more "JSON patches". It can track one or more changes
 * together which may form a single operation or transaction.
 */
export default class JSONPatch extends TypewriterJsonPatch {
  /**
   * Create a new JSONPatch, optionally with an existing array of operations.
   */
  constructor(ops: JSONPatchOp[] = []) {
    super(ops, types);
  }

  /**
   * Sets/updates the Delta document at `path` with the given Delta change. If the Delta document does not exist, this
   * will create the new document.
   */
  changeText(path: string, value: any) {
    value = value.ops || value;
    if (!Array.isArray(value)) throw new Error('JSONPatch changeText(path, value) only accepts a Delta or Delta.ops.');
    return this.op('@changeText', path, value);
  }

  /**
   * Apply this patch to an object, returning a new object with the applied changes (or the same object if nothing
   * changed in the patch).
   */
  apply<T>(obj: T, options = applyOptions): T {
    return super.apply(obj, options);
  }
}
