<script>
  import Note from '@dabble/plugins/novel/components/Note.svelte';
  import FullPageImage from './FullPageImage.svelte';

  export let doc;
  export let margin = true;
</script>

<Note {margin}>
  <FullPageImage {doc} />
</Note>
