// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/Dabble-Color-Logotype-Tagline-Padded.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkout-screen.svelte-yqqhuy{flex:1;display:flex;flex-direction:column;align-items:center;background:var(--white);overflow-y:auto}.checkout.svelte-yqqhuy{position:relative;max-width:1004px;width:100%}.icon.close-btn.svelte-yqqhuy{position:absolute;top:8px;right:8px;font-size:30px}.logo.svelte-yqqhuy{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;background-size:contain;width:220px;height:112px;overflow:hidden;text-indent:-400px;margin:16px auto}\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJmaWxlIjoiQ2hlY2tvdXQuc3ZlbHRlIiwibWFwcGluZ3MiOiJBQXVCRSw4QkFBaUIsQ0FDZixJQUFJLENBQUUsQ0FBQyxDQUNQLE9BQU8sQ0FBRSxJQUFJLENBQ2IsY0FBYyxDQUFFLE1BQU0sQ0FDdEIsV0FBVyxDQUFFLE1BQU0sQ0FDbkIsVUFBVSxDQUFFLElBQUksT0FBTyxDQUFDLENBQ3hCLFVBQVUsQ0FBRSxJQUNkLENBQ0EsdUJBQVUsQ0FDUixRQUFRLENBQUUsUUFBUSxDQUNsQixTQUFTLENBQUUsTUFBTSxDQUNqQixLQUFLLENBQUUsSUFDVCxDQUNBLEtBQUssd0JBQVcsQ0FDZCxRQUFRLENBQUUsUUFBUSxDQUNsQixHQUFHLENBQUUsR0FBRyxDQUNSLEtBQUssQ0FBRSxHQUFHLENBQ1YsU0FBUyxDQUFFLElBQ2IsQ0FDQSxtQkFBTSxDQUNKLFVBQVUsQ0FBRSxxREFBcUQsQ0FBQyxTQUFTLENBQUMsTUFBTSxDQUNsRixlQUFlLENBQUUsT0FBTyxDQUN4QixLQUFLLENBQUUsS0FBSyxDQUNaLE1BQU0sQ0FBRSxLQUFLLENBQ2IsUUFBUSxDQUFFLE1BQU0sQ0FDaEIsV0FBVyxDQUFFLE1BQU0sQ0FDbkIsTUFBTSxDQUFFLElBQUksQ0FBQyxJQUNmIiwibmFtZXMiOltdLCJzb3VyY2VzIjpbIkNoZWNrb3V0LnN2ZWx0ZSJdfQ==*/", "",{"version":3,"sources":["webpack://./src/plugins/billing/components/Checkout.svelte"],"names":[],"mappings":"AAuBE,+BACE,MAAO,CACP,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,uBAAwB,CACxB,eACF,CACA,wBACE,iBAAkB,CAClB,gBAAiB,CACjB,UACF,CACA,8BACE,iBAAkB,CAClB,OAAQ,CACR,SAAU,CACV,cACF,CACA,oBACE,mEAAkF,CAClF,uBAAwB,CACxB,WAAY,CACZ,YAAa,CACb,eAAgB,CAChB,kBAAmB,CACnB,gBACF;AAjDF,61BAA61B","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
