import { projectStore } from '@dabble/app';
import { observe } from '@dabble/data/observe';
import { derived } from '@dabble/data/stores/store';
import { Doc } from '@dabble/data/types';
import { DecorationsModule, Delta, Editor } from 'typewriter-editor';
import { showHistory } from './versioning';

export default function Versioning(doc: Doc, field: string) {
  return (editor: Editor) => {
    const decorations = editor.modules.decorations as DecorationsModule;
    let diff: Delta;
    let previous: Delta;

    let listening = false;
    const currentChangeStore = derived(projectStore, project => project.change);
    const unsubscribes = [
      showHistory.subscribe(show => {
        if (!show) {
          const decorator = decorations.getDecorator('versioning');
          diff = null;
          if (decorator.hasDecorations()) {
            decorator.clear();
            decorator.apply();
          }
        }
      }),

      observe(currentChangeStore, async change => {
        if (change === null) {
          if (listening) {
            diff = null;
            editor.removeEventListener('decorate', onDecorate);
            listening = false;
          }
          return;
        }

        const changes = getChangeDiffs()[doc.id];
        const prevDoc = projectStore.get().previous?.docs[doc.id];
        diff = changes && changes[field];
        previous = prevDoc && prevDoc[field];

        if (!listening) {
          editor.addEventListener('decorate', onDecorate);
          listening = true;
        }
      }),
    ];

    function onDecorate() {
      const decorator = decorations.getDecorator('versioning');
      decorator.clear();
      if (diff) {
        let pos = 0;
        let insertLength = 0;
        let offset = 0;
        diff.ops.forEach(op => {
          if (op.retain && !op.attributes) {
            pos += op.retain;
            insertLength = 0;
          } else if (op.retain) {
            const length = typeof op.insert === 'string' ? op.insert.length : 1;
            decorator.decorateText([pos, (pos += length)], { class: 'formatted-text' });
            insertLength = 0;
          } else if (op.delete) {
            let str = '';
            const start = pos - insertLength - (offset - insertLength);
            if (previous)
              str = previous
                .slice(start, start + op.delete)
                .ops.map(op => (typeof op.insert === 'string' ? op.insert : ' '))
                .join('');
            else for (let i = 0; i < op.delete; i++) str += '\xA0';
            decorator.insertDecoration(pos, { class: 'deleted-text', 'data-content': str });
            insertLength = 0;
            offset -= op.delete;
          } else if (op.insert) {
            insertLength = typeof op.insert === 'string' ? op.insert.length : 1;
            offset += insertLength;
            decorator.decorateText([pos, (pos += insertLength)], { class: 'added-text' });
          }
        });
      }
      decorator.apply();
    }

    return {
      destroy() {
        unsubscribes.forEach(unsub => unsub());
      },
    };
  };
}

interface DocChanges {
  [field: string]: Delta;
}

interface DocsChanges {
  [docId: string]: DocChanges;
}

let cache: DocsChanges;

// All modules will ask for this at once, only let the first one do the work, the rest can share the answer
function getChangeDiffs(): DocsChanges {
  if (cache) return cache;
  setTimeout(() => (cache = null), 0);
  return (cache = calcChangeDiffs());
}

function calcChangeDiffs() {
  const docs: DocsChanges = {};
  const { change } = projectStore.get();
  if (!change) return;
  change.ops.forEach(op => {
    if (op.op !== '@changeText') return;
    const [docId, field] = op.path.split('/').slice(2); // /docs/abcd/body -> [ '', 'docs', 'abcd', 'body' ]
    const doc = docs[docId] || (docs[docId] = {});
    doc[field] = new Delta(op.value);
  });

  return docs;
}
