import {
  mdiMaskCircle,
  mdiMaskCircleBigger,
  mdiMaskEdit,
  mdiMaskEye,
  mdiMaskLock,
  mdiOverlayAdd,
  mdiOverlayCheck,
  mdiOverlayCloudSync,
  mdiOverlayEdit,
  mdiOverlayEye,
  mdiOverlayLock,
  mdiOverlayRemove,
} from './custom-icons';

export const availableOverlays = ['check', 'remove', 'eye', 'edit', 'lock'];

export const iconOverlays: Record<string, string> = {
  add: mdiOverlayAdd,
  check: mdiOverlayCheck,
  remove: mdiOverlayRemove,
  eye: mdiOverlayEye,
  edit: mdiOverlayEdit,
  lock: mdiOverlayLock,
  cloudSync: mdiOverlayCloudSync,
};

export const iconOverlayClips: Record<string, string> = {
  add: mdiMaskCircle,
  check: mdiMaskCircle,
  remove: mdiMaskCircle,
  eye: mdiMaskEye,
  edit: mdiMaskEdit,
  lock: mdiMaskLock,
  cloudSync: mdiMaskCircleBigger,
};

// These are displayed in blocks of 6, try to keep in multiples of 6.
export const iconVariations: Record<string, Record<string, string>> = {
  // novel_folder: {
  //   location: mdiFolderImage,
  //   character: mdiAccountBoxMultiple,
  // },
  // novel_page: {
  //   location: mdiImage,
  //   character: mdiAccountBox,
  // },
};
