import { $t, currentProjectMeta, getNow } from '@dabble/app';
import rest from '@dabble/data/rest';
import { writable } from '@dabble/data/stores/store';
import { uuid } from '@dabble/data/uuid';
import { number } from '@dabble/toolkit/helpers';
import { format } from 'date-fns';
import { totalCount, totalGoal } from './goals';

export const shareStatsModalOpen = writable('');

const CONTENT_URL = '.';

export async function shareStats(imgBlob: Blob) {
  if (!imgBlob) return;

  const filename = uuid(21);
  const html = await processHtml(filename);
  const imgFilename = `${uuid(24)}.jpg`;

  const file = new File([imgBlob], imgFilename, { type: imgBlob.type });

  const imgResponse = await rest.UPLOAD(`images/${imgFilename}`, imgBlob.type, new Blob([file], { type: file.type }));
  const { url: imgUrl } = await imgResponse;

  const response = await rest.POST(`share/stats`, {
    html,
    filename,
    imgUrl,
  });

  shareStatsModalOpen.set(response.path);
}

export function getTagline(author: string) {
  if (!author) author = getAuthor();

  const wordsWritten = number(totalCount.get());
  const wordGoal = number(totalGoal.get());

  return $t('share_goals_social_tagline', { author, wordsWritten, wordGoal });
}

function getAuthor() {
  return currentProjectMeta.get().author || $t('unknown_author');
}

async function processHtml(filename: string) {
  const author = getAuthor();

  const title = $t('share_goals_social_title', { author });

  const siteTagline = getTagline(author);

  const lang = 'en';

  let html = `<!doctype><html lang="${lang}">`;

  html +=
    '<head><meta charset="utf-8" />' +
    '<meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />' +
    `<title>${title}</title>` +
    '<meta name="robots" content = "noindex,nofollow" /> <meta name="generator" content = "Dabble Writer" />' +
    `<link rel="license" href = "${CONTENT_URL}/copyright.html" >` +
    `<link rel="icon" type = "image/png" href = "${CONTENT_URL}/favicon.png" >` +
    `<meta property="og:title" content="${title}" />
    <meta property="og:image:url" content="{{imgurl}}" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:alt" content="${title}" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://share.dabblewriter.com/stats/${filename}" />` +
    `<meta name="twitter:title" content="${title}">
    <meta name="twitter:description" content="${siteTagline}">
    <meta name="twitter:image" content="{{imgurl}}">
    <meta name="twitter:card" content="summary">`;

  html += `<link rel="stylesheet" href="${CONTENT_URL}/share-stats.css?v=${uuid(6)}" />`;

  html +=
    '</head><body style="width:100%;max-width:452px;margin:0 auto;">' +
    `<header id="header"><a href="https://dabblewriter.com"><img src="${CONTENT_URL}/logo.svg" class="logo" alt="Dabble Writer Logo" /></a></header><main>` +
    `<p style="margin-bottom:7px;">Stats generated on: ${format(getNow(), 'MM-dd-yyyy')}</p>` +
    `<img src="{{imgurl}}" alt="${siteTagline}" />`;

  html +=
    '</main><footer>' +
    `<div class="credits">created with <a href="https://dabblewriter.com"><img src="${CONTENT_URL}/logo.svg" class="logo" alt="Dabble Writer Logo" /></a></div>` +
    `<div class="copyright">Copyright &copy; ${new Date().getFullYear()} Dabble Writer, Inc. All Rights Reserved</div>` +
    '</footer></body></html>';

  return html;
}
