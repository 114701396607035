import {
  currentDocId,
  currentProjectMeta,
  locale,
  observe,
  plugins,
  projectStore,
  readonly,
  settings,
  viewport,
} from '@dabble/app';
import { derived, readable, writable } from '@dabble/data/stores/store';
import { DecorationsModule, Editor, isEqual, normalizeRange } from 'typewriter-editor';

// ALLL voices available
export const systemVoices = readable<SpeechSynthesisVoice[]>([], set => {
  if (!window.speechSynthesis) return;
  set(speechSynthesis.getVoices());
  speechSynthesis.onvoiceschanged = () => set(speechSynthesis.getVoices());
  return () => (speechSynthesis.onvoiceschanged = null);
});

// Voices available for the current locale
export const voices = derived(
  [systemVoices, currentProjectMeta, locale],
  ([systemVoices, currentProjectMeta, locale]) => {
    const lang = currentProjectMeta?.spellingLanguage || locale || 'en-US';
    const langShort = lang.slice(0, 2);
    return systemVoices
      .filter(v => v.lang.slice(0, 2) === langShort)
      .sort((a, b) => {
        const scoreA = voiceScore(a, lang),
          scoreB = voiceScore(b, lang);
        return scoreB - scoreA;
      });
  }
);

export const readingSpeed = derived([currentProjectMeta], ([currentProjectMeta]) => {
  return currentProjectMeta?.readingSpeed || 1;
});

export const readingVoice = derived([currentProjectMeta, voices], ([currentProjectMeta, voices]) => {
  const readingVoice = currentProjectMeta?.readingVoice;
  let voice = voices.find(v => v.name === readingVoice);
  if (!voice) voice = voices.find(v => v.default) || voices[0];
  return voice;
});

export function createReadingStore() {
  let reading = false;
  let startPos = 0;
  let endPos = 0;
  let currentField: any = null;
  const { get, set, subscribe } = writable(reading);
  currentDocId.subscribe(stop);
  addEventListener('beforeunload', () => stop);
  addEventListener('unload', stop);
  let speech: SpeechSynthesisUtterance, promise: Promise<any>;

  async function start() {
    if (!window.speechSynthesis) return;
    let [field, editor] = viewport.getSelection();
    if (!field || !field.id) {
      viewport.selectNearTop();
      [field, editor] = viewport.getSelection();
    }
    if (!field.id) return;

    readonly.addLock('reading');
    const fields = viewport.getFields();
    let i = fields.findIndex(f => isEqual(f, field));
    if (i === -1) return;
    set((reading = true));
    let [at] = normalizeRange(editor.doc.selection) || [0];
    [at] = editor.doc.getLineRange(at);
    const alreadyRead: any = {};
    for (; i < fields.length; i++, at = 0) {
      const field = fields[i];
      if (!alreadyRead[field.id]) {
        alreadyRead[field.id] = [];
      }
      if (alreadyRead[field.id].find((f: string) => f === field.field)) continue;
      alreadyRead[field.id].push(field.field);
      editor = await viewport.scrollIntoView(field, at);
      let text = editor.getText([at, editor.doc.length]);
      if (!text.trim()) {
        const doc = projectStore.getDoc(field.id);
        const placeholder = settings.getPlaceholder(doc, field.field);
        if (placeholder && settings.getPlaceholderClass(doc, field.field) === 'unstyled-placeholder') {
          text = placeholder;
        } else {
          continue;
        }
      }
      const voice = readingVoice.get();
      const paragraphs = text.match(/[^\n]+[\n]*/g) || [];
      startPos = at;
      if (!voice || voice.localService || navigator.userAgent.includes('Edg')) {
        for (let n = 0; n < paragraphs.length; n++) {
          if (!reading) break;
          const paragraph = paragraphs[n];
          endPos = startPos + paragraph.length;
          const decorator = (editor.modules.decorations as DecorationsModule).getDecorator('reading');
          decorator.clear().decorateText([startPos, endPos], { class: 'reading', ['data-ids']: field.id });
          decorator.apply();
          currentField = field;
          viewport.select(field, [startPos, endPos], true);
          if (!paragraph) continue;
          [speech, promise] = speakText(paragraph, null, null);
          await promise;
          speech = null;
          if (!reading) break;
          startPos = endPos;
        }
        editor.modules.decorations.removeDecorations('reading');
      } else {
        for (let j = 0; j < paragraphs.length; j++) {
          const paragraph = paragraphs[j];
          let sentences = [paragraph];
          if (paragraph.length > 240) {
            sentences = paragraph.match(/[^?!…—;:]+[.!?…—;:”]+[\n]*/g);
          }
          if (!sentences) {
            sentences = [];
          }

          for (let k = 0; k < sentences.length; k++) {
            const sentence = sentences[k];
            let lines = [sentence];
            if (sentence.length > 240) {
              lines = sentence.match(/[^.?!…—;:]+[.!?…—;:”]+[\n]*/g);
            }
            if (!lines) {
              lines = [];
            }
            for (let m = 0; m < lines.length; m++) {
              const line = lines[m];
              let phrases = [line];
              if (line.length > 240) {
                phrases = line.match(/[^.,?!…—;:]+[.,!?…—;:”]+[\n]*/g);
              }
              for (let n = 0; n < phrases.length; n++) {
                if (!reading) break;
                const phrase = phrases[n];
                endPos = startPos + phrase.length;
                const decorator = (editor.modules.decorations as DecorationsModule).getDecorator('reading');
                decorator.clear().decorateText([startPos, endPos], { class: 'reading', ['data-ids']: field.id });
                decorator.apply();
                currentField = field;
                viewport.select(field, [endPos, endPos], false);
                if (!phrase) continue;
                [speech, promise] = speakText(phrase, null, null);
                await promise;
                speech = null;
                if (!reading) break;
                startPos = endPos;
              }
              if (!reading) break;
              editor.modules.decorations.removeDecorations('reading');
            }
            if (!reading) break;
          }
          editor.modules.decorations.removeDecorations('reading');
          if (!reading) break;
        }
        editor.modules.decorations.removeDecorations('reading');
        if (!reading) break;
      }
      editor.modules.decorations.removeDecorations('reading');
      if (!reading) break;
    }
    readonly.removeLock('reading');
    await viewport.select(currentField, [startPos, startPos], true);
    set((reading = false));
    editor.modules.decorations.removeDecorations('reading');
  }

  async function stop() {
    set((reading = false));
    readonly.removeLock('reading');
    window.speechSynthesis?.cancel();

    let editor: Editor;
    if (currentField !== null) {
      editor = await viewport.select(currentField, [startPos, startPos], true);
      editor?.select([startPos, startPos]);
    }
    editor?.modules.decorations.removeDecorations('reading');
  }

  function restart() {
    if (!window.speechSynthesis || !speech) return;
    speechSynthesis.cancel();
    speech.rate = readingSpeed.get();
    speech.voice = readingVoice.get();
    speechSynthesis.speak(speech);
  }

  return {
    start,
    stop,
    restart,
    get,
    set,
    subscribe,
  };
}

export const reading = createReadingStore();

// Update the reading when the reading speed or voice changes
observe([readingSpeed, readingVoice], () => {
  if (reading.get()) {
    reading.restart();
  }
});

plugins.register({ reading, voices, readingVoice, readingSpeed });

function speakText(
  text: string,
  onBoundary: (event: SpeechSynthesisEvent) => void,
  onEnd: (event: SpeechSynthesisEvent) => void
): [SpeechSynthesisUtterance, Promise<any>] {
  const speech = new SpeechSynthesisUtterance(text);
  return [
    speech,
    new Promise(resolve => {
      speech.voice = readingVoice.get();
      speech.rate = readingSpeed.get();
      speech.onboundary = onBoundary;
      speech.onend = onEnd
        ? event => {
          onEnd(event);
          resolve(event);
        }
        : resolve;
      speechSynthesis.speak(speech);
    }),
  ];
}

function voiceScore(voice: SpeechSynthesisVoice, lang: string) {
  let score = 0;
  if (voice.default) score += 100;
  if (!voice.localService) score += 10;
  if (voice.lang === lang) score += 1;
  return score;
}
