<script>
  import { getUrl, projectMetas, projectStore, router, t } from '@dabble/app';
  import { createImageInfo, saveImage } from '@dabble/plugins/content/images';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { mdiImageAlbum } from '@mdi/js';
  import Dropzone from 'svelte-file-dropzone/src/components/Dropzone.svelte';
  import { coverImageDocId } from '../cover-art-menu-item';

  $: doc = $coverImageDocId;

  //always keeps the cover updated to the first book
  $: {
    let bookIds = $projectStore.project?.docs?.manuscript?.children;
    if (bookIds) {
      bookIds.find(id => {
        if (projectStore.getDoc(id).image) {
          return projectMetas.update(projectStore.get().project.id, { image: projectStore.getDoc(id).image.url });
        } else {
          projectMetas.update(projectStore.get().project.id, { image: null });
        }
      });
    }
  }

  function close() {
    $coverImageDocId = '';
  }

  async function handleImageDrop(e) {
    const { acceptedFiles } = e.detail;
    if (acceptedFiles.length >= 1) {
      const info = await createImageInfo({ file: acceptedFiles[0] });
      const url = await saveImage({ file: acceptedFiles[0] }, info);
      await projectStore.updateDoc(doc, { image: { url, ...info } });
      if (
        doc === projectStore.get().project.docs.manuscript.children[0] ||
        !projectMetas.get()[$projectStore.projectId].image
      ) {
        await projectMetas.update($projectStore.projectId, { image: url });
      }
      router.navigate(getUrl(doc));
      close();
    } else {
      confirm($t('novel_image_unsupported'), $t('novel_image_unsupported_message'));
    }
  }
</script>

{#if $coverImageDocId}
  <Modal title={'Set Cover Art?'} on:close={close}>
    <section>
      <p class="section-text">{$t('novel_cover_art_drop_main_text')}</p>
      <p class="section-text">{$t('novel_cover_art_drop_sub_text')}</p>
      <div class="dropzone-container">
        <Dropzone on:drop={handleImageDrop} accept={['.png', '.jpeg', '.jpg', '.gif', '.webp', '.tiff']}>
          <div class="dropzone-text">
            <div class="cover-icon">
              <Icon path={mdiImageAlbum} />
            </div>
            <p class="drop-modal-text">{$t('novel_modal_drop_drag_text')}</p>
            <h3 class="or-line"><span>{$t('novel_modal_drop_or_divider')}</span></h3>
            <button class="browse-button btn primary">{$t('novel_modal_drop_browse_button')}</button>
          </div>
        </Dropzone>
      </div>
    </section>
  </Modal>
{/if}

<style>
  .section-text {
    float: left;
    width: 60%;
  }
  .cover-icon {
    font-size: 45px;
    text-align: center;
  }
  .drop-modal-text {
    text-align: center;
  }
  .browse-button {
    margin: 30px auto 10px auto;
    width: 180px;
    padding: 14px;
    border: none;
    border-radius: 10px;
    display: block;
  }
  .browse-button:hover {
    cursor: pointer;
    transition: background-color 0.2s;
  }
  .or-line {
    width: 60%;
    text-align: center;
    border-bottom: 1px solid var(--grey-light-lighty);
    line-height: 0.1em;
    margin: auto;
    padding-top: 4px;
  }
  .or-line span {
    background: var(--white);
    padding: 0 10px;
    font-size: 22px;
  }
  :global(.dropzone-container .dropzone) {
    padding: 30px;
    line-height: 1;
    cursor: pointer;
    background: transparent;
  }
  :global(.dropzone:hover) {
    border-color: var(--selection-color);
  }
</style>
