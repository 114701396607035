// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/plan-premium.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plan-wrapper.lifetime.svelte-uxih34{order:4}.plan-image.svelte-uxih34{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.interval.svelte-uxih34{color:var(--text-color-lightest);font-size:var(--font-size-sm)}\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJmaWxlIjoiTGlmZXRpbWVQbGFuLnN2ZWx0ZSIsIm1hcHBpbmdzIjoiQUEyQkUsYUFBYSx1QkFBVSxDQUNyQixLQUFLLENBQUUsQ0FDVCxDQUNBLHlCQUFZLENBQ1YsZ0JBQWdCLENBQUUsK0JBQ3BCLENBQ0EsdUJBQVUsQ0FDUixLQUFLLENBQUUsSUFBSSxxQkFBcUIsQ0FBQyxDQUNqQyxTQUFTLENBQUUsSUFBSSxjQUFjLENBQy9CIiwibmFtZXMiOltdLCJzb3VyY2VzIjpbIkxpZmV0aW1lUGxhbi5zdmVsdGUiXX0=*/", "",{"version":3,"sources":["webpack://./src/plugins/billing/components/LifetimePlan.svelte"],"names":[],"mappings":"AA2BE,qCACE,OACF,CACA,0BACE,wDACF,CACA,wBACE,gCAAiC,CACjC,6BACF;AAnCF,6YAA6Y","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
