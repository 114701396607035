<script>
  import {
    connectionSendAfterAuthed,
    authed,
    online,
    connected,
    currentProjectMeta,
    inform,
    minute,
    plugins,
    projectMetas,
    t,
  } from '@dabble/app';
  import { dateOptions } from '@dabble/data/intl';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import CheckButton from '@dabble/toolkit/CheckButton.svelte';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { escKey } from '@dabble/toolkit/events';
  import { focus } from '@dabble/toolkit/focus';
  import { slide } from '@dabble/toolkit/transitions';
  import { mdiAccount, mdiClose, mdiContentCopy, mdiDelete, mdiDotsVertical } from '@mdi/js';
  import { format } from 'date-fns';
  import { getUser } from '../collab-stores';

  let showExplainer;
  let menuOpen;
  let inviteAuthors;
  let authorEmail;
  let invalid = null;
  let form;
  let submitted;
  let error;
  let invites;
  let trackAuthorChanges;

  const MAX_COLLABORATORS = 5;

  const { currentUser } = plugins.stores;
  const sortOrder = {
    owner: -1,
    author: 1,
  };
  const toggleRoles = {
    owner: 'author',
    author: 'owner',
  };

  $: uid = $currentUser && $currentUser.uid;
  $: roles = $currentProjectMeta?.roles || {};
  $: isOwner = roles[uid] === 'owner';
  $: authors =
    (roles &&
      Object.keys(roles).sort((a, b) => {
        return sortOrder[roles[a]] - sortOrder[roles[b]];
      })) ||
    [];
  $: multipleOwners = authors.filter(uid => roles[uid] === 'owner').length > 2;
  $: disconnected = !$connected || undefined;
  $: if ($currentProjectMeta && !disconnected) loadInvites();
  $: totalInvited = authors.length + (invites ? invites.length : 0);
  $: fullProject = totalInvited >= MAX_COLLABORATORS;
  $: trackAuthorChanges = $currentProjectMeta?.trackAuthorChanges ?? false;

  async function setTrackAuthorChanges() {
    if (roles[uid] !== 'owner') return;

    trackAuthorChanges = !trackAuthorChanges;
    await projectMetas.update($currentProjectMeta.id, { trackAuthorChanges });
    inform('success', $t('collab_track_changes_success'));
  }

  async function loadInvites() {
    try {
      invites = (await connectionSendAfterAuthed('getInvites', $currentProjectMeta.id)).sort(
        ({ created: a }, { created: b }) => {
          return a - b;
        }
      );
    } catch (e) {
      invites = [];
      error = $t(e.message);
    }
  }

  async function inviteByEmail() {
    if (!$authed) {
      error = $t('DISCONNECTED');
      return;
    }
    form.checkValidity();
    if (!form.checkValidity()) {
      invalid = {};
      const elements = form.elements;
      for (let i = 0; i < elements.length; i++) {
        let elem = elements[i];
        if (elem.validationMessage) invalid[elem.id || elem.name] = elem.validationMessage;
      }
      return;
    } else {
      invalid = null;
    }

    submitted = true;
    error = null;

    const email = authorEmail.trim();

    try {
      const newInvites = await connectionSendAfterAuthed('inviteUsers', $currentProjectMeta.id, [email]);
      submitted = false;
      authorEmail = '';
      inviteAuthors = false;
      inform('success', $t('collab_invite_sent'));
      invites = [...invites, ...newInvites];
    } catch (err) {
      error = err.message;
      submitted = false;
    }
  }

  async function deleteInvite(invite) {
    if (!$authed) {
      error = $t('DISCONNECTED');
      return;
    }
    submitted = true;
    error = null;
    try {
      await connectionSendAfterAuthed('deleteInvite', invite.id);
      submitted = false;
      inform('success', $t('collab_invite_deleted'));
      invites = invites.filter(i => i.id !== invite.id);
    } catch (err) {
      error = $t(err.message);
      submitted = false;
    }
  }

  async function changeRole(uid, role) {
    await connectionSendAfterAuthed('changeRole', $currentProjectMeta.id, uid, role);
  }

  function copyLink(inviteId) {
    navigator.clipboard.writeText(`https://app.dabblewriter.com/?invite=${inviteId}`);
    inform('info', $t('collab_invite_link_copied'));
  }
</script>

<div class="section">
  <h2 class="header-with-link">
    {$t('collab_header')}
    <button class="btn link" on:click={() => (showExplainer = !showExplainer)}>What is co-authoring?</button>
  </h2>
  {#if showExplainer}
    <p transition:slide|local={{ duration: 150 }}>{$t('collab_explainer', { limit: MAX_COLLABORATORS })}</p>
  {/if}

  <h4>{$t('collab_authors')}</h4>
  <table class="bordered table">
    <tr>
      <th>{$t('collab_author_name')}</th>
      <th>{$t('collab_author_role')}</th>
    </tr>
    {#each authors as authorId}
      <tr class:me={$currentUser && $currentUser.uid === authorId}>
        <td>
          {#if $getUser(authorId).name}
            <span class="name" class:unloaded={!$getUser(authorId)}>
              {$getUser(authorId) ? $getUser(authorId).name : $t('collab_author_unloaded')}
              {$currentUser && $currentUser.uid === authorId ? $t('collab_author_me') : ''}
            </span>
          {:else}
            <div class="loading-placeholder" />
          {/if}
        </td>
        <td>
          <span class="role">{$t('collab_type_' + roles[authorId])}</span>
          {#if isOwner}
            <div class="actions">
              <button class="icon menu-opener" on:click={() => (menuOpen = authorId)}>
                <Icon path={mdiDotsVertical} />
              </button>
              {#if menuOpen === authorId}
                <Dropdown placement="left-start" arrow on:close={() => (menuOpen = null)}>
                  <button
                    class="dropdown-item"
                    on:click={() => changeRole(authorId, toggleRoles[roles[authorId]])}
                    disabled={(authorId === uid && isOwner && !multipleOwners) || disconnected}
                  >
                    <Icon path={mdiAccount} />
                    {$t('collab_set_role', { role: $t('collab_type_' + toggleRoles[roles[authorId]]) })}
                  </button>
                  <button
                    class="dropdown-item"
                    on:click={() => changeRole(authorId, 'none')}
                    disabled={(authorId === uid && isOwner && !multipleOwners) || disconnected}
                  >
                    <Icon path={mdiDelete} />
                    {$t('collab_remove', { role: $t('collab_type_' + roles[authorId]) })}
                  </button>
                </Dropdown>
              {/if}
            </div>
          {/if}
        </td>
      </tr>
    {/each}
  </table>

  <div class="track-preference">
    <CheckButton checked={trackAuthorChanges} on:click={setTrackAuthorChanges} disabled={roles[uid] !== 'owner'}>
      {$t('collab_track_changes_button')}
    </CheckButton>
    <p class="note">{$t('collab_track_changes_button_description')}</p>
  </div>

  <h4>{$t('collab_invites')}</h4>
  {#if error}
    <Alert type="danger" dismissible on:close={() => (error = null)}>
      <strong>{$t('error')}:</strong>
      {$t(error)}
    </Alert>
  {/if}
  <table class="bordered table">
    <tr>
      <th>{$t('collab_invite_email')}</th>
      <th>{$t('collab_invite_status')}</th>
    </tr>
    {#if invites}
      {#each invites as invite}
        <tr>
          <td>
            <div class="invite-info">
              <span class="name">{invite.invitee.email}</span>
              <span class="date">{format(new Date(invite.created), 'PPP', dateOptions)}</span>
            </div>
          </td>
          <td>
            <span class="status"
              >{invite.expired < $minute
                ? $t('collab_invite_status_expired')
                : $t('collab_invite_status_' + invite.status)}</span
            >
            <div class="actions">
              <button class="icon menu-opener" on:click={() => (menuOpen = invite.id)}>
                <Icon path={mdiDotsVertical} />
              </button>
              {#if menuOpen === invite.id}
                <Dropdown placement="left-start" arrow on:close={() => (menuOpen = null)}>
                  <button class="dropdown-item" on:click={() => copyLink(invite.id)}>
                    <Icon path={mdiContentCopy} />
                    {$t('collab_invite_link_copy')}
                  </button>
                  <hr />
                  <button
                    class="dropdown-item"
                    disabled={!isOwner || disconnected}
                    on:click={() => deleteInvite(invite)}
                  >
                    <Icon path={mdiAccount} />
                    {$t('collab_invite_delete')}
                  </button>
                </Dropdown>
              {/if}
            </div>
          </td>
        </tr>
      {:else}
        <tr>
          <td colspan="2">{$t('No invitations sent.')}</td>
        </tr>
      {/each}
    {:else}
      <tr>
        <td><div class="loading-placeholder" /></td>
        <td><div class="loading-placeholder" /></td>
      </tr>
      <tr>
        <td><div class="loading-placeholder" /></td>
        <td><div class="loading-placeholder" /></td>
      </tr>
    {/if}

    {#if inviteAuthors}
      <tr>
        <td colspan="2" class="invite-row">
          <form bind:this={form} class="form" on:submit|preventDefault={inviteByEmail}>
            <div class="form-group">
              <input
                class:error={invalid && invalid.email}
                bind:value={authorEmail}
                type="email"
                name="email"
                required
                class="form-control"
                placeholder={$t('collab_author_email')}
                autocomplete="off"
                use:escKey={() => (inviteAuthors = false)}
                use:focus
              />
              <button class="btn primary" type="submit" disabled={submitted || disconnected || fullProject}
                >{$t('collab_invite')}</button
              >
              <button class="icon" type="button" on:click={() => (inviteAuthors = false)}>
                <Icon path={mdiClose} />
              </button>
            </div>

            {#if invalid && invalid.email}
              <div class="error-message">{invalid.email}</div>
            {/if}
          </form>
        </td>
      </tr>
    {/if}
  </table>

  <div>
    <button
      class="btn primary"
      disabled={!isOwner || disconnected || fullProject}
      on:click={() => (inviteAuthors = true)}>{$t('collab_invite_author')}</button
    >
    {#if !isOwner}
      <span class="note">{$t('collab_only_author_invites')}</span>
    {/if}
    {#if isOwner && fullProject}
      <span class="note">{$t('collab_full_project', { limit: MAX_COLLABORATORS })}</span>
    {/if}
  </div>

  {#if disconnected}
    <span class="offline-indicator badge danger">{$t($online ? 'account_disconnected' : 'account_offline')}</span>
  {/if}
</div>

<style>
  .track-preference {
    display: flex;
    align-items: center;
  }
  .track-preference .note {
    margin-left: 7px;
  }
  .header-with-link {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .header-with-link button {
    font-size: var(--font-size-sm);
  }
  .me {
    font-weight: bold;
  }
  tr.me td {
    background: var(--gray-lightest);
  }
  .actions {
    float: right;
  }
  .unloaded {
    font-size: var(--font-size-xs);
    color: var(--text-color-lighter);
    text-transform: uppercase;
    font-style: italic;
  }
  .unloaded::before {
    content: '[';
    font-style: normal;
  }
  .unloaded::after {
    content: ']';
    font-style: normal;
  }
  .invite-row .form-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .invite-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .invite-info .date {
    font-size: var(--font-size-xs);
    color: var(--text-color-lighterer);
  }
  @media screen {
    html[data-theme='dark'] table.table th,
    html[data-theme='dark'] table.table td {
      color: var(--gray);
    }
  }
</style>
