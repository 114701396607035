import { getTitle, getUrl, settings } from '@dabble/app';
import { Doc } from '@dabble/data/types';
import { Editor } from 'typewriter-editor';

export default function noteLinking(editor: Editor) {
  function onDragOver(event: DragEvent) {
    const dt = event.dataTransfer;
    if (!dt.types.includes('application/json+dabble-doc') && !dt.types.includes('text/uri-list')) return;
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  function onDrop(event: DragEvent) {
    const canLink = !!editor.typeset.formats.get('link');
    const dt = event.dataTransfer;
    if (dt.types.includes('application/json+dabble-doc')) {
      const jsonData = dt.getData('application/json+dabble-doc');
      try {
        const doc = JSON.parse(jsonData);
        const title = getTitle(doc, 'title');
        const text = editor.getText(editor.doc.selection);
        const link = getLink(doc);
        if (canLink) {
          const format = link ? { link } : undefined;
          editor.insert(text || title, format);
        } else if (link) {
          editor.insert(link);
        }
      } catch (err) {
        // do nothing
      }
    } else if (dt.types.includes('text/uri-list')) {
      const link = dt.getData('text/uri-list');
      editor.insert(link, { link });
    }
  }

  return {
    init() {
      const { root } = editor;
      root.addEventListener('dragenter', onDragOver);
      root.addEventListener('dragover', onDragOver);
      root.addEventListener('drop', onDrop);
    },
    destroy() {
      const { root } = editor;
      root.removeEventListener('dragenter', onDragOver);
      root.removeEventListener('dragover', onDragOver);
      root.removeEventListener('drop', onDrop);
    },
  };
}

export function getLink(doc: Doc) {
  const docSettings = settings.getFor(doc);
  if (docSettings.unselectable || !docSettings.view) {
    return;
  }

  return getUrl(doc);
}
