<script>
  import { projectStore, readonly, settings } from '@dabble/app';
  import { droppable } from '@dabble/plugins/content/droppable';
  import Card from '@dabble/toolkit/Card.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { addDocToDrag, getDropType, isDropDoc, mimes } from '@dabble/toolkit/drag-drop-utils';
  import { draggable } from '@dabble/toolkit/draggable';
  import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
  import ManuscriptInsert from './ManuscriptInsert.svelte';

  const allowedDropTypes = new Set(['novel_part', 'novel_chapter', 'novel_scene']);
  const START_OF_CARD = 44;
  const RIGHT_HALF_OF_CARD = 80;

  export let doc;

  let hoveredDocId;
  let dropId;
  let dropAfter = false;

  $: books = doc && $projectStore.childrenLookup[doc.id];
  $: bookChildren = getBookChildren(books, $projectStore.childrenLookup);
  $: descendents = books.reduce((all, book) => all.concat(book, bookChildren[book.id]), []);

  function getBookChildren(books, childrenLookup) {
    function descendents(item) {
      if (item.type === 'novel_chapter') {
        return childrenLookup[item.id] || [];
      } else if (childrenLookup[item.id]) {
        return childrenLookup[item.id].reduce(
          (children, child) =>
            allowedDropTypes.has(child.type) ? children.concat(child, descendents(child)) : children,
          []
        );
      } else {
        return [];
      }
    }

    const children = {};
    books.forEach(book => (children[book.id] = descendents(book)));
    return children;
  }

  function onMouseMove(event) {
    const target = event.target.type === Node.TEXT_NODE ? event.target.parentNode : event.target;
    if (!target.closest || target.closest('.manuscript-insert')) return;
    const [id] = getNearestDivision(event);
    if (hoveredDocId !== id) hoveredDocId = id;
  }

  function onMouseLeave(event) {
    hoveredDocId = null;
  }

  function getNearestDivision(event, withinCards) {
    const target = event.target.type === Node.TEXT_NODE ? event.target.parentNode : event.target;
    let slot = target.closest && target.closest('.manuscript-card');
    if (!slot) return [];
    const rect = slot.getBoundingClientRect();
    const x = event.pageX - rect.left;
    const y = event.pageY - rect.top;
    if (withinCards && y < START_OF_CARD) {
      return [];
    } else if ((slot.classList.contains('novel_scene') && x < RIGHT_HALF_OF_CARD) || x < 10) {
      return [slot.previousElementSibling && slot.previousElementSibling.dataset.id, slot.dataset.id];
    } else {
      return [slot.dataset.id, slot.nextElementSibling && slot.nextElementSibling.dataset.id];
    }
  }

  function getDragOptions(doc, index) {
    if (doc.type === 'novel_chapter' && doc.children.length) {
      const parent = projectStore.getParent(doc.id);
      if (index === 0 || (parent.type === 'novel_part' && parent.children[0] === doc.id)) {
        // If this is the first chapter in the novel, don't allow it to be moved unless it is empty, because a scene can't
        // be outside of a chapter
        return;
      }
    }

    return {
      start(event) {
        if (event.target.querySelector('.typewriter-editor:focus')) return false;
        addDocToDrag(event.dataTransfer, doc);
      },
    };
  }

  const dropOptions = {
    mimes,
    acceptable(event) {
      const type = getDropType(event.dataTransfer);
      const acceptable = type && allowedDropTypes.has(type);
      return acceptable;
    },
    over(event) {
      const type = getDropType(event.dataTransfer);
      let [beforeId, afterId] = getNearestDivision(event);
      const beforeDoc = beforeId && projectStore.getDoc(beforeId);
      const afterDoc = afterId && projectStore.getDoc(afterId);

      // Ensure scenes aren't trying to be put into parts
      if (type === 'novel_scene') {
        if (!beforeDoc || beforeDoc.type === 'novel_part') {
          return (dropId = undefined);
        }
      } else if (type === 'novel_part') {
        if (afterDoc && afterDoc.type === 'novel_scene') {
          return (dropId = undefined);
        }
      }

      if (beforeId) {
        dropId = beforeId;
        dropAfter = true;
      } else {
        dropId = afterId;
        dropAfter = false;
      }

      if (isDropDoc(event.dataTransfer, dropId) || (afterId && isDropDoc(event.dataTransfer, afterId))) {
        dropId = undefined;
      }
    },
    leave() {
      dropId = undefined;
    },
    drop(event, mime, droppedDoc) {
      if (!dropId) return;

      const droppedOn = dropId;
      dropId = undefined;
      const patch = projectStore.patch();
      const updatedDescendents = descendents.slice();

      // First create the doc if it is from outside the project.
      if (!projectStore.getDoc(droppedDoc.id)) {
        // Allow a doc to be imported into this project multiple times by giving it a new id.
        droppedDoc.id = projectStore.createDocId();
      }

      // Remove the doc from its old location in decendents (if it exists) and put it into its new position
      const origIndex = updatedDescendents.findIndex(d => d.id === droppedDoc.id);
      if (origIndex !== -1) {
        updatedDescendents.splice(origIndex, 1);
      }
      const index = updatedDescendents.findIndex(d => d.id === droppedOn) + (dropAfter ? 1 : 0);
      updatedDescendents.splice(index, 0, droppedDoc);

      const children = unflatten(updatedDescendents);
      if (!children) return;

      patchAnyUpdates(doc, children, patch);

      patch.save();
    },
  };

  function unflatten(updatedDescendents) {
    const children = new Map();
    const stack = [];
    let parent = doc; // Manuscript

    children.set(doc, []);

    const valid = updatedDescendents.every(doc => {
      while (parent && !canAcceptChild(parent, doc)) {
        parent = stack.pop();
      }
      // Invalid operation
      if (!parent) return false;

      children.get(parent).push(doc);

      if (doc.children) {
        stack.push(parent);
        parent = doc;
        children.set(doc, []);
      }
      return true;
    });

    return valid ? children : null;
  }

  function patchAnyUpdates(parent, childrenLookup, patch) {
    // Everything lands somewhere, so look for new items only and move them
    const children = childrenLookup.get(parent);
    const oldIds = new Set();
    parent.children.forEach(id => oldIds.add(id));

    // Move children around if they were moved within the parent
    const hasNewChildren = children.some(child => !oldIds.has(child.id));

    if (hasNewChildren) {
      // Move docs here from elsewhere or add a doc
      children.forEach((child, i) => {
        // Moved or added item
        if (oldIds.has(child.id)) return;
        if (projectStore.getDoc(child.id)) {
          patch.moveDoc(child.id, parent.id, i);
        } else {
          patch.createDoc(child, parent.id, i);
        }
      });
    } else if (children.length === parent.children.length) {
      // Nothing new, check if a doc moved from one place to another within the parent (only ever 1 doc is moved)
      children.some((child, i) => {
        if (child.id !== parent.children[i]) {
          if (child.id === parent.children[i + 1]) {
            // The doc that used to be here moved to later in the children array
            const movedId = parent.children[i];
            const index = children.findIndex(child => movedId === child.id);
            patch.moveDoc(movedId, parent.id, index);
          } else {
            // This doc was moved to here from later in the children array
            patch.moveDoc(child.id, parent.id, i);
          }
          return true;
        }
      });
    }

    children.forEach(child => {
      if (child.children) {
        patchAnyUpdates(child, childrenLookup, patch);
      }
    });
  }

  function canAcceptChild(parent, child) {
    const parentSettings = settings.getFor(parent);
    return !parentSettings.validChildTypes || parentSettings.validChildTypes[child.type];
  }

  function stop(event) {
    event.stopPropagation();
  }
</script>

<div class="manuscript-view">
  {#each books as book (book.id)}
    <div class="manuscript-book" data-id={book.id}>
      <EditableContent class="book-title" doc={book} field="title" header="h2" />

      <div
        class="manuscript-cards"
        use:droppable={!$readonly && dropOptions}
        on:mousemove={onMouseMove}
        on:mouseleave={onMouseLeave}
      >
        {#each bookChildren[book.id] as child, i (child.id)}
          <div class="manuscript-card {child.type}" data-id={child.id} use:draggable={getDragOptions(child, i)}>
            {#if dropId === child.id && !dropAfter}
              <div class="drop-placeholder">
                <div class="line">
                  <Icon path={mdiChevronDown} class="down" />
                  <Icon path={mdiChevronUp} class="up" />
                </div>
              </div>
            {/if}
            {#if child.type === 'novel_scene'}
              <Card doc={child} small showDelete highlightable let:editRibbon />
            {:else}
              <div class="novel-partition">
                <div class="partition-divider" />
                <EditableContent doc={child} class="title" field="title" header="h3" showTitleImages={false} />
              </div>
            {/if}
            {#if dropId === child.id && dropAfter}
              <div class="drop-placeholder">
                <div class="line">
                  <Icon path={mdiChevronUp} highlight class="up" />
                  <Icon path={mdiChevronDown} highlight class="down" />
                </div>
              </div>
            {/if}
            {#if hoveredDocId === child.id}
              <ManuscriptInsert doc={child} />
            {/if}
          </div>
        {/each}
      </div>
    </div>
  {/each}
</div>

<style>
  .manuscript-view {
    padding-bottom: 40px;
  }
  .manuscript-book {
    padding: 0 0.5rem;
  }
  .manuscript-book :global(.book-title) {
    margin: 1rem 0.5rem;
    color: var(--text-color-normal);
    text-shadow: var(--text-highlight);
  }

  .manuscript-cards {
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    padding: 10px;
  }
  .manuscript-card {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 170px;
  }
  /* extending the first item so that drag-drop can go to the first place in the book */
  .manuscript-card:first-child::before {
    content: '';
    position: absolute;
    left: -30px;
    top: 0;
    bottom: 0;
    width: 30px;
  }
  .manuscript-card:last-child::before {
    content: '';
    position: absolute;
    right: -20px;
    top: 0;
    bottom: 0;
    width: 20px;
  }
  .novel_part {
    margin-left: -10px;
    padding: 0 0 10px 0;
    /* Give it a width to encompass at least 1 scene and 1 chapter so that it will wrap to he next row with the scene and
     not trail at the end of a row by itself */
    width: 230px;
  }
  .novel_chapter {
    margin-left: -10px;
    padding: 22px 0 10px 0;
    /* Give it a width to encompass at least 1 scene so that it will wrap to he next row with the scene and not trail at
     the end of a row by itself */
    width: 210px;
  }
  .novel_scene {
    padding: 44px 20px 20px 0;
    width: 180px;
  }
  .novel-partition {
    display: flex;
    height: 100%;
  }
  .hovered {
    background: rgba(0, 0, 0, 0.1);
  }
  .novel-partition :global(.title) {
    position: absolute;
    left: 20px;
    z-index: 1;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color-normal);
    text-shadow: var(--text-highlight);
    white-space: nowrap;
  }
  .novel-partition :global(.title .typewriter-editor:focus) {
    width: auto;
    min-width: 176px;
    z-index: 1;
    background-color: var(--background-darkened);
    padding: 4px 20px 2px 6px;
    margin-top: -4px;
    margin-left: -6px;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.3), 1px 1px 1px rgba(255, 255, 255, 0.4);
    border-radius: 3px;
  }
  .novel-partition :global(.title .typewriter-editor:not(:focus)) {
    width: 170px;
    padding: 4px 0 2px 0;
    margin-top: -4px;
  }
  .novel_part .novel-partition :global(.title .typewriter-editor:focus) {
    width: auto;
    min-width: 196px;
  }
  .novel_part .novel-partition :global(.title .typewriter-editor:not(:focus)) {
    width: 190px;
  }
  .novel-partition :global(.title h3) {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .partition-divider {
    padding: 0 9px 0 10px;
    height: 100%;
  }
  .partition-divider::before {
    content: '';
    display: block;
    height: 100%;
    box-shadow: var(--vertical-bevel);
    width: 1px;
  }
  .manuscript-cards :global(.manuscript-card[draggable='true'] .partition-divider) {
    cursor: ew-resize;
  }

  .manuscript-cards :global(.novel_scene .novel-card) {
    cursor: pointer;
    margin: 0;
  }

  .manuscript-cards .novel_chapter + .novel_scene {
    margin-left: -180px;
  }
  .manuscript-cards .novel_part + .manuscript-card {
    margin-left: -210px;
  }
  .manuscript-card.drag-capture {
    opacity: 0.5;
  }
  .manuscript-card.drag-capture .partition-divider {
    border-radius: 3px;
    margin: 0 6px;
    background: var(--background);
    padding: 4px 3px 4px 4px;
  }
  .manuscript-card.dragging {
    opacity: 0.5;
  }
  .drop-placeholder {
    position: relative;
  }
  .novel-partition + .drop-placeholder {
    margin-left: -10px;
  }
  .novel_scene .drop-placeholder {
    height: calc(100% + 32px);
    margin-bottom: -10px;
  }
  .drop-placeholder .line {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 6px;
    width: 6px;
    background: var(--background-darkened);
    box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.2), 1px 1px 1px rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    font-size: 20px;
    height: 100%;
  }

  .drop-placeholder :global(.icon.up),
  .drop-placeholder :global(.icon.down) {
    position: absolute;
    left: -7px;
    color: var(--text-color-lighterer);
    font-size: 20px;
  }
  .drop-placeholder :global(.icon.up) {
    top: -20px;
  }
  .drop-placeholder :global(.icon.down) {
    bottom: -20px;
  }

  /* .drop-placeholder .line :global(.icon.down) {
  top: -58px;
  left: -7px;
  text-shadow: var(--text-highlight-inverse);
}
.drop-placeholder .line :global(.icon.up){
  top: 100%;
  left: -7px;
  text-shadow: var(--text-highlight);
} */

  .drop-placeholder:first-child .line {
    left: -4px;
  }
</style>
