<script>
  import { features, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiNote } from '@mdi/js';
  import { showStickyNotes } from '../stickies-store';

  function toggle() {
    if (!$features.has('stickies')) {
      features.promptUpgrade('stickies');
    } else {
      $showStickyNotes = !$showStickyNotes;
    }
  }
</script>

<button
  class="dropdown-item"
  class:checked={$features.has('stickies') && $showStickyNotes}
  on:click|stopPropagation={toggle}
>
  <Icon path={mdiNote} />
  {$t('sticky_notes')}
</button>
