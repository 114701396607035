import { $t, confirm, currentProjectMeta, observe } from '@dabble/app';
import { debounce } from 'lodash';
import { Editor, EditorChangeEvent, HistoryModule } from 'typewriter-editor';
import { checkEditor } from './grammar-check';
import { closeCurrentPopover } from './grammar-format';
import { createShowGrammarStore } from './grammar-stores';
import { removeTouchedIssues } from './grammar-updates';
import { grammarSettingsStore, shouldCheckStore } from './model';

// How often to check for changes to a line as the line changes during typing
const CHECK_INTERVAL = 1000;

/**
 * The grammar module checks lines of text for grammar issues and displays them in the editor. It watches for changes to
 * the document and updates the grammar issues as needed.
 */
export function grammarModule() {
  return (editor: Editor) => {
    // Don't record grammar changes in history
    (editor.modules.history as HistoryModule).options.unrecordedSources.add('grammar');

    // Set up stores for tracking whether the editor is in the viewport and whether grammar should be shown
    const showGrammarStore = createShowGrammarStore(editor);

    // Set up a delayed check so it doesn't check too often during typing
    const checkEditorDelayed = debounce(checkEditor.bind(null, editor), CHECK_INTERVAL);

    // Toggle grammar visibility on/off based on visibility and preferences
    const unsubscribe1 = observe([showGrammarStore, grammarSettingsStore], () => {
      editor.render();
    });

    // Check grammar when the document is visible and active
    const unsubscribe2 = observe([showGrammarStore, shouldCheckStore], ([showGrammar, shouldCheck]) => {
      if (showGrammar && shouldCheck) {
        // Check right away if the editor is visible and grammar/spelling is enabled
        checkEditor(editor);
      }
    });

    // When a change happens, remove touched issues and recheck the lines
    function onChanged(event: EditorChangeEvent) {
      const { change, doc } = event;

      // If nothing happened, or we're in readonly, don't do anything
      if (!change || !change.contentChanged || !editor.enabled) return;

      closeCurrentPopover();

      const updates = removeTouchedIssues(doc, change.delta, editor.change);

      // Remove the issues that were touched by the change as part of the operation so undo/redo will restore them
      if (updates.contentChanged) {
        updates.apply('grammar');
      }

      if (shouldCheckStore.get()) {
        checkEditorDelayed();
      }
    }

    return {
      init() {
        editor.root.spellcheck = false;
        editor.on('changed', onChanged);
      },
      destroy() {
        unsubscribe1();
        unsubscribe2();
        editor.off('changed', onChanged);
      },
    };
  };
}

function checkGrammarExtEnabled() {
  setTimeout(async () => {
    const hasPwaExt = document.getElementsByTagName('html')[0].getAttribute('pwa-launched');
    const pwaExtEnabled = [...document.getElementsByTagName('div')].reduce((has, div) => {
      if (div.getAttribute('contenteditable') && div.getAttribute('pwa2-uuid')) {
        return has && true;
      }
      if (div.getAttribute('contenteditable') && !div.getAttribute('pwa2-uuid')) {
        return has && false;
      }
      return has;
    }, true);
    const hasGExt = document.getElementsByTagName('body')[0].dataset.newGrCSCheckLoaded;
    const gExtDisabled = document.body.dataset.grExtDisabled;
    const dabblePwaGrammar = currentProjectMeta.get().grammarCheck;
    const dabblePwaSpelling = currentProjectMeta.get().spellingCheck;

    if (((hasPwaExt && pwaExtEnabled) || (hasGExt && !gExtDisabled)) && (dabblePwaGrammar || dabblePwaSpelling)) {
      const answer = await confirm($t('grammar_ext'), $t('grammar_ext_details'), { yesNo: true });
      if (answer) {
        await currentProjectMeta.update({ grammarCheck: false, spellingCheck: false });
      }
    }
  }, 1000);
}

observe(currentProjectMeta, meta => {
  if (meta?.grammarCheck !== undefined) {
    checkGrammarExtEnabled();
  }
});
