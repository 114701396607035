import { authed, uid } from "@dabble/app";
import { currentUser } from "@dabble/plugins/account/auth";

// check for network filter: /impact|24620/

// Add identify to all pages
authed.subscribe(async (user) => {
  const encoder = new TextEncoder();
  const email = currentUser.get()?.email;
  const encodedEmail = await crypto.subtle.digest('SHA-1', encoder.encode(email));
  const customerEmail = Array.from(new Uint8Array(encodedEmail))
    .map(v => v.toString(16).padStart(2, '0'))
    .join('');
  if (user) {
    const data = {
      customerId: `${uid.get()}`,
      customerEmail,
    };
    window.ire('identify', data);
  }
});

// Checkout Conversions
window.addEventListener('checkout', async (event: CustomEvent) => {
  const encoder = new TextEncoder();
  const orderPromoCode = event.detail.coupon;
  const email = currentUser.get()?.email;
  const encodedEmail = await crypto.subtle.digest('SHA-1', encoder.encode(email));
  const customerEmail = Array.from(new Uint8Array(encodedEmail))
    .map(v => v.toString(16).padStart(2, '0'))
    .join('');
  const subTotal = event.detail.total;
  const plan = event.detail.plan;
  const data = {
    orderId: event.detail.invoiceNumber, // required
    customerId: `${uid.get()}`,
    customerEmail,
    orderPromoCode,
    currencyCode: plan.currency.toUpperCase(), // required
    items: [
      {
        subTotal, // required
        category: plan.nickname, // required
        sku: plan.id,
        quantity: 1, // required
        name: plan.nickname
      },
    ]
  };

  window.ire('trackConversion', 45621,
    data,
    {
      verifySiteDefinitionMatch: true
    }
  );
  sessionStorage.removeItem('irclickid');
});
