import { projectMetas, projectStore, readonly } from '@dabble/app';
import { Doc, Project } from '@dabble/data/types';

const fields = ['title', 'subtitle', 'author'];

let currentProject = projectStore.get().project;
let current = getBook(currentProject);

// Update userProject when book title changes
projectStore.subscribe(({ project, childrenLookup }) => {
  if (readonly.get()) return;
  const oldProject = currentProject;
  currentProject = project;
  const old = current;
  current = getBook(project);

  if (!oldProject || !project || project.type !== 'novel') return;
  if (!current || (old && isSame(old, current))) return;
  if (!isSame(old, projectMetas.get()[project.id])) return; // If the project title was changed from the book title, don't update

  const updates: any = {};
  fields.forEach(field => (updates[field] = current[field]));
  projectMetas.update(project.id, updates);
});

function getBook(project: Project) {
  let manuscript;
  return (
    project &&
    project.type === 'novel' &&
    (manuscript = project.docs[project.children[0]]) &&
    project.docs[manuscript.children[0]]
  );
}

function isSame(oldBook: Doc, newBook: Doc) {
  if (!oldBook !== !newBook) return false;
  return (
    oldBook === newBook ||
    fields.every(field => (!oldBook[field] && !newBook[field]) || oldBook[field] === newBook[field])
  );
}
