import { ExportFileConstructor } from '../types';

export const files = {
  async word() {
    const { File } = await import(/* webpackChunkName: "novelExports" */ './word');
    return File;
  },
  async text() {
    const { File } = await import(/* webpackChunkName: "novelExports" */ './text');
    return File as unknown as ExportFileConstructor;
  },
  async html() {
    // const { File } = await import(/* webpackChunkName: "novelExports" */ './html');
    const { downloadHtml, shareToWeb, emailShared, sharedModalOpen } = await import(
      /* webpackChunkName: "novelExports" */ '../html'
    );
    return { downloadHtml, shareToWeb, emailShared, sharedModalOpen };
  },
  async google() {
    const { File } = await import(/* webpackChunkName: "novelExports" */ './google');
    return File;
  },
};
