<script>
  import { t } from '@dabble/app';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Page from '@dabble/toolkit/Page.svelte';

  export let doc;
</script>

<Page {doc} class="title-page">
  <EditableContent {doc} field="title" header="h1" class="bottom end" />
  <div class="info">{$t('project_by')}</div>
  <EditableContent {doc} field="author" header="h3" />
</Page>
