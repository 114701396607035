import waf from '@dabble/util/waf';

export interface DragOptions {
  effectAllowed?: 'none' | 'copy' | 'copyLink' | 'copyMove' | 'link' | 'linkMove' | 'move' | 'all';
  start?: (event: DragEvent) => false | void;
  captured?: () => void;
  end?: (event: DragEvent) => void;
}

export function draggable(node: HTMLElement, options: DragOptions) {
  let ended = true;

  function onMouseDown() {
    if (!options) return;
    const sel = node.ownerDocument.defaultView.getSelection();
    if (sel.anchorNode) sel.removeAllRanges();
  }

  async function onDragStart(event: DragEvent) {
    if (!options) return;
    const { dataTransfer } = event;
    ended = false;

    // the default, can override as needed
    dataTransfer.effectAllowed = options.effectAllowed || 'all';

    if (options.start && options.start(event) === false) {
      event.preventDefault();
      return;
    }

    event.stopPropagation();
    node.ownerDocument.body.classList.add('drag-drop');

    // The drag-capture class and dragCapture property is temporarily set so any visual changes can be made
    // for when the browser creates its drag image.
    node.classList.add('drag-capture');

    await waf();

    if (ended) return;

    options.captured && options.captured();

    // Remove the drag-capture class and set dragging for the item left behind to be styled
    node.classList.remove('drag-capture');
    await waf();
    node.classList.add('dragging');
  }

  function onDragEnd(event: DragEvent) {
    ended = true;
    options && options.end && options.end(event);
    node.ownerDocument.body.classList.remove('drag-drop');
    node.classList.remove('drag-capture');
    node.classList.remove('dragging');
  }

  options && node.setAttribute('draggable', 'true');
  node.addEventListener('mousedown', onMouseDown);
  node.addEventListener('dragstart', onDragStart);
  node.addEventListener('dragend', onDragEnd);

  return {
    update(value: DragOptions) {
      options = value;
      if (!options) node.removeAttribute('draggable');
      else node.setAttribute('draggable', 'true');
    },

    destroy() {
      node.removeAttribute('draggable');
      node.removeEventListener('mousedown', onMouseDown);
      node.removeEventListener('dragstart', onDragStart);
      node.removeEventListener('dragend', onDragEnd);
    },
  };
}
