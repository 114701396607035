import { alert, dbStore, t } from '@dabble/app';
import Compress from 'compress.js';
import { PasteEvent } from 'typewriter-editor';
import { FileOptions, cacheContent, createFileName, uploadContentFiles } from './content';

export interface ImageOptions extends FileOptions {
  maxSize?: number;
  returnImageInfo?: boolean;
  mime?: string;
}

export interface ImageInfo {
  dataUrl: string;
  width: number;
  height: number;
}
export interface ImageInfoAndURL extends ImageInfo {
  url: string;
}

export interface Image {
  image?: string;
  dataUrl: string;
  width: number;
  height: number;
  url?: string;
}

export type ImageStyles = 'inset-center' | 'outset-left' | 'outset-center' | 'center' | 'fill-width' | 'full-page';

export interface ImageAttributes {
  image: string;
  alt?: string;
  dataUrl?: string;
  style?: string;
  width: number;
  height: number;
}

const compress = new Compress();

const MAX_IMAGE_SIZE = 10_000_000;
const MAX_IMAGE_PX = 5000;

export async function saveImage(options: FileOptions, info?: any) {
  if (!options.file.type.startsWith('image/')) {
    return;
  }

  // Save the file to the database in an upload queue, then upload it async, return the file name
  const name = await createFileName(options);
  const url = `https://files.dabblewriter.com/content/images/${name}`;

  // check if file exists at files.dabblewriter
  const response = await fetch(url).catch(() => null); // this is so no error is thrown except expected file doesn't exist error
  if (response && response.status === 200) {
    return url;
  }
  await cacheContent(options, url);

  const { type, content } = await getContentAndType(options, info);

  // Add to database and then upload, remove from database once uploaded successfully
  await dbStore.get().stores.content_uploads.put({ name, type, content });
  uploadContentFiles();

  return url;
}

export async function constrainImage(
  opts: FileOptions & { maxSize: number; returnImageInfo: true }
): Promise<ImageInfo>;
export async function constrainImage(opts: FileOptions & { maxSize: number; returnImageInfo?: false }): Promise<Blob>;
export async function constrainImage({
  maxSize,
  returnImageInfo,
  mime = 'image/png',
  file,
}: ImageOptions): Promise<ImageInfo | Blob> {
  // Don't use compress.js as it always creates a JPEG (which is larger than PNG at this size)
  const img = document.createElement('img');

  return new Promise((resolve, reject) => {
    img.onload = async () => {
      if (!returnImageInfo && img.naturalWidth < maxSize && img.naturalHeight < maxSize) {
        return resolve(file);
      }
      const ratio = img.naturalWidth / img.naturalHeight;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const w = (canvas.width = Math.ceil(Math.min(maxSize, ratio * maxSize)));
      const h = (canvas.height = Math.ceil(Math.min(maxSize, (1 / ratio) * maxSize)));
      ctx.drawImage(img, 0, 0, w, h);
      if (returnImageInfo) {
        const dataUrl = canvas.toDataURL(mime);
        resolve({ dataUrl, width: img.naturalWidth, height: img.naturalHeight });
      } else {
        canvas.toBlob(blob => resolve(blob), mime);
      }
    };
    img.onerror = err => reject(err);

    img.src = URL.createObjectURL(file);
  });
}

export async function createImageInfo(options: FileOptions): Promise<ImageInfo> {
  return constrainImage({ ...options, maxSize: 5, returnImageInfo: true });
}

export function removeEmbededImagesOnPaste(event: PasteEvent) {
  if (hasImage(event.html.toString())) {
    event = updateImageDeltas(event);
  }

  return event;
}

export function hasImage(htmlString: string) {
  return htmlString.includes('<img');
}

export async function getImage(image: Image) {
  const sourceUrl = image.image || image.url;
  try {
    // fetch should grab from cache if it exists or go to url when online
    const response = await fetch(sourceUrl, {
      method: 'get',
    });
    return response.arrayBuffer();
  } catch (e) {
    // if it fails export small dataUrl instead
    return _base64ToArrayBuffer(image.dataUrl);
  }
}

async function getContentAndType(options: FileOptions, info?: any) {
  let file = options.file;
  const type = file.type;
  // compress the image if it's over 10MB, while keeping quality as high as possible.
  if (file.size > MAX_IMAGE_SIZE && type.startsWith('image/')) {
    if (!(file instanceof File)) {
      file = new File([file], `file.${type.split(/[-+/]/)[1]}`, { type });
    }
    file = await compressImage(file as File);
  }

  return { type, content: file };
}

async function compressImage(file: File) {
  const result = await compress.compress([file], {
    size: 10,
    quality: 1,
    maxWidth: MAX_IMAGE_PX,
    maxHeight: MAX_IMAGE_PX,
    // @ts-ignore rotate is needed for mobile compatibility but is not part of the CompressOption type
    rotate: true,
  });
  const img = result[0];
  const base64str = img.data;
  // Compress will always convert the image to a jpeg for best compression.
  return Compress.convertBase64ToFile(base64str);
}

export function updateImageDeltas(event: PasteEvent) {
  let removed = false;
  for (let i = event.delta.ops.length; i > 0; i--) {
    const j = i - 1;
    const op = event.delta.ops[j] as any;
    if (typeof op.insert !== 'string' && op.insert?.image) {
      if (!op.insert.image.match(/^https:\/\/files.dabblewriter.com/)) {
        event.delta.ops.splice(j, 1);
        removed = true;
      }
    }
  }
  if (removed) {
    alert(t.get()('content_paste_images_removed_title'), t.get()('content_paste_images_removed_text'));
  }

  return event;
}

function _base64ToArrayBuffer(base64: string) {
  const re = /data:image\/png;base64,(.*)/;
  const parts = re.exec(base64);
  if (parts) {
    const binary_string = window.atob(parts[1]);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  return base64;
}
