import { currentProjectMeta, finishedProjectLoad, finishedSyncing, preferences, settings, version } from '@dabble/app';
import { compareVersions } from '@dabble/util/versions';
import { ProjectSettings } from './types';

/*
For account-level migrations, which are run after a successful sync is completed, do this:

settings.configure('dabble', {
  migrations: {
    '2.5.0-templates': async () => {
      // ... check if templates exist, and if not, add templates (the -templates suffix is optional and prevents 2
      // migrations on the same version from overwriting each other)
    },
    '2.5.2': async () => {
      // ... do something else that will run after any migrations before 2.5.2 (they run in order of release version)
    }
  }
});

For project-level migrations, which are run when a project is open and not necessarily after a sync, do this:

settings.configure('novel', {
  migrations: {
    '2.5.0': async () => {
      // ...same as above, can add a dash-suffix, but less likely for multiple migrations to run on the same version
      // at the project level
    },
  }
});
*/

// Migrate data at the account level. Migrations should be set on settings with the key "2.5.0" or "2.5.0-pluginName".
async function runDataMigrations() {
  const migratedVersion = preferences.get().migratedVersion;
  if (migratedVersion && compareVersions(migratedVersion, version) >= 0) return;
  const allMigrations = settings.getFor('dabble').migrations || {};
  const migrations = Object.entries(allMigrations)
    .map(([key, migration]) => [key.replace(/-.+$/, ''), migration] as [string, () => any])
    .filter(([key]) => compareVersions(key, migratedVersion) > 0)
    .sort(([keyA], [keyB]) => compareVersions(keyA, keyB))
    .map(([, migration]) => migration);
  for (const migration of migrations) {
    await migration();
  }
  await preferences.update({ migratedVersion: version });
}

// Migrate data at the project level. Migrations should be set on settings with the key "2.5.0" or "2.5.0-pluginName".
async function runProjectMigrations() {
  const migratedVersion = currentProjectMeta.get().migratedVersion;
  if (migratedVersion && compareVersions(migratedVersion, version) >= 0) return;
  const allMigrations = (settings.getFor(currentProjectMeta.get().type) as ProjectSettings).migrations || {};
  const migrations = Object.entries(allMigrations)
    .map(([key, migration]) => [key.replace(/-.+$/, ''), migration] as [string, () => any])
    .filter(([key]) => compareVersions(key, migratedVersion) > 0)
    .sort(([keyA], [keyB]) => compareVersions(keyA, keyB))
    .map(([, migration]) => migration);
  for (const migration of migrations) {
    await migration();
  }
  await currentProjectMeta.update({ migratedVersion: version });
}

finishedSyncing(initialSessionSync => initialSessionSync && runDataMigrations());
finishedProjectLoad(runProjectMigrations);
