import { Readable, writable } from './store';

export type Documents = Set<Document>;

// For storing all the documents that are part of the application (iFrame docs are added and removed from the set)
export interface DocumentStore extends Readable<Documents> {
  add(document: Document): void;
  remove(document: Document): void;
}

export function createDocumentStore(): DocumentStore {
  let documents: Documents = new Set([document]);

  const { get, set, subscribe } = writable(documents);

  function add(document: Document) {
    documents = new Set(documents);
    documents.add(document);
    set(documents);
  }

  function remove(document: Document) {
    documents = new Set(documents);
    documents.delete(document);
    set(documents);
  }

  return {
    get,
    add,
    remove,
    subscribe,
  };
}
