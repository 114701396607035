import {
  appUpdate,
  collective,
  connectionSendAfterAuthed,
  dbStore,
  alert as modalAlert,
  projectStore,
  readonly,
  router,
  version,
} from '@dabble/app';
import { updateApp } from '@dabble/offline';
import { compareVersions } from '@dabble/util/versions';
import { Migration } from '../types';

let startSyncingProject: (projectId: string) => Promise<void>;
let stopSyncingProject: (projectId: string) => void;

export function setProjectFunctions(start: (projectId: string) => Promise<void>, stop: (projectId: string) => void) {
  startSyncingProject = start;
  stopSyncingProject = stop;
}

export {
  alert,
  clean,
  clearAppCache,
  clearLocalStorage,
  pause,
  refresh,
  resyncProject,
  sendLocalChanges,
  sendLocalData,
  update,
};

async function refresh() {
  await freeze();
  return () => window.location.reload();
}

async function clearLocalStorage() {
  localStorage.clear();
  return refresh();
}

async function clearAppCache() {
  const registrations = await navigator.serviceWorker.getRegistrations();
  for (const registration of registrations) {
    await registration.unregister();
  }
  const keys = (await caches.keys()).filter(key => key.startsWith('webpack-offline:dabble-'));
  for (const key of keys) {
    await caches.delete(key);
  }

  return refresh();
}

async function alert(migration: Migration) {
  await modalAlert(migration.title, migration.message);
}

async function pause(migration: Migration) {
  await new Promise(resolve => setTimeout(resolve, (migration.seconds || 0) * 1000));
}

async function update(migration: Migration) {
  if (process.env.NODE_ENV !== 'production' || compareVersions(version, migration.version as string) >= 0) return;
  updateApp();
  console.log('waiting for updateState to equal "installed"', migration.version);
  await new Promise<void>(resolve => {
    appUpdate.subscribe(update => {
      if (update.state === 'installed') resolve();
    });
  });
  return refresh();
}

async function sendLocalChanges(migration: Migration) {
  const { projectId } = migration;
  await connectionSendAfterAuthed('sendLocalChanges', projectId, await collective.store.getChanges(projectId));
}

async function sendLocalData(migration: Migration) {
  const db = dbStore.get();
  const allData: any = {};
  const stores = Object.values(db).filter(value => value && value.keyPath && typeof value.store === 'function');

  await Promise.all(
    stores.map(async store => {
      allData[store.name] = await store.getAll();
    })
  );

  // When imported, avoid running this migration over again
  allData.other = allData.other.map((item: any) =>
    item.id === 'migrations' ? { ...item, modified: migration.modified + 1 } : item
  );

  const json = JSON.stringify(allData);
  const tenMB = 1024 * 1024 * 10;

  for (let i = 0, part = 1; i < json.length; i += tenMB, part++) {
    await connectionSendAfterAuthed('sendLocalData', json.slice(i, i + tenMB), part);
  }
}

function clean() {
  // Don't delete the database before the migration record is updated, otherwise it will error
  return async () => {
    await dbStore.get().deleteDatabase();
    router.navigate('/');
    window.location.reload();
  };
}

async function resyncProject(migration: Migration) {
  const { projectId, version } = migration as { projectId: string; version: number };
  if (!projectId) return;

  await freeze();
  stopSyncingProject(projectId);

  const transaction = collective.store.start();
  transaction.deleteSnapshots(projectId, { after: version || -1 });
  transaction.deleteChanges(projectId, { after: version || -1 });
  await transaction.commit();

  await startSyncingProject(projectId);
  if (projectStore.get().projectId === projectId) {
    await projectStore.reload();
  }
  unfreeze();
}

async function freeze() {
  readonly.addLock('migrations');
  projectStore.saveText();
  await new Promise(r => setTimeout(r, 100));
}

function unfreeze() {
  readonly.removeLock('migrations');
}
