import { Doc } from '../types';
import { Children, emptyChildren } from './children';

export interface InTrash {
  [id: string]: boolean;
}

export const emptyInTrash: InTrash = {};

export function getInTrash(childrenLookup: Children) {
  if (childrenLookup === emptyChildren) return emptyInTrash;
  const inTrash: InTrash = {};

  function markInTrash(doc: Doc) {
    inTrash[doc.id] = true;
    if (!doc.virtual && childrenLookup[doc.id]) childrenLookup[doc.id].forEach(markInTrash);
  }

  if (childrenLookup.trash) childrenLookup.trash.forEach(markInTrash);
  return inTrash;
}
