import Tooltip from './TooltipComponent.svelte';

let currentTooltip: Tooltip;
let timeout: any;

// Adds an HTML tooltip on mouseenter
function tooltip(node: HTMLElement, html: string | { text: string; offset: number }, placement = 'top') {
  let offset = 0;
  if (html && typeof html === 'object') {
    offset = (html as any).offset || 0;
    html = (html as any).text;
  }
  let tooltip: Tooltip;

  function createTooltip() {
    if (!html) return;
    clearTimeout(timeout);
    if (!currentTooltip) {
      tooltip = currentTooltip = new Tooltip({
        target: document.body,
        props: { target: node, html, placement, offset },
      });
      (tooltip as any).removeTooltip = removeTooltip;
    } else {
      tooltip = currentTooltip;
      tooltip.$set({ target: node, html, placement, offset });
    }
  }

  function queueRemoveTooltip() {
    if (tooltip) {
      tooltip = null;
      timeout = setTimeout(removeTooltip, 100);
    }
  }

  function removeTooltip() {
    if (currentTooltip) {
      currentTooltip.$destroy();
      currentTooltip = null;
      tooltip = null;
    }
  }

  node.addEventListener('mouseenter', createTooltip);
  node.addEventListener('mouseleave', queueRemoveTooltip);
  node.addEventListener('mousedown', removeTooltip);

  return {
    update(value: string) {
      if (typeof value === 'object') {
        offset = (value as any).offset || 0;
        html = (value as any).text;
      } else {
        html = value;
      }
      if (tooltip) tooltip.$set({ html, offset });
    },

    destroy() {
      removeTooltip();
      node.removeEventListener('mouseenter', createTooltip);
      node.removeEventListener('mouseleave', queueRemoveTooltip);
      node.removeEventListener('mousedown', removeTooltip);
    },
  };
}

export { tooltip as tooltipTop };

export function tooltipRight(node: HTMLElement, html: string) {
  return tooltip(node, html, 'right');
}

export function tooltipLeft(node: HTMLElement, html: string) {
  return tooltip(node, html, 'left');
}

export function tooltipBottom(node: HTMLElement, html: string) {
  return tooltip(node, html, 'bottom');
}
