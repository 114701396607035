<script>
  import { t } from '@dabble/app';
  import Cost from './Cost.svelte';
  import { formatPrice } from './util';

  export let plan;
</script>

<Cost currency={plan.currency} interval={plan.interval} amount={plan.amount} />
{#if plan.amount !== 0}
  {#if plan.interval === 'month'}
    <div class="interval">{$t('checkout_interval', { currency: plan.currency })}</div>
  {:else}
    <div class="interval">{$t('checkout_interval_average', { currency: plan.currency })}</div>
    <div class="annually">
      {$t('checkout_annually', { total: formatPrice(plan.amount, plan.currency, true) })}
    </div>
  {/if}
{/if}

<style>
  .interval,
  .annually {
    color: var(--text-color-lightest);
    font-size: var(--font-size-xs);
    font-family: var(--font-family-ui);
    text-transform: uppercase;
  }
</style>
