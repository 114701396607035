<script>
  import { connected, dateOptions, features, router, t, userMeta } from '@dabble/app';
  import { slide } from '@dabble/toolkit/transitions';
  import { format } from 'date-fns';
  import { billing, delinquent, isPaused, subscription, trialAlmostUp, trialAvailable } from '../billing';

  let Intercom = window.Intercom;
  let inCheckout;
  let show = false;

  $: if ($connected) Intercom = window.Intercom;
  $: inCheckout = $router.path.startsWith('/checkout');
  $: inBilling = inCheckout || $router.path.startsWith('/account/billing');
  $: lang = $userMeta.preferences?.locale || 'en-US';

  // After a load, wait 2 seconds to sync before displaying errors
  setTimeout(() => (show = true), 2000);

  function gotoCheckout() {
    router.navigate('/checkout/plans');
  }

  function gotoBilling() {
    router.navigate('/account/billing');
  }

  function chat() {
    if (Intercom) Intercom('show');
  }
</script>

{#if show && $billing.stripeId && !$subscription && !inCheckout}
  <div class="alert danger" transition:slide={{ duration: 200 }}>
    {@html $t('billing_unsubscribed')}
    {#if $features.has('support_chat') && $connected && Intercom}
      <button class="btn link action" on:click={chat}>{$t('billing_support_chat')}</button>&nbsp;|&nbsp;
    {:else if $features.has('support_email') || $features.has('support_chat')}
      <a class="btn link action" href="mailto:support@dabblewriter.com" target="_blank">{$t('billing_support_email')}</a
      >&nbsp;&nbsp;
    {/if}
    <button class="btn small danger" on:click={gotoCheckout}
      >{$t($trialAvailable ? 'billing_start_another_trial' : 'billing_subscribe_now')}</button
    >
  </div>
{:else if show && $delinquent && !inCheckout}
  <div class="alert warning" transition:slide={{ duration: 200 }}>
    {@html $t('billing_delinquent')}
    <button class="btn small warning" on:click={gotoBilling}>{$t('billing_update_payment')}</button>
  </div>
{:else if show && $trialAlmostUp && !inCheckout}
  <div class="alert info" transition:slide={{ duration: 200 }}>
    {@html $t('billing_trial_almost_up')}
    <button class="btn small info" on:click={gotoCheckout}>{$t('billing_subscribe_now')}</button>
  </div>
{:else if $isPaused && !inBilling}
  <div class="alert warning" transition:slide={{ duration: 200 }}>
    {@html $t('billing_paused_subscription', {
      resumeAt: format($subscription.metadata.is_paused_until * 1000, 'PP', dateOptions),
    })}
  </div>
{/if}

<style>
  .alert {
    margin-bottom: 0;
  }
</style>
