<script>
  import { authed, locale, t } from '@dabble/app';
  import { billing, subscription } from '../billing';
  import { formatPrice, paymentString } from './util';

  let nextInvoice;

  $: if ($authed) loadNextInvoice();
  $: subscribed =
    $subscription.status !== 'trialing' ||
    (($subscription.metadata.is_paused_until || billing.payment) && !$subscription.cancel_at_period_end);

  async function loadNextInvoice() {
    if ($subscription.cancel_at_period_end) {
      nextInvoice = null;
      return;
    }
    nextInvoice = undefined;
    try {
      nextInvoice = (await billing.getUpcomingInvoice()) || null;
    } catch (err) {
      nextInvoice = null;
    }
  }
</script>

{#if subscribed || nextInvoice?.customer?.balance}
  <div class="upcoming-invoice">
    {#if nextInvoice?.customer?.balance && nextInvoice?.lines?.data?.[0]?.amount !== 0}
      <strong>{$t('billing_balance')}:</strong>
      {formatPrice(-nextInvoice.customer.balance, nextInvoice.currency) + ' ' + nextInvoice.currency}
    {/if}
    {#if subscribed}
      <strong>{$t('billing_next_payment')}:</strong>
      {#if nextInvoice === undefined}
        <div class="loading-placeholder" />
      {:else if nextInvoice}
        {paymentString(nextInvoice, $locale)}
      {:else}
        {$t('billing_next_payment_none')}
      {/if}
    {/if}
  </div>
{/if}

<style>
  .upcoming-invoice {
    margin-top: 10px;
    display: grid;
    grid: 1fr / 1fr 1fr;
    row-gap: 10px;
    align-items: center;
    color: var(--text-color-lighterer);
    font-size: var(--font-size-sm);
    border: 1px solid var(--background);
    padding: 10px;
    border-radius: 6px;
  }
  .upcoming-invoice .loading-placeholder {
    flex: 1 1;
    margin-left: 6px;
    max-width: 200px;
    margin-top: 7px;
    max-width: 200px;
  }
</style>
