import { ConnectionStore, ListenerData } from '@dabble/data/connection';
import { getNow } from '@dabble/data/date';
import { DabbleDatabase } from '@dabble/data/types';
import logging from '@dabble/util/log';
import { compareVersions } from '@dabble/util/versions';
import { isProduction } from '@dabble/version';
import { SyncingStore } from '../stores/syncing';
import { tab } from '../syncing';
import { ErrorReporter, Migration, MigrationHandler, SyncStep } from '../types';
import * as migrations from './available-migrations';
import { requireUpdate } from './utils';

interface MigrationStepData {
  version: string;
  db: DabbleDatabase;
  connection: ConnectionStore;
  syncing: SyncingStore;
}

const log = logging.tagColor('Migration', '#F2385A');
const modifiedSort = (a: Migration, b: Migration) => a.modified - b.modified;

export default function migrationStep(data: MigrationStepData): SyncStep {
  const { version, db, connection, syncing } = data;

  return { sync, start, name: 'migrationStep' };

  // Sync once
  async function sync() {
    let migrationMeta = await db.stores.other.get('migrations');
    if (!migrationMeta) {
      migrationMeta = { id: 'migrations', modified: getNow() };
      await db.stores.other.put(migrationMeta);
      return;
    }

    const [global, user] = await Promise.all([
      connection.getDocs('migrations', [['modified', '>', migrationMeta.modified]]),
      connection.getDocs('user_migrations', [['modified', '>', migrationMeta.modified]]),
    ]);

    const migrations = global.concat(user).sort(modifiedSort) as Migration[];

    syncing.down(true);

    for (let i = 0; i < migrations.length; i++) {
      try {
        await runMigration(migrations[i]);
      } catch (err) {
        syncing.down(false);
        throw err;
      }
    }
    syncing.down(false);
  }

  // Sync continually
  function start(report: ErrorReporter) {
    const pendingMigrations: Migration[] = [];
    let runningMigration: Promise<void>;

    return connection.listen(onMigration);

    function onMigration({ collection, data, isDelete }: ListenerData) {
      if (collection !== 'migrations' && collection !== 'user_migrations') return;
      const migration = data as Migration;
      if (isDelete || !migration) return;
      pendingMigrations.push(migration);
      pendingMigrations.sort(modifiedSort);
      if (!runningMigration) runNextMigration();
    }

    async function runNextMigration() {
      if (pendingMigrations.length) {
        syncing.down(true);
        try {
          runningMigration = runMigration(pendingMigrations.shift());
          await runningMigration;
        } catch (err) {
          report(err);
        }
        syncing.down(false);
        runningMigration = null;
        runNextMigration();
      }
    }
  }

  async function runMigration(migration: Migration) {
    const handler = (migrations as { [key: string]: Function })[migration.type] as MigrationHandler;
    if (!handler) return log('Migration', migration.type, 'does not exist in version', version);
    if (migration.minVersion && isProduction && compareVersions(version, migration.minVersion) < 0) {
      log('Update required', version, migration.minVersion);
      return requireUpdate(migration.minVersion);
    }

    log('Running migration', migration);
    let cleanup: void | Function;
    if (migration.type === 'refresh' || migration.type === 'alert') {
      tab.broadcast('migration', migration.type, migration);
    } else {
      cleanup = await handler(migration);
    }
    await db.stores.other.put({ id: 'migrations', modified: migration.modified });
    if (typeof cleanup === 'function') await cleanup();
    log('Finished migration', migration);
  }
}
