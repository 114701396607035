import { delegate, desktop, plugins } from '@dabble/app';
import { createId } from 'crypto-id';
import { RestError } from './error';
import { REST_URL } from './global-constants';

export type Hook = (request: Request) => any;
export interface Headers {
  [name: string]: string;
}

function getUrl(baseUrl: string, path: string) {
  if (path.includes('//')) return path;
  if (path[0] !== '/') path = `/${path}`;
  return `${baseUrl}${path}`;
}

export function createAPI(url: string, init: RequestInit = {}) {
  const baseUrl = url.replace(/\/$/, '');
  const headers = new Headers(init.headers);
  const hooks: Hook[] = [];

  async function request(path: string, init: RequestInit = {}) {
    const url = getUrl(baseUrl, path);
    if (!init.headers || !(init.headers instanceof Headers)) {
      init.headers = new Headers(init.headers);
    }
    if (headers) {
      for (const [key, value] of headers.entries()) {
        if (!init.headers.has(key)) {
          init.headers.set(key, value);
        }
      }
    }
    init.headers.set('Accept', 'application/json');
    if (desktop.oldApp) {
      init.headers.set('Authorization', 'Bearer ' + (await (plugins.stores.accounts as any).getIdToken()));
    }

    if (delegate) {
      init.headers.set('x-delegate', delegate);
    }

    if (Array.isArray(init.body) && init.body[0] instanceof Blob) {
      const boundary = createId(18);
      const body: any[] = [];
      init.body.forEach((blob: Blob, index: number) => {
        body.push(`--${boundary}\r\n`);
        body.push(`Content-Type: ${blob.type}\r\n`);
        body.push('\r\n');
        body.push(blob);
        body.push('\r\n');
      });
      body.push(`--${boundary}--\r\n`);
      const contentType = 'multipart/related; boundary=' + boundary;
      const multipartBody = new Blob(body, { type: contentType });
      init.body = multipartBody;
      // Blob.type will lower-case the contentType, so be sure to set the header with correct casing
      init.headers.set('Content-Type', contentType);
    } else if (isJsonable(init.body)) {
      if (!init.headers.has('Content-Type')) {
        init.body = JSON.stringify(init.body);
        init.headers.set('Content-Type', 'application/json');
      }
    }
    init.credentials = 'include';
    let request = new Request(url, init);

    for (let i = 0; i < hooks.length; i++) {
      const result = await hooks[i](request);
      if (result instanceof Request) {
        request = result;
      }
    }

    const response = await fetch(request);
    if (response.ok) {
      if (request.method !== 'DELETE') return await response.json();
      return response;
    } else {
      let text = await response.text();
      try {
        const data = JSON.parse(text);
        text = data.error || (typeof data === 'string' ? data : 'Unknown error');
      } catch (e) {}

      throw new RestError(response.status, text);
    }
  }

  function GET(path: string, headers?: any) {
    return request(path, { method: 'GET', headers });
  }

  function POST(path: string, body?: any, headers?: any) {
    return request(path, { method: 'POST', body, headers });
  }

  function PUT(path: string, body?: any, headers?: any): Promise<any> {
    return request(path, { method: 'PUT', body, headers });
  }

  function PATCH(path: string, body?: any, headers?: any): Promise<any> {
    return request(path, { method: 'PATCH', body, headers });
  }

  function DELETE(path: string, body?: any, headers?: any) {
    return request(path, { method: 'DELETE', body, headers });
  }

  function UPLOAD(path: string, mime: string, body?: any) {
    return request(path, {
      method: 'PUT',
      headers: { 'Content-Type': mime },
      body,
    });
  }

  function REQUEST(path: string, init: RequestInit) {
    return request(path, init);
  }

  return {
    hooks,
    GET,
    POST,
    PATCH,
    PUT,
    DELETE,
    UPLOAD,
    REQUEST,
  };
}

function isJsonable(obj: any) {
  return (
    obj &&
    !(
      typeof obj === 'string' ||
      obj instanceof Blob ||
      obj instanceof FormData ||
      obj instanceof File ||
      obj instanceof ReadableStream ||
      typeof obj.getReader === 'function'
    )
  );
}

export default createAPI(REST_URL);
