<script>
  import { router } from '@dabble/app';
  import SyncStatus from './SyncStatus.svelte';

  let hide;

  $: hide = /^\/(p|auth)\//.test($router.path);
</script>

{#if !hide}
  <div class="app-sync">
    <SyncStatus showOne />
  </div>
{/if}

<style>
  .app-sync {
    display: flex;
    margin: 0 4px;
    padding: 5px 6px;
    order: -10;
  }
</style>
