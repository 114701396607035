<script>
  import { appUpdate, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { tooltipBottom } from '@dabble/toolkit/tooltip';
  import { mdiUpdate } from '@mdi/js';

  function onClick() {
    $appUpdate.state === 'installed' && relaunch();
  }

  function relaunch() {
    window.location.reload();
  }
</script>

{#if $appUpdate.updateRequired}
  <Modal>
    <header>
      <h4>{$t('update_required')}</h4>
    </header>
    <section>
      <p>
        {$t('update_message', {
          version: typeof $appUpdate.updateRequired === 'string' ? 'v' + $appUpdate.updateRequired : '',
        })}
      </p>
      {#if $appUpdate.state !== 'installed'}
        <p>{$t('update_wait')}</p>
      {:else}
        <p>
          {$t('update_installed')}
          <button
            class="btn link"
            on:click={relaunch}
            use:tooltipBottom={$t($appUpdate.state !== 'installed' ? 'update_installing' : 'update_installed')}
            >{$t('update_restart')}</button
          >
        </p>
      {/if}
      <button
        class="btn primary icon"
        on:click={onClick}
        use:tooltipBottom={$t($appUpdate.state !== 'installed' ? 'update_installing' : 'update_installed')}
        class:updating={$appUpdate.state !== 'installed'}
        disabled={$appUpdate.state !== 'installed'}
      >
        <Icon path={mdiUpdate} />
        {$appUpdate.state !== 'installed' ? $t('update_updating') : $t('update_apply')}
      </button>
    </section>
  </Modal>
{/if}

<style>
  .btn.updating.icon {
    animation: anim-rotate 2s infinite linear;
    text-shadow: none;
  }

  @keyframes anim-rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
