import { dbStore, finishedSyncing, isProduction } from '@dabble/app';
import rest from '@dabble/data/rest';
import { uuid } from '@dabble/data/uuid';
import { connection } from '@dabble/plugins/sync/active-connection';
import log from '@dabble/util/log';

export interface FileOptions {
  file: File | Blob;
}

if (!isProduction) {
  // Used for dev workflow
  navigator.serviceWorker.register('/sw.js').then(
    function (registration) {
      // Registration was successful
      log('ServiceWorker registration successful with scope: ', registration.scope);
    },
    err => {
      // registration failed :(
      log('ServiceWorker registration failed: ', err);
    }
  );
}

finishedSyncing(uploadContentFiles);

export async function cacheContent(options: FileOptions, url: string) {
  const cache = await caches.open('content');
  const cachedContent = new Response(options.file);
  await cache.put(url, cachedContent);
}

export async function getContentUploads() {
  const result = await dbStore.get().stores.content_uploads.getAll();
  return result.map(({ name, type, content }) => new File([content], name, { type }));
}

export async function uploadContentFiles() {
  if (connection && !connection.state.get().online) return;
  const store = dbStore.get().stores.content_uploads;
  const result = await store.getAll();
  const files = result.map(({ name, type, content }) => new File([content], name, { type }));
  for (const file of files) {
    if (await uploadContent(file)) {
      await store.delete(file.name);
    }
  }
}

export async function uploadContent(file: File) {
  try {
    //sets the folder path to images, templates, etc then uploads
    const folderPath = file.type.split('/')[0] + 's';
    return await rest.UPLOAD(`${folderPath}/${file.name}`, file.type, new Blob([file], { type: file.type }));
  } catch (e) {
    return false;
  }
}

export async function createFileName(options: FileOptions) {
  const ext = await getExtension(options);
  const buffer = await getFileAsBufferArray(options);
  let name: string;

  if (crypto.subtle) {
    const hash = await crypto.subtle.digest('SHA-1', buffer);
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    name = `${hashHex}.${ext}`;
  } else {
    name = `${uuid(24)}.${ext}`;
  }
  return name;
}

async function getExtension(options: FileOptions) {
  let type: string;
  if (options.file) {
    const file = options.file;
    type = file.type;
  }
  return mimeTypeOverrides[type] || type.replace(/^[^/]+\//, '').replace(/\+.+$/, '');
}

async function getFileAsBufferArray(options: FileOptions) {
  const file = options.file;
  const { type } = file;
  const blob = new Blob([file], { type });
  return await blob.arrayBuffer();
}

// exceptions to the rule: image/<ext> not followed
const mimeTypeOverrides: Record<string, string> = {
  'image/vnd.microsoft.icon': 'ico',
  'image/svg+xml': 'svg',
  'image/jpeg': 'jpg',
};
