import { features, globalData, minute, plugins, readonly, userMeta } from '@dabble/app';
import { getNow } from '@dabble/data/date';
import { observe } from '@dabble/data/observe';
import { Readable, derived } from '@dabble/data/stores/store';
import { User } from '@dabble/data/types';
import {
  CANCELED_STATUSES,
  createBillingStore,
  createDiscountAvailableStore,
  createPlansStore,
  createTrialAvailableStore,
} from './stores';
import { OfferPlan, Plan, Product } from './types';
const DAY = 1000 * 60 * 60 * 24;

declare global {
  interface Window {
    Rewardful: any;
    rewardful: Function;
  }
}

const THREE_DAYS = 1000 * 60 * 60 * 24 * 3;
const LIFETIME_PRODUCT = 'prod_H4v4r4Nvin0irV'; // Order product, not subscription product
const LIFETIME_PLAN = 'plan_H4v52dHQBryIaT'; // Order product, not subscription product

export const billing = createBillingStore(userMeta);
export const plans = createPlansStore(globalData);
export const subscription = derived(billing, billing => {
  if (billing.subscription && !CANCELED_STATUSES.has(billing.subscription.status)) {
    return billing.subscription;
  }
});
export const delinquent = derived(
  subscription,
  subscription => subscription && (subscription.status === 'past_due' || subscription.status === 'incomplete')
);
export const trialing = derived(
  billing,
  billing => billing.subscription && billing.subscription.status === 'trialing' && !billing.payment
);
export const trialAlmostUp = derived([trialing, billing, minute], ([trialing, billing, minute]) => {
  if (!trialing) return;
  return minute.getTime() - THREE_DAYS < billing.subscription.trial_end;
});
export const trialAvailable = createTrialAvailableStore(billing, plans, minute);
export const discountAvailable = createDiscountAvailableStore(billing, plans, minute);
export const lifetimeProduct = derived(
  plans,
  plans => plans.all && (Object.values(plans.all).find((plan: any) => plan.id === LIFETIME_PRODUCT) as Product)
);
export const lifetimePrice = derived(
  [lifetimeProduct, plans],
  ([lifetimeProduct, plans]) =>
    plans.all && Object.values(plans.all).find((plan: any) => plan.id === lifetimeProduct.default_price)
);
export const lifetimePlan = derived(
  plans,
  plans => plans.all && Object.values(plans.all).find((plan: any) => plan.id === LIFETIME_PLAN)
);
export const currentPlan = derived([plans, subscription], ([plans, subscription]) => {
  if (!plans.all || !subscription) return;
  return (plans.all[subscription.plan.id] as Plan) || subscription.plan;
});
export const currentOfferPlan = derived([plans, currentPlan], ([plans, currentPlan]) => {
  if (!currentPlan || !plans.all) return;
  return {
    product: plans.all[currentPlan.product] as Product,
    month:
      currentPlan.interval === 'month'
        ? currentPlan
        : Object.values(plans.all).find(
            p =>
              p.object !== 'product' &&
              p.interval === 'month' &&
              p.product === currentPlan.product &&
              p.metadata.offer === currentPlan.metadata.offer
          ),
    year:
      currentPlan.interval === 'year'
        ? currentPlan
        : Object.values(plans.all).find(
            p =>
              p.object !== 'product' &&
              p.interval === 'year' &&
              p.product === currentPlan.product &&
              p.metadata.offer === currentPlan.metadata.offer
          ),
  } as OfferPlan;
});
export const availablePlans = derived(plans, plans => {
  const availablePlans: OfferPlan[] = [];
  if (plans.all) {
    const offer = plans.currentOffer;
    const lookup: Record<string, OfferPlan> = {};
    const products: Record<string, Product> = {};
    Object.values(plans.all).forEach(p => p.id !== LIFETIME_PRODUCT && p.object === 'product' && (products[p.id] = p));
    Object.values(plans.all).forEach(plan => {
      if (plan.object === 'product' || plan.metadata.offer !== offer) return;
      let offerPlan = lookup[plan.product];
      if (!offerPlan) {
        const product = products[plan.product];
        if (!product) return;
        lookup[plan.product] = offerPlan = { product, month: null, year: null };
        availablePlans.push(offerPlan);
      }
      if (plan.interval === 'month') {
        if (!offerPlan.month || offerPlan.month.amount > plan.amount) offerPlan.month = plan;
      } else {
        if (!offerPlan.year || offerPlan.year.amount > plan.amount) offerPlan.year = plan;
      }
    });
  }
  return availablePlans.sort((a, b) => (a?.year?.amount || 0) - (b?.year?.amount || 0));
});

function createIsPauseStore() {
  return derived(subscription, subscription => {
    return (
      subscription &&
      subscription.trial_end > 0 &&
      parseInt(subscription.metadata?.is_paused_until) === subscription.trial_end
    );
  });
}

export const isPaused = createIsPauseStore();

observe([subscription, plans, isPaused], ([subscription, plans, isPaused]) => {
  if (isPaused || !subscription || !subscription.plan) {
    readonly.addLock('billing');
    return features.clear();
  }
  readonly.removeLock('billing');
  const product = plans.all && (plans.all[subscription.plan.product] as Product);
  if (!product) return features.clear();
  features.replace(product.metadata.features.split(','));
});

plugins.register({
  billing,
  plans,
  subscription,
  delinquent,
  trialing,
  trialAlmostUp,
  trialAvailable,
  discountAvailable,
  currentPlan,
  currentOfferPlan,
  availablePlans,
  lifetimePlan,
  lifetimePrice,
});

if (window.rewardful) {
  window.rewardful('ready', () => {
    if (window.Rewardful.referral) {
      observe([billing, plugins.stores.currentUser as Readable<User>], async ([billingData, user]) => {
        if (!billingData || !user) return;
        if (getNow() - user.createdAt > DAY || billingData.referral) {
          // If the user was created previously, don't associate with this affiliate
          window.rewardful('reset');
        } else {
          await billing.setReferral(window.Rewardful.referral);
        }
      });
    }
  });
}
