<script>
  import { features, readonly } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipRight } from '@dabble/toolkit/tooltip';
  import { mdiTableRow, mdiTableRowRemove } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';

  export let y = undefined;
  export let visible = false;
  export let above = false;
  export let addTooltip = '';
  export let deleteTooltip = '';
  export let partial = false;

  $: disabled = $readonly || !$features.has('plotting');

  const dispatch = createEventDispatcher();

  function highlightActions(node) {
    let highlight;
    if (node) {
      highlight = { left: node.offsetLeft, top: node.offsetTop, y };
    }
    dispatch('highlight', highlight);
  }
</script>

<div
  class="plot-row-actions"
  class:visible
  class:above
  class:partial
  on:mouseenter={event => highlightActions(event.currentTarget)}
  on:mouseleave={() => highlightActions()}
>
  {#if addTooltip}
    <button
      class="icon light insert-above"
      on:click={() => dispatch('add')}
      use:tooltipRight={addTooltip}
      on:mouseenter={() => (partial = true)}
      on:mouseleave={() => (partial = false)}
    >
      <Icon path={mdiTableRow} />
    </button>
  {/if}
  {#if deleteTooltip}
    <button
      class="icon light"
      on:click={() => dispatch('delete')}
      use:tooltipRight={deleteTooltip}
      on:mouseenter={() => (partial = true)}
      on:mouseleave={() => (partial = false)}
    >
      <Icon path={mdiTableRowRemove} />
    </button>
  {/if}
</div>

<style>
  .plot-row-actions {
    position: relative;
    display: flex;
    width: 30px;
    height: calc(var(--card-height-sm) + 20px);
    align-items: center;
    contain: layout;
  }
  .plot-row-actions .insert-above {
    position: absolute;
    top: -14px;
  }
  .plot-row-actions > button {
    opacity: 0;
  }
  .plot-row-actions.visible:not(.partial) .icon,
  .plot-row-actions.above:not(.partial) .insert-above,
  .plot-row-actions.visible:not(.partial) + .plot-row-actions .insert-above {
    opacity: 1;
  }
  .plot-row-actions .icon:hover,
  .plot-row-actions .insert-above:hover .icon {
    opacity: 1;
    color: var(--text-color-light);
  }
</style>
