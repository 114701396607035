export class DabbleError extends Error {
  constructor(public code: string, message?: string) {
    super(message);
    this.code = code;
  }

  toString() {
    return `DabbleError: <${this.code}> ${this.message}`;
  }
}

export class RestError extends Error {
  public code: number;

  constructor(code: number, message: string) {
    super(message);
    this.code = code;
  }
}
