import { settings } from '@dabble/app';
import BillingAppHeader from './components/BillingAppHeader.svelte';
import BillingMenu from './components/BillingMenu.svelte';
import BillingRoutes from './components/BillingRoutes.svelte';
import CouponModal from './components/CouponModal.svelte';
import ReferralCode from './components/ReferralCode.svelte';
import './locales';
import './referral';

settings.configure('dabble', {
  globals: { CouponModal },
  headers: {
    BillingAppHeader,
  },
  routes: { BillingRoutes },
  plugins: {
    account: {
      menuItems: {
        BillingMenu,
      },
    },
  },
  profileOptions: { ReferralCode },
});
