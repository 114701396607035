import { settings } from '@dabble/app';
import StickyGutter from './components/StickyGutter.svelte';
import StickyNoteButton from './components/StickyNoteButton.svelte';
import StickyNoteMenuSection from './components/StickyNoteMenuSection.svelte';
import StickyNoteToggle from './components/StickyNoteToggle.svelte';
import './locales';

settings.configure('dabble', {
  textBubbleMenu: { StickyNoteButton },
  uiToggleMenu: { StickyNoteToggle },
  pageGutterLeft: { StickyGutter },
  textBubbleMenuSection: { StickyNoteMenuSection },
});
