import { appUpdate, projectStore, readonly } from '@dabble/app';
import { updateApp } from '@dabble/offline';

export function requireUpdate(minVersion: string) {
  readonly.addLock('update');
  projectStore.saveText();
  projectStore.forceTextUpdate();
  appUpdate.update(d => ({ ...d, updateRequired: minVersion || true }));
  // Force update now
  updateApp();
  return Promise.reject(new Error('CLIENT_UPDATE_REQUIRED'));
}
