<script>
  import { currentProjectMeta, inform, projectMetas, t } from '@dabble/app';

  let currentFont;
  let font;

  let family;
  let size;
  let sceneStyle;
  let sceneSpacing;
  let noteStyle;
  let noteSpacing;

  const defaults = {
    family: 'general',
    size: 12,
    sceneStyle: 'book',
    sceneSpacing: 'double',
    noteStyle: 'modern',
    noteSpacing: 'half',
  };

  $: currentFont = ($currentProjectMeta && { ...defaults, ...$currentProjectMeta.font }) || defaults;
  $: font = { ...defaults, ...currentFont };
  $: fontUpdated(font);

  function changed(font, updates) {
    return !Object.keys(updates).every(key => font[key] === updates[key]);
  }

  function onChange() {
    font = {
      family,
      size,
      sceneStyle,
      sceneSpacing,
      noteStyle,
      noteSpacing,
    };
  }

  function fontUpdated() {
    family = font.family;
    size = font.size;
    sceneStyle = font.sceneStyle;
    sceneSpacing = font.sceneSpacing;
    noteStyle = font.noteStyle;
    noteSpacing = font.noteSpacing;
  }

  async function saveFont() {
    Object.keys(font).forEach(key => !font[key] && delete font[key]);
    await projectMetas.update($currentProjectMeta.id, { font });
    inform('success', $t('updated_project_formatting'));
  }

  function resetFont() {
    font = {};
    saveFont();
  }
</script>

<div class="project-info">
  <div class="section">
    <h2>{$t('novel_formatting')}</h2>
    <div class="form-group">
      <label for="font-family-select">{$t('font')}</label>
      <div class="split">
        <select id="font-family-select" class="form-control" bind:value={family} on:change={onChange}>
          <option value="general">{$t('font_general')} {$t('font_size_default')}</option>
          <option value="romance">{$t('font_romance')}</option>
          <option value="thriller">{$t('font_thriller')}</option>
          <option value="literary">{$t('font_literary')}</option>
          <option value="screenplay">{$t('font_screenplay')}</option>
          <option value="screenplay-sans">{$t('font_screenplay_sans')}</option>
          <option value="sans">{$t('font_sans')}</option>
          <option value="dyslexic">{$t('font_dyslexic')}</option>
          <option value="times">{$t('font_times')}</option>
          <option value="courier">{$t('font_courier')}</option>
          <option value="comic">{$t('font_comic')}</option>
        </select>
        <select class="form-control" bind:value={size} on:change={onChange}>
          <option value={10}>10pt</option>
          <option value={12}>12pt {$t('font_size_default')}</option>
          <option value={14}>14pt</option>
          <option value={16}>16pt</option>
          <option value={18}>18pt</option>
        </select>
      </div>
    </div>

    <hr />

    <div class="form-group">
      <label for="scene-style-select">{$t('novel_scene_style')}</label>
      <select id="scene-style-select" class="form-control" bind:value={sceneStyle} on:change={onChange}>
        <option value="book">{$t('font_style_book')} {$t('font_size_default')}</option>
        <option value="book-modified">{$t('font_style_book_modified')}</option>
        <option value="modern">{$t('font_style_modern')}</option>
      </select>
    </div>
    <div class="form-group font-selection">
      <label for="scene-spacing-select">{$t('novel_scene_spacing')}</label>
      <select id="scene-spacing-select" class="form-control" bind:value={sceneSpacing} on:change={onChange}>
        <option value="single">{$t('font_single_spaced')}</option>
        <option value="half">{$t('font_half_spaced')}</option>
        <option value="double">{$t('font_double_spaced')} {$t('font_size_default')}</option>
      </select>
    </div>

    <div class="form-group">
      <button class="btn primary" disabled={!changed(currentFont, font)} on:click={saveFont}>{$t('font_save')}</button>
    </div>

    <div
      class="page preview font-{font.family || 'general'} paragraph-spacing-{font.sceneSpacing ||
        'double'} paragraph-style-{font.sceneStyle || 'book'}"
      style="font-size:{(font.size || 12) / 12}rem"
    >
      <h3>{$t('novel_scene_preview')}</h3>
      <div>
        <p>
          Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: once
          or twice she had peeped into the book her sister was reading, but it had no pictures or conversations in it,
          “and what is the use of a book,” thought Alice “without pictures or conversations?”
        </p>
        <p>
          So she was considering in her own mind (as well as she could, for the hot day made her feel very sleepy and
          stupid), whether the pleasure of making a daisy-chain would be worth the trouble of getting up and picking the
          daisies, when suddenly a White Rabbit with pink eyes ran close by her.
        </p>
      </div>
    </div>

    <hr />

    <div class="form-group font-selection">
      <label for="novel-note-style-select">{$t('novel_note_style')}</label>
      <select id="novel-note-style-select" class="form-control" bind:value={noteStyle} on:change={onChange}>
        <option value="book">{$t('font_style_book')}</option>
        <option value="book-modified">{$t('font_style_book_modified')}</option>
        <option value="modern">{$t('font_style_modern')} {$t('font_size_default')}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="novel-note-spacing-select">{$t('novel_note_spacing')}</label>
      <select id="novel-note-spacing-select" class="form-control" bind:value={noteSpacing} on:change={onChange}>
        <option value="single">{$t('font_single_spaced')}</option>
        <option value="half">{$t('font_half_spaced')} {$t('font_size_default')}</option>
        <option value="double">{$t('font_double_spaced')}</option>
      </select>
    </div>

    <div class="form-group">
      <button class="btn primary" disabled={!changed(currentFont, font)} on:click={saveFont}>{$t('font_save')}</button>
    </div>

    <div
      class="page preview font-{font.family || 'general'} paragraph-spacing-{font.noteSpacing ||
        'half'} paragraph-style-{font.noteStyle || 'modern'}"
      style="font-size:{(font.size || 12) / 12}rem"
    >
      <h3>{$t('novel_note_preview')}</h3>
      <div>
        <p>
          Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: once
          or twice she had peeped into the book her sister was reading, but it had no pictures or conversations in it,
          “and what is the use of a book,” thought Alice “without pictures or conversations?”
        </p>
        <p>
          So she was considering in her own mind (as well as she could, for the hot day made her feel very sleepy and
          stupid), whether the pleasure of making a daisy-chain would be worth the trouble of getting up and picking the
          daisies, when suddenly a White Rabbit with pink eyes ran close by her.
        </p>
      </div>
    </div>

    <div class="form-group">
      <button class="btn primary" disabled={!changed({}, currentFont)} on:click={resetFont}>{$t('font_reset')}</button>
    </div>
  </div>
</div>

<style>
  h3 {
    margin-top: 0;
  }
  .type {
    margin-bottom: 2rem;
    text-align: center;
    color: var(--text-color-lightest);
  }
  .novel-title :global(h1) {
    font-size: 5em;
    color: var(--text-color-light);
    margin-top: calc(-1 * var(--page-padding));
    padding-top: var(--page-padding);
    padding-bottom: 0;
    text-align: center;
  }
  .novel-title :global(h2) {
    font-size: 2.5em;
    color: var(--text-color-light);
    padding-bottom: 0;
    text-align: center;
  }
  .novel-title :global(h3) {
    font-size: 2em;
    color: var(--text-color-lighterer);
    text-align: center;
  }

  .project-info :global(.page) {
    padding: 0;
    min-height: auto;
  }
  .split {
    display: flex;
  }
  .split > * {
    flex: 1;
  }
  .split > :not(:first-child) {
    margin-left: 16px;
  }
  .preview h3 {
    text-align: center;
    font-family: var(--font-family-header);
    margin: 0.5em 0;
    padding: 0;
  }
</style>
