type EventCheck<Event> = (event: Event) => boolean;
type EventListener<Event> = (event: Event) => void;
type KeyboardListener = (event: KeyboardEvent) => void;
type MouseListener = (event: MouseEvent) => void;

export function escKey(node: HTMLElement, callback: KeyboardListener) {
  return handleKeyDown(node, callback, 'Escape');
}

export function enterKey(node: HTMLElement, callback: KeyboardListener) {
  return handleKeyDown(node, callback, 'Enter');
}

export function ctrlEnterKey(node: HTMLElement, callback: KeyboardListener) {
  return handleEvent(node, 'keydown', event => (event.ctrlKey || event.metaKey) && event.key === 'Enter', callback);
}

export function delKey(node: HTMLElement, callback: KeyboardListener) {
  return handleKeyDown(node, callback, 'Delete');
}

export function tabKey(node: HTMLElement, callback: KeyboardListener) {
  return handleKeyDown(node, callback, 'Tab');
}

export function backspaceKey(node: HTMLElement, callback: KeyboardListener) {
  return handleKeyDown(node, callback, 'Backspace');
}

export function clickThis(node: HTMLElement, callback: MouseListener) {
  return handleEvent(node, 'click', event => event.target === event.currentTarget, callback);
}

function handleKeyDown(node: HTMLElement, callback: KeyboardListener, key: string) {
  return handleEvent(node, 'keydown', event => event.key === key, callback);
}

function handleEvent<EventType extends Event>(
  node: HTMLElement,
  event: string,
  check: EventCheck<EventType>,
  callback: EventListener<EventType>
) {
  function onEvent(event: EventType) {
    if (check(event)) callback(event);
  }

  node.addEventListener(event, onEvent);

  return {
    destroy() {
      node.removeEventListener(event, onEvent);
    },
  };
}
