<script>
  import { authed, connectionSendAfterAuthed, dateOptions, inform, MOBILE, size, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiLoading, mdiRefresh } from '@mdi/js';
  import { format } from 'date-fns';
  import { formatPrice } from './util';

  const statusColors = {
    paid: 'success',
    open: 'warning',
  };

  let invoices;
  let loadingInvoices = false;
  let noMoreInvoices = false;
  let retrying = {};

  $: if ($authed && !invoices) loadInvoices();

  async function loadInvoices() {
    invoices = await connectionSendAfterAuthed('getInvoices', 10);
    if (invoices.length < 10) noMoreInvoices = true;
  }

  async function loadMoreInvoices() {
    loadingInvoices = true;
    const lastInvoice = invoices.slice(-1).pop();
    try {
      const moreInvoices = await connectionSendAfterAuthed('getInvoices', 10, lastInvoice.id);
      if (moreInvoices.length < 10) noMoreInvoices = true;
      invoices = [...invoices, ...moreInvoices];
    } catch (err) {
      inform('danger', $t(err.message));
    }
    loadingInvoices = false;
  }

  async function retryPayment(invoice) {
    retrying = { ...retrying, [invoice.id]: true };
    try {
      invoice = await connectionSendAfterAuthed('retryPayment', invoice.id);
      invoices = invoices.map(i => (i.id === invoice.id ? invoice : i));
    } catch (err) {
      inform('danger', $t(err.message));
    }
    retrying = { ...retrying };
    delete retrying[invoice.id];
  }

  function getStatus(invoice) {
    if (!invoice.auto_advance && invoice.status === 'open') return 'void';
    return invoice.status;
  }
</script>

<div class="section">
  <h2>{$t('billing_history')}</h2>
  <table class="invoices table {$size === MOBILE ? 'small' : ''}">
    <thead>
      <tr>
        <th class="invoice-number">{$t('billing_invoice_number')}</th>
        <th class="invoice-status"><!-- Status --></th>
        <th class="invoice-amount" colspan={$size === MOBILE ? 1 : 2}
          >{$t($size === MOBILE ? 'billing_invoice_amount_abbr' : 'billing_invoice_amount')}</th
        >
        <th class="invoice-created">{$t('billing_invoice_created')}</th>
      </tr>
    </thead>
    <tbody>
      {#if !invoices}
        <tr class="invoice-row">
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
        </tr>
        <tr class="invoice-row">
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
        </tr>
        <tr class="invoice-row">
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
          <td><div class="loading-placeholder" /></td>
        </tr>
      {:else if !invoices.length}
        <tr class="no-invoices">
          <td colspan="5">{$t('billing_invoices_none')}</td>
        </tr>
      {:else}
        {#each invoices as invoice}
          <tr class="invoice-row">
            <td class="invoice-number"><a href={invoice.hosted_invoice_url} target="_blank">{invoice.number}</a></td>
            <td class="invoice-status">
              <span class="badge badge-pill {statusColors[getStatus(invoice)] || 'default'}"
                >{$t(`invoice_${getStatus(invoice)}`)}</span
              >
              {#if getStatus(invoice) === 'open'}
                <button
                  class="icon"
                  use:tooltipTop={$t('billing_retry_payment')}
                  on:click={() => retryPayment(invoice)}
                  disabled={retrying[invoice.id]}
                >
                  <Icon path={mdiRefresh} />
                </button>
              {/if}
            </td>
            <td class="invoice-amount">{formatPrice(invoice.total, invoice.currency, true)}</td>
            {#if $size !== MOBILE}
              <td class="invoice-currency">{invoice.currency}</td>
            {/if}
            <td class="invoice-created"
              >{format(new Date(invoice.created * 1000), $size === MOBILE ? 'P' : 'PP', dateOptions)}</td
            >
          </tr>
        {/each}
        <tr class="invoice-row">
          <td class="load-more" colspan="5">
            {#if loadingInvoices}
              <Icon path={mdiLoading} spin />
            {:else if !noMoreInvoices}
              <button class="btn link" on:click={loadMoreInvoices}>{$t('billing_load_more_invoices')}</button>
            {/if}
          </td>
        </tr>
      {/if}
    </tbody>
  </table>
</div>

<style>
  .loading-message {
    margin-top: -20px;
    font-size: var(--font-size-sm);
    color: var(--text-color-lighter);
  }
  .invoice-row {
    color: var(--text-color-lighter);
  }
  .invoice-row td {
    white-space: nowrap;
  }
  .invoice-row .loading-placeholder {
    margin-top: 7px;
  }
  .invoices {
    font-size: var(--font-size-sm);
  }
  thead th {
    color: var(--text-color-lighter);
  }
  .invoice-number {
    width: 120px;
  }
  .invoice-status {
    width: 80px;
  }
  .invoice-amount {
    width: 60px;
  }
  td.invoice-amount {
    color: var(--text-color-normal);
  }
  .invoice-currency {
    width: 60px;
  }
  td.invoice-currency {
    color: var(--text-color-lightest);
    text-transform: uppercase;
  }
  .invoice-actions {
    width: 28px;
  }
  .load-more {
    text-align: center;
  }
</style>
