import { isProduction } from '@dabble/version';
import { mdiAlphaTBox, mdiDelete, mdiFile, mdiFolder } from '@mdi/js';
import i18next from 'i18next';
import lodashGet from 'lodash/get';
import { TextDocument } from 'typewriter-editor';
import { AnyMap, DabbleSettings, Doc, DocSettings, ObjectWithSettings, ProjectMeta, ProjectSettings } from '../types';
import { Readable, writable } from './store';

export type TypeSettings = DabbleSettings | ProjectSettings | DocSettings;

export interface AllSettings {
  dabble: DabbleSettings;
  project: ProjectSettings;
  file: DocSettings;
  folder: DocSettings;
  trash: DocSettings;
  [type: string]: any;
}

export type DocOrDocId = string | Doc | ProjectMeta;
export type DocOrType = string | Doc;
export type PossibleSettings = DabbleSettings | ProjectSettings | DocSettings;

export interface SettingsStore extends Readable<AllSettings> {
  getPlaceholder(doc: ObjectWithSettings, field?: string): any;
  getPlaceholderClass(
    doc: ObjectWithSettings,
    field?: string,
    isEmpty?: boolean
  ): '' | 'unstyled-placeholder' | 'placeholder';
  getFor(value: 'dabble'): DabbleSettings;
  getFor(value: DocOrType): DocSettings | ProjectSettings;
  configure(type: string, configs: PossibleSettings, extendsType?: string): void;
  getValuesFromPlugins(name: string, ...docs: DocOrDocId[]): any[];
}

export function getSetting<T, A extends Array<any> = any[]>(value: T | ((...args: A) => T), ...args: A): T {
  if (typeof value === 'function') return (value as (...args: A) => T)(...args);
  else return value;
}

const emptySettings: any = {};
const noSettings: any = { hideInNavigation: true };

export function createSettingsStore(defaults: AllSettings = defaultSettings): SettingsStore {
  let settings = defaults;
  const { get, set, subscribe } = writable<AllSettings>(settings);

  /**
   * Helper method to get the placholder value for a given document field from its settings. Depends on settings.
   */
  function getPlaceholder(doc: Doc, field = 'title') {
    const settings = getFor(doc);
    const placeholder = lodashGet(settings, `placeholders.${field}`);
    if (!placeholder) return '';
    return typeof placeholder === 'function' ? placeholder(doc, field, i18next.t) : i18next.t(placeholder) || '';
  }

  /**
   * Helper method to get the placholder class for a given document field from its settings. A placeholder may sometimes
   * be styled like regular text depending on the settings. Returns either an empty string when the text is not empty,
   * "unstyled-placeholder" when the text is empty but the placeholder text should be styled as regular text, and
   * "placeholder" when the placeholder text should look like a placeholder.
   */
  function getPlaceholderClass(doc: Doc, field = 'title', isEmpty?: boolean) {
    if (isEmpty === false) return '';
    const docSettings = getFor(doc);
    if (isEmpty !== true) {
      const value = doc[field];
      // Handle non-empty strings and non-empty Deltas
      if (!docSettings || !isValueEmpty(value)) return '';
    }
    const unstyled = lodashGet(docSettings, `unstyledPlaceholders.${field}`);
    const value = typeof unstyled === 'function' ? unstyled(doc, field) : unstyled;
    return (value && 'unstyled-placeholder') || 'placeholder';
  }

  /**
   * Helper method to get the settings for the given type of object/document.
   */
  function getFor(value: DocOrType) {
    if (!value) return noSettings;
    const type = typeof value === 'string' ? value : value.type;
    const typeSettings = settings[type || (settings.dabble.defaultProjectType as string)];
    if (!typeSettings && !isProduction) console.error(`Settings not found for type "${type}"`);
    return typeSettings || noSettings;
  }

  /**
   * Configures settings for a given type.
   */
  function configure(type: string, configs: TypeSettings, extendsType?: string) {
    const base = settings[type] || (extendsType ? settings[extendsType] : emptySettings);
    set((settings = { ...settings, [type]: mergeDeep(base, configs) }));
  }

  function getValuesFromPlugins(name: string, ...docs: DocOrDocId[]) {
    const plugins: any[] = [];
    docs.unshift('dabble');
    const nameParts = name.split('.'); // Allow "plugins.account.menuItems"

    docs.forEach(doc => {
      if (!doc) return;
      const config = getFor(doc);
      for (let i = 0, base = config; i < nameParts.length; i++) {
        if (!base) break;
        const prop = nameParts[i],
          last = i === nameParts.length - 1;
        if (last) {
          if (base[prop]) plugins.push(...Object.values(base[prop] as AnyMap));
        } else {
          base = base[prop];
        }
      }
    });

    return plugins;
  }

  return {
    getPlaceholder,
    getPlaceholderClass,
    getFor,
    get,
    configure,
    getValuesFromPlugins,
    subscribe,
  };
}

export const defaultSettings: AllSettings = {
  dabble: {
    defaultProjectType: 'project',
    headers: {},
    globals: {},
    routes: {},
    headerLeft: {},
    headerRight: {},
    statusBarLeft: {},
    statusBarRight: {},
    pageGutterLeft: {},
    pageGutterRight: {},
  },
  project: {
    placeholders: {
      title: 'project_untitled',
      author: 'author_unknown',
    },
    unstyledPlaceholders: {},
    noDeleteInNavigation: true,
    hideFolderToggle: true,
    undraggable: true,
    hasChildren: true,
    openByDefault: true,
    createMenu: ['file', 'folder'],
    menuTypes: ['folder', 'file'],
  },
  file: {
    placeholders: {
      title: 'file_untitled',
    },
    unstyledPlaceholders: {},
    icon: mdiFile,
  },
  folder: {
    placeholders: {
      title: 'folder_untitled',
    },
    unstyledPlaceholders: {},
    icon: mdiFolder,
    hasChildren: true,
    openByDefault: true,
    menuTypes: ['folder', 'file'],
  },
  templates: {
    hasChildren: true,
    icon: mdiAlphaTBox,
    noDeleteInNavigation: true,
    undraggable: true,
    openByDefault: false,
    uneditable: true,
    unstyledPlaceholders: {
      title: true,
    },
    placeholders: {
      title: 'templates',
    },
    menuTypes: [],
  },
  trash: {
    hasChildren: true,
    icon: mdiDelete,
    noDeleteInNavigation: true,
    undraggable: true,
    unselectable: true,
    openByDefault: false,
    uneditable: true,
    unstyledPlaceholders: {
      title: true,
    },
    placeholders: {
      title: 'trash',
    },
    menuTypes: [],
  },
};

function isValueEmpty(value: any) {
  if (!value) return true;
  if (value instanceof TextDocument && value.length === 1) return true;
  return false;
}

function isObject(value: any) {
  return value && typeof value === 'object' && !Array.isArray(value);
}

function mergeDeep<T>(target: any, source: any): T {
  target = { ...target };
  Object.keys(source).forEach(key => {
    const value = source[key];
    if (isObject(value) && target[key]) {
      target[key] = mergeDeep(target[key], value);
    } else if (value !== null && value !== undefined) {
      target[key] = value;
    } else {
      delete target[key];
    }
  });
  return target;
}
