import { StoreChangeDetail } from 'browserbase';
import { DatabaseStore } from '../dabble-database';
import { DabbleDatabase } from '../types';
import { Readable, writable } from './store';

export interface LoadedProjects {
  id: 'loaded-projects';
  [id: string]: boolean | string;
}

export interface LoadedProjectsStore extends Readable<LoadedProjects> {
  mark: (projectId: string, isLoaded: boolean) => Promise<void>;
  contains: (projectId: string) => boolean;
}

const EMPTY: LoadedProjects = { id: 'loaded-projects' };

export function createLoadedProjectsStore(dbStore: DatabaseStore): LoadedProjectsStore {
  let db: DabbleDatabase;
  let loadedProjects: LoadedProjects = EMPTY;
  const { get, set, subscribe } = writable<LoadedProjects>(loadedProjects);

  dbStore.register(async value => {
    if (db) {
      set(EMPTY);
    }

    db = value;

    if (db) {
      loadedProjects = (await db.stores.other.get('loaded-projects')) || EMPTY;
      db.stores.other.addEventListener('change', onChange);
      set(loadedProjects);
    }
  });

  function onChange({ detail: { obj, key, declaredFrom } }: CustomEvent<StoreChangeDetail<LoadedProjects>>) {
    if (declaredFrom === 'local' || key !== 'loaded-projects') return;
    set(obj);
  }

  async function mark(projectId: string, isLoaded: boolean) {
    if (!!loadedProjects[projectId] === isLoaded) return;
    if (isLoaded) {
      loadedProjects = { ...loadedProjects, [projectId]: true };
    } else {
      loadedProjects = { ...loadedProjects };
      delete loadedProjects[projectId];
    }
    set(loadedProjects);
    await db.stores.other.put(loadedProjects);
  }

  function contains(projectId: string) {
    return !!loadedProjects[projectId];
  }

  return {
    mark,
    contains,
    get,
    subscribe,
  };
}
