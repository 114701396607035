<script>
  import { dateOptions, router, t } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiClose, mdiUndoVariant } from '@mdi/js';
  import { format } from 'date-fns';
  import { createEventDispatcher } from 'svelte';
  import { billing, isPaused, plans } from '../billing';
  import Card from './Card.svelte';
  import CardInput from './CardInput.svelte';
  import Discount from './Discount.svelte';
  import PauseSubscriptionOptions from './PauseSubscriptionOptions.svelte';
  import Price from './Price.svelte';
  import UpcomingInvoice from './UpcomingInvoice.svelte';

  const dispatch = createEventDispatcher();

  export let disabled;
  export let subscription;

  let payment;
  let addNewPayment;
  let focusNewPayment;
  let cardInput;
  let showPause = false;

  $: payment = $billing.payment;
  $: autoToggleAddCard(payment);
  $: plan = subscription.plan;
  $: annualPlan = getAnnualPlan(plan, $plans.all);

  async function addCard(thenFocus) {
    addNewPayment = true;
    focusNewPayment = thenFocus;
  }

  async function saveAddCard() {
    const paymentMethodId = await cardInput.save();
    if (paymentMethodId) {
      dispatch('addCard', paymentMethodId);
    }
  }

  function closeAddCard() {
    addNewPayment = false;
  }

  function autoToggleAddCard(payment) {
    if (payment) {
      if (addNewPayment) closeAddCard();
    } else {
      if (!addNewPayment) addCard();
    }
  }

  function getAnnualPlan(plan, allPlans) {
    return (
      plan &&
      plan.interval === 'month' &&
      allPlans &&
      Object.values(allPlans).find(
        other => other.product === plan.product && other.currency === plan.currency && other.interval === 'year'
      )
    );
  }

  function switchToAnnual() {
    router.navigate(`/checkout/confirm?plan=${annualPlan.id}`);
  }

  function navigateToSurvey() {
    router.navigate('/account/billing/cancel');
  }

  function hidePause() {
    showPause = false;
  }
</script>

{#if subscription.cancel_at_period_end}
  <Alert type="warning">
    {$t('billing_cancellation_scheduled', {
      date: format(new Date(subscription.cancel_at * 1000), 'PPP', dateOptions),
    })}
    <button class="btn link resub" on:click={() => dispatch('undoCancellation')} {disabled}
      >{$t('billing_cancellation_undo')} <Icon path={mdiUndoVariant} inline /></button
    >
  </Alert>
{/if}
<div class="layout">
  <div class="span-8 span-4-mobile subscription-info">
    <h2>{$plans.all[subscription.plan.product].name}</h2>
    <div class="plan-description">{$plans.all[subscription.plan.product].metadata.description}</div>
    <div class="price">
      <Price plan={subscription.plan} />
    </div>
    {#if plan.amount !== 0}
      <Discount />
      <UpcomingInvoice />
    {/if}
  </div>
  <div class="span-4 span-4-mobile actions">
    {#if plan.amount !== 0}
      <button class="btn primary" on:click={() => dispatch('subscribe')} {disabled}>{$t('billing_change_plan')}</button>
      {#if plan.interval !== 'year'}
        <button class="btn secondary" on:click={switchToAnnual} {disabled}>{$t('billing_annual_switch')}</button>
        <div class="annual-message">{$t('billing_annual_switch_message')}</div>
      {/if}
      {#if !subscription.cancel_at_period_end}
        <button class="btn secondary" on:click={navigateToSurvey} {disabled}>{$t('billing_cancel_subscription')}</button
        >
      {/if}
      {#if $isPaused}
        <button class="btn secondary" on:click={() => dispatch('unpause')} {disabled}>{$t('billing_pause_undo')}</button
        >
      {:else}
        <button class="btn secondary" on:click={() => (showPause = true)} {disabled}
          >{$t('billing_pause_subscription')}</button
        >
      {/if}
      <button class="btn link apply-coupon-link" on:click={() => dispatch('coupon')} {disabled}
        >{$t('billing_coupon_apply')}</button
      >
    {/if}
  </div>
  <hr class="span-12" />
  <div class="small-section-label span-12">{$t('billing_payment_method')}</div>
  {#if addNewPayment}
    <div class="span-4-mobile span-8-tablet span-10-desktop new-card">
      <div class="card-form">
        <CardInput bind:this={cardInput} autofocus={focusNewPayment} />
        <button class="btn primary" on:click={saveAddCard} {disabled}
          >{$t(payment ? 'billing_change_card' : 'billing_add_card')}</button
        >
        {#if payment}
          <button class="icon" on:click={closeAddCard} use:tooltipTop={$t('billing_cancel')} {disabled}>
            <Icon path={mdiClose} />
          </button>
        {/if}
      </div>
    </div>
  {:else}
    <div class="span-5">
      {#if payment}
        <Card card={payment} />
      {/if}
    </div>
  {/if}
  {#if !addNewPayment}
    <div class="span-3 actions">
      {#if payment}
        <button class="btn link remove-card" on:click={() => dispatch('removeCard')} {disabled}
          >{$t('billing_remove_card')}</button
        >
      {/if}
    </div>
    <div class="span-4 actions">
      <button class="btn secondary" on:click={() => addCard(true)} {disabled}
        >{$t(payment ? 'billing_change_card' : 'billing_add_card')}</button
      >
    </div>
  {/if}
</div>

{#if showPause}
  <Modal title={$t('billing_pause_subscription')} on:close={hidePause}>
    <PauseSubscriptionOptions on:pauseSubscription={hidePause} on:backToBilling={hidePause} />
  </Modal>
{/if}

<style>
  h2 {
    margin-bottom: 0.5rem;
  }
  .annual-message {
    color: var(--text-color-lightest);
    font-size: var(--font-size-xs);
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .subscription-info :global(.coupon) {
    padding: 0.5rem 0.75rem;
  }
  .subscription-info h2 {
    margin: 0;
  }
  .actions .btn.link.apply-coupon-link {
    font-size: var(--font-size-sm);
    align-self: flex-end;
    width: auto;
  }
  .btn.remove-card {
    padding: 0.5rem 1rem;
  }
  .plan-description {
    padding: 10px 0;
  }
  .new-card {
    display: flex;
    flex-direction: column;
    min-height: 38px;
  }
  .card-form {
    display: flex;
    align-items: center;
  }
  .card-form button {
    margin: 0 4px;
  }
</style>
