<script lang="ts">
  import Route from '@dabble/toolkit/Route.svelte';
  import Auth from './Auth.svelte';
  import Preferences from './Preferences.svelte';
  import Profile from './Profile.svelte';
</script>

<Route path="/auth/:screen" component={Auth} />
<Route path="/account/preferences" component={Preferences} />
<Route path="/account/profile" component={Profile} />
