import { GlobalDataStore } from '@dabble/data/stores/global-data';
import { UserMetaTypeStore } from '@dabble/data/stores/user-meta';
import { UserProjectsStore } from '@dabble/data/stores/user-projects';
import { Preferences } from '@dabble/data/types';
import { deepEqual } from '@typewriter/json-patch/lib/utils';
import { SyncStep } from '../types';

interface TemplateData {
  userProjects: UserProjectsStore;
  globalData: GlobalDataStore;
  preferences: UserMetaTypeStore<Preferences>;
}

export default function updateGlobalTemplates(data: TemplateData): SyncStep {
  const { userProjects, globalData, preferences } = data;
  return { sync, start, name: 'updateGlobalTemplates' };

  async function sync() {
    const { templates } = globalData.get();
    if (!templates) return;
    const syncedTemplates = preferences.get().syncedTemplates || {};
    const userProjectMap = userProjects
      .get()
      .reduce((map, up) => (map[up.projectId] = true) && map, {} as { [key: string]: true });

    for (const key in templates.entrance) {
      if (syncedTemplates[key]) continue;
      const templateId = templates.entrance[key];
      if (userProjectMap[templateId]) continue;
      syncedTemplates[key] = true;
      userProjects.update(templateId, {
        projectId: templateId,
        lastOpened: Date.now(), // forces dabble templates to show as opened.
        isTemplate: true,
      });
    }
    if (!deepEqual(syncedTemplates, preferences.get().syncedTemplates)) {
      preferences.update({ syncedTemplates });
    }
  }

  function start() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }
}
