import { DatabaseStore } from '../dabble-database';
import { UserGoal } from '../types';
import { createModifiedDataStore } from './modified-data';
import { ProjectStore } from './project';
import { derived, Readable, readable } from './store';

export interface UserGoalStore extends Readable<UserGoal> {
  update: (updates: Partial<UserGoal>) => Promise<UserGoal>;
  delete: (projectId_?: string) => Promise<UserGoal>;
  isLoaded: Readable<boolean>;
}

export function createUserGoalStore(dbStore: DatabaseStore, project: ProjectStore | string): UserGoalStore {
  let projectId: string;

  const goalsStore = createModifiedDataStore<UserGoal, UserGoal>(dbStore, 'user_goals', {
    query: store => projectId && store.where().equals(projectId),
    start: () => {
      const projectId =
        typeof project === 'string' ? readable(project) : derived(project, project => project.projectId);
      return projectId.subscribe(onProjectId);
    },
    filterChanges: (goal, key) => key === (typeof project === 'string' ? project : projectId),
    packageData: list => list[0] || ({ projectId } as UserGoal),
  });

  const { get, subscribe } = goalsStore;

  async function onProjectId(id: string) {
    projectId = id;
    await goalsStore.reload();
  }

  function update(updates: Partial<UserGoal>) {
    if (!projectId) throw new Error('Project/Goal Type not loaded for updating goal.');
    if ('targetId' in updates) updates.today = null;
    return goalsStore.update(projectId, updates);
  }

  async function deleteGoal(projectId_: string = projectId) {
    return goalsStore.delete(projectId_);
  }

  return {
    update,
    delete: deleteGoal,
    get,
    isLoaded: goalsStore.isLoaded,
    subscribe,
  };
}
