<script lang="ts">
  import { isIOS } from '@dabble/data/device';
  import { duplicateProject } from '@dabble/data/project-duplicate';
  import ContextMenu from '@dabble/toolkit/ContextMenu.svelte';
  import Globals from '@dabble/toolkit/Globals.svelte';
  import InputContextMenu from '@dabble/toolkit/InputContextMenu.svelte';
  import Route from '@dabble/toolkit/Route.svelte';
  import { detectExtensions } from '@dabble/util/extensions';
  import { onMount, tick } from 'svelte';
  import {
    createProject,
    currentProjectMeta,
    desktop,
    focus,
    globalData,
    hideMain,
    isApple,
    preferences,
    projectStore,
    router,
    selectProject,
    settings,
    size,
    todaysDate,
    userProjects,
  } from '../app';
  import AppHeader from './AppHeader.svelte';
  import DesktopNav from './DesktopNav.svelte';
  import FeatureUpgrade from './FeatureUpgrade.svelte';
  import Home from './home/Home.svelte';
  import Project from './workspace/Project.svelte';

  let isHolidays = false;
  let focusing: boolean;
  router.listen();

  $: hideHolidays = $preferences.hideHolidays || localStorage.hideHolidays;
  $: font = $currentProjectMeta?.font || {};
  $: isHolidays = !hideHolidays && $todaysDate.slice(5) >= '12-01' && $todaysDate.slice(5) < '12-26';
  $: document.body.classList.toggle('isApple', isApple);
  $: document.body.classList.toggle('inDesktop', desktop.inApp); // in the Desktop app
  $: document.body.className =
    document.body.className.replace(/ (size|font)-\w+/g, '') + ` size-${$size}` + ` font-${font.family || 'general'}`;
  $: font.size
    ? document.body.setAttribute('style', `--font-size: ${font.size / 12}rem`)
    : document.body.removeAttribute('style');
  $: onFocusing($focus.focusing);

  $: genreMap = $globalData.templates?.entrance || {};

  function getGenre() {
    return sessionStorage.getItem('genre');
  }

  const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

  async function createInitialProject(genre: string): Promise<void> {
    var projects = null;
    var templateProject = null;
    var count = 0;
    while (projects === null || projects.length === 0 || count > 10) {
      count++;
      sleep(100);
      projects = userProjects.get();
    }
    templateProject = projects.find(p => p.projectId === genreMap[genre]);
    if (!templateProject) {
      return null;
    }
    const userProject = templateProject ? await duplicateProject(templateProject) : (await createProject()).userProject;
    selectProject(userProject.projectId);
    if (templateProject) await userProjects.update(templateProject.projectId, { lastOpened: new Date().getTime() });
  }

  // Fix bug in iOS for Safari
  if (isIOS && ((navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches)) {
    document.head.style.height = '100%';
  }

  function inputElement(event: InputEvent) {
    const target = event.target as Element;
    if (target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA') {
      return target;
    }
  }

  async function onFocusing(value: boolean) {
    if (value) return (focusing = true);
    await tick();
    focusing = false;
  }

  onMount(() => {
    const genre = getGenre();
    // needs to pause some time but not 5 seconds its too long
    if (genre) {
      setTimeout(() => {
        createInitialProject(genre);
        sessionStorage.removeItem('genre');
      }, 1000);
    }
    setTimeout(() => {
      detectExtensions();
    }, 5000); // give them a few seconds before springing any popups on them.
  });
</script>

<div
  class="dabble-app"
  class:focusing
  class:focus-slowly={$focus.focusing && !$focus.focusLock}
  class:holidays={isHolidays}
>
  <AppHeader />
  {#each settings.getValuesFromPlugins('headers') as header}
    <svelte:component this={header} />
  {/each}

  {#if !$hideMain}
    {#each settings.getValuesFromPlugins('routes') as routes}
      <svelte:component this={routes} />
    {/each}
    <Route path="/" component={Home} />
    <Route path="/p/:projectId/:docId?" component={Project} />
  {/if}
  <FeatureUpgrade />
  <Globals />
  <DesktopNav />
  {#each settings.getValuesFromPlugins('globals', $projectStore.project) as global}
    <svelte:component this={global} />
  {/each}
</div>

<ContextMenu target={document} targetFilter={inputElement} let:target>
  <InputContextMenu {target} />
</ContextMenu>

<style>
  :global(body) {
    background-color: var(--background);
  }
  .dabble-app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: var(--text-color-normal);
  }
</style>
