<script lang="ts">
  import { t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiVolumeHigh, mdiVolumeOff } from '@mdi/js';
  import { lastWordCount, sprint, status, useAudio } from '../sprints';
  import Timer from './Timer.svelte';
  import TimerForm from './TimerForm.svelte';

  const MIN_HEIGHT = 360;
  let section: HTMLDivElement;

  $: showSprint = !!$sprint;
  $: small =
    (showSprint && section?.parentElement && section.parentElement.offsetHeight - section.offsetTop < MIN_HEIGHT) ||
    false;

  function retry() {
    $lastWordCount = $sprint.wordCount;
    localStorage.lastWordCount = $sprint.wordCount;
    sprint.start();
  }
</script>

<div class="sidebar-section" bind:this={section}>
  <h3 class="section-header">{$t('timer')}</h3>
  {#if !showSprint}
    <TimerForm />
  {:else}
    <Timer {small} />
    {#if $status === 'finished'}
      <div class="try-again">
        <button class="btn primary" on:click={sprint.stop}>
          {$t('finished')}
        </button>
        <br />
        <div class="question">
          {$t('try_again_question')}
          <button class="btn link" on:click={retry}>
            {$t('try_again')}
          </button>
        </div>
      </div>
    {:else}
      <div class="btns">
        <button class="icon audio" on:click={() => ($useAudio = !$useAudio)}
          ><Icon path={$useAudio ? mdiVolumeHigh : mdiVolumeOff} /></button
        >
        <button class="btn link" on:click={sprint.stop}>
          {$t('cancel')}
        </button>
      </div>
    {/if}
  {/if}
</div>

<style>
  .section-header {
    margin: 2rem 0 1rem;
  }
  .btns {
    display: flex;
    justify-content: flex-end;
    gap: 0.25rem;
  }
  .audio {
    color: var(--text-color-lighterer);
  }
  .try-again {
    text-align: center;
  }
  .question {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: var(--text-color-lighterer);
  }
  .btn.link {
    white-space: wrap;
  }
</style>
