import { connectionSendAfterAuthed } from '@dabble/app';
import { GlobalDataStore, GlobalDataTypeStore, createGlobalDataTypeStore } from '@dabble/data/stores/global-data';
import { Readable, derived, writable } from '@dabble/data/stores/store';
import { UserMetaStore, UserMetaTypeStore, createUserMetaTypeStore } from '@dabble/data/stores/user-meta';
import { GlobalData, UserMeta } from '@dabble/data/types';
import {
  Coupon,
  CreateSubscriptionOptions,
  Invoice,
  PaymentSource,
  Plan,
  Product,
  Subscription,
  UpdateSubscriptionOptions,
} from './types';

let stripeInited: Promise<any>;
const RETRIAL_PERIOD = 1000 * 60 * 60 * 24 * 30 * 4; // 4 months
const DAYS_AFTER_TRIAL_FOR_DISCOUNT = 2 * 24 * 60 * 60 * 1000; // 2 days after trial allow buffer for discount
export const CANCELED_STATUSES = new Set(['incomplete_expired', 'canceled', 'unpaid']);

export interface Plans extends GlobalData {
  all: { [id: string]: Plan | Product };
  display: string[];
  subscriberDiscount: boolean;
  currentOffer: string;
  defaults: {
    plan: string;
    trial_period_days?: number;
    trial_end?: number;
  };
}

export interface Billing extends UserMeta {
  stripeId: string;
  subscription?: Subscription;
  payment?: PaymentSource;
  referral?: string;
}

export type PlansStore = GlobalDataTypeStore<Plans>;

export interface BillingStore extends UserMetaTypeStore<Billing> {
  defaultProductId?: string;
  createSubscription(options?: CreateSubscriptionOptions): Promise<Billing>;
  updateSubscription(options?: UpdateSubscriptionOptions): Promise<Billing>;
  cancelSubscription(when?: string): Promise<Billing>;
  pauseSubscription(resumeDate: number): Promise<Billing>;
  unpauseSubscription(): Promise<Billing>;
  purchaseLifetime(options: { sku: string }): Promise<Billing>;
  startNaNoWriMoTrial(): Promise<Billing>;
  // updatePaymentMethod(): Promise<Billing>;
  attachPaymentMethod(id: string): Promise<Billing>;
  deletePaymentMethod(): Promise<Billing>;
  getCoupon(code: string, plan?: string): Promise<Coupon>;
  getUpcomingInvoice(options: UpdateSubscriptionOptions): Promise<Invoice>;
  setReferral(referral: string): Promise<Billing>;
  generateReferralCode(): Promise<Billing>;
}

export interface CardStoreData {
  mounted: boolean;
  empty: boolean;
  error: string;
}

export interface CardStore extends Readable<CardStoreData> {
  mount(selector: string): void;
  focus(): void;
  save(): Promise<string>;
  destroy(): void;
}

export interface NamedUser {
  name: string;
}

export function createPlansStore(globalData: GlobalDataStore): PlansStore {
  const { get, update, subscribe } = createGlobalDataTypeStore<Plans>(globalData, 'plans');

  return {
    get,
    update,
    subscribe,
  };
}

export function createBillingStore(userMeta: UserMetaStore): BillingStore {
  const { get, update, subscribe } = createUserMetaTypeStore<Billing>(userMeta, 'billing');

  function receiveUpdates(updates: any) {
    return update(updates, { isFromRemote: true });
  }

  async function createSubscription(options: UpdateSubscriptionOptions = {}) {
    return receiveUpdates(await connectionSendAfterAuthed('createSubscription', options));
  }

  async function updateSubscription(options: UpdateSubscriptionOptions = {}) {
    return receiveUpdates(await connectionSendAfterAuthed('updateSubscription', options));
  }

  async function cancelSubscription(when?: string) {
    return receiveUpdates(await connectionSendAfterAuthed('cancelSubscription', when));
  }

  async function pauseSubscription(resumeDate: number) {
    return receiveUpdates(await connectionSendAfterAuthed('pauseSubscription', resumeDate));
  }

  async function unpauseSubscription() {
    return receiveUpdates(await connectionSendAfterAuthed('unpauseSubscription'));
  }

  async function purchaseLifetime(options: { sku: string }) {
    return receiveUpdates(await connectionSendAfterAuthed('purchaseLifetime', options));
  }

  async function startNaNoWriMoTrial() {
    return receiveUpdates(await connectionSendAfterAuthed('startNaNoWriMoTrial'));
  }

  // async function updatePaymentMethod() {
  //   const [ stripe, sessionId ] = await Promise.all([
  //     initStripe(), connectionSendAfterAuthed('createSession', window.location.href),
  //   ]);
  //   stripe.redirectToCheckout({ sessionId });
  // }

  async function attachPaymentMethod(id: string) {
    return receiveUpdates(await connectionSendAfterAuthed('attachPaymentMethod', id));
  }

  async function deletePaymentMethod() {
    return receiveUpdates(await connectionSendAfterAuthed('deletePaymentMethod'));
  }

  async function setReferral(referral: string) {
    return receiveUpdates(await connectionSendAfterAuthed('setReferral', referral));
  }

  async function generateReferralCode() {
    return receiveUpdates(await connectionSendAfterAuthed('generateReferralCode'));
  }

  async function getCoupon(code: string, plan?: string) {
    return await connectionSendAfterAuthed('getCoupon', code, plan);
  }

  async function getUpcomingInvoice(options: UpdateSubscriptionOptions) {
    return (await connectionSendAfterAuthed('getUpcomingInvoice', options)) as Invoice;
  }

  return {
    createSubscription,
    updateSubscription,
    cancelSubscription,
    pauseSubscription,
    unpauseSubscription,
    purchaseLifetime,
    startNaNoWriMoTrial,
    // updatePaymentMethod,
    attachPaymentMethod,
    deletePaymentMethod,
    getCoupon,
    getUpcomingInvoice,
    setReferral,
    generateReferralCode,
    get,
    update,
    subscribe,
  };
}

export function createCardStore(): CardStore {
  let card: stripe.elements.Element;
  let stripe: stripe.Stripe;
  let destroyed = false;
  const { get, set, update, subscribe } = writable<CardStoreData>({ mounted: false, empty: true, error: null });

  async function mount(selector: string, options?: stripe.elements.ElementsOptions) {
    stripe = await initStripe();
    if (destroyed) return;
    const elements = stripe.elements();
    card = elements.create('card', options);
    card.addEventListener('change', ({ empty, error }) => {
      update(data => ({ ...data, empty, error: (error && error.message) || null }));
    });
    await new Promise(resolve => {
      if (destroyed) {
        card.destroy();
        return;
      }
      card.addEventListener('ready', resolve);
      card.mount(selector);
      update(data => ({ ...data, mounted: true }));
    });
  }

  function focus() {
    card.focus();
  }

  async function save() {
    if (!card || !get().mounted) return;
    try {
      const clientSecret = await connectionSendAfterAuthed('createSetupIntent');
      const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: { card },
      });

      if (error) {
        update(data => ({ ...data, error: error.message }));
      } else {
        return setupIntent.payment_method;
      }
    } catch (err) {
      update(data => ({ ...data, error: err.message }));
    }
  }

  function destroy() {
    destroyed = true;
    if (card) {
      card.unmount();
      card.destroy();
    }
    set({ mounted: false, empty: true, error: null });
  }

  return {
    mount,
    focus,
    save,
    destroy,
    get,
    subscribe,
  };
}

export function createTrialAvailableStore(billing: BillingStore, plans: PlansStore, minute: Readable<Date>) {
  return derived([billing, plans, minute], ([billing, plans, minute]) => {
    if (
      !billing ||
      !plans ||
      !plans.defaults ||
      (billing.subscription && !CANCELED_STATUSES.has(billing.subscription.status))
    ) {
      return 0;
    }
    if (
      !billing.subscription ||
      billing.subscription.canceled_at * 1000 < minute.getTime() - RETRIAL_PERIOD ||
      plans.defaults.trial_end
    ) {
      return plans.defaults.trial_end ? plans.defaults.trial_end * 1000 : plans.defaults.trial_period_days;
    }
    return 0;
  });
}

export function createDiscountAvailableStore(billing: BillingStore, plans: PlansStore, minute: Readable<Date>) {
  return derived([billing, plans, minute], ([billing, plans, minute]) => {
    const { subscription } = billing;
    return (
      plans.subscriberDiscount &&
      subscription &&
      subscription.trial_end &&
      !subscription.discount &&
      minute.getTime() - subscription.trial_end * 1000 < DAYS_AFTER_TRIAL_FOR_DISCOUNT
    );
  });
}

declare global {
  interface Window {
    Stripe: any;
  }
}

async function initStripe(): Promise<stripe.Stripe> {
  if (stripeInited) return stripeInited;
  return (stripeInited = new Promise(resolve => {
    const checkout = document.createElement('script');
    checkout.src = 'https://js.stripe.com/v3/';
    checkout.onload = resolve;
    document.body.appendChild(checkout);
  }).then(() => {
    return new window.Stripe('pk_live_jz2vNh33pvMngM7J3gO0Ej6U');
  }));
}
