<script lang="ts">
  import { features, router } from '@dabble/app';
  import { t } from '@dabble/data/intl';
  import { focus } from '@dabble/toolkit/focus';
  import Modal from '@dabble/toolkit/Modal.svelte';

  const upgradeFeature = features.upgradeFeature;

  $: featureOpt = $upgradeFeature && { feature: $t(`feature_${$upgradeFeature}`) };

  function upgrade() {
    router.navigate('/checkout/plans');
    close();
  }

  function close() {
    $upgradeFeature = '';
  }
</script>

{#if $upgradeFeature}
  <Modal title={$t('feature_not_available', featureOpt)} on:close={close}>
    <section>
      <p>{$t('feature_upgrade', featureOpt)}</p>
    </section>
    <footer>
      <button class="btn secondary" on:click={close} use:focus>{$t('cancel')}</button>
      <button class="btn primary" on:click={upgrade} use:focus>{$t('feature_upgrade_now')}</button>
    </footer>
  </Modal>
{/if}
