<script lang="ts">
  import { t } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiArrowRight, mdiLock } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { currentUser, signIn } from '../accounts';

  const dispatch = createEventDispatcher();
  let form: HTMLFormElement;
  let error: string;
  let passwordInput: HTMLInputElement;
  let password: string;
  let submitted: boolean;
  let invalid: Record<string, string>;

  async function onSubmit() {
    if (!form.checkValidity()) {
      invalid = {};
      const elements = form.elements;
      for (let i = 0; i < elements.length; i++) {
        let elem = elements[i] as HTMLInputElement;
        if (elem.validationMessage) invalid[elem.id || elem.name] = elem.validationMessage;
      }
      return;
    } else {
      invalid = null;
    }

    submitted = true;
    error = null;

    try {
      await signIn($currentUser.email, password);
    } catch (err) {
      submitted = false;
      handleError(err);
      return;
    }
    password = '';
    submitted = false;
    dispatch('success');
  }

  // When there was an error, let the user know about it
  function handleError(err: Error) {
    submitted = false;
    const code = err.message;
    error = $t(code) !== code ? $t(code) : err.message;
  }

  function onInput(event: Event) {
    if (!invalid) return;
    const target = event.target as HTMLInputElement;
    const name = target.id || target.name;
    if (invalid[name]) {
      delete invalid[name];
    }
  }
</script>

<Modal title="Authenticate" size="small" on:close={() => dispatch('fail')}>
  <form bind:this={form} on:submit|preventDefault={onSubmit} on:input={onInput} novalidate>
    <section>
      {#if error}
        <Alert type="danger" dismissible on:close={() => (error = null)}>
          <strong>{$t('error')}:</strong>
          {$t(error)}
        </Alert>
      {/if}
      <p>Enter your <strong>old password</strong> to verify your identity.</p>
      <div class="form-group" class:error={invalid && invalid.password}>
        <div class="input-group">
          <label
            class="input-group-addon icon"
            for="password"
            title={$t('auth_password')}
            use:tooltipTop={$t('auth_password')}
          >
            <Icon path={mdiLock} />
          </label>
          <input
            bind:this={passwordInput}
            type="password"
            name="password"
            class="form-control"
            id="password"
            placeholder="Old password"
            required
            bind:value={password}
          />
        </div>

        {#if invalid && invalid.password}
          <div class="error-message">{invalid.password}</div>
        {/if}
      </div>
    </section>
    <footer>
      <button type="button" class="btn secondary" disabled={submitted} on:click={() => dispatch('fail')}>
        {$t('auth_cancel')}
      </button>
      <button type="submit" class="btn primary signin" disabled={submitted}>
        Verify Old Password
        <Icon path={mdiArrowRight} inline />
      </button>
    </footer>
  </form>
</Modal>
