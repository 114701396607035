<script>
  import Interactions from '@dabble/toolkit/Interactions.svelte';
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';

  export let nofocus = false;
  export let size = ''; // small, large
  let className = '';

  const dispatch = createEventDispatcher();
  let container;
  let background;

  export { className as class };

  export function close(event) {
    if (!event || event.target === container) {
      dispatch('close');
    }
  }

  function add(node, event, handler) {
    node.addEventListener(event, handler);
    return () => node.removeEventListener(event, handler);
  }

  function dispatch_tap(node, x, y) {
    node.dispatchEvent(
      new CustomEvent('tap', {
        detail: { x, y },
      })
    );
  }

  function tapThis(node) {
    function handle_pointerdown(event) {
      if (node.disabled) return;
      const { clientX, clientY } = event;

      const remove_pointerup_handler = add(node, 'pointerup', event => {
        if (event.target !== node) return;
        if (Math.abs(event.clientX - clientX) > 5) return;
        if (Math.abs(event.clientY - clientY) > 5) return;

        dispatch_tap(node, event.clientX, event.clientY);
        remove_pointerup_handler();
      });

      setTimeout(remove_pointerup_handler, 300);
    }

    const remove_pointerdown_handler = add(node, 'pointerdown', handle_pointerdown);

    return {
      destroy() {
        remove_pointerdown_handler();
      },
    };
  }

  afterUpdate(() => {
    if (container && container.parentNode !== document.body) {
      document.body.appendChild(background);
      document.body.appendChild(container);
      if (!nofocus) container.focus();
    }
  });
</script>

<Interactions on:shortcut-Escape={close} />

<div bind:this={background} class="modal-background" transition:fade|local={{ duration: 300 }} />
<div
  bind:this={container}
  class="modal-container{size ? ' ' + size : ''} {className}"
  tabindex="-1"
  role="dialog"
  use:tapThis
  on:tap={close}
>
  <slot />
</div>
