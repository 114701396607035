import { cubicOut } from 'svelte/easing';
import { TransitionConfig } from 'svelte/transition';
export { blur, crossfade, draw, fade, fly, scale } from 'svelte/transition';

type EasingFunction = (t: number) => number;

interface Params {
  duration?: number;
  delay?: number;
  easing?: EasingFunction;
  distance?: number;
  direction?: -1 | 1;
}

interface SlideParams extends Params {
  fadeStart?: number;
}

export function fadeDown(
  node: Element,
  { delay = 0, duration = 200, easing = cubicOut, distance = 25, direction = -1 }: Params
) {
  const style = getComputedStyle(node);
  const opacity = +style.opacity;

  return {
    delay,
    duration,
    easing,
    css: (t: number) => {
      return (
        'overflow: hidden;' +
        `opacity: ${t * opacity};` +
        `transform: translate(0, ${direction * (1 - t) * distance}%);`
      );
    },
  };
}

export function fadeUp(node: Element, params: Params = {}) {
  params.direction = 1;
  return fadeDown(node, params);
}

export function slide(
  node: Element,
  { fadeStart = 0.5, delay = 0, duration = 200, easing = cubicOut }: SlideParams
): TransitionConfig {
  const style = getComputedStyle(node);
  const opacity = +style.opacity;
  const height = parseFloat(style.height);
  const padding_top = parseFloat(style.paddingTop);
  const padding_bottom = parseFloat(style.paddingBottom);
  const margin_top = parseFloat(style.marginTop);
  const margin_bottom = parseFloat(style.marginBottom);
  const border_top_width = parseFloat(style.borderTopWidth);
  const border_bottom_width = parseFloat(style.borderBottomWidth);
  const fade = 1 / fadeStart;

  return {
    delay,
    duration,
    easing,
    css: t =>
      'overflow: hidden;' +
      `opacity: ${Math.min(t * fade, 1) * opacity};` +
      `height: ${t * height}px;` +
      `padding-top: ${t * padding_top}px;` +
      `padding-bottom: ${t * padding_bottom}px;` +
      `margin-top: ${t * margin_top}px;` +
      `margin-bottom: ${t * margin_bottom}px;` +
      `border-top-width: ${t * border_top_width}px;` +
      `border-bottom-width: ${t * border_bottom_width}px;`,
  };
}

export function grow(
  node: Element,
  { defaultWidth = 0, fadeStart = 0.5, delay = 0, duration = 200, easing = cubicOut }
): TransitionConfig {
  const style = getComputedStyle(node);
  const opacity = +style.opacity;

  const width = parseFloat(style.width);
  const height = parseFloat(style.height);
  const padding_left = parseFloat(style.paddingLeft);
  const padding_right = parseFloat(style.paddingRight);
  const padding_top = parseFloat(style.paddingTop);
  const padding_bottom = parseFloat(style.paddingBottom);
  const margin_left = parseFloat(style.marginLeft);
  const margin_right = parseFloat(style.marginRight);
  const margin_top = parseFloat(style.marginTop);
  const margin_bottom = parseFloat(style.marginBottom);
  const border_left_width = parseFloat(style.borderLeftWidth);
  const border_right_width = parseFloat(style.borderRightWidth);
  const border_top_width = parseFloat(style.borderTopWidth);
  const border_bottom_width = parseFloat(style.borderBottomWidth);
  const fade = 1 / fadeStart;

  return {
    delay,
    duration,
    easing,
    css: t =>
      'overflow: hidden;' +
      `opacity: ${Math.min(t * fade, 1) * opacity};` +
      `width: ${defaultWidth + t * (width - defaultWidth)}px;` +
      `height: ${t * height}px;` +
      `padding-left: ${t * padding_left}px;` +
      `padding-right: ${t * padding_right}px;` +
      `padding-top: ${t * padding_top}px;` +
      `padding-bottom: ${t * padding_bottom}px;` +
      `margin-left: ${t * margin_left}px;` +
      `margin-right: ${t * margin_right}px;` +
      `margin-top: ${t * margin_top}px;` +
      `margin-bottom: ${t * margin_bottom}px;` +
      `border-left-width: ${t * border_left_width}px;` +
      `border-right-width: ${t * border_right_width}px;` +
      `border-top-width: ${t * border_top_width}px;` +
      `border-bottom-width: ${t * border_bottom_width}px;`,
  };
}

export function slideH(
  node: HTMLElement,
  { delay = 0, duration = 200, easing = cubicOut }: SlideParams
): TransitionConfig {
  const style = getComputedStyle(node);
  const width = parseFloat(style.width);
  const paddingLeft = parseFloat(style.paddingLeft);
  const paddingRight = parseFloat(style.paddingRight);
  const marginLeft = parseFloat(style.marginLeft);
  const marginRight = parseFloat(style.marginRight);
  const borderLeftWidth = parseFloat(style.borderLeftWidth);
  const borderRightWidth = parseFloat(style.borderRightWidth);

  return {
    delay,
    duration,
    easing,
    css: (t: number) => {
      return (
        'overflow: hidden;' +
        `width: ${t * width}px;` +
        `padding-left: ${t * paddingLeft}px;` +
        `padding-right: ${t * paddingRight}px;` +
        `margin-left: ${t * marginLeft}px;` +
        `margin-right: ${t * marginRight}px;` +
        `border-left-width: ${t * borderLeftWidth}px;` +
        `border-right-width: ${t * borderRightWidth}px;`
      );
    },
  };
}
