<script>
  import { features, size } from '@dabble/app';
  import { activeCommentId, chosenCommentId, showComments } from '../comments-store';
  import CommentGutterList from './CommentGutterList.svelte';
  import { highlightComment } from './display-helpers';

  // This component is the list of comments next to the page (in the page gutter)

  export let pageElement;

  $: show = $features.has('comments') && $showComments;
  $: pageElement && highlightComment(pageElement, $activeCommentId, !!$chosenCommentId);
</script>

{#if show && $size === 'desktop'}
  <CommentGutterList {pageElement} />
{/if}
