<script>
  import Page from '@dabble/toolkit/Page.svelte';
  import FullPageImage from './FullPageImage.svelte';

  export let doc;
  export let margin = true;
</script>

<Page {margin}>
  <FullPageImage {doc} />
</Page>
