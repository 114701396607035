import { inform, t } from '@dabble/app';
import { writable } from '@dabble/data/stores/store';
import { billing } from './billing';
import { Coupon } from './types';

export const showCouponModal = writable(false);

export function hasExistingCoupon() {
  const discount = billing.get().subscription.discount;
  return discount;
}

export async function getCoupon(refCode: string) {
  let coupon;
  try {
    coupon = await billing.getCoupon(refCode);
  } catch (e) {
    switch (e.message) {
      case 'COUPON_NOT_VALID':
        sessionStorage.removeItem('referralCode');
        await inform('warning', t.get()('coupon_invalid'));
        return false;
      case 'REFERRAL_NEW_ACCOUNTS_ONLY':
        sessionStorage.removeItem('referralCode');
        await inform('warning', t.get()('coupon_new_only'));
        return false;
      default:
        console.error(e.message);
        return false;
    }
  }
  return coupon;
}

export async function applyCode(coupon: Coupon) {
  const sub = billing.get().subscription;
  if (sub.status !== 'canceled') {
    await billing.updateSubscription({ coupon: coupon.id });
    sessionStorage.removeItem('referralCode');
  }
}

export function generateReferralLink() {
  return `https://app.dabblewriter.com?referralCode=${billing.get().myReferralCode}`;
}

window.addEventListener('signin', () => {
  if (sessionStorage.referralCode || sessionStorage.coupon) showCouponModal.set(true);
});

window.addEventListener('signup', () => {
  setTimeout(() => {
    if (sessionStorage.referralCode || sessionStorage.coupon) showCouponModal.set(true);
  }, 1000);
});

window.addEventListener('authed', () => {
  setTimeout(() => {
    if (sessionStorage.referralCode || sessionStorage.coupon) showCouponModal.set(true);
  }, 1000);
});
