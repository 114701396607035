import { editingMode, features, projectStore } from '@dabble/app';
import { createId } from '@dabble/data/uuid';
import { DecorationsModule, format, h } from 'typewriter-editor';
import { chosenCommentId, showComments } from './comments-store';

export const comment = format({
  name: 'comment',
  selector: 'format-comment',
  greedy: false,
  // Toggle's a decoration on/off so that a comment can be made and saved.
  commands: editor => () => {
    const docId = editor.identifier.id;
    const doc = projectStore.getDoc(docId);
    const id = createId(4, doc.comments, docId);
    const decorator = (editor.modules.decorations as DecorationsModule).getDecorator('comments');
    decorator.clear().decorateText(editor?.doc?.selection, { class: 'comment', ['data-ids']: id });
    decorator.apply();
    showComments.set(true);
    chosenCommentId.set(id);
    editor.root.dispatchEvent(new CustomEvent('comment-created', { bubbles: true }));
  },
  fromDom(node: HTMLElement) {
    const { ids, allIds } = node.dataset;
    const unresolved = new Set(ids.split(','));
    const comments: { [id: string]: boolean } = {};
    allIds.split(',').forEach(id => {
      if (id) comments[id] = unresolved.has(id);
    });
    return comments;
  },
  shortcuts: 'Shift+F2',
  render: (attributes, children) => {
    const allIds = attributes.comment ? Object.keys(attributes.comment) : [];
    const ids = allIds.filter(id => attributes.comment[id]);
    if (!features.get().has('comments')) {
      return null;
    }

    return h(
      'format-comment',
      {
        'data-ids': ids,
        'data-all-ids': allIds,
        onclick:
          editingMode.get() && ids.length
            ? () => {
                if (editingMode.get() === 1) {
                  const id = ids[ids.length - 1];
                  chosenCommentId.set(id);
                  showComments.set(true);
                }
              }
            : null,
        ondblclick:
          !editingMode.get() && ids.length
            ? () => {
                const id = ids[ids.length - 1];
                chosenCommentId.set(id);
                showComments.set(true);
              }
            : null,
      },
      children
    );
  },
});
