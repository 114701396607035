import { format, parseISO, startOfDay, startOfMinute, startOfSecond } from 'date-fns';
import { derived, writable } from './stores/store';

export const today = writable(startOfDay(getDate()));
export const minute = writable(startOfMinute(getDate()));
export const second = writable(startOfSecond(getDate()));
export const todaysDate = derived(today, today => getDateString(today));

export function getDateString(date: Date = getDate()) {
  return date && format(date, 'yyyy-MM-dd');
}

export function parseDateString(date: string): Date {
  return date && parseISO(date);
}

export function getDate() {
  return new Date(getNow());
}

export function getNow() {
  return Date.now() + (parseInt(localStorage.timeOffset) || 0);
}

setInterval(() => {
  const todayNow = startOfDay(getDate());
  const minuteNow = startOfMinute(getDate());
  if (today.get().getTime() !== todayNow.getTime()) {
    today.set(todayNow);
  }
  if (minute.get().getTime() !== minuteNow.getTime()) {
    minute.set(minuteNow);
  }
}, 1000);
