<script>
  import { inform, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiCheckBold, mdiContentCopy, mdiFacebook, mdiTwitter } from '@mdi/js';
  import { getTagline, shareStatsModalOpen } from '../share-to-social';

  $: shareUrl = $shareStatsModalOpen;
  $: taglineText = shareUrl ? getTagline() : '';
  $: copied = false;

  function copyToClipboard() {
    if (!shareUrl) return;

    navigator.clipboard.writeText(shareUrl);

    copied = true;

    inform('success', $t('share_goals_copy_success'));

    setTimeout(() => {
      copied = false;
    }, 1000);
  }

  function shareToSocial(ev) {
    const href = ev.target.getAttribute('href');

    window.open(href, '_blank');
  }

  function close() {
    $shareStatsModalOpen = '';
  }
</script>

{#if $shareStatsModalOpen}
  <Modal title={$t('share_goals_modal_title')} size="small" on:close={close}>
    <section>
      <p>{$t('share_goals_to_social_header')}</p>
      <button
        class="btn primary inline-icon"
        href="https://twitter.com/intent/tweet?text={taglineText}&hashtags=dabblewriter&url={shareUrl}"
        on:click={shareToSocial}
      >
        <Icon path={mdiTwitter} />
        {$t('share_goals_to_twitter')}
      </button>
      <button
        class="btn primary inline-icon"
        href="https://www.facebook.com/sharer/sharer.php?u={shareUrl}&quote={taglineText}"
        on:click={shareToSocial}
      >
        <Icon path={mdiFacebook} />
        {$t('share_goals_to_facebook')}
      </button>
      <p class="link-header">{$t('share_goals_with_link_header')}</p>
      <div class="input-group">
        <input class="form-control image-url" readonly value={shareUrl} />
        <button
          class="input-adornment btn secondary"
          on:click={copyToClipboard}
          use:tooltipTop={$t('share_goals_copy_to_clipboard')}
        >
          {#if copied}
            <Icon path={mdiCheckBold} />
          {:else}
            <Icon path={mdiContentCopy} />
          {/if}
        </button>
      </div>
    </section>
    <footer>
      <button class="btn secondary" on:click={close}>
        {$t('cancel')}
      </button>
    </footer>
  </Modal>
{/if}

<style>
  button.input-adornment {
    border-radius: 0 0.25rem 0.25rem 0;
  }

  button.inline-icon {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
  }

  button.inline-icon :global(svg) {
    margin-right: 0.5rem;
  }
</style>
