import { Collective } from '@dabble/data/collective/collective';
import { ConnectionStore } from '@dabble/data/connection';
import { observe } from '@dabble/data/observe';
import { LoadedProjectsStore } from '@dabble/data/stores/loaded-projects';
import { ProjectMetasStore } from '@dabble/data/stores/project-metas';
import { Readable } from '@dabble/data/stores/store';
import { UserProjectsStore } from '@dabble/data/stores/user-projects';
import { DabbleDatabase } from '@dabble/data/types';
import logging from '@dabble/util/log';
import { SyncingStore } from '../stores/syncing';
import { ErrorReporter, SyncStep } from '../types';
import projectStep, { ProjectSyncStep } from './project';

const log = logging.tagColor('Sync', '#36B1BF');
const FIRST_BATCH_SIZE = 2;

export type ProjectSteps = { [projectId: string]: ProjectSyncStep };

export interface ProjectsSyncStep extends SyncStep {
  startSyncingProject(projectId: string): Promise<void>;
  stopSyncingProject(projectId: string): void;
}

interface ProjectsStepData {
  db: DabbleDatabase;
  collective: Collective;
  connection: ConnectionStore;
  fromRemote: Set<any>;
  syncing: SyncingStore;
  userProjects: UserProjectsStore;
  projectMetas: ProjectMetasStore;
  loadedProjects: LoadedProjectsStore;
  currentUser: Readable<any>;
}

export default function projectsStep(data: ProjectsStepData): ProjectsSyncStep {
  const { userProjects, projectMetas, loadedProjects } = data;
  const syncingProjects: ProjectSteps = {};
  let stopped = true;
  let report: ErrorReporter;

  return { sync, start, startSyncingProject, stopSyncingProject, name: 'projectsStep' };

  async function sync() {
    const projectIds = getSyncableProjects().map(p => p.projectId);
    // Sync in 2 batches allowing the older projects to be synced after the active ones
    const firstBatch = projectIds.slice(0, FIRST_BATCH_SIZE);
    const secondBatch = projectIds.slice(FIRST_BATCH_SIZE);
    log('Syncing projects', projectIds);
    await Promise.all(firstBatch.map(syncProject));
    await Promise.all(secondBatch.map(syncProject));

    // Sync trashed project meta once
    await Promise.all(
      userProjects
        .get()
        .filter(p => p.trashed)
        .map(async p => {
          loadedProjects.mark(p.projectId, true);
        })
    );
  }

  function start(errorReporter: ErrorReporter) {
    report = errorReporter;
    stopped = false;
    Object.values(syncingProjects).forEach(step => step.start(report));
    const cancelObserver = observe([userProjects, projectMetas], checkProjectsToSync);

    async function checkProjectsToSync() {
      const projects = createMap(getSyncableProjects(), 'projectId');

      // Add/remove projects needing syncing after the start
      Object.keys({ ...projects, ...syncingProjects }).forEach(async projectId => {
        if (projects[projectId]) {
          startSyncingProject(projectId);
        } else if (!projects[projectId]) {
          stopSyncingProject(projectId);
        }
      });
    }

    return () => {
      stopped = true;
      report = null;
      cancelObserver();
      Object.keys(syncingProjects).forEach(stopSyncingProject);
    };
  }

  function getSyncableProjects() {
    const metas = projectMetas.get();
    return userProjects
      .get()
      .filter(p => !p.trashed && !p.deleted && metas[p.projectId] && metas[p.projectId].committed);
  }

  async function startSyncingProject(projectId: string) {
    if (stopped || syncingProjects[projectId]) return;
    log('Syncing project', projectId);
    await syncProject(projectId);
    if (!stopped && syncingProjects[projectId]) syncingProjects[projectId].start(report);
  }

  function stopSyncingProject(projectId: string) {
    if (!syncingProjects[projectId]) return;
    syncingProjects[projectId].stop();
    delete syncingProjects[projectId];
  }

  function syncProject(projectId: string) {
    const step = (syncingProjects[projectId] = projectStep(projectId, data));
    return step.sync();
  }
}

function createMap(list: any[], prop?: string) {
  return list.reduce((map, item) => (map[prop ? item[prop] : item] = true) && map, {});
}
