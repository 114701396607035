<script>
  import { t } from '@dabble/app';
  import { availablePlans, currentOfferPlan, currentPlan, lifetimePlan, lifetimePrice, plans } from '../billing';
  import LifetimePlan from './LifetimePlan.svelte';
  import Plan from './Plan.svelte';

  export let redirect;
  let currency = 'usd';
  let disabled;

  $: currencies = $plans.available && getCurrencies($plans.all, $plans.available);
  $: currentIsLegacy = $currentPlan && $currentPlan.metadata.offer !== $plans.currentOffer;
  $: yearly = $currentPlan?.interval === 'year';
  $: currentProduct = $plans.all?.[$currentPlan?.product];
  $: recommendLegacy =
    currentIsLegacy && (currentProduct.name === 'Premium' || currentProduct?.metadata.features === 'all');

  window.dispatchEvent(new CustomEvent('currentscreen', { detail: { name: 'Plans' } }));

  function getCurrencies(lookup, productIds) {
    const products = new Set(productIds);
    const currencies = new Set();
    Object.values(lookup).forEach(item => {
      if (!item.currency || !products.has(item.product)) return;
      currencies.add(item.currency);
    });
    const currencyArray = Array.from(currencies).sort();
    if (!currencies.has(currency) && currencyArray.length) {
      currency = currencyArray[0];
    }
    return currencyArray;
  }
</script>

<div class="plan-selection">
  {#if $plans.message}
    <div class="message">
      {@html $plans.message}
    </div>
  {/if}

  <div class="interval-selector">
    <div class="interval-discount">{@html $t('checkout_pay_annually')}</div>
    <button class="btn toggle top" class:yearly on:click={() => (yearly = !yearly)}>
      <div class="highlight" />
      <div class="label" class:selected={!yearly}>{$t('checkout_interval_monthly')}</div>
      <div class="label" class:selected={yearly}>{$t('checkout_interval_annually')}</div>
    </button>
  </div>

  {#if currencies && currencies.length > 2}
    <select class="currency form-control" bind:value={currency}>
      {#each currencies as currency}
        <option value={currency}>{currency.toUpperCase()}</option>
      {/each}
    </select>
  {/if}

  <div class="plans">
    {#if $availablePlans}
      {#each $availablePlans as offerPlan}
        <Plan
          {offerPlan}
          {yearly}
          {currency}
          current={$currentPlan?.id === offerPlan.month?.id || $currentPlan?.id === offerPlan.year?.id}
          recommend={recommendLegacy ? false : null}
          {redirect}
          bind:disabled
        />
      {/each}
      {#if $lifetimePrice && $currentPlan?.amount !== 0}
        <LifetimePlan
          lifetimePrice={$lifetimePrice}
          lifetimePlan={$lifetimePlan}
          current={$currentPlan?.product === $lifetimePlan.id}
        />
      {/if}
      {#if currentIsLegacy}
        <Plan
          offerPlan={$currentOfferPlan}
          planId={$currentPlan.id}
          {yearly}
          {currency}
          current
          legacy
          recommend={recommendLegacy}
        />
      {/if}
    {:else}
      {$t('billing_data_load_error')}
    {/if}
  </div>

  <div class="other">
    <h6>{$t('billing_student')}</h6>
    <p>{@html $t('billing_student_info')}</p>
    <h6>{$t('billing_faqs')}</h6>
    <p>{@html $t('billing_faqs_info')}</p>
  </div>
</div>

<style>
  .plan-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .other {
    color: var(--text-color-light);
    text-align: center;
    margin: 16px;
    margin-bottom: 80px;
  }
  .other h6 {
    font-weight: bold;
  }
  .message {
    margin: 16px;
    font-family: var(--font-family-ui-serif);
    font-size: var(--font-size-sm);
    color: var(--text-color-normal);
  }
  .message > :global(:first-child) {
    margin-top: 0;
  }
  .message > :global(:last-child) {
    margin-bottom: 0;
  }
  .currency {
    width: 80px;
  }
  .plans {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px -138px;
    font-family: var(--font-family-ui-serif);
  }
  .interval-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
  }
  .interval-discount {
    margin-bottom: 10px;
    font-size: var(--font-size-xs);
    color: var(--text-color-lightest);
    text-transform: uppercase;
  }
  .toggle {
    position: relative;
    display: flex;
    width: 240px;
    border-radius: 20px;
    padding: 0;
    background: none;
    border: none;
    text-transform: inherit;
    font-size: inherit;
    vertical-align: baseline;
    line-height: inherit;
    text-decoration: none;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .toggle:hover {
    text-decoration: none;
  }
  .toggle .label {
    position: relative;
    width: 120px;
    padding: 6px 0;
    border-radius: 20px;
    color: var(--text-color-lighter);
    text-align: center;
    text-transform: uppercase;
    font-size: var(--font-size-xs);
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: all 150ms ease-out;
  }
  .toggle .label.selected {
    color: var(--side-nav-selected-text-color);
    text-shadow: var(--side-nav-selected-text-shadow);
  }
  .highlight {
    position: absolute;
    width: 120px;
    height: 100%;
    left: 0;
    background: var(--side-nav-selected-background);
    border-radius: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 150ms ease-out;
  }
  .yearly .highlight {
    left: 120px;
  }
  @media (max-width: 1280px) {
    .plans {
      width: 100%;
      margin: 20px auto;
    }
  }
</style>
