<script>
  import { t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle, mdiHelpCircleOutline } from '@mdi/js';
  import { checklist, items, order, showHelp } from '../checklist';

  $: current = order.filter(item => !$checklist[item])[0];

  function openHelp(item) {
    $showHelp = item;
  }
</script>

<ul class="onboarding-checklist">
  {#each order as item}
    <li
      class="onboarding-item"
      class:current={current === item}
      class:finished={$checklist[item]}
      on:click={() => openHelp(item)}
    >
      <div class="icon">
        {#if $checklist[item]}
          <Icon path={mdiCheckboxMarkedCircle} />
        {:else}
          <Icon path={mdiCheckboxBlankCircleOutline} />
        {/if}
      </div>
      <div class="item">
        {$t(items[item].text)}
      </div>
      <div class="help">
        <Icon path={mdiHelpCircleOutline} />
      </div>
    </li>
  {/each}
</ul>

<style>
  .current {
    color: var(--dabble-blue);
    font-weight: bold;
  }

  .icon {
    margin-right: 0.5rem;
    margin-top: 0.24rem;
  }
  .item {
    flex-grow: 1;
  }

  .finished {
    color: var(--gray-light);
  }

  .finished .icon {
    color: var(--brand-primary);
  }

  .onboarding-checklist {
    list-style: none;
    margin: 0;
    padding: 0.25rem 0;
  }
  .onboarding-item {
    cursor: pointer;
    display: flex;
    padding: 0.25rem 0;
  }
  .onboarding-item:hover,
  .onboarding-item:focus {
    background-color: var(--gray-lightest);
  }
</style>
