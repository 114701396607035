<script>
  import TitleImage from '@dabble/plugins/content/components/TitleImage.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Page from '@dabble/toolkit/Page.svelte';

  export let doc;
</script>

<Page {doc} class="part-view title-page">
  <EditableContent {doc} field="title" prefix header="h1" />
  <TitleImage {doc} />
</Page>

<style>
  :global(.part-view.title-page h1) {
    padding-bottom: 2em;
  }
  :global(.part-view.title-page .title-prefix) {
    top: -3em;
  }
</style>
