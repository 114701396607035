import { $t, alert, currentDoc, projectStore, settings } from '@dabble/app';
import { Doc } from '@dabble/data/types';
import { Delta, cloneDeep } from 'typewriter-editor';

const KEY = 'copy-doc';

interface TextChange {
  docId: string;
  field: string;
  delta: Delta;
}

checkForExpiry();

export function canPaste(doc: Doc) {
  if (!localStorage[KEY]) return false;
  while (doc && !doc.children) doc = projectStore.getParent(doc.id);
  if (!doc) return false;
  const docToPaste = getPaste();
  const validChildTypes = settings.getFor(doc.type)?.validChildTypes;
  return !validChildTypes || validChildTypes[docToPaste.type];
}

export function getPasteType() {
  if (!localStorage[KEY]) return;
  return getPaste()?.type;
}

export function createDuplicate(doc: Doc, emptyTitle = false) {
  if (!doc) return;

  const copiedDocObject: any = cloneDeep(doc);
  delete copiedDocObject.id;

  // recursive. Deletes old ids
  function buildDocChildren(childId: string) {
    if (childId === null) return null; // accounts for plot grid null children
    if (!childId) return;

    const childDoc = projectStore.getDoc(childId);
    const newDoc = cloneDeep(childDoc);

    if (doc.type === 'novel_plot') {
      // converts old scene column into plot points/plot lines
      if (newDoc.type === 'novel_scene' || newDoc.type === 'novel_chapter') {
        newDoc.type = 'novel_plot_point';
        newDoc.description = newDoc.body;
        delete newDoc.body;
      } else if (newDoc.type === 'novel_book') {
        newDoc.type = 'novel_plot_line';
      }
    }

    delete newDoc.id;

    // recursive to build any of the child's children
    if (newDoc.children) {
      newDoc.children = newDoc.children.map(buildDocChildren);
    }

    return newDoc;
  }

  // build doc children with objects instead of id strings
  if (copiedDocObject.children) {
    copiedDocObject.children = copiedDocObject.children.map(buildDocChildren);
  }

  if (doc.type === 'novel_book') {
    // get linked plot grid
    const gridLink = projectStore.get().links.to[`${doc.id}:plot`];
    if (gridLink) {
      const copiedGrid = gridLink[0]?.from;
      const docGrid = cloneDeep(copiedGrid);

      // build plot grid children
      docGrid.children = docGrid.children.map(buildDocChildren);

      copiedDocObject.grid = docGrid;
    }
  }

  if (doc.type === 'novel_plot') {
    copiedDocObject.grid = copiedDocObject.grid.map((gridColumn: string[]) => gridColumn.map(buildDocChildren));

    if (copiedDocObject.grid.length !== copiedDocObject.children.length) {
      // make old scenes column into regular plot line

      copiedDocObject.grid.shift();

      if (!doc.title) {
        // set plot grid title if it doesn't have one
        copiedDocObject.title = settings.getPlaceholder(doc) + ' copy';
      }
    }
  }
  if (emptyTitle && copiedDocObject?.title) copiedDocObject.title = null;

  return copiedDocObject;
}

export function copyDocForDuplication(doc: Doc) {
  const copiedDocObject = createDuplicate(doc);
  copy(copiedDocObject);
}

export async function pasteDocFromDuplication(doc: Doc, dropIndex?: number) {
  const docToPaste = getPaste();
  if (!docToPaste || typeof docToPaste !== 'object' || !docToPaste.type) return;

  while (doc && !doc.children) doc = projectStore.getParent(doc.id);
  if (!doc) return;
  const { type } = doc;
  const copiedType = docToPaste.type;
  const docSettings = settings.get()[type];

  const compatibleTypes = docSettings.validChildTypes;

  if (compatibleTypes && !compatibleTypes[copiedType]) {
    alert(
      $t('paste_error_incompatible_title'),
      $t('paste_error_incompatible_body', { type: copiedType, parentType: type })
    );
    return;
  }

  await duplicateDoc(doc, docToPaste, dropIndex);
}

function buildPlotGrid(children: any[], max = 0, oldGrid?: any[]) {
  if (!children?.length) return;

  let rowNum = max;

  children.forEach((child: any) => {
    if (child.children.length > rowNum) rowNum = child.children.length;
  });

  if (oldGrid) {
    oldGrid.forEach((gridCol: any[]) => {
      if (gridCol.length > rowNum) rowNum = gridCol.length;
    });
  }
  const grid = children.map((column: any, i) => {
    const cellArr: any[] = [];
    const oldGridCol = oldGrid[i];

    cellArr.push(column.id);

    let currChild = 0;
    for (let j = 1; j < oldGridCol.length; j++) {
      const oldGridCell = oldGridCol[j];

      if (oldGridCell === null) {
        cellArr.push(null);
      } else {
        const childCell = column.children[currChild];
        if (childCell) {
          cellArr.push(childCell.id);
        } else {
          cellArr.push(null);
        }
        currChild++;
      }
    }

    while (cellArr.length < rowNum) {
      cellArr.push(null);
    }

    return cellArr;
  });

  return grid;
}

function buildLinkedPlotGrid(docId: string, scenes: string[], children: any[], oldGrid?: any[]) {
  if (!children?.length) return;

  const rowNum = scenes.length + 1; // +1 to account for docId

  const mainCol = [docId];

  let currChild = 0;
  for (let i = 1; i < oldGrid[0].length; i++) {
    const oldGridCell = oldGrid[0][i];

    if (oldGridCell === null) {
      mainCol.push(null);
    } else {
      mainCol.push(scenes[currChild]);
      currChild++;
    }
  }

  const oldGridCloned = [...oldGrid];
  oldGridCloned.shift();

  // build grid with scenes column
  const grid = [mainCol, ...buildPlotGrid(children, rowNum, oldGridCloned)];

  return grid;
}

async function duplicateDoc(parentDoc: Doc, copiedDoc: any, dropIndex?: number) {
  const index =
    dropIndex || dropIndex === 0
      ? dropIndex
      : (currentDoc.get() && parentDoc.children.indexOf(currentDoc.get().id) + 1) || undefined;
  const patch = projectStore.patch();
  const parentDocId = parentDoc.id;
  const parentDocType = parentDoc.type;
  const doc = cloneDeep(copiedDoc);
  const newDocId = projectStore.createDocId();
  doc.id = newDocId;

  const textChanges: TextChange[] = [];
  const bookScenes: string[] = [];

  // create new ids for children, compile text changes for later ops,
  // compile book scenes for novel copying
  const modifyChild = (child: any) => {
    if (child === null) return null;
    if (!child) return;
    if (child.id) delete child.id;
    child.id = projectStore.createDocId();

    if (doc.type === 'novel_book' && child.type === 'novel_scene') {
      bookScenes.push(child.id);
    }

    if (child.body) {
      textChanges.push({ docId: child.id, field: 'body', delta: child.body as any as Delta });
      delete child.body;
    }
    if (child.description) {
      textChanges.push({ docId: child.id, field: 'description', delta: child.description as any as Delta });
      delete child.description;
    }
    if (child.children) {
      child.children = child.children.map(modifyChild);
    }

    return child;
  };

  // iterate through children to find text changes, assign new ids
  if (doc.children) {
    doc.children = doc.children.map(modifyChild);
  }

  // build a linked doc grid for duplicated books
  let linkedGrid: Doc;
  if (doc.type === 'novel_book' && doc.grid && parentDocType === 'novel_manuscript') {
    const newGridChildren = doc.grid.children.map(modifyChild);

    linkedGrid = {
      id: projectStore.createDocId(),
      type: 'novel_plot',
      children: newGridChildren,
    };
  }

  let newGrid: any[];
  if (doc.type === 'novel_plot') {
    newGrid = buildPlotGrid(doc.children, 0, doc.grid);
  }

  if (linkedGrid) {
    newGrid = buildLinkedPlotGrid(doc.id, bookScenes, linkedGrid.children, doc.grid.grid);
  }

  // delete doc grid from doc so we can update the doc manually\
  if (doc.grid) {
    delete doc.grid;
  }

  // create new doc
  patch.createDoc(doc, parentDocId, index, true);

  // link new grid to new book
  if (linkedGrid) {
    patch.createDoc(linkedGrid, 'plots');
    patch.linkDocs(linkedGrid.id, 'plot', doc.id);
    const lines: Doc[] = patch.patch.ops
      .filter(op => op.op === 'add' && op.value.type === 'novel_plot_line')
      .map(a => a.value);

    // compare plot lines to plot grid structure,
    // link plot points to their scenes
    lines.forEach((line: any, lineIndex: number) => {
      if (!line.children.length) return;
      const gridCol: any[] = newGrid[lineIndex + 1];

      let currChild = 0;

      for (let j = 1; j < gridCol.length; j++) {
        const gridCell = gridCol[j];

        if (gridCell !== null && bookScenes[j - 1]) {
          patch.linkDocs(line.children[currChild], 'plot', bookScenes[j - 1]);
          currChild++;
        }
      }
    });
    patch.updateDoc(linkedGrid.id, { grid: newGrid });
  }

  // manually add grid to plot grid
  if (doc.type === 'novel_plot') {
    patch.updateDoc(doc.id, { grid: newGrid });
  }

  // queue text changes to populate text
  textChanges.forEach(({ docId, field, delta }: TextChange) => {
    patch.changeText(docId, field, delta as Delta);
  });

  // save patch
  await patch.save();
}

const EXPIRES = 1800000; // 30 minutes
let timeout: any;

function copy(doc: Doc): void {
  localStorage[KEY] = JSON.stringify({
    expires: Date.now() + EXPIRES,
    doc,
  });
  clearTimeout(timeout);
  timeout = setTimeout(checkForExpiry, EXPIRES);
}

function getPaste(): Doc | undefined {
  try {
    const result = JSON.parse(localStorage[KEY]);
    if (result.expires < Date.now()) {
      delete localStorage[KEY];
    } else {
      copy(result.doc); // Update the expire date
      return result.doc;
    }
    // eslint-disable-next-line no-empty
  } catch (err) {}
}

function checkForExpiry() {
  try {
    const result = JSON.parse(localStorage[KEY]);
    if (result.expires < Date.now()) {
      delete localStorage[KEY];
    }
    // eslint-disable-next-line no-empty
  } catch (err) {}
}
