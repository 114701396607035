<div class="pyro" />

<style>
  /* Fireworks */
  .pyro {
    position: relative;
    z-index: 1;
  }
  .pyro::before,
  .pyro::after {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
    animation: 1s fireworks-bang ease-out infinite backwards, 1s fireworks-gravity ease-in infinite backwards,
      5s fireworks-position linear infinite backwards;
  }
  .pyro::after {
    animation-delay: 1.25s, 1.25s, 1.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
  }

  @keyframes fireworks-bang {
    to {
      box-shadow: -65px 22.33333px #00ffe1, -105px 19.33333px #d000ff, -16px -271.66667px #d500ff,
        199px -22.66667px #5900ff, -208px -58.66667px #0088ff, 127px -75.66667px #d500ff, 8px -63.66667px #ff00c8,
        204px -366.66667px #ff000d, -233px -278.66667px #ff2b00, -220px -238.66667px #8cff00, 187px -283.66667px #37ff00,
        233px -387.66667px #00ffaa, 62px -265.66667px #bfff00, -60px -119.66667px #ff0062, 93px -39.66667px #00ff77,
        -59px -391.66667px #5500ff, -104px -329.66667px #00aaff, -158px -131.66667px #00b3ff, 18px -403.66667px #ff5500,
        122px -376.66667px #ff0051, -16px -226.66667px #ff0073, 238px -221.66667px #00ff09, 168px 1.33333px #ff3c00,
        158px -101.66667px #ff0011, -5px -412.66667px #002bff, -79px -233.66667px #fffb00, -111px 77.33333px #ff4000,
        -98px 72.33333px #00ff2b, 193px -252.66667px #aeff00, -91px -252.66667px #ff5900, -235px -392.66667px #0073ff,
        -180px 57.33333px #ff008c, 187px -346.66667px #e100ff, 7px -366.66667px cyan, -166px -286.66667px #00ffb3,
        -222px -95.66667px #3300ff, 222px -167.66667px #ff4000, 4px -116.66667px #7bff00, -29px -336.66667px #ff9900,
        87px 68.33333px #0062ff, 35px -135.66667px #00fffb, -117px 43.33333px #15ff00, -34px -139.66667px deepskyblue,
        -99px -45.66667px #00ddff, -215px -179.66667px #ff0044, 212px -67.66667px #e1ff00, -188px -1.66667px #0d00ff,
        54px 30.33333px #ff3700, -48px 60.33333px #c400ff, 215px 61.33333px #6fff00, 22px -163.66667px #bb00ff;
    }
  }
  @keyframes fireworks-gravity {
    to {
      transform: translateY(200px);
      opacity: 0;
    }
  }
  @keyframes fireworks-position {
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
</style>
