<script>
  import { lastUrl, router } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Route from '@dabble/toolkit/Route.svelte';
  import { mdiClose } from '@mdi/js';
  import Confirm from './Confirm.svelte';
  import Plans from './Plans.svelte';

  const redirect = $lastUrl && !$lastUrl.startsWith('/checkout') ? $lastUrl : '/account/billing';
</script>

<div class="checkout-screen">
  <div class="checkout">
    <div class="logo" />
    <Route path="/checkout/plans" component={Plans} {redirect} />
    <Route path="/checkout/confirm" component={Confirm} {redirect} />
    <button class="icon close-btn" on:click={() => router.navigate(redirect)}>
      <Icon path={mdiClose} />
    </button>
  </div>
</div>

<style>
  .checkout-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--white);
    overflow-y: auto;
  }
  .checkout {
    position: relative;
    max-width: 1004px;
    width: 100%;
  }
  .icon.close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 30px;
  }
  .logo {
    background: url(~images/Dabble-Color-Logotype-Tagline-Padded.png) no-repeat center;
    background-size: contain;
    width: 220px;
    height: 112px;
    overflow: hidden;
    text-indent: -400px;
    margin: 16px auto;
  }
</style>
