<script lang="ts">
  import { alert, desktop, router, t } from '@dabble/app';
  import { ACCOUNT_URL } from '@dabble/data/global-constants';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiAccount, mdiEmail, mdiLock } from '@mdi/js';
  import { afterUpdate, onMount } from 'svelte';
  import { requestPasswordReset, signIn, signUp } from '../accounts';

  export let screen = 'signin';
  let name = '';
  let email = '';
  let password = '';
  let submitted = !!router.getQuery().get('token');
  let error: string = null;
  let invalid: Record<string, string> = null;
  let form: HTMLFormElement;
  let passwordInput;
  let lastScreen = '';

  onMount(() => {
    if (!desktop.inApp) {
      window.location.href = `${ACCOUNT_URL}auth/${screen === 'signin' ? 'login' : 'signup'}`;
    }
  });

  function onSubmit(event: SubmitEvent) {
    if (!form.checkValidity()) {
      invalid = {};
      const elements = form.elements;
      for (let i = 0; i < elements.length; i++) {
        let elem = elements[i] as HTMLInputElement;
        if (elem.validationMessage) invalid[elem.id || elem.name] = elem.validationMessage;
      }
      return;
    } else {
      invalid = null;
    }

    submitted = true;
    error = null;

    if (screen === 'signin') {
      signIn(email, password).catch(handleError);
    } else if (screen === 'signup') {
      signUp(name, email, password).catch(handleError);
    } else if (screen === 'password') {
      requestPasswordReset(email).then(handlePassword, handleError);
    }
  }

  // When the password has been successfully reset, alert the user
  function handlePassword() {
    submitted = false;
    router.navigate('/auth/signin');
    alert($t('alert_header_email_sent'), $t('alert_email_sent', { email: email }));
  }

  // When there was an error, let the user know about it
  function handleError(err: Error) {
    submitted = false;
    const code = err.message;
    error = $t(code) !== code ? $t(code) : err.message;
  }

  // Update input focus when the screen changes
  afterUpdate(() => {
    if (form && lastScreen !== screen) {
      lastScreen = screen;
      const inputs = form.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i++) {
        if (!inputs[i].value) {
          inputs[i].focus();
          break;
        }
      }
    }
  });

  // Clear invalid state as soon as an input is changed
  function onInput(event: Event) {
    if (!invalid) return;
    const target = event.target as HTMLInputElement;
    const name = target.id || target.name;
    if (invalid[name]) {
      delete invalid[name];
    }
  }
</script>

<svelte:head>
  <title>
    Dabble -
    {screen === 'signin' ? $t('auth_signin') : screen === 'signup' ? $t('auth_signup') : $t('auth_reauth')}
  </title>
</svelte:head>

<div class="signin-screen">
  <div class="signin-area">
    <div class="logo">Dabble</div>

    <div class="signin-form">
      <header>
        {#if screen === 'signin'}
          <h4>{$t('auth_signin')}</h4>
        {:else if screen === 'signup'}
          <h4>{$t('auth_signup')}</h4>
        {:else}
          <h4>{$t('auth_reauth')}</h4>
        {/if}
      </header>
      {#if sessionStorage.referralCode}
        <p class="referral">{$t('have_referral_code')}</p>
      {/if}
      <form bind:this={form} on:submit|preventDefault={onSubmit} on:input={onInput} novalidate>
        <section>
          {#if error}
            <Alert type="danger" dismissible on:close={() => (error = null)}>
              <strong>{$t('error')}:</strong>
              {$t(error)}
            </Alert>
          {/if}

          {#if screen === 'signup'}
            <div class="form-group" class:error={invalid && invalid.name}>
              <div class="input-group">
                <label
                  class="input-group-addon icon icon-account"
                  for="name"
                  title={$t('auth_name')}
                  use:tooltipTop={$t('auth_name')}><Icon path={mdiAccount} /></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder={$t('auth_name')}
                  required
                  bind:value={name}
                  autocomplete="off"
                />
              </div>

              {#if invalid && invalid.name}
                <div class="error-message">{invalid.name}</div>
              {/if}
            </div>
          {/if}

          <div class="form-group" class:error={invalid && invalid.email}>
            <div class="input-group">
              <label
                class="input-group-addon icon icon-email"
                for="email"
                title={$t('auth_email')}
                use:tooltipTop={$t('auth_email')}><Icon path={mdiEmail} /></label
              >
              <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                placeholder={$t('auth_email')}
                required
                bind:value={email}
                autocomplete="off"
              />
            </div>

            {#if invalid && invalid.email}
              <div class="error-message">{invalid.email}</div>
            {/if}
          </div>

          {#if screen !== 'password'}
            <div class="form-group" class:error={invalid && invalid.password}>
              <div class="input-group">
                <label
                  class="input-group-addon icon icon-lock"
                  for="password"
                  title={$t('auth_password')}
                  use:tooltipTop={$t('auth_password')}><Icon path={mdiLock} /></label
                >
                <input
                  bind:this={passwordInput}
                  type="password"
                  name="password"
                  class="form-control"
                  id="password"
                  placeholder={$t('auth_password')}
                  required
                  bind:value={password}
                />
              </div>

              {#if invalid && invalid.password}
                <div class="error-message">{invalid.password}</div>
              {/if}
            </div>
          {/if}

          {#if screen === 'signin'}
            <div class="form-group forgot-password">
              <a href="#/auth/password">{$t('auth_forgot_password')}</a>
            </div>
          {/if}
        </section>

        {#if screen === 'password'}
          <footer>
            <button
              type="button"
              class="btn secondary"
              disabled={submitted}
              on:click={() => router.navigate('/auth/signin')}
            >
              {$t('auth_cancel')}
            </button>
            <button type="submit" class="btn primary" disabled={submitted}>
              {$t('auth_send_reset')}
            </button>
          </footer>
        {:else}
          <footer>
            <button type="submit" class="btn primary signin" disabled={submitted}>
              {screen === 'signin' ? $t('account_login') : $t('account_signup')}
              <Icon name="arrow-right" inline />
            </button>

            <div class="accept-terms">
              {@html $t('auth_terms')}
            </div>
          </footer>
        {/if}
      </form>
    </div>

    {#if screen === 'signin' || screen === 'signup'}
      <div class="signin-switcher">
        {#if screen === 'signin'}
          <span>{$t('auth_not_registered')}</span> <a href="#/auth/signup">{$t('auth_create_account')}</a>
        {:else}
          <span>{$t('auth_already_registered')}</span> <a href="#/auth/signin">{$t('auth_signin_here')}</a>
        {/if}
      </div>
    {/if}
  </div>
</div>

<style>
  .logo {
    background: url(~images/Dabble-Color-Logotype-Tagline-Padded.png) no-repeat center;
    background-size: contain;
    width: 384px;
    height: 100px;
    overflow: hidden;
    text-indent: -400px;
    margin: 0 auto 20px;
  }
  a {
    text-decoration: underline;
  }
  .signin-screen {
    overflow: auto;
  }
  .signin-area {
    position: relative;
    max-width: 400px;
    margin: 32px auto;
  }
  .signin-form {
    padding: 10px;
    background-color: var(--white);
    box-shadow: var(--flat-box-shadow);
    line-height: normal;
  }
  .signin-switcher {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
  }
  .signin-switcher span {
    margin-right: 8px;
  }

  @media only screen and (max-width: 416px) {
    .signin-area {
      width: auto;
      margin: 32px 8px;
    }
  }

  header,
  section,
  footer {
    padding: 16px;
  }
  section {
    padding-bottom: 0;
  }
  footer {
    padding-top: 0;
  }
  header {
    border-bottom: 4px solid #f5a503;
  }
  header h4 {
    color: #333;
    margin: 0;
  }
  .referral {
    text-align: center;
    font-size: 0.875rem;
    padding-top: 0.5rem;
  }
  .accept-terms:last-child {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    background-color: rgba(0, 0, 0, 0.03);
    color: #464a4c;
    font-size: 0.875rem;
    margin: 1rem -25px -25px;
    padding: 15px;
  }
  .btn.signin {
    width: 100%;
    line-height: 30px;
  }
</style>
