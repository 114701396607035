<script>
  import { inform, isOnline, projectStore, t } from '@dabble/app';
  import rest from '@dabble/data/rest';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { mdiDelete } from '@mdi/js';
  import { emailShared } from '../html';

  let emails;
  let message = '';
  let newEmail = '';
  let emailInput;
  let error = null;
  let disconnected = null;
  let submitted = false;

  $: emails = [];

  function close() {
    $emailShared = '';
  }

  function addEmail() {
    emailInput.checkValidity();
    if (!emailInput.checkValidity()) {
      error = emailInput.validationMessage;
      return;
    }

    error = null;
    emails = [...emails, newEmail];
    newEmail = '';
  }

  function remove(email) {
    const index = emails.indexOf(email);
    emails.splice(index, 1);
    emails = [...emails];
  }

  async function sendEmail() {
    if (!$isOnline) {
      disconnected = $t('DISCONNECTED');
      return;
    }

    if (emails.length === 0) {
      error = $t('shared_no_email');
      return;
    }

    submitted = true;
    try {
      await rest.POST('share/email', { projectId: $projectStore.projectId, emails, message, filename: $emailShared });
      submitted = false;
      emails = [];
      message = '';
      error = null;
      inform('success', $t('shared_email_sent'));
      close();
    } catch (err) {
      error = err.message;
      submitted = false;
    }
  }
</script>

{#if $emailShared}
  <Modal title={$t('shared_email_header')} on:close={close}>
    <section class="">
      <div class="">
        <div>{@html $t('shared_email_text')}</div>
        <div class="email-list">
          {#if emails.length === 0}
            <div>{$t('shared_no_email')}</div>
          {/if}
          {#each emails as email}
            <div class="email-item">
              {email}
              <div class="remove" on:click={() => remove(email)} on:keypress={() => remove(email)}>
                <Icon path={mdiDelete} inline />
              </div>
            </div>
          {/each}
        </div>
      </div>
      <div class="input-group">
        <label class="input-group-addon" for="add-email">{$t('shared_add_email')}</label>
        <input bind:this={emailInput} id="add-email" class="form-control" type="email" bind:value={newEmail} required/>
        <div class="input-group-append input-group-btn">
          <button class="btn primary" on:click|preventDefault={addEmail}>{$t('shared_add')}</button>
        </div>
      </div>
      {#if error}
        <div class="error">
          {error}
        </div>
      {/if}
      <div class="form-group">
        <label for="email-message">{$t('shared_email_message')}</label>
        <textarea area id="email-message" class="form-control" bind:value={message} />
      </div>
    </section>
    <footer>
      <button class="btn primary" on:click={sendEmail} disabled={emails.length === 0}>
        {$t('shared_send_email')}
      </button>
    </footer>
  </Modal>
{/if}

<style>
  .form-group {
    margin-top: 10px;
  }
  .email-list {
    padding: 10px;
    border: 1px solid var(--gray-light);
    margin: 10px auto;
  }
  .email-item {
    display: flex;
  }
  .email-item .remove {
    cursor: pointer;
  }
  .email-item .remove:hover {
    color: var(--dabble-red);
  }
</style>
