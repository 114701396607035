<script lang="ts">
  import { connected, inform, settings, t } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import BlankScreen from '@dabble/toolkit/BlankScreen.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiAccount, mdiCardAccountDetails, mdiEmail, mdiLock } from '@mdi/js';
  import { currentUser, updateUser } from '../accounts';
  import VerifyPassword from './VerifyPassword.svelte';

  export const showOffline = true;
  let error: string;
  let invalid: Record<string, string>;
  let submitted: boolean;
  let name: string;
  let email: string;
  let password = '';
  let form: HTMLFormElement;
  let verifyPasswordResolver: (value: unknown) => void;

  let nameRe = [
    {
      re: new RegExp(
        '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
      ),
      text: 'no_url',
      not: true,
    },
    {
      re: new RegExp('^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'),
      text: 'no_url',
      not: true,
    },
    { re: /[a-zA-Z]{3,}/, text: 'min_3' },
  ];
  let emailRe = [{ re: /^[A-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Z0-9.-]+$/i, text: 'invalid_email' }];
  let passwordRe = [
    { re: /[_!#$%&'*+/=?`{|}~^.-]{1}/, text: '1_symbol' },
    { re: /[a-z]{1}/, text: '1_lowercase' },
    { re: /[A-Z]{1}/, text: '1_uppercase' },
    { re: /[0-9]{1}/, text: '1_digit' },
    { re: /^(.){8,32}$/, text: '8_32_characters' },
  ];

  $: userLoaded = !!$currentUser;
  $: if (userLoaded) populateValues(); // Use boolean so it only happens once

  $: one_uppercase = password.match(passwordRe[2].re);
  $: one_lowercase = password.match(passwordRe[1].re);
  $: one_digit = password.match(passwordRe[3].re);
  $: one_symbol = password.match(passwordRe[0].re);
  $: characters = password.match(passwordRe[4].re);

  function populateValues() {
    name = ($currentUser && $currentUser.name) || '';
    email = ($currentUser && $currentUser.email) || '';
  }

  window.dispatchEvent(new CustomEvent('currentscreen', { detail: { name: 'Profile' } }));

  async function onSubmit() {
    if (!validation()) return;
    if (!form.checkValidity()) {
      invalid = {};
      const elements = form.elements;
      for (let i = 0; i < elements.length; i++) {
        let elem = elements[i] as HTMLInputElement;
        if (elem.validationMessage) invalid[elem.id || elem.name] = elem.validationMessage;
      }
      return;
    } else {
      invalid = null;
    }

    submitted = true;
    error = null;
    const isProfile = $currentUser.name !== name || $currentUser.email !== email;
    const isPassword = !!password;

    try {
      await updateUser({ name, email, password });
      inform('success', isPassword && !isProfile ? $t('profile_password_updated') : $t('profile_updated'));
    } catch (err) {
      const code = err.message;
      if (code === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN') {
        if (await verifyPassword()) {
          onSubmit();
          return;
        }
      }
      error = $t(code) !== code ? $t(code) : err.message;
    }

    password = '';
    submitted = false;
  }

  function onInput(event: Event) {
    if (!invalid) return;
    const target = event.target as HTMLInputElement;
    const name = target.id || target.name;
    if (invalid[name]) {
      delete invalid[name];
    }
  }

  async function verifyPassword() {
    const value = await new Promise(result => {
      verifyPasswordResolver = result;
    });
    verifyPasswordResolver = null;
    return value;
  }

  function validation() {
    invalid = {};

    if (!validateField(nameRe, 'name', name)) return false;
    if (!validateField(emailRe, 'email', email)) return false;
    if (password !== '') if (!validateField(passwordRe, 'password', password)) return false;

    invalid = null;
    return true;
  }

  function validateField(rule: any, name: string, value: any) {
    for (let i in rule) {
      let test = rule[i];
      if (test.not) {
        if (test.re.test(value)) {
          invalid[name] = $t(test.text);
          return false;
        }
      } else {
        if (!test.re.test(value)) {
          invalid[name] = $t(test.text);
          return false;
        }
      }
    }
    return true;
  }
</script>

<BlankScreen>
  <svelte:fragment slot="title">
    <Icon path={mdiCardAccountDetails} inline />
    {$t('account_screen_profile')}
  </svelte:fragment>

  <div class="section">
    <h2>{$t('profile_update')}</h2>
    <p class="description">{$t('profile_update_description')}</p>

    <form bind:this={form} on:submit|preventDefault={onSubmit} on:input={onInput} novalidate autocomplete="off">
      <section>
        {#if error}
          <Alert type="danger" dismissible>
            <strong>{$t('error')}</strong>
            {error}
          </Alert>
        {/if}

        <div class="form-group" class:error={invalid && invalid.name}>
          <div class="input-group">
            <label class="input-group-addon icon" for="name" title={$t('auth_name')} use:tooltipTop={$t('auth_name')}>
              <Icon path={mdiAccount} />
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder={$t('auth_name')}
              bind:value={name}
              autocomplete="off"
            />
          </div>

          {#if invalid && invalid.name}
            <div class="error-message">{invalid.name}</div>
          {/if}
        </div>

        <div class="form-group" class:error={invalid && invalid.email}>
          <div class="input-group">
            <label
              class="input-group-addon icon"
              for="update-email"
              title={$t('auth_email')}
              use:tooltipTop={$t('auth_email')}
            >
              <Icon path={mdiEmail} />
            </label>
            <input
              type="email"
              readonly
              on:focus={function () {
                this.readOnly = false;
              }}
              class="form-control"
              id="update-email"
              name="update-email"
              placeholder={$t('auth_email')}
              bind:value={email}
              autocomplete="off"
            />
          </div>

          {#if invalid && invalid.email}
            <div class="error-message">{invalid.email}</div>
          {/if}
        </div>

        <input type="password" name="password" class="form-control" id="password" />
        <div class="form-group" class:error={invalid && invalid.password}>
          <div class="input-group">
            <label
              class="input-group-addon icon"
              for="update-password"
              title={$t('auth_password')}
              use:tooltipTop={$t('auth_password')}
            >
              <Icon path={mdiLock} />
            </label>
            <input
              type="password"
              readonly
              on:focus={function () {
                this.readOnly = false;
              }}
              name="update-password"
              class="form-control"
              id="update-password"
              placeholder={$t('auth_new_password')}
              bind:value={password}
            />
          </div>

          <div class="password-checks">
            <div class="password-check">
              <div class="indicator" class:one_uppercase />
              <div>{$t('rule_upper')}</div>
            </div>
            <div class="password-check">
              <div class="indicator" class:one_lowercase />
              <div>{$t('rule_lower')}</div>
            </div>
            <div class="password-check">
              <div class="indicator" class:one_digit />
              <div>{$t('rule_digit')}</div>
            </div>
            <div class="password-check">
              <div class="indicator" class:one_symbol />
              <div>{$t('rule_symbol')}</div>
            </div>
            <div class="password-check">
              <div class="indicator" class:characters />
              <div>{$t('rule_characters')}</div>
            </div>
          </div>

          {#if invalid && invalid.password}
            <div class="error-message">{invalid.password}</div>
          {/if}
        </div>
      </section>

      <footer>
        <button type="submit" class="btn primary login pull-right" disabled={submitted || !$connected}> Update </button>
      </footer>
    </form>
  </div>
  {#each settings.getValuesFromPlugins('profileOptions') as options}
    <svelte:component this={options} />
  {/each}
</BlankScreen>

{#if verifyPasswordResolver}
  <VerifyPassword on:success={() => verifyPasswordResolver(true)} on:fail={() => verifyPasswordResolver(false)} />
{/if}

<style>
  #password {
    display: none;
  }
  footer {
    overflow: hidden;
  }

  .password-checks {
    font-size: 0.8rem;
    padding: 10px;
  }
  .password-check {
    display: flex;
    align-items: center;
  }
  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background: var(--text-color-lightest);
    margin-right: 5px;
  }
  .one_symbol,
  .one_digit,
  .one_lowercase,
  .one_uppercase,
  .characters {
    background: var(--brand-success);
  }
</style>
