<script>
  import {
    createProject,
    getDateString,
    getTitle,
    globalData,
    plugins,
    projectGoalStore,
    projectMetas,
    projectStore,
    selectProject,
    shownModal,
    t,
    today,
    userProjects,
    waitFor,
  } from '@dabble/app';
  import Fireworks from '@dabble/toolkit/Fireworks.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { addDays, lastDayOfMonth } from 'date-fns';
  import dabbleUInverse from '../images/dabble-u-inverse.svg';
  import dabbleURgular from '../images/dabble-u.svg';
  import { challengeStore, isCamp, monthAfter, monthOf, nanowrimo } from '../nanowrimo';

  let selectedProject = '';
  const { uiTheme } = plugins.stores;

  $: plans = $plugins.plans;
  $: subscription = $plugins.subscription;
  $: trialDays = (plans && plans.defaults && plans.defaults.trial_period_days) || 0;
  $: extending = trialDays && addDays($today, trialDays) < $monthAfter;

  $: dabbleU = $uiTheme === 'dark' ? dabbleUInverse : dabbleURgular;

  $: canHaveTrial = !$isCamp && extending && status !== 'active';
  $: status = getSubscriptionStatus(subscription);

  $: projects = $userProjects.filter(p => !p.deleted && !p.trashed && !p.isTemplate);
  $: nanoUserProject = projects.find(p => p.projectId === $nanowrimo.projectId);

  function closeModal() {
    $shownModal = null;
  }

  async function updateChallenge(key, value) {
    if (key === 'projectId' && value === 'create-novel') {
      const { userProject } = await createProject({ type: 'novel' });
      value = userProject.projectId;
      selectedProject = value;
    }
    challengeStore.update({ [key]: value });
    if ($globalData.nanowrimoData.enabled) {
      nanowrimo.update({ [key]: value });
    }
  }

  async function gotoGoals() {
    closeModal();
    if (!selectedProject) return;

    if (selectedProject !== $projectStore.projectId) {
      await selectProject(selectedProject);
    }
    // Wait until the project is loaded
    const { project } = await waitFor(projectStore, data => data.project?.id === selectedProject);
    // Wait till the project goal store is loaded
    await waitFor(projectGoalStore.isLoaded, isLoaded => isLoaded);

    const deadline = getDateString(lastDayOfMonth($monthOf));
    const targetId = $projectStore.docs[project.children[0]].children[0]; // First book
    const end = $projectGoalStore.end || 50000;
    await projectGoalStore.update({
      ...$projectGoalStore,
      end,
      targetId,
      deadline,
    });

    $shownModal = 'goalSettings';
  }

  function getSubscriptionStatus(subscription) {
    if (!subscription) return 'canceled';
    if (subscription.status === 'trialing') return 'trialing';
    return 'active';
  }
</script>

{#if $shownModal === 'challengeJoined' || $shownModal === 'challengeSettings'}
  <Modal
    title={$t($shownModal === 'challengeJoined' ? 'challenge_congrats_title' : 'challenge_settings')}
    size="large"
    on:close={closeModal}
  >
    {#if $shownModal === 'challengeJoined'}
      <Fireworks />
      <p class="lead">{@html $t('challenge_congrats')}</p>
    {/if}
    <section class="centered">
      <div>
        <img src={dabbleU} alt="Dabble U" />
        <ul>
          {@html $t('dabbleu')}
          {#if canHaveTrial}
            {@html $t('free_trial')}
          {/if}
        </ul>
      </div>
    </section>
    <div class="section-header">{$t('choose_project')}</div>
    <div class="form-group">
      <select
        class="form-control"
        on:change={event => updateChallenge('projectId', event.target.value)}
        bind:value={selectedProject}
      >
        <option value="">(None)</option>
        {#each projects as userProject}
          <option value={userProject.projectId}>{getTitle($projectMetas[userProject.projectId]) || '???'}</option>
        {/each}
        <option value="create-novel">{$t('create_new_novel')}</option>
      </select>
      <div class="section-note">{$t('challenge_select_message')}</div>
    </div>
    <footer>
      <button class="btn primary" on:click={gotoGoals}>
        {$t('setup_goals')}
      </button>
    </footer>
  </Modal>
{/if}

<style>
  .centered {
    display: flex;
    justify-content: center;
  }
</style>
