import { currentProjectMeta, locale, observe, projectStore } from '@dabble/app';
import { TextChange, TextDocument } from 'typewriter-editor';
import BeyondGrammarApi from './api/beyond-grammar-api';
import { GrammarLineFormat, Language } from './grammar-types';
import { getIssueRange } from './grammar-updates';

export const beyondGrammar = new BeyondGrammarApi();

observe([currentProjectMeta, locale], ([meta, locale]) => {
  beyondGrammar.setLanguage(meta?.spellingLanguage || locale || 'en-US');
});

// Cache for languages lookup
let languages: Language[];

export async function getLanguages(): Promise<Language[]> {
  if (languages) return languages;
  return (languages = (await beyondGrammar.getLanguages()).filter((lng: Language) => lng.isEnabled));
}

export async function addToDictionary(word: string) {
  await beyondGrammar.addToDictionary(word);

  const patch = projectStore.patch();

  // Once added, go through the entire project and remove all grammar issues with that word
  Object.values(projectStore.get().docs).forEach(doc => {
    Object.entries(doc).forEach(([field, textDoc]) => {
      if (textDoc instanceof TextDocument) {
        // Check the text document for the word and remove the grammar issue
        const textChange = new TextChange(textDoc);
        textDoc.lines.forEach(line => {
          const issues = (line.attributes.grammar as GrammarLineFormat)?.issues;
          if (issues) {
            for (const [issueId, issue] of Object.entries(issues)) {
              if (issue && issue.category === 'spelling' && issue.word === word) {
                const range = getIssueRange(textDoc, line, issueId);
                textChange.formatText(range, { grammar: null });
              }
            }
          }
        });

        if (textChange.delta.ops.length) {
          patch.changeText(doc.id, field, textChange.delta);
        }
      }
    });
  });

  if (patch.patch.ops.length) {
    await patch.save();
  }
}
