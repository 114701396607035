import { authed, tabStore } from '@dabble/app';
import { StatefulPromise, statefulPromise } from '../collective/stateful-promise';
import { DatabaseStore } from '../dabble-database';
import { getNow } from '../date';
import { observe } from '../observe';
import { EventMetadata, UserAttributes } from '../types';

const NEW_SESSION_TIME = 1000 * 60 * 30; // 30 minutes

export function createAnalytics(db: DatabaseStore) {
  async function record(name: string, metadata?: EventMetadata, timestamp?: number) {
    const dbase = db.get();
    if (authed) {
      await tabStore.get().call('record', name, metadata, timestamp);
    } else if (dbase?.isOpen() && dbase.stores.analytics) {
      await dbase.stores.analytics.put({ name, metadata, timestamp: getNow() });
    }
  }

  async function recordUserData(attributes: UserAttributes) {
    if (authed) {
      await tabStore.get().call('recordUserData', attributes);
    } else if (db.get()) {
      await storeUserData(attributes);
    }
  }

  observe([authed, db], async ([authed, db]) => {
    if (authed && db?.isOpen()) {
      const lastActive = parseInt(localStorage.lastActive || '0');
      if (Date.now() - lastActive > NEW_SESSION_TIME) {
        // Whenever the page is loaded (after 30 minutes since last using Dabble), record a session
        record('session');
      }

      const events = await db.stores.analytics.getAll();
      events.forEach(async event => {
        if (event.name === 'user') {
          await recordUserData(event.metadata);
        } else {
          await record(event.name, event.metadata, event.timestamp);
        }
        await db.stores.analytics.delete(event.id);
      });
    }
  });

  // If multiple calls come in at once, only allow one to update the database at a time so that they don't overwrite
  // each other
  let nextAttributes: UserAttributes;
  let storingPromise: StatefulPromise<void>;
  let nextPromise: StatefulPromise<void>;
  async function storeUserData(attributes: UserAttributes) {
    if (storingPromise) {
      nextAttributes = { ...nextAttributes, ...attributes };
      if (!nextPromise) nextPromise = statefulPromise();
      return nextPromise;
    }

    storingPromise = statefulPromise();
    const store = db.get().stores.analytics;
    let userEvent = await store.get(0);
    if (!userEvent) {
      userEvent = { id: 0, name: 'user', metadata: attributes, timestamp: getNow() };
    } else {
      userEvent.metadata = { ...userEvent.metadata, ...attributes };
    }
    await store.put(userEvent);
    const promise = storingPromise;
    storingPromise = undefined;
    promise.resolve();
    if (nextAttributes) {
      const attributes = nextAttributes;
      const promise = nextPromise;
      nextAttributes = undefined;
      nextPromise = undefined;
      storeUserData(attributes).then(promise.resolve);
    }
  }

  return {
    record,
    recordUserData,
  };
}
