<script>
  import { projectMetas, projectStore } from '@dabble/app';
  import Page from '@dabble/toolkit/Page.svelte';
  import SceneContents from './SceneContents.svelte';

  export let doc;

  let meta;
  let font;
  let className;

  $: meta = $projectMetas[$projectStore.projectId];
  $: font = (meta && meta.font) || {};
  $: className =
    'scene-view fill fill-page' +
    ` paragraph-spacing-${font.sceneSpacing || 'double'}` +
    ` paragraph-style-${font.sceneStyle || 'book'}`;
</script>

<Page {doc} titleless>
  <SceneContents {doc} {className} />
</Page>
