<script>
  import { features, plugins, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiHistory } from '@mdi/js';
  import { showHistory } from '../versioning';

  let admin;

  $: admin = $plugins.masterUser && ($plugins.masterUser.admin || $plugins.masterUser.claims?.admin);
</script>

{#if $features.has('versioning') || admin}
  <button class="icon" use:tooltipTop={$t('history_toggle')} on:click={() => ($showHistory = !$showHistory)}>
    <Icon path={mdiHistory} />
  </button>
{/if}

<style>
  button.icon {
    order: -5;
  }
</style>
