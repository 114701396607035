<script lang="ts">
  import { currentDoc, projectStore, settings, sidebar } from '@dabble/app';
</script>

<div class="side-nav">
  {#each settings.getValuesFromPlugins(`sidebar.${$sidebar}`, $projectStore.project, $currentDoc) as sidebar}
    <svelte:component this={sidebar} />
  {/each}
</div>

<style>
  .side-nav {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    position: relative;
    box-sizing: border-box;
    background: var(--side-nav-background);
    -webkit-user-select: none;
    user-select: none;
    contain: strict;
  }
  :global(body) > .side-nav {
    height: 100vh;
  }
  .side-nav :global(.sidebar-section) {
    position: relative;
    padding: 10px;
  }
  .side-nav :global(.sidebar-section .sidebar-section) {
    position: relative;
    padding: 10px 0;
  }
  .side-nav :global(.section-header) {
    font-size: var(--font-size-lg);
    font-weight: bold;
    line-height: 28px;
    color: var(--text-color-light);
    margin: 0;
  }
  .side-nav :global(.section-header.toggle) {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .side-nav :global(.section-header.toggle > .icon) {
    font-size: 20px;
    transition: transform 0.15s;
  }
  .side-nav :global(.section-header.toggle.closed > .icon) {
    transform: rotate(-90deg);
  }
  .side-nav :global(.shadowed) {
    position: relative;
    z-index: 1;
    box-shadow: var(--top-down-shadow);
  }
</style>
