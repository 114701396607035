<script>
  import { settings } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiImageInsetCenter } from '@dabble/toolkit/custom-icons';
  import BubbleMenu from 'typewriter-editor/lib/BubbleMenu.svelte';

  export let editor;
  export let doc;
  let imageSettings = settings.getFor(doc.type)['imageStyles'];
  const styleDefs = settings.getFor('dabble')['imageStyleDefinitions'];

  if (!imageSettings) {
    imageSettings = Object.keys(styleDefs);
  }
</script>

{#if imageSettings && imageSettings.length > 0}
  <BubbleMenu {editor} let:active let:placement offset={8} for={'image'}>
    <div class="bubble-menu">
      <div data-arrow class="arrow {placement}" />
      <button
        class="bubble-menu-button header3"
        class:active={!active.style}
        on:click={() => editor.formatLine({ ...editor.doc.getLineFormat(), style: null })}
      >
        <Icon path={mdiImageInsetCenter} />
      </button>
      {#each imageSettings as iSetting}
        {#if styleDefs[iSetting]?.shouldDisplay(active)}
          <button
            class="bubble-menu-button"
            class:active={active.style === iSetting}
            on:click={() => editor.formatLine({ ...editor.doc.getLineFormat(), style: iSetting })}
          >
            <Icon path={styleDefs[iSetting].icon} />
          </button>
        {/if}
      {/each}
    </div>
  </BubbleMenu>
{/if}

<style>
  :global(.bubble-menu.active) {
    z-index: 100;
    transition: all 75ms ease-out;
  }
  .bubble-menu {
    background-image: linear-gradient(to bottom, rgba(49, 49, 47, 0.99), #262625);
    border-radius: 0.25rem;
    white-space: nowrap;
    animation: pop-upwards 180ms forwards linear;
  }
  .items {
    display: flex;
    align-items: center;
  }
  .arrow {
    display: block;
    border: 7px solid transparent;
  }
  .arrow.top {
    bottom: -13px;
    border-top-color: #262625;
  }
  .arrow.bottom {
    top: -13px;
    border-bottom-color: rgba(49, 49, 47, 0.99);
  }
  button {
    position: relative;
    height: 42px;
    padding: 9px;
    border: none;
    margin: 0;
    color: #fff;
    background: none;
    outline: none;
    cursor: pointer;
  }
  button:first-child {
    padding-left: 14px;
  }
  button:last-child {
    padding-right: 14px;
  }
  button.active {
    color: #74b6ff;
  }
  .menu :global(button[disabled]) {
    opacity: 0.5;
    cursor: default;
  }
  .bubble-menu :global(.icon) {
    font-size: 24px;
  }

  .separator {
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    margin: 0 6px;
    height: 24px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 1px 0 rgba(255, 255, 255, 0.2);
  }
  @keyframes pop-upwards {
    0% {
      transform: matrix(0.97, 0, 0, 1, 0, 12);
      opacity: 0;
    }
    20% {
      transform: matrix(0.99, 0, 0, 1, 0, 2);
      opacity: 0.7;
    }
    40% {
      transform: matrix(1, 0, 0, 1, 0, -1);
      opacity: 1;
    }
    70% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
</style>
