export const styles = {
  async manuscript() {
    const { initStyles } = await import(/* webpackChunkName: "novelExports" */ './manuscript');
    return { initStyles };
  },
  async dabble() {
    const { initStyles } = await import(/* webpackChunkName: "novelExports" */ './dabble');
    return { initStyles };
  },
};
