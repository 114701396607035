import { getDateString } from '@dabble/app';
import { DaysOff } from '@dabble/data/types';
import { getDay } from 'date-fns';

export function isWeekdayOff(daysOff: DaysOff = {}, weekday: number) {
  return !!daysOff[weekday];
}

export function isDayOff(daysOff: DaysOff = {}, date: Date) {
  const weekdayOff = daysOff[getDay(date)];
  const dateOff = daysOff[getDateString(date)];
  return !!((weekdayOff && dateOff !== false) || dateOff);
}

export function toggleWeekday(daysOff: DaysOff = {}, weekday: number) {
  daysOff = { ...daysOff };
  if (daysOff[weekday]) delete daysOff[weekday];
  else daysOff[weekday] = true;
  return daysOff;
}

export function toggleDay(daysOff: DaysOff = {}, date: Date) {
  const key = getDateString(date);
  const weekdayOff = daysOff[getDay(date)];
  const dateOff = daysOff[key];
  daysOff = { ...daysOff };
  if (weekdayOff) {
    if (dateOff === false) delete daysOff[key];
    else daysOff[key] = false;
  } else {
    if (dateOff) delete daysOff[key];
    else daysOff[key] = true;
  }
  return daysOff;
}
