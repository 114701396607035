<script>
  import { userMeta } from '@dabble/app';
  import rest from '@dabble/data/rest';
  import { onMount } from 'svelte';

  export let connected = false;

  let client;

  $: accessToken = $userMeta?.external?.google?.accessToken;
  $: connected = accessToken ? true : false;

  function authorize() {
    client.requestCode();
  }

  async function revoke() {
    google.accounts.oauth2.revoke(accessToken, async data => {
      await rest.DELETE('google/auth');
    });

    const meta = $userMeta.external;
    meta.google = undefined;
    meta.modified = Date.now();
    userMeta.update('external', { ...meta }, { isFromRemote: true });
    connected = false;
  }

  function getRedirectUrl() {
    const url = new URL(location.href);
    const validUrl =
      url.hostname.endsWith('dabblewriter.com') ||
      url.hostname.startsWith('localhost') ||
      url.hostname.endsWith('dabble-writer.pages.dev');
    if (!validUrl) throw new Error('Not a valid origin');
    return `${url.origin}`;
  }

  onMount(() => {
    client = google.accounts.oauth2.initCodeClient({
      client_id: '890907808375-h9lo7bcj9805mrhjrcom6p7f1lgri4hs.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/drive.file',
      ux_mode: 'popup',
      access_type: 'offline',
      callback: connectGoogle,
      redirect_uri: getRedirectUrl(),
    });
    google.accounts.id.prompt(); // also display the One Tap dialog
  });

  async function connectGoogle(response) {
    const res = await rest.POST('google/auth', {
      code: response.code,
    });
    const meta = $userMeta.external;
    meta.google = res;
    meta.modified = Date.now();
    userMeta.update('external', { ...meta }, { isFromRemote: true });
    connected = true;
  }
</script>

{#if connected}
  <button class="btn" on:click={revoke}>Disconnect Google</button>
{:else}
  <button class="btn primary" on:click={authorize}>Connect Google</button>
{/if}
