<script>
  import { features } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiNote } from '@mdi/js';

  // Show stickies whenever highlights are also available
  export let commands;
  export let section;
  // leave these in even though they aren't used to keep warnings from screen.
  export let editor;
  export let active;
  export let placement;
  export let selection;
</script>

{#if $features.has('stickies') && commands.highlight}
  <button class="editor-menu-sticky" on:click={() => (section = 'sticky')}>
    <Icon path={mdiNote} />
  </button>
{/if}

<style>
  .editor-menu-sticky {
    order: 82;
  }
</style>
