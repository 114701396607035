import { Readable, writable } from '@dabble/data/stores/store';

export type SyncState = 'error' | 'down' | 'up' | 'syncing' | null;

interface States {
  [state: string]: number;
}

export interface SyncingStore extends Readable<SyncState> {
  set(value: SyncState): void;
  error: (value: Error) => void;
  down: (value: boolean) => void;
  up: (value: boolean) => void;
  syncing: (value: boolean) => void;
  clear: () => void;
  getError(): Error;
}

export function createSyncingStore(): SyncingStore {
  let state: SyncState = null;
  let lastError: Error;
  const { get, set, subscribe } = writable<SyncState>(state);
  // These are in order of priority of display (e.g. if there are any errors, the state should be error)
  const states: States = {
    down: 0,
    up: 0,
    syncing: 0,
  };

  function createStateMethod(addedState: SyncState) {
    return (value: boolean) => {
      if (!(addedState in states)) throw new Error('Incorrect sync state');
      if (value) states[addedState]++;
      else states[addedState]--;

      const newState = (Object.keys(states).find((key: SyncState) => states[key]) || null) as SyncState;
      if (newState !== state) set((state = newState));
    };
  }

  function error(error: Error) {
    if (error.message === 'CONNECTION_CLOSED' || error.message === 'DISCONNECTED') {
      console.log(error);
      return; // Don't sweat this, normal things, life goes on
    }
    lastError = error;
    console.error(error);
    set('error');
  }

  function getError() {
    return lastError;
  }

  function clear() {
    Object.keys(states).forEach((key: SyncState) => (states[key] = 0));
    state && set((state = null));
  }

  return {
    error,
    down: createStateMethod('down'),
    up: createStateMethod('up'),
    syncing: createStateMethod('syncing'),
    clear,
    getError,
    get,
    set,
    subscribe,
  };
}
