import { isProduction } from '@dabble/version';

let websocket_url = 'wss://api.dabblewriter.com/';
let rest_url = 'https://rest.dabblewriter.com/';
let account_url = 'https://accounts.dabblewriter.com/';

if (location.host === 'test.dabblewriter.com') {
  rest_url = 'https://rest.test.dabblewriter.com/';
  account_url = 'https://accounts.test.dabblewriter.com/';
}

if (import.meta.env.VITE_LOCAL && !isProduction) {
  websocket_url = 'wss://api.dabblewriter.com/';
  rest_url = 'http://localhost:35379/';
  account_url = 'http://localhost:5174/';
}
if (!import.meta.env.VITE_LOCAL && !isProduction) {
  if (import.meta.env.VITE_REST) rest_url = 'http://localhost:35379/';
  if (import.meta.env.VITE_WS) websocket_url = 'ws://localhost:6060/';
  if (import.meta.env.VITE_ACCOUNT) account_url = 'http://localhost:5174/';
}

export const WS_URL = websocket_url;
export const REST_URL = rest_url;
export const ACCOUNT_URL = account_url;
