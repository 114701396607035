<script>
  import { locale } from '@dabble/app';
  import { zeroDecimalCurrencies } from './util';

  export let currency;
  export let amount;
  export let interval;
  let className;
  export { className as class };

  function formatPlanPrice() {
    const monthly = interval === 'month';

    let total = amount;
    let minimumFractionDigits = 0;

    if (!monthly && interval !== 'lifetime') total /= 12;
    if (!zeroDecimalCurrencies.has(currency)) {
      total /= 100;
      if (total % 1 !== 0) {
        minimumFractionDigits = 2;
      }
    }

    const formatter = new Intl.NumberFormat($locale, { style: 'currency', currency, minimumFractionDigits });
    if (formatter.formatToParts) {
      const parts = formatter.formatToParts(total);
      return parts
        .map(p =>
          p.type === 'currency' ? `<span class="currency">${p.value}</span>` : `<span class="number">${p.value}</span>`
        )
        .join('');
    } else {
      return `<span class="number">${formatter.format(total)}</span>`;
    }
  }
</script>

<div class="amount{className ? ` ${className}` : ''}">{@html formatPlanPrice(currency, amount, interval)}</div>

<style>
  .amount {
    position: relative;
    font-family: var(--font-family-ui-serif);
  }
  .amount :global(.currency) {
    font-size: 2em;
  }
  .amount :global(.number) {
    font-size: 3em;
  }
</style>
