<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { createEventDispatcher, onMount } from 'svelte';
  import { ExportOptions } from '../types';

  export let options: ExportOptions;

  const dispatch = createEventDispatcher();

  let selected = 'all';

  let count = 1;

  function update() {
    switch (selected) {
      case 'pages':
      case 'chapters':
        options.counts = { [`export_${selected}`]: count };
        break;
      case 'all':
      default:
        delete options.counts;
        break;
    }

    dispatch('update', options);
  }
  onMount(() => {
    dispatch('update', options);
  });
</script>

<div class="row">
  <div class="cell form-group">
    <label for="export-what">{$t(`export_what`)}</label>
    <select id="export-what" class="form-control" bind:value={selected} on:change={update}>
      <option value="all">{$t('export_options_limit_none')}</option>
      <option value="chapters">{$t('export_options_limit_chapters')}</option>
      <option value="pages">{$t('export_options_limit_pages')}</option>
    </select>
  </div>
  <div class="cell">
    {#if selected !== 'all'}
      <div class="form-group">
        <label for="export-count">{$t(`export_count`)}</label>
        <input id="export-count" class="form-control" type="number" min="1" bind:value={count} on:change={update} />
      </div>
      {#if selected === 'pages'}
        <div class="pages-note">{$t('export_pages_info')}</div>
      {/if}
    {/if}
  </div>
</div>

<style>
  .row {
    display: flex;
    width: 100%;
  }
  .row .cell {
    width: 50%;
  }
</style>
