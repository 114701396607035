import { settings } from '@dabble/app';

const sectionConfig = {
  noDeleteInNavigation: true,
  undraggable: true,
  uneditable: true,
  hideWordCount: true,
  hasChildren: true,
  openByDefault: true,
  unstyledPlaceholders: {
    title: true,
  },
};

settings.configure('novel_manuscript', sectionConfig);
settings.configure('novel_plots', sectionConfig);
settings.configure('novel_characters', sectionConfig);
settings.configure('novel_notes', sectionConfig);

settings.configure('novel_manuscript', {
  // icon: mdiBookMultiple,
  placeholders: {
    title: 'novel_manuscript',
  },
  hideRightNav: true,
  validChildTypes: {
    novel_book: true,
  },
});

settings.configure('novel_plots', {
  // hideInNavigation: true, // Removing temporarily from the navigation
  // icon: mdiRoutes,
  placeholders: {
    title: 'novel_plots',
  },
  hideRightNav: true,
  validChildTypes: {
    novel_plot: true,
    novel_plot_line: true,
  },
  requiresFeature: 'plotting',
});

settings.configure('novel_characters', {
  // hideInNavigation: true, // Removing temporarily from the navigation
  // icon: mdiAccountGroup,
  placeholders: {
    title: 'novel_characters',
  },
  validChildTypes: {
    novel_character: true,
    novel_cast: true,
  },
});

settings.configure('novel_notes', {
  // icon: mdiNotebook,
  hideWordCount: false,
  placeholders: {
    title: 'novel_notes',
  },
  menuTypes: ['novel_folder', 'novel_page', 'note_image'],
  requiresFeature: 'story_notes',
});
