/**
 * Tiny. Compiled code for signals results in 15 lines of JS and < 500 bytes of uncompressed code.
 *
 * Usage:
 *
 * const clickedSave = createSignal<Doc>();
 *
 * clickedSave(doc => {
 *   // do something to save the doc, or when the doc is saved
 * })
 *
 * clickedSave(doc);
 */
export interface SignalListener<T> {
  (event: T): any;
}
export interface SignalUnsubscriber {
  (): void;
}
export interface SignalSubscriber<T> {
  (listener: SignalListener<T>): SignalUnsubscriber;
}
export interface SignalDispatcher<T> {
  (event: T): Promise<void>;
}
export type Signal<T = void> = SignalSubscriber<T> & SignalDispatcher<T>;
export type SignalReturn = SignalUnsubscriber & void;

export function signal<T = void>(): Signal<T> {
  const subscribers = new Set<SignalListener<T>>();

  const signal = (eventOrListener: any): any => {
    if (typeof eventOrListener === 'function') {
      subscribers.add(eventOrListener);
      return () => {
        subscribers.delete(eventOrListener);
      };
    } else {
      return Promise.all(Array.from(subscribers).map(listener => listener(eventOrListener))).then<void>(() => void 0);
    }
  };

  return signal;
}
