<script lang="ts">
  import { t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiLoading } from '@mdi/js';
  import { decodeBlurHash } from 'fast-blurhash';
  import { createEventDispatcher } from 'svelte';
  import { UnsplashImage } from './UnsplashSearchDialog.svelte';

  export let image: UnsplashImage;
  export let selected: UnsplashImage | undefined;

  const dispatch = createEventDispatcher();
  const imageWidth = 400;
  const bestWidth = 200;
  let failed = false;

  $: grayscale = selected;
  $: imageHeight = (imageWidth * image.height) / image.width;
  $: if (image) failed = false;
  $: background = image && getBackground(image);

  function getBackground(image: UnsplashImage) {
    try {
      const pixels = decodeBlurHash(image.blur_hash, 32, 32);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const imageData = ctx.createImageData(32, 32);
      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);
      return canvas.toDataURL('image/png');
    } catch (err) {
      return null;
    }
  }

  function select() {
    dispatch('select', image);
  }
</script>

{#if !failed && image}
  <figure
    class="unsplash-image"
    style:flex-grow={(imageWidth * 100) / imageHeight}
    style:flex-basis="{(imageWidth * bestWidth) / imageHeight}px"
    style:aspect-ratio={imageWidth / imageHeight}
    on:click={select}
    on:keydown={select}
  >
    <!-- TODO remove this when asepct-ratio is supported by most live browsers -->
    <div style:padding-bottom="{(imageHeight / imageWidth) * 100}%" />
    <img
      src={image.urls.small}
      alt={image.alt_description}
      style:background-image="url({background})"
      class:grayscale
      on:error={() => (failed = true)}
    />
    {#if selected}
      <div class="overlay" class:thisSelected={selected === image} class:hasSelection={selected}>
        {#if selected === image}
          <Icon path={mdiLoading} spin />
        {/if}
      </div>
    {/if}
    <figcaption on:click|stopPropagation on:keydown|stopPropagation>
      {#if image.user}
        {$t('photo_by')}
        <a
          href={`${image.user.links.html}?utm_source=dabble-writer&utm_medium=referral`}
          target="_blank"
          rel="noreferrer">{image.user.name}</a
        >
      {/if}
    </figcaption>
  </figure>
{/if}

<style>
  figure {
    position: relative;
    padding: 2px;
    margin: 0;
    background: var(--white);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  figure:hover {
    z-index: 1;
    transform: scale(1.02);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  img {
    position: absolute;
    inset: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    vertical-align: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  figcaption {
    position: absolute;
    inset: auto 0 0 auto;
    padding: 2px 4px;
    background: var(--white);
    font-size: 0.8rem;
    border-top-left-radius: 0.375rem;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  figure:hover figcaption {
    opacity: 1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .thisSelected {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 72pt;
  }
  .hasSelection {
    cursor: not-allowed;
  }
  .grayscale {
    filter: grayscale(100%);
  }
</style>
