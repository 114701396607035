<script>
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiFormatColorHighlight, mdiFormatStrikethrough } from '@mdi/js';

  export let section;
  export let commands;
  export let active;
  // leave these in even though they aren't used to keep warnings from screen.
  export let editor;
  export let placement;
  export let selection;
</script>

{#if commands.del}
  <button class="editor-menu-del" class:active={active.del} on:click={() => commands.del()}>
    <Icon path={mdiFormatStrikethrough} />
  </button>
{/if}
{#if commands.highlight}
  <button class="editor-menu-highlight" class:active={active.highlight} on:click={() => (section = 'highlight')}>
    <Icon path={mdiFormatColorHighlight} />
  </button>
{/if}

<style>
  .editor-menu-del {
    order: 70;
  }
  .editor-menu-highlight {
    order: 80;
  }
</style>
