import { settings } from '@dabble/app';
import { Doc } from '@dabble/data/types';
import EmailSharedLink from './components/EmailSharedLink.svelte';
import ExportMenu from './components/ExportMenu.svelte';
import ExportModal from './components/ExportModal.svelte';
import AuthorInfoSettings from './components/ExportSettings.svelte';
import ExportingStatus from './components/ExportingStatus.svelte';
import ShareToWebModal from './components/ShareToWebModal.svelte';
import ShareToWebSettings from './components/ShareToWebSettings.svelte';
import { exportedDoc } from './exports';
import './locales';

settings.configure('dabble', {
  globals: {
    ExportModal,
    ShareToWebModal,
    EmailSharedLink,
  },
  menuItems: {
    export: ExportMenu,
  },
  statusBarLeft: { ExportingStatus },
  projectSettings: { ShareToWebSettings, AuthorInfoSettings },
  plugins: {},
});

function openExportDialog(doc: Doc) {
  exportedDoc.set(doc);
}

function canExport(doc: Doc) {
  const available = settings.getFor(doc.type)?.exportFileTypes;
  return available && available.length > 0;
}
