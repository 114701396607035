type Cache = Set<any>;
let cache: Cache;

export function makeChanges<T>(producer: () => T) {
  cache = new Set();
  const result = producer();
  cache = null;
  return result;
}

export function getImmutable(obj: any, key: string, creator: () => any = emptyObject) {
  let value = obj[key];
  if (value && cache && cache.has(value)) return value;
  if (value) value = { ...value };
  else value = creator();
  obj[key] = value;
  if (cache) cache.add(value);
  return value;
}

export function getImmutableValue(value: any) {
  if (cache && cache.has(value)) return value;
  value = { ...value };
  if (cache) cache.add(value);
  return value;
}

function emptyObject() {
  return {};
}
