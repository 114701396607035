import { Delta } from 'typewriter-editor';
import { hasChanges } from './collective/changes';
import JSONPatch from './collective/json-patch';
import { StatefulPromise, statefulPromise } from './collective/stateful-promise';
import { SettingsStore } from './stores/settings';
import { Doc, Project, ProjectSettings } from './types';
import { createDocId, createProjectId, idLengths } from './uuid';

const TEXT_FIELDS = { body: true, description: true };
type PatchSave = (patch: ProjectPatch, ...args: any[]) => StatefulPromise<Project>;
const noopSave: PatchSave = () => statefulPromise<Project>().resolve(null);

/**
 * Creates a new patch for a project and provides APIs to populate the patch.
 */
export default class ProjectPatch {
  project: Project;
  patch: JSONPatch;
  settings: SettingsStore;
  onSave: PatchSave;

  constructor(project: Project, settings: SettingsStore, onSave: PatchSave = noopSave) {
    if (!project) throw new TypeError('A Project is required to create a project patch');
    this.project = project;
    this.settings = settings;
    this.patch = new JSONPatch();
    this.onSave = onSave;
  }

  /**
   * Create a new project patch which will create a project.
   */
  static createProject(newProject: Partial<Project>, settings: SettingsStore, onSave: PatchSave = noopSave) {
    const id = createProjectId();
    const defaultType = settings.getFor('dabble').defaultProjectType;

    const project: Project = {
      ...getEmptyProject(),
      type: defaultType,
      ...newProject,
      id,
      created: Date.now(),
    };

    const projectPatch = new ProjectPatch(project, settings, onSave);
    projectPatch.patch.add('', project);

    createStructure(projectPatch, project);
    const { finalizeStructure } = settings.getFor(project) as ProjectSettings;
    if (finalizeStructure) finalizeStructure(project);

    return projectPatch;
  }

  /**
   * Calls the onSave property passing a reference to this patch.
   */
  save(...args: any[]): StatefulPromise<Project> {
    return this.onSave(this, ...args);
  }

  /**
   * Determines whether the patch is empty or has pending operations.
   */
  isEmpty() {
    return this.patch.ops.length === 0;
  }

  /**
   * Updates the loaded project, returning a promise when the changes are saved. This will update the project and
   * user project because the metadata duplicates a portion of project data for caching.
   */
  updateProject(updates: any) {
    if (hasChanges(this.project, updates)) this.patch.addUpdates(updates);
    return this;
  }

  /**
   * Creates a new doc within the loaded project, within the parent folder, at the given index.
   */
  createDoc(newDoc: Partial<Doc>, parentId: string, index?: number, structure = true) {
    if (!parentId || typeof parentId !== 'string') throw new TypeError('createDoc argument parentId must be a string');

    const parent = this.getDoc(parentId);
    if (!parent) throw new TypeError(`createDoc argument parent "${parentId}" does not exist`);
    if (!parent.children) throw new Error(`Parent Doc "${parentId}" does not have children[] for adding.`);
    const id = newDoc && newDoc.id && !this.project.docs[newDoc.id] ? newDoc.id : createDocId(this.project.docs);
    if (index == null || isNaN(index)) index = parent.children.length;

    if (this.project.docs[id]) throw new TypeError('createDoc argument id already exists in project');

    const doc: Doc = { ...getEmptyDoc(), ...newDoc, id };

    ensureChildren(this.settings, doc);
    if (structure) createStructure(this, doc, this.project.docs);
    this.patch.add(`/docs/${doc.id}`, doc);
    this.patch.add(parentPath(this.project, parentId, index), doc.id);
    constituteDeltas(this, doc);

    window.dispatchEvent(new CustomEvent('create-doc', { detail: { doc: newDoc } }));
    return this;
  }

  /**
   * Updates a doc with the given updates. The doc must belong to the loaded project.
   */
  updateDoc(docId: string, updates: any) {
    if (this.project.id === docId) return this.updateProject(updates);
    const doc = this.getDoc(docId);
    if (!doc) throw new Error(`Doc does not exist with docId "${docId}" for updating.`);
    if (!hasChanges(doc, updates)) return this;
    this.patch.addUpdates(updates, `/docs/${docId}/`);
    return this;
  }

  /**
   * Move a doc from one location to another in the loaded project.
   */
  moveDoc(docId: string, newParentId: string, index?: number) {
    const doc = this.getDoc(docId);
    const parent = this.getDoc(newParentId);
    let anscestor = parent;
    while (anscestor) {
      if (anscestor.id === docId) throw new Error('Doc cannot be a child of itself.');
      anscestor = this.getParent(anscestor.id);
    }
    if (!doc) throw new Error(`Doc does not exist with docId "${docId}" for moving.`);
    if (!parent) throw new Error(`Parent Doc does not exist with docId "${newParentId}" for moving.`);
    if (!parent.children) throw new Error(`Parent Doc "${newParentId}" does not have children for moving.`);
    if (index == null || isNaN(index)) index = parent.children.length;
    let oldParentId: string, oldIndex: number;

    forEachDocInHierarchy(this.patch.apply(this.project), new Set([docId]), (parentId, index) => {
      if (oldParentId) {
        // Should not be a valid state where a doc has 2 parents, but if it is, remove
        this.patch.remove(getChildPath(parentId, index));
      }
      oldParentId = parentId;
      oldIndex = index;
    });

    const testIndex = index == null || isNaN(index) ? parent.children.length : index;

    if (!oldParentId) {
      // Should not be a valid state, but if it is, add the doc to its new parent
      this.patch.add(getChildPath(newParentId, index), docId);
    } else if (oldParentId === newParentId && oldIndex === testIndex) {
      return this; // do nothing, this is a noop
    } else {
      this.patch.move(getChildPath(oldParentId, oldIndex), getChildPath(newParentId, index));
    }

    if (newParentId === 'trash') {
      // If parent is trash, remember the last place
      this.patch.add(`/docs/${docId}/oldParentId`, oldParentId);
      this.patch.add(`/docs/${docId}/oldIndex`, oldIndex);
    } else if (this.getDoc(docId).oldParentId) {
      // If being moved out of the trash remove last-place data
      this.patch.remove(`/docs/${docId}/oldParentId`);
      this.patch.remove(`/docs/${docId}/oldIndex`);
    }

    return this;
  }

  /**
   * Put a doc into the trash. This is the same as a move with parentId of 'trash'.
   */
  trashDoc(docId: string) {
    if (!docId) throw new Error('No doc id for trashing doc.');
    return this.moveDoc(docId, 'trash');
  }

  /**
   * Restore a doc from the trash to its previous location.
   */
  restoreDoc(docId: string) {
    const doc = this.getDoc(docId);
    if (!doc.oldParentId) return this;
    let index = doc.oldIndex;
    const oldParent = this.project.docs[doc.oldParentId];
    if (!oldParent.children || oldParent.children.length < index - 1) index = oldParent.children.length;
    return this.moveDoc(doc.id, doc.oldParentId, index);
  }

  /**
   * Delete a doc from the loaded project. This is not removal from trash, but complete deletion. Delete doc texts as
   * well.
   */
  deleteDoc(docId: string) {
    const doc = this.getDoc(docId);
    if (!doc) throw new Error('Doc does not exist.');
    const removed: any = {};
    const projectLinks = Object.keys(this.project.links);

    // Remove doc from parent
    Object.values(this.project.docs).forEach(parent => {
      if (!parent.children) return;
      const index = parent.children.indexOf(docId);
      if (index >= 0) this.patch.remove(getChildPath(parent.id, index));
    });

    const removeDoc = (docId: string) => {
      projectLinks.forEach(key => {
        if (key.startsWith(docId + ':') || key.endsWith(':' + docId)) {
          this.patch.remove(`/links/${key}`);
        }
      });
      removed[docId] = true;
      const children = this.getDoc(docId).children;
      if (children) children.forEach(removeDoc);
      this.patch.remove(`/docs/${docId}`);
    };

    // Remove doc and descencents from docs
    removeDoc(docId);

    return this;
  }

  /**
   * Completely delete all docs in the trash of the loaded project.
   */
  emptyTrash() {
    const deleteIds = this.project.docs.trash.children.slice().reverse();
    deleteIds.forEach(id => this.deleteDoc(id));
    return this;
  }

  /**
   * Links two docs together (or updates their existing link) with the given metadata.
   */
  linkDocs(from: string, rel: string, to: string, metadata = {}) {
    if (!this.getDoc(from)) {
      throw new Error(`No doc with id "${from}" found for link.`);
    }
    if (!this.getDoc(to)) {
      throw new Error(`No doc with id "${to}" found for link.`);
    }

    this.patch.add(`/links/${from}:${rel}:${to}`, metadata);
    return this;
  }

  /**
   * Removes the link between two docs.
   */
  unlinkDocs(from: string, rel: string, to: string) {
    if (!this.project.links[`${from}:${rel}:${to}`]) {
      throw new Error(`No link for "${from}:${rel}:${to}" found to remove.`);
    }

    this.patch.remove(`/links/${from}:${rel}:${to}`);
    return this;
  }

  /**
   * Change the text in a doc with the given delta.
   */
  changeText(docId: string, field: string, delta: Delta, force = false) {
    if (!force && !this.getDoc(docId)) {
      throw new Error('No doc found to update text.');
    }

    this.patch.changeText(`/docs/${docId}/${field}`, delta);
    return this;
  }

  /**
   * Gets the doc by id from the current project or within this patch if it was newly added.
   */
  getDoc(docId: string): Doc {
    const doc = docId === this.project.id ? this.project : this.project.docs[docId];
    if (doc) return doc;
    const op = this.patch.ops.find(op => op.op === 'add' && op.path === `/docs/${docId}`);
    return op && (op.value as Doc);
  }

  /**
   * Gets the doc's parent by doc id from the current project or within this patch if it was newly added.
   */
  getParent(docId: string) {
    let doc = this.project.docs[docId];
    if (!doc) return doc;
    const op = this.patch.ops.find(op => op.op === 'add' && op.path === `/docs/${docId}`);
    doc = op && (op.value as Doc);
    if (!doc) return;
    return Object.values(this.project.docs).find(parent => parent.children.includes(docId));
  }
}

function getEmptyProject(): Project {
  return {
    id: '',
    version: 0,
    type: 'project',
    children: [],
    links: {},
    docs: {
      trash: { id: 'trash', type: 'trash', children: [] },
      templates: { id: 'templates', type: 'templates', children: [] },
    },
    created: 0,
    committed: 0,
    changeId: '',
  };
}

function getEmptyDoc(): Doc {
  return {
    id: '',
    type: 'folder',
  };
}

function parentPath(project: Project, parentId: string, index: string | number) {
  if (project.id === parentId) {
    return `/children/${index}`;
  } else {
    return `/docs/${parentId}/children/${index}`;
  }
}

function ensureChildren(settings: SettingsStore, doc: Doc) {
  if (doc.children) return;
  const docSettings = settings.getFor(doc);
  if (!doc.children && docSettings.hasChildren && !docSettings.getChildren) {
    doc.children = [];
  }
}

function createStructure(projectPatch: ProjectPatch, doc: Doc, avoidIds?: any) {
  const structureData =
    doc.children && doc.children.length ? doc.children : projectPatch.settings.getFor(doc).structure;
  let structure = null;
  if (typeof structureData === 'function') {
    structure = structureData();
  } else {
    structure = structureData;
  }
  if (!structure) return;
  const isProject = !avoidIds;
  avoidIds = { ...avoidIds };

  const addChildren = (childTemplate: any) => {
    const child = { ...getEmptyDoc(), ...childTemplate };
    if (!child.id) child.id = createDocId(avoidIds);
    avoidIds[child.id] = true;

    ensureChildren(projectPatch.settings, child);

    // Add children first, so they exist on docs when parent is created and the project is valid every step of the way
    if (child.children && child.children.length) {
      child.children = child.children.map(addChildren);
    }

    if (isProject) {
      doc.docs[child.id] = child;
    } else {
      projectPatch.patch.add(`/docs/${child.id}`, child);
    }

    // After the doc is added, add any changeText needed
    constituteDeltas(projectPatch, child);

    return child.id;
  };

  if (!structure.length || typeof structure[0] !== 'string') {
    doc.children = structure.map(addChildren);
  }

  return doc;
}

function getChildPath(parentId: string, index: number | string) {
  return parentId.length === idLengths.project ? `/children/${index}` : `/docs/${parentId}/children/${index}`;
}

/**
 * Call the callback once for each doc in the map with the parent, index, and doc id. Do not handle docs which are
 * descendents of docs in the map.
 */
function forEachDocInHierarchy(
  project: Project,
  docIds: Set<string>,
  callback: (parentId?: string, index?: number, childId?: string) => any
) {
  // Check the children for this doc to see if any need removing
  function iterateChildren(doc: Doc) {
    const children = doc.children;
    if (!children) return;

    // Go backwards so if the value is removed from the array it doesn't mess up the loop
    for (let i = children.length - 1; i >= 0; i--) {
      const childId = children[i];
      if (docIds.has(childId)) {
        callback(doc.id, i, childId);
      }
    }
  }

  iterateChildren(project);

  Object.keys(project.docs).forEach(docId => {
    if (docIds.has(docId)) return; // Don't iterate over children of handled docs
    iterateChildren(project.docs[docId]);
  });
}

function constituteDeltas(projectPatch: ProjectPatch, doc: Doc) {
  Object.keys(doc).forEach(key => {
    const value = doc[key];
    if (key in TEXT_FIELDS && Array.isArray(value)) {
      projectPatch.changeText(doc.id, key, trimNewline(new Delta(value)));
      delete doc[key];
    } else if (value && Array.isArray(value.ops)) {
      projectPatch.changeText(doc.id, key, trimNewline(new Delta(value.ops)));
      delete doc[key];
    }
  });
  return doc;
}

function trimNewline(delta: Delta) {
  const last = delta.ops[delta.ops.length - 1];
  if (last && typeof last.insert === 'string' && last.insert.endsWith('\n')) {
    delta = delta.compose(new Delta().retain(delta.length() - 1).delete(1));
  }
  return delta;
}
