// Detect and handle third party browser extensions that cause problems with Dabble.
import { $t, alert } from '@dabble/app';

const extensions: Record<string, Function> = {
  handleSpeechify,
};

export function detectExtensions() {
  for (const detect of Object.values(extensions)) {
    detect();
  }
}

async function handleSpeechify() {
  const isInstalled = !!document.querySelector('#speechify-global-notifications');

  if (!isInstalled || localStorage.speechifyPrompt) {
    return;
  }

  await alert($t('speechify_prompt_title'), $t('speechify_prompt_message'));
  localStorage.setItem('speechifyPrompt', 'true');
}
