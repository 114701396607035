export const mdiImageFillWidth = 'M18 19v2h-12v-2h12zM21 3v14h-18v-14h18z';
export const mdiImageInsetCenter = 'M19 19v2h-14v-2h14zM19 7v10h-14v-10h14zM19 3v2h-14v-2h14z';
export const mdiImageOutsetCenter = 'M19 19v2h-14v-2h14zM21 7v10h-18v-10h18zM19 3v2h-14v-2h14z';
export const mdiImageFullPage =
  'M6.501,19L6.501,21L5,21L5,19L6.501,19ZM9.643,21L8.072,21L8.072,19L9.643,19L9.643,21ZM12.786,21L11.214,21L11.214,19L12.786,19L12.786,21ZM15.928,21L14.357,21L14.357,19L15.928,19L15.928,21ZM17.499,19L19,19L19,21L17.499,21L17.499,19ZM19,7L19,17L5,17L5,7L19,7ZM6.501,3L6.501,5L5,5L5,3L6.501,3ZM9.643,5L8.072,5L8.072,3L9.643,3L9.643,5ZM12.786,5L11.214,5L11.214,3L12.786,3L12.786,5ZM15.928,5L14.357,5L14.357,3L15.928,3L15.928,5ZM17.499,3L19,3L19,5L17.499,5L17.499,3Z';
export const mdiImageOutsetLeft =
  'M22 19v2h-15v-2h15zM22 15v2h-6v-2h6zM14 7v10h-12v-10h12zM22 11v2h-6v-2h6zM22 7v2h-6v-2h6zM22 3v2h-15v-2h15z';

export const mdiBookOpenBlankSplitVariant =
  'M16,10 L19,13 L16,16 L16,14 L13,14 L13,12 L16,12 L16,10 Z M8,10 L8,12 L11,12 L11,14 L8,14 L8,16 L5,13 L8,10 Z M21,4.5 C19.89,4.15 18.67,4 17.5,4 C15.55,4 13.45,4.4 12,5.5 C10.55,4.4 8.45,4 6.5,4 C4.55,4 2.45,4.4 1,5.5 L1,20.15 C1,20.4 1.25,20.65 1.5,20.65 C1.6,20.65 1.65,20.6 1.75,20.6 C3.1,19.95 5.05,19.5 6.5,19.5 C8.45,19.5 10.55,19.9 12,21 C13.35,20.15 15.8,19.5 17.5,19.5 C19.15,19.5 20.85,19.8 22.25,20.55 C22.35,20.6 22.4,20.6 22.5,20.6 C22.75,20.6 23,20.35 23,20.1 L23,5.5 C22.4,5.05 21.75,4.75 21,4.5 M21,18 C19.9,17.65 18.7,17.5 17.5,17.5 C15.8,17.5 13.35,18.15 12,19 L12,7.5 C13.35,6.65 15.8,6 17.5,6 C18.7,6 19.9,6.15 21,6.5 L21,18 Z';
export const mdiFileSplitOutline =
  'M6,14 L6,20 L18,20 L18,14 L20,14 L20,20 C20,21.0543618 19.1841222,21.9181651 18.1492623,21.9945143 L18,22 L6,22 C4.9456382,22 4.08183488,21.1841222 4.00548574,20.1492623 L4,20 L4,14 L6,14 Z M8,9 L8,11 L11,11 L11,13 L8,13 L8,15 L5,12 L8,9 Z M16,9 L19,12 L16,15 L16,13 L13,13 L13,11 L16,11 L16,9 Z M14,2 L20,8 L20,10 L18,10 L18,9 L13,9 L13,4 L6,4 L6,10 L4,10 L4,4 C4,2.9456382 4.81587779,2.08183488 5.85073766,2.00548574 L6,2 L14,2 Z';
export const mdiFileMergeOutline =
  'M13,22 L19,22 C20.1045695,22 21,21.1045695 21,20 L21,14 L19,14 L19,20 L13,20 L13,22 Z M5,14 L5,20 L11,20 L11,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,20 L3,14 L5,14 Z M8,9 L11,12 L8,15 L8,13 L3,13 L3,11 L8,11 L8,9 Z M16,9 L16,11 L21,11 L21,13 L16,13 L16,15 L13,12 L16,9 Z M15,2 L21,8 L21,10 L19,10 L19,9 L14,9 L14,4 L13,4 L13,2 L15,2 Z M11,2 L11,4 L5,4 L5,10 L3,10 L3,4 C3,2.8954305 3.8954305,2 5,2 L5,2 L11,2 Z';

export const mdiRibbonVariant =
  'M3,4 L22.0700793,4 C22.622364,4 23.0700793,4.44771525 23.0700793,5 C23.0700793,5.20651571 23.0061413,5.4079665 22.8870472,5.5766832 L13.1952474,19.3067328 C12.4457364,20.36854 11.2270688,21 9.92737599,21 L3,21 C1.34314575,21 2.02906125e-16,19.6568542 0,18 L0,7 C-2.02906125e-16,5.34314575 1.34314575,4 3,4 Z';
export const mdiRibbonVariantTip =
  'M0,0 L20.1324265,0 C21.236996,4.23798597e-15 22.1324265,0.8954305 22.1324265,2 C22.1324265,2.41414695 22.0038596,2.81808063 21.7644754,3.15603468 L8.49452192,21.8900867 C7.55713314,23.2134591 6.03613076,24 4.41439951,24 L0,24 L0,24 L0,0 Z';

export const mdiCommentHistory =
  'M16.5,11 L16.5,13.82 L18.94,15.23 L18.19,16.53 L15,14.69 L15,11 L16.5,11 Z M7,21 L7,18 L3,18 C1.8954305,18 1,17.1045695 1,16 L1,4 C1,2.89 1.9,2 3,2 L19,2 C20.1045695,2 21,2.8954305 21,4 C21,6.88888889 21,8.88888889 21,10 C24,15 21,23.5 11.8695434,19.0332416 C11.4263267,19.4776562 10.5364789,20.369909 9.2,21.71 C9,21.9 8.75,22 8.5,22 L8,22 C7.44771525,22 7,21.5522847 7,21 Z M9,18 C9,18.7345015 9,19.0945015 9,19.08 C9.6760804,18.4039196 10.3521608,17.7278392 11.0282412,17.0517588 C16,22.5 26,18 19,9 C19,7.33333333 19,5.66666667 19,4 L3,4 L3,16 C4.92635243,16 6.25968577,16 7,16 C8.11047135,16 9,16.8982477 9,18 Z M16,7 C19.8659932,7 23,10.1340068 23,14 C23,17.8659932 19.8659932,21 16,21 C12.1340068,21 9,17.8659932 9,14 C9,10.1340068 12.1340068,7 16,7 Z M16,9 C13.2385763,9 11,11.2385763 11,14 C11,16.7614237 13.2385763,19 16,19 C18.7614237,19 21,16.7614237 21,14 C21,11.2385763 18.7614237,9 16,9 Z';

export const mdiColumnsHeader =
  'M3 4H21V7H3V4ZM3 10H11V12H3V10ZM13 10H21V12H13V10ZM3 14H11V16H3V14ZM13 14H21V16H13V14ZM3 18H11V20H3V18ZM13 18H21V20H13V18Z';

export const mdiFormatManuscript =
  'M6 2C5.4696 2 4.9609 2.2107 4.5858 2.5858 4.2107 2.9609 4 3.4696 4 4V20C4 20.5304 4.2107 21.0391 4.5858 21.4142 4.9609 21.7893 5.4696 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142 19.7893 21.0391 20 20.5304 20 20V8L17 5 14 2H6ZM5.5 3.5H13.5V8.5H18.5V20.5H5.5V3.5ZM8 9V10H16V9H8ZM8 17V18H13V17H8ZM8 12V11H16V12H8ZM9 7V6H13V7H9ZM8 14V13H16V14H8ZM8 16V15H16V16H8Z';

export const mdiFormatDabble =
  'M6 2C5.4696 2 4.9609 2.2107 4.5858 2.5858 4.2107 2.9609 4 3.4696 4 4V20C4 20.5304 4.2107 21.0391 4.5858 21.4142 4.9609 21.7893 5.4696 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142 19.7893 21.0391 20 20.5304 20 20V8L14 2H6ZM5.5 3.5H13.5V8.5H18.5V20.5H5.5V3.5ZM8 13V14H16V13H8ZM8 17V18H13V17H8ZM9 7H13V9H15V9.5H13 9V7ZM8 15H16V16H8V15Z';

export const mdiFileBanner =
  'M18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H18C18.5304 2 19.0391 2.21071 19.4142 2.58579C19.7893 2.96086 20 3.46957 20 4V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22ZM6 4V20H18V4H6Z M8 6V10H16V6H8Z';

// This one is the original moved right by 1px to match the mdiContentSaveCheck icon
export const mdiContentSaveEdit =
  'M11 19L11.14 18.86C9.9 18.5 9 17.36 9 16C9 15.2044 9.31607 14.4413 9.87868 13.8787C10.4413 13.3161 11.2044 13 12 13C13.36 13 14.5 13.9 14.86 15.14L21 9V7L17 3H5C3.89 3 3 3.9 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H11V19ZM5 5H15V9H5V5ZM21.04 12.13C20.9 12.13 20.76 12.19 20.65 12.3L19.65 13.3L21.7 15.35L22.7 14.35C22.92 14.14 22.92 13.79 22.7 13.58L21.42 12.3C21.31 12.19 21.18 12.13 21.04 12.13ZM19.07 13.88L13 19.94V22H15.06L21.12 15.93L19.07 13.88Z';

// Use for additive icons, masking and icon overlays.
export const mdiMaskCircle =
  'M24 0H0V24H15.6822C14.0655 22.9251 13 21.087 13 19C13 15.6863 15.6863 13 19 13C21.087 13 22.9251 14.0655 24 15.6822V0Z M24 22.3178C23.5569 22.9842 22.9842 23.5569 22.3178 24H24V22.3178Z';
export const mdiMaskCircleBigger =
  'M24 0H0V24H19.5H24V22.1904C22.8321 23.3112 21.2465 24 19.5 24C15.9101 24 13 21.0899 13 17.5C13 13.9101 15.9101 11 19.5 11C21.2465 11 22.8321 11.6888 24 12.8096V0Z';
export const mdiOverlayAdd = 'M23 20H20V23H18V20H15V18H18V15H20V18H23V20Z';
export const mdiOverlayCheck = 'M18.5 22L23.5 17L22 15.5L18.5 19L16.5 17L15 18.5L18.5 22Z';
export const mdiOverlayRemove =
  'M20.41 19L22.54 21.12L21.12 22.54L19 20.41L16.88 22.54L15.47 21.12L17.59 19L15.47 16.88L16.88 15.47L19 17.59L21.12 15.47L22.54 16.88L20.41 19Z';
export const mdiMaskEye =
  'M24 0H0V24H13.1503C11.2647 22.958 9.77657 21.1748 9 19C10.2533 15.49 13.36 13 17 13C19.8895 13 22.443 14.5691 24 16.9719V0Z M24 21.0281C23.1891 22.2795 22.1079 23.3048 20.8497 24H24V21.0281Z';
export const mdiOverlayEye =
  'M17 18C17.56 18 18 18.44 18 19C18 19.56 17.56 20 17 20C16.44 20 16 19.56 16 19C16 18.44 16.44 18 17 18ZM17 15C14.27 15 11.94 16.66 11 19C11.94 21.34 14.27 23 17 23C19.73 23 22.06 21.34 23 19C22.06 16.66 19.73 15 17 15ZM17 21.5C16.337 21.5 15.7011 21.2366 15.2322 20.7678C14.7634 20.2989 14.5 19.663 14.5 19C14.5 18.337 14.7634 17.7011 15.2322 17.2322C15.7011 16.7634 16.337 16.5 17 16.5C17.663 16.5 18.2989 16.7634 18.7678 17.2322C19.2366 17.7011 19.5 18.337 19.5 19C19.5 19.663 19.2366 20.2989 18.7678 20.7678C18.2989 21.2366 17.663 21.5 17 21.5Z';
export const mdiMaskEdit =
  'M24 0H0V24H10V19.9L18.8 11.2L19.8 10.2C19.9 10.1 20 10 20.2 10C20.3 10 20.5 10.1 20.6 10.2L24 13.6V0Z M24 16.2L23.9 16.3L16.1115 24H24V16.2Z';
export const mdiOverlayEdit =
  'M20.2 13C20.3 13 20.5 13.1 20.6 13.2L21.9 14.5C22.1 14.7 22.1 15.1 21.9 15.3L20.9 16.3L18.8 14.2L19.8 13.2C19.9 13.1 20 13 20.2 13ZM20.2 16.9L14.1 23H12V20.9L18.1 14.8L20.2 16.9Z';
export const mdiMaskLock =
  'M24 0H0V24H12.0368C12.0129 23.9032 12 23.8027 12 23.7V17.2C12 16 13 15.5 13.2 15.5V14.5C13.2 13 15.6 11 18 11C20.4 11 22.8 13 22.8 14.5V15.5C23 15.5 24 16 24 17.3V0Z M24 23.8C24 23.8669 23.9925 23.9339 23.9783 24H24V23.8Z';
export const mdiOverlayLock =
  'M18 13C16.6 13 15.2 14.1 15.2 15.5V17C14.6 17 14 17.6 14 18.2V21.7C14 22.4 14.6 23 15.2 23H20.7C21.4 23 22 22.4 22 21.8V18.3C22 17.6 21.4 17 20.8 17V15.5C20.8 14.1 19.4 13 18 13ZM18 14.2C18.8 14.2 19.5 14.7 19.5 15.5V17H16.5V15.5C16.5 14.7 17.2 14.2 18 14.2Z';
export const mdiOverlayCloudSync =
  'M19.5 13.5V12L17.25 14.25L19.5 16.5V15C20.88 15 22 16.12 22 17.5C22 17.9 21.91 18.28 21.74 18.62L22.83 19.71C23.25 19.08 23.5 18.32 23.5 17.5C23.5 15.29 21.71 13.5 19.5 13.5ZM19.5 20C18.12 20 17 18.88 17 17.5C17 17.1 17.09 16.72 17.26 16.38L16.17 15.29C15.75 15.92 15.5 16.68 15.5 17.5C15.5 19.71 17.29 21.5 19.5 21.5V23L21.75 20.75L19.5 18.5V20Z';
