<script lang="ts">
  import { plugins, router, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiFaceAgent } from '@mdi/js';

  function onClick() {
    if (router.getUrl().startsWith('/admin/')) {
      router.navigate(sessionStorage.lastUrlBeforeAdmin || '/');
    } else {
      sessionStorage.lastUrlBeforeAdmin = router.getUrl();
      router.navigate('/admin/');
    }
  }
</script>

{#if $plugins.currentUser && ($plugins.currentUser.admin || $plugins.currentUser.claims?.admin)}
  <button class="dropdown-item" on:click={onClick}>
    <Icon path={mdiFaceAgent} />
    {$t('feature_support_center')}
  </button>
{/if}
