import { idLengths } from '../uuid';
import {
  applyChange,
  applyChanges,
  Change,
  changesCoverSnapshots,
  createChangeId,
  groupChangesIntoSnapshotChunks,
  idMap,
  PROJECT_SHAPE,
  rebaseChanges,
  Snapshot,
} from './changes';
import { EventDispatcher } from './event-dispatcher';
import JSONPatch from './json-patch';
import PromiseQueue from './promise-queue';

export interface GetOptions {
  before?: number;
  after?: number;
  version?: number;
  reverse?: boolean;
  limit?: number;
  committed?: boolean;
}

export interface StorageEventData {
  storageId: string;
}

export interface ChangeStorageEvent extends StorageEventData {
  change: Change;
}

export interface ChangeQueryStorageEvent extends StorageEventData {
  projectId: string;
  before: number;
  after: number;
  remoteCount: number;
}

export interface ChangeProjectEvent<Project extends Snapshot = Snapshot> {
  change: Change;
  snapshot: Project;
  currentSnapshot: Project;
  remote?: boolean;
  fromEditor?: boolean;
}

export interface CommittedChangesEvent {
  changes: Change[];
  remote?: boolean;
}

export interface ReceiveChangesEvent {
  changes: Change[];
  remoteChanges: Change[];
  rebased: Change[];
}

export interface CollectiveStore<Project extends Snapshot = Snapshot> {
  open(collective: Collective<any>): void;
  close(): void;
  start(): CollectiveStoreInTransaction<Project>;
  getSnapshots(projectId: string, options?: GetOptions): Promise<Project[]>;
  getSnapshot(projectId: string, options?: GetOptions): Promise<Project>;
  getSnapshot(projectId: string, version: number): Promise<Project>;
  getChanges(projectId: string, options?: GetOptions): Promise<Change[]>;
  getChange(projectId: string, options?: GetOptions): Promise<Change>;
  getChange(projectId: string, version: number): Promise<Change>;
  putSnapshot(snapshot: Project): Promise<void>;
  putChange(change: Change): Promise<void>;
  deleteSnapshots(project: string, options?: GetOptions): Promise<void>;
  deleteChanges(project: string, options?: GetOptions): Promise<void>;
  deleteProject(projectId: string): Promise<void>;
}

export interface CollectiveStoreInTransaction<Project extends Snapshot = Snapshot> extends CollectiveStore<Project> {
  commit(): Promise<any>;
}

export interface CollectiveSource<Project extends Snapshot = Snapshot> {
  open: (collective: Collective<any>) => void;
  close: () => void;
  syncProject: (projectId: string) => Promise<Project>;
}

export interface CollectiveOptions {
  store: CollectiveStore<any>;
  strict?: boolean;
  source?: CollectiveSource<any>;
  author: string;
  snapshotInterval?: number;
  serverTimeOffset?: () => number;
}

export class CollectiveError extends Error {
  constructor(public code: string, message: string) {
    super(message) /* istanbul ignore next */;
    this.name = 'CollectiveError';
    this.code = code;
  }
}

type DBError = 'TRANSACTION_START' | 'TRANSACTION_COMMIT' | 'DATA_LOAD' | 'DATA_CORRUPTION';

export class CollectiveDBError extends Error {
  code: DBError;

  constructor(code: DBError, message: string) {
    super(message) /* istanbul ignore next */;
    this.name = 'CollectiveDBError';
    this.code = code;
  }
}

const DEFAULT_SERVER_TIME_OFFSET = () => 0;
const DEFAULT_SNAPSHOT_SIZE = 200;

/**
 * A collective is a versioned, shared object model that supports concurrent editing and offline support. Requires
 * a store for storing data locally and can have a source for sharing data between devices and users.
 */
export class Collective<Project extends Snapshot = Snapshot> extends EventDispatcher {
  store: CollectiveStore<Project>;
  source: CollectiveSource<Project>; // server connection
  strict: boolean;
  snapshotInterval: number;
  serverTimeOffset: () => number;
  author: string;
  promiseQueue: PromiseQueue;
  protected queuedPatches: Set<JSONPatch>;
  protected closed: boolean;
  protected storagePrefix: string;
  protected storageEvents: Set<string>;

  constructor(options: CollectiveOptions) {
    super();
    this.store = options.store;
    this.source = options.source;
    this.snapshotInterval = options.snapshotInterval || DEFAULT_SNAPSHOT_SIZE;
    this.serverTimeOffset = options.serverTimeOffset || DEFAULT_SERVER_TIME_OFFSET;
    this.strict = options.strict;
    this.author = options.author;
    this.promiseQueue = new PromiseQueue(1);
    this.queuedPatches = new Set();
    this.closed = true;
    this.storagePrefix = `collective/${this.author}/`;
    this.storageEvents = new Set();
  }

  async open() {
    await this.store.open(this);
    if (this.source) await this.source.open(this);
    this.closed = false;
    window.addEventListener('storage', this._onStorage);
  }

  /**
   * Detach collective from the database. Does not close the database.
   */
  close() {
    window.removeEventListener('storage', this._onStorage);
    this.closed = true;
    if (this.store) {
      this.store.close();
      this.store = null;
    }
    if (this.source) {
      this.source.close();
      this.source = null;
    }
  }

  setAuthor(author: string) {
    this.author = author;
    this.storagePrefix = `collective/${author}/`;
  }

  /**
   * Gets a project at the latest version.
   */
  async getProject(projectId: string, atVersion?: number) {
    const { currentSnapshot } = await this._getProjectData(projectId, atVersion);
    return currentSnapshot;
  }

  /**
   * Changes a project with a given JSON patch.
   */
  changeProject(projectId: string, patch: JSONPatch, changeId?: string, fromEditor?: boolean) {
    if (this.closed) throw new CollectiveError('closed', 'Collective has been closed');

    // Hold on to these until they are ready to be committed so they can be rebased as needed
    this.queuedPatches.add(patch);
    return this.promiseQueue.add(() => {
      this.queuedPatches.delete(patch);
      return this._changeProject(projectId, patch, changeId, fromEditor);
    });
  }

  /**
   * Process and store changes received remotely from a server (not another tab as those are already stored locally).
   * This allows syncing and collaborative authoring. This method handles changes made from elsewhere as well as those
   * made from here that are being updated with the "committed" field. The committed field is used to determine whether
   * a change has been saved remotely. When the field is empty the change is assumed to have only been saved locally.
   * Each change has an ID which is used to determine whether a remote change and local change are the same.
   * All local changes which are not committed to the server will be rebased against those coming from the server. If
   * the project is currently loaded in-memory those changes will be applied to the in-memory store as well as database.
   * Remote changes are expected to be received in order and without gaps.
   * @param {String} projectId The project ID for the changes being received
   * @param {Array} remoteChanges An array of changes for the project
   */
  receiveChanges(projectId: string, remoteChanges: Change[]) {
    if (this.closed) throw new CollectiveError('closed', 'Collective has been closed');

    return this.promiseQueue.add(async () => {
      // remoteChanges is all changes from the server, including the ones we've sent to the server from this process
      const changesNotFromMe = await this._receiveChanges(projectId, remoteChanges);

      // Once saved, rebase pending local changes against nonLocalChanges ones.
      if (this.queuedPatches.size && changesNotFromMe.length) {
        const remoteOps = changesNotFromMe.reduce((ops, change) => ops.concat(change.ops), []);
        const remotePatch = new JSONPatch(remoteOps);
        this.queuedPatches.forEach(patch => {
          patch.ops = remotePatch.transform(PROJECT_SHAPE, patch).ops;
        });
      }

      return changesNotFromMe;
    });
  }

  /**
   * Stores changes retrieved remotely and stores them in the collective store with their snapshots.
   */
  async backfillProject(projectId: string, changes: Change[], saveSnapshot?: Project) {
    validateChanges(changes, projectId);

    let snapshotsToSave: Project[];

    if (saveSnapshot !== undefined) {
      try {
        snapshotsToSave = this._getSnapshotsForSave(projectId, saveSnapshot, changes);
      } catch (err) {
        throw new CollectiveDBError(
          'DATA_CORRUPTION',
          'Error creating snapshots for remote changes for "' + projectId + '": ' + err.message
        );
      }
    }

    let transaction: CollectiveStoreInTransaction<Project>;
    const commitPromises = [];

    try {
      transaction = this.store.start();
    } catch (err) {
      throw new CollectiveDBError('TRANSACTION_START', 'Could not start a transaction: ' + err.message);
    }

    commitPromises.push(...changes.map(change => transaction.putChange(change)));
    if (snapshotsToSave) commitPromises.push(...snapshotsToSave.map(snapshot => transaction.putSnapshot(snapshot)));

    try {
      await transaction.commit();
      await Promise.all(commitPromises);
    } catch (err) {
      throw new CollectiveDBError(
        'TRANSACTION_COMMIT',
        'Error saving backfill changes and snapshots to the store for "' + projectId + '": ' + err.message
      );
    }
  }

  /**
   * Creates snapshots if needed for a list of changes. Ensure we have our snapshots in place.
   */
  async backfillSnapshots(projectId: string, previousSnapshot: Project, changes: Change[]) {
    let snapshotsToSave: Project[];
    try {
      snapshotsToSave = this._getSnapshotsForSave(projectId, previousSnapshot, changes);
      if (!snapshotsToSave.length) return previousSnapshot;
    } catch (err) {
      throw new CollectiveDBError(
        'DATA_CORRUPTION',
        'Error creating snapshots for backfill for "' + projectId + '": ' + err.message
      );
    }

    let transaction: CollectiveStoreInTransaction<Project>;
    const commitPromises = [];

    try {
      transaction = this.store.start();
      commitPromises.push(snapshotsToSave.map(snapshot => transaction.putSnapshot(snapshot)));
    } catch (err) {
      throw new CollectiveDBError('TRANSACTION_START', 'Could not start a transaction: ' + err.message);
    }

    try {
      await transaction.commit();
      await Promise.all(commitPromises);
    } catch (err) {
      throw new CollectiveDBError(
        'TRANSACTION_COMMIT',
        'Error saving backfill changes and snapshots to the store for "' + projectId + '": ' + err.message
      );
    }
    return snapshotsToSave[snapshotsToSave.length - 1];
  }

  async createMissingSnapshots(projectId: string) {
    let snapshot: Project,
      changes: Change[],
      version = 0,
      until = 0;

    const snapshots = await this.store.getSnapshots(projectId, { limit: 2 });
    if (snapshots.length < 2) return;
    if (snapshots.length === 2 && snapshots[0].version === 0 && snapshots[1].version === this.snapshotInterval) return;
    snapshot = snapshots[1];
    until = snapshot.version;
    snapshot = null;
    changes = await this.store.getChanges(projectId, { version: 0 });

    while (changes.length && version < until) {
      snapshot = await this.backfillSnapshots(projectId, snapshot, changes);
      changes = await this.store.getChanges(projectId, { after: snapshot.version, limit: this.snapshotInterval });
      version += this.snapshotInterval;
    }
  }

  /**
   * Dispatch an event to collectives in other tabs that use the same database.
   */
  dispatchEventAcrossTabs(type: string, data: any) {
    const id = (data.storageId = createChangeId());
    this.storageEvents.add(id);
    setTimeout(() => this.storageEvents.delete(id), 2000);
    const itemKey = this.storagePrefix + type;
    localStorage.setItem(itemKey, JSON.stringify(data));
    localStorage.removeItem(itemKey);
  }

  /**
   * Make a project out of a snapshot (or null) and a list of changes that come after that snapshot. ProjectId is
   * required because snapshot will be null at version 0.
   */
  hydrateProject(projectId: string, snapshot: Project, changes: Change[]) {
    if (!changes || !changes.length) return snapshot;
    let project: Project = snapshot;

    // Try applying all at once (most performant)
    try {
      project = updateSnapshot(projectId, applyChanges(project, changes), changes[changes.length - 1]);
    } catch (err) {
      if (this.strict) {
        // Work with bad data rather than throwing errors.
        throw new CollectiveError(
          'BAD_DATA',
          'The data in the database is bad and cannot build the latest snapshot for "' + projectId + '": ' + err.message
        );
      }

      // If a change would not apply, try applying them one at a time
      for (let i = 0; i < changes.length; i++) {
        const change = changes[i];
        try {
          project = applyChange(project, change);
        } catch (err) {
          if (this.strict !== false) console.warn('could not apply change', change.version, err.message);
        }
      }
      project = updateSnapshot(projectId, project, changes[changes.length - 1]);
    }
    return project;
  }

  /**
   * Protected method to get the last snapshot, changes, and current project from it.
   */
  protected async _getProjectData(projectId: string, atVersion?: number) {
    let lastSnapshot: Project;
    let latestChanges: Change[];
    let currentSnapshot: Project;
    const before = atVersion != null ? atVersion + 1 : undefined;

    try {
      lastSnapshot = await this.store.getSnapshot(projectId, { reverse: true, before });
      latestChanges = await this.store.getChanges(projectId, { after: lastSnapshot && lastSnapshot.version, before });
    } catch (err) {
      throw new CollectiveDBError(
        'DATA_LOAD',
        'Error loading the latest snapshot and changes from the store for "' + projectId + '": ' + err.message
      );
    }

    if (latestChanges.length) {
      lastSnapshot = await this.backfillSnapshots(projectId, lastSnapshot, latestChanges);
      latestChanges = latestChanges.filter(change => change.version > lastSnapshot.version);
    }

    // eslint-disable-next-line prefer-const
    currentSnapshot = this.hydrateProject(projectId, lastSnapshot, latestChanges);

    return { lastSnapshot, latestChanges, currentSnapshot };
  }

  /**
   * Protected method to change a project. Applies the patch, but `change` queues this method.
   */
  protected async _changeProject(projectId: string, patch: JSONPatch, changeId?: string, fromEditor?: boolean) {
    const { lastSnapshot, latestChanges, currentSnapshot } = await this._getProjectData(projectId);
    let snapshot: Project;
    let transaction: CollectiveStoreInTransaction<Project>;
    const commitPromises = [];

    if (!patch.ops.length) return currentSnapshot;

    try {
      transaction = this.store.start();
    } catch (err) {
      throw new CollectiveDBError('TRANSACTION_START', 'Could not start a transaction: ' + err.message);
    }

    if (typeof changeId !== 'string' || changeId.length !== idLengths.change) {
      changeId = createChangeId();
    }

    const version = currentSnapshot ? currentSnapshot.version + 1 : 0;
    const prevId = currentSnapshot ? currentSnapshot.changeId : undefined;
    const change: Change = {
      id: changeId,
      prevId,
      projectId,
      version,
      author: this.author,
      created: Date.now() + this.serverTimeOffset(),
      committed: 0,
      ops: patch.ops,
    };

    if (!prevId) delete change.prevId;

    // Apply the change to verify it is a valid change
    try {
      snapshot = updateSnapshot(projectId, applyChange(currentSnapshot, change, true), change);
    } catch (err) {
      throw new CollectiveError(
        'BAD_PATCH',
        'There was an error applying the provided patch to "' + projectId + '": ' + err.message
      );
    }

    commitPromises.push(transaction.putChange(change));
    const snapshots = this._getSnapshotsForSave(projectId, lastSnapshot, latestChanges.concat(change));
    commitPromises.push(...snapshots.map(snapshot => transaction.putSnapshot(snapshot)));

    try {
      await transaction.commit();
      await Promise.all(commitPromises); // Catch any errors from the puts
    } catch (err) {
      throw new CollectiveDBError(
        'TRANSACTION_COMMIT',
        'Error saving change and possible snapshots to the database for "' + projectId + '": ' + err.message
      );
    }

    this.dispatchEvent('changeProject', { change, snapshot, currentSnapshot, fromEditor }, 'local');
    this.dispatchEventAcrossTabs('changeProject', { change });
    return snapshot;
  }

  /**
   * Protected method to receive remote changes for project. `receiveChanges` queues this method.
   */
  protected async _receiveChanges(projectId: string, remoteChanges: Change[]) {
    if (!remoteChanges.length) return remoteChanges;
    let remotePrevId = remoteChanges[0].prevId;
    let remoteVersion = remoteChanges[0].version;
    let snapshot: Project;
    let changeBeforeRemote: Change;
    let localChanges: Change[];
    let transaction: CollectiveStoreInTransaction<Project>;
    const commitPromises = [];

    validateChanges(remoteChanges, projectId);

    try {
      transaction = this.store.start();
    } catch (err) {
      throw new CollectiveDBError('TRANSACTION_START', 'Could not start a transaction: ' + err.message);
    }

    // Ensure remote changes are contiguous with existing local changes
    try {
      [snapshot, changeBeforeRemote, localChanges] = await Promise.all([
        transaction.getSnapshot(projectId, { before: remoteVersion, reverse: true }),
        transaction.getChange(projectId, { before: remoteVersion, reverse: true }),
        transaction.getChanges(projectId, { after: remoteVersion - 1 }),
      ]);
    } catch (err) {
      throw new CollectiveDBError(
        'DATA_LOAD',
        'Could not load snapshot and change data from the store: ' + err.message
      );
    }

    const versionBefore = changeBeforeRemote?.version ?? snapshot?.version;
    const localIdBefore = changeBeforeRemote?.id ?? snapshot?.changeId;

    if (remoteVersion !== 0) {
      if (remoteVersion - 1 !== versionBefore || remotePrevId !== localIdBefore) {
        throw new CollectiveError(
          'received_changes_gap',
          'There is a gap between local changes and the received remote changes'
        );
      }
    }

    const localById = idMap(localChanges);
    const remoteById = idMap(remoteChanges);
    const committed = remoteChanges.filter(c => localById.has(c.id)); // In both local and remote
    remoteChanges = remoteChanges.filter(c => !localById.has(c.id)); // In only remote
    localChanges = localChanges.filter(c => !remoteById.has(c.id)); // In only local

    // 1. Save local changes with their new "committed" field
    if (committed.length) {
      let snapshots: Project[];

      if (changesCoverSnapshots(committed, this.snapshotInterval)) {
        try {
          snapshots = await transaction.getSnapshots(projectId, {
            after: committed[0].version - 1,
            before: committed[committed.length - 1].version + 1,
          });
          snapshots.forEach(s => (s.committed = (remoteById.get(s.changeId) || localById.get(s.changeId)).committed));
        } catch (err) {
          throw new CollectiveDBError('DATA_LOAD', 'Could not load snapshots from the store to commit: ' + err.message);
        }
      }

      if (snapshots) commitPromises.push(...snapshots.map(snapshot => transaction.putSnapshot(snapshot)));
      commitPromises.push(...committed.map(change => transaction.putChange(change)));

      // If there were no remote changes, only local updates, commit and exit
      if (!remoteChanges.length) {
        try {
          await transaction.commit();
          await Promise.all(commitPromises);
        } catch (err) {
          throw new CollectiveDBError(
            'TRANSACTION_COMMIT',
            'Error saving local changes and snapshots to the store for "' + projectId + '": ' + err.message
          );
        }

        this.dispatchEvent('committedChanges', { changes: committed }, 'local');
        const after = committed[0].version - 1;
        const before = committed[committed.length - 1].version + 1;
        this.dispatchEventAcrossTabs('committedChanges', { projectId, before, after });
        return remoteChanges;
      }
    }

    // 2. Rebase local changes against remote ones
    remotePrevId = remoteChanges[0].prevId;
    remoteVersion = remoteChanges[0].version;
    const rebased = rebaseChanges(localChanges, remoteChanges);
    const versionsToDelete = new Set(localChanges.map(c => c.version));
    const changesToSave = remoteChanges.concat(rebased);

    // 3. Recreate snapshots as needed and get a snapshot up to the latest committed change
    if (changesCoverSnapshots(changesToSave, this.snapshotInterval)) {
      let snapshot: Project;
      let intermediateChanges: Change[];
      try {
        snapshot = await transaction.getSnapshot(projectId, {
          before: remoteVersion,
          reverse: true,
        });
        intermediateChanges = await transaction.getChanges(projectId, {
          before: remoteVersion,
          after: snapshot && snapshot.version,
        });
      } catch (err) {
        throw new CollectiveDBError(
          'DATA_LOAD',
          'Could not load latest snapshot and changes from the store: ' + err.message
        );
      }
      const allChanges = intermediateChanges.concat(changesToSave);
      try {
        const snapshotsToSave = this._getSnapshotsForSave(projectId, snapshot, allChanges);
        commitPromises.push(...snapshotsToSave.map(snapshot => transaction.putSnapshot(snapshot)));
      } catch (err) {
        throw new CollectiveDBError(
          'DATA_CORRUPTION',
          'Error creating snapshots for remote changes for "' + projectId + '": ' + err.message
        );
      }
    }

    changesToSave.forEach(change => versionsToDelete.delete(change.version));
    commitPromises.push(...changesToSave.map(change => transaction.putChange(change)));
    if (versionsToDelete.size) {
      // If changes are rendered invalid and removed from the list of local changes, that can allow a local change to
      // be duplicated by being saved at a version lower than it was and not being deleted at the version higher. This
      // should be a rare occurrence. In most cases, the change versions will be overwritten.
      commitPromises.push(
        ...Array.from(versionsToDelete).map(version => transaction.deleteChanges(projectId, { version }))
      );
    }

    try {
      await transaction.commit();
      await Promise.all(commitPromises);
    } catch (err) {
      throw new CollectiveDBError(
        'TRANSACTION_COMMIT',
        'Error saving remote changes and snapshots to the store for "' + projectId + '": ' + err.message
      );
    }

    if (committed.length) {
      this.dispatchEvent('committedChanges', { changes: committed }, 'local');
      const after = committed[0].version - 1;
      const before = committed[committed.length - 1].version + 1;
      this.dispatchEventAcrossTabs('committedChanges', { projectId, before, after });
    }

    this.dispatchEvent('receiveChanges', { changes: changesToSave, remoteChanges, rebased }, 'local');
    const after = changesToSave[0].version - 1;
    const before = changesToSave[changesToSave.length - 1].version + 1;
    this.dispatchEventAcrossTabs('receiveChanges', { projectId, before, after, remoteCount: remoteChanges.length });
    return remoteChanges;
  }

  /**
   * Creates an array of snapshots that need saving given the list of changes.
   */
  _getSnapshotsForSave(projectId: string, lastSnapshot: Project, changesSinceLastSnapshot: Change[]) {
    const snapshots: Project[] = [];
    if (!changesCoverSnapshots(changesSinceLastSnapshot, this.snapshotInterval)) {
      return snapshots;
    }

    const snapshotsChanges = groupChangesIntoSnapshotChunks(changesSinceLastSnapshot, this.snapshotInterval);
    let snapshot = lastSnapshot;

    snapshotsChanges.forEach(slice => {
      // Create/recreate snapshots that need updating
      if (slice.length === this.snapshotInterval || slice[0].version === 0) {
        // apply changes does not throw for bad changes
        snapshot = this.hydrateProject(projectId, snapshot, slice);
        snapshots.push(snapshot);
      }
    });

    return snapshots;
  }

  protected _onStorage = async (event: StorageEvent) => {
    const { storageArea, newValue, key } = event;
    if (storageArea !== localStorage || newValue === null || newValue === '' || !key.startsWith(this.storagePrefix)) {
      return;
    }

    let eventName: string, data: StorageEventData, change: Change, query: ChangeQueryStorageEvent;

    try {
      eventName = key.slice(this.storagePrefix.length);
      data = JSON.parse(newValue);
      if (this.storageEvents.has(data.storageId)) {
        return; // Fix for Safari dispatching storage events in the same tab
      }

      if (eventName === 'changeProject') {
        change = (data as ChangeStorageEvent).change;
      } else if (eventName === 'committedChanges') {
        query = data as ChangeQueryStorageEvent;
      } else if (eventName === 'receiveChanges') {
        query = data as ChangeQueryStorageEvent;
      }
    } catch (err) {
      console.error('Error dispatching across tabs:', err);
    }

    if (eventName === 'changeProject') {
      const { currentSnapshot } = await this._getProjectData(change.projectId, change.version - 1);
      const snapshot = updateSnapshot(change.projectId, applyChange(currentSnapshot, change), change);
      const event = { change, snapshot, currentSnapshot, remote: true } as ChangeProjectEvent;
      this.dispatchEvent('changeProject', event);
    } else if (eventName === 'committedChanges') {
      const { projectId, before, after } = query;
      this.store
        .getChanges(projectId, { before, after })
        .then(changes => {
          const event = { changes, remote: true } as CommittedChangesEvent;
          this.dispatchEvent('committedChanges', event);
        })
        .catch(error => {
          console.log(error);
          throw new CollectiveDBError(
            'DATA_LOAD',
            'Error loading the latest changes from the store for "' + projectId + '": ' + error.message
          );
        });
    } else if (eventName === 'receiveChanges') {
      const { projectId, before, after, remoteCount } = query;
      // changing to then/catch statements should remove the sentry error #DABBLE-WEB-APP-4S7 'Database is not opened'
      // on ./src/data/collective/stores/browserbase-store.ts in getChanges at line 106:53
      // line 106: `return this.getChangesQuery(projectId, options).getAll();`
      // alternative method is a try/catch block around this.store.getChanges and a graceful return/exit
      this.store
        .getChanges(projectId, { before, after })
        .then(changes => {
          const remoteChanges = changes.slice(0, remoteCount);
          const rebased = changes.slice(remoteCount);
          const event = { changes, remoteChanges, rebased } as ReceiveChangesEvent;
          this.dispatchEvent('receiveChanges', event);
        })
        .catch(error => {
          console.log(error);
          throw new CollectiveDBError(
            'DATA_LOAD',
            'Error loading the latest changes from the store for "' + projectId + '": ' + error.message
          );
          // can we (or should we) open the database here and retry?
        });
    }
  };
}

function updateSnapshot<Project extends Snapshot>(projectId: string, snapshot: Project, lastChange: Change) {
  const { id: changeId, committed, version } = lastChange;
  return { ...snapshot, id: projectId, changeId, committed, version };
}

function validateChanges(changes: Change[], projectId: string) {
  const firstVersion = changes[0].version;
  for (let i = 0; i < changes.length; i++) {
    if (changes[i].version !== firstVersion + i) {
      throw new CollectiveError(
        'RECEIVED_CHANGES_INVALID',
        'Remote changes are sparse or unsorted, missing version ' + (firstVersion + i) + ' for project ' + projectId
      );
    }
    if (!changes[i].committed) {
      throw new CollectiveError(
        'RECEIVED_CHANGES_UNCOMMITTED',
        'Remote changes are not committed in project ' + projectId
      );
    }
    if (changes[i].projectId !== projectId) {
      throw new CollectiveError(
        'RECEIVED_CHANGES_INVALID',
        'Remote changes’ projectId do not match the project ' + projectId
      );
    }
  }
}
