<script>
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiClose } from '@mdi/js';

  export let section;
  export let commands;
  // leave these in even though they aren't used to keep warnings from screen.
  export let editor;
  export let active;
  export let placement;
  export let selection;

  const colors = ['yellow', 'purple', 'teal', 'green', 'blue', 'black'];

  function highlight(color) {
    commands.highlight(color);
    section = 'main';
  }
</script>

{#if section === 'highlight'}
  <div class="highlight-section">
    {#each colors as color}
      <button class="swatch-btn" on:click={() => highlight(color)}>
        <div class="swatch {color}" />
      </button>
    {/each}
    <button class="swatch-btn" on:click={() => highlight(null)}>
      <div class="swatch cancel" />
    </button>
    <div class="spacer" />
    <button on:click={() => (section = 'main')} class="closer"><Icon path={mdiClose} /></button>
  </div>
{/if}

<style>
  .highlight-section {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  button.swatch-btn {
    padding: 3px;
  }
  .swatch {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid #222222;
    box-shadow: 0 0 0 1px #929292;
    background: #444;
  }
  .spacer {
    flex: 1;
  }
  :global(.menu) button.closer {
    color: #f7f7f9;
    opacity: 0.5;
  }
  .closer:hover {
    color: #f7f7f9;
    opacity: 1;
  }

  .swatch.yellow {
    background-color: #fff697;
  }
  .swatch.purple {
    background-color: #f5c4ff;
  }
  .swatch.teal {
    background-color: #adedfc;
  }
  .swatch.green {
    background-color: #d4eabc;
  }
  .swatch.blue {
    background-color: #a6c3f0;
  }
  .swatch.black {
    background-color: var(--text-color-normal);
  }
  .swatch.cancel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-image: linear-gradient(45deg, var(--gray-light) 25%, transparent 25%),
      linear-gradient(-45deg, var(--gray-light) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, var(--gray-light) 75%),
      linear-gradient(-45deg, transparent 75%, var(--gray-light) 75%);
    background-size: 8px 8px;
    background-position: 0 0, 0 4px, 4px -4px, -4px 0px;
  }
  .swatch.cancel::before {
    content: '';
    display: block;
    height: 4px;
    width: 200%;
    background-color: var(--dabble-red);
    transform: rotate(-42deg);
  }
</style>
