<script>
  import { sidebar as openSidebar } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipBottom } from './tooltip';

  let className = '';
  export { className as class };
  export let icon;
  export let sidebar = undefined;
  export let tooltip;
  export let selected = undefined;

  $: if (sidebar) selected = $openSidebar === sidebar;

  function onClick(event) {
    if (sidebar) {
      $openSidebar = sidebar;
    }
    event.target.blur();
  }
</script>

<button
  class="icon{className ? ` ${className}` : ''}"
  class:selected
  use:tooltipBottom={tooltip}
  on:click
  on:click={onClick}
>
  {#if selected}
    <div class="selected-marker" />
  {/if}
  <Icon path={icon} />
</button>

<style>
  :global(.size-desktop) button.icon.selected:not([disabled]),
  :global(.size-desktop) button.icon.selected:not([disabled]):hover,
  :global(.size-desktop) button.icon.selected:not([disabled]):focus {
    color: var(--dabble-blue);
  }
  button.selected {
    cursor: default;
  }
  .selected-marker {
    position: absolute;
    width: 4px;
    border-radius: 0 3px 3px 0;
    top: 0;
    left: -1px;
    height: 100%;
    background-color: var(--dabble-blue);
  }
</style>
