<script lang="ts">
  import { viewport } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiRedo, mdiUndo } from '@mdi/js';
  import { editorStores } from 'typewriter-editor';

  const selectedEditor = viewport.selectedEditor;
  const { active, updateEditor } = editorStores($selectedEditor);

  $: updateEditor($selectedEditor);
</script>

<button class="icon" disabled={!$active.undo} on:click={() => $selectedEditor.commands.undo()}>
  <Icon path={mdiUndo} />
</button>
<button class="icon" disabled={!$active.redo} on:click={() => $selectedEditor.commands.redo()}>
  <Icon path={mdiRedo} />
</button>
