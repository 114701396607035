<script>
  import { autoselect } from '@dabble/toolkit/autoselect';
  import { escKey } from '@dabble/toolkit/events';
  import { tooltipBottom } from '@dabble/toolkit/tooltip';
  import { mdiCloseCircle, mdiMagnify } from '@mdi/js';
  import Icon from './Icon.svelte';

  export let tooltip = '';
  export let value = '';
  export let placeholder = '';
  export let icon = mdiMagnify;
  let className = '';
  export { className as class };
  let input;

  function clear() {
    input.value = '';
    input.focus();
    input.dispatchEvent(new InputEvent('input'));
    input.dispatchEvent(new Event('change'));
  }
</script>

<div class="filter-input">
  <input
    type="text"
    class="form-control{className ? ` ${className}` : ''}"
    {placeholder}
    on:input
    on:change
    bind:this={input}
    bind:value
    use:tooltipBottom={tooltip}
    use:autoselect
    use:escKey={clear}
  />
  <div class="search-icon">
    <Icon path={icon} />
  </div>
  {#if value}
    <button class="clear-btn"><Icon path={mdiCloseCircle} on:click={clear} /></button>
  {/if}
</div>

<style>
  .filter-input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  input {
    font-size: 14px;
    border-radius: 30px;
    padding: 4px 18px 4px 24px;
    flex: 1;
    min-width: 0;
  }
  .search-icon,
  .clear-btn {
    position: absolute;
    display: flex;
    align-items: center;
  }
  .search-icon {
    left: 6px;
    font-size: var(--font-size-base);
    color: var(--text-color-lighter);
  }
  .clear-btn {
    right: 6px;
    display: inline-flex;
    justify-content: center;
    appearance: none;
    --webkit-appearance: none;
    background: transparent;
    margin: 0;
    padding: 2px;
    font-size: var(--font-size-base);
    outline: none;
    border: 0;
    color: var(--text-color-lightest);
    cursor: pointer;
  }
</style>
