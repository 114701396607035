<script>
  import { editingMode, features, getUrl } from '@dabble/app';
  import NavItemMenu from '@dabble/components/workspace/NavItemMenu.svelte';
  import Avatar from '@dabble/plugins/core/components/Avatar.svelte';
  import Banner from '@dabble/plugins/core/components/Banner.svelte';
  import ContextMenu from '@dabble/toolkit/ContextMenu.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import ItemActions from './ItemActions.svelte';

  export let doc;
  let header;
  $: readonly = !$features.has('story_notes') || $editingMode === 1;

  function editTitle() {
    header.parentNode.querySelector('.title .typewriter-editor:has(h2)')?.focus();
  }
</script>

<div data-id={doc && doc.id} class="character">
  <a class="header" href={getUrl(doc)} bind:this={header}>
    <div class="header-image">
      <Banner {doc} />
    </div>
    {#if doc.type === 'novel_character'}
      <div class="avatar">
        <Avatar {doc} />
      </div>
    {/if}
  </a>
  <EditableContent {doc} field="title" header="h2" class="title" {readonly} />
  <EditableContent {doc} field="subtitle" header="h3" class="subtitle" {readonly} />
  <ItemActions {doc} on:editTitle={editTitle} />
</div>

<ContextMenu target={header} activeClass="menu-open">
  <NavItemMenu {doc} on:editTitle={editTitle} />
</ContextMenu>

<style>
  .character {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 16rem;
    max-height: 16rem;
    border-radius: 0.5rem;
    background: var(--white);
    padding: 1rem;
    text-align: center;
    overflow: hidden;
    box-shadow: var(--flat-box-shadow);
  }
  .header {
    display: flex;
    flex-direction: column;
    margin: -1rem;
    margin-bottom: 0;
  }
  .header:hover {
    text-decoration: none;
  }
  .header-image {
    aspect-ratio: 3 / 1;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: rgb(var(--neutral-300));
    overflow: hidden;
  }
  :global(html[data-theme='dark']) .header-image {
    background-color: rgb(var(--neutral-500));
  }
  .avatar {
    position: relative;
    width: 6rem;
    align-self: center;
  }
  .character :global(.title h2),
  .character :global(.title h3) {
    color: var(--text-color-light);
    font-family: var(--font-family-header);
    font-weight: normal;
  }
  .character :global(.title h2) {
    font-size: 2em;
    margin: 0;
    padding: 1rem 0 0.5rem;
    padding-bottom: 0;
    font-size: 1.375rem;
    color: var(--text-color-light);
    font-weight: bold;
  }
  .character :global(.subtitle h3) {
    margin: 0;
    padding: 0.5rem 0 1rem;
    font-size: 1rem;
    color: var(--text-color-lightest);
  }
</style>
