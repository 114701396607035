import { ConnectionStore, DocumentData, Where, Wheres } from '@dabble/data/connection';
import { getNow } from '@dabble/data/date';
import { DabbleDatabase } from '@dabble/data/types';
import logging from '@dabble/util/log';
import { ObjectStore, StoreChangeDetail } from 'browserbase';
import { SyncingStore } from '../stores/syncing';
import { ErrorReporter, SyncStep } from '../types';

export type LastModifiedFormatter = (
  local: DocumentData,
  remote: DocumentData,
  saveTo: 'remote' | 'local'
) => DocumentData;

interface LastModifiedSyncData {
  db: DabbleDatabase;
  connection: ConnectionStore;
  fromRemote: Set<any>;
  syncing: SyncingStore;
}

interface LastModifiedSyncOptions {
  filters?: Wheres;
  formatter?: LastModifiedFormatter;
  onRemoteLoad?: (record: any) => any;
  onRemoteSave?: (record: any, committed: number, localRecords: Cache) => any;
  onRemoteForbidden?: (id: string, err: Error) => any;
  forceSync?: (store: ObjectStore) => Promise<DocumentData[]>;
  merge?: boolean;
  firstFilter?: Wheres;
  getIds?: () => string[];
  sort?: (a: any, b: any) => number;
  storeName?: string;
}

type KeyName = string | string[];
type Key = string; // This can be an array but will be cast to a string in all cases where needed
type Cache = { [key: string]: DocumentData };

const log = logging.tagColor('Sync', '#36B1BF');
const defaultFormatter: LastModifiedFormatter = (local, remote, saveTo) => (saveTo === 'local' ? remote : local);

// Keeps local and remote collections in sync using the "modified" field to know when to upload or download data.
export default function syncLastModifiedStep(
  collectionName: string,
  data: LastModifiedSyncData,
  options: LastModifiedSyncOptions = {}
): SyncStep {
  const { db, connection, fromRemote, syncing } = data;
  let since = 0;
  const filters = options.filters || [];
  const formatter = options.formatter || defaultFormatter;

  const storeName = options.storeName || collectionName;
  const store = (db.stores as any)[storeName] as ObjectStore;
  const keys = store.keyPath as KeyName;

  const getKey = getKeyGetter(keys);

  const SINCE_ID = `${collectionName}_since`;

  const localRecords: Cache = {};
  const remoteRecords: Cache = {};
  const localFilter = createLocalQuery(filters);
  const deletedFilter: Wheres = [['deleted', 'unset']];

  return { sync, start, name: `syncLastModifiedStep:${collectionName}` };

  async function sync() {
    try {
      const sinceRecord = await db.stores.other.get(SINCE_ID);
      since = (sinceRecord && sinceRecord.since) || 0;
    } catch (err) {
      console.error(err);
    }

    // Don't alter the original, sync may be called again.
    let syncFilters = filters;
    if (since) {
      syncFilters = syncFilters.concat([
        ['modified', '>', since],
        ['orderBy', 'modified'],
      ]);
    } else {
      // This will be applied afterwards so may be added with other filters
      syncFilters = syncFilters.concat(deletedFilter);
      if (options.firstFilter) syncFilters = syncFilters.concat(options.firstFilter);
    }

    // eslint-disable-next-line prefer-const
    let [docs, rows] = (await Promise.all([
      getDocs(syncFilters),
      store.where('modified').startsAfter(since).getAll(),
    ])) as [DocumentData[], DocumentData[]];

    if (options.forceSync) {
      rows.push(...(await options.forceSync(store)));
    }

    if (options.onRemoteLoad) {
      docs = docs.filter(doc => options.onRemoteLoad(doc) !== false);
    }

    // Use the same keyspace for both for correct comparison
    docs.forEach(doc => (remoteRecords[getKey(doc)] = doc));
    rows.forEach(local => local && (localRecords[getKey(local)] = local));

    // First time sync, there are no local records, optimize by adding all at once
    if (!since && !rows.length) {
      if (options.sort) docs.sort(options.sort);
      await syncAll(remoteRecords);
    } else {
      const all = Object.keys({ ...localRecords, ...remoteRecords });
      if (options.sort) all.sort(options.sort);

      await Promise.all(
        all.map(key => {
          const local = localFilter(localRecords[key]) && localRecords[key];
          const remote = remoteRecords[key];
          return syncOne(local, remote, key);
        })
      );
    }

    const newest = Math.max(...Object.values(remoteRecords).map(r => r.modified));
    if (newest > 0) updateSince(newest);
  }

  function start(report: ErrorReporter) {
    const handleLocal = ({ detail: { obj, key } }: CustomEvent<StoreChangeDetail>) =>
      onLocalChange(obj, key).catch(report);
    store.addEventListener('change', handleLocal);
    const remoteCancel = connection.listen(({ collection, data }) => {
      if (collection === collectionName) {
        onRemoteChange(data).catch(report);
      }
    });

    return () => {
      remoteCancel();
      store.removeEventListener('change', handleLocal);
    };
  }

  async function onRemoteChange(remote: DocumentData) {
    const key = getKey(remote);
    const local = localRecords[key];
    remoteRecords[key] = remote;
    if (options.onRemoteLoad && options.onRemoteLoad(remote) === false) {
      return;
    }
    if (local && local.modified === remote.modified) {
      return; // Assume already synced
    }
    const modified = await syncOne(local, remote, key);
    if (remote.deleted && local) {
      await store.delete(key);
    }

    // Set the local "since" for next reload
    await updateSince(modified);
  }

  async function onLocalChange(local: any, key: Key) {
    if (localFilter && !localFilter(local)) return;
    if (fromRemote.has(local)) {
      remoteRecords[key] = local;
      return;
    }

    if (local === null) {
      // Deleted locally, remove from local and remote
      delete localRecords[key];
      delete remoteRecords[key];
      return;
    }

    if (localRecords[key] && local && localRecords[key].modified === local.modified) {
      return;
    }

    const remote = remoteRecords[key];
    localRecords[key] = local;
    if (!local && !remote) return;

    const modified = await syncOne(local, remote, key);

    // Set the local "since" for next reload
    await updateSince(modified);
  }

  function updateSince(newSince: number) {
    if (newSince < since) return;
    since = Math.min(newSince, getNow());
    return db.stores.other.put({ id: SINCE_ID, since });
  }

  function getDocs(syncFilters: Wheres) {
    if (options && options.getIds) {
      const ids = options.getIds();
      return Promise.all(
        ids.map(id =>
          connection
            .getDocs(collectionName, [['id', '==', id], ...syncFilters])
            .then(result => result[0])
            .catch(err => {
              if (err.message === 'FORBIDDEN' && options.onRemoteForbidden) {
                options.onRemoteForbidden(id, err);
              } else {
                return Promise.reject(err);
              }
            })
        )
      ).then(all => all.filter(Boolean));
    } else {
      return connection.getDocs(collectionName, syncFilters);
    }
  }

  async function syncAll(remote: Cache) {
    const keys = Object.keys(remote);
    const objs = keys.map(key => formatter(null, remote[key], 'local'));

    // Update local from newer remote version
    log(`Updating local ${storeName}`, keys);
    syncing.down(true);
    try {
      await store.putAll(objs);
      objs.forEach((obj, i) => {
        db.dispatchChange(store, obj, keys[i], 'remote');
      });
    } catch (err) {
      console.error(err);
    }
    syncing.down(false);
  }

  async function syncOne(local: DocumentData, remote: DocumentData, localKey: Key) {
    if (!local && !remote) return local.modified as number;
    const modified: number = Math.max((local && local.modified) || 0, (remote && remote.modified) || 0);

    if ((local && local.deleted) || (remote && remote.deleted)) {
      if (!local || !local.deleted) {
        // The remote is marked deleted, remove locally
        log(`Deleting local ${storeName}`, localKey);
        syncing.down(true);
        try {
          await store.delete(localKey);
          db.dispatchChange(store, null, localKey, 'remote');
        } catch (err) {
          console.error(err);
        }
        syncing.down(false);
      } else if (!remote || !remote.deleted) {
        // The local is marked deleted, update the remote to also be marked
        log(`Marking remote ${storeName} deleted`, localKey);
        const remoteKey = getKey(local);
        const remoteObj = formatter(local, remote, 'remote');
        syncing.up(true);
        try {
          await connection.putDoc(collectionName, remoteObj, options.merge && { merge: true });
          // then delete locally on success
        } catch (err) {
          console.error('Could not update remote', `${collectionName}/${remoteKey}`, err);
        }
        try {
          log(`Deleting local ${storeName} after remote marked`, localKey);
          await store.delete(localKey);
          db.dispatchChange(store, null, localKey, 'remote');
        } catch (err) {
          console.error(err);
        }
        syncing.up(false);
      }
    } else if (!local || (remote && local.modified < remote.modified)) {
      // Update local from newer remote version
      log(`Updating local ${storeName}`, localKey);
      const localObj = formatter(local, remote, 'local');
      syncing.down(true);
      try {
        await store.put(localObj);
        db.dispatchChange(store, localObj, localKey, 'remote');
      } catch (err) {
        console.error(err);
      }
      syncing.down(false);
    } else if (!remote || remote.modified < local.modified) {
      // Update remote from newer local version
      log(`Updating remote ${storeName}`, localKey);
      const remoteKey = getKey(local);
      const remoteObj = formatter(local, remote, 'remote');
      syncing.up(true);
      try {
        const result = await connection.putDoc(collectionName, remoteObj, options.merge && { merge: true });
        if (options.onRemoteSave) {
          await options.onRemoteSave(local, result, localRecords);
        }
      } catch (err) {
        console.error('Could not update remote', `${collectionName}/${remoteKey}`, remoteObj, err);
      }
      syncing.up(false);
    }

    return modified;
  }
}

function getKeyGetter(keys: KeyName): (obj: DocumentData) => Key {
  if (typeof keys === 'string') return obj => obj[keys];
  return obj => keys.map(key => obj[key]) as any as string; // it's fine, don't worry about it
}

function createLocalQuery(wheres: Wheres): (obj: DocumentData) => boolean {
  const filters = wheres.filter(item => item[0] !== 'orderby' && item[0] !== 'limit') as Where[];
  if (!filters.length) return () => true;

  return obj =>
    obj &&
    filters.every(filter => {
      const [prop, comparator, constraint] = filter;
      return comparators[comparator](obj[prop], constraint);
    });
}

const comparators = {
  '==': (value: any, constraint: any) => value === constraint,
  '!=': (value: any, constraint: any) => value !== constraint,
  '<': (value: any, constraint: any) => value < constraint,
  '>': (value: any, constraint: any) => value > constraint,
  '<=': (value: any, constraint: any) => value <= constraint,
  '>=': (value: any, constraint: any) => value >= constraint,
  'array-contains': (value: Array<any>, constraint: any) => Array.isArray(value) && value.includes(constraint),
  in: (value: any, constraint: Array<any>) => Array.isArray(constraint) && constraint.includes(value),
  'not-in': (value: any, constraint: Array<any>) => Array.isArray(constraint) && !constraint.includes(value),
  'array-contains-any': (value: Array<any>, constraint: Array<any>) =>
    Array.isArray(value) && Array.isArray(constraint) && value.some(value => constraint.includes(value)),
};
