import { documents, features, observe, plugins, preferences, settings } from '@dabble/app';
import { derived, readable } from '@dabble/data/stores/store';
import ThemePreferences from './components/ThemePreference.svelte';
import './dark.css';
import './locales';

type Theme = 'default' | 'dark' | 'auto';

settings.configure('dabble', {
  plugins: {
    account: {
      preferences: {
        themes: ThemePreferences,
      },
    },
  },
});

export const theme = derived([preferences, features], ([preferences, features]): Theme => {
  let theme: Theme = 'default';
  if (!features.size || !preferences.theme) {
    theme = preferences.theme || localStorage.theme || 'default';
  } else {
    theme = ((features.has('dark_mode') && preferences.theme) || 'default') as Theme;
  }
  if (theme !== localStorage.theme) localStorage.theme = theme;
  return theme;
});

const prefersDarkScheme = readable(false, set => {
  const media = matchMedia('(prefers-color-scheme: dark)');
  const update = () => set(media.matches);
  media.addEventListener('change', update);
  update();
  return () => media.removeEventListener('change', update);
});

export const uiTheme = derived([theme, prefersDarkScheme], ([theme, prefersDark]) => {
  return theme === 'dark' || (theme === 'auto' && prefersDark) ? 'dark' : 'light';
});

plugins.register({ theme, uiTheme });

observe([documents, uiTheme], ([documents, uiTheme]) => {
  documents.forEach(document => (document.documentElement.dataset.theme = uiTheme));
});
