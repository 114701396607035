import { getImmutable, getImmutableValue, makeChanges } from '@dabble/util/immutable';
import { StoreChangeDetail } from 'browserbase';
import { subDays } from 'date-fns';
import { isEqual } from 'typewriter-editor';
import { ChangeProjectEvent, Collective } from '../collective/collective';
import { DatabaseStore } from '../dabble-database';
import { getDate, getDateString, getNow } from '../date';
import { DabbleDatabase, Project, UserDocStats, UserStatsByDate } from '../types';
import { ProjectData, ProjectStore } from './project';
import { Readable, Unsubscriber, writable } from './store';

export interface UserStats {
  [date: string]: {
    [projectId: string]: UserStatsByDate;
  };
}

export interface UserStatsStore extends Readable<UserStats> {
  getForDay(docId: string, date?: string): number;
}

export function createUserStatsStore(
  dbStore: DatabaseStore,
  collective: Collective<Project>,
  projectStore: ProjectStore
): UserStatsStore {
  let db: DabbleDatabase;
  let stats: UserStats = null;
  let unsubscribe: Unsubscriber;
  let oldProject: ProjectData;
  const { get, set, subscribe } = writable<UserStats>(stats);

  dbStore.register(async value => {
    if (db) {
      set((stats = null));
      db.stores.user_stats_by_date.removeEventListener('change', onChange);
      collective.off('changeProject', onChangeProject);
      unsubscribe();
    }

    db = value;

    if (db) {
      unsubscribe = projectStore.subscribe(onProject);
      const since = getDateString(subDays(getDate(), 30));
      const statsRecords = await db.stores.user_stats_by_date.where().startsAt([since]).getAll();
      db.stores.user_stats_by_date.addEventListener('change', onChange);
      collective.on('changeProject', onChangeProject);
      set((stats = applyStatsRecords(null, statsRecords)));
    }
  });

  function onProject(project: ProjectData) {
    if (
      stats &&
      oldProject.projectId &&
      oldProject.projectId === project.projectId &&
      oldProject.counts !== project.counts &&
      !projectStore.changesJustReceived
    ) {
      stats = applyWordcountChanges(stats, oldProject, project);
      set(stats);
    }
    oldProject = project;
  }

  function getForDay(docId: string, date: string = getDateString()) {
    const { project } = projectStore.get();
    const doc = project && (project.id === docId ? project : project.docs[docId]);
    if (!stats || (docId && !doc)) return 0;

    // Count all word count changes across all projects
    if (!docId) {
      let change = 0;
      const dateStats = stats[date];
      dateStats &&
        Object.values(dateStats).forEach(projectStats => {
          change += projectStats.change || 0;
        });
      return change;
    }

    const dateStats = stats[date];
    const projectStats = dateStats && dateStats[project.id];
    const docStats = projectStats && (projectStats[doc.id] as UserDocStats);

    if (!docStats && doc.children) {
      let change = 0;
      doc.children.forEach(childId => {
        change += getForDay(childId, date);
      });
      return change;
    } else {
      return (docStats && docStats.change) || 0;
    }
  }

  function onChange(event: CustomEvent<StoreChangeDetail<UserStatsByDate, [string, string]>>) {
    const { obj: record, declaredFrom } = event.detail;
    if (!stats || !record || declaredFrom === 'local') return;
    set((stats = applyStatsRecord(stats, record)));
  }

  // Update totals and changes from your text changes
  async function onChangeProject(event: ChangeProjectEvent<Project>, from: 'local' | 'remote') {
    if (from !== 'local') return;
    const date = getDateString();
    const projectId = event.snapshot.id;
    const record = await db.stores.user_stats_by_date.get([date, projectId]);
    const todaysProjectStats = stats[date]?.[projectId];
    if (!isEqual(todaysProjectStats, record)) {
      todaysProjectStats.modified = getNow();
      db.stores.user_stats_by_date.put(todaysProjectStats);
    }
  }

  return {
    getForDay,
    get,
    subscribe,
  };
}

function applyStatsRecords(stats: UserStats, records: UserStatsByDate[]) {
  return makeChanges(() => {
    return records.reduce((stats, record) => {
      return applyStatsRecord(stats, record);
    }, stats || {});
  });
}

function applyStatsRecord(stats: UserStats, record: UserStatsByDate) {
  const { date, projectId } = record;
  stats = getImmutableValue(stats);
  const day = getImmutable(stats, date, () => ({ change: 0 }));
  const oldProjectChange = (day[projectId] && day[projectId].change) || 0;
  day.change += record.change - oldProjectChange;
  day[projectId] = record;
  return stats;
}

function applyWordcountChanges(stats: UserStats, oldProject: ProjectData, project: ProjectData) {
  const oldCounts = oldProject.counts;
  const deleted = Object.keys(oldCounts).filter(
    docId => !(docId in project.counts) && !oldProject.inTrash[docId] && oldProject.docs[docId].body
  );
  const trashed = new Set(Object.keys(oldCounts).filter(docId => !oldProject.inTrash[docId] && project.inTrash[docId]));
  const restored = new Set(
    Object.keys(oldCounts).filter(docId => oldProject.inTrash[docId] && !project.inTrash[docId])
  );

  makeChanges(() => {
    const projectId = project.projectId;
    const date = getDateString();
    stats = { ...stats };
    const day = getImmutable(stats, date, () => ({ change: 0 }));
    const projectStats = getImmutable(day, projectId, () => ({
      date,
      projectId,
      modified: 0,
      change: 0,
      total: oldCounts[projectId].wordCount,
    }));

    const changedMap = new Map<string, [number, number, boolean]>();
    Object.keys(project.counts).forEach(docId => {
      const isSource = hasBody(project, docId) || hasBody(oldProject, docId);
      const newTotal = project.counts[docId].wordCount;
      const oldTotal = oldCounts[docId] ? oldCounts[docId].wordCount : 0;
      let change = newTotal - oldTotal;

      if (trashed.has(docId)) {
        change = -oldTotal;
      } else if (restored.has(docId)) {
        change = newTotal;
      }
      changedMap.set(docId, [newTotal, change, isSource]);
    });

    deleted.forEach(docId => {
      const isSource = 'body' in oldProject.texts.textWithQueued[docId];
      changedMap.set(docId, [0, -oldCounts[docId].wordCount, isSource]);
    });

    const changedItems = new Set<string>();

    changedMap.forEach(([, change], docId) => {
      if (!change || changedItems.has(docId)) return;
      while (docId || changedItems.has(docId)) {
        changedItems.add(docId);
        docId = project.parentsLookup[docId]?.id;
      }
    });

    changedItems.forEach(docId => {
      updateChangeForDoc(docId, ...changedMap.get(docId));
    });

    function updateChangeForDoc(docId: string, total: number, change: number, isSource: boolean) {
      const docStats = getImmutable(projectStats, docId, () => ({ change: 0, total }));
      docStats.change += change;
      docStats.total = total; // not +=
      if (isSource) {
        projectStats.change += change;
        projectStats.total += change;
        day.change += change;
      }
    }
  });

  return stats;
}

function hasBody(project: ProjectData, docId: string) {
  const queuedText = project.texts.textWithQueued[docId];
  return queuedText && 'body' in queuedText;
}
