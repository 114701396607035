import {
  $t,
  alert,
  dailyGoalStore,
  daysOffStore,
  dbStore,
  parseDateString,
  preferences,
  projectGoalStore,
  projectStore,
  shownModal,
  syncing,
  syncReady,
  today,
  todaysDate,
  userStats,
} from '@dabble/app';
import { getDateString } from '@dabble/data/date';
import { observe } from '@dabble/data/observe';
import { derived } from '@dabble/data/stores/store';
import { DabbleDatabase, DaysOff, UserGoal } from '@dabble/data/types';
import interactions from '@dabble/toolkit/interactions';
import { addDays, differenceInDays, endOfDay, isSameDay } from 'date-fns';
import { tick } from 'svelte';
import { isDayOff } from './days-off';

export const last30For = derived(preferences, preferences => preferences.last30For || 'project');
export const target = derived([projectGoalStore, projectStore], ([projectGoalStore, project]) => {
  return !project.currentVersion && projectGoalStore.targetId
    ? project.project && project.docs[projectGoalStore.targetId]
    : null;
});
export const userTarget = derived(projectStore, project => {
  return project && project.docs && project.docs[project.projectId];
});
export const wordcount = derived(
  [target, projectStore],
  ([target, project]) => (target && project.counts[target.id].wordCount) || 0
);
export const dailyWordcount = derived(
  [projectStore, userTarget],
  ([project, userTarget]) =>
    (userTarget && project.counts[userTarget.id] && project.counts[userTarget.id].wordCount) || 0
);
export const daysLeft = derived([projectGoalStore, today], ([projectGoalStore, today]) =>
  !projectGoalStore || !projectGoalStore.deadline
    ? 0
    : Math.max(0, differenceInDays(parseDateString(projectGoalStore.deadline), today) + 1)
);
export const workingDaysLeft = derived(
  [projectGoalStore, dailyGoalStore, daysOffStore, today],
  ([projectGoalStore, dailyGoalStore, daysOffStore, today]) =>
    calculateWorkingDaysLeft(projectGoalStore, dailyGoalStore, daysOffStore, today)
);
export const startingCount = derived(
  [projectGoalStore],
  ([projectGoalStore]) => (projectGoalStore && projectGoalStore.start) || 0
);
export const totalGoal = derived([projectGoalStore], ([projectGoalStore]) =>
  Math.max(0, (projectGoalStore && projectGoalStore.end) || 0)
);
export const totalCount = derived([wordcount, startingCount], ([wordcount, startingCount]) =>
  Math.max(0, wordcount - startingCount)
);
export const dailyGoal = derived(dailyGoalStore, goal => goal.end);
export const countBeforeToday = derived(
  [target, projectGoalStore, todaysDate, wordcount],
  ([target, goal, todaysDate, start]) => {
    if (!target) return 0;
    if (goal.today?.date === todaysDate) return goal.today.start;
    // The first time a user has stats for today, goal.today will be set/updated to today's date
    const todaysUserCount = userStats.getForDay(target.id, todaysDate);
    if (todaysUserCount) {
      start -= todaysUserCount;
      // set the today object, but do it async so we don't get into an infinite loop
      tick().then(() => projectGoalStore.update({ today: { date: todaysDate, start } }));
    }
    return start;
  }
);
export const todaysCount = derived([countBeforeToday, wordcount], ([start, wordcount]) => wordcount - start);

// ************ calculate the number of words across all projects for today. Don't go below zero AND after deletions from previous days
// ************ (when actual count goes below zero), all future typing for the day counts towards the goal. I.E. author doesn't have to
// ************ type to make up words deleted.
let lastCount = 0;
let currentCount = 0;
let deltaCount = 0;
export const daysDeltaCount = derived([todaysDate, dailyGoalStore, dailyWordcount], ([date, dailyGoal]) => {
  if (dailyGoal && dailyGoal.end && dailyGoal.today?.date !== date) {
    lastCount = 0;
    currentCount = 0;
    deltaCount = 0;
    tick().then(() => dailyGoalStore.update({ today: { date: date, start: 0 } }));
  }
  currentCount = userStats.getForDay(null, date);
  if (currentCount < lastCount) {
    lastCount = currentCount;
  }
  if (currentCount >= 0) {
    lastCount = 0;
  }
  deltaCount = currentCount - lastCount;
  return deltaCount;
});

export const totalTowardsDailyGoal = derived(daysDeltaCount, deltaCount => Math.max(0, deltaCount));

export const countLeftBeforeToday = derived(
  [totalGoal, startingCount, countBeforeToday],
  ([totalGoal, startingCount, countBeforeToday]) => Math.max(0, totalGoal + startingCount - countBeforeToday)
);
export const todaysGoal = derived(
  [countLeftBeforeToday, workingDaysLeft, daysOffStore],
  ([countLeftBeforeToday, workingDaysLeft, daysOffStore]) => {
    if (isDayOff(daysOffStore.daysOff, today.get())) return 0;
    return Math.ceil(
      Math.max(
        0,
        countLeftBeforeToday && workingDaysLeft ? countLeftBeforeToday / workingDaysLeft : countLeftBeforeToday
      )
    );
  }
);

observe(
  [target, totalCount, projectGoalStore, dailyGoalStore, daysOffStore, dailyGoal, totalTowardsDailyGoal],
  ([target, totalCount, projectGoalStore, dailyGoalStore, daysOffStore, dailyGoal, totalTowardsDailyGoal]) =>
    target && checkForCelebration(totalCount, projectGoalStore, dailyGoalStore, daysOffStore)
);

// Set up the initial global days off store after finishing syncing (to make sure we don't overwrite it)
let synced = false;
observe(
  [dbStore, daysOffStore, daysOffStore.isLoaded, syncing, syncReady],
  async ([db, daysOff, isLoaded, syncing, syncReady]) => {
    if (db && syncing) {
      synced = true;
    } else if (db && synced && syncReady && isLoaded) {
      // once we started syncing and then stopped, we know we are synced
      updateDaysOff(db, daysOff);
    }
  }
);

function calculateWorkingDaysLeft(
  projectGoalStore: UserGoal,
  dailyGoalStore: UserGoal,
  daysOffStore: UserGoal,
  today: Date
) {
  if (!projectGoalStore || !projectGoalStore.deadline) return 0;
  const deadline = endOfDay(parseDateString(projectGoalStore.deadline));
  let day = today;
  let workingDays = 0;
  while (day < deadline) {
    // Is this day off or not
    if (!isDayOff(daysOffStore.daysOff, day) || isSameDay(day, today)) {
      workingDays++;
    }
    day = addDays(day, 1);
  }
  return workingDays;
}

function checkForCelebration(
  totalCount: number,
  $projectGoalStore: UserGoal,
  $dailyGoalStore: UserGoal,
  $daysOffStore: UserGoal
) {
  if (!$projectGoalStore && !$dailyGoalStore) return false;
  if (isDayOff($daysOffStore.daysOff, today.get())) return false;
  const date = getDateString();
  const goalWin = `${date}+`;
  const dayWin = `${date}`;
  const $target = target.get();
  const $dailyGoal = dailyGoal.get();
  const $totalTowardDailyGoal = totalTowardsDailyGoal.get();
  const $todaysGoal = todaysGoal.get();
  const $todaysCount = todaysCount.get();
  const $totalGoal = totalGoal.get();

  // If you hit today's goal, celebrate your win! If you hit your goal, celebrate that!!
  if ($target && $dailyGoal) {
    if ($dailyGoal <= $totalTowardDailyGoal && (!$dailyGoalStore.lastWin || $dailyGoalStore.lastWin < dayWin)) {
      runAfterTyping(celebrateDailyGoal);
      dailyGoalStore.update({ lastWin: dayWin });
    }
  }

  if ($target && $todaysGoal && (!$projectGoalStore.lastWin || $projectGoalStore.lastWin < goalWin)) {
    if (totalCount >= $totalGoal) {
      runAfterTyping(celebrate);
      projectGoalStore.update({ lastWin: goalWin });
    } else if ($todaysCount >= $todaysGoal && (!$projectGoalStore.lastWin || $projectGoalStore.lastWin < dayWin)) {
      runAfterTyping(miniCelebrate);
      projectGoalStore.update({ lastWin: dayWin });
    }
  }
}

function miniCelebrate() {
  alert($t('goals_daily_achieve_title'), $t('goals_daily_achieve_body'), { noFocus: true });
}

function celebrate() {
  shownModal.set('goalCelebration');
}

function celebrateDailyGoal() {
  shownModal.set('dailyGoalCelebration');
}

function runAfterTyping(method: () => void) {
  let timeout: any;
  const reset = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      interactions.off('keydown', reset);
      method();
    }, 4000);
  };
  interactions.on('keydown', reset);
  reset();
}

async function updateDaysOff(db: DabbleDatabase, daysOff: UserGoal) {
  if (db && !daysOff.daysOff) {
    let goals: UserGoal[] = db && (await db.stores.user_goals.getAll());
    if (goals) {
      const mergedDaysOff: DaysOff = {};
      // only get goals that have daysOff specified that have been active in the last month.
      goals = goals
        .filter(
          goal =>
            goal.projectId !== 'daysOff' &&
            goal.daysOff &&
            (!goal.deadline || new Date().getTime() <= new Date(goal.deadline).getTime())
        )
        .sort((g1, g2) => {
          return g1.modified > g2.modified ? -1 : g1.modified < g2.modified ? 1 : 0;
        });

      goals.forEach((goal: UserGoal) => {
        Object.keys(goal.daysOff).forEach((key: string) => {
          mergedDaysOff[key] = goal.daysOff[key];
        });
      });
      daysOff.projectId = 'daysOff';
      daysOff.daysOff = mergedDaysOff;
      await daysOffStore.update(daysOff);
    }
  }
}
