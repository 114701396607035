import { projectStore } from '../app';
import { Doc } from '../data/types';

export const docMime = 'application/json+dabble-doc';
export const mimes = [docMime];

export function addDocToDrag(dataTransfer: DataTransfer, doc: Doc) {
  dataTransfer.setData('application/json+dabble-doc', JSON.stringify(doc));
  dataTransfer.setData(`dabble-type/${doc.type}`, doc.type);
  dataTransfer.setData(
    `dabble-id/${getCaseInsensitiveId(projectStore.get().project.id)}/${getCaseInsensitiveId(doc.id)}`,
    doc.id
  );
}

export function getDropType(dataTransfer: DataTransfer) {
  return (dataTransfer.types.find(mime => mime.startsWith('dabble-type/')) || '').split('/')[1];
}

export function isDropInProject(dataTransfer: DataTransfer) {
  const { project } = projectStore.get();
  if (!project) return false;
  const projectId = (dataTransfer.types.find(mime => mime.startsWith('dabble-id/')) || '').split('/')[1];
  return projectId === getCaseInsensitiveId(project.id);
}

export function isDropDoc(dataTransfer: DataTransfer, doc: Doc | string) {
  const docId = typeof doc === 'string' ? doc : doc && doc.id;
  const { project } = projectStore.get();
  if (!project || !docId) return false;
  const [projectId, id] = (dataTransfer.types.find(mime => mime.startsWith('dabble-id/')) || '').split('/').slice(1);
  if (projectId !== getCaseInsensitiveId(project.id)) {
    return false;
  }

  return id === getCaseInsensitiveId(docId);
}

function getCaseInsensitiveId(id: string) {
  return (
    id &&
    id.replace(/[A-Z]/g, cap => {
      const lower = cap.toLowerCase();
      return lower + lower;
    })
  );
}
