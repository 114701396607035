<script>
  import { dateOptions, t, today } from '@dabble/app';
  import { number } from '@dabble/toolkit/helpers';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { addDays, format, isSameDay } from 'date-fns';

  export let days;
  export let startDay;
  export let max = 0;
  export let tooltip = 'stats_number_of_words';
  const height = 24;
</script>

<div class="wordcount-graph">
  {#each days as day, index}
    <div
      class:today={isSameDay($today, addDays(startDay, index))}
      use:tooltipTop={$t(tooltip, {
        date: format(addDays(startDay, index), 'MMM d', dateOptions),
        count: day,
        number: number(day),
      })}
      class="wordcount-graph-bar"
    >
      <div class="wordcount-graph-background">
        <div class="wordcount-graph-fill" style="height: {(max && Math.ceil((day / max) * height)) || 0}px" />
      </div>
    </div>
  {/each}
</div>

<style>
  .wordcount-graph {
    width: 100%;
    min-width: 206px;
    height: 24px;
    display: flex;
    justify-content: center;
  }
  .wordcount-graph-bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 0 0 auto;
    height: 100%;
    width: 7px;
    padding: 0 1px;
  }
  .wordcount-graph-bar:last-child {
    width: 5px;
    padding-right: 0;
  }
  .wordcount-graph-background {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: 5px;
    height: 100%;
    background: var(--side-nav-color-light);
  }
  .wordcount-graph-fill {
    background: var(--side-nav-color-dark);
  }
  .wordcount-graph-bar.today .wordcount-graph-background {
    background: rgba(var(--dabble-blue-rgb), 0.3);
  }
  .wordcount-graph-bar.today .wordcount-graph-fill {
    background: var(--dabble-blue);
  }
  .wordcount-graph-bar:hover .wordcount-graph-background {
    border: 1px solid var(--side-nav-color-dark);
  }
  .wordcount-graph-bar.today:hover .wordcount-graph-background {
    border: 1px solid var(--dabble-blue);
  }
</style>
