/* eslint-disable @typescript-eslint/no-unused-vars */
export class Globals {
  inform(type: string, title: string, message?: string): Promise<void> {
    return Promise.resolve();
  }
  alert(title: string, message: string, options?: { noFocus?: boolean; fireworks?: boolean, okButtonText?: string }): Promise<void> {
    return Promise.resolve();
  }
  confirm(title: string, message?: string | { yesNo?: boolean }, options?: { yesNo?: boolean }): Promise<boolean> {
    return Promise.resolve(false);
  }
  danger(title: string, message: string, options?: { yesNo?: boolean }): Promise<boolean> {
    return Promise.resolve(false);
  }
  prompt(title: string, message: string, options?: { placeholder?: string }): Promise<string> {
    return Promise.resolve('');
  }
}

export interface ShownCard {
  docId: string;
  target: HTMLElement;
}

export type AppHeaderData = { hideLogo: boolean };
