<script lang="ts">
  import {
    alert,
    confirm,
    createProject,
    creatingProject,
    deleteProject,
    getNow,
    getTitle,
    inform,
    loadedProjects,
    plugins,
    projectMetas,
    readonly,
    selectProject,
    settings,
    syncing,
    t,
    userProjects,
  } from '@dabble/app';
  import { duplicateProject } from '@dabble/data/project-duplicate';
  import { UserProject } from '@dabble/data/types';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { focus } from '@dabble/toolkit/focus';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiArchive, mdiChevronDown, mdiDelete, mdiLoading, mdiNotebookMultiple, mdiTextBoxMultiple } from '@mdi/js';
  import { tick } from 'svelte';
  import NewProjectButton from './NewProjectButton.svelte';
  import ProjectButton from './ProjectButton.svelte';

  let selectedId = '';
  let view = 'recent';
  let recentProjects: UserProject[] = [];
  let templatesElement: HTMLElement;
  let recentsElement: HTMLElement;
  let renaming: { id: string; type: string; title: string; subtitle: string; author: string } | null;
  let renameError: string;
  let templatesWidth: number;
  let showingMore: boolean;

  $: trashedProjects = $userProjects.filter(p => !p.deleted && p.trashed);
  $: recentProjects = $userProjects.filter(
    p => !p.deleted && !p.trashed && !$projectMetas[p.projectId]?.isTemplate && p.category !== 'archive'
  );
  $: archivedProjects = $userProjects.filter(p => !p.deleted && !p.trashed && p.category === 'archive');
  $: templates = $userProjects.filter(
    p => $projectMetas[p.projectId]?.isTemplate && !p.deleted && !p.trashed && p.category !== 'archive'
  );
  $: projects = recentProjects;
  $: if (view === 'trash') projects = trashedProjects;
  $: if (view === 'archive') projects = archivedProjects;
  $: if (view === 'recent') projects = recentProjects;

  function toggleShowTemplates() {
    showingMore = !showingMore;
    if (showingMore) {
      templatesElement.style.height = 'auto'; // let it be the size it wants to be
      const height = templatesElement.offsetHeight; // get the height
      templatesElement.style.height = ''; // set it back to the default height
      templatesElement.offsetHeight; // make the browser actually go back to the default height with this getter
      templatesElement.style.height = `${height}px`; // allow it to transition to the right size
      setTimeout(() => (templatesElement.style.height = 'auto'), 300); // Turn it to auto so if people add templates it grows automatically
    } else {
      const height = templatesElement.offsetHeight; // it's in auto, so set the height
      templatesElement.style.height = `${height}px`; // allow it to transition to the right size
      templatesElement.offsetHeight; // make the browser actually go back to the default height with this getter
      templatesElement.style.height = '';
    }
  }

  async function selectByUserProject(userProject: UserProject) {
    selectedId = userProject.projectId;

    setTimeout(() => {
      selectProject(selectedId);
    }, 200);
  }

  async function createFromTemplate(userProject: UserProject) {
    if ($readonly) return selectProject(userProject.projectId);
    const newUserProject = await duplicateProject(userProject);
    await runProjectAnimation(newUserProject.projectId, 'in');
    selectByUserProject(newUserProject);
    await userProjects.update(userProject.projectId, { lastOpened: new Date().getTime() });
  }

  async function duplicate(userProject: UserProject) {
    if ($readonly) return;
    const newUserProject = await duplicateProject(userProject);
    await runProjectAnimation(newUserProject.projectId, 'in');
  }

  async function renameProject(id: string) {
    const projectMeta = $projectMetas[id];
    if (!projectMeta) return alert($t('alert_projet_not_fully_loaded'));
    const { type, title, subtitle, author } = projectMeta;
    renaming = { id, type, title, subtitle, author };
  }

  async function rename() {
    const { title, subtitle, author } = renaming;
    try {
      await projectMetas.update(renaming.id, { title, subtitle, author });
    } catch (err) {
      renameError = err.message;
      return;
    }
    closeRename();
  }

  function closeRename() {
    renaming = null;
  }

  async function createNewProject() {
    const { userProject } = await createProject();
    await runProjectAnimation(userProject.projectId, 'in');
    selectByUserProject(userProject);
  }

  async function deleteThisProject(id: string) {
    const projectMeta = $projectMetas[id];
    const title = getTitle(projectMeta);
    if (!(await confirm($t('delete_confirm', { title }), { yesNo: true }))) return;

    if (projectMeta.isTemplate) {
      const uid = plugins.stores.uid.get();
      if (projectMeta.roles[uid]) {
        const count = $projectMetas[id]?.templateCount ? $projectMetas[id].templateCount : 0;
        await projectMetas.update(id, {
          templateCount: count - 1,
        });
      }
    }

    await runProjectAnimation(id, 'out');
    await deleteProject(id);

    if (view === 'trash' && !trashedProjects.length) {
      view = 'recent';
    }
  }

  async function restoreProject(userProject: UserProject) {
    await runProjectAnimation(userProject.projectId, 'out', true);
    await userProjects.update(userProject.projectId, { trashed: undefined, lastOpened: getNow() });
    view = 'recent';
    await tick();
    await runProjectAnimation(userProject.projectId, 'in');
  }

  async function categorizeProject(userProject: UserProject, category: string) {
    await userProjects.update(userProject.projectId, { category });
  }

  async function makeTemplate(userProject: UserProject) {
    await projectMetas.update(userProject.projectId, { isTemplate: true });
    await userProjects.update(userProject.projectId, { isTemplate: true });
  }

  async function shareTemplate(userProject: UserProject) {
    navigator.clipboard.writeText(`https://app.dabblewriter.com/?importTemplate=${userProject.projectId}`);
    inform('success', $t('template_shared_link'));
  }

  async function runProjectAnimation(id: string, type: string, keepClassAfter?: boolean) {
    const node = recentsElement?.querySelector(`[data-id="${id}"]`);
    if (node) {
      node.classList.add(type);
      await waitForAnimation(node, 600);
      if (!keepClassAfter) node.classList.remove(type);
    }
  }

  function waitForAnimation(node: Element, duration: number) {
    return new Promise<void>(resolve => {
      function onEnd() {
        node.removeEventListener('animationend', onEnd);
        clearTimeout(id);
        resolve();
      }
      node.addEventListener('animationend', onEnd);
      const id = setTimeout(onEnd, duration);
    });
  }
</script>

{#if view === 'recent'}
  <div class="new-projects-strip" bind:this={templatesElement}>
    <div class="new-projects" class:showingMore>
      <h3>
        <Icon path={mdiTextBoxMultiple} highlight inline />
        {$t('start_new_project')}
      </h3>
      <div class="template-projects">
        {#if !templates.length}
          <p>{$t('home_templates_empty')}</p>
        {/if}
        <div class="templates" bind:offsetWidth={templatesWidth}>
          {#each templates as userProject (userProject.projectId)}
            <ProjectButton
              {userProject}
              selected={selectedId === userProject.projectId}
              on:select={() => createFromTemplate(userProject)}
              on:rename={() => renameProject(userProject.projectId)}
              on:delete={() => deleteThisProject(userProject.projectId)}
              on:edit={() => selectByUserProject(userProject)}
              on:category={event => categorizeProject(userProject, event.detail)}
              on:share={() => shareTemplate(userProject)}
              on:view={() => selectByUserProject(userProject)}
              loading={!$loadedProjects[userProject.projectId]}
            />
          {/each}
        </div>
      </div>
      <div class="show-more">
        <button class="show-more-btn btn link" on:click={toggleShowTemplates}>
          {$t(showingMore ? 'hide_templates' : 'show_more_templates')}
          <Icon path={mdiChevronDown} inline />
        </button>
      </div>
    </div>
  </div>
{/if}
<div class="recent-projects" class:trash={view === 'trash'}>
  <h3>
    {#if view === 'trash'}
      <Icon path={mdiDelete} highlight /> {$t('trashed_projects')}
    {:else if view === 'archive'}
      <Icon path={mdiArchive} highlight /> {$t('archived_projects')}
    {:else}
      <Icon path={mdiNotebookMultiple} highlight inline /> {$t('recent_projects')}
    {/if}
    {#if $syncing}
      <Icon path={mdiLoading} spin highlight />
    {/if}
    {#if recentProjects.length && view !== 'recent'}
      <button on:click={() => (view = 'recent')} use:tooltipTop={$t('recent_projects')} class="icon pull-right switch">
        <Icon path={mdiNotebookMultiple} highlight />
      </button>
    {/if}
    {#if archivedProjects.length && view !== 'archive'}
      <button
        on:click={() => (view = 'archive')}
        use:tooltipTop={$t('archived_projects')}
        class="icon pull-right switch"
      >
        <Icon path={mdiArchive} highlight />
      </button>
    {/if}
    {#if trashedProjects.length && view !== 'trash'}
      <button on:click={() => (view = 'trash')} use:tooltipTop={$t('trashed_projects')} class="icon pull-right switch">
        <Icon path={mdiDelete} highlight />
      </button>
    {/if}
  </h3>

  <div bind:this={recentsElement} class="projects">
    {#if !recentProjects.length && view === 'trash'}
      <h6 class="projects-message">{$t('home_trash_empty')}</h6>
    {/if}

    {#if view === 'recent' && !$readonly}
      <NewProjectButton on:click={createNewProject} />
    {/if}

    {#each projects as userProject (userProject.projectId)}
      {#if $creatingProject !== userProject.projectId}
        <ProjectButton
          {userProject}
          selected={selectedId === userProject.projectId}
          on:select={() => selectByUserProject(userProject)}
          on:rename={() => renameProject(userProject.projectId)}
          on:delete={() => deleteThisProject(userProject.projectId)}
          on:restore={() => restoreProject(userProject)}
          on:duplicate={() => duplicate(userProject)}
          on:template={() => makeTemplate(userProject)}
          on:category={event => categorizeProject(userProject, event.detail)}
          loading={!$loadedProjects[userProject.projectId]}
        />
      {/if}
    {/each}
  </div>
</div>
{#if renaming}
  <Modal title={$t('project_rename_header')} size="small" on:close={closeRename}>
    <form on:submit|preventDefault={rename}>
      <section>
        <p>{$t('project_rename_body')}</p>
        {#if renameError}
          <Alert type="danger" dismissible on:close={() => (renameError = null)}>
            <strong>{$t('error')}:</strong>
            {$t(renameError)}
          </Alert>
        {/if}
        <div class="form-group">
          <label for="recent-projects-title">{$t('home_title')}</label>
          <input
            id="recent-projects-title"
            type="text"
            class="form-control"
            placeholder={settings.getPlaceholder(renaming, 'title')}
            bind:value={renaming.title}
            use:focus
          />
        </div>
        <div class="form-group">
          <label for="recent-projects-subtitle">{$t('home_subtitle')}</label>
          <input
            id="recent-projects-subtitle"
            type="text"
            class="form-control"
            placeholder={settings.getPlaceholder(renaming, 'subtitle')}
            bind:value={renaming.subtitle}
          />
        </div>
        <div class="form-group">
          <label for="recent-projects-author">{$t('home_author')}</label>
          <input
            id="recent-projects-author"
            type="text"
            class="form-control"
            placeholder={settings.getPlaceholder(renaming, 'author')}
            bind:value={renaming.author}
          />
        </div>
      </section>
      <footer>
        <button class="btn secondary" type="button" on:click={closeRename}>{$t('dialog_cancel')}</button>
        <button class="btn primary" type="submit">{$t('dialog_ok')}</button>
      </footer>
    </form>
  </Modal>
{/if}

<style>
  .new-projects-strip {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -1px 1px -1px white;
    margin-bottom: 24px;
    height: 0px;
    transition: all 0.3s ease-in-out;
  }
  .new-projects {
    padding: 16px;
    width: 688px;
    overflow: hidden;
  }
  .templates {
    margin: 0 -16px;
    display: flex;
    flex-wrap: wrap;
  }
  .show-more {
    position: absolute;
    left: 50%;
    top: 100%;
  }
  .link.show-more-btn {
    padding: 8px;
    margin-left: -50%;
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
    text-decoration: none;
  }
  .show-more :global(.icon) {
    transition: all 0.3s ease-in-out;
  }
  .showingMore .show-more :global(.icon) {
    transform: rotate(180deg);
  }

  .recent-projects {
    padding: 0 16px;
    width: 688px;
  }
  .recent-projects .projects {
    margin: 0 -16px;
    display: flex;
    flex-wrap: wrap;
  }
  h3 {
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
    margin-top: 0;
    margin: 1em 0 1.4em;
    font-size: 1.2rem;
    line-height: 24px;
  }
  p {
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
  }
  .projects-message {
    flex: 1;
    margin: 2em 2em 6em;
    text-align: center;
    color: var(--text-color-lighterer);
    text-shadow: var(--text-highlight);
  }
  @media (max-width: 700px) {
    .new-projects {
      width: auto;
    }
    .recent-projects {
      width: auto;
    }
  }
</style>
