<script>
  import { t } from '@dabble/app';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { items, showHelp } from '../checklist';
  import HelpImage from './HelpImage.svelte';
  import HelpText from './HelpText.svelte';

  function close() {
    $showHelp = null;
  }
</script>

{#if $showHelp}
  <Modal title={$t(items[$showHelp].text)} on:close={close} size={'large'}>
    <section>
      <div class="help-image">
        <HelpImage name={items[$showHelp].help} />
      </div>
      <div class="help-items">
        {#each Array(items[$showHelp].steps) as _, i}
          <div class="help-text">
            <div class="font-website-heading number">{i + 1}</div>
            <div class="text"><HelpText text={items[$showHelp].help} step={i + 1} /></div>
          </div>
        {/each}
      </div>
    </section>
  </Modal>
{/if}

<style>
  section {
    padding: 40px;
    display: flex;
  }
  .help-image {
    width: 400px;
    padding: 0;
    margin-right: 40px;
    flex-shrink: 0;
  }
  .help-text {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
  }
  .help-text .number {
    font-size: 40px;
    width: 40px;
    color: var(--brand-primary);
    flex-shrink: 0;
    margin-right: 1rem;
    text-align: center;
  }
  .help-text .text {
    flex-grow: 1;
  }
  @media (max-width: 600px) {
    section {
      padding: 10px;
      display: block;
    }

    .help-image {
      max-width: 100%;
    }
  }
</style>
