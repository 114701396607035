<script>
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
  import { checklist, onboardingAvailable } from '../checklist';

  function toggleChecklist() {
    checklist.update({ hide: !$checklist.hide });
  }
</script>

{#if $onboardingAvailable}
  <button class="dropdown-item" class:checked={!$checklist.hide} on:click={toggleChecklist}>
    <Icon path={mdiCheckboxMarkedCircleOutline} />
    {$t('onboarding_getting_started')}
  </button>
{/if}
