import { analytics, externalMeta, isDelegate, isProduction, observe, plugins, router, userProjects } from '@dabble/app';
import * as Sentry from '@sentry/browser';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';

interface GEvent {
  event_category?: string;
  event_label?: string;
  value?: number;
  send_to?: string;
  [key: string]: any;
}
interface GParams {
  [key: string]: any;
}
interface GTag {
  (type: 'event', action: string, event?: GEvent): void;
  (type: 'config', measurementId: string, params?: GParams): void;
  (type: 'set', params?: GParams): void;
}
interface Fathom {
  trackGoal(eventId: string, value: number): void;
}

declare global {
  interface Window {
    'ga-disable-analyticsId': boolean;
    fathom: Fathom;
    fbq: (type: 'track', name: string, value?: any) => void;
    pintrk: (type: 'track' | 'load' | 'page', name?: string, value?: any) => void;
    rdt: (type: 'track', name: string, value?: any) => void;
    gtag: GTag;
    Intercom: (type: 'update' | 'show', value?: any) => void;
    profitwell: (property: string, value: string) => void;
    _cio: {
      identify: (value?: any) => void;
    };
    ire: (keyword: string, identity: any, data?: any, options?: any) => void;
  }
}

if (!isProduction || isDelegate) {
  window['ga-disable-analyticsId'] = true;
} else {
  let authed = false;

  // CHECKOUT
  window.addEventListener('checkout', ({ detail: { type, plan, coupon, total, lifetime, priceDiff } }: CustomEvent) => {
    const value = getCheckoutValue(plan, coupon);
    if (lifetime) type = 'upgrade';
    analytics.record('purchase', { type, plan_name: plan.nickname, currency: plan.currency.toUpperCase(), total });

    // new subscription
    if (type === 'new') {
      window.fbq && window.fbq('track', 'Subscribe', { value: value + '', currency: plan.currency.toUpperCase() });
      window.pintrk &&
        window.pintrk('track', 'Checkout', {
          value: value + '',
          currency: plan.currency.toUpperCase(),
          product_id: plan.id,
        });
      window.rdt && window.rdt('track', 'Purchase');
      window.gtag && window.gtag('event', 'Subscribe', { value });
      window.gtag && window.gtag('event', 'conversion', { send_to: 'AW-831705431/3XxNCJCD4XoQ16LLjAM' });
      window.fathom && window.fathom.trackGoal('XASSRAVP', priceDiff);
    } else {
      // Upgrade or Downgrade
      window.fbq && window.fbq('track', capitalize(type), { value: value + '', currency: plan.currency.toUpperCase() });
      if (type === 'upgrade') {
        window.fathom && window.fathom.trackGoal('HLIDYLSC', priceDiff);
      } else if (type === 'downgrade') {
        window.fathom && window.fathom.trackGoal('77ATYVIT', priceDiff);
      }
    }

    window.gtag &&
      window.gtag('event', 'purchase', {
        transaction_id: undefined,
        coupon: coupon && coupon.id,
        currency: plan.currency,
        items: [plan.id],
        value: total || value,
      });
  });

  // BEGIN CHECKOUT
  window.addEventListener('begincheckout', ({ detail: { plan, coupon, total } }: CustomEvent) => {
    analytics.record('checkout_start', {
      coupon: coupon && coupon.id,
      currency: plan.currency,
      items: [plan.id],
      value: total || getCheckoutValue(plan, coupon),
    });
    window.gtag &&
      window.gtag('event', 'begin_checkout', {
        coupon: coupon && coupon.id,
        currency: plan.currency,
        items: [plan.id],
        value: total || getCheckoutValue(plan, coupon),
      });
  });

  // NANOWORDCOUNT
  window.addEventListener('nanowordcount', ({ detail: { wordcount } }: CustomEvent) => {
    const nanowrimo = `nanowrimo.${format(new Date(), 'yyyy-MM')}`;
    analytics.recordUserData({ [nanowrimo]: wordcount });
  });

  // PAGE CHANGE
  router.subscribe(data => {
    window.fbq && window.fbq('track', 'PageView');
    window.pintrk && authed && window.pintrk('page');
    window.rdt && window.rdt('track', 'PageVisit');
    setTimeout(() => {
      // allow title to get set
      window.gtag &&
        window.gtag('event', 'page_view', {
          page_location: location.href,
          page_path: data.path,
          page_title: document.title,
        });
    });
  });

  // SCREEN CHANGE
  window.addEventListener('currentscreen', ({ detail: { name } }: CustomEvent) => {
    window.gtag && window.gtag('set', { screen_name: name });
  });

  // SIGN UP
  window.addEventListener('signup', () => {
    analytics.record('signup');
    window.fbq && window.fbq('track', 'CompleteRegistration');
    window.pintrk && window.pintrk('track', 'Signup');
    window.rdt && window.rdt('track', 'SignUp');
    window.gtag && window.gtag('event', 'conversion', { send_to: 'AW-831705431/1YrYCKTK3XoQ16LLjAM' });
    window.gtag && window.gtag('event', 'sign_up', { method: 'password' });
  });

  // SIGN IN
  window.addEventListener('signin', () => {
    analytics.record('login');
    window.gtag && window.gtag('event', 'login', { method: 'password' });
  });

  // AUTHENTICATED
  window.addEventListener('authed', ({ detail: { uid, name, email } }: CustomEvent) => {
    authed = true;
    window.pintrk && window.pintrk('load', '2612791876124', { em: email });
    window.pintrk && window.pintrk('page');
    window.gtag && window.gtag('set', { user_id: uid });
    window._cio && window._cio.identify({ id: uid, email });
    window.profitwell && window.profitwell('user_email', email);
    Sentry.setUser({ id: uid });
  });

  // PROJECT CREATION
  window.addEventListener('projectcreated', () => {
    analytics.record('create_project');
    analytics.recordUserData({ projectCount: userProjects.get().length });
    window.gtag && window.gtag('event', 'created', { event_category: 'projects', event_label: 'Project Creation' });
  });

  // UPDATE INTERCOM
  const unsubscribeInt = observe([externalMeta, plugins.stores.currentUser], ([meta, user]) => {
    if (meta?.intercomHash && user?.uid) {
      Promise.resolve().then(() => unsubscribeInt());
      if (window.Intercom) {
        const { uid, email, name } = user;
        window.Intercom('update', { user_id: uid, name, email, user_hash: meta.intercomHash });
      }
    }
  });
}

function getCheckoutValue(plan: any, coupon: any): number {
  let value = plan.amount / 100;
  if (plan.interval === 'year') value /= 12;
  if (coupon && coupon.percent_off && (coupon.duration === 'forever' || coupon.duration_in_months === 12)) {
    value *= coupon.percent_off / 100;
  }
  return value;
}
