import { plugins, userMeta } from '@dabble/app';
import { createUserMetaTypeStore, UserMetaStore, UserMetaTypeStore } from '@dabble/data/stores/user-meta';
import { UserMeta } from '@dabble/data/types';

export interface ExitSurvey extends UserMeta {
  reason?: string;
  otherAppName?: string;
  otherAppReasons?: string;
  other?: string;
  pausedInstead?: boolean;
  presentedSpecialOffer?: boolean;
  acceptedSpecialOffer?: boolean;
}

export function createExitSurveyStore(userMeta: UserMetaStore): UserMetaTypeStore<ExitSurvey> {
  const { get, update, subscribe } = createUserMetaTypeStore<ExitSurvey>(userMeta, 'survey-exit');

  return {
    get,
    update,
    subscribe,
  };
}

export const exitSurvey = createExitSurveyStore(userMeta);
plugins.register({ exitSurvey });
