import { Doc } from '../types';
import { Children } from './children';
import { Docs } from './docs';

export interface Parents {
  [id: string]: Doc;
}

export const emptyParents: Parents = {};

export function getParentsLookup(docs: Docs, childrenLookup: Children) {
  if (!docs || !childrenLookup) return emptyParents;
  const parents: Parents = {};

  Object.keys(childrenLookup).forEach(parentId => {
    const parent = docs[parentId];
    if (parent.virtual) return;
    childrenLookup[parentId].forEach(child => (parents[child.id] = parent));
  });

  return parents;
}
