import { features } from '@dabble/app';
import { format, h } from 'typewriter-editor';
import Popover from './components/Popover.svelte';
import { GrammarSettings } from './grammar-stores';
import { GrammarFormatAttributes, GrammarLineFormat } from './grammar-types';
import { grammarSettingsStore } from './model';

const TIMEOUT = 250;
let currentPopover: Popover | null = null;

export function closeCurrentPopover() {
  if (currentPopover) {
    currentPopover.close();
  }
}

let settings: GrammarSettings;
grammarSettingsStore.subscribe(value => (settings = value));

export const comment = format({
  name: 'grammar',
  selector: 'format-grammar',
  greedy: false,
  fromDom(node: HTMLElement) {
    return node.dataset.id;
  },
  render: (attributes: GrammarFormatAttributes, children, line, editor) => {
    if (!features.get().has('spell_check')) {
      return null;
    }
    const issueId = attributes.grammar;
    const issue = (line.attributes?.grammar as GrammarLineFormat)?.issues?.[issueId];
    if (!issue || issue.dismissed) return null;
    const name = issue.category === 'spelling' ? 'spelling' : 'grammar';
    let currentTimeout: ReturnType<typeof setTimeout>;

    if (!settings[name]) return null;

    return h(
      'format-grammar',
      {
        'data-category': issue.category,
        'data-id': issueId,
        onmouseenter(event: MouseEvent) {
          const mark = event.target as HTMLElement;
          if (!settings[name]) return;

          let off: () => void;
          clearInterval(currentTimeout);
          if (currentPopover && currentPopover.issueId === issueId) {
            currentPopover.onMouseEnter();
          } else {
            currentTimeout = setTimeout(openPopover, TIMEOUT);
          }

          function openPopover() {
            // If the mark is not in the DOM anymore, don't show the popover
            if (!mark.ownerDocument.documentElement.contains(mark)) return;
            mark.classList.add('hovered');
            if (currentPopover) currentPopover.close();

            currentPopover = new (Popover as any)({
              target: document.documentElement,
              props: {
                issueId,
                issue,
                editor,
              },
            });

            off = currentPopover.$on('close', closePopover);
          }

          function closePopover() {
            const mark = event.target as HTMLElement;
            mark.classList.remove('hovered');
            if (currentPopover?.issueId !== issueId) return;
            off();
            currentPopover.$destroy();
            currentPopover = null;
          }
        },
        onmouseleave() {
          clearInterval(currentTimeout);
          if (!settings[name] || currentPopover?.issueId !== issueId) return;
          if (currentPopover) {
            currentPopover.onMouseLeave();
          }
        },
      },
      children
    );
  },
});
