import { currentProjectMeta, plugins } from '@dabble/app';
import { Doc } from '@dabble/data/types';
import { Editor, EditorChangeEvent } from 'typewriter-editor';

export default function addAuthorFormat(doc: Doc, field: string) {
  if (field !== 'body') return null;

  return (editor: Editor) => {
    function onChanging({ change }: EditorChangeEvent) {
      if (!change || !change.contentChanged) {
        return;
      }
      const uid = currentProjectMeta.get()?.trackAuthorChanges && plugins.stores.currentUser.get().uid;

      change.delta.ops.forEach(op => {
        if (op.insert) {
          if (!op.attributes && uid) op.attributes = {};
          if (uid) op.attributes.author = uid;
          else if (op.attributes?.author) delete op.attributes.author;
        }
      });
    }

    return {
      init() {
        editor.on('changing', onChanging);
      },
      destroy() {
        editor.off('changing', onChanging);
      },
    };
  };
}
