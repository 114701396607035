<script>
  import { currentProjectMeta, features, locale, projectMetas, t } from '@dabble/app';
  import CheckButton from '@dabble/toolkit/CheckButton.svelte';
  import { getLanguages } from '../grammar-api';
  import SpeedControls from './SpeedControls.svelte';
  import VoiceSelect from './VoiceSelect.svelte';

  export function updateEdits() {}

  let languages = [];

  $: currentLanguage = ($currentProjectMeta && $currentProjectMeta.spellingLanguage) || $locale || 'en-US';
  $: currentVoice = $currentProjectMeta && $currentProjectMeta.readingVoice;
  $: $currentProjectMeta && loadLanguages();

  function toggle(field) {
    if (!$features.has('spell_check') && field === 'spellingCheck') {
      features.promptUpgrade('spell_check');
    } else if (!$features.has('grammar') && field === 'grammarCheck') {
      features.promptUpgrade('grammar');
    } else {
      if ($currentProjectMeta) {
        projectMetas.update($currentProjectMeta.id, { [field]: !$currentProjectMeta[field] });
      }
    }
  }

  function onLanguageChange(spellingLanguage) {
    currentVoice = spellingLanguage;
    projectMetas.update($currentProjectMeta.id, { spellingLanguage });
    // loadVoices();
  }

  async function loadLanguages() {
    languages = (await getLanguages()) || [];
  }
</script>

<div class="section preferences">
  <h2>{$t('preferences_spelling_header')}</h2>
  <div class="preference vertical">
    <CheckButton
      on:click={() => toggle('spellingCheck')}
      checked={$features.has('spell_check') &&
        ($currentProjectMeta?.spellingCheck || $currentProjectMeta?.spellingCheck === undefined)}
    >
      {$t('preferences_spellcheck_label')}
    </CheckButton>
    <div class="description">{$t('preferences_spellcheck_description')}</div>
  </div>

  <div class="preference vertical">
    <CheckButton
      on:click={() => toggle('grammarCheck')}
      checked={$features.has('grammar') && $currentProjectMeta?.grammarCheck}
    >
      {$t('preferences_grammarcheck_label')}
    </CheckButton>
    <div class="description">{$t('preferences_grammarcheck_description')}</div>
  </div>

  <div class="preference vertical">
    <h6 for="spellingLanguage">{$t('preferences_spelling_language_header')}</h6>
    <select class="form-control" id="spellingLanguage" on:change={event => onLanguageChange(event.target.value)}>
      <option value="" disabled>{$t('preferences_select_language')}</option>
      {#each languages as language}
        <option selected={language.languageCode === currentLanguage} value={language.languageCode}
          >{language.displayName}</option
        >
      {/each}
    </select>
  </div>

  <div class="preference vertical">
    <h6 for="spellingVoices">{$t('preferences_spelling_voice_header')}</h6>
    <div class="row">
      <VoiceSelect />
      <SpeedControls />
    </div>
  </div>

  <p class="description">{@html $t('preferences_spellcheck_poweredby')}</p>
</div>

<style>
  .preferences {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .preferences :global(h6) {
    margin-top: 0;
    font-weight: bold;
    color: var(--text-color-lighter);
    text-transform: uppercase;
  }
  .preferences :global(.preference) {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .preferences :global(.preference.vertical) {
    flex-direction: column;
    align-items: flex-start;
  }
  .preferences :global(.preference:not(.vertical) .description) {
    margin-left: 10px;
  }
  .preferences :global(.preference.vertical .description) {
    margin-bottom: 10px;
  }
  .preferences :global(.preference.vertical .buttons) {
    margin-top: 10px;
  }
  .row {
    display: flex;
    width: 100%;
  }
  .row > * {
    width: 50%;
  }
  .section {
    order: 0;
  }

  .form-control {
    max-width: 100%;
  }

  @media (max-width: 688px) {
    .preferences :global(.preference) {
      flex-direction: column;
      align-items: flex-start;
    }
    .preferences :global(.check-button) {
      margin-right: 0;
      margin-bottom: 4px;
    }
    .row {
      display: block;
    }
    .row > * {
      width: 100%;
    }
  }
</style>
