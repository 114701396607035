<script>
  import { editingMode, features, projectMetas, projectStore, t } from '@dabble/app';
  import Banner from '@dabble/plugins/core/components/Banner.svelte';
  import RichBody from '@dabble/plugins/core/components/RichBody.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Page from '@dabble/toolkit/Page.svelte';

  export let doc;
  export let last = true;
  export const backgroundColor = 'var(--page-background)';
  export let noBackground = false;
  let meta;
  let font;
  let className;

  $: readonly = !$features.has('story_notes') || $editingMode === 1;
  $: meta = $projectMetas[$projectStore.projectId];
  $: font = (meta && meta.font) || {};
  $: hasBanner = doc && 'bannerUrl' in doc;
  $: noBackground = hasBanner;
  $: className = [
    'note-view',
    hasBanner ? 'has-banner' : '',
    `paragraph-spacing-${font.noteSpacing || 'half'}`,
    `paragraph-style-${font.noteStyle || 'modern'}`,
  ]
    .filter(Boolean)
    .join(' ');
</script>

<div data-id={doc && doc.id} class={className}>
  <Page {doc} frameless={!hasBanner}>
    <slot>
      {#if hasBanner}
        <div class="header-image">
          <Banner {doc} removable canEdit />
        </div>
      {/if}
      <EditableContent
        {doc}
        field="title"
        header="h1"
        class="title"
        readonly={!$features.has('story_notes') || $editingMode === 1}
      />
      {#if hasBanner}
        <EditableContent
          {doc}
          field="subtitle"
          header="h2"
          class="subtitle"
          placeholder={$t('home_subtitle')}
          {readonly}
        />
      {/if}
      <RichBody {doc} readonly={!$features.has('story_notes')} fillPage={last} />
    </slot>
  </Page>
</div>

<style>
  .header-image {
    margin: calc(-1 * var(--page-padding));
    margin-bottom: 0;
    aspect-ratio: 3 / 1;
    border-radius: 2px 2px 0 0;
    background-color: rgb(var(--neutral-300));
    overflow: hidden;
  }
  :global(html[data-theme='dark']) .header-image {
    background-color: rgb(var(--neutral-500));
  }
  .has-banner :global(.page .title h1) {
    margin: 0;
    padding: 1rem 0 0.5rem;
    padding-bottom: 0;
    text-align: left;
    font-size: 1.375rem;
    color: var(--text-color-light);
    font-weight: bold;
  }
  .has-banner :global(.page .subtitle h2) {
    margin: 0;
    padding: 0.5rem 0 1rem;
    text-align: left;
    font-size: 1rem;
    color: var(--text-color-lightest);
  }
</style>
