import { DocMap, FieldMap, FindState, Ranges } from '../data/stores/find-replace';
import { ProjectData } from '../data/stores/project';
import { Doc, DocSettings } from '../data/types';
import getFindExpression from './find-expression';

// Create a lookup of all docs with their fields containing the matched text
export function getFindMap(state: FindState, docSettings: DocSettings, projectData: ProjectData, doc: Doc) {
  const { project, childrenLookup, texts } = projectData;
  const { inProject } = state;
  if (!project) return [null, null];
  const expr = getFindExpression(state);
  if (!expr) return [null, null];
  const map: DocMap = {};
  const allMap: DocMap = {};
  let found = false;
  let allFound = false;

  const findSettings = docSettings && docSettings.findSettings;
  const visibleFields = findSettings && findSettings.visibleFields;
  let match: RegExpExecArray,
    fields: FieldMap = {},
    allFields: FieldMap = {},
    indexes: Ranges = [];

  function findInDoc(doc: Doc) {
    const visibleForDoc = visibleFields && visibleFields[doc.type];
    const docTexts = texts.textWithQueued[doc.id];
    let hasMatch = false;
    let hasAllMatch = false;

    docTexts &&
      Object.keys(docTexts).forEach(field => {
        findInText(docTexts[field]);
        if (indexes.length) {
          hasAllMatch = true;
          allFields[field] = indexes;

          if (inProject || !visibleForDoc || visibleForDoc[field]) {
            hasMatch = true;
            fields[field] = indexes;
          }

          indexes = [];
        }
      });

    if (hasMatch) {
      map[doc.id] = fields;
      found = true;
      fields = {};
    }

    if (hasAllMatch) {
      allMap[doc.id] = allFields;
      allFound = true;
      allFields = {};
    }

    const children = childrenLookup[doc.id];
    if (children && children.length && (inProject || !findSettings?.childrenShouldBeSearched)) {
      children.forEach(findInDoc);
    }
  }

  function findInText(text: string) {
    try {
      while ((match = expr.exec(text))) {
        if (!match[0].length) {
          expr.lastIndex++;
        } else {
          indexes.push([match.index, expr.lastIndex]);
        }
      }
    } catch (e) {}
  }

  findInDoc(inProject ? project : doc);
  return [found ? map : null, allFound ? allMap : null];
}
