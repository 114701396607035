import { $t, confirm, currentProjectMeta, getTitle, inform, projectStore, settings, t } from '@dabble/app';
import { Children } from '@dabble/data/stores/children';
import { Parents } from '@dabble/data/stores/parents';
import { Updater, writable } from '@dabble/data/stores/store';
import { Doc } from '@dabble/data/types';
import { files } from './files';
import { projects } from './projects';
import { styles } from './styles';
import { Counts, ExportData, ExportOptions } from './types';
import { createFileName, getDocName } from './utils';

export const exportOptions = createExportOptionsStore();
export const exportedDoc = writable<Doc | null>(null);
export const exportDescriptions = writable<any | null>(null);
export const exporting = writable<boolean>(false);
export const sharedModalOpen = writable('');
export const emailShared = writable('');

export async function startExport(close?: () => void) {
  const $exportOptions = exportOptions.get();
  inform('info', $t('export_started', { fileType: $exportOptions.fileType }));
  exporting.set(true);
  let url: string;
  try {
    url = await exportDoc($exportOptions.doc, $exportOptions);
  } catch (err) {
    console.error(err);
    inform('danger', $t('export_failed', { fileType: $exportOptions.fileType }));
  }
  exporting.set(false);
  close?.();
  if (url && (await confirm('Would you like to open the exported file now?', { yesNo: true }))) {
    window.open(url, 'export');
  }
}

export async function exportDoc(doc: Doc, options: ExportOptions) {
  const data = prepareDoc(doc, options);

  // Tried making a flexible export system, but Google Docs didn't support many of the features through their API. It
  // turned out to work better to create the Word doc and upload it to Google Drive.
  if (options.fileType === 'html') {
    const { shareToWeb } = await files[options.fileType]();
    await shareToWeb(data);
  } else {
    const [File, style, project] = await Promise.all([
      files[options.fileType](),
      styles[options.style](),
      projects[options.project || 'novel'](),
    ]);
    const file = new File(data);
    data.file = file;
    try {
      style.initStyles(file.styles);
      project.exportDoc(file, data.doc);
      return await file.save();
    } catch (e) {
      console.error(e);
      throw new Error(t.get()('novel_export_failed'));
    }
  }
}

function createExportOptionsStore() {
  const { get, set: writeSet, subscribe } = writable<ExportOptions | null>(null);

  async function set(value: ExportOptions): Promise<void> {
    if (value?.doc && value.doc !== get()?.doc) {
      const docSettings = settings.getFor(value.doc.type);
      const shortName =
        value.doc.type === 'novel_book'
          ? currentProjectMeta.get().authorInfo?.shortTitle || getTitle(currentProjectMeta.get(), 'title')
          : undefined;
      if (!value.style) value.style = docSettings.exportStyles?.[0];
      value.filename = createFileName(value.doc, shortName);
      value.descriptions = docSettings.exportDescriptions;
      value.images = true;
    }
    writeSet(value);
  }

  function update(fn: Updater<ExportOptions>): Promise<void> {
    return set(fn(get()));
  }

  return { get, set, subscribe, update };
}

function prepareDoc(doc: Doc, options: ExportOptions): ExportData {
  const counts = { ...getCounts(doc), ...options.counts };
  const { filename, images = false, descriptions = false, shortName, style } = options;
  const title = shortName || doc.title || getDocName(counts, doc, true);
  const author = getAuthor(doc);
  counts.words = projectStore.get().counts[doc.id].wordCount;

  return { file: null, filename, style, counts, title, author, doc, images, descriptions };
}

function getAuthor(doc: Doc) {
  while (doc && doc.type !== 'novel_book') {
    doc = projectStore.getParent(doc.id);
  }
  return getTitle(doc, 'author');
}

function getCounts(doc: Doc) {
  const { parentsLookup, childrenLookup } = projectStore.get();
  const counts: Counts = {};
  if (doc.type === 'novel_part') {
    counts.novel_part = getNumber(doc, parentsLookup, childrenLookup);
    counts.novel_chapter = getNumber(childrenLookup[doc.id][0], parentsLookup, childrenLookup);
  } else if (doc.type === 'novel_chapter') {
    counts.novel_chapter = getNumber(doc, parentsLookup, childrenLookup);
  }
  return counts;
}

function getNumber(doc: Doc, parents: Parents, allChildren: Children) {
  let book = doc;
  while (book && book.type !== 'novel_book') book = parents[book.id];
  if (!book || book.type !== 'novel_book') return 1;
  let number = 0;
  allChildren[book.id].some(function count(child) {
    if (child.type === doc.type) {
      if (!child.unnumbered) number++;
      return child === doc;
    } else if (allChildren[child.id]) {
      return allChildren[child.id].some(count);
    }
  });
  return number;
}
