<script lang="ts">
  import { projectMetas, projectStore, settings } from '@dabble/app';
  import { Doc } from '@dabble/data/types';
  import { autoselect } from '@dabble/toolkit/autoselect';
  import { enterKey, escKey } from '@dabble/toolkit/events';
  import { focus } from '@dabble/toolkit/focus';
  import { createEventDispatcher } from 'svelte';

  export let doc: Doc;
  let proxy = ($projectStore.projectId === doc.id && $projectMetas[doc.id]) || doc;

  let editingTitle = proxy.title || '';
  const dispatch = createEventDispatcher();

  function cancelEditing() {
    dispatch('done');
  }

  async function saveEditing() {
    const existingTitle = proxy.title || '';
    const title = editingTitle.trim();
    if (existingTitle !== title) {
      if ($projectStore.projectId === doc.id) {
        await projectMetas.update(doc.id, { title });
      } else {
        await projectStore.updateDoc(doc.id, { title });
        projectStore.forceTextUpdate();
      }
    }
    cancelEditing();
  }
</script>

<input
  use:focus
  use:autoselect
  use:escKey={cancelEditing}
  use:enterKey={saveEditing}
  on:blur={saveEditing}
  bind:value={editingTitle}
  placeholder={settings.getPlaceholder(proxy) || ''}
  class="form-control input-sm edit-input"
  class:empty={!editingTitle}
/>

<style>
  .edit-input {
    display: inline;
    flex: 1 1 auto;
    font: inherit;
    color: var(--text-color-normal);
    padding: 0 5px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    margin: -1px -6px -1px -6px;
  }
  .edit-input.empty {
    font-style: italic;
  }
</style>
