<script>
  import { alert, isMac, settings, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiContentCopy, mdiContentCut, mdiContentPaste, mdiDelete, mdiRedo, mdiSelectAll, mdiUndo } from '@mdi/js';

  export let editor;
  let history;
  let target;
  let doc;

  $: history = editor && editor.modules.history;
  $: target = editor && editor.root;
  $: doc = target && target.ownerDocument;

  async function exec(command) {
    focus();
    if (command === 'paste' && !doc.queryCommandSupported('paste')) {
      const options = await pasteCommand();
      if (options) {
        editor.commands.paste(options);
      }
    } else {
      target.focus();
      doc.execCommand(command);
    }
  }

  async function pasteCommand() {
    let permissions;
    let html = '';
    let text = '';

    try {
      permissions = await navigator.permissions.query({
        name: 'clipboard-read',
      });
    } catch (err) {
      await alert(
        t.get()('context_menu_paste_permission_title'),
        t.get().call('context_menu_paste_permission_unsupported', {
          shortcut: isMac ? 'Cmd + V' : 'Ctrl + V',
        })
      );
      return false;
    }

    if (permissions.state !== 'granted') {
      await alert(
        t.get()('context_menu_paste_permission_title'),
        t.get()('context_menu_paste_permission_' + permissions.state)
      );
    }

    try {
      text = await navigator.clipboard.readText();
    } catch (err) {}

    try {
      const clipboard = await navigator.clipboard.read();
      const blob = await clipboard[0].getType('text/html');
      html = cleanHtml(await blob.text());
    } catch (err) {}
    return { text, html };
  }

  function cleanHtml(html) {
    return html
      .replaceAll(/<!--EndFragment-->/g, ' ') // fixes blank like before the final paragraph
      .replaceAll('<o:p></o:p>', ' ')
      .replaceAll('<o:p>&nbsp;</o:p>', ' ')
      .replaceAll(/\n/g, ' '); // remove excess returns in html
  }

  function selectAll() {
    focus();
    const { root } = editor;
    const selection = root.ownerDocument.getSelection();
    selection.selectAllChildren(root);
  }

  function query(command) {
    return doc.queryCommandEnabled(command);
  }

  function focus() {
    editor.root.ownerDocument.defaultView.focus();
  }
</script>

<button class="dropdown-item" on:click={() => history.undo()} disabled={!history.hasUndo()}>
  <Icon path={mdiUndo} />
  {$t('text_undo')}
</button>
<button class="dropdown-item" on:click={() => history.redo()} disabled={!history.hasRedo()}>
  <Icon path={mdiRedo} />
  {$t('text_redo')}
</button>
<hr />
<button class="dropdown-item" on:click={() => exec('cut')} disabled={!query('cut')}>
  <Icon path={mdiContentCut} />
  {$t('text_cut')}
</button>
<button class="dropdown-item" on:click={() => exec('copy')} disabled={!query('copy')}>
  <Icon path={mdiContentCopy} />
  {$t('text_copy')}
</button>
<button
  class="dropdown-item"
  on:click={() => exec('paste')}
  disabled={doc.queryCommandSupported('paste') && !query('paste')}
>
  <Icon path={mdiContentPaste} />
  {$t('text_paste')}
</button>

{#each settings.getValuesFromPlugins('editableContextMenu') as component}
  <svelte:component this={component} {editor} />
{/each}
<button class="dropdown-item" on:click={() => exec('delete')} disabled={!query('delete')}>
  <Icon path={mdiDelete} />
  {$t('text_delete')}
</button>
<hr />
<button class="dropdown-item" on:click={selectAll}>
  <Icon path={mdiSelectAll} />
  {$t('text_selectall')}
</button>
