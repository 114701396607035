import * as Sentry from '@sentry/browser';
import { isProduction, version } from './version';

if (isProduction) {
  Sentry.init({
    release: `dabble-web-app@${version}`,
    ignoreErrors: ['CONNECTION_CLOSED', 'offline', /Non-Error promise rejection captured with value/],
    dsn: 'https://d81211acb9c2478ba5f9b2f32c09c1f9@o225830.ingest.sentry.io/1378058',
  });
}

export function reportError(err: Error) {
  if (isProduction) {
    Sentry.captureException(err);
  }
}
