<script>
  import { dateOptions, t } from '@dabble/app';
  import { format } from 'date-fns';
  import { createEventDispatcher } from 'svelte';
  import { trialAvailable } from '../billing';
  import Discount from './Discount.svelte';

  export let disabled;
  const dispatch = createEventDispatcher();

  function subscribe() {
    dispatch('subscribe');
  }
</script>

<h2>{$t('billing_subscribe_now')}</h2>
<Discount />
<div class="layout">
  <div class="span-8 span-4-mobile subscription-info">
    <p class="no-subscription-text">{$t('billing_subscription_lapsed')}</p>
    {#if $trialAvailable}
      <p class="free-trial-available">
        {#if $trialAvailable < 1000}
          {@html $t('billing_trial_start_days', { count: $trialAvailable })}
        {:else}
          {@html $t('billing_trial_start_date', { date: format(new Date($trialAvailable), 'PPP', dateOptions) })}
        {/if}
      </p>
    {/if}
  </div>
  <div class="span-4 span-4-mobile actions">
    <button class="btn primary" on:click={subscribe} {disabled}
      >{$t($trialAvailable ? 'billing_trial_start' : 'billing_subscribe_now')}</button
    >
  </div>
</div>

<style>
  h2 {
    margin-bottom: 0.5rem;
  }
  .no-subscription-text {
    font-weight: 500;
  }
  .free-trial-available {
    font-weight: 500;
    font-size: var(--font-size-sm);
  }
</style>
