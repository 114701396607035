import { format, h } from 'typewriter-editor';
import { getAuthorColor, getUser } from './collab-stores';
import './collab.css';

export const author = format({
  name: 'author',
  selector: 'format-author',
  render: (attributes, children) => {
    return h(
      'format-author',
      {
        'data-uid': `${attributes.author}`,
        'data-name': getUser.get()(attributes.author).name,
        class: getAuthorColor(attributes.author),
      },
      children
    );
  },
});
