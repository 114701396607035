import { settings } from '@dabble/app';
import AdminButton from './components/AdminButton.svelte';
import AdminRoutes from './components/AdminRoutes.svelte';

settings.configure('dabble', {
  plugins: {
    account: {
      menuItems: {
        AdminButton,
      },
    },
  },
  routes: { AdminRoutes },
});
