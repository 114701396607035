<script>
  import { alert, confirm, inform, router, t, userMeta } from '@dabble/app';
  import rest from '@dabble/data/rest';
  import { currentUser } from '@dabble/plugins/account/auth';
  import BlankScreen from '@dabble/toolkit/BlankScreen.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiArrowLeft, mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle, mdiLoading } from '@mdi/js';
  import { billing, subscription, trialing } from '../billing';
  import { exitSurvey } from '../exit-survey';
  import PauseSubscriptionOptions from './PauseSubscriptionOptions.svelte';

  $: selectedReason = '';
  $: otherAppName = '';
  $: otherAppReasons = '';
  $: otherReason = '';
  $: offerAccepted = $exitSurvey.offerAccepted ?? false;
  $: pausedInstead = false;
  $: showPauseSub = false;
  $: showFinalCancelPrompt = false;
  $: saving = false;
  $: yearly = $subscription?.plan?.interval === 'year';
  $: lang = $userMeta.preferences?.locale || 'en-US';

  let reasons = [
    { key: 'too_expensive' },
    { key: 'found_another_app' },
    { key: 'hard_to_learn' },
    { key: 'not_writing' },
    { key: 'missing_features' },
    { key: 'other' },
  ];

  function submitForm() {
    const formFields = {
      reason: selectedReason,
      otherAppName,
      otherAppReasons,
      other: otherReason,
      offerAccepted,
      pausedInstead,
    };

    exitSurvey.update(formFields);
    rest.POST('/analytics/survey', {
      email: $currentUser.email,
      attributes: {
        cancelReason: offerAccepted || pausedInstead ? undefined : selectedReason,
        churnSaved: offerAccepted || pausedInstead ? true : undefined,
      },
    });
  }

  function resetSurvey() {
    selectedReason = '';
  }

  async function save(callback) {
    saving = true;
    try {
      await callback();
    } catch (err) {
      inform('danger', $t(err.message));
      return false;
    }
    saving = false;
    return true;
  }

  async function updateSubscription(options) {
    return await save(() => billing.updateSubscription(options));
  }

  async function applyOffer() {
    if (offerAccepted) {
      inform('danger', $t('exit_survey_accepted'));
      return;
    }
    offerAccepted = true;
    const code = yearly ? 'UZ1n5x2A' : 'duRF5o6N';
    try {
      const coupon = await billing.getCoupon(code);
      await updateSubscription({ coupon: coupon.id });
    } catch (err) {
      inform('danger', $t(err.message));
      return;
    }

    alert($t('exit_survey_yay_title'), $t('exit_survey_offer_applied'), { fireworks: true });
    submitForm();
    router.navigate('/account/billing');
  }

  function backToBilling() {
    router.navigate('/account/billing');
  }

  function continueToCancel() {
    if (selectedReason === 'not_writing') {
      showPauseSub = true;
    } else {
      showFinalPrompt();
    }
    submitForm();
  }

  async function cancelSubscription(when) {
    const what = $trialing ? 'trial' : 'subscription';
    if (await confirm($t('billing_cancel_title', { what }), $t('billing_cancel_body', { what }))) {
      const result = window.profitwell && (await window.profitwell('on_cancellation'));
      if (!result || result.status !== 'accepted') {
        if (await save(async () => await billing.cancelSubscription(when))) {
          inform('success', $t('billing_cancellation_cofirmed'));
          submitForm();
        }
      }
    }
  }

  function showFinalPrompt() {
    showPauseSub = false;
    showFinalCancelPrompt = true;

    if ($trialing) {
      cancelSubscription('now');
    } else {
      cancelSubscription();
    }
    submitForm();
  }

  function pauseInstead() {
    pausedInstead = true;
    submitForm();
    router.navigate('/account/billing');
  }
</script>

<BlankScreen connected>
  {#if showPauseSub}
    <section class="section">
      <h3 class="section-heading">{$t('pause_subscription_heading')}</h3>
      <hr />
      <p>{@html $t('exit_survey_pause_instead')}</p>
      <PauseSubscriptionOptions
        fromCancelSurvey
        on:cancel={showFinalPrompt}
        on:pauseSubscription={pauseInstead}
        on:backToBilling={backToBilling}
      />
    </section>
  {:else if showFinalCancelPrompt}
    <section class="section">
      <h3 class="section-heading">{$t('exit_survey_final_heading')}</h3>
      <hr />
      <p>{@html $t('exit_survey_final_text')}</p>
      <div class="survey-section-footer">
        <button class="btn primary" on:click={backToBilling}>{$t('exit_survey_back_to_billing')}</button>
      </div>
    </section>
  {:else}
    <section class="section">
      <h3 class="section-heading">{$t('exit_survey_main_heading')}</h3>
      <hr />
      <form class="survey-wrapper">
        <section class="survey-section">
          <p>{@html $t('exit_survey_cancel_description')}</p>
          <div class="survey-options">
            {#each reasons as reason}
              {#if (!!selectedReason && selectedReason === reason.key) || !selectedReason}
                <label class="checkbox-input" class:checked={selectedReason === reason.key}>
                  <div class="checkbox-icon">
                    {#if selectedReason === reason.key}
                      <Icon path={mdiCheckboxMarkedCircle} />
                    {:else}
                      <Icon path={mdiCheckboxBlankCircleOutline} />
                    {/if}
                  </div>
                  <input type="radio" hidden aria-hidden bind:group={selectedReason} value={reason.key} />
                  <span class="checkbox-label">{$t(`exit_survey_${reason.key}`)}</span>
                </label>
              {/if}
            {/each}
            {#if selectedReason}
              <div class="more-info">
                {#if (selectedReason === 'too_expensive' || selectedReason === 'hard_to_learn') && !offerAccepted}
                  <div class="coupon">
                    <div class="coupon-inner">
                      <div class="coupon-inner-bg" />
                      <span class="period font-website-heading">{$t('exit_survey_offer_term')}</span>
                      <span class="amount font-website-heading">{$t('exit_survey_offer_value')}</span>
                    </div>
                  </div>
                  <p>{$t('exit_survey_offer_description')}</p>
                {:else if selectedReason === 'found_another_app'}
                  <h6>{$t('exit_survey_other_app_heading')}</h6>
                  <label for="other_app_name">{$t('exit_survey_other_app_name')}</label>
                  <input class="form-control" id="other_app_name" name="other_app_name" bind:value={otherAppName} />
                  <label for="other_app_reasons">{$t('exit_survey_other_app_reasons')}</label>
                  <textarea
                    class="form-control"
                    id="other_app_reasons"
                    name="other_app_reasons"
                    bind:value={otherAppReasons}
                  />
                {:else if selectedReason === 'missing_features'}
                  <h6>{$t('exit_survey_missing_features_heading')}</h6>
                  <textarea class="form-control" name="other_reason" bind:value={otherReason} />
                {:else if selectedReason === 'other'}
                  <textarea class="form-control" name="other_reason" bind:value={otherReason} />
                {/if}
              </div>
              <button class="btn text" on:click={resetSurvey}
                ><Icon path={mdiArrowLeft} />{$t('exit_survey_different_reason')}</button
              >
            {/if}
          </div>
          <div class="survey-section-footer">
            {#if (selectedReason === 'too_expensive' || selectedReason === 'hard_to_learn') && !offerAccepted}
              <button class="btn primary" on:click|preventDefault={applyOffer}>
                {#if saving}
                  <Icon path={mdiLoading} spin />
                {/if}
                {$t('exit_survey_accept_offer')}
              </button>
            {:else}
              <button class="btn secondary" on:click|preventDefault={backToBilling}
                >{$t('exit_survey_never_mind')}</button
              >
            {/if}
            <button class="btn danger" on:click|preventDefault={continueToCancel}>{$t('exit_survey_continue')}</button>
          </div>
        </section>
      </form>
    </section>
  {/if}
</BlankScreen>

<style>
  .section-heading {
    margin-top: 0;
  }
  .survey-section-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    padding-bottom: 8px;
  }
  .survey-section-footer button {
    margin-left: 7px;
  }
  .more-info label {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--text-color-lighter);
    margin-bottom: 0;
  }
  .more-info input {
    margin-bottom: 1rem;
  }
  .more-info h6 {
    margin-top: 1rem;
  }
  .coupon {
    width: 100%;
    border: 2px dashed var(--gray-lightish);
    padding: 10px;
  }
  .coupon-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    background-color: var(--calendar-background);
    position: relative;
  }
  .coupon-inner-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../../../images/icon-padded.png');
    background-repeat: no-repeat;
    background-size: 441px 441px;
    background-position: -90px -193px;
    opacity: 0.15;
  }
  .period {
    font-size: 32px;
    position: relative;
    line-height: 1;
    margin-bottom: 10px;
  }
  .amount {
    font-size: 64px;
    position: relative;
    text-transform: uppercase;
    line-height: 0.8;
  }
  .btn.text {
    color: var(--dabble-blue);
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 16px;
  }
  .btn.text :global(svg) {
    margin-right: 3px;
  }
  .checkbox-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .checkbox-input.checked .checkbox-icon {
    color: var(--dabble-blue);
  }

  .checkbox-icon {
    font-size: 24px;
    display: flex;
    align-items: center;
  }

  .checkbox-icon {
    margin-right: 7px;
  }
</style>
