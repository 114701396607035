<script>
  import { collective, confirm, dbStore, inform, plugins, t, unload, userProjects } from '@dabble/app';
  const hasServiceWorkers = 'serviceWorker' in navigator;

  async function clearCache() {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      await registration.unregister();
    }
    const keys = (await caches.keys()).filter(key => key.startsWith('webpack-offline:dabble-'));
    for (const key of keys) {
      await caches.delete(key);
    }

    location.reload();
  }

  async function clearImageCache() {
    await caches.delete('content');
    inform('success', $t('app_cache_images_cleared'));
  }

  async function deleteDatabase() {
    if ($dbStore) {
      const localCount = (
        await Promise.all(
          userProjects.get().map(({ projectId }) => {
            return collective.store.getChange(projectId, { committed: false });
          })
        )
      ).filter(Boolean).length;

      if (localCount) {
        const stop = !(await confirm($t('confirm_header_unsynced_data'), $t('confirm_unsynced_data'), {
          yesNo: true,
        }));
        if (stop) return;
      }

      unload(true);
    } else if ($plugins.uid) {
      const request = indexedDB.deleteDatabase(`dabble/${$plugins.uid}`);
      await new Promise(resolve => {
        request.onsuccess = resolve;
        request.onerror = resolve;
      });
    }
    location.reload();
  }
</script>

<div class="section data">
  <h2>{$t('app_cache')}</h2>

  {#if hasServiceWorkers}
    <div class="preference">
      <button type="button" class="btn primary" on:click={clearCache}>{$t('app_cache_clear')}</button>
      <div class="description">{$t('app_cache_clear_description')}</div>
    </div>

    <div class="preference">
      <button type="button" class="btn primary" on:click={clearImageCache}>{$t('app_cache_image_clear')}</button>
      <div class="description">{$t('app_cache_image_clear_description')}</div>
    </div>
  {/if}

  <div class="preference">
    <button type="button" class="btn primary" on:click={deleteDatabase}>{$t('app_db_delete')}</button>
    <div class="description">{$t('app_db_delete_description')}</div>
  </div>
</div>
