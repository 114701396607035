<script>
  import { features, size, t } from '@dabble/app';
  import ToolButton from '@dabble/toolkit/ToolButton.svelte';
  import { mdiComment, mdiCommentOff } from '@mdi/js';
  import { showComments } from '../comments-store';

  function toggle() {
    if (!$features.has('comments')) {
      features.promptUpgrade('comments');
    } else {
      $showComments = !$showComments;
    }
  }
</script>

{#if $size === 'desktop'}
  <ToolButton
    icon={$showComments ? mdiComment : mdiCommentOff}
    tooltip={$t(`comments_${$showComments ? 'hide' : 'show'}`)}
    on:click={toggle}
  />
{/if}
