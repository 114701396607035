<script>
  import { t } from '@dabble/app';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { checklist, showOnboardingLearnMore } from '../checklist';

  function close() {
    $showOnboardingLearnMore = false;
    checklist.update({ seenOnce: true });
  }
</script>

{#if $showOnboardingLearnMore}
  <Modal title={$t('onboarding_get_paid')} on:close={close}>
    <div class="learn-more">
      <section class="coupon">
        <div>
          <h1 class="font-website-heading">{@html $t('onboarding_coupon_title')}</h1>
          <h2 class="font-website-heading">{$t('onboarding_coupon_subtitle')}</h2>
        </div>
        <div>
          <p class="paid"><strong>{$t('onboarding_get_paid')}</strong></p>
          <p class="pay">{$t('onboarding_write_we_pay')}</p>
        </div>
        <p class="credits">{$t('onboarding_objective')}</p>
      </section>
    </div>
    <footer>
      <button class="btn primary" on:click={close}>{$t('onboarding_survey_submit')}</button>
    </footer>
  </Modal>
{/if}

<style>
  .learn-more {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .coupon {
    margin-top: 1rem;
    text-align: center;
    background: url('images/onboarding_coupon.png') no-repeat center center;
    width: 377px;
    height: 484px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 40px;
  }
  .learn-more h1 {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
  .learn-more h1 :global(span) {
    margin: 0;
    padding: 0;
  }
  .learn-more h2 {
    font-weight: normal;
    margin: 0;
    padding: 0;
    font-size: 20px;
    margin-top: -1rem;
  }
  .learn-more :global(.dollar) {
    font-size: 64px;
  }
  .learn-more :global(.value) {
    font-size: 96px;
  }
  .learn-more :global(.off) {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 32px;
  }
  .learn-more .paid {
    font-size: 24px;
    margin: 0;
    padding: 0;
  }
  .learn-more .pay {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }
  .learn-more .credits {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 0;
    padding-bottom: 0;
  }
</style>
