<script>
  import { t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { number } from '@dabble/toolkit/helpers';
  import { mdiHelpCircleOutline } from '@mdi/js';
  import { writeGoal } from '../rewards';

  $: end = $writeGoal.endDate;
  const oneDay = 24 * 60 * 60 * 1000;

  $: today = new Date().getTime();
  $: daysLeft = Math.ceil((end - today) / oneDay);
  $: hasRewardGoal = !$writeGoal?.rewarded && !$writeGoal?.expired && $writeGoal.wordsAdded < $writeGoal.goal;
  $: if (daysLeft < 0 && !$writeGoal.expired) writeGoal.update({ expired: true });

  function openInfo() {
    window.dispatchEvent(new CustomEvent($writeGoal.readme));
  }
</script>

{#if hasRewardGoal}
  <div class="card">
    <div class="row" on:click={openInfo} on:keydown={openInfo}>
      <span>{$t('onboarding_first_words', { goal: number($writeGoal.goal) })}</span>
      <div class="help"><Icon path={mdiHelpCircleOutline} /></div>
    </div>
    <div class="row">
      <div class="days-left">{daysLeft} {$t('onboarding_days_left')}</div>
      <div class="total">{$writeGoal.wordsAdded}/<strong>{$writeGoal.goal}</strong></div>
    </div>
  </div>
{/if}

<style>
  .help {
    cursor: point;
  }
  .card {
    background: var(--white);
    color: var(--gray-light);
    border: 1px solid var(--gray-lightish);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .row span {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
  }
  .row .days-left {
    line-height: 1;
  }

  .row .total {
    font-size: 14px;
  }
  .row .total strong {
    font-size: 16px;
  }
</style>
