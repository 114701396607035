import { Doc } from './types';

type DocMap = { [id: string]: Doc };

export function isDocEmpty(doc: Doc, docs: DocMap): boolean {
  return (
    !doc.title &&
    !(doc.description && doc.description.length > 1) &&
    !(doc.body && doc.body.length > 1) &&
    !(doc.children && doc.children.length && doc.children.some(childId => !isDocEmpty(docs[childId], docs))) &&
    !doc.image?.url
  );
}
