import { ProjectStore } from './stores/project';
import { Doc } from './types';
import { idLengths } from './uuid';

export type GetURLFunction = (doc: string | Doc, projectId?: string) => string;

export function createGetUrl(project: ProjectStore): GetURLFunction {
  return function getUrl(doc: Doc | string, projectId?: string) {
    if (!doc && !projectId) return '/';
    const docId = !doc ? projectId : typeof doc === 'string' ? doc : doc.id;
    if (!projectId) {
      if (docId.length === idLengths.project) projectId = docId;
      else {
        const currentProject = project.get().project;
        projectId = currentProject && currentProject.id;
      }
    }
    if (!projectId || !docId) return '/';
    return projectId === docId ? `/p/${projectId}/` : `/p/${projectId}/${docId}`;
  };
}
