<script lang="ts">
  import { confirm, currentDoc, findReplace, settings, t } from '@dabble/app';
  import { getSetting } from '@dabble/data/stores/settings';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import { enterKey } from '@dabble/toolkit/events';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Interactions from '@dabble/toolkit/Interactions.svelte';
  import { fly, slide } from '@dabble/toolkit/transitions';
  import {
    mdiCheckBold,
    mdiChevronDown,
    mdiChevronUp,
    mdiClose,
    mdiCog,
    mdiFile,
    mdiFindReplace,
    mdiMagnify,
    mdiNotebook,
  } from '@mdi/js';

  let settingsVisible: boolean;
  let lastFocused: HTMLElement;
  let findInput: HTMLInputElement;
  let text = '';
  let replaceText = '';
  let timeout: ReturnType<typeof setTimeout>;

  $: updateText($findReplace.text);
  $: textUpdated(text);
  $: updateReplaceText($findReplace.replaceText);
  $: replaceTextUpdated(replaceText);

  function updateFind(findText: string) {
    clearTimeout(timeout);
    if (findText.length) {
      // Wait 0.75 seconds after typing 1 letter, 0.5 seconds after 2, 0.25 after 3 letters or more
      const wait = (Math.max(1, 4 - findText.length) / 4) * 1000;
      timeout = setTimeout(() => findReplace.update({ text: findText }), wait);
    } else {
      findReplace.update({ text: findText });
    }
  }

  function updateText(value: string) {
    if (text !== value) {
      text = value;
    }
  }

  function textUpdated(value: string) {
    if ($findReplace.text !== value) {
      updateFind(text);
    }
  }

  function updateReplaceText(value: string) {
    if (replaceText !== value) {
      replaceText = value;
    }
  }

  function replaceTextUpdated(value: string) {
    if ($findReplace.replaceText !== value) {
      findReplace.update({ replaceText: value });
    }
  }

  function onClose() {
    settingsVisible = false;
    (lastFocused || findInput).focus();
  }

  function setLastFocused(event: Event) {
    lastFocused = event.target as HTMLElement;
  }

  function findNext(event: Event) {
    event.preventDefault();
    findReplace.findNext();
  }

  function replace(event: Event) {
    event.preventDefault();
    findReplace.replace();
  }

  async function replaceAll() {
    if (await confirm($t('confirm_header_replace'), $t('confirm_replace'))) {
      findReplace.replaceAll();
    }
  }
</script>

<Interactions on:shortcut-Escape={findReplace.close} />

<div class="find-replace" transition:fly={{ duration: 150, y: -40 }}>
  <button
    class="with-icon dropdown-toggle"
    class:active={settingsVisible}
    type="button"
    on:click={() => (settingsVisible = !settingsVisible)}
  >
    <Icon path={mdiCog} />
  </button>
  {#if settingsVisible}
    <Dropdown on:close={onClose} class="find-replace-dd">
      <button class="dropdown-item" on:click={() => findReplace.update({ replaceOpen: false })}>
        <span class="check">
          {#if !$findReplace.replaceOpen}<Icon path={mdiCheckBold} />{/if}
        </span>
        <Icon path={mdiMagnify} />
        {$t('find')}
      </button>
      <button class="dropdown-item" on:click={() => findReplace.update({ replaceOpen: true })}>
        <span class="check">
          {#if $findReplace.replaceOpen}<Icon path={mdiCheckBold} />{/if}
        </span>
        <Icon path={mdiFindReplace} />
        {$t('find_replace')}
      </button>
      <hr />
      <button class="dropdown-item" on:click={() => findReplace.update({ inProject: false })}>
        <span class="check">
          {#if !$findReplace.inProject}<Icon path={mdiCheckBold} />{/if}
        </span>
        <Icon path={getSetting($settings[$currentDoc.type].icon, $currentDoc) || mdiFile} />
        {$t('find_in')}
        {$t($currentDoc.type)}
      </button>
      <button class="dropdown-item" on:click={() => findReplace.update({ inProject: true })}>
        <span class="check">
          {#if $findReplace.inProject}<Icon path={mdiCheckBold} />{/if}
        </span>
        <Icon path={mdiNotebook} />
        {$t('find_in_project')}
      </button>
      <hr />
      <button
        class="dropdown-item"
        on:click={() => findReplace.update({ matchWholeWords: !$findReplace.matchWholeWords })}
      >
        <span class="check">
          {#if $findReplace.matchWholeWords}<Icon path={mdiCheckBold} />{/if}
        </span>
        {$t('match_word')}
      </button>
      <button class="dropdown-item" on:click={() => findReplace.update({ matchCase: !$findReplace.matchCase })}>
        <span class="check">
          {#if $findReplace.matchCase}<Icon path={mdiCheckBold} />{/if}
        </span>
        {$t('match_case')}
      </button>
      <button class="dropdown-item" on:click={() => findReplace.update({ matchRegex: !$findReplace.matchRegex })}>
        <span class="check">
          {#if $findReplace.matchRegex}<Icon path={mdiCheckBold} />{/if}
        </span>
        {$t('use_regex')}
      </button>
    </Dropdown>
  {/if}

  <div class="inputs">
    <div class="row">
      <input
        bind:this={findInput}
        id="findInput"
        type="text"
        class="form-control"
        placeholder={$t('find')}
        bind:value={text}
        use:enterKey={findNext}
        on:focus={setLastFocused}
      />
      <button class="icon" disabled={!$findReplace.map} on:click={() => findReplace.findPrevious()}>
        <Icon path={mdiChevronUp} />
      </button>
      <button class="icon" disabled={!$findReplace.map} on:click={() => findReplace.findNext()}>
        <Icon path={mdiChevronDown} />
      </button>
      <button class="icon close-btn" on:click={findReplace.close}>
        <Icon path={mdiClose} />
      </button>
    </div>

    {#if $findReplace.replaceOpen}
      <div class="row replace" transition:slide={{ duration: 150 }} on:submit={replace}>
        <input
          id="replaceInput"
          type="text"
          class="form-control"
          placeholder={$t('replace')}
          bind:value={replaceText}
          use:enterKey={replace}
          on:focus={setLastFocused}
        />
        <button class="btn small link" disabled={!$findReplace.map} on:click={() => findReplace.replace()}>
          <Icon path={mdiFindReplace} size="20px" /><br />
          {$t('replace_once')}
        </button>
        <button class="btn small link" disabled={!$findReplace.mapAll} on:click={() => replaceAll()}
          ><Icon path={mdiFindReplace} size="20px" /><br /> {$t('replace_all')}
        </button>
      </div>
    {/if}
  </div>
</div>

<style>
  .find-replace {
    position: absolute;
    z-index: 980;
    top: calc(var(--app-header-height) - 4px);
    right: calc(var(--side-nav-width) - 4px);
    background: var(--app-header-background);
    box-shadow: var(--app-header-shadow);
    border-radius: 3px;
    padding: 3px 0;
    display: flex;
  }
  @media (max-width: 600px) {
    .find-replace {
      right: 10px;
    }
  }
  .find-replace .dropdown-toggle :global(.icon) {
    font-size: 20px;
    height: 38px;
  }
  .btn.link {
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
  }
  .btn.link:not([disabled]):hover {
    color: var(--text-color-normal);
    text-decoration: none;
  }
  .inputs {
    flex: 1;
  }
  .inputs input {
    width: 220px;
  }
  .inputs .row {
    display: flex;
    align-items: center;
  }
  .inputs .row + .row {
    margin-top: 2px;
  }
  .replace .small.link {
    line-height: 1;
    width: 42px;
  }
  .find-replace .close-btn {
    margin-right: 4px;
  }
  :global(.find-replace-dd .replace-all) {
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.02em;
    padding: 0.25rem 0.5rem;
    margin-right: 4px;
  }
  .check {
    display: flex;
    margin-left: -16px;
    width: 16px;
    height: 16px;
  }
  .dropdown-item .check :global(.icon) {
    color: var(--text-color-lighterer);
    font-size: 16px;
    margin-right: 0;
  }
</style>
