import { plugins, tabStore, uid } from '@dabble/app';
import { derived } from '@dabble/data/stores/store';
import { createSyncingStore } from './stores/syncing';
import { createTabStore } from './stores/tab';

export const syncing = createSyncingStore();
export const tab = createTabStore(uid);
export const syncState = derived(syncing, $syncing => {
  if ($syncing === 'error') return syncing.getError();
  return !!$syncing;
});
tabStore.set(tab);

plugins.register({ syncing, tab });
