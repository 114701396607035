import { DatabaseStore } from '../dabble-database';
import { UserProject } from '../types';
import { createModifiedDataStore, ModifiedDataStore } from './modified-data';

export type UserProjectsStore = ModifiedDataStore<UserProject>;

const MAX = Number.MAX_SAFE_INTEGER;
export const userProjectSort = (a: UserProject, b: UserProject) => (b.lastOpened || MAX) - (a.lastOpened || MAX);

export const userProjectFields = new Set([
  'projectId',
  'lastOpened',
  'lastSelected',
  'trashed',
  'deleted',
  'modified',
  'uid',
  'category',
  'isTemplate',
]);

export function createUserProjectsStore(dbStore: DatabaseStore): UserProjectsStore {
  return createModifiedDataStore(dbStore, 'user_projects', {
    sort: userProjectSort,
    fields: userProjectFields,
  });
}
