<script lang="ts">
  import { t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { mdiEmail, mdiOpenInNew } from '@mdi/js';
  import { emailShared, sharedModalOpen } from '../html';

  function close() {
    $sharedModalOpen = '';
  }

  function openSharedDocument() {
    window.open($sharedModalOpen);
    close();
  }

  function emailDocument() {
    $emailShared = $sharedModalOpen;
    $sharedModalOpen = '';
  }
</script>

{#if $sharedModalOpen}
  <Modal title={$t('export_to_web')} on:close={close}>
    <section class="">
      <div class="section-flex">
        <div>{@html $t('shared_modal_text')}</div>
      </div>
    </section>
    <footer>
      <button class="btn primary" on:click={emailDocument}>
        <Icon path={mdiEmail} />
        {$t('shared_send_email')}
      </button>
      <button class="btn primary" on:click={openSharedDocument}>
        <Icon path={mdiOpenInNew} />
        {$t('shared_view')}
      </button>
    </footer>
  </Modal>
{/if}

<style>
  .section-flex {
    display: flex;
  }
  .section-flex > :not(:first-child) {
    margin-left: 40px;
  }
  :global(.size-mobile) .section-flex {
    flex-direction: column;
  }
  :global(.size-mobile) .section-flex > :not(:first-child) {
    margin-left: 0;
    margin-top: 40px;
  }
</style>
