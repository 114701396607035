import { $t, collective, createProject, creatingProject, getNow, projectMetas, projectStore } from '@dabble/app';
import { Change, createChangeId } from '@dabble/data/collective/changes';
import { Project, ProjectMeta, UserProject } from '@dabble/data/types';
import { cloneDeep, Delta } from 'typewriter-editor';

interface TextChange {
  docId: string;
  field: string;
  delta: Delta;
}

export function duplicateProject(userProject: UserProject) {
  return duplicateProjectById(userProject.projectId);
}

export async function duplicateProjectById(projectId: string) {
  await projectStore.load(projectId);
  const proj = projectStore.get();
  projectStore.unload();
  const metas = projectMetas.get();

  const project = cloneDeep(proj.project) as Project;
  const projectMeta = cloneDeep(metas[projectId]) as ProjectMeta;

  delete project.version;
  delete project.created;
  delete project.committed;
  delete project.changeId;
  delete project.id;

  delete projectMeta.id;
  delete projectMeta.created;
  delete projectMeta.committed;
  delete projectMeta.modified;
  delete projectMeta.isTemplate;

  // Add copy to title
  const hasTitle = Boolean(projectMeta.title);
  projectMeta.title = projectMeta.title
    ? $t('project_title_copy', { title: projectMeta.title, interpolation: { escapeValue: false } })
    : $t('project_copy');

  if (!hasTitle) {
    const bookId = project.docs.manuscript.children[0];
    project.docs[bookId].title = projectMeta.title;
  }

  const textChanges: TextChange[] = [];

  Object.keys(project.docs).forEach(docId => {
    const doc = { ...project.docs[docId] };
    if (doc.description) {
      textChanges.push({ docId: doc.id, field: 'description', delta: doc.description as any as Delta });
      delete doc.description;
    }
    if (doc.body) {
      textChanges.push({ docId: doc.id, field: 'body', delta: doc.body as any as Delta });
      delete doc.body;
    }
    project.docs[docId] = doc;
  });

  return createDuplicate(project, projectMeta, textChanges);
}

async function createDuplicate(project: any, projectMeta: ProjectMeta, textChanges: TextChange[]) {
  const { userProject } = await createProject(project, projectMeta);
  creatingProject.set(userProject.projectId);
  const { projectId } = userProject;
  let lastChange = await collective.store.getChange(projectId, { reverse: true, limit: 1 });

  const transaction = collective.store.start();

  textChanges.forEach(({ docId, field, delta }: TextChange) => {
    const ops = [{ op: '@changeText', path: `/docs/${docId}/${field}`, value: delta.ops }];
    const change = {
      id: createChangeId(),
      prevId: lastChange.id,
      projectId,
      version: lastChange.version + 1,
      author: collective.author,
      created: getNow(),
      committed: 0,
      ops,
    } as Change;

    transaction.putChange(change);

    lastChange = change;
  });

  await transaction.commit();
  creatingProject.set('');
  return userProject;
}
