<script lang="ts">
  import { currentDoc, features, focus, hideLeftNav, hideRightNav, settings, size, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Title from '@dabble/toolkit/Title.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiUnfoldLessVertical, mdiUnfoldMoreVertical } from '@mdi/js';
  import FocusLock from './FocusLock.svelte';

  export let paddingLeft = 0;
  export let paddingRight = 0;

  $: docSettings = $currentDoc && $settings[$currentDoc.type];
</script>

<div class="status-bar" style="padding-left:{paddingLeft}px;padding-right:{paddingRight}px;">
  {#if !$focus.focused && !$focus.autofocused}
    <div class="status-bar-left focus-fade">
      {#if $size === 'desktop'}
        <div class="control-buttons-left">
          <button
            class="icon sidebar-toggle"
            on:click={() => hideLeftNav.set(!$hideLeftNav)}
            use:tooltipTop={$t('toggle_navigation')}
          >
            <Icon path={$hideLeftNav ? mdiUnfoldMoreVertical : mdiUnfoldLessVertical} />
          </button>
        </div>
      {/if}
      {#each settings.getValuesFromPlugins('statusBarLeft') as component}
        <svelte:component this={component} />
      {/each}
    </div>
    {#if $size === 'desktop'}
      <div class="status-bar-center focus-fade">
        {#if $currentDoc}
          <Title class="doc-name" doc={$currentDoc} />
        {/if}
      </div>
    {/if}
    <div class="status-bar-right focus-fade">
      {#each settings.getValuesFromPlugins('statusBarRight') as component}
        <svelte:component this={component} />
      {/each}
      {#if $size === 'desktop'}
        <div class="control-buttons-right" class:has-focus={$features.has('focus')}>
          <FocusLock />
          <button
            class="icon sidebar-toggle"
            on:click={() => hideRightNav.set(!$hideRightNav)}
            use:tooltipTop={$t('toggle_sidebar')}
            disabled={docSettings && docSettings.hideRightNav}
          >
            <Icon path={$hideRightNav ? mdiUnfoldMoreVertical : mdiUnfoldLessVertical} />
          </button>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .status-bar {
    display: flex;
    height: var(--status-bar-height);
    flex: 0 0 var(--status-bar-height);
    background: var(--status-bar-background);
    z-index: 3;
    box-shadow: var(--status-bar-shadow);

    font-size: var(--font-size-sm);
    color: var(--text-color-light);
    cursor: text;
    -webkit-user-select: text;
    user-select: text;
    justify-content: space-between;
    padding: 0 16px;
  }
  :global(.size-desktop) .status-bar {
    justify-content: normal;
    padding: 0;
  }
  .status-bar :global(.icon) {
    font-size: 20px;
  }
  :global(.is-touch) .status-bar :global(.icon) {
    font-size: 25px;
  }
  .control-buttons-left {
    display: flex;
    margin-left: 4px;
  }
  .control-buttons-right {
    display: flex;
    margin-right: 4px;
  }
  .status-bar :global(.doc-name) {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .status-bar :global(.btn:hover),
  .status-bar :global(.btn:active),
  .status-bar :global(.btn.active),
  .status-bar :global(.btn[disabled]),
  .status-bar :global(.btn[disabled]:hover) {
    box-shadow: none;
    border: none;
    background: none;
  }
  .status-bar :global(.btn:not([disabled]):hover),
  .status-bar :global(.btn:active),
  .status-bar :global(.btn.active) {
    color: var(--text-color-normal);
  }
  .status-bar :global(.btn .icon) {
    line-height: inherit;
  }
  .status-bar :global(.btn .icon:first-child:last-child) {
    font-size: 20px;
    line-height: 18px;
    height: 18px;
  }
  .status-bar :global(.status-bar-nav) {
    display: flex;
    width: 210px;
    box-sizing: border-box;
    height: 100%;
  }
  .status-bar :global(.status-bar-nav .btn) {
    display: flex;
    align-items: center;
    margin: 4px;
    height: 30px;
    line-height: 14px;
    border-radius: 5px;
    color: var(--text-color-lighter);
  }
  .status-bar :global(.status-bar-nav .btn[disabled]) {
    opacity: 0.5;
    cursor: default;
    -webkit-app-region: drag;
  }
  .status-bar :global(.status-bar-nav .btn:not([disabled]):hover) {
    color: var(--text-color-normal);
  }
  .status-bar-left,
  .status-bar-center,
  .status-bar-right {
    display: flex;
    align-items: center;
  }
  :global(.size-desktop) .status-bar-left,
  :global(.size-desktop) .status-bar-right {
    flex: 1;
    min-width: 0;
  }
  .status-bar-center {
    justify-content: center;
    padding: 0 4px;
  }
  .status-bar-right {
    justify-content: flex-end;
  }
</style>
