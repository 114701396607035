import { tick } from 'svelte';
import { Collective } from './data/collective/collective';
import { CollectiveBrowserbaseStore } from './data/collective/stores/browserbase-store';
import * as computed from './data/computed';
import { Connection } from './data/connection';
import { DatabaseStore, createDatabaseStore, deleteDatabase } from './data/dabble-database';
import { DESKTOP, isMobileMode, size, touchEnabled } from './data/device';
import { t } from './data/intl';
import { pullSessionFromUrl } from './data/session-data';
import { signal } from './data/signals';
import { createAnalytics } from './data/stores/analytics';
import { createDocStore as createCurrentDocStore } from './data/stores/doc';
import { createDocumentStore } from './data/stores/documents';
import { createFeaturesStore } from './data/stores/features';
import { createFindReplaceStore } from './data/stores/find-replace';
import { createFocusStore } from './data/stores/focus';
import { createGlobalDataStore } from './data/stores/global-data';
import { createLastProductUrl, createLastUrl } from './data/stores/history';
import { createLoadedProjectsStore } from './data/stores/loaded-projects';
import { dynamicStoredWritable, locallyStoredWritable } from './data/stores/locally-stored-writable';
import { createPluginsStore } from './data/stores/plugins';
import { cleanupProject, createProjectStore } from './data/stores/project';
import { createCurrentProjectsMetaStore, createProjectsMetaStore } from './data/stores/project-metas';
import { createReadOnlyStore } from './data/stores/readonly';
import routerStore from './data/stores/router';
import { createSettingsStore, getSetting } from './data/stores/settings';
import { Readable, Unsubscriber, derived, writable } from './data/stores/store';
import { createUserDocsStore } from './data/stores/user-docs';
import { createUserGoalStore } from './data/stores/user-goal';
import { createUserMetaStore, createUserMetaTypeStore } from './data/stores/user-meta';
import { createUserProjectsStore } from './data/stores/user-projects';
import { createUserStatsStore } from './data/stores/user-stats';
import { createViewport } from './data/stores/viewport';
import {
  AppUpdate,
  Doc,
  ExternalMeta,
  ObjectWithSettings,
  Preferences,
  Project,
  ProjectDocs,
  ProjectMeta,
  ProjectSettings,
} from './data/types';
import { Globals, ShownCard } from './data/ui';
import { createGetUrl } from './data/urls';
import { uuid } from './data/uuid';
import { desktop } from './desktop';
import { setupFindReplace } from './find/find-setup';
import type { TabStore } from './plugins/sync/stores/tab';
import { iconVariations } from './toolkit/icons';
import { version } from './version';

declare global {
  interface Window {
    version: string;
  }
}

const MAX_COMPONENT_DEPTH = 20;

export { Delta } from 'typewriter-editor';
export * from './data/date';
export * from './data/device';
export * from './data/intl';
export * from './data/observe';
export * from './version';
export { desktop };
export let $t = t.get();
t.subscribe(value => ($t = value));

if (location.hostname === 'www.dabblewriter.com') {
  location.href = location.href.replace('www', 'app').replace('/app/', '/');
} else if (location.hash) {
  const path = location.hash.replace('#', '') || '/';
  history.replaceState(path, null, path);
}

let base = location.pathname.split('/').slice(0, 2).join('/');
if (!/^\/(beta|test)/.test(base)) base = '';
export const router = routerStore(base);
pullSessionFromUrl(router);
export const delegate = sessionStorage.delegate;

const browserbaseStore = new CollectiveBrowserbaseStore<Project>(null, {
  snapshotsStore: 'project_snapshots',
  changesStore: 'project_changes',
});

export const collective = new Collective<Project>({
  store: browserbaseStore,
  author: '',
  serverTimeOffset: () => parseInt(localStorage.timeOffset) || 0,
  snapshotInterval: 200,
});

export const YY = new Date().toISOString().slice(0, 2);
// Data in session
export const dbStore = createDatabaseStore() as DatabaseStore;
export const deviceId = writable(localStorage.deviceId || (localStorage.deviceId = uuid()));
export const connectionState = writable<Connection>({
  online: false,
  connected: false,
  authed: false,
  serverTimeOffset: 0,
});
export { default as rest } from './data/rest';
export const isLeader = writable(false);
export const online = derived(connectionState, connection => connection.online);
export const connected = derived(connectionState, connection => connection.connected);
export const authed = derived(connectionState, connection => connection.authed);
export const settings = createSettingsStore();
export const readonly = createReadOnlyStore();
export const ready = writable(false);
export const readingNovel = writable(false);
export const isSyncTab = writable(false);
export const printReady = writable(false);
export const syncReady = derived([isSyncTab, ready, authed, dbStore], ([isSyncTab, ready, authed, db]) =>
  Boolean(isSyncTab && ready && authed && db)
);
export const syncing = writable(false);
export const finishedSyncing = signal<boolean>();
export const finishedProjectLoad = signal();
export const fromRemote = new Set<any>();
export const userProjects = createUserProjectsStore(dbStore);
export const projectMetas = createProjectsMetaStore(dbStore);
export const projectStore = createProjectStore(settings, collective);
export const currentProjectMeta = createCurrentProjectsMetaStore(projectMetas, projectStore);
export const currentDocId = writable<string>(null);
export const currentDoc = createCurrentDocStore(projectStore, currentDocId);
export const currentDocSettings = derived(
  [currentDoc, settings],
  ([currentDoc, settings]) => currentDoc && settings[currentDoc.type]
);
export const userStats = createUserStatsStore(dbStore, collective, projectStore);
export const userDocs = createUserDocsStore(dbStore, projectStore);
export const projectGoalStore = createUserGoalStore(dbStore, projectStore);
export const dailyGoalStore = createUserGoalStore(dbStore, 'daily');
export const daysOffStore = createUserGoalStore(dbStore, 'daysOff');
export const loadedProjects = createLoadedProjectsStore(dbStore);
export const creatingProject = writable('');
export const selectedCount = writable(0);
export const globalData = createGlobalDataStore(dbStore);
export const userMeta = createUserMetaStore(dbStore, fromRemote);
export const features = createFeaturesStore();
export const preferences = createUserMetaTypeStore<Preferences>(userMeta, 'preferences');
export const externalMeta = createUserMetaTypeStore<ExternalMeta>(userMeta, 'external');
export const analytics = createAnalytics(dbStore);
export const uid = writable<string>(undefined); // Expose outside of the auth plugin as an essential value for permissions
export const userRole = derived([uid, currentProjectMeta], ([uid, projectMeta]) => projectMeta?.roles?.[uid]);
export const signals = {
  ready: signal<void>(),
};
export const plugins = createPluginsStore();
export const tabStore = writable<TabStore>(null);

export const isOnline = writable(window.navigator.onLine);

window.addEventListener('online', () => isOnline.set(true));
window.addEventListener('offline', () => isOnline.set(false));

export function connectionSend<T = any>(name: string, ...args: any[]): Promise<T> {
  return tabStore.get()?.call('connectionSend', name, ...args);
}

export function connectionSendAfterAuthed<T = any>(name: string, ...args: any[]): Promise<T> {
  return tabStore.get()?.call('connectionSendAfterAuthed', name, ...args);
}

export function connect(): Promise<void> {
  return tabStore.get()?.call('connect');
}

export function disconnect(): Promise<void> {
  return tabStore.get()?.call('disconnect');
}

// Data methods

export async function load(uid: string) {
  ready.set(false);
  dbStore.close();

  collective.setAuthor(uid);
  await dbStore.open(uid);
  const db = dbStore.get();

  browserbaseStore.db = db;
  collective.store = browserbaseStore;
  await collective.open();

  ready.set(true);
  signals.ready();
}

export function unload(shouldDeleteDb = false, uid?: string) {
  if (ready.get()) {
    ready.set(false);
    unloadProject();
    collective.close();
  }
  if (!dbStore.get()) {
    if (uid && shouldDeleteDb) {
      deleteDatabase(uid);
      caches.delete('content');
      caches.delete('app');
      caches.delete('spelling');
    }
  } else {
    dbStore.close(shouldDeleteDb);
  }
}

export async function createProject(newProject?: Partial<Project>, meta?: Partial<ProjectMeta>) {
  const newProjectObj = await projectStore.createProject(newProject);
  creatingProject.set(newProjectObj.id);
  const projectMeta = await projectMetas.update(newProjectObj.id, {
    ...newProjectObj,
    ...meta,
    roles: { [collective.author]: 'owner' },
  });
  const userProject = await userProjects.update(newProjectObj.id, {});
  loadedProjects.mark(newProjectObj.id, true);
  const detail = { project: newProjectObj, userProject, projectMeta };
  window.dispatchEvent(new CustomEvent('projectcreated', { detail }));
  creatingProject.set('');
  return detail;
}

export async function trashOrDeleteDoc(docId: string, deletePermanently = false) {
  if (!docId) return;

  if (docId === currentDocId.get()) {
    const project = projectStore.get();
    router.navigate(getUrl(project.parentsLookup[docId]));
  }

  await tick();

  if (deletePermanently) {
    projectStore.deleteDoc(docId);
  } else {
    projectStore.trashDoc(docId);
  }
}

export async function deleteProject(projectId: string, forceDelete = false) {
  const userProject = await userProjects.delete(projectId, forceDelete);
  const projectMeta = await projectMetas.get()[projectId];
  if (!userProject && projectMeta) {
    projectMetas.delete(projectId);
  } else if (userProject.deleted) {
    const { ...roles } = projectMeta.roles;
    const uid = plugins.stores.uid?.get();
    delete roles[uid];
    await Promise.all([
      projectMetas.update(projectId, { roles }),
      browserbaseStore.deleteProject(projectId),
      userDocs.deleteAllFor(projectId),
      projectGoalStore.delete(projectId),
    ]);
  }
  return userProject;
}

export async function loadProject(projectId: string) {
  await projectStore.load(projectId);
  await new Promise(resolve => userDocs.subscribe(() => userDocs.isLoaded.get() && resolve(null)));
  await cleanupProject(projectStore);
  await finishedProjectLoad();
}

export function unloadProject() {
  projectStore.unload();
}

export const getUrl = createGetUrl(projectStore);

export async function selectProject(projectId: string) {
  const userProject = userProjects.get().find(up => up.projectId === projectId);
  if (!userProject) return;
  await router.navigate(getUrl(userProject.lastSelected, userProject.projectId));
  if (!userProject.lastSelected) {
    if (projectStore.get().project) gotoDefault(projectStore.get().project);
    else {
      const unsubscribe = projectStore.subscribe(data => {
        if (data.project) {
          unsubscribe();
          gotoDefault(data.project);
        }
      });
    }
  }
}

export function getProjectUrl(project: Project) {
  const userProject = userProjects.get().find(up => up.projectId === project.id);
  if (userProject?.lastSelected) {
    return getUrl(userProject.lastSelected, userProject.projectId);
  } else {
    const projectSettings = settings.getFor(project) as ProjectSettings;
    if (projectSettings.getDefaultDocument) {
      const doc = projectSettings.getDefaultDocument(project);
      return getUrl(doc.id, project.id);
    }
  }
  return getUrl(null, project.id);
}

function gotoDefault(project: Project) {
  const projectSettings = settings.getFor(project) as ProjectSettings;
  if (!projectSettings.getDefaultDocument) return;
  const doc = projectSettings.getDefaultDocument(project);
  if (!doc) return;
  router.navigate(getUrl(doc.id, project.id), true);
}

export function navigateToDoc(docId: string) {
  selectDoc(docId);
  displayedDocId.set(docId);
  createViewport(displayedDocId);
  router.navigate(getUrl(docId, projectStore.get().project.id), true);
}

export function selectDoc(docId: string) {
  currentDocId.set(docId);
  if (docId && projectStore.get().project && !delegate) {
    userProjects.update(projectStore.get().projectId, { lastSelected: docId });
  }
}

export function isDocEmpty(docId: string | Doc): boolean {
  const doc = typeof docId === 'string' ? projectStore.get().docs[docId] : docId;
  return computed.isDocEmpty(doc, projectStore.get().docs);
}

export function deleteTooltipText(docId: string | Doc) {
  const doc = typeof docId === 'string' ? projectStore.get().docs[docId] : docId;
  return isDocEmpty(doc) ? 'delete_item' : 'send_to_trash';
}

export function getPlaceholderClass(doc: ObjectWithSettings, field = 'title'): string {
  if (!doc || (doc as any)[field]) return '';
  return settings.getPlaceholderClass(doc, field);
}

export function getTitle(doc: ObjectWithSettings, field = 'title'): string {
  const project = doc as Project;
  if (project && project.docs && project.links) {
    doc = projectMetas.get()[project.id];
  }
  if (!doc) return '';
  return (doc as any)[field] || settings.getPlaceholder(doc, field) || '';
}
export function getIcon(doc: Doc): string {
  return (doc.icon && iconVariations[doc.type]?.[doc.icon]) || getSetting(settings.getFor(doc)?.icon, doc);
}

export function getAllOfType(within: Doc, docs: ProjectDocs, type: string, getDocs: true): Doc[];
export function getAllOfType(within: Doc, docs: ProjectDocs, type: string, getDocs?: false): string[];
export function getAllOfType(within: Doc, docs: ProjectDocs, type: string, getDocs = false): string[] | Doc[] {
  if (within.type === type) return getDocs ? [within] : [within.id];
  if (!within.children) return [];
  return within.children.reduce(
    (all, childId) => (docs[childId] ? all.concat(getAllOfType(docs[childId], docs, type, getDocs as any)) : all),
    []
  );
}

export function waitFor<T>(store: Readable<T>, condition: (value: T) => boolean): Promise<T> {
  return new Promise(resolve => {
    let resolved = false;
    let unsub: Unsubscriber;
    // eslint-disable-next-line prefer-const
    unsub = store.subscribe(data => {
      if (condition(data)) {
        if (unsub) unsub();
        else resolved = true;
        resolve(data);
      }
    });
    if (resolved) unsub();
  });
}

// UI
export const globals: Globals = new Globals();
export const documents = createDocumentStore();
export const loadingQuoteCleared = writable(false);
export const hideMain = writable(false);
export const hideLeftNav = locallyStoredWritable('hideLeftNav', false);
export const hideRightNav = locallyStoredWritable('hideRightNav', false);
export const sidebar = locallyStoredWritable('sidebar', 'goals'); // The visible sidebar (e.g. 'goals')
export const leftNavWidth = locallyStoredWritable<number>('leftNavWidth', 227);
if (leftNavWidth.get() < 180) leftNavWidth.set(null);
export const sidebarWidthKey = derived(sidebar, sidebar => `sidebar-size-${sidebar}`);
export const sidebarWidth = dynamicStoredWritable<number>(sidebarWidthKey, 227);
sidebarWidth.subscribe(value => {
  if (value < 180) sidebarWidth.set(null);
});
export const workspaceWidth = writable(0);
export const mobileShowNav = writable<'left' | 'right' | null>(null);
export const focus = createFocusStore(features, preferences, router);
export const writing = writable(false);
export const virtualVisible = writable<any[]>(null);
export const displayedDoc = writable<Doc>(null); // Will dispatch after the doc is finished displaying
export const displayingDoc = writable<boolean>(false); // True while the doc is displaying
export const displayedDocId = writable<string>(null); // Will dispatch after the doc is finished displaying for the first time only
export const viewport = createViewport(displayedDocId);
export const findReplace = createFindReplaceStore(settings, projectStore, currentDoc, router, getUrl, viewport);
setupFindReplace(findReplace, preferences, router);
export const appUpdate = writable<AppUpdate>({ version });
export const appHeader = writable({ hideLogo: false });
export const shownCard = writable<ShownCard>(null);
export const shownModal = writable<string>(null);
export const lastUrl = createLastUrl(router);
export const lastProductUrl = createLastProductUrl(lastUrl);
desktop.ui = { appUpdate, globals };
export const font = writable<string>('');
export const readToMeDocId = writable(null);
export const isTouch = writable(touchEnabled);
export const editingMode = writable<0 | 1 | 2>(isMobileMode ? 1 : 0); // 0 not mobile, 1 mobile not-editing, 2 mobile editing
export const showLeftPane = derived(
  [focus, hideLeftNav, size, mobileShowNav, editingMode],
  ([focus, hideLeftNav, size, mobileShowNav, editingMode]) =>
    !focus.focused && !(hideLeftNav || (size !== DESKTOP && mobileShowNav !== 'left')) && editingMode !== 2
);
export const showRightPane = derived(
  [focus, hideRightNav, currentDocSettings, size, mobileShowNav, editingMode],
  ([focus, hideRightNav, currentDocSettings, size, mobileShowNav, editingMode]) =>
    !focus.focused &&
    !(hideRightNav || currentDocSettings?.hideRightNav || (size !== DESKTOP && mobileShowNav !== 'right')) &&
    editingMode !== 2
);
export const showRightNav = derived(
  [showRightPane, sidebar, projectStore, currentDoc],
  ([showRightPane, sidebar, projectStore, currentDoc]) =>
    showRightPane && settings.getValuesFromPlugins('sidebar.' + sidebar, projectStore.project, currentDoc).length > 0
);
export const sideToolbarWidth = 49;
export const rightPaneTotalWidth = derived(
  [showRightNav, sidebarWidth],
  ([showRightNav, sidebarWidth]) => ((showRightNav && sidebarWidth) || 0) + sideToolbarWidth
);

size.subscribe(size => {
  if (size === 'desktop' && editingMode.get()) {
    editingMode.set(0);
  } else if (isMobileMode && !editingMode.get()) {
    editingMode.set(1);
  }
});

export async function afterAllUpdates(callback: Function) {
  await waitForUpdates();
  callback();
}

export async function waitForUpdates() {
  for (let i = 0; i < MAX_COMPONENT_DEPTH; i++) {
    await tick();
  }
}

export function inform(type: string, title: string, message?: string): Promise<void> {
  return globals.inform(type, title, message);
}
export function alert(
  title: string,
  message?: string,
  options?: { noFocus?: boolean; fireworks?: boolean; okButtonText?: string }
): Promise<void> {
  return globals.alert(title, message, options);
}
export function confirm(title: string, options?: { yesNo?: boolean }): Promise<boolean>;
export function confirm(title: string, message?: string, options?: { yesNo?: boolean }): Promise<boolean>;
export function confirm(
  title: string,
  message?: string | { yesNo?: boolean },
  options?: { yesNo?: boolean }
): Promise<boolean> {
  return globals.confirm(title, message, options);
}
export function prompt(title: string, message: string, options?: { placeholder?: string }): Promise<string> {
  return globals.prompt(title, message, options);
}
export function danger(title: string, message: string, options?: { yesNo?: boolean }): Promise<boolean> {
  return globals.danger(title, message, options);
}

function beforeUnload(event: BeforeUnloadEvent) {
  if (projectStore.textQueue.hasQueued()) {
    projectStore.textQueue.finish();
    event.preventDefault();
    return (event.returnValue = 'There are pending text changes');
  }
}

window.addEventListener('beforeunload', beforeUnload);
