import { Browserbase, ChangeDetail, ObjectStore, Where } from 'browserbase';
import { Change, DynamicSnapshot, Snapshot } from '../changes';
import { Collective, CollectiveStore, CollectiveStoreInTransaction, GetOptions } from '../collective';

interface BrowserbaseStoreOptions {
  snapshotsStore?: string;
  changesStore?: string;
}

export interface DefaultCollectiveDB extends Browserbase {
  snapshots: ObjectStore<DynamicSnapshot, [string, number]>;
  changes: ObjectStore<Change, [string, number]>;
}

export class CollectiveBrowserbaseStore<Project extends Snapshot = Snapshot> implements CollectiveStore<Project> {
  collective: Collective;
  db: Browserbase;
  snapshotsStore: string;
  changesStore: string;
  protected transaction: Browserbase;

  constructor(db: Browserbase, options: BrowserbaseStoreOptions = {}) {
    this.db = db;
    this.snapshotsStore = options.snapshotsStore || 'snapshots';
    this.changesStore = options.changesStore || 'changes';
    this.transaction = null;
  }

  async open(collective: Collective) {
    this.collective = collective;
    if (!this.db.getVersions().length) {
      this.db.version(1, {
        [this.snapshotsStore]: '[id+version], [id+committed+version]',
        [this.changesStore]: `[projectId+version], [projectId+committed+version]`,
      });
    }
    this.db.addEventListener('error', this._onDbError);
    this.db.addEventListener('change', this._onChange);
    if (!this.db.isOpen()) await this.db.open();
  }

  close() {
    this.db.removeEventListener('error', this._onDbError);
    this.db.removeEventListener('change', this._onChange);
  }

  start(): CollectiveBrowserbaseStoreInTransaction<Project> {
    return new CollectiveBrowserbaseStoreInTransaction(this);
  }

  /**
   * Get a Browserbase query for snapshots.
   */
  protected getSnapshotsQuery(projectId: string, options?: GetOptions) {
    const store = this.getSnapshotsStore();
    const index = options && 'committed' in options ? '[id+committed+version]' : '';
    return processQuery(store.where(index), projectId, options);
  }

  /**
   * Get a Browserbase query for snapshots.
   */
  protected getChangesQuery(projectId: string, options?: GetOptions) {
    const store = this.getChangesStore();
    const index = options && 'committed' in options ? '[projectId+committed+version]' : '';
    return processQuery(store.where(index), projectId, options);
  }

  getSnapshotsStore() {
    return (this.transaction || (this.db as any)).stores[this.snapshotsStore] as ObjectStore<Project, [string, number]>;
  }

  getChangesStore() {
    return (this.transaction || (this.db as any)).stores[this.changesStore] as ObjectStore<Change, [string, number]>;
  }

  /**
   * Get a list of snapshots.
   */
  async getSnapshots(projectId: string, options?: GetOptions): Promise<Project[]> {
    const query = this.getSnapshotsQuery(projectId, options);
    // When loading all snapshots, could potentially overload the browser with a "Maximum IPC message size exceeded."
    // error. By reversing the query it will use a cursor to load the data which we can then reverse again afterwards.
    if (!options) query.reverse();
    const results = await query.getAll();
    if (!options) results.reverse();
    return results;
  }

  /**
   * Get a snapshot.
   */
  getSnapshot(projectId: string, options?: GetOptions | number): Promise<Project> {
    if (typeof options === 'number') {
      const store = this.getSnapshotsStore();
      return store.get([projectId, options]);
    } else {
      return this.getSnapshotsQuery(projectId, options).get();
    }
  }

  /**
   * Get a list of changes.
   */
  getChanges(projectId: string, options?: GetOptions): Promise<Change[]> {
    return this.getChangesQuery(projectId, options).getAll();
  }

  /**
   * Get a change.
   */
  getChange(projectId: string, options?: GetOptions | number): Promise<Change> {
    if (typeof options === 'number') {
      const store = this.getChangesStore();
      return store.get([projectId, options]);
    } else {
      return this.getChangesQuery(projectId, options).get();
    }
  }

  /**
   * Save a snapshot
   */
  async putSnapshot(snapshot: Project): Promise<void> {
    await this.getSnapshotsStore().put(snapshot);
  }

  /**
   * Save a change
   */
  async putChange(change: Change): Promise<void> {
    await this.getChangesStore().put(change);
  }

  /**
   * Delete snapshots that match the options.
   */
  async deleteSnapshots(projectId: string, options?: GetOptions) {
    await this.getSnapshotsQuery(projectId, options).deleteAll();
  }

  /**
   * Delete changes that match the options.
   */
  async deleteChanges(projectId: string, options?: GetOptions) {
    await this.getChangesQuery(projectId, options).deleteAll();
  }

  /**
   * Delete all snapshots and changes for a project.
   */
  async deleteProject(projectId: string) {
    await Promise.all([
      this.getSnapshotsStore().where().startsWith([projectId]).deleteAll(),
      this.getChangesStore().where().startsWith([projectId]).deleteAll(),
    ]);
  }

  /**
   * Handle database errors generically.
   */
  protected _onDbError = ({ error }: ErrorEvent) => {
    if (this.collective) this.collective.dispatchEvent('db_error', error);
  };

  /**
   * Handles changes from the database.
   */
  protected _onChange = ({ detail: { store, obj, declaredFrom } }: CustomEvent<ChangeDetail>) => {
    if (!this.collective) return;
    if (store.name === this.changesStore) {
      if (obj) this.collective.dispatchEvent('change', obj, declaredFrom);
    } else if (store.name === this.snapshotsStore) {
      if (obj) this.collective.dispatchEvent('snapshot', obj, declaredFrom);
    }
  };
}

export class CollectiveBrowserbaseStoreInTransaction<Project extends Snapshot = Snapshot>
  extends CollectiveBrowserbaseStore<Project>
  implements CollectiveStoreInTransaction<Project>
{
  constructor(store: CollectiveBrowserbaseStore) {
    super(store.db);
    this.snapshotsStore = store.snapshotsStore;
    this.changesStore = store.changesStore;
    this.transaction = this.db.start([this.changesStore, this.snapshotsStore]);
  }

  commit() {
    if (this.transaction) {
      const result = this.transaction.commit();
      this.transaction = null;
      return result;
    }
    return Promise.resolve();
  }
}

function processQuery(query: Where<any, any>, id: string, options?: GetOptions) {
  if (options && 'committed' in options) {
    if (!options.committed) {
      query.startsWith([id, 0]);
    } else {
      query.startsAfter([id, 0, []]).endsBefore([id, Number.MAX_SAFE_INTEGER, []]);
    }
  } else {
    query.startsWith([id]);
  }
  if (!options) return query;
  if (options.version != null) query.startsAt([id, options.version]).endsAt([id, options.version]);
  if (options.before != null) query.endsBefore([id, options.before]);
  if (options.after != null) query.startsAfter([id, options.after]);
  if (options.reverse) query.reverse();
  if (options.limit) query.limit(options.limit);
  return query;
}
