import { WS_URL } from '@dabble/data/global-constants';
import { derived, Readable, Subscriber, Unsubscriber, writable } from '@dabble/data/stores/store';
import { getNow } from './date';
import rest from './rest';
import { EventMetadata, UserAttributes } from './types';

const URL = WS_URL;
const BASE_RETRY_TIME = 1000;
const MAX_RETRY_BACKOFF = 3;
const CLOSING_WAIT = 3000;
const PING_INTERVAL = 10000;
const PING_TIMEOUT = 5000;
export const BATCH_SIZE = 500;

export interface Connection {
  online: boolean;
  connected: boolean;
  authed: boolean;
  serverTimeOffset: number;
}

export interface Keys {
  [prop: string]: any;
}

export interface Tokens {
  idToken: string;
  refreshToken: string;
}

export type DocumentData = { [field: string]: any };
export type WhereFilterOp =
  | '<'
  | '<='
  | '=='
  | '!='
  | '>='
  | '>'
  | 'array-contains'
  | 'in'
  | 'not-in'
  | 'array-contains-any';
export type Where = [string, WhereFilterOp, any];
export type WhereUnset = [string, 'unset'];
export type OrderByDirection = 'asc' | 'desc';
export type OrderBy = ['orderBy', string, OrderByDirection?];
export type Limit = ['limit', number];
export type Wheres = Array<Where | WhereUnset | OrderBy | Limit>;
export type DocumentHandler = (doc: DocumentData) => void;
export type ListenerData = {
  collection: string;
  data: DocumentData;
  primaryId?: string;
  isDelete?: true;
  isRefresh?: true;
};
export type Listener = Subscriber<ListenerData>;
export interface GetOptions {
  keys?: boolean;
  allProps?: boolean;
  fields?: string[];
}
export interface PutOptions {
  merge?: boolean;
  force?: boolean;
}
export interface DeleteOptions {
  force?: boolean;
}

export interface ConnectionStore {
  connect(): Promise<void>;
  disconnect(): void;
  close(): void;
  dispatch(data: ListenerData): void;
  listen(callback: Listener): Unsubscriber;
  send(action: string, ...rest: any[]): Promise<any>;
  sendAfterAuthed(action: string, ...rest: any[]): Promise<any>;
  auth(tokens?: Tokens): Promise<string>;
  getDoc(collection: string, keys?: Keys, options?: GetOptions): Promise<DocumentData | null>;
  getDocs(collection: string, wheres?: Wheres, options?: GetOptions): Promise<DocumentData[]>;
  putDoc(collection: string, data: DocumentData, options?: PutOptions): Promise<number>;
  putDocs(collection: string, data: DocumentData[], options?: PutOptions): Promise<number>;
  deleteDoc(collection: string, keys?: Keys, options?: DeleteOptions): Promise<number>;
  deleteDocs(collection: string, wheres?: Wheres, options?: DeleteOptions): Promise<number>;
  streamDocs(collection: string, wheres: Wheres | null, onItem: DocumentHandler): Promise<string>;
  listenForChanges(collection: string, wheres: Wheres, listener: Listener): Unsubscriber;
  record(name: string, metadata: EventMetadata, timestamp?: number): Promise<void>;
  recordUserData(attributes: UserAttributes): Promise<void>;
  collection(name: string): Query;
  pause(pause?: boolean): void;
  state: Readable<Connection>;
}

interface Request {
  action: string;
  args: any[];
  resolve(value?: unknown): void;
  reject(reason?: any): void;
  onMessage: DocumentHandler;
}

export function createConnectionStore(
  appVersion: string,
  deviceId: Readable<string>,
  delegate?: string
): ConnectionStore {
  const requests: { [r: string]: Request } = {};
  const afterConnectedQueue: Array<[string, any[], (value?: unknown) => void, (reason?: any) => void]> = [];
  const afterAuthedQueue: Array<[string, any[], (value?: unknown) => void, (reason?: any) => void]> = [];

  let socket: WebSocket;
  let shouldConnect = false;
  let requestNumber = 1;
  let online = window.navigator.onLine;
  let connected = false;
  let authed = false;
  let serverTimeOffset = 0;
  let retries = 0;
  let reconnectTimeout: any;
  let closing: any;
  let pingInterval: any;
  let pongTimeout: any;
  let paused: boolean; // use for testing data drop and sync stability/recovery

  const listeners = new Set<Listener>();
  const listenersByRef: Record<number, Listener> = {};
  const state = writable<Connection>({ online, connected, authed, serverTimeOffset });
  const authedStore = derived(state, ({ authed }) => authed);

  window.addEventListener('online', onOnline);
  window.addEventListener('offline', onOffline);

  function close() {
    window.removeEventListener('online', onOnline);
    window.removeEventListener('offline', onOffline);
  }

  function update() {
    state.set({ online, connected, authed, serverTimeOffset });
  }

  function connect(): Promise<void> {
    clearTimeout(reconnectTimeout);

    return new Promise((resolve, reject) => {
      shouldConnect = true;

      if (!online) {
        return reject(new Error('offline'));
      } else if (connected) {
        return;
      }

      try {
        socket = new WebSocket(URL);
      } catch (err) {
        reject(err);
      }

      function startPings() {
        pingInterval = setInterval(() => {
          if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send('ping');
            pongTimeout = setTimeout(closeSocket, PING_TIMEOUT);
          }
        }, PING_INTERVAL);
      }

      socket.onerror = () => {
        reject();
        closeSocket();
      };

      socket.onopen = async () => {
        await send('info', deviceId.get(), appVersion, delegate);
        startPings();
        if (!connected) {
          connected = true;
          update();
        }
        while (afterConnectedQueue.length) {
          const [a, rest, resolve, reject] = afterConnectedQueue.shift();
          send(a, ...rest).then(resolve, reject);
        }
        retries = 0;
        resolve();
      };

      socket.onclose = () => {
        clearTimeout(closing);
        clearInterval(pingInterval);
        clearTimeout(pongTimeout);
        closing = null;
        if (socket) socket.onclose = null;
        socket = null;
        if (connected) {
          connected = false;
          authed = null;
          update();
        }

        Object.keys(requests).forEach(key => {
          const request = requests[key];
          request.reject(new Error('CONNECTION_CLOSED'));
          delete requests[key];
        });

        if (shouldConnect && online) {
          const backoff = Math.round(Math.random() * (Math.pow(2, retries) - 1) * BASE_RETRY_TIME);
          retries = Math.min(MAX_RETRY_BACKOFF, retries + 1);
          reconnectTimeout = setTimeout(() => {
            connect().catch(() => {});
          }, backoff);
        }
      };

      socket.onmessage = event => {
        if (event.data === 'pong') return clearTimeout(pongTimeout);
        if (paused) return;
        let data;
        try {
          data = JSON.parse(event.data);
        } catch (err) {
          console.error('Unparseable data from socket:', event.data);
          return;
        }

        if (data.ts) {
          serverTimeOffset = data.ts - Date.now();
          update();
          return;
        }

        if (data.p) {
          if (data.d && data.d._connection) {
            if (data.d._connection === 'closing' && connected) {
              connected = false;
              authed = null;
              update();
              if (!Object.keys(requests).length) {
                closeSocket();
              } else {
                closing = setTimeout(closeSocket, CLOSING_WAIT);
              }
            }
          } else {
            dispatch(data.d as ListenerData, data.p);
          }
          return;
        }

        const request = requests[data.r];
        if (!request) return; // for now
        if (data.err) {
          console.log('Error with send', request.action, request.args);
          request.reject(new Error(data.err));
        } else {
          if (data.s) {
            if (request.onMessage) request.onMessage(data.d);
          } else {
            request.resolve(data.d);
          }
        }
      };
    });
  }

  function disconnect() {
    shouldConnect = false;
    closeSocket();
  }

  function pause(pause = true) {
    paused = pause;
  }

  function closeSocket() {
    if (!socket) return;
    connected = false;
    authed = null;
    update();
    socket.close();
    if (socket) (socket.onclose as any)();
  }

  function dispatch(data: ListenerData, ref?: number) {
    if (ref && ref > 1) {
      const callback = listenersByRef[ref];
      if (!callback) console.error('Got a message we were not listening for:', ref, data);
      else callback(data);
    } else {
      listeners.forEach(callback => callback(data));
    }
  }

  function listen(callback: Listener): Unsubscriber {
    listeners.add(callback);
    return () => listeners.delete(callback);
  }

  function send(action: string, ...args: any[]): Promise<any> {
    if (!socket || socket.readyState > 1 || closing) {
      return Promise.reject(new Error('CONNECTION_CLOSED'));
    } else if (socket.readyState === WebSocket.CONNECTING) {
      return new Promise((resolve, reject) => {
        afterConnectedQueue.push([action, args, resolve, reject]);
      });
    }

    let onMessage: DocumentHandler;
    if (typeof args[args.length - 1] === 'function') {
      onMessage = args.pop();
    }

    while (args.length && args[args.length - 1] === undefined) args.pop();

    const r = requestNumber++;
    return new Promise((resolve, reject) => {
      requests[r] = { action, args, resolve, reject, onMessage };
      try {
        socket.send(JSON.stringify({ r, a: action, d: args.length ? args : undefined }));
      } catch (err) {
        console.error('Exception thrown from WebSocket.send():', err.message, 'Closing connection.');
      }
    }).finally(() => {
      delete requests[r];

      if (closing && !Object.keys(requests).length && socket) {
        closeSocket();
      }
    });
  }

  function sendAfterAuthed(a: string, ...rest: any[]): Promise<any> {
    if (authed) return send(a, ...rest);

    return new Promise((resolve, reject) => {
      afterAuthedQueue.push([a, rest, resolve, reject]);
    });
  }

  async function auth(tokens?: Tokens) {
    const uid = await send('auth', tokens, delegate);
    authed = !!uid;
    if (authed) {
      try {
        if (uid !== (await rest.GET('/auth/user')).uid) {
          throw new Error('UID mismatch');
        }
      } catch (e) {
        await rest.POST('/auth/signIn', { token: tokens.idToken, provider: 'oldjwt' });
      }
    }

    update();
    while (afterAuthedQueue.length) {
      const [a, rest, resolve, reject] = afterAuthedQueue.shift();
      send(a, ...rest).then(resolve, reject);
    }
    return uid;
  }

  function getDoc(collection: string, keys: Keys = {}, options?: GetOptions) {
    return sendAfterAuthed('get', collection, keys, options);
  }

  function getDocs(collection: string, wheres?: Wheres, options?: GetOptions) {
    return sendAfterAuthed('get', collection, wheres, options);
  }

  function putDoc(collection: string, data: DocumentData | DocumentData[], options?: PutOptions) {
    return sendAfterAuthed('put', collection, data, options);
  }

  function deleteDoc(collection: string, keys: Keys | Wheres) {
    return sendAfterAuthed('delete', collection, keys);
  }

  function streamDocs(collection: string, wheres: Wheres, onDoc: DocumentHandler) {
    if (typeof wheres === 'function') {
      return sendAfterAuthed('stream', collection, wheres);
    } else {
      return sendAfterAuthed('stream', collection, wheres, onDoc);
    }
  }

  function listenForChanges(collection: string, wheres: Wheres, listener: Listener) {
    let ref: number;

    const unsubscribe = authedStore.subscribe(async authed => {
      if (authed) {
        const value = await sendAfterAuthed('listen', collection, wheres);
        if (ref === -1) return;
        ref = value;
        listenersByRef[ref] = listener;
      } else if (ref && ref !== -1) {
        delete listenersByRef[ref];
      }
    });

    return () => {
      unsubscribe();
      delete listenersByRef[ref];
      sendAfterAuthed('unlisten', ref);
      ref = -1;
    };
  }

  function record(name: string, metadata: EventMetadata, timestamp?: number): Promise<void> {
    if (authed) {
      return send('record', name, metadata, timestamp);
    } else {
      // Allow it to be queued up
      return sendAfterAuthed('record', name, metadata, getNow());
    }
  }

  function recordUserData(attributes: UserAttributes): Promise<void> {
    return sendAfterAuthed('recordUserData', attributes);
  }

  function onOnline() {
    online = true;
    update();
    if (shouldConnect) {
      connect().catch(() => {});
    }
  }

  function onOffline() {
    online = false;
    update();
    closeSocket();
  }

  function collection(name: string) {
    return new Query(name, getDocs, deleteDoc);
  }

  return {
    close,
    connect,
    disconnect,
    pause,
    dispatch,
    listen,
    send,
    sendAfterAuthed,
    auth,
    getDoc,
    getDocs,
    putDoc,
    putDocs: putDoc,
    deleteDoc,
    deleteDocs: deleteDoc,
    streamDocs,
    listenForChanges,
    record,
    recordUserData,
    collection,
    state,
  };
}

class Query {
  public get: () => Promise<DocumentData[]>;
  public delete: () => Promise<number>;
  private _wheres: Wheres;

  constructor(collection: string, getDocs: Function, deleteDocs: Function) {
    this._wheres = [];
    this.get = getDocs.bind(null, collection, this._wheres);
    this.delete = deleteDocs.bind(null, collection, this._wheres);
  }

  where(field: string) {
    return new Operator(this, field, this._wheres);
  }

  and(field: string) {
    return this.where(field);
  }

  orderBy(field: string, direction: 'asc' | 'desc' = 'asc') {
    this._wheres.push(['orderBy', field, direction]);
    return this;
  }

  limit(count: number) {
    this._wheres.push(['limit', count]);
    return this;
  }

  toJSON() {
    return this._wheres;
  }
}

class Operator {
  constructor(private _query: Query, private _field: string, private _wheres: Wheres) {}

  equals(value: any) {
    this._wheres.push([this._field, '==', value]);
    return this._query;
  }

  notEquals(value: any) {
    this._wheres.push([this._field, '!=', value]);
    return this._query;
  }

  is(value: any) {
    return this.equals(value);
  }

  isNot(value: any) {
    return this.notEquals(value);
  }

  startsAfter(value: any) {
    this._wheres.push([this._field, '>', value]);
    return this._query;
  }

  startsAt(value: any) {
    this._wheres.push([this._field, '>=', value]);
    return this._query;
  }

  endsBefore(value: any) {
    this._wheres.push([this._field, '<', value]);
    return this._query;
  }

  endsAt(value: any) {
    this._wheres.push([this._field, '<=', value]);
    return this._query;
  }

  contains(value: any) {
    this._wheres.push([this._field, Array.isArray(value) ? 'array-contains-any' : 'array-contains', value]);
    return this._query;
  }

  in(value: any[]) {
    this._wheres.push([this._field, 'in', value]);
    return this._query;
  }

  notIn(value: any[]) {
    this._wheres.push([this._field, 'not-in', value]);
    return this._query;
  }
}
