<script>
  import { clickOutside } from '@dabble/toolkit/click-outside';
  import { mdiDotsVertical } from '@mdi/js';
  import ContextMenu from './ContextMenu.svelte';
  import Dropdown from './Dropdown.svelte';
  import Icon from './Icon.svelte';
  import { tooltipTop } from './tooltip';

  export let placement;
  export let arrow;
  export let target;
  export let actionTooltip;
  export let menuOpen = false;
  export let contextOpen = false;

  function triggerMenu() {
    menuOpen = !menuOpen;
    contextOpen = false;
  }

  function closeMenu() {
    menuOpen = false;
    contextOpen = false;
  }
</script>

<button class="icon display-dots-vertical" on:click={triggerMenu} use:tooltipTop={actionTooltip}>
  <Icon path={mdiDotsVertical} />
</button>

{#if menuOpen}
  <Dropdown {placement} {arrow} on:close={closeMenu}>
    <div use:clickOutside on:click_outside={closeMenu}>
      <slot />
    </div>
  </Dropdown>
{/if}

<ContextMenu {target} bind:isOpen={contextOpen} activeClass="menu-open">
  <slot />
</ContextMenu>
