import { FindState } from '@dabble/data/stores/find-replace';

// Create a regular expression for searching text based off the find-replace options such as Match Case and Use Regex
export default function getFindExpression(state: FindState) {
  const { text, matchWholeWords, matchCase, matchRegex } = state;
  let flags = 'g';
  let exprText = matchRegex ? text : escapeRegExp(text);
  if (matchWholeWords) exprText = `\\b${exprText}\\b`;
  if (!matchCase) flags += 'i';
  try {
    return new RegExp(exprText, flags);
  } catch (e) {}
}

function escapeRegExp(text: string) {
  return text
    .replace(/[-[\]{}()*+?.\\^$|#\s]/g, '\\$&')
    .replace(/['‘’]/g, "['‘’]")
    .replace(/["“”]/g, '["“”]');
}
