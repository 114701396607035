<script>
  import { currentDoc, projectStore, selectedCount, settings, size, t, todaysDate, userStats } from '@dabble/app';
  import { number } from '@dabble/toolkit/helpers';
  import { tooltipTop } from '@dabble/toolkit/tooltip';

  let docSettings;
  let pageCount = 0;
  let wordCount = 0;
  let todaysCount = 0;

  $: docSettings = $currentDoc && $settings[$currentDoc.type];
  $: pageCount = ($currentDoc && $projectStore.counts[$currentDoc.id].pageCount) || 0;
  $: wordCount = ($currentDoc && $projectStore.counts[$currentDoc.id].wordCount) || 0;
  $: todaysCount =
    $currentDoc && $userStats && $projectStore.counts && userStats.getForDay($currentDoc.id, $todaysDate);
</script>

{#if docSettings && !docSettings.hidewordCounts}
  <div class="counts">
    {#if $selectedCount && $size === 'desktop'}
      <span class="count" use:tooltipTop={$t('counts_selection_tooltip')}
        >{number($selectedCount)} {$t('counts_selection', { count: $selectedCount })}</span
      > |
    {/if}
    {#if $size === 'desktop'}
      <span class="count page" use:tooltipTop={$t('counts_pages_tooltip')}
        >{number(pageCount)} {$t('counts_pages', { count: pageCount })}</span
      > |
    {/if}
    <span class="count" use:tooltipTop={$t('counts_words_tooltip')}
      >{number(wordCount)} {$t('counts_words', { count: wordCount })}</span
    >
    |
    <span class="count" use:tooltipTop={$t('counts_today_tooltip')}>{number(todaysCount)} {$t('counts_today')}</span>
  </div>
{/if}

<style>
  .counts {
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
