<script lang="ts">
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js';
  export let checked = false;
  export let disabled = false;
  let className = '';
  export { className as class };
</script>

<button class="btn {checked ? 'success' : 'secondary'} check-button {className}" class:checked on:click {disabled}>
  <Icon path={checked ? mdiCheckboxMarkedCircleOutline : mdiCheckboxBlankCircleOutline} />
  <slot />
</button>

<style>
  .btn {
    display: flex;
    align-items: center;
  }
  .check-button :global(.icon) {
    font-size: 18px;
    margin-right: 4px;
  }
</style>
