<script>
  import { projectStore, shownCard } from '@dabble/app';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { autoselect } from '@dabble/toolkit/autoselect';
  import { enterKey, escKey } from '@dabble/toolkit/events';
  import { focus } from '@dabble/toolkit/focus';
  import { dbltap, tap } from '@dabble/toolkit/tap';
  import { createEventDispatcher } from 'svelte';
  import { mdiRibbonVariant } from './custom-icons';

  export let doc;
  export let text;
  export let color;
  export let editing;

  const colors = ['yellow', 'red', 'purple', 'blue', 'green'];
  const dispatch = createEventDispatcher();
  let onCardPopup = $shownCard;
  let editingLabel;
  let editingColor;

  $: editingLabel = text || '';
  $: editingColor = color;

  function startEditing() {
    if (!editing) {
      dispatch('editing');
    }
  }

  function cancelEditing() {
    dispatch('done');
  }

  function saveEditing() {
    if (editingLabel !== text || editingColor !== color) {
      projectStore.updateDoc(doc.id, { label: { color: editingColor, text: editingLabel } });
    }
    cancelEditing();
  }
</script>

<div
  class="card-ribbon"
  style="--ribbon-color: var(--ribbon-color-{editingColor}, #ccc);"
  use:tap={['pointer', true]}
  use:dbltap={true}
  on:dbltap={startEditing}
>
  {#if editing}
    <input
      use:focus
      use:autoselect
      use:escKey={saveEditing}
      use:enterKey={saveEditing}
      bind:value={editingLabel}
      class="card-ribbon-text input-sm edit-input"
      class:empty={!editingLabel}
    />
  {:else}
    <span class="card-ribbon-text">{text}</span>
  {/if}
  <div class="tip" />
</div>

{#if editing}
  <Dropdown
    class="card-bubble-dropdown {(onCardPopup && 'horizontal') || ''}"
    placement={onCardPopup ? 'bottom' : 'left'}
    offset="0,6"
    on:close={saveEditing}
    arrow
    iconsOnly
  >
    {#each colors as color}
      <button
        class="dropdown-item color-button"
        style="--ribbon-color: var(--ribbon-color-{color}, #ccc);"
        on:click={() => (editingColor = color)}
        class:active={editingColor === color}
      >
        <Icon path={mdiRibbonVariant} />
      </button>
    {/each}
  </Dropdown>
{/if}

<style>
  :global(:root) {
    --ribbon-color-red: rgb(var(--red-500));
    --ribbon-color-blue: rgb(var(--dabble-blue-500));
    --ribbon-color-yellow: rgb(var(--amber-500));
    --ribbon-color-purple: rgb(var(--purple-900));
    --ribbon-color-green: rgb(var(--green-700));
  }
  :global(.card-bubble-dropdown.dropdown-menu) {
    --menu-background: #262625;
    background-image: var(--bubble-menu-background-gradient);
    min-width: 40px;
  }
  :global(.card-bubble-dropdown.dropdown-menu.horizontal) {
    display: flex;
  }
  :global(.card-bubble-dropdown.dropdown-menu .dropdown-item),
  :global(.is-mouse .card-bubble-dropdown.dropdown-menu .dropdown-item) {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 1px solid transparent;
  }
  :global(.card-bubble-dropdown.dropdown-menu .dropdown-item:hover) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  :global(.card-bubble-dropdown.dropdown-menu .dropdown-item.active) {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
  :global(.card-bubble-dropdown.dropdown-menu.icons-only .dropdown-item .icon) {
    margin-right: 0;
  }
  .dropdown-item.color-button {
    color: var(--ribbon-color);
  }
  .card-ribbon {
    --ribbon-color: var(--dabble-gray);
    --ribbon-height: 20px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    left: -3px;
    bottom: 4px;
    padding-left: calc(var(--ribbon-height) / 4 + 2px);
    height: var(--ribbon-height);
    border-radius: 2px 0px 0px 2px;
    background-color: var(--ribbon-color);
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3)) drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3));
    text-shadow: var(--text-lowlight);
    color: #fff;
    text-align: left;
    font-weight: bold;
    font-size: calc(var(--ribbon-height) / 2);
    font-family: var(--font-family-ui);
  }
  .card-ribbon-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    min-width: 0;
  }
  .tip {
    display: flex;
    flex: 0 0 0px;
    margin-right: calc(-1 * var(--ribbon-height));
    font-size: var(--ribbon-height);
    color: var(--ribbon-color);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: var(--ribbon-height) var(--ribbon-height) 0 0;
    border-color: var(--ribbon-color) transparent transparent transparent;
  }
  :global(.notecard:not(.small):not(.medium)) .card-ribbon {
    --ribbon-height: 24px;
  }
</style>
