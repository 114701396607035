import getClippingRect from '@popperjs/core/lib/dom-utils/getClippingRect';
import getDocumentElement from '@popperjs/core/lib/dom-utils/getDocumentElement';
import { isElement } from '@popperjs/core/lib/dom-utils/instanceOf';
import type { Boundary, Context, Placement, RootBoundary } from '@popperjs/core/lib/enums';
import {
  basePlacements,
  bottom,
  clippingParents,
  popper,
  reference,
  right,
  top,
  viewport,
} from '@popperjs/core/lib/enums';
import type { Padding, SideObject, State } from '@popperjs/core/lib/types';
import computeOffsets from '@popperjs/core/lib/utils/computeOffsets';
import expandToHashMap from '@popperjs/core/lib/utils/expandToHashMap';
import mergePaddingObject from '@popperjs/core/lib/utils/mergePaddingObject';
import rectToClientRect from '@popperjs/core/lib/utils/rectToClientRect';

// eslint-disable-next-line
export type Options = {
  placement: Placement;
  boundary: Boundary;
  rootBoundary: RootBoundary;
  elementContext: Context;
  altBoundary: boolean;
  padding: Padding;
};

export default function detectOverflow(state: State, options: Partial<Options> = {}): SideObject {
  const {
    placement = state.placement,
    boundary = clippingParents,
    rootBoundary = viewport,
    elementContext = popper,
    altBoundary = false,
    padding = 0,
  } = options;

  const paddingObject = mergePaddingObject(
    typeof padding !== 'number' ? padding : expandToHashMap(padding, basePlacements)
  );

  const altContext = elementContext === popper ? reference : popper;

  const referenceRect = state.rects.reference;
  const popperRect = state.rects.popper;
  const element = state.elements[altBoundary ? altContext : elementContext];

  const clippingClientRect = getClippingRect(
    isElement(element) ? element : (element as any).contextElement || getDocumentElement(state.elements.popper),
    boundary,
    rootBoundary,
    'absolute'
  );

  // const referenceClientRect = getBoundingClientRect(state.elements.reference);
  const referenceClientRect = rectToClientRect(referenceRect);

  const popperOffsets = computeOffsets({
    reference: referenceClientRect,
    element: popperRect,
    strategy: 'absolute',
    placement,
  });

  const popperClientRect = rectToClientRect({
    ...popperRect,
    ...popperOffsets,
  });

  const elementClientRect = elementContext === popper ? popperClientRect : referenceClientRect;

  // positive = overflowing the clipping rect
  // 0 or negative = within the clipping rect
  const overflowOffsets = {
    top: clippingClientRect.top - elementClientRect.top + paddingObject.top,
    bottom: elementClientRect.bottom - clippingClientRect.bottom + paddingObject.bottom,
    left: clippingClientRect.left - elementClientRect.left + paddingObject.left,
    right: elementClientRect.right - clippingClientRect.right + paddingObject.right,
  };

  const offsetData = state.modifiersData.offset;

  // Offsets can be applied only to the popper element
  if (elementContext === popper && offsetData) {
    const offset = offsetData[placement];

    Object.keys(overflowOffsets).forEach((key: 'right' | 'bottom' | 'left' | 'top') => {
      const multiply = [right, bottom].indexOf(key as any) >= 0 ? 1 : -1;
      const axis = [top, bottom].indexOf(key as any) >= 0 ? 'y' : 'x';
      overflowOffsets[key] += offset[axis] * multiply;
    });
  }

  return overflowOffsets;
}
