<script lang="ts">
  import { desktop, isApple, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipBottom } from '@dabble/toolkit/tooltip';
  import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';

  const fullscreen = desktop.isFullscreen;
</script>

{#if desktop.inApp}
  <div class="desktop-nav focus-fade" class:fullscreen={$fullscreen}>
    <button
      on:click|preventDefault={() => history.back()}
      use:tooltipBottom={$t('go_back', { shortcut: isApple ? '⌘[' : 'Ctrl+[' })}
      class="btn icon"><Icon path={mdiArrowLeft} /></button
    >

    <button
      on:click|preventDefault={() => history.forward()}
      use:tooltipBottom={$t('go_forward', { shortcut: isApple ? '⌘]' : 'Ctrl+]' })}
      class="btn icon"><Icon path={mdiArrowRight} /></button
    >
  </div>
{/if}

<style>
  .desktop-nav {
    position: fixed;
    top: 0;
    left: 8px;
    display: flex;
    box-sizing: border-box;
    height: 40px;
    z-index: 40;
    -webkit-app-region: no-drag;
  }
  :global(body:not(.size-desktop)) .desktop-nav {
    left: 44px;
  }
  .desktop-nav .btn {
    display: flex;
    align-items: center;
    height: 100%;
    width: 40px;
    line-height: 14px;
    border-radius: 5px;
    color: var(--text-color-lighter);
    font-size: 24px;
  }
  .desktop-nav .btn:disabled {
    opacity: 0.5;
    cursor: default;
    -webkit-app-region: drag;
  }
  .desktop-nav .btn:not(:disabled):hover {
    color: var(--text-color-normal);
  }
  :global(body.isApple.inDesktop) .desktop-nav:not(.fullscreen) {
    left: 72px;
  }
  :global(body.isApple.inDesktop:not(.size-desktop)) .desktop-nav:not(.fullscreen) {
    left: 108px;
  }
</style>
