import { delegate, deviceId, tabStore } from '@dabble/app';
import { ConnectionStore, createConnectionStore } from '@dabble/data/connection';
import { writable } from '@dabble/data/stores/store';
import { version } from '@dabble/version';

// This file is for the leader tab and will maintain the active connection to the server when this tab is the leader.
const INACTIVE_TIME = 1000 * 60 * 10; // 10 minutes
const LONG_INACTIVE_TIME = 1000 * 60 * 30; // 30 minutes

export let connection: ConnectionStore;
let isDisconnected = false;
let worker: Worker;
let inactive = false;

export const connectionStore = writable(connection);
(window as any).connectionStore = connectionStore;

export function createConnection() {
  connection = createConnectionStore(version, deviceId, delegate);
  connection.connect();
  connectionStore.set(connection);
}

/**
 * Once there has been no activity for a certain amount of time, disconnect from the server. Reconnect as soon as
 * there is more activity again.
 */
export function hadActivity() {
  if (!worker) {
    worker = new Worker(new URL('./setTimeoutWorker.js', import.meta.url));
    worker.onmessage = e => {
      const timerName = e.data.timerName;
      if (timerName === 'activity') {
        console.log('No activity for a while, disconnecting');
        isDisconnected = true;
        connection.disconnect();
      } else if (timerName === 'inactive') {
        console.log('Inactive, will reload');
        inactive = true;
      }
    };
  }

  if (inactive) {
    tabStore.get().broadcast('reload');
    return;
  }

  if (isDisconnected) {
    connect();
  }

  worker.postMessage({ timerName: 'activity', time: INACTIVE_TIME });
  worker.postMessage({ timerName: 'inactive', time: LONG_INACTIVE_TIME });
}

function connect() {
  isDisconnected = false;
  connection.connect().catch(err => {}); // will automatically retry if it fails
  hadActivity();
}
