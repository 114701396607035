const paddingSelector = '.special-padding-rule';

// page margin-bottom + line-height + app header + status bar
const winEditorOffset = 16 + 44 + 40 + 36;

/**
 * Adds and maintains a padding at the bottom of each page, enough to allow the cursor to be scrolled to the very top
 * for writers who want it up there. Without this padding a writer would only be able to do this on empty pages. This
 * padding is dynamically updated for the window size to be just right on small and large screens.
 */
export function pagepad(node: Element) {
  const doc = node.ownerDocument;
  const rule = findRule(doc, paddingSelector);

  if (!rule) {
    console.error(`Could not find a style for "${paddingSelector}" to add padding at the end of each page`);
    return;
  }

  function updatePagePadding() {
    const height = window.innerHeight - winEditorOffset; // subtract the padding/margin at the bottom of the page
    rule.style.cssText = rule.style.cssText.replace(/\d+/, height + '');
  }

  setTimeout(updatePagePadding);
  window.addEventListener('resize', updatePagePadding);

  return {
    destroy() {
      window.removeEventListener('resize', updatePagePadding);
    },
  };
}

function findRule(doc: Document, selector: string) {
  for (let i = 0; i < doc.styleSheets.length; i++) {
    const sheet = doc.styleSheets[i] as CSSStyleSheet;
    try {
      if (!sheet.cssRules) continue;
    } catch (e) {
      continue;
    }

    for (let j = 0; j < sheet.cssRules.length; j++) {
      const rule = sheet.cssRules[j] as CSSStyleRule;
      if (rule.selectorText && rule.selectorText.indexOf(selector) !== -1) {
        return rule;
      }
    }
  }
}
