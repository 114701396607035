<script>
  import { alert, lastUrl, router, t } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiCheckBold, mdiInformationOutline, mdiLoading } from '@mdi/js';
  import { billing, lifetimeProduct, plans, trialAvailable, trialing } from '../billing';
  import Cost from './Cost.svelte';
  import Price from './Price.svelte';

  export let offerPlan;
  export let planId;
  export let current;
  export let legacy;
  export let yearly;
  export let originalPrice;
  export let recommend;
  export let disabled;
  export let redirect;

  const redirectBack = $lastUrl && !$lastUrl.startsWith('/checkout') ? $lastUrl : '/account/billing';
  let plan;
  let error;
  let saving;

  $: product = offerPlan.product;
  $: basedOn = product && getBasedOn(product);
  $: plan = yearly ? offerPlan.year : $lifetimeProduct.id === product.id ? offerPlan.year : offerPlan.month;
  $: features = product && getFeatures(product);
  $: originalPrice = plan && plan.metadata.originalPrice ? parseInt(plan.metadata.originalPrice) : null;
  $: recommended = (product.metadata.recommended && recommend !== false) || recommend === true;

  function getFeatures(product) {
    let features = product.metadata.features.split(',');
    const basedOn = getBasedOn(product);
    if (basedOn) {
      const basedOnFeatures = new Set(basedOn.metadata.features.split(','));
      features = features.filter(feature => !basedOnFeatures.has(feature));
    }
    return new Set(features);
  }

  function getBasedOn(product) {
    return product.metadata.basedOn && $plans.all[product.metadata.basedOn];
  }

  async function startTrial() {
    disabled = saving = true;
    error = null;
    let failed = false;
    try {
      await billing.createSubscription({ plan: plan.id });
    } catch (err) {
      error = err.message;
      failed = true;
    }
    disabled = saving = false;
    if (!failed) {
      router.navigate(redirect || '/account/billing');
      setTimeout(() => {
        alert($t('checkout_success_trial_title'), $t('checkout_success_trial_body'));
      }, 500);
    }
  }
</script>

{#if product && plan}
  <div class="plan-wrapper" class:legacy class:current class:recommended>
    {#if error}
      <Alert type="danger" dismissible on:close={() => (error = null)}>
        <strong>{$t('error')}:</strong>
        {error.includes(':') ? error : $t(error)}
      </Alert>
    {/if}
    <div class="plan">
      {#if current}
        <div class="current-banner"><span>{$t('billing_current_plan')}</span></div>
        {#if legacy && product.id !== $lifetimeProduct.id}
          <div class="current-banner legacy"><span>—{$t('billing_legacy_plan')}—</span></div>
        {/if}
      {/if}
      {#if recommended}
        <div class="recommended-banner"><span>{$t('checkout_recommended')}</span></div>
      {/if}
      <h3 class="plan-name">{product.name}</h3>
      <div class="plan-desc">{product.metadata.description}</div>
      {#if legacy && !$trialing && plan.id === planId}
        <a class="btn primary" href={redirectBack}>{$t('billing_current_plan_keep')}</a>
      {:else if $trialAvailable}
        <button class="btn primary" on:click={startTrial} {disabled}>
          {$t('checkout_start_trial')}
          {#if saving}
            <Icon path={mdiLoading} spin class="loading" />
          {/if}
        </button>
      {:else}
        <a class="btn primary" href="/checkout/confirm?plan={plan.id}">{$t('checkout_select_plan')}</a>
      {/if}
      <div class="price">
        <Price {plan} />
        {#if originalPrice !== null}
          <div class="original-price">
            <Cost currency={plan.currency} interval={plan.interval} amount={originalPrice} />
          </div>
        {/if}
      </div>
      <hr />
      <div class="plan-image {legacy ? 'premium' : product.name.toLowerCase()}" />
      <ul class="features">
        {#if basedOn}
          <li class="plus">Everything in {basedOn.name},<br />plus:</li>
        {/if}

        {#if features.has('all')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_all')}
            <span class="info" use:tooltipTop={$t('feature_all_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if !basedOn && !features.has('all')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_backup')}
            <span class="info" use:tooltipTop={$t('feature_backup_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_sync')}
            <span class="info" use:tooltipTop={$t('feature_sync_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_everywhere')}
            <span class="info" use:tooltipTop={$t('feature_everywhere_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_mobile')}
            <span class="info" use:tooltipTop={$t('feature_mobile_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_manuscript')}
            <span class="info" use:tooltipTop={$t('feature_manuscript_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_text_highlighting')}
            <span class="info" use:tooltipTop={$t('feature_text_highlighting_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('focus')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_focus')}
            <span class="info" use:tooltipTop={$t('feature_focus_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('story_notes')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_story_notes')}
            <span class="info" use:tooltipTop={$t('feature_story_notes_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('plotting')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_plotting')}
            <span class="info" use:tooltipTop={$t('feature_plotting_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('goals')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_goals')}
            <span class="info" use:tooltipTop={$t('feature_goals_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('dark_mode')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_darkmode')}
            <span class="info" use:tooltipTop={$t('feature_darkmode_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('stickies')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_stickies')}
            <span class="info" use:tooltipTop={$t('feature_stickies_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('spell_check')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_spelling')}
            <span class="info" use:tooltipTop={$t('feature_spelling_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('grammar')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_grammar')}
            <span class="info" use:tooltipTop={$t('feature_grammar_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('comments')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_comments')}
            <span class="info" use:tooltipTop={$t('feature_comments_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('images')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_images')}
            <span class="info" use:tooltipTop={$t('feature_images_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('coauthoring')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_coauthoring')}
            <span class="info" use:tooltipTop={$t('feature_coauthoring_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}

        {#if features.has('support_chat')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_chat_support')}
            <span class="info" use:tooltipTop={$t('feature_chat_support_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {:else if features.has('support_email')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_email_support')}
            <span class="info" use:tooltipTop={$t('feature_email_support_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {:else if features.has('support_low')}
          <li>
            <Icon path={mdiCheckBold} />
            {@html $t('feature_low_support')}
            <span class="info" use:tooltipTop={$t('feature_low_support_description')}>
              <Icon path={mdiInformationOutline} inline />
            </span>
          </li>
        {/if}
      </ul>
    </div>
  </div>
{/if}

<style>
  :global(.plan-wrapper) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    order: 2;
  }
  .plan-wrapper :global(.alert) {
    position: absolute;
    left: 10px;
    right: 10px;
    z-index: 1;
  }
  .legacy {
    order: 3;
    /* flex-basis: 100%; */
  }
  :global(.plan-wrapper .plan) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 280px;
    margin: 20px;
    padding: 40px 20px;
  }
  :global(.plan-wrapper.current .plan) {
    border-radius: 6px;
    border: 1px solid var(--background);
  }
  .recommended .plan {
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.2);
    background: rgba(var(--dabble-blue-rgb), 0.05);
  }
  button :global(.icon.loading) {
    position: absolute;
    margin-top: 2px;
    margin-left: 8px;
  }
  @media screen {
    html[data-theme='dark'] .recommended .plan {
      border: 1px solid var(--background);
      background: rgba(255, 255, 255, 0.1);
    }
  }
  @media (prefers-color-scheme: dark) {
    /* Support dark mode on MacOS/iOS */
    html:not([attribute]) .recommended .plan {
      border: 1px solid var(--background);
      background: rgba(255, 255, 255, 0.1);
    }
  }
  :global(.plan-wrapper .current-banner) {
    position: absolute;
    align-self: center;
    border-radius: 3px;
    background: var(--dabble-yellow);
    text-align: center;
    font-size: var(--font-size-xs);
    color: #444;
    padding: 0 8px;
    top: 44px;
  }
  :global(.plan-wrapper .current-banner.legacy) {
    background: none;
    text-transform: uppercase;
    top: calc(44px + 1.8em);
  }
  .recommended-banner {
    position: absolute;
    left: -3px;
    right: -3px;
    top: 14px;
    padding: 2px 0;
    background: var(--dabble-red);
    color: var(--side-nav-selected-text-color);
    text-shadow: var(--side-nav-selected-text-shadow);
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: rotate(-3deg) skewX(-2deg);
  }
  .recommended-banner span {
    display: inline-block;
    transform: skewX(2deg);
  }
  :global(.plan-wrapper .plan-image) {
    position: relative;
    width: 200px;
    height: 248px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .plan-image.basic {
    background-image: url(../images/plan-basic.svg);
  }
  .plan-image.standard {
    background-image: url(../images/plan-standard.svg);
  }
  .plan-image.premium {
    background-image: url(../images/plan-premium.svg);
  }
  :global(.plan-wrapper .plan-name) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 60px;
    margin: 20px 0 10px;
    text-align: center;
  }
  :global(.plan-wrapper .plan-desc) {
    height: 60px;
  }
  :global(.plan-wrapper .plan hr) {
    margin: 30px 0;
    border-top: 1px solid var(--background-lightened);
  }
  :global(.plan-wrapper .features) {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    color: var(--text-color-light);
    list-style: none;
  }
  :global(.plan-wrapper .features li) {
    margin: 0;
    padding: 0;
    line-height: 2;
  }
  :global(.plan-wrapper li.plus) {
    line-height: inherit;
    font-weight: bold;
  }
  .soon {
    font-size: var(--font-size-xs);
    color: var(--text-color-lightest);
    text-transform: uppercase;
  }
  .info {
    color: var(--text-color-lightest);
    cursor: pointer;
  }
  :global(.plan-wrapper .price) {
    position: relative;
    margin-top: 20px;
    text-align: center;
  }
  .original-price {
    position: absolute;
    top: 21px;
    right: 24px;
    font-size: 0.6em;
    color: var(--text-color-lightest);
  }
  .original-price::after {
    content: '';
    position: absolute;
    top: 54%;
    left: -2px;
    right: -2px;
    border-top: 1px solid var(--text-color-lightest);
    box-shadow: 0 0 0 1px var(--white);
  }
  @media (max-width: 960px) {
    .recommended {
      order: 1;
    }
  }
</style>
