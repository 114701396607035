// Autoselects the contents of an input when first focused or clicked onto
export function autoselect(node: HTMLInputElement) {
  let focused = false;
  let mouseEvent = false;

  function onFocus() {
    if (!mouseEvent) {
      setTimeout(() => node.select());
    }
  }

  function onMouseDown() {
    focused = node.matches(':focus');
    mouseEvent = true;
  }

  function onMouseUp() {
    if (!focused) {
      setTimeout(() => node.select());
    }
    mouseEvent = false;
  }

  node.addEventListener('focus', onFocus);
  node.addEventListener('mousedown', onMouseDown);
  node.addEventListener('mouseup', onMouseUp);

  return {
    destroy() {
      node.removeEventListener('focus', onFocus);
      node.removeEventListener('mousedown', onMouseDown);
      node.removeEventListener('mouseup', onMouseUp);
    },
  };
}
