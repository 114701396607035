import { delegate, isProduction, plugins } from '@dabble/app';

export default function log(...args: any[]) {
  const user = plugins.stores.currentUser.get();
  if (isProduction && !delegate && !(user?.admin || user?.claims?.admin)) return;
  console.log(...args);
}

const show: any = {};
if (localStorage.log) localStorage.log.split(/, ?/).forEach((key: any) => (show[key] = true));
const showAll = show['*'];

// Can call with > 2 arguments to output or with 1 argument to return a function that can be called later with the tag
log.tag = function (tag: string, ...args: any[]) {
  if (!args.length) {
    return function (...args: any[]) {
      if (showAll || show[tag]) log(`%c[${tag}]`, 'font-weight:bold', ...args);
      else log(`[${tag}]`, ...args);
    };
  } else {
    if (showAll || show[tag]) log(`%c[${tag}]`, 'font-weight:bold', ...args);
    else log(`[${tag}]`, ...args);
  }
};

log.tagColor = function (tag: string, color: string, ...args: any[]) {
  if (!args.length) {
    return function (...args: any[]) {
      if (showAll || show[tag]) log(`%c[${tag}]`, 'font-weight:bold;color:' + color, ...args);
      else log(`[${tag}]`, ...args);
    };
  } else {
    if (showAll || show[tag]) log(`%c[${tag}]`, 'font-weight:bold;color:' + color, ...args);
    else log(`[${tag}]`, ...args);
  }
};
