import { ConnectionStore, ListenerData } from '@dabble/data/connection';
import { GlobalDataStore } from '@dabble/data/stores/global-data';
import { DabbleDatabase, GlobalData } from '@dabble/data/types';
import { compareVersions } from '@dabble/util/versions';
import { isProduction } from '@dabble/version';
import { SyncStep } from '../types';
import { requireUpdate } from './utils';

interface MinVersionData {
  version: string;
  db: DabbleDatabase;
  connection: ConnectionStore;
  globalData: GlobalDataStore;
}

// Ensures the client is at a minimum version before attempting to sync. This version should be months old or longer
// and this check is to help with very old/stale versions laying around in cache.
export default function checkMinimumVersionStep(data: MinVersionData): SyncStep {
  const { version, db, connection, globalData } = data;
  let { clientSettings } = globalData.get();

  return { sync, start, name: 'checkMinimumVersionStep' };

  async function sync() {
    if (
      clientSettings &&
      clientSettings.minVersion &&
      isProduction &&
      compareVersions(version, clientSettings.minVersion) < 0
    ) {
      db.deleteDatabase();
      return requireUpdate(clientSettings.minVersion);
    }
  }

  function start() {
    return connection.listen(onMinVersion);
  }

  function onMinVersion({ collection, data }: ListenerData) {
    if (collection !== 'global' || data.id !== 'clientSettings') return;
    clientSettings = data as GlobalData;
    sync();
  }
}
