import { connectionState, isLeader, isSyncTab, observe, syncReady } from '@dabble/app';
import { connection, createConnection } from './active-connection';
import { isSyncing, startSyncing, stopSyncing } from './sync';
import { syncing, tab } from './syncing';

export function syncWhenLeader() {
  tab.subscribe(data => {
    const { syncing: syncingValue, ...state } = data;
    syncing.set(syncingValue);
    connectionState.set(state);
  });
  tab.waitForLeadership(becomeLeader);
}

function becomeLeader() {
  if (isLeader.get()) return;
  isLeader.set(true);
  if (!connection) {
    createConnection();
  }
  isSyncTab.set(true);
  syncing.subscribe(syncing => tab.set({ syncing }));
  connection.state.subscribe(state => tab.set(state));

  observe(syncReady, async syncReady => {
    if (syncReady) {
      if (!isSyncing()) startSyncing();
    } else if (isSyncing()) {
      stopSyncing();
    }
  });
}
