import { settings, syncing as appSync } from '@dabble/app';
import { observe } from '@dabble/data/observe';
import AppCache from './components/AppCache.svelte';
import DataManagement from './components/DataManagement.svelte';
import SyncAppHeader from './components/SyncAppHeader.svelte';
import SyncStatus from './components/SyncStatus.svelte';
import UpdateRequired from './components/UpdateRequired.svelte';
import { syncWhenLeader } from './leader';
import './locales';
import { syncing } from './syncing';

observe(syncing, state => appSync.set(!!state));

syncWhenLeader();

settings.configure('dabble', {
  headerRight: { SyncAppHeader },
  statusBarLeft: { SyncStatus },
  globals: { UpdateRequired },
  plugins: {
    account: {
      preferences: { DataManagement, AppCache },
    },
  },
});
