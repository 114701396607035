import { settings } from "@dabble/app";
import { onChapterTitlePaste, onHeaderPaste, onSceneBodyPaste } from "../paste-import";

settings.configure('manuscript', {
  onPaste: {
    title: onChapterTitlePaste,
    subtitle: onHeaderPaste,
    author: onHeaderPaste,
  },
});

settings.configure('novel_scene', {
  onPaste: {
    body: onSceneBodyPaste,
  }
});

settings.configure('novel_chapter', {
  onPaste: {
    title: onChapterTitlePaste,
  }
});

settings.configure('novel_part', {
  onPaste: {
    title: onHeaderPaste,
  }
});
