import { findReplace as findState } from '@dabble/app';
import { tick } from 'svelte';
import { DecorateEvent, DecorationsModule, Editor, EditorChangeEvent, isEqual } from 'typewriter-editor';

export default function findReplace() {
  return (editor: Editor) => {
    async function onChange({ change }: EditorChangeEvent) {
      if (!change?.contentChanged || !findState.get().open) return;
      await tick(); // Allow the project store to update with the correct text
      findState.update();
      return;
    }

    function onDecorate({ doc, change, changedLines }: DecorateEvent) {
      if (change && !change.contentChanged) return;
      const decorator = (editor.modules.decorations as DecorationsModule).getDecorator('find');
      const { id, field } = editor.identifier;
      const { map, selected } = findState.get();
      const matches = map && map[id] && map[id][field];
      if (!matches) {
        decorator.remove();
        return;
      }
      if (changedLines && changedLines.length) {
        decorator.clearLines(changedLines);
        const lines = changedLines.map(line => doc.getLineRange(line));
        const from = lines[0][0];
        const to = lines[lines.length - 1][1];
        matches.forEach(match => {
          if ((match[0] >= from && match[0] < to) || (match[1] > from && match[1] <= to)) {
            decorator.decorateText([match[0], match[1]], { class: getClass(selected, match) });
          }
        });
      } else {
        decorator.clear();
        matches.forEach(match => decorator.decorateText([match[0], match[1]], { class: getClass(selected, match) }));
      }
      decorator.apply();
    }

    let firstTime = true;
    const unsubscribe = findState.subscribe(async () => {
      if (firstTime) return (firstTime = false);
      editor.render();
    });

    return {
      init() {
        editor.addEventListener('decorate', onDecorate);
        editor.addEventListener('change', onChange);
      },
      destroy() {
        unsubscribe();
        editor.removeEventListener('decorate', onDecorate);
        editor.removeEventListener('change', onChange);
      },
    };
  };
}

function getClass(selected: { range: [number, number] } | null, match: [number, number]) {
  return 'found' + (isEqual(selected?.range, match) ? ' found-selected' : '');
}
