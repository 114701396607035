// Forked from https://github.com/sveltejs/gestures/blob/master/src/tap.ts

import { addListener } from './listen';

// second argument is a destructured array with optional parameters for stopping
// propagation and the eventType
// set to empty array by default
export function tap(
  node: EventTarget,
  [eventType = 'mouse', stopBubbling = false]: [eventType?: string, stopBubbling?: boolean] = []
) {
  const mouseEnabled = true;
  let mouseTimeout: NodeJS.Timeout;

  function handleMousedown(event: MouseEvent) {
    if (stopBubbling) event.stopPropagation();

    const { clientX: x, clientY: y } = event;

    const removeMouseupHandler = addListener(node, `${eventType}up`, (event: MouseEvent) => {
      if (!mouseEnabled) return;
      const { target, clientX, clientY } = event;
      if (Math.abs(clientX - x) > 5) return;
      if (Math.abs(clientY - y) > 5) return;

      dispatchTap(node, target, 'tap', clientX, clientY);
      removeMouseupHandler();
    });

    clearTimeout(mouseTimeout);
    setTimeout(removeMouseupHandler, 300);
  }

  function handleFocus(event: FocusEvent) {
    if (stopBubbling) event.stopPropagation();
    const removeKeydownHandler = addListener(event.currentTarget, 'keydown', (event: KeyboardEvent) => {
      if (event.code === 'Space') dispatchTap(node, event.target, 'tap', null, null);
    });

    const removeBlurHandler = addListener(event.currentTarget, 'blur', () => {
      removeKeydownHandler();
      removeBlurHandler();
    });
  }

  const removeMousedownHandler = addListener(node, `${eventType}down`, handleMousedown);
  const removeFocusHandler = isButton(node as HTMLButtonElement) && addListener(node, 'focus', handleFocus);

  return {
    destroy() {
      removeMousedownHandler();
      removeFocusHandler && removeFocusHandler();
    },
  };
}

export function dbltap(node: EventTarget, stopBubbling = false) {
  let timeout: any;

  function onTap(event: MouseEvent) {
    if (stopBubbling) event.stopPropagation();

    if (timeout) {
      timeout = null;
      dispatchTap(node, event.relatedTarget, 'dbltap', event.clientX, event.clientY);
    } else {
      timeout = setTimeout(() => (timeout = null), 500);
    }
  }

  const removeTapHandler = addListener(node, 'tap', onTap);

  return {
    destroy() {
      removeTapHandler();
    },
  };
}

function isButton(node: HTMLButtonElement | HTMLInputElement) {
  return node.tagName === 'BUTTON' || node.type === 'button';
}

function dispatchTap(node: EventTarget, relatedTarget: EventTarget, event: string, clientX: number, clientY: number) {
  node.dispatchEvent(new MouseEvent(event, { relatedTarget, clientX, clientY }));
}
