import { Doc } from '@dabble/data/types';
import { Delta } from 'typewriter-editor';
import { ImageAttributes } from '../content/images';

export interface ExportOptions {
  doc: Doc;
  filename?: string;
  project?: 'novel';
  fileType?: 'word' | 'google' | 'text' | 'html';
  style?: 'manuscript' | 'dabble';
  shortName?: string;
  images?: boolean;
  counts?: Counts;
  descriptions?: boolean;
}

export interface ExportData {
  file: ExportFile;
  doc: Doc;
  counts: Counts;
  filename: string;
  style?: 'manuscript' | 'dabble';
  title: string;
  author: string;
  images: boolean;
  descriptions: boolean;
}

export interface AuthorInfo {
  phone?: string;
  address?: string;
  sceneSeparator?: string;
}

export type Counts = {
  words?: number;
  pages?: number;
  export_chapters?: number;
  export_pages?: number;

  novel_part?: number;
  novel_chapter?: number;
};

export interface ParagraphOptions {
  style?: string;
  list?: ListAttributes;
  image?: ImageAttributes;
  hr?: boolean;
}

export interface OrderedListAttributes {
  kind: 'ordered';
  type?: 'a' | 'A' | 'i' | 'I' | '1';
  indent?: number;
  start?: string;
}
export interface UnorderedListAttributes {
  kind: 'bullet';
  type?: 'dash' | 'check';
  indent?: number;
  checked?: boolean;
}
export type ListAttributes = OrderedListAttributes | UnorderedListAttributes;

export interface ExportFileConstructor {
  new (data: ExportData): ExportFile;
}

export interface ExportFile {
  styles: Styles;
  data: ExportData;

  setPageSize(width: number, height: number): this;
  pageNumbersStart(start: number): this;
  addSection(): Section;
  addParagraph(options: ParagraphOptions, content: Delta | string): this;
  addPageBreak(): this;

  save(): Promise<any>;
}

export interface Section {
  addHeader(): Header;
  addFooter(): Footer;
}

export interface Header {
  addParagraph(options: ParagraphOptions, content: Delta | string): this;
}

export interface Footer {
  addParagraph(options: ParagraphOptions, content: Delta | string): this;
}

export interface Styles {
  style(name: string, basedOn?: string): Style;
}

export enum DefaultStyles {
  Normal = 'Normal',
  Body = 'Body',
  Title = 'Title',
  Subtitle = 'Subtitle',
  Heading1 = 'Heading 1',
  Heading2 = 'Heading 2',
  Heading3 = 'Heading 3',
  Heading4 = 'Heading 4',
  Heading5 = 'Heading 5',
  Heading6 = 'Heading 6',
}

export interface Style extends ParagraphStyle, TextStyle {
  defaultNext(value: string): this;
}

export interface ParagraphStyle {
  alignment(value: 'start' | 'center' | 'end' | 'justified'): this;
  indent(value: number, side?: 'start' | 'end' | 'firstLine'): this;
  keepLinesTogether(value?: boolean): this;
  keepWithNext(value?: boolean): this;
  lineSpacing(value: number): this;
  spaceAbove(value: number): this;
  spaceBelow(value: number): this;
  tabStop(value: number, alignment?: 'start' | 'end'): this;
}

export interface TextStyle {
  backgroundColor(value: string | null): this;
  bold(value?: boolean): this;
  color(value: string): this;
  fontFamily(value: string, weight?: number): this;
  fontSize(value: number): this;
  italic(value?: boolean): this;
  smallCaps(value?: boolean): this;
  strikeThrough(value?: boolean): this;
  underline(value?: boolean): this;
}

export interface TextStyles {
  backgroundColor?: string | null;
  bold?: boolean;
  color?: string;
  fontFamily?: string;
  fontSize?: number;
  italic?: boolean;
  smallCaps?: boolean;
  strikeThrough?: boolean;
  underline?: boolean;
}
