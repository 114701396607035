<script>
  import { getTitle, settings } from '@dabble/app';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { createEventDispatcher } from 'svelte';
  import { frameOffsets } from '../popper-frames';
  import {
    currentHoverIndex,
    docs,
    lastSelection,
    listItemHeight,
    listScrollPositionTracker,
    listScrollTop,
    menuPosition,
    wordCopy,
  } from './mentions.ts';
  import { getLink } from './note-linking';

  export let editor;

  const dispatch = createEventDispatcher();
  const dropdownOffset = [-16, 10];

  let dropdown;
  let mouseY = 0;

  $: if (dropdown) {
    const list = document.getElementsByClassName('list');
    list[0].scrollTop = $listScrollTop;
  }

  $: element = {
    getBoundingClientRect: () => {
      if (!editor) return;
      const { x, y } = frameOffsets(editor.root);
      const { left, right, top, bottom, width, height } = editor.getBounds($menuPosition);
      return { left: left + x, right: right + x, top: top + y, bottom: bottom + y, width, height };
    },
    contextElement: editor && editor.root,
  };

  //updates the active list index via the cursor, but only if the mouse has actually moved (which prevents an issue it was causing with the scroll)
  function setlistHoverIndex(event, i) {
    if (mouseY !== event.clientY) {
      currentHoverIndex.update(() => i);
    }
  }
  //keeps mouse position updated so that it can be checked in setlistHoverIndex (above)
  function setMousePosition(event) {
    mouseY = event.clientY;
  }

  function onScroll(event) {
    listScrollPositionTracker.update(() => Math.round(event.target.scrollTop / $listItemHeight));
    listScrollTop.update(() => event.target.scrollTop);
  }

  async function insertLink() {
    const link = getLink($docs[$currentHoverIndex]);
    const title = getTitle($docs[$currentHoverIndex]);
    editor.delete([$lastSelection[0] - $wordCopy.length + 1, $lastSelection[1]]);
    editor.insert(title, { link: link + '' });
    editor.formatText({ link: null });
    dispatch('close');
  }
</script>

<Dropdown target={element} placement="top-start" offset={dropdownOffset} class="mentions-dropdown" noFocus>
  <div bind:this={dropdown} class="list" on:scroll={onScroll}>
    {#each $docs as doc, i}
      <button
        class="dropdown-item {i === $currentHoverIndex ? 'active' : 'no-highlight'}"
        on:focus={() => ''}
        on:mouseover={event => setlistHoverIndex(event, i)}
        on:mousemove={event => setMousePosition(event)}
        on:click={insertLink}
      >
        <Icon
          class="doc-icon"
          name={typeof settings.getFor(doc.type).icon == 'function'
            ? settings.getFor(doc.type).icon(doc)
            : settings.getFor(doc.type).icon}
        />
        <span class="doc-title {settings.getPlaceholderClass(doc, 'title')}">{getTitle(doc)}</span>
      </button>
    {/each}
  </div>
</Dropdown>

<style>
  :global(.dropdown-menu.mentions-dropdown) {
    width: 400px;
    background-clip: unset;
  }
  :global(.dropdown-menu.mentions-dropdown ol .active) {
    background-color: var(--menu-hover-color);
  }
  :global(.dropdown-menu.mentions-dropdown ol .no-highlight) {
    background-color: var(--white) !important;
  }
  :global(.dropdown-menu.mentions-dropdown .dropdown-item .doc-icon) {
    position: absolute;
    margin-left: -8px;
  }
  :global(.dropdown-menu.mentions-dropdown .dropdown-item) {
    white-space: nowrap;
    width: 375px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :global(.dropdown-menu.mentions-dropdown .dropdown-item .doc-title) {
    margin-left: 15px;
  }
  :global(.dropdown-menu.mentions-dropdown .dropdown-item .placeholder) {
    opacity: 0.5;
    font-style: italic;
  }
  .list {
    position: relative;
    max-height: 146px;
    overflow-y: scroll;
    padding: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    pointer-events: all;
  }
</style>
