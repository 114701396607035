<script>
  import {
    confirm,
    createProject,
    getDateString,
    getTitle,
    inform,
    projectGoalStore,
    projectMetas,
    projectStore,
    router,
    selectProject,
    shownModal,
    t,
    today,
    userProjects,
    waitFor,
  } from '@dabble/app';
  import { dateOptions } from '@dabble/data/intl';
  import rest from '@dabble/data/rest';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { mdiArrowRight, mdiBook, mdiCheckBold, mdiHelpCircle, mdiLoading } from '@mdi/js';
  import { format, lastDayOfMonth } from 'date-fns';
  import { monthOf, nanowrimo, nanowrimoAuthExpires, nanowrimoParticipating } from '../nanowrimo';

  import nanowrimoLogo from '../images/nanowrimo-icon.svg';

  const DAY = 24 * 60 * 60 * 1000;
  const THREE_DAYS = 3 * DAY;
  const WEEK = 7 * DAY;
  let loading;
  let auth;
  let error;
  let warning;
  let noProject;

  $: projects = $userProjects.filter(p => !p.deleted && !p.trashed && !p.isTemplate);
  $: nanoUserProject = projects.find(p => p.projectId === $nanowrimo.projectId);
  $: if ($shownModal === 'nanowrimoSettings') onModalShown();

  async function showSyncMessage() {
    if (await confirm($t('confirm_header_resync'), $t('confirm_resync'))) {
      loadNovel();
    }
  }

  async function gotoGoals() {
    if (!nanoUserProject) return;

    if (nanoUserProject.projectId !== $projectStore.projectId) {
      selectProject(nanoUserProject.projectId);
    }

    // Wait until the project is loaded
    const { project } = await waitFor(projectStore, data => data.projectId === nanoUserProject.projectId);
    const deadline = getDateString(lastDayOfMonth($monthOf));

    if (
      !$projectGoalStore ||
      !$projectGoalStore.targetId ||
      !$projectGoalStore.end ||
      $projectGoalStore.deadline !== deadline
    ) {
      const targetId = $projectStore.docs[project.children[0]].children[0]; // First book
      await projectGoalStore.update({ ...$projectGoalStore, end: 50000, targetId, deadline });
    }

    $shownModal = 'goalSettings';
  }

  async function unlinkAccount() {
    await nanowrimo.update({ participating: false, auth: null });
    $shownModal = null;
    inform('info', $t('nanowrimo_unlinked'));
  }

  async function updateNaNoWriMo(field, value) {
    if (field === 'projectId' && value === 'create-novel') {
      const { userProject } = await createProject({ type: 'novel' });
      value = userProject.projectId;
    }
    await nanowrimo.update({ [field]: value });
    if ($nanowrimoParticipating && $nanowrimo.auth && !$nanowrimo.novel) {
      loadNovel();
    }
  }

  async function loadNovel() {
    loading = true;
    error = null;

    let result;

    try {
      result = await rest.GET('nanowrimo/project', { 'x-nanowrimo-auth': $nanowrimo.auth });
      loading = false;
    } catch (error) {
      result = { error };
      loading = false;
    }

    const errorMsg = (result.error && result.error.error) || result.alert;

    if (errorMsg?.startsWith('user does not have a project')) {
      noProject = true;
    } else if (errorMsg) {
      console.error('api nanowrimo/info error:', result);
      error =
        errorMsg === 'Not Authorized - missing auth payload'
          ? 'The auth appears to be invalid. Please contact support.'
          : `Error from NaNoWriMo.org: ${errorMsg}`;
    } else if (result.project) {
      nanowrimo.update({ username: result.user, novel: result.project });
    } else if (result.alert) {
      warning = result.alert;
    }
  }

  function onModalShown() {
    auth = $nanowrimo && $nanowrimo.auth;
    error = null;

    if (!auth) {
      sessionStorage.lastUrlBeforeNaNoAuth = router.getUrl();
      if (sessionStorage.lastUrlBeforeNaNoAuth === '/nanowrimo/auth') {
        sessionStorage.lastUrlBeforeNaNoAuth = '/';
      }
      authenticate();
    } else if ($nanowrimoParticipating && !$nanowrimo.novel) {
      loadNovel();
    }
  }

  async function authenticate() {
    $shownModal = null;
    router.navigate('/nanowrimo/auth');
  }

  function getAuthColor(expires, today) {
    const diff = expires - today;
    if (diff < 0) return 'danger';
    if (diff < THREE_DAYS) return 'warning';
    return 'success';
  }
</script>

{#if $shownModal === 'nanowrimoSettings'}
  <Modal title="NaNoWriMo Settings" on:close={() => ($shownModal = null)}>
    {#if error}
      <Alert type="danger" dismissible>
        <strong>{$t('error')}</strong>
        {error}
      </Alert>
    {/if}

    {#if warning}
      <Alert type="warning" dismissible>
        {warning}
      </Alert>
    {:else if noProject}
      <Alert type="warning" dismissible>
        Please set a goal on <a
          href="https://nanowrimo.org/dashboard"
          target="_blank"
          rel="noreferrer"
          on:click={showSyncMessage}>NaNoWriMo.org</a
        >
      </Alert>
    {/if}

    <section class="goals-settings-main nano-settings">
      <div class="section-flex">
        <div class="nano-logo">
          <img src={nanowrimoLogo} alt="NaNoWriMo" />
        </div>
        <div class="content">
          {#if !$nanowrimoParticipating}
            <button class="btn primary left participate" on:click={() => updateNaNoWriMo('participating', true)}
              >Auto-submit word count to NaNoWriMo</button
            >
          {:else}
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={!$nanowrimo.paused}
                  on:change={event => updateNaNoWriMo('paused', !event.target.checked)}
                  disabled={!$nanowrimoParticipating || !$nanowrimo.projectId}
                />
                Auto-submit NaNoWriMo Word Count
              </label>
            </div>
            <p>To auto-submit your word count to NaNoWriMo, set the following information.</p>
            <ol>
              <li>
                <span class:complete={$nanowrimo.projectId}><Icon path={mdiCheckBold} class="check" /></span> Choose Dabble
                Project
              </li>
              <li>
                <span class:complete={$nanowrimo.novel}><Icon path={mdiCheckBold} class="check" /></span> Register Novel
              </li>
            </ol>
            <div class="form-group valid-key">
              <label for="">NaNoWriMo Username:</label>
              {#if $nanowrimo.username}
                {$nanowrimo.username}
                <span class="badge success">Valid</span>
              {:else}
                <span class="unknown">Unknown</span>
              {/if}
              <div>
                <label for="">Auth Expiration:</label>
                <span class="badge {getAuthColor($nanowrimoAuthExpires, $today)}"
                  >{format(new Date($nanowrimoAuthExpires), 'PP', dateOptions)}</span
                >
              </div>
              <div class="section-note">
                You will need to re-authenticate once your authentication with NaNoWriMo expires for word count
                submission.
              </div>
              {#if $nanowrimoAuthExpires - $today < WEEK}
                <div class="reauth-link">
                  <a href="/nanowrimo/auth" on:click|preventDefault={authenticate}>
                    <Icon path={mdiArrowRight} inline />
                    Re-authenticate Now
                  </a>
                </div>
              {/if}
            </div>
            <div class="section-header">1. Choose Dabble Project</div>
            <div class="form-group">
              <select
                class="form-control"
                on:change={event => updateNaNoWriMo('projectId', event.target.value)}
                value={$nanowrimo.projectId || ''}
              >
                <option value="">(None)</option>
                {#each projects as userProject}
                  <option value={userProject.projectId}
                    >{getTitle($projectMetas[userProject.projectId]) || '???'}</option
                  >
                {/each}
                <option value="create-novel">Create a new novel...</option>
              </select>
              <div class="section-note">Select the project containing the novel you are writing for NaNoWriMo.</div>
            </div>
            <div class="section-header">2. Set a goal on NaNoWriMo.org</div>

            <div>
              {#if loading}
                <Icon path={mdiLoading} spin class="loading" />
              {:else if $nanowrimo.novel}
                <Icon path={mdiBook} />
                {$nanowrimo.novel.title || 'Untitled'}
                <span class="badge success">Valid</span>
              {:else}
                <span class="unknown">No goal set (yet)</span>
              {/if}
            </div>
            <div class="form-group">
              <div class="section-text">
                <p class="small">
                  <strong>Create a novel project</strong> on the
                  <a href="https://nanowrimo.org/dashboard" target="_blank" rel="noreferrer" on:click={showSyncMessage}
                    >NaNoWriMo website</a
                  >
                  and set a goal for it.
                </p>
                <button class="btn small secondary" on:click={loadNovel} disabled={!auth && !$nanowrimo.auth}
                  >Resync with NaNoWriMo</button
                >
                <p>
                  <a
                    href="https://help.dabblewriter.com/en/articles/1258380-word-tracking-for-nanowrimo"
                    target="_blank"
                    rel="noreferrer"><Icon path={mdiHelpCircle} /> Need additional help?</a
                  >
                </p>
              </div>
            </div>
          {/if}
        </div>
      </div>
    </section>

    <footer>
      {#if $nanowrimoParticipating}
        <button class="btn secondary left" on:click={unlinkAccount}>Unlink NaNoWriMo Account</button>
      {/if}
      <button class="btn primary" on:click={gotoGoals} disabled={!$nanowrimoParticipating || !$nanowrimo.projectId}
        >Setup Goals</button
      >
    </footer>
  </Modal>
{/if}

<style>
  .section-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .section-text,
  .section-note {
    margin-top: 4px;
    color: var(--gray-light);
  }
  .section-text .small {
    font-size: var(--font-size-sm);
  }
  .section-note {
    font-size: var(--font-size-xs);
  }
  .section-flex {
    display: flex;
  }
  :global(.size-mobile) .section-flex {
    flex-direction: column;
  }
  :global(.size-mobile) .section-flex > :not(:first-child) {
    margin-left: 0;
    margin-top: 40px;
  }
  .section-flex > :not(:first-child) {
    margin-left: 40px;
  }
  .nano-logo {
    width: 180px;
    height: 180px;
    flex: 0 0 auto;
  }

  .nano-logo img {
    width: 180px;
    height: 180px;
  }
  .nano-settings :global(.icon.check) {
    color: var(--gray-lighter);
  }
  .nano-settings .complete :global(.icon.check) {
    color: var(--green);
  }
  .valid-key {
    font-size: 0.875rem;
  }
  .nano-settings :global(.icon.loading) {
    color: #ccc;
    margin-left: 4px;
  }
  label {
    margin-right: 10px;
  }
  .unknown {
    font-style: italic;
    color: var(--dabble-gray);
  }
  .participate.btn {
    margin: 80px 0 0;
  }
  .reauth-link {
    font-weight: bold;
    font-size: var(--font-size-sm);
  }
</style>
