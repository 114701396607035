export interface StatefulPromise<T> extends Promise<T> {
  resolve: (value?: T) => this;
  reject: (reason?: any) => this;
  // Process/isProcessing is optional and may be used to indicate when a promise is no longer in a queue and is being
  // acted upon (request sent to a server or result being calculated)
  process: () => this;
  isPending: () => boolean;
  isProcessing: () => boolean;
  isResolved: () => boolean;
  isRejected: () => boolean;
  cancel: () => void;
}

export function statefulPromise<T>(
  executor?: (resolve: (value?: T) => void, reject: (reason?: any) => void, processing: () => void) => void
): StatefulPromise<T> {
  let state = 'pending';
  let resolve: (value?: T | PromiseLike<T>) => any;
  let reject: (reason?: any) => any;
  let process: (reason?: any) => any;

  const promise = new Promise<T>((res, rej) => {
    resolve = value => {
      promise.isPending() && (state = 'resolved') && res(value);
      return promise;
    };
    reject = reason => {
      promise.isPending() && (state = 'rejected') && rej(reason);
      return this;
    };
    process = () => {
      state === 'pending' && (state = 'processing');
      return this;
    };
    executor && executor(resolve, reject, process);
  }) as StatefulPromise<T>;

  promise.resolve = resolve;
  promise.reject = reject;
  promise.process = process;
  promise.isPending = () => state === 'pending' || state === 'processing';
  promise.isProcessing = () => state === 'processing';
  promise.isResolved = () => state === 'resolved';
  promise.isRejected = () => state === 'rejected';
  promise.cancel = () => (state === 'pending' || state === 'processing') && (state = 'rejected');

  return promise;
}
