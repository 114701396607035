import { Delta, EditorRange, EditorTextChange, Line, Op, TextDocument } from 'typewriter-editor';
import { cancelLineCheck } from './grammar-check';

/**
 * When a change happens to a document, go through the document and adjust/delete any issues found within
 */
export function removeTouchedIssues(doc: TextDocument, delta: Delta, change: EditorTextChange) {
  const changeIter = Op.iterator(delta.ops);
  let pos = 0;

  // Go through each line's changes and remove touched grammar issues
  while (changeIter.hasNext()) {
    const op = changeIter.next();

    if (op.retain) {
      // Skip past text that wasn't altered
      pos += op.retain;
    } else if (op.delete || op.insert) {
      // Check if the grammar format was touched by either of these operations (doc is the pre-changed document)
      const length = typeof op.insert === 'string' ? op.insert.length : op.insert ? 1 : 0;

      const { grammar: before } = doc.getTextFormat([pos - 1, pos], { allFormats: true }) as { grammar: string };
      const { grammar: after } = doc.getTextFormat([pos + length, pos + length + 1], { allFormats: true }) as {
        grammar: string;
      };
      if (before || after) {
        // remove the touching grammar issues and mark the line(s) as changed
        const lines = doc.getLinesAt([pos, pos + length + 1]);

        // If there is an enqueued check for this line, cancel it since it has changed. It will be re-enqueued
        lines.forEach(line => cancelLineCheck(line));

        if (before) removeIssueFormat(doc, change, lines, before);
        if (after && after !== before) removeIssueFormat(doc, change, lines, after);
      }

      if (op.delete) {
        pos += op.delete;
      }
    }
  }

  return change;
}

function removeIssueFormat(doc: TextDocument, change: EditorTextChange, lines: Line[], issueId: string) {
  let [start, end] = getIssueRange(doc, lines[0], issueId);
  if (start && lines.length > 1) {
    [, end] = getIssueRange(doc, lines[lines.length - 1], issueId);
  }
  if (start !== undefined) {
    change.formatText([start, end], { grammar: null });
    lines.forEach(line => {
      change.formatLine(doc.getLineRange(line)[0], { ...line.attributes, grammar: { h: '' } });
    });
  }
}

export function getIssueRange(doc: TextDocument, line: Line, issueId: string): EditorRange {
  const [lineStart] = doc.getLineRange(line);
  let pos = lineStart;
  let start: number;
  let end: number;
  line.content.ops.forEach(op => {
    const length = Op.length(op);
    if (op.attributes?.grammar === issueId) {
      if (start === undefined) start = pos;
      end = pos + length;
    }
    pos += length;
  });
  return [start, end];
}
