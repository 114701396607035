<script>
  import { globals, t } from '@dabble/app';
  import { enterKey } from '@dabble/toolkit/events';
  import { focus } from '@dabble/toolkit/focus';
  import { autolink, br, escape } from '@dabble/toolkit/helpers';
  import { mdiAlertOctagon } from '@mdi/js';
  import Alert from './Alert.svelte';
  import Fireworks from './Fireworks.svelte';
  import Icon from './Icon.svelte';
  import Modal from './Modal.svelte';

  let informData = null;
  let alertData = null;
  let confirmData = null;
  let dangerData = null;
  let promptData = null;
  let hovered = false;

  function resolve(obj, value) {
    obj && obj.resolve(value);
  }

  function resolveIfTimedout(obj, value) {
    obj && obj.resolveIfTimedout(value);
  }

  // Type of success (green), info (blue), warning (yellow), danger (red)
  globals.inform = function (type, title, message) {
    if (informData) {
      clearTimeout(informData.timeout);
      informData = null;
    }

    if (!message) {
      message = title;
      title = '';
    }

    return new Promise(resolve => {
      let timeout = setTimeout(() => {
        timeout = 0;
        if (!hovered) resolve();
      }, 6000);

      informData = {
        type,
        title,
        message,
        resolve,
        timeout,
        resolveIfTimedout: () => {
          if (!timeout) {
            hovered = false;
            resolve();
          }
        },
      };
    }).then(() => {
      informData = null;
    });
  };

  globals.alert = function (title, message, options = { okButtonText: $t('dialog_ok') }) {
    return new Promise(resolve => {
      alertData = { title, message, resolve, options };
    }).then(() => {
      alertData = null;
    });
  };

  globals.confirm = function (title, message, options = {}) {
    if (typeof message !== 'string') {
      options = message || options;
      message = title;
      title = $t('confirm');
    }

    return new Promise(resolve => {
      confirmData = { title, message, options, resolve };
    }).then(answer => {
      confirmData = null;
      return answer;
    });
  };

  globals.danger = function (title, message, options = {}) {
    if (typeof message !== 'string') {
      options = message || options;
      message = title;
      title = $t('confirm');
    }

    return new Promise(resolve => {
      dangerData = { title, message, options, resolve };
    }).then(answer => {
      dangerData = null;
      return answer;
    });
  };

  globals.prompt = function (title, message, options = {}) {
    return new Promise(resolve => {
      promptData = { title, message, options, input: '', resolve };
    }).then(answer => {
      promptData = null;
      return answer;
    });
  };
</script>

{#if informData}
  <Alert
    type={informData.type}
    dismissible
    global
    bind:hovered
    on:close={() => resolve(informData)}
    on:unhovered={() => resolveIfTimedout(informData)}
    visible={informData}
  >
    {#if informData}
      {#if informData.title}<strong>{informData.title}</strong>{/if}
      {informData.message}
    {/if}
  </Alert>
{/if}

{#if alertData}
  <Modal title={alertData.title} size="small" on:close={() => resolve(alertData)} nofocus={alertData.options.noFocus}>
    <section>
      {#if alertData.options.fireworks}
        <Fireworks />
      {/if}
      <p>{@html autolink(br(escape(alertData.message)), true)}</p>
    </section>
    <footer>
      <button class="btn primary" on:click={() => resolve(alertData)} use:focus
        >{alertData.options.okButtonText || $t('dialog_ok')}</button
      >
    </footer>
  </Modal>
{/if}

{#if confirmData}
  <Modal
    title={confirmData.title}
    size="small"
    on:close={() => resolve(confirmData, null)}
    nofocus={confirmData.options.noFocus}
  >
    <section>
      <p>{@html autolink(br(escape(confirmData.message)), true)}</p>
    </section>
    <footer>
      <button class="btn secondary" on:click={() => resolve(confirmData, false)}
        >{confirmData.options.yesNo ? $t('dialog_no') : $t('dialog_cancel')}</button
      >
      <button class="btn primary" on:click={() => resolve(confirmData, true)} use:focus
        >{confirmData.options.yesNo ? $t('dialog_yes') : confirmData.options.okButtonText || $t('dialog_ok')}</button
      >
    </footer>
  </Modal>
{/if}

{#if dangerData}
  <Modal
    title={dangerData.title}
    size="small"
    on:close={() => resolve(dangerData, null)}
    nofocus={dangerData.options.noFocus}
    class="danger"
  >
    <section class="danger">
      <div class="danger">
        <Icon path={mdiAlertOctagon} />
      </div>
      <p>{@html autolink(br(escape(dangerData.message)), true)}</p>
    </section>
    <footer>
      <button class="btn secondary" on:click={() => resolve(dangerData, false)}
        >{dangerData.options.yesNo ? $t('dialog_no') : $t('dialog_cancel')}</button
      >
      <button class="btn primary" on:click={() => resolve(dangerData, true)} use:focus
        >{dangerData.options.yesNo ? $t('dialog_yes') : dangerData.options.okButtonText || $t('dialog_ok')}</button
      >
    </footer>
  </Modal>
{/if}

{#if promptData}
  <Modal
    title={promptData.title}
    size="small"
    on:close={() => resolve(promptData, '')}
    nofocus={promptData.options.noFocus}
  >
    <section>
      <p>{@html autolink(br(escape(promptData.message)), true)}</p>
      <input
        type="text"
        class="form-control"
        placeholder={promptData.options.placeholder || ''}
        bind:value={promptData.input}
        use:enterKey={() => resolve(promptData, promptData.input)}
        use:focus
      />
    </section>
    <footer>
      <button class="btn secondary" on:click={() => resolve(promptData, '')}>{$t('dialog_cancel')}</button>
      <button class="btn primary" on:click={() => resolve(promptData, promptData.input)}>{$t('dialog_ok')}</button>
    </footer>
  </Modal>
{/if}

<style>
  section.danger {
    display: flex;
  }
  section .danger {
    color: #d9534f;
    font-size: 72px;
    line-height: 72px;
    padding: 10px 10px 10px 0;
  }
</style>
