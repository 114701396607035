<script lang="ts">
  import { t } from '@dabble/app';
  import { number } from '@dabble/toolkit/helpers';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import {
    SECONDS_BEFORE_START,
    lastWordCount,
    percent,
    secondsLeft,
    secondsUntil,
    sprint,
    status,
    timeLeft,
  } from '../sprints';

  export let small = false;
  $: size = small ? 60 : 200;
  $: stroke = small ? 16 : 40;
  const padding = 2;

  $: radius = (size - padding * 2) / 2;
  $: normalizedRadius = radius - stroke / 2;
  $: circumference = normalizedRadius * 2 * Math.PI;
  $: offset = circumference - $percent * circumference;

  $: minutes = Math.floor(($secondsUntil || $secondsLeft) / 60);
  $: seconds = Math.ceil($secondsUntil || $secondsLeft) % 60;
  $: content = (minutes ? minutes + ':' : '') + (minutes && seconds < 10 ? '0' : '') + seconds;
  $: final = !minutes && seconds <= 10 && $timeLeft > 0;
  $: even = final && seconds % 2 === 0;
</script>

<div class="sprint-timer" class:small>
  <svg
    width="{size}px"
    height="{size}px"
    class="circle"
    viewBox="0 0 {size} {size}"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <circle
        id="background-ring"
        fill="transparent"
        stroke-width={stroke}
        r={normalizedRadius}
        cx={radius + padding}
        cy={radius + padding}
      />
      <circle
        id="progress-ring"
        fill="transparent"
        stroke-dasharray="{circumference} {circumference}"
        style="stroke-dashoffset: {offset}"
        stroke-width={stroke + 1}
        r={normalizedRadius}
        cx={radius + padding}
        cy={radius + padding}
      />
      <filter id="background-filter" x="-50%" y="-50%" width="200%" height="200%">
        <feGaussianBlur stdDeviation="1.5" in="SourceAlpha" result="shadowBlurInner" />
        <feOffset dx="0" dy="1" in="shadowBlurInner" result="shadowOffsetInner" />
        <feComposite
          in="shadowOffsetInner"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix" in="shadowInnerInner1" />
      </filter>
      <filter id="progress-filter" x="-50%" y="-50%" width="200%" height="200%">
        <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g>
      <use class="background-ring" fill-rule="evenodd" xlink:href="#background-ring" />
      <use class="ring-filter" filter="url(#background-filter)" xlink:href="#background-ring" />
    </g>
    <g>
      <use class="ring-filter" filter="url(#progress-filter)" xlink:href="#progress-ring" />
      <use class="progress-ring" fill-rule="evenodd" xlink:href="#progress-ring" />
    </g>
  </svg>
  <div class="time {$status}">
    <div class="time-display">
      {#if $status === 'started'}
        <div
          class="units {!small && minutes > 9 ? 'small' : small || minutes ? 'medium' : seconds > 9 ? 'large' : 'xl'}"
          class:final
          class:even={final && even}
          class:odd={final && !even}
          data-content={content}
        >
          {content}
        </div>
        <div class="label hide">{minutes ? 'minutes' : 'seconds'}</div>
      {:else if $status === 'finished'}
        <div class="done">{$t('finished')}</div>
      {:else if $secondsUntil <= SECONDS_BEFORE_START}
        <div class="label hide">{$t('starting_in')}</div>
        <div
          class="units {!small && minutes > 9 ? 'small' : small || minutes ? 'medium' : seconds > 9 ? 'large' : 'xl'}"
          class:final
          class:even={final && even}
          class:odd={final && !even}
          data-content={content}
        >
          {content}
        </div>
      {/if}
    </div>
  </div>
</div>

<div class="details">
  <div class="detail light" use:tooltipTop={$t('counts_words')}>
    <div class="value">
      {number($sprint.wordCount)}
      {#if $lastWordCount}
        / {number($lastWordCount)}
      {/if}
    </div>
    <div class="detail-label">{$t('counts_words', { count: $sprint.wordCount })}</div>
  </div>
</div>

<style>
  .sprint-timer {
    position: relative;
    display: flex;
    width: 200px;
    justify-content: center;
    --darker-color: rgb(var(--dabble-blue-700));
    --lighter-color: rgb(var(--dabble-blue-400));
  }
  .circle {
    flex: 0 0 auto;
  }
  .sprint-timer.small {
    justify-content: start;
    align-items: center;
    margin: 0.5rem 0;
  }
  :global(html[data-theme='dark']) .sprint-timer {
    --darker-color: rgb(var(--dabble-blue-800));
    --lighter-color: rgb(var(--dabble-blue-600));
  }
  .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .small .time {
    position: relative;
    justify-content: start;
    margin-left: 0.5rem;
  }
  .time.small {
    display: none;
  }
  .time-display {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .small .time-display {
    align-items: start;
  }
  .units {
    font-family: 'Roboto Condensed';
    font-size: var(--font-size-xxl);
    font-weight: 700;
    color: var(--text-color-light);
    line-height: 0.8;
  }
  .medium {
    font-size: 3rem;
  }
  .large {
    font-size: 3.75rem;
  }
  .xl {
    font-size: 4.5rem;
  }
  .final,
  .xl {
    color: var(--lighter-color);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  }
  .done {
    font-family: 'Roboto Condensed';
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--text-color-lighter);
  }
  .label {
    position: relative;
    width: 100%;
    font-size: var(--font-size-xs);
    color: var(--text-color-lighterer);
  }
  .label:last-child {
    text-align: right;
  }
  .small .label:first-child {
    line-height: 0;
    top: calc(-0.5 * var(--font-size-xs));
  }
  .small .label:last-child {
    line-height: 0;
    bottom: calc(-0.5 * var(--font-size-xs));
  }
  .time-to-start {
    font-weight: 700;
    font-size: 1rem;
    color: var(--text-color-lighterer);
  }
  #progress-ring {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  .final.even::before,
  .final.odd::before {
    position: absolute;
    content: attr(data-content);
    pointer-events: none;
  }
  .final.even::before {
    animation: expload 1s ease-out;
  }
  .final.odd::before {
    animation: expload2 1s ease-out;
  }

  .detail {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: var(--font-size-lg);
    font-family: 'Roboto Condensed';
  }
  .detail .value {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    color: var(--text-color-light);
    margin-right: 1rem;
  }
  .detail-label {
    white-space: nowrap;
    color: var(--text-color-lighterer);
  }
  .info {
    font-size: var(--font-size-xs);
    color: var(--text-color-lighterer);
  }
  .ring-filter {
    stroke: black;
  }
  .background-ring {
    stroke: var(--darker-color);
  }
  .progress-ring {
    stroke: var(--lighter-color);
  }
  @keyframes expload {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(10);
      opacity: 0;
    }
  }
  @keyframes expload2 {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(10);
      opacity: 0;
    }
  }
</style>
