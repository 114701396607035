<script>
  import onboarding_help_create_plot_line from '../images/onboarding_help_create_plot_line.webp';
  import onboarding_help_create_project from '../images/onboarding_help_create_project.webp';
  import onboarding_help_create_scene from '../images/onboarding_help_create_scene.webp';
  import onboarding_help_write_1000 from '../images/onboarding_help_write_1000.webp';
  import onboarding_help_write_note from '../images/onboarding_help_write_note.webp';
  export let name;
</script>

{#if name === 'onboarding_help_create_project'}
  <img src={onboarding_help_create_project} alt="" />
{:else if name === 'onboarding_help_create_scene'}
  <img src={onboarding_help_create_scene} alt="" />
{:else if name === 'onboarding_help_write_note'}
  <img src={onboarding_help_write_note} alt="" />
{:else if name === 'onboarding_help_create_plot_line'}
  <img src={onboarding_help_create_plot_line} alt="" />
{:else}
  <img src={onboarding_help_write_1000} alt="" />
{/if}

<style>
  img {
    border: 1px solid var(--gray-light);
    padding: 0;
    margin: 0;
    max-width: 100%;
  }
</style>
