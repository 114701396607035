import { createId as uuid } from 'crypto-id';

export { uuid };

const EMPTY = {};

interface AvoidMap {
  [id: string]: any;
}

/**
 * Lengths of the different object ids.
 */
export const idLengths = {
  project: 16, // Because this is a global number, it should be larger
  doc: 4, // Because we can check synchronously this can be small
  change: 6, // Because this is per-project AND doesn't need to be absolutely unique this number this can be small
};

/**
 * Generate a new unique-enough doc id
 */
export function createId(length = 24, avoid: AvoidMap = EMPTY, postfix = ''): string {
  let id;
  while (avoid[(id = uuid(length) + postfix)] || parseInt(id) + '' === id);
  return id;
}

/**
 * Generate a new unique-enough project id
 */
export function createProjectId(): string {
  return uuid(idLengths.project);
}

/**
 * Generate a new unique-enough doc id
 */
export function createDocId(docs: AvoidMap): string {
  return createId(idLengths.doc, docs);
}

/**
 * Generate a new unique-enough change id
 */
export function createChangeId(): string {
  return uuid(idLengths.change);
}
