import { projectStore, settings } from '@dabble/app';
import { DocSettings } from '@dabble/data/types';
import { Editor } from 'typewriter-editor';

export default function pasteHandling(editor: Editor) {
  async function onPaste(event: any) {
    const { root } = editor;
    const { id, field } = root.parentElement.dataset;
    if (!id || !field) return;
    const doc = projectStore.getDoc(id);
    const docSettings = settings.getFor(doc) as DocSettings;
    const onPaste = docSettings.onPaste?.[field];
    if (onPaste) {
      onPaste(event, doc, editor);
    }
  }

  return {
    init() {
      editor.on('paste', onPaste);
    },
    destroy() {
      editor.off('paste', onPaste);
    },
  };
}
