<script>
  import { dateOptions, plugins, t, today } from '@dabble/app';
  import { format, isSameDay, isSameYear } from 'date-fns';

  export let uid;
  export let comment;

  const getUser = plugins.stores.getUser;

  function getDate(today, date) {
    const time = format(date, 'p', dateOptions) + ' ';
    if (isSameDay(today, date)) {
      return time + $t('today');
    } else if (isSameYear(today, date)) {
      return time + format(date, 'MMM d', dateOptions);
    } else {
      return time + format(date, 'MMM d, yyyy', dateOptions);
    }
  }
</script>

<div class="header">
  <!-- <Avatar uid={comment && comment.uid || uid}/> -->
  <div class="header-text">
    <div class="name">
      {$getUser((comment && comment.uid) || uid).name}
    </div>
    {#if comment}
      <div class="date">
        {getDate($today, new Date(comment.created))}
      </div>
    {/if}
  </div>
  <slot />
</div>

<style>
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .header-text {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    min-width: 0;
  }
  .name {
    padding: 0;
    font-weight: bold;
    font-size: var(--font-size-sm);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date {
    color: var(--text-color-lighter);
    font-size: var(--font-size-xs);
  }
</style>
