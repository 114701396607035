import { inform, t } from '@dabble/app';
import { Editor, EditorChangeEvent, getLineInfoFromPoint } from 'typewriter-editor';
import { DropOptions } from './droppable';
import { createImageInfo, saveImage } from './images';

/*
From: https://help.medium.com/hc/en-us/articles/215679797-Using-images

Preferred image sizes
While images in Dabble documents will appear differently whether viewed on web or mobile web, below are some guidelines
for image dimensions in documents. If you use too small an image, neither Dabble nor other social networks will be able
to pull a featured image for a preview.

Full column-width images: 1400 px wide
Out-set images: 2040 px wide
Screen-width images: 2500 px wide

Image placement
Depending on the size of your image, you'll be able to test out different placement options from the toolbar.

Note: Your image needs to be at least 1060 px wide for all four placement options to show up.

My notes: in testing, 1192px was the width that allowed all options, lower than that limited to the 2 options
*/

export const imageDropOptions: DropOptions = {
  dropEffect: 'copy',
  mimes: ['Files'],
  acceptable(event) {
    if (event.dataTransfer.types.includes('Files')) {
      return Array.from(event.dataTransfer.items).some(file => file.type.startsWith('image/'));
    }
  },
  async drop(event) {
    const images = Array.from(event.dataTransfer.files).filter(file => file.type.startsWith('image/'));
    const editor = ((event.target as HTMLElement).closest('.typewriter-editor') as any)?.editor as Editor;
    if (!editor) {
      await inform('warning', t.get()('content_not_document'));
      return;
    }
    const info = getLineInfoFromPoint(editor, event.clientY);
    if (!info) return;
    const { line, belowMid } = info;
    const range = editor.doc.getLineRange(line);
    let index = belowMid ? range[1] + 1 : range[0];
    function onChange(event: EditorChangeEvent) {
      if (event.change.contentChanged) {
        index = event.change.delta.transformPosition(index);
      }
    }
    editor.on('change', onChange);
    await Promise.all(
      images.map(async file => {
        const info = await createImageInfo({ file });
        const url = await saveImage({ file }, info);
        editor.off('change', onChange);
        editor.insert('\n', { image: url, ...info });
      })
    );
  },
};
