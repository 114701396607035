import { statefulPromise, StatefulPromise } from './stateful-promise';

type Generator<T> = (...args: any[]) => Promise<T>;

interface QueueEntry {
  generator: Generator<any>;
  promise: StatefulPromise<any>;
}

export default class PromiseQueue {
  concurrency: number; // How many calls can be sent at once
  throttle: number; // How many calls can be queued before they get discarded
  private _pending: Set<QueueEntry>;
  private _queue: QueueEntry[];

  constructor(concurrency = Infinity, throttle = Infinity) {
    this.concurrency = concurrency;
    this.throttle = throttle;
    this._pending = new Set();
    this._queue = [];
  }

  clear() {
    this._pending.forEach(entry => entry.promise.cancel());
    this._queue.forEach(entry => entry.promise.cancel());
    this._pending.clear();
    this._queue.length = 0;
  }

  add<T>(generator: Generator<T>, toHead = false): StatefulPromise<T> {
    const promise = statefulPromise<T>();
    if (this._queue.length < this.throttle - this._pending.size) {
      this._queue[toHead ? 'unshift' : 'push']({ generator, promise });
      this._dequeue();
      return promise;
    } else {
      const last = this._queue[this._queue.length - 1] || Array.from(this._pending).pop();
      return last.promise;
    }
  }

  addToHead<T>(generator: Generator<T>): StatefulPromise<T> {
    return this.add(generator, true);
  }

  getPendingLength() {
    return this._pending.size;
  }

  getQueueLength() {
    return this._queue.length;
  }

  private _dequeue() {
    if (this._pending.size >= this.concurrency) return false;

    let entry: QueueEntry;

    while ((entry = this._queue.shift())) {
      if (entry.promise.isPending()) break;
    }
    if (!entry) return;

    this._pending.add(entry);
    const { generator, promise } = entry;
    promise.process();

    const handle = (callback: Function) => {
      return (...args: any[]) => {
        this._pending.delete(entry);
        let result;
        if (promise.isPending()) {
          result = callback(...args);
        }
        this._dequeue();
        return result;
      };
    };

    Promise.resolve(generator()).then(handle(promise.resolve), handle(promise.reject));
  }
}
