import { isProduction, loadingQuoteCleared, locale, preferences } from '@dabble/app';

let removeTimeout: any;

addEventListener('click', removeQuote);
const quoteTimeout = setTimeout(removeQuote, 6500 - (Date.now() - (<any>window).quoteDisplayed));

preferences.subscribe(prefs => {
  const hide = prefs.hideLoadingQuotes || isProduction;
  if (hide && !localStorage.hideLoadingQuotes) localStorage.hideLoadingQuotes = true;
  else if (!hide && localStorage.hideLoadingQuotes) delete localStorage.hideLoadingQuotes;
  if (hide && !loadingQuoteCleared.get()) {
    removeQuote();
  }

  const hideHolidays = prefs.hideHolidays;
  if (hideHolidays != null && hideHolidays !== localStorage.hideHolidays) {
    if (hideHolidays) localStorage.hideHolidays = hideHolidays;
    else delete localStorage.hideHolidays;
  }

  const local = prefs.locale;
  if (local != null && local !== localStorage.i18nextLng) {
    localStorage.i18nextLng = local;
    locale.set(local);
  }
});

function removeQuote(event?: Event) {
  if (event) {
    try {
      event.preventDefault();
    } catch (err) {}
  }
  removeEventListener('click', removeQuote);
  clearTimeout(quoteTimeout);
  const quote = document.getElementById('loader');
  document.getElementById('index-clear-btns')?.remove();
  if (quote) quote.style.opacity = '0';
  removeTimeout = setTimeout(function () {
    clearTimeout(removeTimeout);
    if (quote) quote.remove();
    loadingQuoteCleared.set(true);
  }, 300);
}
