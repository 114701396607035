<script lang="ts">
  import { currentDoc, projectStore, settings } from '@dabble/app';
  import { t } from '@dabble/data/intl';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import ToolButton from '@dabble/toolkit/ToolButton.svelte';
  import { mdiEye } from '@mdi/js';

  let menuOpen: boolean;
</script>

<div class="tools-buttons">
  <div class="top-buttons">
    {#each settings.getValuesFromPlugins('sidebarButtons', $projectStore.project, $currentDoc) as component}
      <svelte:component this={component} />
    {/each}
  </div>
  <div class="toggle-buttons">
    <div class="separator" />
    {#each settings.getValuesFromPlugins('toolButtonsTop', $projectStore.project, $currentDoc) as component}
      <svelte:component this={component} />
    {/each}
    <ToolButton icon={mdiEye} on:click={() => (menuOpen = true)} tooltip={$t('ui_toggle_tooltip')} />
    {#if menuOpen}
      <Dropdown class="ui-toggle-menu" placement="left" arrow offset="0,12" on:close={() => (menuOpen = false)}>
        {#each settings.getValuesFromPlugins('uiToggleMenu', $projectStore.project, $currentDoc) as component}
          <svelte:component this={component} />
        {/each}
      </Dropdown>
    {/if}
  </div>
  <div class="fill" />
  <div class="bottom-buttons">
    {#each settings.getValuesFromPlugins('toolButtonsBottom', $projectStore.project, $currentDoc) as component}
      <svelte:component this={component} />
    {/each}
  </div>
</div>

<style>
  .tools-buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 49px;
    padding: 8px 0;
    background-color: var(--white);
    order: 2;
    border-left: var(--side-tools-border);
  }
  .tools-buttons :global(button.icon) {
    font-size: 24px;
    padding: 12px;
  }
  .tools-buttons :global(.separator) {
    align-self: stretch;
    margin: 4px 8px;
    border-top: 1px solid var(--gray-lightish);
  }
  .top-buttons,
  .bottom-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fill {
    flex: 1;
  }
  :global(.is-touch) .tools-buttons {
    width: 57px;
  }
  :global(.is-touch) .tools-buttons :global(button.icon) {
    font-size: 32px;
    padding: 12px;
  }
  :global(.dropdown-menu.ui-toggle-menu .dropdown-item),
  :global(.dropdown-menu.ui-toggle-menu .dropdown-item:hover) {
    background: transparent;
  }
  :global(.dropdown-menu.ui-toggle-menu .dropdown-item.checked),
  :global(.dropdown-menu.ui-toggle-menu .dropdown-item.checked:hover) {
    color: #292b2c;
    background-color: #e6e6e6;
  }
</style>
