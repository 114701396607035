import { DatabaseStore } from '../dabble-database';
import { UserDoc } from '../types';
import { createLookupFromList, createModifiedDataStore, Lookup } from './modified-data';
import { ProjectData, ProjectStore } from './project';
import { Readable } from './store';

export type UserDocs = Lookup<UserDoc>;

export interface UserDocsStore extends Readable<UserDocs> {
  update: (docId: string, updates: Partial<UserDoc>) => Promise<UserDoc>;
  delete: (docId: string) => Promise<UserDoc>;
  deleteAllFor: (projectId: string) => Promise<void>;
  isLoaded: Readable<boolean>;
}

export function createUserDocsStore(dbStore: DatabaseStore, project: ProjectStore): UserDocsStore {
  let projectId: string;

  const docsStore = createModifiedDataStore<UserDoc, UserDocs>(dbStore, 'user_docs', {
    query: store => projectId && store.where().startsWith([projectId]),
    start: () => project.subscribe(onProject),
    filterChanges: (doc, key) => key[0] === projectId,
    packageData: list => createLookupFromList(list, 'docId'),
  });

  async function onProject({ project }: ProjectData) {
    projectId = project && project.id;
    await docsStore.reload();
  }

  function update(docId: string, updates: Partial<UserDoc>) {
    if (!projectId) throw new Error('Project not loaded for updating doc metadata.');
    return docsStore.update([projectId, docId], updates);
  }

  function deleteData(docId: string) {
    if (!projectId) throw new Error('Project not loaded for updating doc metadata.');
    return docsStore.delete([projectId, docId]);
  }

  async function deleteAllFor(projectId: string) {
    await dbStore.get().stores.user_docs.where().startsWith([projectId]).deleteAll();
  }

  return {
    update,
    delete: deleteData,
    deleteAllFor,
    isLoaded: docsStore.isLoaded,
    get: docsStore.get,
    subscribe: docsStore.subscribe,
  };
}
