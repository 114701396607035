import { Project } from '../types';
import JSONPatch, { JSONPatchOp } from './json-patch';

const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
export const PROJECT_SHAPE: Project = {
  id: '',
  version: 0,
  changeId: '',
  type: '',
  children: [],
  links: {},
  docs: {
    trash: {
      id: 'trash',
      type: 'trash',
      children: [],
    },
  },
  created: 0,
  committed: 0,
};

export interface Snapshot {
  id: string;
  version: number;
  changeId: string;
  committed: number;
}

export interface DynamicSnapshot extends Snapshot {
  [key: string]: any;
}

export interface Change {
  id: string;
  projectId: string;
  version: number;
  prevId?: string;
  author: string;
  ops: JSONPatchOp[];
  created: number;
  committed: number;
}

export function createChangeId() {
  let length = 6;
  let id = '';
  while (length--) {
    id += chars[(Math.random() * chars.length) | 0];
  }
  return id;
}

/**
 * Apply a single change to a project.
 */
export function applyChange<T extends Snapshot>(snapshot: T, change: Change, strict?: boolean) {
  return new JSONPatch(change.ops).apply(snapshot, { strict });
}

/**
 * Apply a list of changes to a project in an efficient manner, more efficient than calling applyChange for each
 * individual change.
 */
export function applyChanges<T extends Snapshot>(snapshot: T, changes: Change[], strict?: boolean) {
  const ops = changes.reduce((ops, change) => ops.concat(change.ops), []);
  return new JSONPatch(ops).apply(snapshot, { strict });
}

/**
 * Rebase local changes against incoming (authoritative) remote changes
 */
export function rebaseChanges(localChanges: Change[], remoteChanges: Change[]): Change[] {
  // Getting empty objects from server, this check ensures only real changes are being processed
  remoteChanges = remoteChanges.filter(c => c.ops);
  if (!localChanges || !localChanges.length || !remoteChanges || !remoteChanges.length) return localChanges;
  const remoteOps = remoteChanges.reduce((ops, change) => ops.concat(change.ops), []);
  let version = remoteChanges[remoteChanges.length - 1].version + 1;
  let prevId = remoteChanges[remoteChanges.length - 1].id;

  const rebaseChange = (change: Change) => {
    if (!change.ops.length) return;
    change = { ...change };
    change.ops = new JSONPatch(remoteOps).transform(PROJECT_SHAPE, change.ops).ops;
    if (!change.ops.length) return;
    change.prevId = prevId;
    change.version = version++;
    prevId = change.id;
    return change;
  };

  return localChanges.map(rebaseChange).filter(Boolean);
}

export function idMap<T extends { id: string }>(changes: T[]) {
  const map = new Map<string, T>();
  changes.forEach(change => map.set(change.id, change));
  return map;
}

export function changesCoverSnapshots(changes: Change[], interval: number): boolean {
  const firstVersion = changes[0].version;
  const lastVersion = changes[changes.length - 1].version;
  const prevSnapshotVersion = Math.floor((firstVersion - 1) / interval) * interval;
  const nextSnapshotVersion = prevSnapshotVersion + interval;
  return !firstVersion || lastVersion >= nextSnapshotVersion;
}

export function hasChanges(source: any, updates: any) {
  return Object.keys(updates).some(key => {
    return source[key] !== updates[key];
  });
}

export function groupChangesIntoSnapshotChunks(changes: Change[], snapshotInterval: number): Change[][] {
  let snapshotChanges: Change[];
  let lastSnapshotVersion = -Infinity;
  const allSnapshotsChanges: Change[][] = [];

  changes.forEach(change => {
    if (change.version > lastSnapshotVersion + snapshotInterval) {
      if (snapshotChanges) allSnapshotsChanges.push(snapshotChanges);
      snapshotChanges = [];
      lastSnapshotVersion = Math.floor((change.version - 1) / snapshotInterval) * snapshotInterval;
    }
    snapshotChanges.push(change);
  });

  if (snapshotChanges && snapshotChanges.length) {
    allSnapshotsChanges.push(snapshotChanges);
  }

  return allSnapshotsChanges;
}
