<script lang="ts">
  import { globalData, settings, t } from '@dabble/app';
  import SubNavItem from '@dabble/components/workspace/SubNavItem.svelte';
  import { Doc } from '@dabble/data/types';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiExport, mdiFileWordBox, mdiGoogle, mdiTextBoxOutline, mdiWeb } from '@mdi/js';
  import { exportOptions, startExport } from '../exports';
  import { ExportOptions } from '../types';

  export let doc: Doc;

  const exportFileTypes: Record<string, { icon: string; isMain?: true }> = {
    word: { icon: mdiFileWordBox },
    google: { icon: mdiGoogle },
    html: { icon: mdiWeb },
    text: { icon: mdiTextBoxOutline },
  };
  const fileTypesKeys = Object.keys(exportFileTypes);

  $: docSettings = settings.getFor(doc?.type);
  $: fileTypes = (docSettings.exportFileTypes || []).sort(
    (a, b) => fileTypesKeys.indexOf(a) - fileTypesKeys.indexOf(b)
  );

  async function exportType(fileType: ExportOptions['fileType']) {
    $exportOptions = { doc, fileType };
    if (fileType === 'html' || fileType === 'text') {
      await startExport();
      $exportOptions = null;
    }
  }
</script>

{#if fileTypes.length > 0}
  <SubNavItem>
    <svelte:fragment slot="submenu-button">
      <Icon path={mdiExport} />
      {$t('export_more')}
    </svelte:fragment>

    {#each fileTypes as fileType}
      {#if !$globalData.clientSettings[`exclude_${fileType}`]}
        <button class="dropdown-item nav-item-menu add-child" on:click={() => exportType(fileType)}>
          <Icon path={exportFileTypes[fileType].icon} />
          {$t(`export_file_${fileType}`)}
        </button>
      {/if}
    {/each}
  </SubNavItem>
{/if}
