<script>
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiLock } from '@mdi/js';

  export let card;
</script>

<div class="credit-card">
  {#if card && card.ach_credit_transfer}
    <span class="bank-name">{card.ach_credit_transfer.bank_name}</span>
    •••• {card.ach_credit_transfer.account_number.slice(-4)}
    <Icon path={mdiLock} />
  {:else if card}
    <span class="card-icon" data-brand={card.brand} /> •••• {card.last4} <Icon path={mdiLock} />
  {/if}
</div>

<style>
  .credit-card {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-sm);
  }
  .credit-card :global(.icon) {
    font-weight: normal;
    color: var(--text-color-lightest);
    margin-left: 10px;
  }
  .bank-name {
    font-size: var(--font-size-xs);
    margin-right: 4px;
  }
  .card-icon {
    width: 36px;
    height: 24px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  /* American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown
amex, diners, discover, jcb, mastercard, unionpay, visa, or unknown */
  .card-icon[data-brand='American Express'],
  .card-icon[data-brand='amex'] {
    background-image: url(../images/amex.svg);
  }
  .card-icon[data-brand='Diners Club'],
  .card-icon[data-brand='diners'] {
    background-image: url(../images/diners.svg);
  }
  .card-icon[data-brand='Discover'],
  .card-icon[data-brand='discover'] {
    background-image: url(../images/discover.svg);
  }
  .card-icon[data-brand='JCB'],
  .card-icon[data-brand='jcb'] {
    background-image: url(../images/jcb.svg);
  }
  .card-icon[data-brand='MasterCard'],
  .card-icon[data-brand='mastercard'] {
    background-image: url(../images/mastercard.svg);
  }
  .card-icon[data-brand='UnionPay'],
  .card-icon[data-brand='unionpay'] {
    background-image: url(../images/unionpay.svg);
  }
  .card-icon[data-brand='Visa'],
  .card-icon[data-brand='visa'] {
    background-image: url(../images/visa.svg);
  }
  .card-icon[data-brand='Unknown'],
  .card-icon[data-brand='unknown'] {
    background-image: url(../images/unknown.svg);
  }
</style>
