import { Delta, TextDocument } from 'typewriter-editor';
import { Change } from '../collective/changes';
import { ChangeProjectEvent, Collective, CommittedChangesEvent, ReceiveChangesEvent } from '../collective/collective';
import { StatefulPromise } from '../collective/stateful-promise';
import ProjectPatch from '../project-patch';
import { Signal, signal } from '../signals';
import { TextQueue, createTextQueue } from '../text-queue';
import { Doc, Project, ProjectSnapshot } from '../types';
import { createChangeId, createDocId, createProjectId } from '../uuid';
import { Children, emptyChildren, getChildrenLookup } from './children';
import { Counts, emptyCounts, getCounts } from './counts';
import { DocTexts, emptyTexts, getTexts } from './doc-texts';
import { Docs, emptyDocs, getDocs } from './docs';
import { InTrash, emptyInTrash, getInTrash } from './in-trash';
import { Link, LinkSet, emptyLinkSet, getLinks } from './links';
import { Parents, emptyParents, getParentsLookup } from './parents';
import { SettingsStore } from './settings';
import { Readable, Writable, writable } from './store';

export const PENDING = 'pending';
export const SAVING = 'saving';
export const SAVED = 'saved';
export const ERROR = 'error';
export const EMPTY_TEXT_DOCUMENT = new TextDocument();
export type SaveStatus = 'pending' | 'saving' | 'saved' | 'error';

export interface ProjectData {
  projectId: string;
  project: Project;
  docs: Docs;
  childrenLookup: Children;
  parentsLookup: Parents;
  inTrash: InTrash;
  links: LinkSet;
  texts: DocTexts;
  counts: Counts;
  textQueued: boolean;
  change: Change;
  currentVersion: number;
  previous: Project;
}

export interface Comment {
  uid: string;
  content: string;
  resolved?: boolean;
  created: number;
  replies?: Comment[];
}

export interface ChangeSignalEvent {
  change: Change;
  snapshot: ProjectSnapshot;
  remoteChange: boolean;
  fromEditor?: boolean;
}

export const emptyLinks: Link[] = [];
export const emptyProjectData: ProjectData = {
  projectId: null,
  project: null,
  docs: emptyDocs,
  parentsLookup: emptyParents,
  childrenLookup: emptyChildren,
  inTrash: emptyInTrash,
  links: emptyLinkSet,
  texts: emptyTexts,
  counts: emptyCounts,
  textQueued: false,
  change: null,
  currentVersion: null,
  previous: null,
};

export interface ProjectStore extends Readable<ProjectData> {
  onPatch: Signal<ProjectPatch>;
  onChange: Signal<ChangeSignalEvent>;
  onReceiveChanges: Signal<ReceiveChangesEvent>;
  forceTextUpdate: Signal<string | void>;
  saveText: Signal<string | void>;
  onError: Signal<Error>;
  status: Writable<SaveStatus>;
  load(projectId: string): Promise<void>;
  unload(): void;

  /**
   * Reload the currently loaded project.
   */
  reload(): Promise<void>;

  /**
   * Go to the project at the given version. Pass nothing to go to the latest version. While not at the latest version,
   * no changes to the project will be accepted.
   */
  goto(version: number, snapshots: Project[], changes: Change[]): void;

  /**
   * Create a new project patch which will create a project.
   */
  createProject: (newProject: Partial<Project>) => Promise<Project>;

  /**
   * Updates the loaded project, returning a promise when the changes are saved. This will update the project and
   * user project because the metadata duplicates a portion of project data for caching.
   */
  updateProject(updates: any): StatefulPromise<Project>;

  /**
   * Creates a new doc within the loaded project, within the parent folder, at the given index.
   */
  createDoc(newDoc: Partial<Doc>, parentId: string, index?: number, structure?: boolean): StatefulPromise<Project>;

  /**
   * Updates a doc with the given updates. The doc must belong to the loaded project.
   */
  updateDoc(docId: string, updates: any): StatefulPromise<Project>;

  /**
   * Move a doc from one location to another in the loaded project.
   */
  moveDoc(docId: string, newParentId: string, index?: number | string): StatefulPromise<Project>;

  /**
   * Put a doc into the trash. This is the same as a move with parentId of 'trash'.
   */
  trashDoc(docId: string): StatefulPromise<Project>;

  /**
   * Restore a doc from the trash to its previous location.
   */
  restoreDoc(docId: string): StatefulPromise<Project>;

  /**
   * Delete a doc from the loaded project. This is not removal from trash, but complete deletion. Delete doc texts as
   * well.
   */
  deleteDoc(docId: string): StatefulPromise<Project>;

  /**
   * Completely delete all docs in the trash of the loaded project.
   */
  emptyTrash(): StatefulPromise<Project>;

  /**
   * Links two docs together (or updates their existing link) with the given metadata.
   */
  linkDocs(from: string, rel: string, to: string, metadata?: any): StatefulPromise<Project>;

  /**
   * Removes the link between two docs.
   */
  unlinkDocs(from: string, rel: string, to: string): StatefulPromise<Project>;

  /**
   * Change the text in a doc with the given delta.
   */
  changeText(docId: string, field: string, delta: Delta): StatefulPromise<Project>;

  /**
   * Returns the closest parent of the given type.
   */
  closest(doc: string | Doc, type: string): Doc;

  /**
   * Returns whether the parent is or contains the provided doc.
   */
  contains(parent: string | Doc, doc: string | Doc): boolean;

  /**
   * Returns all links coming in to the given doc, optionally filtered by rel.
   */
  getDoc(docId: string): Doc;

  /**
   * Returns all docs (or ids) of a certain type within a doc.
   */
  getDocsOfType(within: Doc, type: string, getDocs: true): Doc[];
  getDocsOfType(within: Doc, type: string, getDocs?: false): string[];

  /**
   * Flattens all the children inside a doc, optionally stopping at given types
   */
  flatten(within: Doc, untilType?: string[]): Doc[];

  /**
   * Returns all links coming in to the given doc, optionally filtered by rel.
   */
  getParent(docId: string): Doc;

  /**
   * Returns all links coming in to the given doc, optionally filtered by rel.
   */
  getChildren(docId: string): Doc[];

  /**
   * Returns the indexed path from the root of the project to the given doc.
   */
  getPath(docId: string): number[];

  /**
   * A sort algorithm for sorting docs or docIds by their order in the heirarchy
   */
  pathSort(docA: string | Doc, docB: string | Doc): number;

  /**
   * Returns all links going out from the given doc, optionally filtered by rel.
   */
  linksFrom(docId: string, rel?: string, includeTrashed?: boolean): Link[];

  /**
   * Returns all links coming in to the given doc, optionally filtered by rel.
   */
  linksTo(docId: string, rel?: string, includeTrashed?: boolean): Link[];

  /**
   * Returns all links for given doc, to and from, optionally filtered by rel.
   */
  linksFor(docId: string, rel?: string, includeTrashed?: boolean): Link[];

  /**
   * Get the text for the given doc and field.
   */
  textFor(docId: string, field: string): string;

  patch: () => ProjectPatch;
  createProjectId: () => string;
  createDocId: () => string;
  createChangeId: () => string;

  queueTextChange(docId: string, field: string, delta: Delta, dontExtendDelay?: boolean): void;
  transformAgainstQueuedTextChanges(docId: string, field: string, delta: Delta): Delta;
  commitQueuedTextChanges(docId?: string, field?: string): Promise<void>;
  cancelQueuedTextChanges(docId?: string, field?: string): void;
  getQueueKey(docId?: string, field?: string, projectId?: string): string;
  getTextDocumentByKey(key?: string): TextDocument;
  textQueue: TextQueue;

  readonly changesJustReceived: boolean;

  // These methods are only to be used outside an active project
  updateData(project: Project): Promise<void>;
  set(data: ProjectData): Promise<void>;
}

export function createProjectStore(settingsStore: SettingsStore, collective: Collective<Project>): ProjectStore {
  const textQueue = createTextQueue({
    onSave: onCommitQueuedTextChange,
    onCancel: onCancelQueuedTextChange,
  });
  let data: ProjectData = emptyProjectData;
  let ignoreUpdates = false;
  let changesJustReceived = false;
  const onPatch = signal<ProjectPatch>();
  const onChange = signal<ChangeSignalEvent>();
  const onReceiveChanges = signal<ReceiveChangesEvent>();
  const forceTextUpdate = signal<string | void>();
  const saveText = signal<string | void>();
  const onError = signal<Error>();
  const status = writable<SaveStatus>(SAVED);
  const pathCache = new WeakMap<Doc, number[]>();

  const { get, set, subscribe } = writable<ProjectData>(data);

  async function load(projectId: string) {
    collective.on('changeProject', onChangeProject);
    collective.on('receiveChanges', onReceiveChangesListener);
    collective.on('committedChanges', onCommittedChangesListener);
    updateData(await collective.getProject(projectId));
    if (!data.projectId) {
      // If no data is loaded (no changes/snapshots exist locally), the project id needs setting
      set({ ...data, projectId });
    }
  }

  function unload() {
    if (data.project) commitQueuedTextChanges();
    collective.off('changeProject', onChangeProject);
    collective.off('receiveChanges', onReceiveChangesListener);
    collective.off('committedChanges', onCommittedChangesListener);
    updateData(null);
  }

  async function reload() {
    if (!data.projectId) return;
    updateData(await collective.getProject(data.projectId));
    forceTextUpdate();
  }

  function scheduleTextUpdate() {
    Promise.resolve().then(forceTextUpdate);
  }

  function goto(version: number, snapshots: Project[], changes: Change[]) {
    if (!snapshots || !changes) return;
    const snapshotVersion =
      Math.max(Math.floor(changes[0].version / 200), Math.floor((version - 1) / collective.snapshotInterval)) *
      collective.snapshotInterval;
    const snapshot = snapshots.find(s => s.version === snapshotVersion);
    const change = changes.find(c => c.version === version);

    const indexStart = changes.findIndex(c => c.version === snapshotVersion + 1);
    const indexEnd = changes.findIndex(c => c.version === version);
    if (snapshot && change && indexStart !== -1 && indexEnd !== -1) {
      changes = changes.slice(indexStart, indexEnd + 1);
      const previous = collective.hydrateProject(data.projectId, snapshot, changes.slice(0, -1));
      const project = collective.hydrateProject(data.projectId, previous, changes.slice(-1));
      updateData(project, change, version, previous);
      scheduleTextUpdate();
    } else {
      updateData(null, null, version);
    }
  }

  function patch() {
    return new ProjectPatch(data.project, settingsStore, savePatch);
  }

  function savePatch(patch: ProjectPatch, fromEditor?: boolean): StatefulPromise<Project> {
    if (patch.isEmpty() || data.currentVersion !== null) return null;

    // Allow any changes to be made
    onPatch(patch);

    if (patch.isEmpty()) return null;

    // Update data immediately for local changes
    let updatedProject: Project;
    status.set(SAVING);
    const changeId = createChangeId();

    try {
      updatedProject = patch.patch.apply(data.project);
      updatedProject.version++;
      updatedProject.changeId = changeId;
    } catch (err) {
      onError(err);
      scheduleTextUpdate();
      return;
    }

    updateData(updatedProject);

    const promise = collective.changeProject(patch.project.id, patch.patch, changeId, fromEditor);

    promise.then(
      () => status.set(SAVED),
      async err => {
        onError(err);
        status.set(ERROR);
        // Revert to last committed version
        try {
          const project = await collective.getProject(data.project.id);
          updateData(project);
          scheduleTextUpdate();
          status.set(SAVED);
        } catch (err) {
          scheduleTextUpdate();
        }
      }
    );

    return promise;
  }

  function newDocId() {
    return createDocId(data.docs);
  }

  // Receives a local change from this machine
  function onChangeProject({ change, snapshot, remote, fromEditor }: ChangeProjectEvent<Project>) {
    if (!data.project || change.projectId !== data.project.id || data.currentVersion !== null) return;
    if (remote) {
      // Called when a change occurred from another tab
      updateData(snapshot);
    }
    onChange({ change, snapshot, remoteChange: remote || false, fromEditor });
  }

  // Receives remote changes from the cloud
  async function onReceiveChangesListener(event: ReceiveChangesEvent) {
    if (
      !data.project ||
      !event.changes.length ||
      event.changes[0].projectId !== data.project.id ||
      data.currentVersion !== null
    )
      return;
    changesJustReceived = true;
    onReceiveChanges(event);
    updateData(await collective.getProject(data.project.id));
    scheduleTextUpdate();
    changesJustReceived = false;
  }

  // Marks saved changes as committed
  async function onCommittedChangesListener(event: CommittedChangesEvent) {
    if (
      !data.project ||
      !event.changes.length ||
      event.changes[0].projectId !== data.project.id ||
      data.currentVersion !== null
    )
      return;
    const change = event.changes.find(change => change.version === data.project.version);
    if (change && change.committed !== data.project.committed) {
      updateData({ ...data.project, committed: change.committed });
    }
  }

  function updateData(
    project: Project,
    change: Change = null,
    currentVersion: number = null,
    previous: Project = null
  ) {
    if (data.project === project || ignoreUpdates) return;
    const projectId = project && project.id;
    if (data.projectId && data.projectId !== projectId) {
      ignoreUpdates = true;
      commitQueuedTextChanges();
      ignoreUpdates = false;
    }
    const settings = settingsStore.get();

    data = { ...data, projectId, project, change, currentVersion, previous };

    data.docs = getDocs(project, settings);
    data.childrenLookup = getChildrenLookup(data.docs, settings);
    data.parentsLookup = getParentsLookup(data.docs, data.childrenLookup);
    data.inTrash = getInTrash(data.childrenLookup);
    data.links = getLinks(project, data.inTrash);
    data.texts = getTexts(data.docs, textQueue, data.texts);
    data.counts = getCounts(data.texts, data.childrenLookup, data.counts);

    return set(data);
  }

  // Project API
  function createProject(newProject: Partial<Project>) {
    const patch = ProjectPatch.createProject(newProject, settingsStore);
    const changeId = createChangeId();
    return collective.changeProject(patch.project.id, patch.patch, changeId);
  }

  function updateProject(updates: any) {
    return patch().updateProject(updates).save();
  }

  function createDoc(newDoc: Partial<Doc>, parentId: string, index?: number, structure = true) {
    return patch().createDoc(newDoc, parentId, index, structure).save();
  }

  function updateDoc(docId: string, updates: any) {
    return patch().updateDoc(docId, updates).save();
  }

  function moveDoc(docId: string, newParentId: string, index?: number) {
    return patch().moveDoc(docId, newParentId, index).save();
  }

  function trashDoc(docId: string) {
    return patch().trashDoc(docId).save();
  }

  function restoreDoc(docId: string) {
    return patch().restoreDoc(docId).save();
  }

  function deleteDoc(docId: string) {
    return patch().deleteDoc(docId).save();
  }

  function emptyTrash() {
    return patch().emptyTrash().save();
  }

  function linkDocs(from: string, rel: string, to: string, metadata = {}) {
    return patch().linkDocs(from, rel, to, metadata).save();
  }

  function unlinkDocs(from: string, rel: string, to: string) {
    return patch().unlinkDocs(from, rel, to).save();
  }

  function changeText(docId: string, field: string, delta: Delta, fromEditor?: boolean) {
    return patch().changeText(docId, field, delta).save(fromEditor);
  }

  function closest(docId: Doc | string, type: string) {
    let doc = typeof docId === 'string' ? data.docs[docId] : docId;
    while (doc) {
      if (doc.type === type) return doc;
      doc = data.parentsLookup[doc.id];
    }
  }

  function contains(parent: Doc | string, doc: Doc | string) {
    if (!parent || !doc) return false;
    const parentId = typeof parent === 'string' ? parent : parent.id;
    let docId = typeof doc === 'string' ? doc : doc.id;
    docId = data.parentsLookup[docId] && data.parentsLookup[docId].id;
    while (docId && docId !== parentId) {
      const parent = data.parentsLookup[docId];
      docId = parent && parent.id;
    }
    return docId === parentId;
  }

  function getDoc(docId: string) {
    return data.docs[docId];
  }

  function getParent(docId: string) {
    return data.parentsLookup[docId];
  }

  function getChildren(docId: string) {
    return data.childrenLookup[docId];
  }

  function getPath(docId: string): number[] {
    let doc = getDoc(docId);
    const path: number[] = pathCache.get(doc) || [];
    if (path.length) return path; // Cached, skip the lookup (improves sort speed)
    let parent: Doc;

    while ((parent = getParent(doc.id))) {
      const children = getChildren(parent.id);
      const index = children.indexOf(doc);
      path.push(index);
      doc = parent;
    }

    if (doc.type === 'trash') {
      path[path.length] = getChildren(data.project.id).length;
    }

    return path.reverse();
  }

  function pathSort(docA: string | Doc, docB: string | Doc): number {
    const pathA = getPath(typeof docA === 'string' ? docA : docA.id);
    const pathB = getPath(typeof docB === 'string' ? docB : docB.id);
    for (let i = 0, length = Math.min(pathA.length, pathB.length); i < length; i++) {
      if (pathA[i] !== pathB[i]) {
        return pathA[i] - pathB[i];
      }
    }
    return 0;
  }

  function linksFrom(docId: string, rel = '', includeTrashed = false) {
    const from = includeTrashed ? data.links.all.from : data.links.from;
    return from[`${docId}:${rel}`] || emptyLinks;
  }

  function linksTo(docId: string, rel = '', includeTrashed = false) {
    const to = includeTrashed ? data.links.all.to : data.links.to;
    return to[`${docId}:${rel}`] || emptyLinks;
  }

  function linksFor(docId: string, rel = '', includeTrashed = false) {
    return linksFrom(docId, rel, includeTrashed).concat(linksTo(docId, rel, includeTrashed));
  }

  function textFor(docId: string, field: string) {
    return data.texts.textWithQueued[docId] && data.texts.textWithQueued[docId][field];
  }

  async function onCommitQueuedTextChange(delta: Delta, key: string) {
    const [projectId, docId, field] = key.split(':');
    if (data.projectId !== projectId || !data.docs[docId]) return;
    const contents = (data.docs[docId][field] as TextDocument) || EMPTY_TEXT_DOCUMENT;
    let isChanged: boolean;
    try {
      isChanged = contents.apply(delta) !== contents;
    } catch (err) {
      forceTextUpdate(key);
    }
    if (isChanged) {
      // A change actually happened (e.g. it wasn't a delete then undo operation)
      await changeText(docId, field, delta, true);
    }
    updateQueuedData();
  }

  function onCancelQueuedTextChange(key: string) {
    const [projectId] = key.split(':');
    if (data.projectId !== projectId) return;
    updateQueuedData();
  }

  function getQueueKey(docId?: string, field?: string, projectId: string = data.projectId) {
    if (!projectId) throw new Error('Must have a project loaded to alter the queue');
    let key = projectId + ':';
    if (docId) {
      key += docId + ':';
      if (field) {
        key += field;
      }
    }
    return key;
  }

  function getTextDocumentByKey(key: string) {
    const [projectId, docId, field] = key.split(':');
    if (data.projectId !== projectId) return;
    return (data.docs[docId]?.[field] as TextDocument) || EMPTY_TEXT_DOCUMENT;
  }

  function updateQueuedData() {
    const queued = data.textQueued;
    const texts = getTexts(data.docs, textQueue, data.texts);

    if (texts !== data.texts) {
      data = { ...data, texts, textQueued: textQueue.hasQueued() };
      data.counts = getCounts(data.texts, data.childrenLookup, data.counts);
      set(data);
    } else if (queued !== textQueue.hasQueued()) {
      set((data = { ...data, textQueued: textQueue.hasQueued() }));
    }

    if (!queued && data.textQueued) {
      status.set(PENDING);
    } else if (queued && !data.textQueued) {
      status.set(SAVED);
    }
  }

  function queueTextChange(docId: string, field: string, delta: Delta, dontExtendDelay?: boolean): void {
    if (data.projectId === docId) return; // Don't queue changes to the project itself
    if (data.currentVersion !== null) return;
    textQueue.add(getQueueKey(docId, field), delta, dontExtendDelay);
    updateQueuedData();
  }

  function transformAgainstQueuedTextChanges(docId: string, field: string, delta: Delta): Delta {
    delta = textQueue.transform(getQueueKey(docId, field), delta);
    updateQueuedData();
    return delta;
  }

  function commitQueuedTextChanges(docId?: string, field?: string): Promise<void> {
    return textQueue.finish(getQueueKey(docId, field)); // update will run inside onCommitQueuedTextChange
  }

  function cancelQueuedTextChanges(docId?: string, field?: string): void {
    textQueue.cancel(getQueueKey(docId, field));
  }

  function getDocsOfType(within: Doc, type: string, getDocs: true): Doc[];
  function getDocsOfType(within: Doc, type: string, getDocs?: false): string[];
  function getDocsOfType(within: Doc, type: string, getDocs = false): string[] | Doc[] {
    if (within.type === type) return getDocs ? [within] : [within.id];
    if (!within.children) return [];
    return within.children.reduce(
      (all, childId) =>
        data.docs[childId] ? all.concat(getDocsOfType(data.docs[childId], type, getDocs as any)) : all,
      []
    );
  }

  function flatten(within: Doc, untilType?: string[]): Doc[] {
    const all: Doc[] = [];
    if (!within.children) return all;
    within.children.forEach(childId => {
      const doc = data.docs[childId];
      doc && all.push(doc);
      if (!untilType || !untilType.includes(doc.type)) {
        all.push(...flatten(doc, untilType));
      }
    });
    return all;
  }

  return {
    onPatch,
    onChange,
    onReceiveChanges,
    forceTextUpdate,
    saveText,
    onError,
    status,
    load,
    unload,
    reload,
    goto,
    createProject,
    updateProject,
    createDoc,
    updateDoc,
    moveDoc,
    trashDoc,
    restoreDoc,
    deleteDoc,
    emptyTrash,
    linkDocs,
    unlinkDocs,
    changeText,
    patch,
    createProjectId,
    createDocId: newDocId,
    createChangeId,
    closest,
    contains,
    getDoc,
    getDocsOfType,
    flatten,
    getParent,
    getChildren,
    getPath,
    pathSort,
    linksFrom,
    linksTo,
    linksFor,
    textFor,
    queueTextChange,
    transformAgainstQueuedTextChanges,
    commitQueuedTextChanges,
    cancelQueuedTextChanges,
    getQueueKey,
    getTextDocumentByKey,
    textQueue,
    get changesJustReceived() {
      return changesJustReceived;
    },
    updateData(project: Project) {
      if (collective) throw new Error('Cannot use updateData with an active project');
      return updateData(project);
    },
    set(projectData: ProjectData) {
      if (collective) throw new Error('Cannot use set with an active project');
      return set((data = projectData));
    },
    get,
    subscribe,
  };
}

export async function cleanupProject(projectStore: ProjectStore) {
  const project = projectStore.get().project;
  if (!project) return;
  const patch = projectStore.patch();

  // Clean up old links
  Object.keys(project.links).forEach(key => {
    const [from, rel, to] = key.split(':');
    if ((!project.docs[from] && from !== project.id) || (!project.docs[to] && to !== project.id)) {
      patch.unlinkDocs(from, rel, to);
    }
  });

  // Clean up duplicate children
  const hasParent: { [id: string]: string } = {};

  checkChildren(project.id);
  Object.keys(project.docs).forEach(parentId => parentId !== 'trash' && checkChildren(parentId));
  checkChildren('trash');

  function checkChildren(parentId: string) {
    const parent = project.docs[parentId] || (parentId === project.id && project);
    if (!parent || !parent.children) return;

    for (let i = parent.children.length - 1; i >= 0; i--) {
      const childId = parent.children[i];
      if (hasParent[childId]) {
        // Already has a parent, remove the duplicate
        if (parent === project) {
          patch.patch.remove(`/children/${i}`);
        } else {
          patch.patch.remove(`/docs/${parentId}/children/${i}`);
        }
      }
      hasParent[childId] = parentId;
    }
  }

  await patch.save();
}
