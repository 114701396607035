import { Doc, GetChildrenFunction } from '../types';
import { Docs, emptyDocs } from './docs';
import { AllSettings } from './settings';

export interface Children {
  [id: string]: Doc[];
}

export const emptyChildren: Children = {};

export function getChildrenLookup(docs: Docs, settings: AllSettings) {
  if (docs === emptyDocs) return emptyChildren;
  const childrenLookup: Children = {};

  function addChildren(parent: Doc) {
    const parentSettings = settings[parent.type];
    if (!parentSettings) return; // allows projects created on newer versions of Dabble to load on old versions

    const getChildren: GetChildrenFunction = settings[parent.type].getChildren;
    if (parent.children || getChildren) {
      const children = getChildren ? getChildren(parent, docs.project) : parent.children;
      childrenLookup[parent.id] = children.map(id => docs[id]).filter(Boolean);
    }
  }

  Object.values(docs).forEach(addChildren);
  return childrenLookup;
}
