import { delegate, deviceId, observe, uid } from '@dabble/app';
import rest from '@dabble/data/rest';
import { Readable, writable } from '@dabble/data/stores/store';
import { User } from '@dabble/data/types';
import { Browserbase, ChangeDetail, ObjectStore } from 'browserbase';

const tokenExpBuffer = 60000;

export interface UserUpdates {
  name?: string;
  email?: string;
  photoUrl?: string;
  password?: string;
}

interface Tokens {
  idToken: string;
  refreshToken: string;
}

interface Auth {
  user: User;
  tokens: Tokens;
  lastUsed: number;
}

interface TokenPayload {
  // Custom data
  user_id: string;
  name: string;
  email: string;
  email_verified: boolean;

  iss: string; // Issuer
  aud: string; // Audience
  auth_time: string; // Time when auth occurred
  sub: string; // Subject (uid)
  iat: number; // Issued At
  exp: number; // Expiration
}

interface OtherData {
  id: string;
  [key: string]: any;
}

interface AuthDatabaseStores {
  auths: ObjectStore<Auth>;
  other: ObjectStore<OtherData>;
}

interface AccountsStore extends Readable<User[]> {
  getIdToken(): Promise<string>;
}

export type OnAuth = (user: User) => void;
export type Unsubscribe = () => void;

let db: Browserbase<AuthDatabaseStores>;
let auth: Auth;
const accountsStore = writable<User[]>([]);
export const masterUser = writable<User>(undefined);
export const currentUser = writable<User>(undefined);
observe(currentUser, user => uid.set(user && user.uid));
export { uid };

export const accounts: AccountsStore = {
  async getIdToken() {
    try {
      return (await getIdTokens()).idToken;
    } catch (err) {
      return null;
    }
  },
  get: accountsStore.get,
  subscribe: accountsStore.subscribe,
};

masterUser.subscribe(async user => {
  if (user && (user.admin || user.claims?.admin) && delegate) {
    currentUser.set(await rest.GET(`/auth/user?delegate=${delegate}`));
  } else {
    currentUser.set(user);
  }
});

export async function load() {
  await openDatabase();

  // Migrate from localStorage to IndexedDB
  if (localStorage.accounts) {
    await migrateAccounts();
  }
}

export async function signIn(email: string, password: string) {
  auth = (await rest.POST('/auth/signIn?tokens=true', { email, password, delegate })) as Auth;
  await Promise.all([storeAuth(), storeCurrent()]);
  return auth && auth.user;
}

export async function signInWithCustomToken(token: string) {
  auth = (await rest.POST('/auth/signIn?tokens=true', { provider: 'custom', token })) as Auth;
  await Promise.all([storeAuth(), storeCurrent()]);
  return auth && auth.user;
}

export async function signOut(dontSwitch?: boolean) {
  if (delegate) {
    currentUser.set(null);
    return;
  }
  await rest.DELETE('/auth/signIn');
  const uid = currentUser.get().uid;
  const accounts = accountsStore.get();
  let user: User;
  for (let i = 0, l = Math.min(1, accounts.length); i < l; i++) {
    if (accounts[i].uid !== uid) {
      user = accounts[i];
      break;
    }
  }
  if (user && !dontSwitch) {
    await switchTo(user);
    await deleteAuth(auth);
  } else {
    auth = null;
    await Promise.all([storeAuth(), storeCurrent()]);
  }
}

export async function switchTo(user: User) {
  const userAuth = await db.stores.auths.get(user.uid);
  auth = userAuth || null;
  await Promise.all([storeAuth(true), storeCurrent()]);
}

export async function getIdTokens() {
  if (!auth) throw new Error('Please sign in.');
  if (auth.tokens && auth.tokens.idToken) {
    const payload = JSON.parse(atob(auth.tokens.idToken.split('.')[1])) as TokenPayload;
    if (payload.exp * 1000 > Date.now() + tokenExpBuffer) {
      return auth.tokens;
    }
  }
  return await refreshToken();
}

export async function getUser() {
  const user = (await rest.GET('/auth/user')) as User;
  auth.user = user;
  storeAuth();
}

export function getLocalUser() {
  return auth.user;
}

export async function signUp(name: string, email: string, password: string) {
  auth = (await rest.POST('/auth/signUp?tokens=true', { name, email, password, metadata: { trial: true } })) as Auth;
  await Promise.all([storeAuth(), storeCurrent()]);
  return auth.user;
}

export async function updateUser(updates: UserUpdates) {
  let tokens = await rest.PUT('/auth/user', updates);
  if (!tokens.idToken) tokens = auth.tokens;
  delete updates.password;
  auth = { ...auth, user: { ...auth.user, ...updates }, tokens };
  storeAuth();
  return auth.user;
}

export async function sendVerification() {
  await rest.POST('/auth/sendVerification');
}

export async function requestPasswordReset(email: string) {
  await rest.POST('/auth/passwordReset', { email });
}

async function refreshToken() {
  if (!auth) throw new Error('Please sign in.');
  const results = await rest.POST('/auth/refreshToken', { refreshToken: auth.tokens.refreshToken });
  auth.tokens = results;
  await storeAuth();
  return auth.tokens;
}

async function storeAuth(skipSet?: boolean) {
  if (delegate) return;
  const user = masterUser.get();
  const oldUid = user && user.uid;
  const uid = auth && auth.user.uid;

  if (auth) {
    auth.lastUsed = Date.now();
    await db.stores.auths.put(auth, uid);
  } else {
    await db.stores.auths.delete(oldUid);
  }

  if (!skipSet) {
    masterUser.set(auth && auth.user);
    updateAccountsWith(uid || oldUid, auth && auth.user);
  }
  return auth;
}

async function deleteAuth(auth: Auth) {
  await db.stores.auths.delete(auth.user.uid);
}

async function storeCurrent() {
  if (delegate) return;
  const uid = (auth && auth.user.uid) || null;
  await db.stores.other.put({ id: 'current', uid });
}

function updateAccountsWith(uid: string, user?: User) {
  accountsStore.update(accounts => {
    accounts = accounts.filter(a => a.uid !== uid);
    if (user) accounts.unshift(user);
    return accounts;
  });
}

async function openDatabase() {
  db = new Browserbase<AuthDatabaseStores>('dabble-auth');

  db.version(1, {
    auths: ' ',
    current: ' ',
  })
    .version(
      2,
      {
        other: 'id',
      },
      async (oldVersion, transaction) => {
        let trans = db.start(transaction);
        const data = await ((trans.stores as any).current as ObjectStore).getAll();

        trans = db.start(transaction);
        const other = data.map((record: any) => ({ id: 'current', uid: record.uid } as OtherData));

        trans.stores.other.putAll(other);
        trans.upgradeStore('current', null);
      }
    )
    .version(0, {
      auths: ' ',
      other: 'id',
    });

  await db.open();
  db.addEventListener('change', onDBChange);

  // eslint-disable-next-line prefer-const
  let [auths, current, device] = await Promise.all([
    db.stores.auths.getAll(),
    db.stores.other.get('current'),
    db.stores.other.get('device'),
  ]);

  if (!device) {
    device = { id: 'device', uuid: deviceId.get() };
    await db.stores.other.put(device);
  }

  if (localStorage.deviceId !== device.uuid) localStorage.deviceId = device.uuid;
  deviceId.set(device.uuid);

  populateAuths(auths);

  let uid: string;
  if (current && current.uid && auths.find(auth => auth.user.uid === current.uid)) {
    uid = current.uid;
  } else if (accounts.get().length) {
    uid = accounts.get()[0].uid;
  }

  populateUser(auths, uid);

  return db;
}

async function migrateAccounts() {
  // Migrate from localStorage to IndexedDB
  if (!localStorage.accounts) return;
  const oldAccounts = JSON.parse(localStorage.accounts);
  const auths = (
    await Promise.all(
      oldAccounts.map(async (account: any, i: number) => {
        if (!account || !account.stsTokenManager) return;
        const tokens: Tokens = {
          idToken: account.stsTokenManager.accessToken,
          refreshToken: account.stsTokenManager.refreshToken,
        };
        const user: User = convertUserData(account);
        const auth = { tokens, user, lastUsed: 1000 - i };
        await db.stores.auths.put(auth, user.uid);
        return auth;
      })
    )
  ).filter(Boolean) as Auth[];

  localStorage.removeItem('accounts');
  Browserbase.deleteDatabase('firebaseLocalStorageDb');
  populateAuths(auths);

  if (auths.length) {
    const { uid } = auths[0].user;
    populateUser(auths, uid);
    await db.stores.other.put({ id: 'current', uid });
  }
}

function populateAuths(auths: Auth[]) {
  const accounts = auths.sort(byLastUsed).map(auth => auth.user);
  accountsStore.set(accounts);
}

function populateUser(auths: Auth[], uid: string) {
  auth = auths.find(auth => auth.user.uid === uid);
  masterUser.set((auth && auth.tokens && auth.tokens.idToken && auth.user) || null);
}

async function onDBChange({ detail: { store, obj, key, declaredFrom } }: CustomEvent<ChangeDetail>) {
  if (declaredFrom !== 'remote') return;
  if (store.name === 'auths') {
    const auth = obj as Auth;
    const user = masterUser.get();
    if (!auth) {
      if (user && user.uid === key) {
        masterUser.set(undefined);
      }
      updateAccountsWith(key);
    } else {
      if (user && user.uid === key) {
        masterUser.set(auth.user);
      }
      updateAccountsWith(key, auth.user);
    }
  } else if (store.name === 'current') {
    if (!obj) {
      auth = null;
    } else {
      auth = await db.stores.auths.get(obj.uid);
    }
    masterUser.set(auth && auth.user);
  }
}

function byLastUsed(a: Auth, b: Auth) {
  return b.lastUsed - a.lastUsed;
}

function convertUserData(user: any): User {
  return {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    photoUrl: user.photoUrl,
    validSince: parseInt(user.validSince || 0, 10),
    disabled: user.disabled || false,
    lastLoginAt: parseInt(user.lastLoginAt, 10),
    createdAt: parseInt(user.createdAt, 10),
  };
}
