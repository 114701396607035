// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/OpenDyslexic/OpenDyslexic-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/OpenDyslexic/OpenDyslexic-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/OpenDyslexic/OpenDyslexic-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/OpenDyslexic/OpenDyslexic-BoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* latin */\n@font-face {\n  font-family: 'OpenDyslexic';\n  font-style: normal;\n  font-weight: normal;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n}\n/* latin */\n@font-face {\n  font-family: 'OpenDyslexic';\n  font-style: italic;\n  font-weight: normal;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n}\n/* latin */\n@font-face {\n  font-family: 'OpenDyslexic';\n  font-style: normal;\n  font-weight: bold;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2');\n}\n/* latin */\n@font-face {\n  font-family: 'OpenDyslexic';\n  font-style: italic;\n  font-weight: bold;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2');\n}\n", "",{"version":3,"sources":["webpack://./src/styles/fonts/OpenDyslexic.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,mBAAmB;EACnB,4DAAwE;AAC1E;AACA,UAAU;AACV;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,mBAAmB;EACnB,4DAAuE;AACzE;AACA,UAAU;AACV;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,iBAAiB;EACjB,4DAAqE;AACvE;AACA,UAAU;AACV;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,iBAAiB;EACjB,4DAA2E;AAC7E","sourcesContent":["/* latin */\n@font-face {\n  font-family: 'OpenDyslexic';\n  font-style: normal;\n  font-weight: normal;\n  src: url(~fonts/OpenDyslexic/OpenDyslexic-Regular.woff2) format('woff2');\n}\n/* latin */\n@font-face {\n  font-family: 'OpenDyslexic';\n  font-style: italic;\n  font-weight: normal;\n  src: url(~fonts/OpenDyslexic/OpenDyslexic-Italic.woff2) format('woff2');\n}\n/* latin */\n@font-face {\n  font-family: 'OpenDyslexic';\n  font-style: normal;\n  font-weight: bold;\n  src: url(~fonts/OpenDyslexic/OpenDyslexic-Bold.woff2) format('woff2');\n}\n/* latin */\n@font-face {\n  font-family: 'OpenDyslexic';\n  font-style: italic;\n  font-weight: bold;\n  src: url(~fonts/OpenDyslexic/OpenDyslexic-BoldItalic.woff2) format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
