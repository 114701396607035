<script>
  import { router } from '@dabble/app';

  export let path;
  export let component;
  let params;

  $: params = $router.matches(path);
</script>

{#if params}
  {#if component}
    <svelte:component this={component} {...params} />
  {:else}
    <slot {params} />
  {/if}
{/if}
