<script>
  import { printReady, projectStore, settings, viewport } from '@dabble/app';
  import VirtualList from '@dabble/components/workspace/VirtualList.svelte';
  import { VirtualViewportView } from '@dabble/data/stores/viewport';
  import { onMount } from 'svelte';

  export let doc;
  const defaultSearchableFields = ['title', 'body'];
  let list;

  $: items = getVirtualizedChildren(doc, $projectStore);

  function getVirtualizedChildren(doc) {
    const children = [doc];
    projectStore.getChildren(doc.id).forEach(child => {
      if (isVirtualized(child)) {
        children.push(...getVirtualizedChildren(child));
      } else {
        children.push(child);
      }
    });
    return children;
  }

  function getView(doc) {
    const { view } = settings.getFor(doc);
    return view && (view.ownComponent || view.component);
  }

  function isVirtualized(doc) {
    const { view } = settings.getFor(doc);
    return Boolean(view && view.ownComponent);
  }

  function getFields(doc) {
    const { view } = settings.getFor(doc);
    if (!view) return [];
    const searchable =
      typeof view.searchableFields === 'function'
        ? view.searchableFields(doc.type)
        : view.searchableFields || defaultSearchableFields;
    if (Array.isArray(searchable)) {
      return searchable.map(field => ({ id: doc.id, field }));
    } else {
      const fields = [];
      let docs = [doc];
      if (!view.ownComponent) {
        docs = docs.concat(projectStore.flatten(doc));
      }

      docs.forEach(doc => {
        if (searchable[doc.type] && searchable[doc.type].length) {
          searchable[doc.type].forEach(field => fields.push({ id: doc.id, field }));
        }
      });
      return fields;
    }
  }

  function getViewportView() {
    return new VirtualViewportView(
      list,
      child => isVirtualized(child) || isVirtualized(projectStore.getParent(child.id)),
      () => {
        const fields = [];
        getVirtualizedChildren(doc).forEach(doc => {
          fields.push(...getFields(doc));
        });
        return fields;
      }
    );
  }

  onMount(() => {
    viewport.view.set(getViewportView());
  });
</script>

<VirtualList bind:this={list} bind:items let:item={doc} renderAll={$printReady}>
  <svelte:component this={getView(doc)} {doc} last={items[items.length - 1] === doc} />
</VirtualList>
