<script lang="ts">
  import { editingMode, features, projectStore } from '@dabble/app';
  import { Doc } from '@dabble/data/types';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import CastItem from './CastItem.svelte';

  export let doc: Doc;
  export const backgroundColor = 'var(--folder-background)';

  $: readonly = !$features.has('story_notes') || $editingMode === 1;
  $: children = $projectStore.childrenLookup[doc.id] || [];
</script>

<div data-id={doc.id} class="cast-view">
  <EditableContent {doc} class="cast-title centered" field="title" header="h1" {readonly} />
  <div class="children">
    {#each children as child}
      <CastItem doc={child} />
    {/each}
  </div>
</div>

<style>
  .cast-view {
    position: relative;
    background: var(--folder-background);
  }
  .cast-view :global(.cast-title h1) {
    margin: 0;
    padding: 3rem 0.5rem;
    color: var(--text-color-light);
    text-shadow: var(--text-highlight);
    text-align: center;
  }
  .children {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    gap: 1rem;
  }
</style>
