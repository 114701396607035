import { Delta, projectStore } from '@dabble/app';
import { writable } from '@dabble/data/stores/store';
import { Doc } from '@dabble/data/types';
import { mdiFileMergeOutline } from '@dabble/toolkit/custom-icons';
import { TextDocument } from 'typewriter-editor';

export const mergeDocId = writable('');
const TOP = /^\n*/;
const BOTTOM = /\n*$/;

export const joinSceneMenuItems = [
  {
    key: 'novel_merge_scene',
    icon: mdiFileMergeOutline,
    action: joinSceneDialog,
    disabled: (doc: Doc) => projectStore.getParent(doc.id).children.length <= 1,
  },
];

function joinSceneDialog(doc: Doc) {
  mergeDocId.set(doc.id);
}

export function getSiblings(docId: string) {
  const parent = projectStore.getParent(docId);
  return parent.children;
}

export async function mergeScenes(topId: string, bottomId: string) {
  const patch = projectStore.patch();

  const topScene = projectStore.getDoc(topId);
  const bottomScene = projectStore.getDoc(bottomId);

  const bodyOps = getDelta(topScene.body, bottomScene.body);
  const descriptionOps = getDelta(topScene.description, bottomScene.description);

  if (bodyOps.ops.length) {
    patch.changeText(topId, 'body', bodyOps);
  }
  if (bottomScene.body) {
    patch.changeText(bottomId, 'body', new Delta().delete(bottomScene.body.length));
  }
  if (descriptionOps.ops.length) {
    patch.changeText(topId, 'description', descriptionOps);
  }
  if (bottomScene.description) {
    patch.changeText(bottomId, 'description', new Delta().delete(bottomScene.description.length));
  }
  if ('title' in bottomScene) {
    projectStore.updateDoc(bottomId, { title: null });
  }
  patch.trashDoc(bottomId);

  if (bottomScene.comments && Object.keys(bottomScene.comments).length) {
    if (!topScene.comments) {
      patch.patch.add(`/docs/${topScene.id}/comments`, {});
    }
    Object.values(bottomScene.comments).forEach((comment: any) => {
      comment = { ...comment, docId: topScene.id };
      patch.patch.add(`/docs/${topScene.id}/comments/${comment.id}`, comment);
    });
  }

  await patch.save();

  projectStore.forceTextUpdate();
}

function getDelta(top: TextDocument, bottom: TextDocument) {
  let delta = new Delta();

  if (top) {
    const topTrim = getNewlineLength(top, BOTTOM);
    delta.retain(top.length - 1 - topTrim).delete(topTrim);
  }

  if (bottom) {
    const bottomTrim = getNewlineLength(bottom, TOP);
    const bottomDelta = bottom
      .toDelta()
      .slice(0, bottom.length - 1)
      .compose(new Delta().delete(bottomTrim));
    delta = delta.insert('\n\n').concat(bottomDelta);
  }

  return delta;
}

function getNewlineLength(textDoc: TextDocument, direction: RegExp) {
  const text = textDoc.getText();
  return text.length - text.replace(direction, '').length;
}
