<script lang="ts">
  import { currentDoc, editingMode, focus, mobileShowNav, router, settings, size } from '@dabble/app';
  import { DocSettings } from '@dabble/data/types';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCheckBold, mdiMenu } from '@mdi/js';
  import EditingBar from './EditingBar.svelte';
  import UpdateAvailable from './UpdateAvailable.svelte';

  let docSettings: DocSettings;

  $: docSettings = $currentDoc && $settings[$currentDoc.type];
</script>

<div class="app-header">
  {#if !$focus.focused && !$focus.autofocused}
    <div class="app-header-left focus-fade">
      {#if $editingMode === 2}
        <button class="icon editing-complete" on:click={() => ($editingMode = 1)}>
          <Icon path={mdiCheckBold} />
        </button>
      {:else}
        {#if $size !== 'desktop' && $router.url.startsWith('/p/')}
          <button class="icon menu" on:click={() => ($mobileShowNav = $mobileShowNav ? null : 'left')}>
            <Icon path={mdiMenu} />
          </button>
        {/if}
        {#each settings.getValuesFromPlugins('headerLeft') as component}
          <svelte:component this={component} />
        {/each}
      {/if}
    </div>
  {/if}
  <div class="window-dragger" />
  {#if !$focus.focused && !$focus.autofocused}
    <div class="app-header-right focus-fade">
      {#if $editingMode === 2}
        <EditingBar />
      {:else}
        {#each settings.getValuesFromPlugins('headerRight') as component}
          <svelte:component this={component} />
        {/each}
        <UpdateAvailable />
        {#if $size !== 'desktop' && $router.url.startsWith('/p/') && !(docSettings && docSettings.hideRightNav)}
          <button class="icon menu" on:click={() => ($mobileShowNav = $mobileShowNav ? null : 'right')}>
            <Icon path={mdiMenu} />
          </button>
        {/if}
      {/if}
    </div>
  {/if}
</div>

<style>
  .app-header {
    display: flex;
    align-items: center;
    height: var(--app-header-height);
    flex: 0 0 var(--app-header-height);
    padding: 0 8px;
    box-sizing: border-box;
    background-color: var(--app-header-background);
    box-shadow: var(--app-header-shadow);
    z-index: 4;
    -webkit-app-region: drag;
    contain: strict;
  }
  .editing-complete {
    color: var(--teal);
    margin-left: 4px;
  }
  .app-header :global(.btn) {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    color: var(--text-color-lighter);
    font-size: 18px;
    margin: 0 4px;
    padding: 5px 10px;
  }
  .app-header :global(.btn:hover),
  .app-header :global(.btn:active),
  .app-header :global(.btn.active),
  .app-header :global(.btn[disabled]),
  .app-header :global(.btn[disabled]:hover) {
    box-shadow: none;
    border: none;
  }
  .app-header :global(.btn:not([disabled]):hover),
  .app-header :global(.btn:active),
  .app-header :global(.btn.active) {
    color: var(--text-color-normal);
    background: var(--app-header-button-hover);
  }
  .app-header :global(.btn.icon),
  .app-header :global(.btn) {
    font-size: var(--font-size-sm);
  }
  .app-header :global(.btn.icon),
  .app-header :global(.icon) {
    font-size: 24px;
  }
  :global(.is-touch) .app-header :global(.btn.icon),
  :global(.is-touch) .app-header :global(.icon) {
    font-size: 32px;
  }
  .app-header-left {
    flex: 0 0;
    height: var(--app-header-height);
    display: flex;
    align-items: center;
    -webkit-app-region: no-drag;
  }
  .app-header-left .menu {
    margin-left: 8px;
  }
  :global(body.isApple.inDesktop) .app-header-left {
    margin-left: 64px;
  }
  .app-header-right .menu {
    margin-right: 8px;
  }
  .app-header-right {
    flex: 0 0;
    height: var(--app-header-height);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    -webkit-app-region: no-drag;
  }
  .window-dragger {
    flex: 1 1 auto;
    height: 100%;
    -webkit-app-region: drag;
  }
</style>
