<script>
  import { alert, connectionSendAfterAuthed, plugins, router, t } from '@dabble/app';
  import Modal from '@dabble/toolkit/Modal.svelte';

  const { currentUser } = plugins.stores;

  if (sessionStorage.invite) {
    loadInvite();
  }

  let invite;
  let waitingForLogin;

  // If waitingForLogin is true and currentUser changes, set it to false to open the modal again
  $: if ($currentUser) onCurrentUser();

  function onCurrentUser() {
    if (waitingForLogin) {
      waitingForLogin = false;
    }
  }

  async function loadInvite() {
    try {
      invite = await connectionSendAfterAuthed('getInvite', sessionStorage.invite);
    } catch (err) {
      clearInvite();
      const errorKey = `collab_error_${err.message}`;
      const errorMsg = $t(errorKey) === errorKey ? err.message : $t(errorKey);
      await alert($t('error'), errorMsg);
      if (!$currentUser) {
        location.href = 'https://www.dabblewriter.com/';
      }
      return;
    }
  }

  function loginScreen(screen) {
    router.navigate(`/auth/${screen}`);
    waitingForLogin = true;
  }

  async function execute(action) {
    if (action === 'decline' || $currentUser) {
      try {
        await connectionSendAfterAuthed(`${action}Invite`, invite.id);
      } catch (err) {
        clearInvite();
        await alert($t('error'), $t(err.message));
        return;
      }
      clearInvite();
      await alert($t('success'), $t(`collab_success_${action}`));
    } else {
      waitingForLogin = true;
    }
  }

  function clearInvite() {
    invite = null;
    delete sessionStorage.invite;
  }
</script>

{#if invite && !waitingForLogin}
  <Modal title="Want to co-author?" on:close={() => (invite = null)}>
    <section>
      <p>{@html $t('collab_invited_you', { inviter: invite.inviter.name, title: invite.project.title })}</p>
      {#if $currentUser}
        <p>{@html $t('collab_invite_accept', { email: $currentUser.email })}</p>
      {:else}
        <p>{@html $t('collab_signin_first_message')}</p>
      {/if}
      <footer>
        <button class="btn secondary" on:click={() => execute('decline')}>{$t('collab_decline')}</button>
        {#if $currentUser}
          <button class="btn secondary" on:click={() => loginScreen('signin')}>{$t('account_login_another')}</button>
          <button class="btn primary" on:click={() => execute('accept')}>{$t('collab_accept')}</button>
        {:else}
          <button class="btn primary" on:click={() => loginScreen('signup')}>{$t('account_signup')}</button>
          <button class="btn primary" on:click={() => loginScreen('signin')}>{$t('account_login')}</button>
        {/if}
      </footer>
    </section>
  </Modal>
{/if}
