<script>
  import { dateOptions, t, today } from '@dabble/app';
  import { format } from 'date-fns';
  import { createEventDispatcher } from 'svelte';
  import { subscription } from '../billing';
  import Discount from './Discount.svelte';
  import UpcomingInvoice from './UpcomingInvoice.svelte';

  export let disabled;

  const dispatch = createEventDispatcher();
  const DAY = 1000 * 60 * 60 * 24;

  function calcDays(end, today) {
    return Math.floor((end - today) / DAY);
  }
</script>

<h2>{$t('billing_subscription_start_after_trial')}</h2>
<div class="layout">
  <div class="span-8 span-4-mobile subscription-info">
    <Discount />
    {#if $subscription}
      <UpcomingInvoice />
      <p>{@html $t('billing_trial_left', { count: calcDays($subscription.trial_end * 1000, $today) })}</p>
      <p>
        {@html $t('billing_subscription_bill_due', {
          date: format(new Date($subscription.trial_end * 1000), 'PPPP', dateOptions),
        })}
      </p>
    {/if}
  </div>
  <div class="span-4 span-4-mobile actions">
    <button class="btn primary" on:click={() => dispatch('subscribe')} {disabled}>{$t('billing_subscribe_now')}</button>
    <button class="btn secondary" on:click={() => dispatch('cancel')} {disabled}>{$t('billing_cancel_trial')}</button>
  </div>
</div>

<style>
  h2 {
    margin-bottom: 0.5rem;
  }
</style>
