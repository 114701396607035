<script>
  import { alert, projectStore, readonly, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipBottom } from '@dabble/toolkit/tooltip';
  import { mdiBookOpenBlankVariant, mdiChevronUp, mdiFileOutline } from '@mdi/js';

  export let doc;

  const allowedDropTypes = new Set(['novel_part', 'novel_chapter']);

  const parentTypes = {
    novel_scene: 'novel_chapter',
    novel_chapter: 'novel_part',
  };

  function canInsert(type, after) {
    if (type === 'novel_scene' && after.type === 'novel_part') {
      return false;
    }
    return true;
  }

  function insertDoc(type, after) {
    const id = projectStore.createDocId();
    let parent, index;
    const doc = { id, type };

    if (type === after.type) {
      parent = projectStore.getParent(after.id);
      index = projectStore.getChildren(parent.id).indexOf(after) + 1;
    } else if (after.type === parentTypes[type]) {
      parent = after;
      index = 0;
    } else if (type === 'novel_chapter' && after.type === 'novel_scene') {
      const afterParent = projectStore.getParent(after.id);
      parent = projectStore.getParent(afterParent.id);
      index = projectStore.getChildren(parent.id).indexOf(afterParent) + 1;
    } else {
      alert(t('cannot_insert_document', { type }));
      return;
    }

    const patch = projectStore.patch();
    patch.createDoc(doc, parent.id, index, false);

    if (type === 'novel_chapter') {
      if (after.type === 'novel_chapter') {
        // Must do it backwards because the remove op will access incorrect indexes if we start at 0
        after.children
          .slice()
          .reverse()
          .forEach(docId => patch.moveDoc(docId, id, 0));
      } else if (after.type === 'novel_scene') {
        const afterSibs = projectStore.getChildren(projectStore.getParent(after.id).id);
        const startIndex = afterSibs.indexOf(after) + 1;
        afterSibs
          .slice(startIndex)
          .reverse()
          .forEach(sib => patch.moveDoc(sib.id, id, 0));
      }
    }

    patch.save();
  }
</script>

{#if !$readonly}
  <div class="manuscript-insert">
    <div class="manuscript-insert-display">
      <Icon path={mdiChevronUp} class="up" highlight />
      <div class="manuscript-insert-actions">
        <button
          class="icon"
          disabled={!canInsert('novel_chapter', doc)}
          on:click={() => insertDoc('novel_chapter', doc)}
          use:tooltipBottom={$t('insert_document', { type: 'novel_chapter' })}
        >
          <Icon path={mdiBookOpenBlankVariant} /></button
        >
        <button
          class="icon"
          disabled={!canInsert('novel_scene', doc)}
          on:click={() => insertDoc('novel_scene', doc)}
          use:tooltipBottom={$t('insert_document', { type: 'novel_scene' })}
        >
          <Icon path={mdiFileOutline} /></button
        >
      </div>
    </div>
  </div>
{/if}

<style>
  .manuscript-insert :global(.icon.up) {
    color: var(--text-color-lighterer);
    font-size: 20px;
    text-shadow: var(--text-highlight);
    margin-top: -22px;
  }
  .manuscript-insert {
    position: relative;
    height: calc(100%);
  }
  :global(body.drag-drop) .manuscript-insert {
    display: none;
  }
  :global(.novel-partition + .manuscript-insert) {
    margin-left: -10px;
  }
  :global(.novel_scene) .manuscript-insert {
    height: calc(100% + 32px);
    margin-bottom: -10px;
  }

  .manuscript-insert-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: calc(100% - 15px);
    width: 60px;
    margin-left: -20px;
  }
  .manuscript-insert-actions {
    display: flex;
    border-radius: 3px;
    padding: 4px;
    box-shadow: var(--actions-shadow);
    background: var(--background-lightened);
  }
</style>
