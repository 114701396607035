<script>
  import { t } from '@dabble/app';
  import noteLinking from '@dabble/toolkit/editor-modules/note-linking';
  import spaces from '@dabble/toolkit/editor-modules/spaces';
  import { ctrlEnterKey } from '@dabble/toolkit/events';
  import { createEventDispatcher, tick } from 'svelte';
  import { Editor, placeholder as placeholderModule, smartQuotes } from 'typewriter-editor';
  import { updateCommentDisplay } from '../comments-store';

  export let shyButtons;
  export let style;
  export let hasInput;
  export let autofocus;
  export let content;

  const dispatch = createEventDispatcher();
  const domain = 'https://app.dabblewriter.com';
  const placeholders = {
    comment: 'comments_comment_placeholder',
    reply: 'comments_reply_placeholder',
    edit_reply: 'comments_edit_reply_placeholder',
    edit_comment: 'comments_edit_comment_placeholder',
  };
  const buttons = {
    comment: 'comments_comment',
    reply: 'comments_reply',
    edit_reply: 'comments_edit_reply',
    edit_comment: 'comments_edit_comment',
  };
  let inputElem;
  let editor;
  let focused;

  $: onInputElem(inputElem);
  $: showButtons = !shyButtons || focused || hasInput;
  $: onShowButtons(showButtons);

  export function focus() {
    inputElem.focus();
  }

  async function onShowButtons() {
    await tick();
    updateCommentDisplay();
  }

  function onInputElem(elem) {
    if (editor) editor.destroy();
    if (elem) {
      const types = {
        lines: ['paragraph'],
        formats: [],
        embeds: [],
      };
      const modules = {
        placeholder: placeholderModule($t(placeholders[style])),
        smartQuotes,
        spaces,
        noteLinking,
      };
      editor = new Editor({ root: elem, types, modules });
      editor.on('change', ({ change }) => {
        if (change.contentChanged) {
          hasInput = editor.getText().trim().length > 0;
          updateCommentDisplay();
        }
      });
      if (content) {
        hasInput = true;
        editor.setText(content);
      }
      if (autofocus) {
        editor.select([0, editor.doc.length]);
        setTimeout(() => elem.focus());
      }
    }
  }

  function save() {
    if (!hasInput) return;
    content = editor
      .getText('')
      .trim()
      .replace(/\n{3,}/g, '\n\n')
      .replace(/(https?:\/\/)?((app|beta|test)\.dabblewriter.com|localhost:8080)/g, domain);
    editor.setText('');
    hasInput = false;
    if (content) {
      dispatch('save', { content });
    }
  }

  function cancel() {
    const hadInput = hasInput;
    const thread = inputElem && inputElem.closest('.thread.selected');
    if (thread) thread.focus();
    editor.setText('');
    focused = false;
    hasInput = false;
    dispatch('cancel', { hadInput });
  }
</script>

<div
  bind:this={inputElem}
  class="form-control input typewriter-editor"
  type="input"
  contenteditable="true"
  use:ctrlEnterKey={save}
  on:focus={() => (focused = true)}
/>
{#if showButtons}
  <div class="content-buttons">
    <button class="btn primary small" disabled={!hasInput} on:click={save}>{$t(buttons[style])}</button>
    <button class="btn secondary small" on:click={cancel}>{$t('cancel')}</button>
  </div>
{/if}

<style>
  .content-buttons {
    margin-top: 8px;
  }
  .input :global(p) {
    margin: 0;
  }
</style>
