export type AcceptableDrop = (event: DragEvent) => boolean;
export type DropEventHandler = (event: DragEvent) => void;
export type DropHandler = (event: DragEvent, mime: string, data: any) => void;
export type DropEffect = 'move' | 'copy' | 'link';
export type GetDropEffect = (event: DragEvent) => DropEffect;

export interface DropOptions {
  mimes?: string[];
  dropEffect?: DropEffect | GetDropEffect;
  acceptable?: AcceptableDrop;
  enter?: DropEventHandler;
  leave?: DropEventHandler;
  over?: DropEventHandler;
  drop?: DropHandler;
  docEnter?: DropEventHandler;
  docLeave?: DropEventHandler;
}

export function droppable(node: HTMLElement, options: DropOptions) {
  let dropAvailable = false;
  let dropping = false;
  let ignoreNextDragLeave = false;
  let ignoreNextDocDragLeave = false;
  let dropDragTarget: Document;

  function isAcceptable(event: DragEvent) {
    if (!options) return '';
    if (!options.acceptable || options.acceptable(event)) {
      return (options.mimes && options.mimes.find(mime => event.dataTransfer.types.includes(mime))) || '';
    } else {
      return '';
    }
  }

  function getDropEffect(event: DragEvent) {
    return typeof options.dropEffect === 'function' ? options.dropEffect(event) : options.dropEffect || 'move';
  }

  function onDragEnter(event: DragEvent) {
    if (!isAcceptable(event)) return;
    event.preventDefault();
    event.stopPropagation();

    // If we were already dropping, we have just been told about dragging moving to another element contained
    // within ourselves, and we will be told very soon about leaving the element we were in. This event order
    // seems backwards, but what can ya do HTML5?
    if (dropping) {
      ignoreNextDragLeave = true;
    } else {
      node.classList.add('dropping');
      dropping = true;
      event.dataTransfer.dropEffect = getDropEffect(event);
      options.enter && options.enter(event);
    }
  }

  function onDragLeave(event: DragEvent) {
    if (!isAcceptable(event)) return;

    event.preventDefault();
    event.stopPropagation();

    if (ignoreNextDragLeave) {
      ignoreNextDragLeave = false;
    } else {
      node.classList.remove('dropping');
      dropping = false;
      options.leave && options.leave(event);
    }
  }

  function onDragOver(event: DragEvent) {
    if (!isAcceptable(event)) return;

    // Tell the browser that we will handle the drag by preventing the default and setting our dropEffect.
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = getDropEffect(event);
    options.over && options.over(event);
  }

  function onDrop(event: DragEvent) {
    const mime = isAcceptable(event);
    if (!mime) return;

    event.preventDefault();
    event.stopPropagation();
    onDragEnd();

    // Parse the JSON data automatically for the drop handler.
    let data = event.dataTransfer.getData(mime);
    if (mime.includes('json')) {
      try {
        data = JSON.parse(data);
      } catch (e) {
        console.error('Error parsing JSON drop data:', data, e);
      }
    }

    options.drop && options.drop(event, mime, data);
  }

  function onDocDragEnter(event: DragEvent) {
    if (!options || !options.docEnter || !isAcceptable(event)) return;

    // See comment above about HTML5 drag-drop
    if (dropAvailable) {
      ignoreNextDocDragLeave = true;
    } else {
      node.classList.add('drop-available');
      dropAvailable = true;
      // Listen for the dropend event, document drop doesn't cut it with iframes invovled
      dropDragTarget = (event.target as HTMLElement).ownerDocument;
      dropDragTarget.addEventListener('dragend', onDragEnd);
      options.docEnter && options.docEnter(event);
    }
  }

  function onDocDragLeave(event: DragEvent) {
    if (!options || !options.docLeave || !isAcceptable(event)) return;

    // See comment above about HTML5 drag-drop
    if (ignoreNextDocDragLeave) {
      ignoreNextDocDragLeave = false;
    } else {
      onDragEnd();
      options.docLeave && options.docLeave(event);
    }
  }

  function onDragEnd() {
    if (dropDragTarget) dropDragTarget.removeEventListener('dragend', onDragEnd);
    node.classList.remove('drop-available');
    node.classList.remove('dropping');
    dropAvailable = false;
    dropping = false;
    dropDragTarget = null;
  }

  node.addEventListener('dragenter', onDragEnter);
  node.addEventListener('dragleave', onDragLeave);
  node.addEventListener('dragover', onDragOver);
  node.addEventListener('drop', onDrop);
  node.ownerDocument.addEventListener('dragenter', onDocDragEnter, true);
  node.ownerDocument.addEventListener('dragleave', onDocDragLeave, true);
  node.ownerDocument.addEventListener('dragend', onDragEnd, true);

  return {
    update(value: DropOptions) {
      options = value;
    },
    destroy() {
      node.removeEventListener('dragenter', onDragEnter);
      node.removeEventListener('dragleave', onDragLeave);
      node.removeEventListener('dragover', onDragOver);
      node.removeEventListener('drop', onDrop);
      node.ownerDocument.removeEventListener('dragenter', onDocDragEnter, true);
      node.ownerDocument.removeEventListener('dragleave', onDocDragLeave, true);
      node.ownerDocument.removeEventListener('dragend', onDragEnd, true);
    },
  };
}
