<script>
  import popperFrames from '@dabble/toolkit/popper-frames';
  import preventOverflow from '@dabble/toolkit/popper-preventOverflow';
  import { createPopper } from '@popperjs/core';
  import { onDestroy, onMount } from 'svelte';

  const vertical = new Set(['top', 'bottom']);
  export let target = null;
  export let placement = 'top';
  export let html;
  export let offset = 0;
  $: offsetOptions = {
    name: 'offset',
    options: {
      offset: [vertical.has(placement) ? 0 : offset, vertical.has(placement) ? offset : 0],
    },
  };
  let container;
  let popper;
  const preventOverflowOptions = {
    name: 'preventOverflow',
    options: { padding: 4 },
  };

  $: updatePlacement(html);
  $: updateTooltip(target);

  function highlight(html) {
    return html.replace(/<highlight>/g, '<span class="highlight">').replace(/<\/highlight>/g, '</span>');
  }

  async function updatePlacement() {
    if (popper) popper.update();
  }

  function updateTooltip() {
    if (popper) popper.destroy();
    if (!container || !target) return;
    popper = createPopper(target, container, {
      placement,
      modifiers: [popperFrames, preventOverflow, preventOverflowOptions, offsetOptions],
    });
  }

  onMount(() => {
    updateTooltip();
    container.classList.add('in');
    setTimeout(updateTooltip, 100);
  });

  onDestroy(() => {
    try {
      if (popper) {
        popper.destroy();
        popper = null;
      }
    } catch (err) {
      /* may have already been destroyed */
    }
  });
</script>

<div bind:this={container} class="tooltip" role="tooltip">
  <div class="tooltip-arrow" data-popper-arrow />
  <div class="tooltip-inner">{@html highlight(html)}</div>
</div>
