<script>
  import { features, size, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiAccountGroup } from '@mdi/js';
  import { showCoauthors } from '../collab-stores';

  function toggle() {
    if (!$features.has('comments')) {
      features.promptUpgrade('comments');
    } else {
      $showCoauthors = !$showCoauthors;
    }
  }
</script>

{#if $size === 'desktop'}
  <button
    class="dropdown-item"
    class:checked={$features.has('comments') && $showCoauthors}
    on:click|stopPropagation={toggle}
  >
    <Icon path={mdiAccountGroup} />
    {$t('collab_authors')}
  </button>
{/if}
