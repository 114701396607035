<script>
  import { isDelegate } from '@dabble/version';
  import { imageApproved } from '../classifyImages';

  export let doc;
  $: blur = isDelegate && doc.image && !imageApproved(doc.image.image);
</script>

{#if doc.image}
  <div class="title-image">
    <div class="chapter-image-container">
      <img class="chapter-image" class:blur src={doc.image.image} alt="" />
    </div>
  </div>
{/if}

<style>
  .blur {
    filter: blur(50px);
  }
  .chapter-image-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .chapter-image {
    width: auto;
    max-width: 50%;
  }
</style>
