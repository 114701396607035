import * as app from './app';
import App from './components/App.svelte';
import './data/data-migrations';
import './data/date';
import './data/device';
import './data/intl';
import './error-reporting';
import './errors';
import './locales';
import './offline';
import './plugins';
import * as content from './plugins/content/content';
import './startup';
import './styles';
import './util/extensions';
import log from './util/log';

const ui = new App({ target: document.body });

declare global {
  interface Window {
    app: any;
    data: any;
    ui: App;
    version: string;
  }
}

setTimeout(() => {
  log.tagColor('Load', '#444', 'Dabble Web v' + window.version);
}, 500);

if (app.delegate || !app.isProduction) {
  setupDebugging();
} else {
  app.plugins.stores.currentUser.subscribe(user => {
    if ((user?.admin || user?.claims?.admin) && !window.app) {
      setupDebugging();
    }
  });
}

function setupDebugging() {
  window.app = { ...app, ...content };
  window.ui = ui;
  const data = (window.data = {} as any);

  Object.keys(window.app).forEach(key => {
    const store = window.app[key];
    if (key.endsWith('Store')) {
      delete window.app[key];
      key = key.replace(/Store$/, '');
      window.app[key] = store;
    }
    if (store && store.subscribe) {
      store.subscribe((value: any) => (data[key] = value));
    }
  });
}
