// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/Kalam/YA9dr0Wd4kDdMthfOCfhsCkAj7g.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/Kalam/YA9dr0Wd4kDdMthROCfhsCkA.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* latin-ext */\n@font-face {\n  font-family: 'Kalam';\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;\n}\n/* latin */\n@font-face {\n  font-family: 'Kalam';\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,\n    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/fonts/Kalam.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAwE;EACxE,mHAAmH;AACrH;AACA,UAAU;AACV;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAqE;EACrE;0DACwD;AAC1D","sourcesContent":["/* latin-ext */\n@font-face {\n  font-family: 'Kalam';\n  font-style: normal;\n  font-weight: 400;\n  src: url(~fonts/Kalam/YA9dr0Wd4kDdMthfOCfhsCkAj7g.woff2) format('woff2');\n  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;\n}\n/* latin */\n@font-face {\n  font-family: 'Kalam';\n  font-style: normal;\n  font-weight: 400;\n  src: url(~fonts/Kalam/YA9dr0Wd4kDdMthROCfhsCkA.woff2) format('woff2');\n  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,\n    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
