<script lang="ts">
  import {
    currentDoc,
    findReplace,
    focus,
    getTitle,
    leftNavWidth,
    mobileShowNav,
    projectStore,
    router,
    showLeftPane,
    showRightNav,
    showRightPane,
    sidebarWidth,
    size,
    workspaceWidth,
  } from '@dabble/app';
  import { isMobileMode } from '@dabble/data/device';
  import Frame from '@dabble/toolkit/Frame.svelte';
  import resize from '@dabble/toolkit/resize';
  import { fade } from '@dabble/toolkit/transitions';
  import { onDestroy, onMount } from 'svelte';
  import DocSection from './DocSection.svelte';
  import FindReplace from './FindReplace.svelte';
  import FocusLock from './FocusLock.svelte';
  import LeftNav from './LeftNav.svelte';
  import RightNav from './RightNav.svelte';
  import SideToolbar from './SideToolbar.svelte';

  export let loading = true;
  let workspace: HTMLElement;
  const unsubscribeRouterChange = router.beforeChange(() => ($mobileShowNav = null));
  const sidebarMax = 520; //notecard width + padding

  $: title = getTitle($currentDoc);

  function getLeftNavMax() {
    const rightNavWidth = sidebarWidth.get();
    const leftNavMax = window.innerWidth - rightNavWidth - 100 - 48; // 100 is max center-space width, 48 is toolbar width

    return leftNavMax;
  }

  window.dispatchEvent(new CustomEvent('currentscreen', { detail: { name: 'Workspace' } }));

  onMount(() => {
    $workspaceWidth = workspace.offsetWidth;
  });

  onDestroy(() => {
    unsubscribeRouterChange();
  });
</script>

<svelte:head>
  <title>Dabble - {title || ''}</title>
</svelte:head>

<svelte:window
  on:blur={() => $projectStore.project && projectStore.commitQueuedTextChanges()}
  on:resize={() => ($workspaceWidth = workspace.offsetWidth)}
/>

<div
  class="workspace {($projectStore.project && $projectStore.project.type) || 'default'}-project"
  bind:this={workspace}
>
  {#if $size !== 'desktop' && ($showLeftPane || $showRightPane)}
    <div class="nav-backdrop" transition:fade on:click={() => ($mobileShowNav = null)} />
  {/if}

  {#if $showLeftPane}
    <div class="left-nav focus-fade">
      <!-- {#if document.body.style.contain === undefined} -->
      <Frame component={LeftNav} {loading} />
      <!-- {:else}
    <LeftNav {loading}/>
    {/if} -->
      {#if !isMobileMode}
        <div class="gripper" use:resize={{ store: leftNavWidth, min: 180, getMax: getLeftNavMax }} />
      {/if}
    </div>
  {/if}

  <div class="center-space">
    <!-- {#if document.body.style.contain === undefined} -->
    <!-- Frame component={DocSection} {loading} /-->
    <!-- {:else} -->
    <div class="no-frame">
      <DocSection {loading} />
    </div>
    <!-- {/if} -->
  </div>

  {#if $showRightPane}
    <div class="right-pane focus-fade">
      <SideToolbar />
      {#if $showRightNav}
        <div class="right-nav">
          <!-- {#if document.body.style.contain === undefined} -->
          <Frame component={RightNav} />
          <!-- {loading}/ -->
          <!-- {:else}
    <RightNav {loading}/>
    {/if} -->
          {#if !isMobileMode}
            <div class="gripper" use:resize={{ store: sidebarWidth, reverse: true, min: 180, max: sidebarMax }} />
          {/if}
        </div>
      {/if}
    </div>
  {/if}
</div>

{#if $focus.focused}
  <div class="screen-controls" in:fade={{ duration: 500 }}>
    <FocusLock />
  </div>
{/if}

{#if $findReplace.open && $projectStore.project && $currentDoc}
  <FindReplace />
{/if}

<style>
  .workspace {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
    contain: strict;
  }
  .center-space {
    display: flex;
    flex: 1;
    min-width: 100px;
  }
  .no-frame {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .right-pane {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;
    height: 100%;
  }
  .left-nav,
  .right-nav {
    display: flex;
    flex: 0 0 auto;
    height: 100%;
    width: calc(1px + var(--side-nav-width));
    border-right: var(--side-nav-border);
    background: var(--side-nav-background);
    z-index: 1;
  }
  .left-nav {
    position: absolute;
  }
  .right-nav {
    border-right: none;
    border-left: var(--side-nav-border);
    box-shadow: var(--right-nav-shadow);
    max-width: var(--right-nav-max-width);
  }
  .gripper {
    position: absolute;
    top: 0;
    bottom: var(--status-bar-height);
    width: 6px;
    margin-left: -6px;
    cursor: ew-resize;
    z-index: 10;
  }
  .workspace :global(.gripper.gripped) {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .left-nav .gripper {
    right: -6px;
  }
  .right-nav .gripper {
    left: 5px;
  }
  .screen-controls {
    position: absolute;
    z-index: 3;
    display: flex;
    bottom: 0;
    right: 14px;
    padding: 4px 0 4px 4px;
    border-top-left-radius: 4px;
    contain: content;
  }
  .screen-controls :global(button) {
    margin-right: 4px;
  }
  :global(.is-touch) .screen-controls :global(.icon) {
    font-size: 25px;
  }
  :global(.is-touch) .screen-controls {
    padding: 6px 0 6px 4px;
  }
  @media (max-width: 840px) {
    .left-nav,
    .right-pane {
      position: fixed;
      top: 0;
      bottom: 0;
      width: auto;
      z-index: 5;
    }
    .left-nav > :global(*) {
      position: relative;
    }
    .nav-backdrop {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: var(--modal-background);
      z-index: 5;
    }
    .left-nav {
      left: 0;
      width: 80%;
      max-width: 400px;
    }
    .right-pane {
      right: 0;
      width: 80%;
      max-width: 300px;
      background-color: var(--side-nav-background);
    }
    .right-nav {
      flex: 1 1 auto;
    }
  }
</style>
