import { getImmutableValue, makeChanges } from '@dabble/util/immutable';
import isEqual from 'lodash/isEqual';
import { countWords } from '../word-count';
import { Children } from './children';
import { DocTexts, emptyTexts, TextFields } from './doc-texts';

(window as any).isEqual = isEqual;

const WORDS_PER_PAGE = 250;
const processedCounts = new WeakMap<TextFields, number>();
const totalCounts = new WeakMap<TextFields, number>();

export interface DocCounts {
  wordCount: number;
  queuedCount: number;
  totalCount: number;
  pageCount: number;
}

export interface Counts {
  [docId: string]: DocCounts;
}

export const emptyCounts: Counts = {};

export function getCounts(texts: DocTexts, childrenLookup: Children, counts: Counts) {
  if (texts === emptyTexts) return emptyCounts;

  const { textWithQueued, textWithoutQueued } = texts;

  makeChanges(() => {
    Object.keys(textWithQueued).forEach(getCount);
  });

  return counts;

  function getCount(docId: string): [number, number, number] {
    if (!docId) return;

    let wordCount = 0;
    let queuedCount = 0;
    let totalCount = 0;

    if (textWithQueued[docId] || textWithoutQueued[docId]) {
      wordCount = processedCounts.get(textWithQueued[docId]);
      if (wordCount === undefined) {
        wordCount = countWords(textWithQueued[docId]?.body);
        processedCounts.set(textWithQueued[docId], wordCount);
      }
      if (textWithQueued[docId] !== textWithoutQueued[docId]) {
        let withoutQueuedCount = processedCounts.get(textWithoutQueued[docId]);
        if (withoutQueuedCount === undefined) {
          withoutQueuedCount = countWords(textWithoutQueued[docId]?.body);
          processedCounts.set(textWithoutQueued[docId], withoutQueuedCount);
        }
        queuedCount = wordCount - withoutQueuedCount;
      }
      totalCount = totalCounts.get(textWithQueued[docId]);
      if (totalCount === undefined) {
        const descriptionCount = countWords(textWithQueued[docId]?.description);
        const titleCount = countWords(textWithQueued[docId]?.title);
        totalCount = wordCount + descriptionCount + titleCount;
        totalCounts.set(textWithQueued[docId], totalCount);
      }
    }

    if (childrenLookup && childrenLookup[docId]) {
      childrenLookup[docId].forEach(child => {
        if (!child.virtual) {
          const result = getCount(child.id);
          wordCount += result[0];
          queuedCount += result[1];
          totalCount += result[2];
        }
      });
    }

    const docCounts = { wordCount, queuedCount, totalCount, pageCount: Math.ceil(wordCount / WORDS_PER_PAGE) };
    if (!isEqual(docCounts, counts[docId])) {
      counts = getImmutableValue(counts);
      counts[docId] = docCounts;
    }

    return [wordCount, queuedCount, totalCount];
  }
}
