<script>
  import { features, t } from '@dabble/app';
  import ToolButton from '@dabble/toolkit/ToolButton.svelte';
  import { mdiPoll } from '@mdi/js';
  import { forNaNoWriMo } from '../nanowrimo';

  let tooltip;

  $: {
    if (!$features.has('goals')) {
      tooltip = $t('stats_title');
    } else if ($forNaNoWriMo) {
      tooltip = $t('goals_nanowrimo');
    } else {
      tooltip = $t('goals_and_stats_title');
    }
  }
</script>

<ToolButton sidebar="goals" icon={mdiPoll} {tooltip} />
