<script>
  import { t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { addListener } from '@dabble/toolkit/listen';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiClose } from '@mdi/js';
  import debounce from 'lodash/debounce';
  import { onMount } from 'svelte';
  import { Editor } from 'typewriter-editor';
  import asRoot from 'typewriter-editor/lib/asRoot';
  import { getBoundedPosition, SIZE, stickyNotesStore } from '../stickies-store';

  export let note;
  export let pageElement;

  const editor = new Editor({
    types: {
      lines: ['paragraph'],
      formats: [],
      embeds: [],
    },
    text: note.content,
  });
  const saveNote = debounce(
    () => {
      stickyNotesStore.updateStickyNote(note.id, { content: note.content });
    },
    2000,
    { maxWait: 5000 }
  );

  let node;
  let topOffset;
  let topPx;
  let horizontalPx;
  let lifted = false;
  let moveHandlers;
  let moveOffsets;

  $: right = note.horizontal > 0.5;
  $: root =
    pageElement &&
    pageElement.querySelector(`.editable-content[data-id="${docId}"][data-field="body"] .typewriter-editor`);
  $: updateOffset(pageElement, root);
  $: updatePosition(pageElement, note);
  $: projectContainer = pageElement && pageElement.closest('.project-container');
  $: docId = note.docId;

  onMount(() => {
    const editorHandler = addListener(editor, 'change', event => {
      if (event.change && event.change.contentChanged) {
        updateContent(editor.getText());
      }
    });

    const changeHandler = stickyNotesStore.changing(saveNote.flush);

    return () => {
      editorHandler();
      changeHandler();
    };
  });

  function updateOffset() {
    topOffset =
      (root && pageElement && root.getBoundingClientRect().top - pageElement.getBoundingClientRect().top) || 0;
  }

  function updatePosition() {
    topPx = Math.round(Math.max(0, Math.min(root.offsetHeight - SIZE, note.top))) + topOffset;
    horizontalPx = Math.round(root ? (root.offsetWidth - SIZE) * note.horizontal : 0);
  }

  function updateContent(content) {
    note = { ...note, content };
    saveNote();
  }

  function deleteNote() {
    saveNote.cancel();
    stickyNotesStore.deleteStickyNote(note.id);
  }

  function onTouchStart(event) {
    if (moveHandlers) return;

    const timeout = setTimeout(() => {
      lifted = true;
      releaseHandler();
      onMouseDown(event.touches[0]);
      moveHandlers.forEach(h => h());
      moveHandlers = [
        addListener(event.target.ownerDocument, 'touchmove', event => onMouseMove(event.touches[0])),
        addListener(event.target.ownerDocument.defaultView, 'touchend', onMouseUp),
      ];
    }, 500);

    const releaseHandler = addListener(event.target.ownerDocument.defaultView, 'touchend', () => {
      releaseHandler();
      clearTimeout(timeout);
    });
  }

  function onMouseDown(event) {
    const rect = getBounds(node, node.offsetParent);
    lifted = true;
    moveOffsets = {
      x: rect.left - event.clientX - projectContainer.scrollLeft,
      y: rect.top - event.clientY - topOffset - projectContainer.scrollTop,
    };
    moveHandlers = [
      addListener(event.target.ownerDocument, 'mousemove', onMouseMove),
      addListener(event.target.ownerDocument.defaultView, 'mouseup', onMouseUp),
    ];
  }

  function onMouseMove(event) {
    const pos = getBoundedPosition(
      event.clientY + moveOffsets.y + projectContainer.scrollTop,
      event.clientX + moveOffsets.x + projectContainer.scrollLeft,
      root.offsetHeight,
      root.offsetWidth
    );
    note = { ...note, ...pos };
  }

  function onMouseUp() {
    stickyNotesStore.updateStickyNote(note.id, { top: note.top, horizontal: note.horizontal });
    lifted = false;
    moveHandlers.forEach(h => h());
    moveHandlers = null;
    moveOffsets = null;
  }

  function getBounds(node, relativeTo) {
    const rect = node.getBoundingClientRect();
    const relative = relativeTo.getBoundingClientRect();
    return new DOMRect(rect.x - relative.x, rect.y - relative.y, rect.width, rect.height);
  }
</script>

<div
  bind:this={node}
  class="sticky-note {note.color} ink-blue"
  data-id={note.id}
  class:right
  class:lifted
  style="top:{topPx}px;left:{horizontalPx}px;"
>
  <div class="shadow" />
  <div class="background" />
  <div class="content-scroll"><div class="content" use:asRoot={editor} /></div>
  <div class="header" on:mousedown|preventDefault={onMouseDown} on:touchstart|preventDefault={onTouchStart}>
    <button class="icon closer" on:mousedown|stopPropagation on:click={deleteNote} use:tooltipTop={$t('delete')}>
      <Icon path={mdiClose} />
    </button>
  </div>
</div>

<svelte:window
  on:resize={() => {
    updateOffset();
    updatePosition();
  }}
/>

<style>
  .sticky-note {
    position: absolute;
    width: 240px;
    height: 240px;
    border-radius: 2px;
    transform: rotate(-1.2deg);
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .sticky-note.right {
    transform: rotate(1.2deg);
  }
  .sticky-note .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .sticky-note .shadow {
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 1px;
    height: 10px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.3);
    transform: skew(4deg) rotate(4deg);
    transform-origin: 100% 100%;
  }
  .sticky-note.right .shadow {
    transform: skew(-4deg) rotate(-4deg);
    transform-origin: 0% 100%;
  }
  .sticky-note.lifted .shadow {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  }
  .sticky-note.lifted {
    transform: rotate(0deg);
  }
  .sticky-note.lifted {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 5px 20px rgba(0, 0, 0, 0.2);
  }
  .sticky-note .header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 48px;
    cursor: pointer;
  }
  .closer {
    display: none;
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 24px;
    float: right;
  }
  .sticky-note .header:hover .closer {
    display: inline-flex;
  }
  .sticky-note .content-scroll {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 10px;
    outline: none;
    font-family: 'Kalam';
    font-size: 24px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .sticky-note .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex: 1;
  }
  .sticky-note.ink-black {
    color: #345;
  }
  .sticky-note.ink-blue {
    color: #009;
  }
  .sticky-note :global(p) {
    margin: 0 0 9px;
    line-height: normal;
  }
  .sticky-note :global(p:last-child) {
    margin-bottom: 0;
  }
  .sticky-note :global(a) {
    color: #0070ff;
  }

  .sticky-note.yellow .background {
    background-color: #ffffd7;
    background-image: linear-gradient(to right bottom, #fbffdf 0%, #fbeead 100%);
  }
  .sticky-note.green .background {
    background-color: #f0ffde;
    background-image: linear-gradient(to right bottom, #f0ffde 0%, #bfe5a5 100%);
  }
  .sticky-note.blue .background {
    background-color: #d9f8ff;
    background-image: linear-gradient(to right bottom, #d9f8ff 0%, #afdaee 100%);
  }
  .sticky-note.pink .background {
    background-color: #ffe3e3;
    background-image: linear-gradient(to right bottom, #ffefef 0%, #fad1d2 100%);
  }
</style>
